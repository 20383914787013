import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decodeNewToken } from 'state/reducers/token'
import { ICombineReducers } from 'state/reducers'
import { checkAllTokens, handleFailure } from 'adapter/xhr'

// useTokenInitializer hook will be used in Protected Routes
// This is an Offline Check on the accessToken and refreshToken to see if it's expired.
// If not, it will just clear all localStorage and redirect to Login
// THIS DOES NOT GUARANTEE THAT THE TOKEN IS LEGIT. LEGITNESS WILL BE AT THE GRAPHQL AXIOS INTERCEPTOR
// So maybe you can hack the token and fokup the signature, but you can't shoot any API
const useTokenInitializer: any = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state: ICombineReducers) => state.tokenReducer.isLoggedIn)

  // Everytime change the pathname (page), it will run
  // 1. Check if have refreshToken and if the refreshToken is not expired.
  // 2. Check if accessToken is not expired, else refreshToken
  useEffect(() => {
    checkAllTokens()
      .then((refreshTokenResponse) => {
        const newAccessToken = refreshTokenResponse.accessToken
        const newRefreshToken = refreshTokenResponse.refreshToken
        // Immediately reject if no access Token Provided
        if (!newAccessToken) handleFailure()
        else localStorage.setItem('accessToken', newAccessToken)
        if (newRefreshToken) localStorage.setItem('refreshToken', newRefreshToken)
        dispatch(decodeNewToken(newAccessToken, newRefreshToken))
      })
      .catch((err) => {
        console.error(err)
        handleFailure()
      })
  }, [location.pathname])

  return isLoggedIn
}

export default useTokenInitializer

// interface ITokenInitializerProps {
// unProtectedPathList: any[]
// }
