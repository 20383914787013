import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, Field, FieldProps, ErrorMessage } from 'formik'
import { THAILAND_PROVINCE_MAPPER } from '../../components/commons/thailandFact'
import { FormikInputRadio } from 'components/commons/input/formikInputRadio'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import { DatePicker } from '@fluentui/react'
import { calDateforInputDate } from '../../utils/common'
import * as Yup from 'yup'

const VerifyFristPerson: React.FC = (): JSX.Element => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  async function validatePersonID(value: string) {
    if (!value) {
      return 'กรุณากรอกเลขประจำตัวประชาชน'
    }
    try {
      await Yup.object()
        .shape({
          PersonID: Yup.string().length(13, 'กรุณากรอกเลขประจำตัวประชาชน 13 หลัก').required('eeee'),
        })
        .validate({ PersonID: value.toString() })
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }
  async function validatePhone(value: string) {
    if (!value) {
      return 'กรุณากรอกเบอร์ติดต่อ'
    }
    try {
      await Yup.object()
        .shape({
          phone: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'กรอกเฉพาะหมายเลขเท่านั้น')
            .min(10, 'กรอกเบอร์ติดต่อ 10 หลัก')
            .max(10, 'กรอกเบอร์ติดต่อ 10 หลัก'),
        })
        .validate({ phone: value })
      console.log(value, 'value phone')
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }

  async function validatePhoneEmer(value: string) {
    try {
      await Yup.object()
        .shape({
          phone: Yup.string()
            .matches(/^[0-9]+$/, 'กรอกเฉพาะหมายเลขเท่านั้น')
            .min(10, 'กรอกเบอร์ติดต่อ 10 หลัก')
            .max(10, 'กรอกเบอร์ติดต่อ 10 หลัก'),
        })
        .validate({ phone: `${value}` })
      console.log(value, 'value phone')
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }

  return (
    <>
      <div>
        {' '}
        <div className={styles.flexbox}>
          {' '}
          <div className={styles.inputleft}>
            {' '}
            <div className={styles.inputArea}>
              <p>ชื่อ - นามสกุล</p>
              <div className={styles.flexinput}>
                <Field className={styles.inputText} type="text" name={'profileUp.firstName'} placeholder="ชื่อ" />
                <Field className={styles.inputText} type="text" name={'profileUp.lastName'} placeholder="นามสกุล" />
              </div>
              <div>
                <p>ชื่อเล่น</p>
                <Field className={styles.inputText} type="text" name={'profileUp.nickName'} placeholder="ชื่อเล่น" />
              </div>
              <div>
                <span>
                  เลขบัตรประชาชน *{' '}
                  <span className="errorMessage">
                    <ErrorMessage name="profileUp.personalID" />
                  </span>
                </span>
                <Field
                  className={styles.inputText}
                  type="number"
                  name={'profileUp.personalID'}
                  placeholder="เลขบัตรประชาชน"
                  validate={validatePersonID}
                />
              </div>

              <div className={styles.addressBox}>
                <p>ที่อยู่ตามบัตรประชาชน</p>
                <div className={styles.flexinput}>
                  <Field
                    className={styles.inputText}
                    type="number"
                    name={'profileUp.zipCode'}
                    placeholder="รหัสไปรษณีย์"
                  />
                  <FormikInputSelect
                    className={styles.inputText}
                    name={'profileUp.province'}
                    option={[
                      ...Object.entries(THAILAND_PROVINCE_MAPPER).map((region: any) => ({
                        value: region[1],
                        text: region[0],
                      })),
                    ]}
                    defaultValue={''}
                  />{' '}
                </div>{' '}
                <div>
                  <Field
                    as="textarea"
                    className={styles.inputText}
                    name={'profileUp.addressDetail'}
                    placeholder="ที่อยู่"
                  />
                </div>
              </div>
              <div>
                <span>
                  เบอร์ติดต่อ *{' '}
                  <span className="errorMessage">
                    <ErrorMessage name="profileUp.phone" />
                  </span>
                </span>{' '}
                <Field
                  className={styles.inputText}
                  type="text"
                  name={'profileUp.phone'}
                  placeholder="เบอร์ติดต่อ"
                  validate={validatePhone}
                />
              </div>
              <div>
                <p>อีเมล์</p>
                <Field className={styles.inputText} type="text" name={'profileUp.email'} placeholder="อีเมล์" />
              </div>
            </div>
          </div>
          <div className={styles.inputrigth}>
            {' '}
            <div>
              <p>วันเดือนปีเกิด</p>
              <Field name={'profileUp.birthDate'}>
                {({ field }: FieldProps) => (
                  <DatePicker
                    style={{ width: '200px' }}
                    {...field}
                    // allowTextInput
                    value={field.value ? new Date(field.value) : undefined}
                    onSelectDate={(date) => {
                      if (date) {
                        setFieldValue('profileUp.birthDate', calDateforInputDate(date))
                      }
                    }}
                    maxDate={new Date()}
                  />
                )}
              </Field>
            </div>
            <div className={styles.radioGroup}>
              <p className={styles.texthead}>เพศ</p>
              <div className={styles.radio}>
                {' '}
                <FormikInputRadio
                  defaultValue="MALE"
                  listRadio={[
                    {
                      text: 'ชาย',
                      value: 'MALE',
                      name: `${'profileUp.gender'}`,
                    },
                    {
                      text: 'หญิง',
                      value: 'FEMALE',
                      name: `${'profileUp.gender'}`,
                    },
                    {
                      text: 'เพศทางเลือก',
                      value: 'LGBT',
                      name: `${'profileUp.gender'}`,
                    },
                  ]}
                />
              </div>
            </div>
            <div className={styles.radioGroup}>
              <p className={styles.texthead}>สถานภาพ</p>
              <div className={styles.radio}>
                <FormikInputRadio
                  defaultValue="SINGLE"
                  listRadio={[
                    {
                      text: 'โสด',
                      value: 'SINGLE',
                      name: `profileUp.personalRelation`,
                    },
                    {
                      text: 'สมรส',
                      value: 'MARRIED',
                      name: `profileUp.personalRelation`,
                    },
                    {
                      text: 'หย่า',
                      value: 'DIVORCE',
                      name: `profileUp.personalRelation`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.emerBox}>
          <div className={styles.flexinput}>
            <div className={styles.GroupInput}>
              <p>ชื่อ-นามสกุล ผู้ติดต่อกรณี ฉุกเฉิน</p>
              <div className={styles.flexInput}>
                <Field
                  className={styles.inputText}
                  type="text"
                  name={'profileUp.emergencyContact.firstName'}
                  placeholder="ชื่อ"
                />
                <Field
                  className={styles.inputText}
                  type="text"
                  name={'profileUp.emergencyContact.lastName'}
                  placeholder="นามสกุล"
                />
              </div>
            </div>
            <div className={styles.GroupInput}>
              {' '}
              <p>ความสัมพันธ์</p>
              <Field
                className={styles.inputText}
                type="text"
                name={'profileUp.emergencyContact.relation'}
                placeholder="ความสัมพันธ์"
              />
            </div>
          </div>
          <div></div>
          <div>
            <span>
              เบอร์ผู้ติดต่อกรณีฉุกเฉิน{' '}
              <span className="errorMessage">
                <ErrorMessage name="profileUp.emergencyContact.phone" />
              </span>
            </span>
            <Field
              className={styles.inputText}
              type="text"
              name={'profileUp.emergencyContact.phone'}
              placeholder="เบอร์ติดต่อ"
              validate={validatePhoneEmer}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default VerifyFristPerson
