import React, { useState, useLayoutEffect } from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import './index.scss'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { Router } from 'react-router-dom'
// import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { Provider } from 'react-redux'
import store from './state/store'
import history from 'historyCommon'
import { History } from 'history'

// initializeIcons()
const container = document.getElementById('root')
const root = createRoot(container!)

// Using custom history in Router v6
// https://stackoverflow.com/questions/69871987/react-router-v6-navigate-outside-of-components
const CustomRouter = ({ history, children, ...props }: { history: History; children: JSX.Element }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router {...props} location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  )
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* BrowserRouter doesn't take in a prop called history. There's a Router common low-level interface  */}
      {/* https://stackoverflow.com/questions/57594535/typescript-does-not-allow-me-to-use-history-in-browserrouter */}
      <CustomRouter history={history}>
        <App />
      </CustomRouter>
    </Provider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
