import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useFormikContext } from 'formik'
// import dropLogo from 'image/droplogo.svg'
import ChungTumTable, {
  CELL_TYPE,
  ITableColumn,
  patStatusColorMapper,
  PAT_STATUS_MAPPER,
} from 'components/ChungTumTable'
import { useParams, useLocation } from 'react-router-dom'
import { CUSTOMER_PREFIX } from 'App'
import { IProject, IWorkType } from 'adapter/xhr'
import { THAILAND_REGION2CODE_MAPPER, THAILAND_REGION_MAPPER } from 'components/commons/thailandFact'
import FooterComponant from 'components/commons/btnFooter'
import ModalConfirm from 'components/commons/modalConfirm'
import { packAndPutProjectArchiveStatus } from 'adapter/projectManagementPage'
import { exportIssueExcel } from './exportIssueExcel'

const Thirdcontent: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { customerOrTechnician } = useParams()
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  const [isModalCloseConfirm, setIsModalCloseConfirm] = useState<boolean>(false)

  function handleProjectClose() {
    if (values.unpackproject) {
      console.time('handleProjectClose>packAndPutProjectArchiveStatus')
      packAndPutProjectArchiveStatus(values.unpackproject).then((projectResponse: IProject) => {
        console.log('THIS IS projectResponse', projectResponse)
        console.timeEnd('handleProjectClose>packAndPutProjectArchiveStatus')
      })
    }
    setIsModalCloseConfirm(false)
    window.location.reload() //Refresh the page https://upmostly.com/tutorials/how-to-refresh-a-page-or-component-in-react
  }

  return (
    <>
      {console.log(values.unpackproject, '==========>')}
      {values.unpackproject &&
        values.unpackproject.workTypeList.map((workTypeElement: IWorkType, workTypeIndex: number) => {
          if (workTypeElement.jobList && values.unpackproject.workTypeList[workTypeIndex].jobList.length > 0)
            return (
              <div className={styles.thirdcontentcontainer} key={workTypeIndex}>
                <h1>{workTypeElement.workType}</h1>
                <button
                  className={'btExport'}
                  onClick={() => {
                    exportIssueExcel(workTypeElement.workType, workTypeElement.jobList as any[])
                  }}
                >
                  <p>Export (Excel)</p>
                  {/* <img src={dropLogo} alt="" /> */}
                </button>
                <ChungTumTable
                  tableColumns={
                    customerOrTechnician === CUSTOMER_PREFIX ? TABLE_COLUMNS_CUSTOMER : TABLE_COLUMNS_TECHNICIAN
                  }
                  data={workTypeElement.jobList}
                  formikPrefix={``}
                  disableRemove
                  showTotal
                  showToolbar
                  tableId={`T${workTypeElement.lineNumber}`}
                  regionFilterInput={
                    params.get('tableId') === `T${workTypeElement.lineNumber}` && params.get('regionFilter')
                      ? (params.get('regionFilter') as string)
                      : ''
                  }
                  patStatusFilterInput={
                    params.get('tableId') === `T${workTypeElement.lineNumber}` && params.get('patStatusFilter')
                      ? (params.get('patStatusFilter') as string)
                      : ''
                  }
                  textFilterInput={
                    params.get('tableId') === `T${workTypeElement.lineNumber}` && params.get('textFilter')
                      ? (params.get('textFilter') as string)
                      : ''
                  }
                />
              </div>
            )
        })}

      {/* For Customer to Close the project */}
      {values.unpackproject && customerOrTechnician === CUSTOMER_PREFIX && (
        <FooterComponant
          listButtons={[
            {
              fucnctionOnclick: () => setIsModalCloseConfirm(true),
              text: 'ปิดโครงการ',
              type: 'button',
              class: 'btnDefault',
            },
          ]}
        />
      )}
      {/* 
      {values.projectSelectedId ? (
        <ModalIssue
          open={open}
          setOpen={setOpen}
          // projectId={values.projectSelectedId}
          // changTumId={data && dataElement.changTumId[modalIndex]}

          // setModalIndex={setModalIndex}
        />
      ) : (
        ''
      )} */}

      {/* Modal Confirm for saving */}
      <ModalConfirm
        isModalOpen={isModalCloseConfirm}
        closeModal={() => setIsModalCloseConfirm(false)}
        submitModal={handleProjectClose}
        description={`โครงการ${values.unpackproject.projectName} สำเร็จแล้ว ${
          values.unpackproject.workTypeList
            ? values.unpackproject.workTypeList.reduce(
                (jobCount: number, workTypeElement: IWorkType) =>
                  workTypeElement.jobList
                    ? jobCount +
                      workTypeElement.jobList.filter((job) => job.patStatus === 'ACCEPT' || job.patStatus === 'REJECT')
                        .length
                    : jobCount,
                0,
              )
            : ''
        } งาน จาก ${
          values.unpackproject.workTypeList
            ? values.unpackproject.workTypeList.reduce(
                (jobCount: number, workTypeElement: IWorkType) =>
                  workTypeElement.jobList ? jobCount + workTypeElement.jobList.length : jobCount,
                0,
              )
            : ''
        } งาน หากทำการปิดโครงการแล้ว จะไม่สามารถแก้ไขข้อมูลภายในโครงการได้`}
        status={`ต้องการปิดโครงการ ใช่หรือไม่`}
      />
    </>
  )
}

export default Thirdcontent

const TABLE_COLUMNS_CUSTOMER: ITableColumn[] = [
  {
    name: 'index',
    text: '#',
    engText: 'No',
    columnSize: '20px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
    sortable: false,
  },
  {
    name: 'approvePAT',
    text: '(ตรวจสอบPAT)',
    engText: 'Approve PAT',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
    sortable: false,
  },
  {
    name: 'patStatus',
    text: '(สถานะ)',
    engText: 'Status',
    columnSize: '110px',
    readOnly: true,
    mapper: (data) => PAT_STATUS_MAPPER[data],
    colorMapper: patStatusColorMapper,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'patUpdatedAt',
    text: '(แก้ไขล่าสุด)',
    engText: 'PAT UpdateAt',
    columnSize: '130px',
    readOnly: true,
    mapper: (data) => new Date(data).toLocaleString('th-TH'),
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'IssueManagement',
    text: '(ตรวจสอบ PAT)',
    engText: 'Approve PAT',
    columnSize: '120px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteCode',
    text: '(รหัสไซต์)',
    engText: 'Site Code',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteName',
    text: '(ชื่อไซต์)',
    engText: 'Site Name',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'number',
    text: '(เลขที่)',
    engText: 'Number',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'region',
    text: '(ภูมิภาค)',
    engText: 'Region',
    columnSize: '50px',
    readOnly: true,
    mapper: (data) => THAILAND_REGION2CODE_MAPPER[data],
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'customAttributeEditFieldID',
    text: '(ประเภทงาน)',
    engText: 'CustomAttribute',
    columnSize: '110px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'province',
    text: '(จังหวัด)',
    engText: 'Province',
    columnSize: '50px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'district',
    text: '(อำเภอ)',
    engText: 'District',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'lat',
    text: '(แลตติจูด)',
    engText: 'Latitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'long',
    text: '(ลองติจูด)',
    engText: 'Longitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'zone',
    text: '(โซน)',
    engText: 'Zone',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'changTumId',
    text: '(ช่างทำไอดี)',
    engText: 'ChangTumID',
    columnSize: '88px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'acceptedUserId',
    text: '(ช่างที่รับผิดชอบ)',
    engText: 'Res.Person',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'jobId',
    text: '(รหัสงาน)',
    engText: 'JobID',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
]

const TABLE_COLUMNS_TECHNICIAN: ITableColumn[] = [
  {
    name: 'index',
    text: '#',
    engText: 'No',
    columnSize: '20px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
    sortable: false,
  },

  {
    name: 'uploadPAT',
    text: '(อัพโหลดรูป)',
    engText: 'Upload PAT',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
    sortable: false,
  },

  {
    name: 'patStatus',
    text: '(สถานะ)',
    engText: 'Status',
    columnSize: '110px',
    readOnly: true,
    mapper: (data) => PAT_STATUS_MAPPER[data],
    colorMapper: patStatusColorMapper,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'patUpdatedAt',
    text: '(แก้ไขล่าสุด)',
    engText: 'PAT UpdateAt',
    columnSize: '150px',
    readOnly: true,
    mapper: (data) => new Date(data).toLocaleString('th-TH'),
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'changTumId',
    text: '(ช่างทำไอดี)',
    engText: 'ChangTumID',
    columnSize: '88px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'IssueManagement',
    text: '(ตรวจสอบ PAT)',
    engText: 'Approve PAT',
    columnSize: '120px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },

  {
    name: 'jobId',
    text: '(รหัสงาน)',
    engText: 'JobID',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'region',
    text: '(ภูมิภาค)',
    engText: 'Region',
    columnSize: '50px',
    readOnly: true,
    mapper: (data) => THAILAND_REGION2CODE_MAPPER[data],
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'province',
    text: '(จังหวัด)',
    engText: 'Province',
    columnSize: '50px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'district',
    text: '(อำเภอ)',
    engText: 'District',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'lat',
    text: '(แลตติจูด)',
    engText: 'Latitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'long',
    text: '(ลองติจูด)',
    engText: 'Longitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteCode',
    text: '(รหัสไซต์)',
    engText: 'Site Code',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteName',
    text: '(ชื่อไซต์)',
    engText: 'Site Name',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'zone',
    text: '(โซน)',
    engText: 'Zone',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'number',
    text: '(เลขที่)',
    engText: 'Number',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'assignedUserId',
    text: '(ช่างที่รับผิดชอบ)',
    engText: 'Res.Person',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
]
