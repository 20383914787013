import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useFormikContext } from 'formik'

import { useSelector, useDispatch } from 'react-redux'
import { ICombineReducers } from 'state/reducers'

import ChungTumTable, { CELL_TYPE, ITableColumn } from 'components/ChungTumTable'
import PlanTable from 'components/PlanTable'
import { useParams } from 'react-router-dom'
import { CUSTOMER_PREFIX, TECHNICIAN_PREFIX } from 'App'
import FooterComponant from 'components/commons/btnFooter'
import { IJob, IProject, IWorkType, createNotification, putMasterPlan } from 'adapter/xhr'
import ModalConfirm from 'components/commons/modalConfirm'
import { destructureJobList, packAndPutJobs } from 'adapter/projectManagementPage'
import { THAILAND_REGION2CODE_MAPPER } from 'components/commons/thailandFact'
import allAction from 'state/actions'
import MoreIcon from '../../../image/more.svg'
import MasterPlanModal, { IMasterPlanCellParamData } from './MasterPlanModal'
import { packMasterPlan } from 'utils/common'
// import Tooltip from 'components/Tooltip'
import { NewExportAIS } from 'utils/newExportAIS'
import { NewExportInno } from 'utils/newExportInno'

const SecondContent: React.FC<any> = (props: any) => {
  const [showExportSwitch, setShowExportSwitch] = useState(false)
  const { values, setFieldValue } = useFormikContext<any>()
  const { customerOrTechnician } = useParams()
  const userinfo = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken.username)

  const [isPlanButton, setIsPlanButton] = useState<number>(0)
  const [tableColumns, setTableColumns] = useState<ITableColumn[]>([])

  // Modal Save Confirm
  const [isModalSaveConfirm, setIsModalSaveConfirm] = useState<boolean>(false)
  // const [year, setYear] = useState<number>(new Date().getFullYear())
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1)

  const [isShowMasterPlanModal, setIsShowMasterPlanModal] = useState<boolean>(false)

  const [masterPlanModalData, setMasterPlanModalData] = useState<IMasterPlanCellParamData>()

  const dispatch = useDispatch()

  const monthFilterOptions: IOption[] = []
  for (let monthIndex = 1; monthIndex <= 12; monthIndex++) {
    monthFilterOptions.push({
      value: monthIndex,
      text: new Date(2000, monthIndex - 1, 1).toLocaleDateString('th-TH', { month: 'long' }),
    })
  }

  interface IOption {
    value: string | number
    text: string
  }

  useEffect(() => {
    // Customer or Technician
    // 1. If customer, just show same thing as technician first - without edit
    // 2. Check if isPlanButton or Not isPlanButton (Actual Date)

    // Customer
    if (customerOrTechnician === CUSTOMER_PREFIX) {
      if (isPlanButton === 0) setTableColumns(TABLE_COLUMNS_PLAN_CUSTOMER)
      else if (isPlanButton === 1) setTableColumns(TABLE_COLUMNS_ACTUAL_CUSTOMER)
    } else {
      //Technician
      if (isPlanButton === 0) setTableColumns(TABLE_COLUMNS_PLAN_TECHNICIAN)
      else if (isPlanButton === 1) setTableColumns(TABLE_COLUMNS_ACTUAL_TECHNICIAN)
    }
  }, [customerOrTechnician, isPlanButton])

  function handleSavePlanActual() {
    console.log('I AM SUBMITING')

    if (values.unpackproject && values.jobListReference) {
      const differenceList = checkJobDifference(values.unpackproject, values.jobListReference)
      console.log('THIS IS differenceList', differenceList)
      differenceList.map((diffListEle: any) => {
        const CreateNotificationInput = {
          userId: `${values.unpackproject.createdByUserId}`,
          message: `<b>${userinfo}.</b> ได้ทำการอัพเดท <b>${
            isPlanButton === 0 ? 'Plan date' : 'Actual Date'
          }</b> " ชื่อโปรเจ็ค ${values.unpackproject.projectName} + ชื่อประเภทงาน ${
            diffListEle?.workType
          } + รหัสไซต์งาน ${diffListEle?.siteCode} + ชื่อไซต์งาน ${
            diffListEle?.siteName
          }" ${diffListEle.changedFields.map((chagedFieldEle: any) => {
            return `<span>${chagedFieldEle.name}</span> จาก <span style="color:grey;">${
              chagedFieldEle.before === '' ? 'ว่าง' : chagedFieldEle.before
            }</span> <span>เป็น</span> <span style="color:blue;">${
              chagedFieldEle.after === '' ? 'ว่าง' : chagedFieldEle.after
            }</span> `
          })} `,
          iconUrl: '',
          toLink: `/customer/projectmanagementpage/`,
          isRead: false,
        }
        createNotification(CreateNotificationInput)
          .then((res) => {
            console.log(res, 'createNotification >>>>>>>>>>>>>>>>')
          })
          .catch((err) => {
            console.log(err, 'err')
          })
      })
    }

    setIsModalSaveConfirm(false)
    packAndPutJobs(values.unpackproject)
      .then(() => {
        console.log('success handleSavePlanActual')

        // Change jobListReference if successful
        const jobListReference = destructureJobList(values.unpackproject)
        setFieldValue('jobListReference', jobListReference)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  // fieldsToCheck => null means check all, array of strings means check those
  function checkJobDifference(
    unpackedProject: IProject,
    jobListReference: IJob[],
    fieldsToCheck: string[] | null = null,
  ): any[] {
    const jobListToCheck = destructureJobList(unpackedProject)
    const differenceList = jobListToCheck.flatMap((jobElement, jobIndex) => {
      // Keys to do the checking, Union between the job Elements
      const keysToCheck = fieldsToCheck
        ? fieldsToCheck
        : [...new Set([...Object.keys(jobElement), ...Object.keys(jobListReference[jobIndex])])]
      const changedFields: any[] = []

      keysToCheck.map((key: string) => {
        if (jobElement[key] !== jobListReference[jobIndex][key])
          changedFields.push({
            name: key,
            before: jobListReference[jobIndex][key],
            after: jobElement[key],
          })
      })
      if (changedFields.length === 0) return []
      else return [{ ...jobElement, changedFields }]
    })
    return differenceList
  }

  // useEffect(() => {
  //   setFieldValue('masterPlan.selectTechnician', 'ALL')
  // }, [])

  return (
    <>
      {values.unpackproject && (
        <div className={styles.planActualWrapper}>
          <button
            className={`${styles.selectionButton} ${isPlanButton === 0 ? styles.selected : ''}`}
            type="button"
            onClick={() => setIsPlanButton(0)}
          >
            Plan Date
          </button>
          <button
            className={`${styles.selectionButton} ${isPlanButton === 1 ? styles.selected : ''}`}
            type="button"
            onClick={() => setIsPlanButton(1)}
          >
            Installed Date
          </button>
          {customerOrTechnician === CUSTOMER_PREFIX ? (
            <button
              className={`${styles.selectionButton} ${isPlanButton === 2 ? styles.selected : ''}`}
              type="button"
              onClick={() => setIsPlanButton(2)}
            >
              Master Plan
            </button>
          ) : (
            ''
          )}
        </div>
      )}
      {isPlanButton !== 2 ? (
        values.unpackproject &&
        values.unpackproject.workTypeList.map((workTypeElement: IWorkType, workTypeIndex: number) => {
          if (values.unpackproject.workTypeList[workTypeIndex].jobList.length > 0)
            return (
              <div className={styles.thirdcontentcontainer} key={workTypeIndex}>
                <h1>{workTypeElement.workType}</h1>

                <ChungTumTable
                  tableColumns={tableColumns}
                  data={values.unpackproject.workTypeList[workTypeIndex].jobList}
                  formikPrefix={`unpackproject.workTypeList[${workTypeIndex}].jobList`}
                  disableRemove
                  showTotal
                  showToolbar
                  tableId={`T${workTypeElement.lineNumber}`}
                />
              </div>
            )
        })
      ) : (
        // <PlanTable />
        <div className={styles.masterplanCard}>
          <section className={styles.masterplanToolbar}>
            <article className={styles.toolbarLeftSide}>
              <h1 className={styles.masterPlanTopic}>Master Plan</h1>
              <div className={styles.masterPlanSelectContainer}>
                <select
                  className={styles.selectDropdown}
                  value={props.year}
                  onChange={(e) => {
                    console.log(e.target.value, 'e.target.value')
                    setFieldValue('masterPlan.planningYear', e.target.value)
                    props.setYear(parseInt(e.target.value))
                  }}
                >
                  {[...Array(20).keys()].map((yearIndex) => {
                    const yearSelection = props.year + yearIndex - 10
                    return (
                      <option key={yearIndex} value={yearSelection}>
                        {yearSelection}
                      </option>
                    )
                  })}
                </select>
                <select
                  className={styles.selectDropdown}
                  value={month}
                  onChange={(e) => setMonth(parseInt(e.target.value))}
                >
                  {monthFilterOptions.map((optionElement, optionIndex) => (
                    <option key={optionIndex} value={optionElement.value}>
                      {optionElement.text}
                    </option>
                  ))}
                </select>
                <select
                  value={values.masterPlan.selectTechnician}
                  onChange={(e) => {
                    setFieldValue('masterPlan.selectTechnician', e.target.value)
                  }}
                  className={styles.selectDropdown}
                >
                  <option value="ALL">เลือกทีมงานช่างทั้งหมด</option>

                  {values.acceptedUserIdList?.map((user: any) => (
                    <option key={user.userId} value={user.userId}>
                      {user.getUser.username}
                    </option>
                  ))}
                </select>
              </div>
            </article>
            <article className={styles.toolbarRightSide}>
              {values.isNoMasterPlan ? (
                <button
                  type="button"
                  className={styles.createMasterplanBtn}
                  disabled={!values.masterPlan.fieldID.length}
                  onClick={() => {
                    const inputMasterPlan = {
                      input: {
                        ...values.masterPlan,
                        planningYear: `${values.masterPlan.planningYear}`,
                        projectId: values.projectSelectedId,
                      },
                    }
                    delete inputMasterPlan.input.updatedAt
                    delete inputMasterPlan.input.selectTechnician
                    // console.log(values.masterPlan, 'values.masterPlan')
                    putMasterPlan(inputMasterPlan).then((putResponse: any) => {
                      // console.log(putResponse, 'putResponse')
                      setFieldValue(
                        'masterPlan.selectTechnician',
                        values.masterPlan.selectTechnician === 'ALL' ? null : 'ALL',
                      )
                    })
                  }}
                >
                  สร้าง MasterPlan
                </button>
              ) : (
                <>
                  <button type="button" className={styles.importBtn} disabled={true}>
                    Import
                  </button>
                  <button type="button" className={styles.downloadCsvBtn} disabled>
                    ดาวน์โหลด CSV
                  </button>
                  {/* nut */}
                  <button
                    type="button"
                    // onClick={() => generateMasterPlan(values.masterPlan.groupFieldList, props.year)}
                    // onClick={() => console.log('masterPlan', values.masterPlan)}
                    className={styles.exportBtn}
                  >
                    <div className={styles.headBtn} onClick={() => setShowExportSwitch(!showExportSwitch)}>
                      <div>Export</div>
                      <img className={styles.headImgBtn} src={MoreIcon} alt="more icon" />
                      {showExportSwitch && (
                        <div className={styles.selectExport}>
                          <div
                            onClick={() => {
                              NewExportAIS(
                                {
                                  masterPlan: values.masterPlan.groupFieldList,
                                },
                                props.year,
                                ['masterPlan'],
                              )
                            }}
                            className={styles.hiddenItem}
                          >
                            Export Master Plan
                          </div>
                          <div
                            onClick={() => {
                              NewExportInno(
                                values.projectSelectedId,
                                props.year,
                                props.month,
                                true,
                                false,
                                false,
                                values,
                              )
                            }}
                            className={styles.hiddenItem}
                          >
                            Export Master Plan Detail
                          </div>
                        </div>
                      )}
                    </div>
                  </button>
                </>
              )}
            </article>
          </section>
          <PlanTable
            tableTopic="Master Plan"
            theme="masterplan"
            year={props.year}
            month={month}
            tableOption={values.options}
            FieldName="masterPlan"
            onMasterPlanCellClick={(data) => {
              console.log('pankorn', data)
              setIsShowMasterPlanModal(true)
              setMasterPlanModalData({
                ...data,
              })
            }}
          />
          <FooterComponant
            listButtons={[
              {
                // fucnctionOnclick: () => setIsModalSaveConfirm(true),
                fucnctionOnclick: () => {
                  dispatch(
                    allAction.modalConfirmAction.setOpenModalConfirm(
                      'ยืนยันการทำรายการ',
                      'คุณต้องการบันทึกใช่หรือไม่',
                      `เมื่อบันทึกแล้วจะอัพเดตข้อมูลล่าสุดเสมอ`,
                      'ใช่, ทำบันทึกรายการ',
                      () => {
                        const inputMasterPlan = { input: { ...values.masterPlan, projectId: values.projectSelectedId } }
                        delete inputMasterPlan.input.updatedAt

                        putMasterPlan(packMasterPlan(inputMasterPlan, values.masterPlan.selectTechnician)).then(
                          (putResponse: any) => {
                            console.log(putResponse, 'putResponse')
                          },
                        )
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus('ยืนยันการบันทึกเรียบร้อย', '', 'success'),
                        )
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      },
                    ),
                  )
                },
                text: 'บันทึก',
                type: 'button',
                class: 'btnDefault2',
                disabled: values.masterPlan.fieldID === '',
              },
            ]}
          />
        </div>
      )}

      {values.unpackproject && customerOrTechnician === TECHNICIAN_PREFIX && (
        <FooterComponant
          listButtons={[
            {
              fucnctionOnclick: () => setIsModalSaveConfirm(true),
              text: 'บันทึก',
              type: 'button',
              class: 'btnDefault2',
            },
          ]}
        />
      )}

      {/* Modal Confirm for saving */}
      <ModalConfirm
        isModalOpen={isModalSaveConfirm}
        closeModal={() => setIsModalSaveConfirm(false)}
        submitModal={() => handleSavePlanActual()}
        description={`ข้อมูลของ${values.unpackproject.projectName}`}
        status={`ต้องการบันทึก ใช่หรือไม่`}
      />
      {/* Modal Master Plan */}
      <MasterPlanModal open={isShowMasterPlanModal} onClose={setIsShowMasterPlanModal} data={masterPlanModalData} />
    </>
  )
}

export default SecondContent

const TABLE_COLUMNS_PLAN_CUSTOMER: ITableColumn[] = [
  {
    name: 'index',
    text: '#',
    engText: 'No',
    columnSize: '20px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'planDate',
    text: '(วันที่เข้างาน)',
    engText: 'Plan Date',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'assignedUserId',
    text: '(ช่างที่รับผิดชอบ)',
    engText: 'Res.Person',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'assignedUserPhone',
    text: '(เบอร์โทร)',
    engText: 'Phone No.',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'addition1',
    text: '(เพิ่มเติม1)',
    engText: 'Addition1',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteCode',
    text: '(รหัสไซต์)',
    engText: 'Site Code',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteName',
    text: '(ชื่อไซต์)',
    engText: 'Site Name',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'number',
    text: '(เลขที่)',
    engText: 'Number',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'region',
    text: '(ภูมิภาค)',
    engText: 'Region',
    columnSize: '50px',
    readOnly: true,
    mapper: (data) => THAILAND_REGION2CODE_MAPPER[data],
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'customAttributeEditFieldID',
    text: '(ประเภทงาน)',
    engText: 'CustomAttribute',
    columnSize: '110px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'province',
    text: '(จังหวัด)',
    engText: 'Province',
    columnSize: '50px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'district',
    text: '(อำเภอ)',
    engText: 'District',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'lat',
    text: '(แลตติจูด)',
    engText: 'Latitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'long',
    text: '(ลองติจูด)',
    engText: 'Longitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'changTumId',
    text: '(ช่างทำไอดี)',
    engText: 'ChangTumID',
    columnSize: '88px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'jobId',
    text: '(รหัสงาน)',
    engText: 'JobID',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'remark',
    text: '(หมายเหตุ)',
    engText: 'Remark',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },

  {
    name: 'zone',
    text: '(โซน)',
    engText: 'Zone',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },

  {
    name: 'description',
    text: '(รายละเอียด)',
    engText: 'Description',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
]

const TABLE_COLUMNS_ACTUAL_CUSTOMER: ITableColumn[] = [
  {
    name: 'index',
    text: '#',
    engText: 'No',
    columnSize: '20px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'actualDate',
    text: '(วันที่เข้างานจริง)',
    engText: 'Actual Date',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'assignedUserId',
    text: '(ช่างที่รับผิดชอบ)',
    engText: 'Res.Person',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'assignedUserPhone',
    text: '(เบอร์โทร)',
    engText: 'Phone No.',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'addition2',
    text: '(เพิ่มเติม2)',
    engText: 'Addition2',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteCode',
    text: '(รหัสไซต์)',
    engText: 'Site Code',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteName',
    text: '(ชื่อไซต์)',
    engText: 'Site Name',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'number',
    text: '(เลขที่)',
    engText: 'Number',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'region',
    text: '(ภูมิภาค)',
    engText: 'Region',
    columnSize: '50px',
    readOnly: true,
    mapper: (data) => THAILAND_REGION2CODE_MAPPER[data],
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'customAttributeEditFieldID',
    text: '(ประเภทงาน)',
    engText: 'CustomAttribute',
    columnSize: '110px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'province',
    text: '(จังหวัด)',
    engText: 'Province',
    columnSize: '50px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'district',
    text: '(อำเภอ)',
    engText: 'District',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'lat',
    text: '(แลตติจูด)',
    engText: 'Latitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'long',
    text: '(ลองติจูด)',
    engText: 'Longitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'changTumId',
    text: '(ช่างทำไอดี)',
    engText: 'ChangTumID',
    columnSize: '88px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'jobId',
    text: '(รหัสงาน)',
    engText: 'JobID',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'remark',
    text: '(หมายเหตุ)',
    engText: 'Remark',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },

  {
    name: 'zone',
    text: '(โซน)',
    engText: 'Zone',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },

  {
    name: 'description',
    text: '(รายละเอียด)',
    engText: 'Description',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
]

const TABLE_COLUMNS_PLAN_TECHNICIAN: ITableColumn[] = [
  {
    name: 'index',
    text: '#',
    engText: 'No',
    columnSize: '20px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'planDate',
    text: '(วันที่เข้างาน)',
    engText: 'Plan Date',
    columnSize: '150px',
    readOnly: false,
    type: CELL_TYPE.INPUT_DATETIME,
  },
  {
    name: 'assignedUserId',
    text: '(ช่างที่รับผิดชอบ)',
    engText: 'Res.Person',
    columnSize: '100px',
    readOnly: false,
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'assignedUserPhone',
    text: '(เบอร์โทร)',
    engText: 'Phone No.',
    columnSize: '100px',
    readOnly: false,
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'addition1',
    text: '(เพิ่มเติม1)',
    engText: 'Addition1',
    columnSize: '100px',
    readOnly: false,
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'changTumId',
    text: '(ช่างทำไอดี)',
    engText: 'ChangTumID',
    columnSize: '88px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'jobId',
    text: '(รหัสงาน)',
    engText: 'JobID',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'region',
    text: '(ภูมิภาค)',
    engText: 'Region',
    columnSize: '50px',
    readOnly: true,
    mapper: (data) => THAILAND_REGION2CODE_MAPPER[data],
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'province',
    text: '(จังหวัด)',
    engText: 'Province',
    columnSize: '50px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'district',
    text: '(อำเภอ)',
    engText: 'District',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'lat',
    text: '(แลตติจูด)',
    engText: 'Latitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'long',
    text: '(ลองติจูด)',
    engText: 'Longitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteCode',
    text: '(รหัสไซต์)',
    engText: 'Site Code',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteName',
    text: '(ชื่อไซต์)',
    engText: 'Site Name',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'zone',
    text: '(โซน)',
    engText: 'Zone',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'number',
    text: '(เลขที่)',
    engText: 'Number',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'description',
    text: '(รายละเอียด)',
    engText: 'Description',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'remark',
    text: '(หมายเหตุ)',
    engText: 'Remark',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
]

const TABLE_COLUMNS_ACTUAL_TECHNICIAN: ITableColumn[] = [
  {
    name: 'index',
    text: '#',
    engText: 'No',
    columnSize: '20px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'actualDate',
    text: '(วันที่เข้างานจริง)',
    engText: 'Actual Date',
    columnSize: '150px',
    readOnly: false,
    type: CELL_TYPE.INPUT_DATETIME,
  },
  {
    name: 'addition2',
    text: '(เพิ่มเติม2)',
    engText: 'Addition2',
    columnSize: '100px',
    readOnly: false,
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'changTumId',
    text: '(ช่างทำไอดี)',
    engText: 'ChangTumID',
    columnSize: '88px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'jobId',
    text: '(รหัสงาน)',
    engText: 'JobID',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'region',
    text: '(ภูมิภาค)',
    engText: 'Region',
    columnSize: '50px',
    readOnly: true,
    mapper: (data) => THAILAND_REGION2CODE_MAPPER[data],
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'province',
    text: '(จังหวัด)',
    engText: 'Province',
    columnSize: '50px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'district',
    text: '(อำเภอ)',
    engText: 'District',
    columnSize: '80px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'lat',
    text: '(แลตติจูด)',
    engText: 'Latitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'long',
    text: '(ลองติจูด)',
    engText: 'Longitude',
    columnSize: '60px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteCode',
    text: '(รหัสไซต์)',
    engText: 'Site Code',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteName',
    text: '(ชื่อไซต์)',
    engText: 'Site Name',
    columnSize: '90px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'zone',
    text: '(โซน)',
    engText: 'Zone',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'number',
    text: '(เลขที่)',
    engText: 'Number',
    columnSize: '40px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'description',
    text: '(รายละเอียด)',
    engText: 'Description',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'remark',
    text: '(หมายเหตุ)',
    engText: 'Remark',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
]
