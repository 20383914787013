import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import ProfileUpload from '../../components/ProfileUpload'
import EmailIcon from '../../image/email.svg'
import PhoneIcon from '../../image/phone.svg'
import { getUserProfile } from 'adapter/xhr'
import patRequests from 'adapter/pat'
import { Pat } from 'utils/generated'
import { useDispatch } from 'react-redux'
import allAction from 'state/actions'
import ModalUploadLogo from './modalUploadLogo'
import binSVG from 'image/binRed.svg'
import dupIcon from 'image/pat/duplicate.png'
import cn from 'classnames'

const ProfilePage: React.FC = (): JSX.Element => {
  const [image, setImage] = useState('')
  const [patList, setPatList] = useState<Pat[]>([])
  const [isOpenModalUpload, setIsOpenModalUpload] = useState(false)
  const { values, setFieldValue } = useFormikContext<any>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { customerOrTechnician } = useParams<any>()

  useEffect(() => {
    getUserProfile().then((profile: any) => {
      // console.log('Get Profile', profile)
      setFieldValue('profile', profile.data.data.getUser)
    })
  }, [])

  const fetchPATbyUserId = () => {
    if (values.profile && values.profile.userId) {
      patRequests.listPATbyUserId(values.profile.userId).then((_patList) => {
        // console.log('PatList', _patList)
        setPatList(_patList)
      })
    }
  }
  useEffect(() => {
    fetchPATbyUserId()
  }, [values.profile && values.profile.userId])

  const deletePAT = (patId: string) => {
    patRequests.deletePAT(patId).then(() => {
      dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
      fetchPATbyUserId()
    })
  }
  const duplicatePAT = (patId: string) => {
    patRequests.duplicatePAT(patId).then(() => {
      dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
      fetchPATbyUserId()
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        {values.profile && (
          <Form>
            <div className={styles.card}>
              <Link to={`/${customerOrTechnician}/verifypage`}>
                <button type="button" className={styles.btnEditProfile} onClick={() => console.log('Edit Profile')}>
                  Edit Profile
                </button>
              </Link>

              {/* Some random section that doesn't render? */}
              <section className={styles.detailWrapper}>
                <article>
                  <ProfileUpload image={image} setImage={setImage} />
                </article>
                <article>
                  {customerOrTechnician === 'technician' ? (
                    <p className={styles.textTopic}>
                      {values.profile.firstName} {values.profile.lastName}
                    </p>
                  ) : (
                    <p className={styles.textTopic}>{values.profile.companyName}</p>
                  )}
                  <br />
                  <div className={styles.contractWrapper}>
                    {values.profile.email && (
                      <div className={styles.textIconWrapper}>
                        <img src={EmailIcon} alt="email" />
                        <p className={styles.textSubTitle}>{values.profile.email}</p>
                      </div>
                    )}

                    {values.profile.phone && (
                      <div className={styles.textIconWrapper}>
                        <img src={PhoneIcon} alt="phone" />
                        <p className={styles.textSubTitle}>{values.profile.phone}</p>
                      </div>
                    )}
                  </div>

                  <br />
                  {values.profile.addressDetail && (
                    <section>
                      <p>ที่อยู่:</p>
                      <div className={styles.addressWrapper}>
                        <p className={styles.textAddress}>
                          {values.profile.addressDetail}
                          {values.profile.zipCode}
                        </p>
                      </div>
                    </section>
                  )}
                </article>
              </section>
              {/* Section for Username & Password */}
              <section>
                <article>
                  <img
                    className={styles.logoImg}
                    src={values.profile.logoUrl ? values.profile.logoUrl : undefined}
                    alt=""
                  />
                  <br />
                  <button
                    type="button"
                    className={styles.btnChangePassword}
                    onClick={() => {
                      setFieldValue('uploadLogo', values.profile.logoUrl)

                      setIsOpenModalUpload(true)
                    }}
                  >
                    แก้ไขโลโก้
                  </button>
                </article>
                <article>
                  <p>Username</p>
                  <Field className={styles.inputUserName} type="text" name={'profile.username'} disabled />
                </article>
                <br />
                <p className={styles.textTitle}>Password</p>
                <button
                  type="button"
                  className={styles.btnChangePassword}
                  onClick={() => navigate(`/${customerOrTechnician}/forgotpassword`)}
                >
                  เปลี่ยนรหัสผ่าน
                </button>
                <br />
                <br />
                {/* <p className={styles.textTitle}>ผู้ประสานงาน</p> */}
                <p className={styles.textAddress}></p>
                <p className={styles.textAddress}></p>
              </section>
              {/* Section for PAT created by User */}
              <section className={styles.patListWrapper}>
                <h3>PAT ที่สร้างไว้</h3>
                {/* Section for Follow List */}
                {patList.length > 0 &&
                  patList.map((pat, patIndex) => (
                    <div key={patIndex} className={styles.patButton} onClick={() => navigate(`../pat/${pat.patId}`)}>
                      <span style={{ textAlign: 'left' }}>
                        {pat.patId} {pat.name}
                      </span>
                      <button
                        className={cn(styles.button)}
                        onClick={(e) => {
                          e.stopPropagation()
                          dispatch(
                            allAction.modalConfirmAction.setOpenModalConfirm(
                              'ยืนยันการทำรายการ',
                              'คุณต้องการทำซ้ำ PAT Template ใช่หรือไม่',
                              `PAT ID: ${pat.patId} Name: ${pat.name}`,
                              'ใช่, ทำซ้ำ',
                              () => duplicatePAT(pat.patId),
                            ),
                          )
                        }}
                      >
                        <img src={dupIcon} />
                      </button>
                      <button
                        className={cn(styles.button, styles.red)}
                        onClick={(e) => {
                          e.stopPropagation()
                          dispatch(
                            allAction.modalConfirmAction.setOpenModalConfirm(
                              'ยืนยันการทำรายการ',
                              'คุณต้องการลบ PAT Template ใช่หรือไม่',
                              `PAT ID: ${pat.patId} Name: ${pat.name}`,
                              'ใช่, ลบ',
                              () => deletePAT(pat.patId),
                            ),
                          )
                        }}
                      >
                        <img src={binSVG} />
                        ลบ
                      </button>
                    </div>
                  ))}
              </section>
            </div>

            {/* <div>
              <section>
                <h2>Follow List</h2>
                <Field className={styles.inputSearchUser} type="text" name={'inputSearchUser'} />
                {values.profile.friendList && values.profile.friendList.length > 0 && (
                  <ul>
                    {values.profile.friendList.map((friend: any, index: number) => {
                      return (
                        <li key={index}>
                          {friend.userId} {friend.nickname} {friend.displayName}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </section>
            </div> */}
            <ModalUploadLogo userId={values.profile.userId} open={isOpenModalUpload} setOpen={setIsOpenModalUpload} />
          </Form>
        )}
      </div>
    </div>
  )
}

const EnhancedProfilePage = withFormik({
  mapPropsToValues: () => ({
    userName: '',
    inputSearchUser: '',
    uploadLogo: undefined,
  }),
  validate: (values) => {
    const error = {}

    return error
  },
  handleSubmit: (values, { setFieldValue }) => {
    //
    console.log(values)
    console.log(JSON.stringify(values))
  },
})(ProfilePage)

export default EnhancedProfilePage
