import React, { useState } from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { useNavigate, useParams } from 'react-router-dom'

import allAction from 'state/actions'
import uploadlogo from '../../image/uploadlogo.svg'
import filelogo from '../../image/filelogo.svg'
import showIdCard from './showCardId.svg'
import AddIcon from '@mui/icons-material/Add'
import VerifyThirdPerson from './verifyThirdperson'
import { CreateUserVerification, createNotification } from 'adapter/xhr'
import { handleUploadFile } from 'utils/common'

interface Props {
  setVerifystep: any
}
const VerifyThird: React.FC<Props> = (props: Props): JSX.Element => {
  const { userId, username } = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const { customerOrTechnician } = useParams()
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const navigate = useNavigate()
  const [idCardImage, setIdCardImage] = useState<any>('')
  const dispatch = useDispatch()

  return (
    <>
      {values.profileUp && values.profileUp.userType === 'JURISTIC' ? (
        <div className={styles.verifythirdContainer}>
          <section>
            <p className={styles.textTitle}>อัพโหลดไฟล์หนังสือรับรอง </p>
            <input
              id={'companyCertificateFileAttachment'}
              type="file"
              accept=".pdf,.jpeg,.png"
              onChange={(e) => {
                Promise.all(handleUploadFile(e.target.files, `Verification/${userId}/file/`)).then(
                  (companyCertificateFileAttachment: any) => {
                    setFieldValue('companyCertificateFileAttachment', companyCertificateFileAttachment[0])
                  },
                )
              }}
              // multiple
              style={{ display: 'none' }}
            />
            {values.companyCertificateFileAttachment ? (
              <p>
                <label className={styles.labelshowfile} htmlFor="companyCertificateFileAttachment">
                  <img className={styles.filelogo} src={filelogo} alt="filelogo" />
                  <b>{values.companyCertificateFileAttachment}</b>
                </label>
              </p>
            ) : (
              <p className="margin-Bottom15px">
                <label className={styles.labelfile} htmlFor="companyCertificateFileAttachment">
                  <span className={styles.primaryuploadspan}>
                    <img className={styles.uploadlogo} src={uploadlogo} alt="uploadlogo" />
                    Upload File
                  </span>
                  <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
                </label>
              </p>
            )}
          </section>
          <section>
            <p className={styles.textTitle}>อัพโหลดไฟล์ ภพ. 20 </p>
            <input
              id={'vatFileAttachment'}
              type="file"
              accept=".pdf,.jpeg,.png"
              onChange={(e) => {
                Promise.all(handleUploadFile(e.target.files, `Verification/${userId}/file/`)).then(
                  (vatFileAttachment: any) => {
                    setFieldValue('vatFileAttachment', vatFileAttachment[0])
                  },
                )
              }}
              // multiple
              style={{ display: 'none' }}
            />
            {values.vatFileAttachment ? (
              <p>
                <label className={styles.labelshowfile} htmlFor="vatFileAttachment">
                  <img className={styles.filelogo} src={filelogo} alt="filelogo" />
                  <b>{values.vatFileAttachment}</b>
                </label>
              </p>
            ) : (
              <p className="margin-Bottom15px">
                <label className={styles.labelfile} htmlFor="vatFileAttachment">
                  <span className={styles.primaryuploadspan}>
                    <img className={styles.uploadlogo} src={uploadlogo} alt="uploadlogo" />
                    Upload File
                  </span>
                  <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
                </label>
              </p>
            )}
          </section>
          <section className={styles.imageUploadWrapper}>
            <div className={styles.imgCardId}>
              {values.selfiePersonalIDFileAttachment ? (
                <img className={styles.image} src={values.selfiePersonalIDFileAttachment} alt="idcard" />
              ) : (
                <img className={styles.image} src={showIdCard} alt="idcard" />
              )}
              <label htmlFor="selfiePersonalIDFileAttachment" className={styles.upLoadImage}>
                <AddIcon className={styles.addIcon} />
                <input
                  type="file"
                  id={'selfiePersonalIDFileAttachment'}
                  style={{ display: 'none' }}
                  accept=".pdf,.jpeg,.png"
                  onChange={(e) => {
                    Promise.all(handleUploadFile(e.target.files, `Verification/${userId}/images/`)).then(
                      (selfiePersonalIDFileAttachment: any) => {
                        console.log(selfiePersonalIDFileAttachment, 'selfiePersonalIDFileAttachment')
                        setFieldValue('selfiePersonalIDFileAttachment', selfiePersonalIDFileAttachment[0])
                      },
                    )
                  }}
                />
              </label>
            </div>
          </section>
          <section>
            <p className={styles.textTitle}>อัพโหลดไฟล์บัตรประชาชนผู้ประสานงาน </p>
            <input
              id={'coordinatorPersonalIDFileAttachment'}
              type="file"
              accept=".pdf,.jpeg,.png"
              onChange={(e) => {
                Promise.all(handleUploadFile(e.target.files, `Verification/${userId}/file/`)).then(
                  (coordinatorPersonalIDFileAttachment: any) => {
                    setFieldValue('coordinatorPersonalIDFileAttachment', coordinatorPersonalIDFileAttachment[0])
                  },
                )
              }}
              // multiple
              style={{ display: 'none' }}
            />
            {values.coordinatorPersonalIDFileAttachment ? (
              <p>
                <label className={styles.labelshowfile} htmlFor="coordinatorPersonalIDFileAttachment">
                  <img className={styles.filelogo} src={filelogo} alt="filelogo" />
                  <b>{values.coordinatorPersonalIDFileAttachment}</b>
                </label>
              </p>
            ) : (
              <p className="margin-Bottom15px">
                <label className={styles.labelfile} htmlFor="coordinatorPersonalIDFileAttachment">
                  <span className={styles.primaryuploadspan}>
                    <img className={styles.uploadlogo} src={uploadlogo} alt="uploadlogo" />
                    Upload File
                  </span>
                  <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
                </label>
              </p>
            )}
          </section>
          <br />
        </div>
      ) : (
        <div>
          <VerifyThirdPerson />
        </div>
      )}
      <section className={styles.btnFooterWrapper}>
        <button type="button" className={styles.btnCancle} onClick={() => props.setVerifystep('verifysecond')}>
          ยกเลิก
        </button>
        <button
          type="submit"
          className={styles.btnConfirm}
          onClick={() => {
            const UpdateVerificationVariable = {
              input: {
                selfiePersonalIDFileAttachment: values.selfiePersonalIDFileAttachment,
                personalIDFileAttachment: values.personalIDFileAttachment,
                companyCertificateFileAttachment: values.companyCertificateFileAttachment,
                vatFileAttachment: values.vatFileAttachment,
                coordinatorPersonalIDFileAttachment: values.coordinatorPersonalIDFileAttachment,
              },
            }
            // console.log('UpdateVerificationVariable', UpdateVerificationVariable)
            console.log('value ==>', UpdateVerificationVariable)
            if (!values.selfiePersonalIDFileAttachment) {
              dispatch(allAction.modalAction.setOpenModalAlertStatus('กรุณาแนบภาพเพื่อยืนยันตัวตน', '', 'error'))
            } else {
              CreateUserVerification(UpdateVerificationVariable).then((res: any) => {
                console.log('Update  =====>  ', res)
                const CreateNotificationInput = {
                  userId: `admin`,
                  message: `กรุณาตรวจสอบ safety Passport ของ ${userId}`,
                  iconUrl: '',
                  toLink: ``,
                  isRead: false,
                }
                createNotification(CreateNotificationInput)
                  .then(() => {
                    console.log(res, 'res createJobApplication')
                    dispatch(allAction.modalAction.setCloseModalAlertStatus())
                    if (customerOrTechnician === 'technician') {
                      dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันตัวตนเรียบร้อยแล้ว', '', 'success'))
                      navigate(`/${customerOrTechnician}/profileteamlist`)
                    } else {
                      dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันตัวตนเรียบร้อยแล้ว', '', 'success'))
                      navigate(`/${customerOrTechnician}/profilepage`)
                    }
                    // history.goBack()
                  })
                  .catch((err) => {
                    console.log(err, 'err')
                  })
              })
            }
          }}
        >
          ยืนยัน
        </button>
      </section>
    </>
  )
}
export default VerifyThird
