import React, { useState } from 'react'
import styles from './index.module.scss'
import plusimg from './plus.svg'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'

const mockUpQuestion = [
  {
    topic: 'ChangTum ทำงานอย่างไร',
    content:
      'ผู้ใช้ ChangTum (ผู้ใช้ทั่วไป) ลงงานหรือโครงการรับเหมาของตนเอง พร้อมเสนอระบุราคางานนั้นๆ รวมถึงทำการสร้างเอกสาร PAT (เอกสารตรวจรับงาน) ไปยังผู้ใช้ ChangTumMAN (ผู้ให้บริการ) ผู้ให้บริการจะทำการสมัครงานหรือโครงการนั้นๆ ทั้งนี้ผู้ให้บริการจะต้องรอการอนุมัติงานจากผู้ใช้ก่อนถึงทำการเริ่มงานได้ เมื่อผู้ให้บริการเริ่มงานต้องทำการระบุวันนัดหมาย (วันปฏิบัติงาน Plan Date) กับผู้ใช้ อย่างน้อย 1 วันก่อนวันปฏิบัติงานจริง หลังจากเสร็จสิ้นงานผู้ให้บริการ ต้องทำการแจ้ง (วันปฏิบัติงานจริง Actual Date) กับผู้ใช้ เมื่อปฏิบัติงานเสร็จสิ้นสมบูรณ์  ต้องทำการอัพโหลดข้อมูล (รูป,คลิป,เสียง,อื่นๆ) อ้างอิงตามเอกสาร PAT นั้น แล้วผู้ใช้จะมาทำการตรวจสอบเอกสาร PAT และทำการรีวิวให้คะแนน',
  },
  {
    topic: 'ถ้าฉันมีปัญหาในการใช้แพลตฟอร์ม ChangTum',
    content:
      'พวกเรามีทีมสนับสนุนสำหรับปัญหาด้านเทคนิค โปรดติดต่อเราทางอีเมล (ChungTum@gmail.com) หรือโทรศัพท์ (02-549-5318 - 9, 089 8136529)',
  },
  {
    topic: 'อะไรคือสิ่งจำเป็นในการใช้แพลตฟอร์ม ChangTum',
    numbercontent: [
      {
        content: 'คอมพิวเตอร์/ สมาร์ทโฟนและการเชื่อมต่ออินเทอร์เน็ตที่จำเป็นสำหรับผู้ใช้ทั่วไป',
      },
      {
        content: 'รูปถ่ายประจำตัวหรือการยืนยันตัวตนสำหรับผู้ค้า / ผู้ให้บริการ',
      },
      {
        content: 'การตรวจสอบเอกสาร PAT และการวางแผนการทำงาน',
      },
    ],
  },
  {
    topic: 'เอกสาร PAT คืออะไร แล้วมันจำเป็นอย่างไร',
    content: `เอกสาร PAT คือ Pre-Acceptance Test เป็นเอกสารที่เอาไว้ตรวจสอบการทำงาน โดยผู้ให้บริการต้องทำการอัพโหลดข้อมูล 
    (รูป,คลิป,เสียง,อื่นๆ) ก่อนทำและหลังทำ เพื่อเป็นการส่งมอบงานให้กับทางผู้ใช้ ซึ่งเมื่อผู้ใช้อนุมัติ เอกสาร PAT 
    ให้กับผู้ให้บริการแล้ว ถือว่างานหรือโครงการนั้นเสร็จสิ้นสมบูรณ์ ผู้ให้บริการจะได้รับค่าบริการนั้น`,
  },
  {
    topic: 'ฉันจะเริ่มสร้างเอกสาร PAT ได้อย่างไร',
    content: `ผู้ใช้ สารมารถสร้างเอกสาร PAT ได้ โดยเข้าไปที่เมนู “งาน” โดยเอกสาร PAT สามารถสร้างได้ที่ขั้นตอนที่ 2 ทั้งนี้เอกสาร PAT 
    มีรูปแบบให้เลือก อาทิ เทมเพลต หรือสร้างใหม่ (ผู้ใช้สร้างใหม่ตามที่ต้องการ)`,
  },
  {
    topic: 'ตรวจสอบเอกสาร PAT จำเป็นแค่ไหน',
    content: `ผู้ใช้ สารมารถสร้างเอกสาร PAT ได้ โดยเข้าไปที่เมนู “งาน” โดยเอกสาร PAT สามารถสร้างได้ที่ขั้นตอนที่ 2 ทั้งนี้เอกสาร PAT 
    มีรูปแบบให้เลือก อาทิ เทมเพลต หรือสร้างใหม่ (ผู้ใช้สร้างใหม่ตามที่ต้องการ)`,
  },
  {
    topic: 'วิธีการอัพโหลดข้อมูลลงเอกสาร PAT',
    content: `ผู้ให้บริการสามารถอัพโหลดข้อมูล (รูป,คลิป,เสียง,อื่นๆ) ได้ในเมนู “บริหารโครงการ” เข้าไปแถบหัวข้อ “ลงงาน/อัพโหลดรูป” 
    แล้วคลิกเลือก “อัพโหลดไฟล์รูปที่นี่” เพื่อทำเข้าไปในเอกสารไฟล์ PAT และทำการอัพโหลดข้อมูล (รูป,คลิป,เสียง,อื่นๆ) 
    ตามรายละเอียด PAT เมื่อเสร็จอัพโหลดเรียบร้อย ผู้ให้บริการสามรถกดปุ่ม “ส่งงาน” 
    ถือว่าผู้ให้บริการจัดส่งงานแก่ผู้ใช้เรียบร้อย หลังจากนี้ เอกสาร PAT ก็เข้าสู่กระบวนการรอผู้ใช้ทำการตรวจสอบ`,
  },
  {
    topic: 'ความหมายสถานะของเอกสาร PAT ',
    numbercontent: [
      {
        content: '“รอการตรวจสอบ” เมื่อผู้ให้บริการป้อนงานรูปเรียบร้อย และรอผู้ใช้ทำการตรวจสอบ',
      },
      {
        content: '“รอการดำเนินการ” ผู้ให้บริการยังป้อนงานรูปไม่เสร็จ',
      },
      {
        content:
          '“รอการแก้ไข” กรณีที่ผู้ใช้ ตรวจสอบรูปในไฟล์ PAT แล้วกดให้ “ไม่ผ่าน” ทางผู้รับผิดชอบ [ผู้ให้บริการ] ต้องทำการแก้ไขงานและอัพโหลดข้อมูล  (รูป,คลิป,เสียง,อื่นๆ) อีกรอบ',
      },
      {
        content: '“ผ่านการตรวจสอบ ”  ผู้ใช้ทำการตรวจสอบเอกสาร PAT และให้สถานะว่า “ผ่าน”',
      },
    ],
  },
]

const Homepage: React.FC = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  return (
    <div className={`wrapper2 ${styles.homeContainer}`}>
      <h1 className={styles.homeTopic}>ยินดีต้อนรับเข้าสู่ ChangTum</h1>
      <section className={styles.homeContent}>
        <h1 className={styles.homeContentTopic}>คำถามที่ถามบ่อย</h1>
        <section className={styles.homeItem}>
          {mockUpQuestion.map((questionEle: any, questionIndex: number) => {
            return (
              <section key={questionIndex} className={styles.homeSubItem}>
                <article
                  onClick={() => {
                    setFieldValue(`dropdownStatus[${questionIndex}]`, !values.dropdownStatus[questionIndex])

                    // console.log(dropdownStatus, 'questionIndex')
                  }}
                  className={styles.homeSubItemTopicGroup}
                >
                  <img className={styles.plusImg} src={plusimg} alt="plusimg"></img>
                  <u className={styles.homeSubItemTopic}>{questionEle.topic}</u>
                </article>
                {questionEle.numbercontent !== undefined ? (
                  questionEle.numbercontent.map((numbercontentEle: any, numbercontentIndex: number) => {
                    return (
                      <p
                        key={numbercontentIndex}
                        className={styles.homeSubItemNumberContent}
                        style={values.dropdownStatus[questionIndex] === true ? { height: '100%' } : { display: 'none' }}
                      >
                        <span className={styles.homeSubItemNumberItem}>{numbercontentIndex + 1 + '. '}</span>
                        <span>{numbercontentEle.content}</span>
                      </p>
                    )
                  })
                ) : (
                  <p
                    className={styles.homeSubItemContent}
                    style={values.dropdownStatus[questionIndex] === true ? { height: '100%' } : { display: 'none' }}
                  >
                    {questionEle.content}
                  </p>
                )}
              </section>
            )
          })}
        </section>
      </section>
    </div>
  )
}

const EnhancedJHomepage = withFormik({
  mapPropsToValues: () => ({
    dropdownStatus: [false, false, false, false, false, false, false, false],
    dropdownIndex: false,
  }),
  validate: (values) => {
    // const error = {}
    // return error
  },
  handleSubmit: (values, { setFieldValue }) => {
    // //
    // console.log(values)
    // console.log(JSON.stringify(values))
  },
})(Homepage)

export default EnhancedJHomepage
