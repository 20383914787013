import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './index.module.scss'
import { unpackProject } from 'adapter/xhr'
import { getProjectsByUserIdForPost } from 'adapter/createPostPageAdapter'
import {
  IProject,
  IWorkType,
  ICustomFields,
  createPost,
  getPost,
  createJobApplication,
  getTeam,
  createNotification,
} from 'adapter/xhr'
import { useFormikContext, withFormik, Form, Field } from 'formik'
import { useSelector } from 'react-redux'
import { TextField, DetailsList, IColumn, SelectionMode } from '@fluentui/react'
import TableDropDown from 'components/dropdowntable'
import ImagesGroup from 'components/imagesGroup'
import ImagesSlideShow from 'components/imagesSlideShow'
import { ICombineReducers } from 'state/reducers'

import { CreatePostTopic, ModalShowCreatePost } from './createPost'
import { ApplyJobTopic, packPostFromPost, ModalSelectTeamMember } from './applyJob'

import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { sumQty } from 'utils/common'
import { THAILAND_PROVINCE, THAILAND_REGION } from 'components/commons/thailandFact'

const jobSelectionColumns: IColumn[] = [
  {
    key: 'column1',
    name: 'ID ช่างทำ',
    fieldName: 'changTumId',
    minWidth: 70,
    maxWidth: 70,
    isResizable: false,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column2',
    name: 'Region',
    fieldName: 'region',
    minWidth: 50,
    maxWidth: 50,
    isResizable: false,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column3',
    name: 'Province',
    fieldName: 'province',
    minWidth: 50,
    maxWidth: 200,
    isResizable: true,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column4',
    name: 'Site Code',
    fieldName: 'siteCode',
    minWidth: 50,
    maxWidth: 200,
    isResizable: true,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column5',
    name: 'Site Name',
    fieldName: 'siteName',
    minWidth: 80,
    maxWidth: 200,
    isResizable: true,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column6',
    name: 'Status',
    fieldName: 'status',
    minWidth: 40,
    maxWidth: 40,
    isResizable: false,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column7',
    name: 'มี PAT',
    fieldName: 'isPatGenerated',
    minWidth: 40,
    maxWidth: 40,
    isResizable: false,
    isFiltered: true,
    isSorted: true,
  },
]

const CreatePostPage: React.FC = () => {
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormikContext<any>()
  const [isShowExamPleModal, setIsShowExampleModal] = useState<boolean>(false)
  const [isShowModalSelectTeam, setIsShowModalSelectTeam] = useState<boolean>(false)
  const imageFileRef = useRef<any>()
  const [itemsFilter, setItemsFilter] = useState<string>('')
  const [itemsToShow, setItemsToShow] = useState<any[]>([])

  const { projectID, postID } = useParams()

  const tableHeader = ['ทั้งหมด', 'คงเหลือ', 'สมัคร', 'อนุมัติ']

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const pageType = location.pathname.split('/')[2]

  const { userId, username } = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)

  useEffect(() => {
    if (pageType === 'createPostPage') {
      getProjectsByUserIdForPost(projectID!).then((project: any) => {
        const unpackedProject: IProject = unpackProject(project)
        const post: any = {
          projectID: unpackedProject.projectId,
          createdByUserID: userId,
          title: unpackedProject.projectName,
          description: unpackedProject.projectDescription,
          startDate: unpackedProject.startDate,
          endDate: unpackedProject.endDate,
          imageURLList: [],
          fileAttachmentList: [],
          workTypePostList: [],
        }
        if (unpackedProject.TORFile) {
          post.fileAttachmentList.push(unpackedProject.TORFile)
        }
        const backUpJobList: any[] = []
        unpackedProject.workTypeList.map((workType: IWorkType) => {
          const newWorkType = {
            workType: workType.workType,
            isLinked: false,
            fieldID: '',
            // jobList: workType.jobList,
            jobList: [],
            totalJobList: [
              // {
              //   fieldValue: '',
              //   totalQty: 0,
              //   balanceQty: 0,
              // },
            ],
            imageURLList: workType.imageURLList,
            jobUnitPrice: workType.jobUnitPrice,
          }
          backUpJobList.push(workType.jobList)
          post.workTypePostList.push(newWorkType)
        })
        setFieldValue('projectName', unpackedProject.projectName)
        setFieldValue('backUpJobList', backUpJobList)
        const customOptionField: any = []
        if (unpackedProject.customFieldList) {
          unpackedProject.customFieldList.map((customField: ICustomFields) => {
            customOptionField.push({ text: customField.name, value: customField.name })
          })
          setFieldValue('fieldList', [...values.fieldList, ...unpackedProject.customFieldList])
        }
        setFieldValue('post', post)
        setFieldValue('options', [...values.options, ...customOptionField])
      })
    } else if (pageType === 'applyJob') {
      getPost({ postId: postID }).then((res: any) => {
        // const backUpJobList: any[] = []
        // res.data.data.post.workTypePostList.map((workType: IWorkType) => {
        //   backUpJobList.push(workType.jobList)
        // })
        const post = res.data.data.post
        setFieldValue('projectName', post.project.projectName)
        const packedPost = packPostFromPost(post)
        console.log(packedPost, 'packedPost')
        const customOptionField: any = []
        packedPost.workTypePostList.map((workTypePost: any) => {
          console.log(workTypePost, 'workTypePost')
          customOptionField.push({ text: workTypePost.fieldID, value: workTypePost.fieldID })
        })
        setFieldValue('options', [...values.options, ...customOptionField])
        setFieldValue('post', packedPost)
      })
      getTeam().then((res: any) => {
        console.log(res.data.data, 'get team response')
        const packTeamList = res.data.data.getUser.technicianList.map((technicianElement: any) => {
          console.log(technicianElement.getUser, 'newtechnicianElement')
          const newtechnicianElement = technicianElement.getUser
          newtechnicianElement.technicianJobType
            ? (newtechnicianElement.technicianJobType = technicianElement.getUser.technicianJobType.map(
                (technicianJob: any) => technicianJob,
              ))
            : // `${technicianElement.getUser.technicianJobType.map(
              //   (technicianJob: any) => technicianJob,
              // )}`
              ''
          newtechnicianElement.safetyPassport
            ? (newtechnicianElement.safetyPassport = `${technicianElement.getUser.safetyPassport.map(
                (safetyPassport: any) => safetyPassport.safetyPassportType,
              )}`)
            : ''
          newtechnicianElement.status = technicianElement.getUser.status
          return newtechnicianElement
        })
        console.log(packTeamList, 'packTeamList')
        setFieldValue('technicianList', packTeamList)
      })
    }
  }, [])

  return (
    <Form>
      <div className={`${styles.createPostContainer} wrapper2`}>
        {pageType === 'createPostPage' ? (
          <CreatePostTopic
            setIsShowExampleModal={setIsShowExampleModal}
            projectID={projectID}
            imageFileRef={imageFileRef}
          />
        ) : (
          <ApplyJobTopic />
        )}
        {values.post.workTypePostList &&
          values.post.workTypePostList.map((workType: any, workTypeIndex: number) => {
            return (
              <section className={styles.content} key={workTypeIndex}>
                <article className={styles.contentTopic}>
                  <div className={styles.contentTopicLeftSide}>
                    <h1 className={styles.contentTopicText}>
                      {/* ประเภทงานที่ 1 : งาน POE+Hex  */}
                      {`${workType.workType}`}
                    </h1>
                    <label className={styles.checkboxContainer}>
                      <Field
                        disabled={
                          (values.backUpJobList[workTypeIndex] && values.backUpJobList[workTypeIndex].length === 0) ||
                          pageType !== 'createPostPage'
                        }
                        className={styles.linkJobCheckbox}
                        type="checkbox"
                        name={`post.workTypePostList[${workTypeIndex}].isLinked`}
                      />
                      Link Job
                    </label>
                  </div>
                  <h1 className={styles.contentTopicRightSide}>
                    {workType.jobUnitPrice ? workType.jobUnitPrice : 'ไม่ระบุจำนวนเงิน'} บาท
                  </h1>
                </article>
                <article className={styles.contentTableAndImg}>
                  <div className={styles.tableLeftSide}>
                    <TableDropDown
                      tableTopic={
                        pageType === 'createPostPage' ? username : values.post.user && values.post.user.username
                      }
                      tableHeader={tableHeader}
                      workType={workType}
                      // tableIndex={workTypeIndex}
                      tableOption={values.options}
                      tableRowList={values.rowList}
                      // tablePostName={`post`}
                      totalJobListFieldName={`post.workTypePostList[${workTypeIndex}]`}
                      tableTotal={workType.totalJobList.length !== 0 ? sumQty(workType.totalJobList, 'totalQty') : 0}
                      tableBalance={
                        workType.totalJobList.length !== 0 ? sumQty(workType.totalJobList, 'balanceQty') : 0
                      }
                      tableRegister={
                        workType.totalJobList.length !== 0 ? sumQty(workType.totalJobList, 'registerQty') : 0
                      }
                      tableApprove={
                        workType.totalJobList.length !== 0 ? sumQty(workType.totalJobList, 'approveQty') : 0
                      }
                      isDisabledDropdown={pageType !== 'createPostPage'}
                      isDisabledTotalQty={pageType !== 'createPostPage'}
                      isDisabledBalanceQty={true}
                      isDisabledRegistryQty={pageType !== 'applyJob'}
                      isDisabledApproveQty={pageType !== 'approve'}
                    />
                  </div>
                  <div className={styles.tableRightSide}>
                    <ImagesGroup
                      image1={workType.imageURLList ? workType.imageURLList[0] : ''}
                      image2={workType.imageURLList ? workType.imageURLList[1] : ''}
                      image3={workType.imageURLList ? workType.imageURLList[2] : ''}
                      image4={workType.imageURLList ? workType.imageURLList[3] : ''}
                    />
                  </div>
                </article>
                <article className={styles.footerTableContainer}>
                  <TextField
                    label="Filter by ChangtumId / JobId / SiteCode / SiteName:"
                    onChange={(
                      ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                      text: string | undefined,
                    ): void => {
                      if (text) setItemsFilter(text)
                    }}
                  />
                  <DetailsList
                    compact={true}
                    items={itemsToShow}
                    columns={jobSelectionColumns}
                    // selection={jobSelection as unknown as ISelection<IObjectWithKey>}
                    selectionMode={SelectionMode.multiple}
                    // getKey={getChangTumIdKey}
                    setKey="multiple"
                    isHeaderVisible={true}
                    selectionPreservedOnEmptyClick={true}
                  />
                </article>
              </section>
            )
          })}

        <section className={styles.submitBtnGroup}>
          <button type="button" onClick={() => navigate(-1)} className={styles.cancelBtn}>
            ยกเลิก
          </button>
          <button
            type="button"
            onClick={() => {
              if (pageType === 'createPostPage') {
                dispatch(
                  allAction.modalConfirmAction.setOpenModalConfirm(
                    'ยืนยันการทำรายการ',
                    'คุณต้องการประกาศงาน ใช่หรือไม่',
                    `${values.post.workTypePostList.map((workType: any, workTypeIndex: number) => {
                      return `ประเภทงานที่ ${workTypeIndex + 1} จำนวน ${
                        workType.totalJobList.length !== 0 ? sumQty(workType.totalJobList, 'totalQty') : 0
                      } งาน`
                    })}`,
                    'ใช่, ทำรายการ',
                    () => {
                      const clonePost = { input: values.post }
                      clonePost.input.workTypePostList.map((workType: any) => {
                        delete workType.imageURLList
                        delete workType.jobUnitPrice
                        workType.totalJobList.map((totalJob: any) => {
                          if (isNaN(parseInt(totalJob.totalQty))) {
                            totalJob.totalQty = 0
                          }
                          if (isNaN(parseInt(totalJob.balanceQty))) {
                            totalJob.balanceQty = 0
                          }
                          delete totalJob.registerQty
                          delete totalJob.approveQty
                        })
                      })
                      console.log(clonePost, 'clonePost')
                      createPost(clonePost).then((res: any) => {
                        console.log(res, 'resssss')
                        // const CreateNotificationInput = {
                        //   userId: `${userId}`,
                        //   message: ` <span style="color: 'red;'">${username}</span> ได้ทำการประกาศ ${values.post.title} ของ ${values.projectName} คุณสามารถสมัครงานได้แล้ววันนี้`,
                        //   iconUrl: '',
                        //   toLink: `/technician/applyJob/${res.data.data.createPost.postID}`,
                        //   isRead: false,
                        // }
                        // createNotification(CreateNotificationInput)
                        //   .then(() => {})
                        //   .catch((err) => {
                        //     console.log(err, 'err')
                        //   })
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus('ยืนยันการประกาศงานเรียบร้อย', '', 'success'),
                        )
                        console.log(dispatch, 'DISPATCHHHHHHHHHHHHHHHHHH')
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        console.log(res, 'resssssssssssssssssssssssssssssssssssssssssssss')
                        console.log(values, 'valueeeeeeeeeee')
                        navigate(-1)
                      })
                    },
                  ),
                )
              } else if (pageType === 'applyJob') {
                setIsShowModalSelectTeam(true)
              }
            }}
            className={styles.submitBtn}
          >
            {pageType === 'createPostPage' ? 'ลงประกาศ' : 'สมัคร'}
          </button>
        </section>
        <ModalShowCreatePost
          isShowExamPleModal={isShowExamPleModal}
          setIsShowExampleModal={setIsShowExampleModal}
          username={username}
        />
        <ModalSelectTeamMember
          isShowModalSelectTeam={isShowModalSelectTeam}
          setIsShowModalSelectTeam={setIsShowModalSelectTeam}
          confirmOnClick={() => {
            setIsShowModalSelectTeam(false)
            dispatch(
              allAction.modalConfirmAction.setOpenModalConfirm(
                'ยืนยันการทำรายการ',
                'คุณต้องการสมัครงาน ใช่หรือไม่',
                '',
                'ใช่, ทำรายการ',
                () => {
                  const clonePost = values.post
                  console.log(values, 'values .......')
                  const packWorkType = clonePost.workTypePostList.map((workType: any) => {
                    delete workType.imageURLList
                    delete workType.isLinked
                    delete workType.jobList
                    delete workType.jobUnitPrice
                    workType.totalJobList.map((job: any) => {
                      delete job.totalQty
                      delete job.balanceQty
                      delete job.approveQty
                    })
                    return workType
                  })
                  const packedTechnicianList = values.selectedTechnicianList.map((technicianElement: string) => {
                    return { userId: technicianElement }
                  })
                  const packInput = {
                    input: {
                      postID: clonePost.postID,
                      workTypeJobApplicationList: packWorkType,
                      technicianList: packedTechnicianList,
                    },
                  }
                  console.log(packInput, 'packInput')
                  createJobApplication(packInput).then((res: any) => {
                    const CreateNotificationInput = {
                      userId: `${values.post.createdByUserID}`,
                      message: ` ชื่อ ${username} ได้ทำการสมัคร ชื่อ ${values.post.title} ของ ${
                        values.projectName
                      } เป็นงานทั้งหมด ${
                        values.post.workTypePostList &&
                        values.post.workTypePostList
                          .map((workType: any) => {
                            return workType.totalJobList.length !== 0 ? sumQty(workType.totalJobList, 'registerQty') : 0
                          })
                          .reduce((prev: number, curr: number) => prev + curr)
                      }  คุณสามารถคัดเลือกได้แล้ว`,
                      iconUrl: '',
                      toLink: `/customer/acceptJob/${res.data.data.createJobApplication.postID}`,
                      isRead: false,
                    }
                    createNotification(CreateNotificationInput)
                      .then(() => {
                        console.log(res, 'res createJobApplication')
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus('ยืนยันการสมัครงานเรียบร้อย', '', 'success'),
                        )
                        // allAction.modalConfirmAction.setCloseModalConfirm
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        navigate(-1)
                      })
                      .catch((err) => {
                        console.log(err, 'err')
                      })
                  })
                },
              ),
            )
          }}
        />
      </div>
    </Form>
  )
}

const EnhancedCreatePostPage = withFormik({
  mapPropsToValues: () => ({
    post: {
      projectID: '',
      createdByUserID: '',
      title: '',
      description: '',
      startDate: '',
      endDate: '',
      imageURLList: [''],
      fileAttachmentList: [''],
      workTypePostList: [
        {
          workType: '',
          isLinked: false,
          fieldID: '',
          jobList: [''],
          imageURLList: [''],
          jobUnitPrice: 0,
          totalJobList: [
            {
              fieldValue: '',
              totalQty: 0,
              balanceQty: 0,
            },
          ],
        },
      ],
    },
    projectName: '',
    backUpJobList: [[]],
    isModalOpen: false,
    options: [
      { value: '', text: '' },
      // {
      //   value: 'Group',
      //   text: 'Group',
      // },
      {
        value: 'province',
        text: 'จังหวัด',
      },
      {
        value: 'region',
        text: 'ภูมิภาค',
      },
    ],
    fieldList: [
      {
        name: 'region',
        allowedValueList: THAILAND_REGION,
      },
      {
        name: 'province',
        allowedValueList: THAILAND_PROVINCE,
      },
    ],
    total: 300,
    searchName: '',
    isHaveSafety: 'false',
    selectedSafetyPassport: [],
    safetyPassport: [
      {
        value: 'AIS_G',
        text: 'AIS [G]',
      },
      {
        value: 'AIS_E',
        text: 'AIS [E]',
      },
      {
        value: 'AIS_EE',
        text: 'AIS [EE]',
      },
      {
        value: 'AIS_H',
        text: 'AIS [H]',
      },
      {
        value: 'AIS_C',
        text: 'AIS [C]',
      },
    ],
    selectedTechnicianWorkType: [],
    technicianWorkType: [
      {
        value: 'Electrician',
        text: 'ช่างไฟฟ้า',
      },
      {
        value: 'Painter',
        text: 'ช่างสี',
      },
      {
        value: 'Plumber',
        text: 'ช่างประปา',
      },
      {
        value: 'Plasterer',
        text: 'ช่างปูน',
      },
      {
        value: 'Metalworker',
        text: 'ช่างเหล็ก',
      },
      {
        value: 'Carpenter',
        text: 'ช่างไม้',
      },
      {
        value: 'Mechanic',
        text: 'ช่างยนต์',
      },
      {
        value: 'Installer',
        text: 'ช่างติดตั้ง',
      },
    ],
    technicianList: [],
    selectedTechnicianList: [],
  }),
  validate: (values) => {
    const error = {}

    return error
  },
  handleSubmit: (values, { setFieldValue }) => {
    console.log(values, 'valuesssss')
    // console.log(pageType, 'pageType')
    // if (pageType === 'createPostPage') {
    //   const clonePost = { input: values.post }
    //   clonePost.input.workTypePostList.map((workType: any) => {
    //     delete workType.imageURLList
    //     delete workType.jobUnitPrice
    //     workType.totalJobList.map((totalJob: any) => {
    //       delete totalJob.registerQty
    //       delete totalJob.approveQty
    //     })
    //   })
    //   createPost(clonePost).then((res: any) => {
    //     console.log(res, 'resssss')
    //   })
    //   console.log(clonePost, 'clonePost')
    // }
  },
})(CreatePostPage)

export default EnhancedCreatePostPage
