import React from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useNavigate } from 'react-router-dom'

// interface Props {
//   setCreatTeamstep?: any
//   open: boolean
//   setOpen?: any
// }
const ModalSkip: React.FC<any> = (props: any): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const [verifystep, setVerifystep] = React.useState('verifyfrist')

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalVerifyAlert}>
        <div className={styles.card}>
          <section className={styles.textGroup}>
            <p className={styles.texthead}>กรุณายืนยันตัวตน</p>
            <p className={styles.text}>คุณต้องการยืนยันตัวตนเพื่อเริ่มงานเลย ใช่หรือไม่ ?</p>
          </section>

          <div className={styles.bottom}>
            <section className={styles.btnForm}>
              {' '}
              <div className={styles.btntwin}>
                {' '}
                <button
                  type="button"
                  className={styles.btCancle}
                  onClick={() => {
                    props.setOpen(false)
                  }}
                >
                  ยกเลิก
                </button>{' '}
                <button
                  type="button"
                  className={styles.btSkip}
                  onClick={() => {
                    props.setOpen(false)
                    dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        'กรุณากลับมายืนยันตัวตนเพื่อเริ่มงาน',
                        '',
                        'success',
                      ),
                    )
                    navigate('/technician/profileteamlist')
                  }}
                >
                  ข้าม
                </button>{' '}
              </div>
              <button type="button" className={styles.btVerify} onClick={() => props.setStep()}>
                ใช่,Verify
              </button>
            </section>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalSkip
