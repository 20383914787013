import React, { useState, useEffect } from 'react'
// import styles from './index.module.scss'
import { useFormikContext } from 'formik'

import PlanActualDashboard from 'components/PlanActualDashboard'

const FirstContent: React.FC<any> = (props: any) => {
  const { values } = useFormikContext<any>()

  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    console.log('THIS IS VALUES UNPACK')
    console.log(values.unpackproject)
  }, [values.unpackproject])

  // Only Filter the ones with jobLists
  const filterWorkType = (workTypeElement: any) => workTypeElement.jobList.length > 0

  return (
    <>
      {values.unpackproject && (
        <PlanActualDashboard
          data={values.unpackproject.workTypeList.filter(filterWorkType)[page]}
          showToolbar
          year={props.year}
          setYear={props.setYear}
          tableId="planActualDashboard"
        />
      )}
      {/* Pagination Section */}
      {/* {values.unpackproject && (
        <section className={styles.paginationWrapper}>
          {[...Array(values.unpackproject.workTypeList.filter(filterWorkType).length).keys()].map((pageNumber) => {
            return (
              <button
                className={`${styles.paginationButton} ${page === pageNumber ? styles.paginationSelected : ''}`}
                key={pageNumber}
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  setPage(pageNumber)
                }}
              >
                {pageNumber + 1}
              </button>
            )
          })}
        </section>
      )} */}
    </>
  )
}

export default FirstContent
