import React, { useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import Footercomponent from 'components/footercomponent'

//first section
import technicianindexpic from './technicianindexpic.svg'

//second section
import jobpic from './job.svg'
import managepic from './manage.svg'
import technicianpic from './technician.svg'

const mockUpLeftSideContent = [
  {
    pic: jobpic,
    text: 'รับงานช่างทำ',
  },
  {
    pic: managepic,
    text: 'บริหารโครงการ',
  },
  {
    pic: technicianpic,
    text: 'โปรโมทงานของฉัน',
  },
]

//third section
import graphpic from './graph.svg'
import menupic from './pointingmenu.svg'
import holdingwrench from './holdingwrench.svg'
import weldingpic from './welding.svg'
import engineerpic from './engineer.svg'
import { withFormik } from 'formik'
// import useRedirectOnLanding from 'hook/useRedirectOnLanding' NOT USE ANYMORE

const mockUpthirdIndexContent = [
  {
    pic: graphpic,
    text: 'โอกาสการรับงานที่เพิ่มขึ้น และรายได้ที่มากขึ้น ',
  },
  {
    pic: menupic,
    text: 'ผู้ช่วยช่างในการบริหารโครงการ',
  },
  {
    pic: holdingwrench,
    text: 'ผู้ช่วยช่างในการคัดสรรงานที่ต้องการ',
  },
]

//fourth section

//fifth section

const TechnicianLandingPage: React.FC = () => {
  // useRedirectOnLanding()
  const [leftSideElementStatus, setleftSideElementStatus] = useState(0)
  return (
    <div className={`wrapper2 ${styles.indexBlock}`}>
      <section className={styles.firstIndexBox}>
        <img className={styles.firstIndexImg} src={technicianindexpic} alt="indexpic" />
        <div className={styles.frontGround}></div>
        <article className={styles.firstIndexContent}>
          <p className={styles.firstIndexText}>
            “ผูัช่วยช่างหรือผู้รับเหมา ในการบริหารโครงการ และส่งมอบงาน (PAT) ได้อย่างสะดวกมากขึ้น”
          </p>
          <Link to="/technician/login">
            <button className={styles.inputBtn} type="button">
              เริ่มบริหารโครงการ {'>'}
            </button>
          </Link>
        </article>
      </section>
      <section className={styles.secondIndexBox}>
        <article className={styles.secondIndexBoxTopicBox}>
          <h1 className={styles.secondIndexBoxTopic}>Feature ของช่างทำ</h1>
        </article>
        <article className={styles.secondIndexBoxContent}>
          <div className={styles.secondIndexBoxLeftSideContent}>
            {mockUpLeftSideContent.map((leftSideElement: any, leftSideIndex: number) => {
              return (
                <div
                  className={` ${styles.secondIndexBoxLeftSideItems} ${
                    leftSideIndex === leftSideElementStatus ? styles.secondIndexBoxLeftSideItemsSelected : ''
                  }`}
                  key={leftSideIndex}
                  onClick={() => {
                    setleftSideElementStatus(leftSideIndex)
                  }}
                >
                  <img className={styles.leftSideImg} src={leftSideElement.pic} alt="" />
                  <p className={styles.leftSidetext}>{leftSideElement.text}</p>
                </div>
              )
            })}
          </div>
          <div className={styles.secondIndexBoxRightSideContent}>
            <img
              className={styles.rightSideImg}
              src={leftSideElementStatus === 0 ? jobpic : leftSideElementStatus === 1 ? managepic : technicianpic}
              alt=""
            />
            <h1 className={styles.rightSidetopic}>
              {leftSideElementStatus === 0
                ? 'รับงานช่างทำ'
                : leftSideElementStatus === 1
                ? 'บริหารโครงการ'
                : 'โปรโมทงานของฉัน'}
            </h1>
            <p className={styles.rightSideText}>
              {leftSideElementStatus === 0
                ? 'ช่างสามารถหางานได้จาก Feature อาทิ งานรับเหมาโครงการ, งานซ่อมบำรุง'
                : leftSideElementStatus === 1
                ? 'ช่างสามารถบริหารงาน/โครงการ ที่ตัวเองรับผิดชอบได้'
                : 'ช่างสามารถหางานได้จาก Feature อาทิ งานรับเหมาโครงการ, งานซ่อมบำรุง'}
            </p>
          </div>
        </article>
      </section>
      <section className={styles.thirdIndexBox}>
        <article className={styles.thirdIndexContent}>
          <div className={styles.thirdIndexTopicGroup}>
            <h1 className={styles.thirdIndexTopic}>เป็นช่างกับ ChangTum</h1>
            <p className={styles.thirdIndexSubTopic}>ช่างทำพวกเราคำนึงถึงคุณภาพการทำงานของช่างเป็นอันดับหนึ่ง</p>
          </div>
          {mockUpthirdIndexContent.map((thirdIndexElement: any, thirdIndexIndex: number) => {
            return (
              <div key={thirdIndexIndex} className={styles.thirdIndexItems}>
                <img className={styles.ItemsImg} src={thirdIndexElement.pic} alt="" />
                <p className={styles.Itemstext}>{thirdIndexElement.text}</p>
              </div>
            )
          })}
          <img className={styles.weldingpic} src={weldingpic} alt="weldingpic" />
          <Link className={`${styles.registerBtn} ${styles.inputBtn}`} to="/technician/login">
            สมัครเข้าร่วมช่างทำ
          </Link>
        </article>

        <img className={styles.engineerpic} src={engineerpic} alt="engineerpic" />
      </section>
      <section className={styles.fourthIndexBox}>
        <div className={styles.fourthIndexLeftSideContent}>
          <h2>ขั้นตอนการสมัครใช้บริการ</h2>
          <p>สมัครเข้าสู่ระบบเพื่อใช้บริการกับ ChangTum ง่ายๆ เพียงทำตามขั้นตอนดังนี้</p>
        </div>
        <div className={styles.fourthIndexRightSideContent}>
          <article className={styles.fourthIndexRightSideItems}>
            <p className={styles.fourthIndexIndex}>1.</p>
            <p>กดเข้าสู่ www.ช่างทำ.com หรือ </p>
            <Link to="/technician/login" style={{ color: 'black', textDecoration: 'underline' }}>
              คลิกที่นี่
            </Link>
          </article>
          <article className={styles.fourthIndexRightSideItems}>
            <p className={styles.fourthIndexIndex}>2.</p>
            <section>
              <p style={{ fontSize: '18px' }}>สมัครและลงทะเบียนพร้อม</p>
              <p>กรอกข้อมูลพร้อมอัปโหลดเอกสารให้ครบถ้วน เพื่อลงทะเบียน</p>
            </section>
          </article>
          <article className={styles.fourthIndexRightSideItems} style={{ alignItems: 'flex-start' }}>
            <p className={styles.fourthIndexIndex}>3.</p>
            <section>
              <p style={{ fontSize: '18px' }}>อบรมการใช้งานแพลตฟอร์ม และส่งเอกสารตัวจริง</p>
              <p>
                สมัครอบรมการใช้งาน และเข้าร่วมการอบรมตามวันเวลาที่ลงทะเบียนไว้ สถานที่ 88 หมู่ 18 ถ. ลำลูกกา ต.
                บึงทองหลาง อ.ลำลูกกา ปทุมธานี 12150 โทรศัพท์: 02-549-5318 - 9, 089 8136529
                พร้อมกับเตรียมเอกสารตัวจริงมาส่งก่อนเข้าร่วมอบรม (สามารถส่งเอกสารตัวจริงทางไปรษณีย์ได้ตามที่อยู่ด้านบน)
              </p>
            </section>
          </article>
          <article className={styles.fourthIndexRightSideItems}>
            <p className={styles.fourthIndexIndex}>4.</p>
            <section>
              <p style={{ fontSize: '18px' }}>เปิดการใช้งาน เริ่มวางแผนงานได้ทันที</p>
              <p>รอระบบอนุมัติ 7 วันทำการหลังจากส่งเอกสารครบถ้วน</p>
            </section>
          </article>
        </div>
      </section>
      <section className={styles.fifthIndexBox}>
        <h1 className={styles.fifthIndexTopic}>พร้อมรับสิทธิประโยชน์มากขึ้น</h1>
        <p className={styles.fifthIndexSubtopic}>
          เพิ่มงาน เพิ่มรายได้ พร้อมรับสิทธิประโยชน์ต่างๆ สมัครเข้าร่วมกับ ChangTum ได้แล้ววันนี้
        </p>
      </section>
      <Footercomponent />
    </div>
  )
}

const EnhancedTechnicianLandingpage = withFormik({
  mapPropsToValues: () => ({
    firstname: '',
    lastname: '',
    tel: '',
    companyname: '',
    footerRemark: '',
  }),
  validate: (values) => {
    //   console.log('I AM VALIDATING')
    //   console.log(values)
  },
  handleSubmit: (values, { props, setFieldValue }: any) => {
    // console.log(values, 'values')
  },
})(TechnicianLandingPage)

export default EnhancedTechnicianLandingpage
