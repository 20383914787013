import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import arrowpic from '../arrow.svg'
import emailpic from './emailpic.svg'

const Emailverifycomponent: React.FC<any> = ({ setforgotpasswordComponentStatus }: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <section className={styles.rightSideContent}>
      <div>
        <img
          onClick={() => {
            setforgotpasswordComponentStatus('fill')
          }}
          className={`arrowPic`}
          src={arrowpic}
          alt="arrowpic"
        />
        <section className={styles.emailverifyItems}>
          <p className={styles.emailverifyTopic}>ตั้งรหัสผ่านใหม่</p>
          <img className={styles.emailpic} src={emailpic} alt="emailpic" />
          <p>รหัสยืนยันตัวตนจะถูกส่งไปที่ Email</p>
          <p className={styles.emailShow}>{values.emailorphonenumber}</p>
          <p>กรุณายืนยัน</p>
        </section>
      </div>
      <button
        onClick={() => {
          setforgotpasswordComponentStatus('fill-newpassword')
        }}
        type="button"
        className={`orangeBtn`}
      >
        ตกลง
      </button>
    </section>
  )
}

export default Emailverifycomponent
