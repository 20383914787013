import React, { useState, useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'

export const FormikInputFile: any = ({
  className,
  isMultiple,
  name,
  placeholder,
  touched,
  errors,
  functionOnChange,
  accept,
}: any) => {
  // console.log(name, 'name')
  return (
    <>
      <input accept={accept} multiple={isMultiple} className={`${className}`} type="file" onChange={functionOnChange} />
    </>
  )
}

export const ShowImage: any = ({ listURL }: any) => {
  return (
    <>
      {listURL.map((ele: any, index: number) => {
        return <img width="30px" className={listURL.className} key={index} src={ele.URL[index]} alt="" />
      })}
    </>
  )
}
