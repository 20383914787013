import React from 'react'
import styles from './index.module.scss'
//

interface Props {
  onClickImage?: any
  image1: any
  image2: any
  image3: any
  image4: any
}
const ImagesGroup: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <div className={styles.imageWrapper}>
        {/* <section> */}
        <div
          className={styles.imgBox}
          onClick={() => (props.onClickImage ? props.onClickImage() : '')}
          style={props.onClickImage ? { cursor: 'pointer' } : {}}
        >
          {' '}
          {props.image1 ? <img src={props.image1} alt="first img" className={styles.image} /> : ''}
        </div>
        {/* </section> */}
        <section className={styles.imageColumnWrapper}>
          <div className={styles.imageBoxMini}>
            {props.image2 ? <img src={props.image2} alt="second img" className={styles.image} /> : ''}
          </div>
          <div className={styles.imageBoxMini}>
            {props.image3 ? <img src={props.image3} alt="third img" className={styles.image} /> : ''}
          </div>
          <div className={styles.imageBoxMini}>
            {props.image4 ? <img src={props.image4} alt="fourth img" className={styles.image} /> : ''}
          </div>
        </section>
      </div>
    </>
  )
}
export default ImagesGroup
