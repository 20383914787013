import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps, ErrorMessage } from 'formik'
// import { calOfDayDiff } from 'components/commons/function/calofdaysdiff'
// import { calOfSumUnitTotal } from 'components/commons/function/calofsumunittotal'
import { TextField, DefaultButton, DatePicker, Panel, PanelType, Toggle } from '@fluentui/react'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
//Image
import uploadlogo from './uploadlogo.svg'
import filelogo from './filelogo.svg'
import addIcon from '../../../image/addIcon.svg'
import CloseIcon from '../../../image/close.svg'
// import EditIcon from '../../../image/pen.svg'
// import { handleUploadFile } from 'utils/common'
import { useParams } from 'react-router-dom'

const Firstpage: React.FC = (props: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  // console.log('Props >> customField', [...values.customField])
  const [inputField, setInputField] = useState('')
  // const [inputAllowed, setInputAllowed] = useState('')
  const [isAddList, setIsAddList] = useState(false)
  const [list, setlist] = useState<any[]>([])
  // const [checkEdit, setCheckEdit] = useState(false)
  const [editIndex, setEditIndex] = useState<any>(null)
  // const [selectedField, setSelectedField] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  // console.log('selectedIndex', selectedIndex)
  const { projectId } = useParams<any>()

  // const handleChange = (e: any) => {
  //   setInputField(e.target.value)
  // }
  //? newCreateList
  const createListNew = (e: any) => {
    // e.preventDefault()
    // const newList = [...values.customField]
    if (inputField !== '') {
      values.customFieldList.push({
        name: inputField,
        allowedValueList: [''],
      })

      console.log(values.customFieldList, 'customfielllll')

      setFieldValue('customFieldList', values.customFieldList)
      setSelectedIndex(values.customFieldList.length - 1)
      setInputField('')
    }
  }

  // const deleteList = (listIndex: any) => {
  //   // const deleteList = [...values.customField]
  //   values.customFieldList.splice(listIndex, 1)
  //   setFieldValue('customFieldList', deleteList)
  // }

  //! onEnter
  // const createList = (e: any) => {
  //   e.preventDefault()
  //   const listField: any = []
  //   const newItem = {
  //     fieldValues: e.target.value,
  //   }
  // }

  //! RemoveList
  const listRemove = (listIndex: number) => {
    const newList = [...list]
    newList.splice(listIndex, 1)
    setlist(newList)
  }
  //! EditList
  // const listEdit = (listIndex: number) => {
  //   setIsAddList(true)
  //   setEditIndex(listIndex)
  //   const searchItem = list.find((item: any, index: number) => index === listIndex)
  //   setInputField(searchItem.fieldValues)
  // }

  return (
    <div className={`dp-flex wrapper2`}>
      <div className={`${styles.addProjectWrapper}`}>
        <section>
          <b>
            ชื่อโครงการ<span style={{ color: 'red' }}>*</span>
          </b>
          <span className="errorMessage">
            <ErrorMessage name="projectName" />
          </span>
          <Field name="projectName">
            {({ field }: FieldProps) => <TextField placeholder="ชื่อโครงการ" {...field} required />}
          </Field>
        </section>
        <section>
          <b>
            เริ่มโครงการ<span style={{ color: 'red' }}>*</span>
          </b>
          <span className="errorMessage">
            <ErrorMessage name="startDate" />
            <ErrorMessage name="endDate" />
          </span>
          <div className={styles.datepickerline}>
            <Field name="startDate">
              {({ field }: FieldProps) => (
                <DatePicker
                  {...field}
                  // allowTextInput
                  value={field.value ? new Date(field.value) : undefined}
                  onSelectDate={(date) => {
                    if (date) {
                      setFieldValue(
                        field.name,
                        `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(
                          2,
                          '0',
                        )}-${`${date.getDate()}`.padStart(2, '0')}`,
                      )
                    }
                  }}
                  style={{ flexGrow: 1 }}
                />
              )}
            </Field>
            <b>ถึง</b>
            <Field name="endDate">
              {({ field }: FieldProps) => (
                <DatePicker
                  {...field}
                  // allowTextInput
                  value={field.value ? new Date(field.value) : undefined}
                  onSelectDate={(date) => {
                    if (date) {
                      setFieldValue(
                        field.name,
                        `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(
                          2,
                          '0',
                        )}-${`${date.getDate()}`.padStart(2, '0')}`,
                      )
                    }
                  }}
                  style={{ flexGrow: 1 }}
                />
              )}
            </Field>
            <b>=</b>
            <TextField
              style={{ flexGrow: 1 }}
              readOnly
              placeholder={`${
                values.startDate && values.endDate
                  ? Math.round(
                      (new Date(values.endDate).valueOf() - new Date(values.startDate).valueOf()) /
                        (24 * 60 * 60 * 1000),
                    )
                  : 0
              } วัน`}
            />
          </div>
        </section>
        <section>
          <b>รายละเอียดเพิ่มเติม</b>
          <Field name="projectDescription">
            {({ field }: FieldProps) => <TextField {...field} multiline resizable={false} autoAdjustHeight />}
          </Field>
        </section>
        <section className={styles.projectmanagerline}>
          <span className="f-basis50">
            <b>ชื่อผู้ดูแลโครงการ</b>
            <Field name="projectManagerName">{({ field }: FieldProps) => <TextField {...field} />}</Field>
          </span>
          <span className="f-basis50">
            <b>แผนก/ตำแหน่ง</b>
            <Field name="projectManagerPosition">{({ field }: FieldProps) => <TextField {...field} />}</Field>
          </span>
        </section>
        <section>
          <p>
            <b>อัพโหลดไฟล์เอกสารหรือรูปภาพของโครงการ</b>
          </p>
          <input
            id="TORFile"
            className={styles.inputfile}
            type="file"
            placeholder="Upload File"
            accept=".pdf,.jpeg,.png"
            // accept=".pdf,image/*"
            onChange={(e) => {
              setFieldValue('TORFile', e?.target?.files?.[0].name) // Only one file in S3 String
            }}
            // onChange={(e) => {
            //   Promise.all(handleUploadFile(e.target.files, ``)).then((fileUpload: any[]) => {
            //     setFieldValue(`fileUpload`, fileUpload)
            //   })
            // }}
          />
          {values.TORFile === '' ? (
            <p className="margin-Bottom15px">
              <label className={styles.labelfile} htmlFor="TORFile">
                <span className={styles.primaryuploadspan}>
                  <img className={styles.uploadlogo} src={uploadlogo} alt="uploadlogo" />
                  Upload File
                </span>
                <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
              </label>
            </p>
          ) : (
            <p className="margin-Bottom15px">
              <label className={styles.labelshowfile} htmlFor="TORFile">
                <img className={styles.filelogo} src={filelogo} alt="filelogo" />
                <b>{values.TORFile}</b>
              </label>
            </p>
          )}
        </section>
        <section className={styles.cardCustomFields}>
          <p className={styles.textTopic}>Custom Field</p>
          <div className={styles.rowWrapper}>
            <article className={styles.inputWrapper}>
              <p className={styles.title}>Field Name</p>
              {values.customFieldList &&
                values.customFieldList.map((fieldsElement: any, listIndex: number) => {
                  return (
                    <div
                      className={styles.inputFieldNameWrapper}
                      key={listIndex}
                      onClick={() => {
                        // setSelectedField(fieldsElement.name)
                        setSelectedIndex(listIndex)
                      }}
                    >
                      <Field name={`customFieldList[${listIndex}].name`} key={listIndex}>
                        {({ field }: FieldProps) => (
                          <input
                            className={selectedIndex === listIndex ? styles.field : styles.inputAdd}
                            type="text"
                            {...field}
                            // required
                          />
                        )}
                      </Field>
                      {values.customFieldList.length > 1 ? (
                        <span
                          className={styles.closeIcon}
                          onClick={() => {
                            values.customFieldList.splice(listIndex, 1)
                            setFieldValue(`customFieldList`, values.customFieldList)
                          }}
                        >
                          <img className={styles.image} src={CloseIcon} alt="delete" />
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  )
                })}
              {isAddList && (
                <div className={styles.inputFieldContainer}>
                  <input
                    type="text"
                    value={inputField}
                    className={styles.inputField}
                    onChange={(e: any) => setInputField(e.target.value)}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        if (!values.customFieldList) {
                          values.customFieldList = []
                          createListNew(e)
                        } else if (values.customFieldList[values.customFieldList.length - 1].name !== '') {
                          createListNew(e)
                        }
                      }
                    }}
                  />
                  <span className={styles.close} onClick={() => setIsAddList(false)}>
                    <img className={styles.image} src={CloseIcon} alt="delete" />
                  </span>
                </div>
              )}

              <br />
              <button type="button" className={styles.btnAddList} onClick={() => setIsAddList(true)}>
                <span>
                  <img src={addIcon} alt="addIcon" />
                </span>
                <p className={styles.text}> New Field</p>
              </button>
            </article>
            <article className={styles.title}>
              <p>Allowed Values</p>

              {values.customFieldList &&
                values.customFieldList[selectedIndex] &&
                values.customFieldList[selectedIndex].allowedValueList.map((allowedEle: any, allowedIndex: number) => {
                  return (
                    <>
                      <div className={styles.inputAllowedWrapper} key={allowedIndex}>
                        <Field name={`customFieldList[${selectedIndex}].allowedValueList[${allowedIndex}]`}>
                          {({ field }: FieldProps) => (
                            <input
                              onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                  if (
                                    values.customFieldList[selectedIndex].allowedValueList[
                                      values.customFieldList[selectedIndex].allowedValueList.length - 1
                                    ] !== ''
                                  ) {
                                    values.customFieldList[selectedIndex].allowedValueList.push('')
                                  }
                                }
                                setFieldValue(`customFieldList`, values.customFieldList)
                              }}
                              className={allowedIndex % 2 === 0 ? styles.inputValues : styles.inputValuesBGWhite}
                              type="text"
                              {...field}
                              // required
                            />
                          )}
                        </Field>
                        {values.customFieldList &&
                        values.customFieldList[selectedIndex] &&
                        values.customFieldList[selectedIndex].allowedValueList.length > 1 ? (
                          <span
                            className={styles.closeIcon}
                            onClick={() => {
                              values.customFieldList[selectedIndex].allowedValueList.splice(allowedIndex, 1)
                              setFieldValue(`customFieldList`, values.customFieldList)
                            }}
                          >
                            <img className={styles.image} src={CloseIcon} alt="delete" />
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  )
                })}
              <button
                type="button"
                className={styles.btnAddList}
                onClick={() => {
                  if (
                    values.customFieldList[selectedIndex].allowedValueList[
                      values.customFieldList[selectedIndex].allowedValueList.length - 1
                    ] !== ''
                  ) {
                    values.customFieldList[selectedIndex].allowedValueList.push('')
                    setFieldValue(`customField`, values.customField)
                  }
                }}
              >
                <span>
                  <img src={addIcon} alt="addIcon" />
                </span>
                <p className={styles.text}> New Field</p>
              </button>
            </article>
          </div>
        </section>
        <section className={styles.dropDownConfigReportFieldID}>
          <p>โปรดเลือกการจำแนกกลุ่มของรายงาน</p>
          <FormikInputSelect
            className={styles.dropDownInput}
            name={`configReportFieldID`}
            option={values.options.concat(
              values.customFieldList && values.customFieldList.length > 0
                ? values.customFieldList.map((customFieldElement: any) => {
                    return { value: customFieldElement.name, text: customFieldElement.name }
                  })
                : [],
            )}
            disabled={!!projectId}
            defaultValue={''}
          />
        </section>
        <section className={styles.dropDownConfigReportFieldID}>
          <p>โปรดเลือกการจำแนกกลุ่มประเภทงาน</p>
          <FormikInputSelect
            className={styles.dropDownInput}
            name={`editFieldID`}
            option={[{ value: '', text: '' }].concat(
              values.customFieldList && values.customFieldList.length > 0
                ? values.customFieldList.map((customFieldElement: any) => {
                    return { value: customFieldElement.name, text: customFieldElement.name }
                  })
                : [],
            )}
            defaultValue={''}
          />
        </section>
      </div>
    </div>
  )
}

export default Firstpage
