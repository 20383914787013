import React from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { TextField } from '@fluentui/react'
import facebookpic from '../../logincomponent/facebooklogo.svg'
import googlepic from '../../logincomponent/googlelogo.svg'

const Fillnumbercomponent: React.FC<any> = ({ setComponentStatus, setloginComponentStatus }: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <section className={styles.rightSideContent}>
      <article className={styles.rightSideTopicContainer}>
        <h1 className={styles.rightSideTopic}>สมัครเข้าใช้งาน</h1>
      </article>
      <Form>
        <article className={styles.rightSideItems}>
          <section className={styles.textFieldGroup}>
            <Field name="phonenumber">
              {({ field }: FieldProps) => <TextField type="tel" {...field} placeholder="หมายเลขโทรศัพท์" />}
            </Field>
          </section>
          <section className={styles.loginBtnContainer}>
            <button
              className={styles.loginBtn}
              onClick={() => {
                values.phonenumber !== '' ? setloginComponentStatus('verify') : null
              }}
              type="button"
            >
              ต่อไป
            </button>
            <button
              type="button"
              onClick={() => {
                setComponentStatus('login')
              }}
              className={styles.linkitems}
            >
              เข้าสู่ระบบด้วย รหัสผ่าน
            </button>
          </section>
          <section className={styles.btnContainer}>
            <section className={styles.orLine}>
              <hr />
              or
              <hr />
            </section>
            <article className={styles.idpsBtnGroup}>
              <button
                className={`${styles.idpsBtn} ${values.phonenumber !== '' ? styles.idpsBtnDisabled : ''}`}
                type="button"
                disabled={values.phonenumber !== ''}
              >
                <img
                  className={styles.idpsLogo}
                  style={values.phonenumber !== '' ? { display: 'none' } : {}}
                  src={facebookpic}
                  alt="facebookpic"
                />
                Facebook
              </button>
              <button
                className={`${styles.idpsBtn} ${values.phonenumber !== '' ? styles.idpsBtnDisabled : ''}`}
                type="button"
                disabled={values.phonenumber !== ''}
              >
                <img
                  className={styles.idpsLogo}
                  style={values.phonenumber !== '' ? { display: 'none' } : {}}
                  src={googlepic}
                  alt="googlepic"
                />
                Google
              </button>
            </article>
            <article className={styles.registerline}>
              <p className={styles.registertext}>เพิ่งเคยเข้ามาใน ChangTum ใช่หรือไม่</p>
              <Link to="/" className={styles.registerBtn}>
                สมัครใหม่
              </Link>
            </article>
          </section>
        </article>
      </Form>
    </section>
  )
}

export default Fillnumbercomponent
