import React from 'react'
import styles from './index.module.scss'
import closeIcon from '../../image/close.svg'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import moment from 'moment'
import { useFormikContext } from 'formik'
const ModalViewTeam: React.FC<any> = (props: any): JSX.Element => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const dispatch = useDispatch()

  return (
    <Modal
      open={props.open}
      disablePortal={true}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500,
      //   sx: { zIndex: 100 },
      // }}
    >
      <div className={styles.modalBox}>
        {/* {console.log('Table Data ARRAY => ', values.profile.technicianList)} */}

        {/* {values.profile.technicianList && ( */}
        {values.profile && (
          <div className={styles.cardModal}>
            <div className={styles.head}>
              <p className={styles.textHead}>ข้อมูลทีมงาน</p>
              <img
                src={closeIcon}
                alt=""
                onClick={() => {
                  props.setOpen(false)
                }}
                className={styles.Closeicon}
              />
            </div>

            <div className={styles.textGroup}>
              <section>
                <p className={styles.textTopic}>
                  วันที่{'  '} <span className={styles.textDate}>{props.value && props.value.getUser.userId}</span>
                </p>
              </section>
              <section>
                <p className={styles.textTopic}>
                  สถานะ{'  '}
                  <span className={styles.pendingView}>{props.leaveList?.status}</span>
                </p>
              </section>

              <section>
                <p className={styles.textTopic}>วันที่เริ่ม</p>
                <p className={styles.textContent}>{moment(`${props.leaveList?.startedAt}`).format('L')}</p>
              </section>

              <section>
                <p className={styles.textTopic}>วันที่สิ้นสุด</p>
                <p className={styles.textContent}>{moment(`${props.leaveList?.endedAt}`).format('L')}</p>
              </section>

              <section>
                <p className={styles.textTopic}>หมายเหตุ</p>
                {/* <p className={styles.textContent}>{values.profile.getUser.lastName}</p> */}
              </section>
            </div>
          </div>
        )}

        {/* )} */}
      </div>
    </Modal>
  )
}
export default ModalViewTeam
