import jwt_decode from 'jwt-decode'

import { makeActionCreator } from 'state/actions/generateActionCreator'

// Actions
const DECODE_NEW_TOKEN = 'token/DECODE_NEW_TOKEN'

const SET_LOGGING_IN = 'token/SET_LOGGING_IN'
const SET_LOGGED_IN_TRUE = 'token/SET_LOGGED_IN_TRUE'
const SET_LOGGED_IN_FALSE = 'token/SET_LOGGED_IN_FALSE'

const initialState = {
  rawAccessToken: localStorage.getItem('accessToken'),
  decodedAccessToken: localStorage.getItem('accessToken')
    ? jwt_decode(localStorage.getItem('accessToken') as string)
    : {},
  rawRefreshToken: localStorage.getItem('refreshToken'),
  // Needs to check for token, else we will bounce back to login page everytime we refresh a page (initialState will be false)
  isLoggedIn: localStorage.getItem('accessToken') ? true : false,
  isLoggingIn: false,
}

// Reducer
export default function reducer(state = initialState, action: any): ITokenState {
  switch (action.type) {
    case DECODE_NEW_TOKEN:
      return {
        ...state,
        rawAccessToken: action.accessToken,
        decodedAccessToken: jwt_decode(action.accessToken),
        rawRefreshToken: action.refreshToken ? action.refreshToken : state.rawRefreshToken,
      }
    case SET_LOGGED_IN_TRUE:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
      }
    case SET_LOGGED_IN_FALSE:
      return {
        rawAccessToken: '',
        decodedAccessToken: {},
        rawRefreshToken: '',
        isLoggingIn: false,
        isLoggedIn: false,
      }
    case SET_LOGGING_IN:
      return {
        ...state,
        isLoggingIn: true,
      }
    default:
      return state
  }
}

// Action Creators
export const decodeNewToken = makeActionCreator(DECODE_NEW_TOKEN, 'accessToken', 'refreshToken')
export const setLoggingInTrue = makeActionCreator(SET_LOGGING_IN)
export const setLoggedInTrue = makeActionCreator(SET_LOGGED_IN_TRUE)
export const setLoggedInFalse = makeActionCreator(SET_LOGGED_IN_FALSE)

// function shouldDecodeToken(state: any, raw_token: string) {
//   const current_token = state.token.raw_token
//   return current_token !== raw_token
// }

// export function decodeTokenIfNeeded() {
//   // console.log("localStorage.getItem('token_auth')", localStorage.getItem('token_auth'))
//   //   const rawAccessToken = localStorage.getItem('accessToken')
//   return (dispatch: any, getState: any) => {
//     if (shouldDecodeToken(getState(), rawAccessToken)) {
//       return dispatch(decodeToken(rawAccessToken))
//     } else {
//       return Promise.resolve()
//     }
//   }
// }

export interface ITokenState {
  rawAccessToken: string | null
  decodedAccessToken: any
  rawRefreshToken: string | null
  isLoggedIn: boolean
  isLoggingIn: boolean
}
