import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps, setNestedObjectValues, ErrorMessage } from 'formik'
import { FormikInputRadio } from 'components/commons/input/formikInputRadio'
import { DatePicker } from '@fluentui/react'
import { calDateforInputDate } from '../../utils/common'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

interface Props {
  setCreatTeamstep: any
}
const CreateMyTeam: React.FC<Props> = (props: Props) => {
  const { values, errors, touched, setFieldValue, validateForm, setTouched } = useFormikContext<any>()
  const [mode, setMode] = React.useState<'create' | 'update'>('create')
  const [image, setImage] = React.useState('')
  const navigate = useNavigate()
  const { userId } = useParams<any>()

  //Validate
  async function validatePersonId(value: any) {
    if (!value) {
      return 'กรุณากรอกเลขประจำตัวประชาชน'
    }
    try {
      await Yup.object()
        .shape({
          personId: Yup.string().length(13, 'โปรดกรอกเลขบัตรประชาชน 13 หลัก').required('eeee'),
        })
        .validate({ personId: value.toString() })
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }
  async function validatePhone(value: string) {
    if (!value) {
      return 'กรุณากรอกเบอร์ติดต่อ'
    }
    try {
      await Yup.object()
        .shape({
          phone: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'กรอกเฉพาะหมายเลขเท่านั้น')
            .min(10, 'กรอกเบอร์ติดต่อ 10 หลัก')
            .max(10, 'กรอกเบอร์ติดต่อ 10 หลัก'),
        })
        .validate({ phone: value })
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }

  return (
    <div className={styles.verifyFristContainer}>
      <div className={styles.flexbox}>
        {' '}
        <div className={styles.inputleft}>
          {' '}
          <div className={styles.inputArea}>
            <p>ชื่อ - นามสกุล</p>
            <div className={styles.flexinput}>
              <Field className={styles.inputText} type="text" name={'userteam.firstName'} placeholder="ชื่อ" />
              <Field className={styles.inputText} type="text" name={'userteam.lastName'} placeholder="นามสกุล" />
            </div>
            <div>
              <p>ชื่อเล่น</p>
              <Field className={styles.inputText} type="text" name={'userteam.nickName'} placeholder="ชื่อเล่น" />
            </div>
            <div>
              <span>
                เลขบัตรประชาชน *{' '}
                <span className="errorMessage">
                  <ErrorMessage name={'userteam.personalID'} />
                </span>
              </span>{' '}
              <Field
                className={styles.inputText}
                type="number"
                name={'userteam.personalID'}
                placeholder="เลขบัตรประชาชน"
                validate={validatePersonId}
              />
            </div>

            <div className={styles.addressBox}>
              <p>ที่อยู่ตามบัตรประชาชน</p>
              <Field as="textarea" className={styles.inputText} name={'userteam.addressDetail'} placeholder="ที่อยู่" />
            </div>
            <div>
              <span>
                เบอร์ติดต่อ *{' '}
                <span className="errorMessage">
                  <ErrorMessage name={'userteam.phone'} />
                </span>
              </span>{' '}
              <Field
                className={styles.inputText}
                type="text"
                name={'userteam.phone'}
                placeholder="เบอร์ติดต่อ"
                validate={validatePhone}
              />
            </div>
            <div>
              <p>อีเมล์</p>
              <Field className={styles.inputText} type="text" name={'userteam.email'} placeholder="อีเมล์" />
            </div>
          </div>
        </div>
        <div className={styles.inputrigth}>
          <div>
            <p>วันเดือนปีเกิด</p>
            <Field className={styles.inputText} type="date" name={'userteam.birthDate'} />

            {/* <Field name={'userteam.birthDate'}>
              {({ field }: FieldProps) => (
                <DatePicker
                  allowTextInput={true}
                  style={{ width: '200px' }}
                  {...field}
                  value={field.value ? new Date(field.value) : undefined}
                  onSelectDate={(date) => {
                    if (date) {
                      setFieldValue('userteam.birthDate', calDateforInputDate(date))
                    }
                  }}
                  maxDate={new Date()}
                />
              )}
            </Field> */}
          </div>
          <div className={styles.radioGroup}>
            <p className={styles.texthead}>เพศ</p>
            <div className={styles.radio}>
              <FormikInputRadio
                defaultValue="MALE"
                listRadio={[
                  {
                    text: 'ชาย',
                    value: 'MALE',
                    name: 'userteam.gender',
                  },
                  {
                    text: 'หญิง',
                    value: 'FEMALE',
                    name: 'userteam.gender',
                  },
                  {
                    text: 'เพศทางเลือก',
                    value: 'LGBT',
                    name: 'userteam.gender',
                  },
                ]}
              />
            </div>
          </div>
          <div className={styles.radioGroup}>
            <p className={styles.texthead}>สถานภาพ</p>
            <div className={styles.radio}>
              <FormikInputRadio
                defaultValue="SINGLE"
                listRadio={[
                  {
                    text: 'โสด',
                    value: 'SINGLE',
                    name: 'userteam.personalRelation',
                  },
                  {
                    text: 'สมรส',
                    value: 'MARRIED',
                    name: 'userteam.personalRelation',
                  },
                  {
                    text: 'หย่า',
                    value: 'DIVORCE',
                    name: 'userteam.personalRelation',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.emerBox}>
        <div className={styles.flexinput}>
          <div className={styles.GroupInput}>
            <p>ชื่อ-นามสกุล ผู้ติดต่อกรณี ฉุกเฉิน</p>
            <div className={styles.flexInput}>
              <Field
                className={styles.inputText}
                type="text"
                name={'userteam.emergencyContact.firstName'}
                placeholder="ชื่อ"
              />
              <Field
                className={styles.inputText}
                type="text"
                name={'userteam.emergencyContact.lastName'}
                placeholder="นามสกุล"
              />
            </div>
          </div>
          <div className={styles.GroupInput}>
            {' '}
            <p>ความสัมพันธ์</p>
            <Field
              className={styles.inputText}
              type="text"
              name={'userteam.emergencyContact.relation'}
              placeholder="ความสัมพันธ์"
            />
          </div>
        </div>
        <div></div>
        <div>
          <span>
            เบอร์ผู้ติดต่อกรณีฉุกเฉิน{' '}
            <span className="errorMessage">
              <ErrorMessage name={'userteam.emergencyContact.phone'} />
            </span>
          </span>
          <Field
            className={styles.inputText}
            type="text"
            name={'userteam.emergencyContact.phone'}
            placeholder="เบอร์ติดต่อ"
            validate={validatePhone}
          />
        </div>
      </div>
      <section>
        <article className={styles.btnFooterWrapper}>
          <button type="button" className={styles.btnCancle} onClick={() => navigate(-1)}>
            ยกเลิก
          </button>
          <button
            type="button"
            className={styles.btnNext}
            onClick={() => {
              validateForm().then((error) => {
                // console.log('errors after vadlisad', errors)
                if (Object.keys(error).length === 0) {
                  props.setCreatTeamstep('crtSecond')
                  // setpagestatus('secondstep')
                  console.log('Ok')
                } else {
                  console.log('No NextStep')
                  setTouched(setNestedObjectValues(error, true))
                }
              })
            }}
          >
            ถัดไป
          </button>
        </article>
      </section>
    </div>
  )
}
export default CreateMyTeam
