import * as React from 'react'
import styles from './index.module.scss'
//svg Image
import FileTOR from '../../image/file.svg'
import TableShow from 'components/TableShow'
import ImagesSlideShow from 'components/imagesSlideShow'

const tableHeader = [
  // 'Group',
  'ทั้งหมด',
  'คงเหลือ',
  'สมัคร',
  'อนุมัติ',
]
interface Icard {
  title?: string
  username?: string
  fileAttachmentList?: string[]
  startDate?: string
  endDate?: string
  description?: string
  workTypePostList?: string[]
  createdAt?: string
  imageURLList?: any[]

  //   companyName?: string
  //   fileAttachmentList?: string[]
  //   startDate?: string
  //   endDate?: string
  //   description?: string
  //   workTypePostList?: string[]
  //   imageURLList?: string[]
  //   createdAt?: string
  //   jobUnitPrice?: number
  //   buttonHeaderList?: any
}
const eRegionArr: any = {
  BKK: 'กรุงเทพ',
  CR: 'ภาคกลาง',
  ER: 'ภาคตะวันออก',
  NER: 'ภาคตะวันออกเฉียงเหนือ',
  WR: 'ภาคตะวันตก',
  SR: 'ภาคใต้',
}

const CardHistoryComponent: React.FC<Icard> = (props: Icard & any) => {
  return (
    <div className={styles.center}>
      <div className={styles.cardArea}>
        <div className={styles.cardNews}>
          <div className={styles.header}>
            <p className={styles.headtext}>
              {props.title} <span className={styles.redtext}>!!</span>
            </p>
            {/*           */}
            <div className={styles.detail}>
              <div className={styles.companyName}>{props.username}</div>
              <div className={styles.rigthead}>
                {' '}
                <p className={styles.subtext}>
                  {' '}
                  <span className={styles.titletext}>ราคา</span>
                  2,500 - 5,500
                </p>
              </div>
            </div>
            <div className={styles.detail}>
              <div className={styles.file}>
                {props.fileAttachmentList &&
                  props.fileAttachmentList.map((file: any, fileIndex: number) => {
                    return (
                      <div key={fileIndex} className={styles.filebox}>
                        <img src={FileTOR} alt="" />
                        <p className={styles.filetext}>{file.split('/')[file.split('/').length - 1]}</p>
                      </div>
                    )
                  })}
              </div>
              <div className={styles.rigthead}>
                <p className={styles.subtext}>
                  {' '}
                  <span className={styles.titletext}>ระยะเวลาโครงการ</span>
                  {props.startDate} - {props.endDate}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.line}></div>

          <div className={styles.twosides}>
            <div className={styles.descript}>
              <div className={styles.workbox}>
                {props.workTypePostList &&
                  props.workTypePostList.map((workType: any, workTypeIndex: number) => (
                    <div key={workTypeIndex} className={styles.textwork}>
                      <p>{workType.workType}</p>
                      <span className={styles.textsub}>
                        {workType.totalJobList.map((joblist: any) => joblist.totalQty).length > 0
                          ? workType.totalJobList
                              .map((joblist: any) => joblist.totalQty)
                              .reduce((a: any, b: any) => a + b)
                          : 0}
                        <p className={styles.worktext}>งาน</p>
                      </span>
                    </div>
                  ))}
              </div>
              <div>
                <p className={styles.textdes}>รายละเอียด</p>
                <p>{props.description}</p>
              </div>
            </div>
            <div className={styles.imageside}>
              <div className={styles.imgshow}>
                {' '}
                <ImagesSlideShow imageList={props.imageURLList} />
              </div>
            </div>
          </div>
          <div className={styles.bottomtext}>
            <div className={styles.tRegion}></div>
            <div className={styles.tdatecreate}>
              <p className={styles.textdate}>
                {new Date(props.createdAt).toLocaleDateString('th-TH', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            </div>
          </div>

          <div className={styles.jobboxArea}>
            {' '}
            {props.workTypePostList &&
              props.workTypePostList.map((workType: any, workTypeIndex: number) => (
                <div key={workTypeIndex} className={styles.jobbox}>
                  <p className={styles.texthead}>{workType.workType}</p>
                  <div className={styles.TableShow}>
                    <TableShow
                      tableTopic={props.username}
                      tableHeader={tableHeader}
                      tableRowList={workType}
                      workType={workType}
                      tableIndex={0}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardHistoryComponent
