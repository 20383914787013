import React from 'react'
import { Outlet } from 'react-router-dom'
import NavTopBar from '../components/navTopBar'
import Menubar from '../components/menuindex/menubar'

export const Layout: React.FC = () => {
  return (
    <div className="layout-main">
      <NavTopBar />
      <Menubar />
      <Outlet />
    </div>
  )
}
