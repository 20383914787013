import * as React from 'react'
import { useState } from 'react'
import styles from './index.module.scss'
import Tree from 'components/Tree'
import { getIn, useFormikContext } from 'formik'

import { FormikInputText } from 'components/commons/input/formikInputText'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { ModalProperty } from 'container'
import { EPropertyType } from 'utils/common'

import textPNG from 'image/pat/text.png'
import dropPNG from 'image/pat/drop.png'
import numPNG from 'image/pat/num.png'
import checkPNG from 'image/pat/check.png'
import closeSVG from 'image/closeCircleRed.svg'
import addSVG from 'image/plusCircleGreen.svg'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

interface Props {
  node: any
  name: string
  index: number
  valuesData: any
  subIndex: number
  lineIndex: number
}

const TreeNode: React.FC<Props> = ({ node, name, index, valuesData, subIndex, lineIndex }: Props) => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [visible, setVisible] = React.useState(true)
  const hasChild = node?.children?.length >= 1 ? true : false
  const level = name.split('.').slice(3).length + 1
  const [open, setOpen] = useState<boolean>(false)
  const EMTYP_CHILDREN = { name: '', property: null } // This should be null --> defaultProperty
  const actualProperty = getIn(values, `${name}.property`)
  const property: EPropertyType = actualProperty ? actualProperty : valuesData.defaultProperty // We should just render default value to defaultProperty if null

  const handleAddRow = () => {
    if (valuesData.levelRow === 1) return
    const initialValues = getIn(values, `${name}`)
    if (!initialValues.children) initialValues.children = []
    const cloneEmptyChildren = { ...EMTYP_CHILDREN }
    // console.log('This is empty child ', cloneEmptyChildren)
    if (valuesData && valuesData.defaultProperty) cloneEmptyChildren.property = valuesData.defaultProperty
    initialValues.children.push(cloneEmptyChildren)
    // console.log('This is added empty child ', initialValues)
    setFieldValue(`${name}`, initialValues)
  }

  const handleDeleteRow = () => {
    const idx = parseInt(name.split('.')[2].split('[')[1])

    const dataName = `formikUnPackPAT.lineList[${lineIndex}][${subIndex}].rowList`

    if (level === 1) {
      const intitialValues = getIn(values, `${dataName}`)
      intitialValues.splice(idx, 1)
      setFieldValue(`${dataName}`, intitialValues)
    } else if (level === 2) {
      const intitialValues = getIn(values, `${dataName}[${idx}].children`)
      intitialValues.splice(index, 1)
      setFieldValue(`${dataName}[${idx}].children`, intitialValues)
    } else if (level === 3) {
      const idxParent = parseInt(name.split('.')[3].split('[')[1])
      const idxNode = parseInt(name.split('.')[4].split('[')[1])
      const intitialValue = getIn(values, `${dataName}[${idx}].children[${idxParent}].children`)
      intitialValue.splice(idxNode, 1)
      setFieldValue(`${dataName}[${idx}].children[${idxParent}].children`, intitialValue)
    }
  }

  // React.useEffect(() => {
  //   if (!property) setFieldValue(`${name}.property`, valuesData?.defaultProperty)
  // }, [valuesData.defaultProperty])

  return (
    <div className={styles.container} aria-checked={hasChild}>
      <div className={styles.group}>
        {hasChild ? (
          <div aria-checked={visible === true} className={styles.icon} onClick={() => setVisible((e) => !e)}>
            <ExpandMoreRoundedIcon />
          </div>
        ) : (
          <div className={styles.box}></div>
        )}

        <div className={styles.wrapperForm}>
          <FormikInputText className={styles.panelcheckboxconfigtopic} name={`${name}.name`} placeholder="ข้อความ" />
          <div className={styles.group}>
            {level === parseInt(valuesData.levelRow) && (
              <>
                <img src={PropertyImgs[property]} alt="" />
                <div className={styles.icon} onClick={() => setOpen(true)}>
                  <MoreHorizIcon />
                </div>

                {index === 0 ? (
                  <img src={closeSVG} alt="" width={25} height={25} style={{ opacity: 0.4 }} />
                ) : (
                  <img src={closeSVG} alt="" width={25} height={25} onClick={handleDeleteRow} />
                )}
              </>
            )}

            {level < parseInt(valuesData.levelRow) && (
              <>
                {/* <img src={PropertyImgs[property]} alt="" /> */}
                <img src={addSVG} alt="" width={25} height={25} onClick={handleAddRow} />
                {index === 0 ? (
                  <img src={closeSVG} alt="" width={25} height={25} style={{ opacity: 0.4 }} />
                ) : (
                  <img src={closeSVG} alt="" width={25} height={25} onClick={handleDeleteRow} />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {hasChild && visible && (
        <Tree
          name={`${name}.children`}
          data={node.children}
          valuesData={valuesData}
          subIndex={subIndex}
          lineIndex={lineIndex}
        />
      )}

      <ModalProperty
        open={open}
        handleClose={() => setOpen((prev) => !prev)}
        name={`${name}`}
        dataLineList={valuesData}
        indexLineList={lineIndex}
        subIndex={subIndex}
      />
    </div>
  )
}

const PropertyImgs: any = {
  CHECK: checkPNG,
  NUMBER: numPNG,
  TEXT: textPNG,
  DROPDOWN: dropPNG,
  DROPDOWN1: dropPNG,
  DROPDOWN2: dropPNG,
  DROPDOWN3: dropPNG,
}

export default TreeNode
