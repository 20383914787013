import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal } from '@mui/material'
import CloseSVG from './close.svg'
import styles from './masterPlanModal.module.scss'
import { post } from 'adapter/xhr'
import Tooltip from 'components/Tooltip'
import { useFormikContext } from 'formik'

interface Props {
  open: boolean
  onClose: (value: boolean) => void
  data?: IMasterPlanCellParamData
}

export interface IMasterPlanCellParamData {
  weekNo: number
  cellData: any
  projectData?: {
    workType: string
    region: string
    groupFieldIndex: number
    workTypeIndex: number
    projectId: string
    acceptedUserIdList?: any[]
    planningYear: string
  }
}

interface ICellUserData {
  acceptedUserId: string
  quantity: number
  getUser: any
}

/**
 * week_[number] = [{acceptedUserId: 1, quantity: 1}, acceptedUserId: 1, quantity: 1},]
 * find week_number index which acceptedUserId had changed -> weekIndex
 */

/**
 * payload to api
  {
    input: {
      projectId: 1,
      groupFieldIndex: 1,
      workTypeIndex: 1,
      weekNumber: week_14
      week: {
        weekIndex: 1,
        quantity: 1,
      }
    },
  },
 */

interface IQueryMasterPlanByOneCellProps {
  weekNumber?: number
  planningYear?: string
  projectId?: string
  workType?: string
  groupFieldIndex?: number
  workTypeIndex?: number
}
const useQueryMasterPlanByOneCell = (props: IQueryMasterPlanByOneCellProps): [ICellUserData[], boolean] => {
  const [cellUserData, setCellUserData] = useState<ICellUserData[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const week = `week_${props.weekNumber}`

  const queryMasterPlanByOneCell = async () => {
    if (!props.weekNumber) return
    setLoading(true)

    const query = `query project($projectId: ID!, $planningYear: String!) {
      project(projectId: $projectId) {
        masterPlan(planningYear: $planningYear) {
          groupFieldList {
            workTypeList {
              workType
              workTypeTable {
                ${week} {
                  acceptedUserId
                  quantity
                }
              }
            }
          }
        }
      }
    }`

    try {
      const res = await post('/', {
        query,
        variables: { planningYear: props.planningYear, projectId: props.projectId },
      })

      unpack(res.data.data?.project?.masterPlan?.groupFieldList)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  useEffect(() => {
    queryMasterPlanByOneCell()
  }, [props.weekNumber, props.workType, props.groupFieldIndex, props.workTypeIndex])

  const unpack = (groupFieldList: any[]) => {
    if (props.groupFieldIndex === undefined || props.workTypeIndex === undefined || props.weekNumber === undefined)
      return

    setCellUserData(
      groupFieldList[props.groupFieldIndex].workTypeList[props.workTypeIndex].workTypeTable[`week_${props.weekNumber}`],
    )
  }

  return [cellUserData, loading]
}

const MasterPlanModal: React.FC<Props> = (props) => {
  const onSubmit = async () => {
    // props.onClose(false)
    const query = `mutation Mutation($input: PutMasterPlanOneCell) {
      putMasterPlanOneCell(input: $input) {
        projectId
      }
    }`
    await post('/', {
      query,
      variables: {
        input: {
          planningYear: props.data?.projectData?.planningYear,
          projectId: props.data?.projectData?.projectId,
          groupFieldIndex: props.data?.projectData?.groupFieldIndex,
          workTypeIndex: props.data?.projectData?.workTypeIndex,
          weekNumber: `week_${props.data?.weekNo}`,
          week: userDataList.map((user) => ({
            acceptedUserId: user.userId,
            quantity: user.quantity,
          })),
        },
      },
    })

    setFieldValue('masterPlan.selectTechnician', values.masterPlan.selectTechnician === 'ALL' ? null : 'ALL')

    props.onClose(false)
  }

  const [cellUserData, cellUserDataLoading] = useQueryMasterPlanByOneCell({
    weekNumber: props.data?.weekNo,
    projectId: props.data?.projectData?.projectId,
    planningYear: props.data?.projectData?.planningYear,
    workType: props.data?.projectData?.workType,
    groupFieldIndex: props.data?.projectData?.groupFieldIndex,
    workTypeIndex: props.data?.projectData?.workTypeIndex,
  })
  const [userDataList, setUserDataList] = useState<any[]>([])

  const { values, setFieldValue } = useFormikContext<any>()
  const mapCellUserDataToAcceptedUserIdList = () => {
    const userIdMap: any = {}
    cellUserData?.forEach((user) => {
      userIdMap[user.acceptedUserId] = user.quantity
    })

    const data = props.data?.projectData?.acceptedUserIdList?.map((user) => {
      return {
        ...user,
        quantity: userIdMap[user.userId] || null,
      }
    })

    setUserDataList(data || [])

    return data
  }

  useEffect(() => {
    mapCellUserDataToAcceptedUserIdList()
  }, [cellUserData])

  const getTotal = useMemo(() => {
    return userDataList?.reduce((acc, curr) => acc + (curr.quantity || 0), 0) || 0
  }, [userDataList])

  const handleChangeUserDataList = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserDataList((prev) => {
        prev[index].quantity = parseInt(e.target.value)
        return [...prev]
      })
    }
  }

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      // BackdropProps={{
      //   timeout: 500,
      // }}
      onClose={() => {
        setUserDataList([])
        props.onClose(false)
      }}
      sx={{ zIndex: 1000000 }}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <h1>กรุณาระบุจำนวนให้ทีมช่าง</h1>
            <button onClick={() => props.onClose(false)} className={styles.closeButton}>
              <img src={CloseSVG} />
            </button>
          </div>
          <div className={styles.subHeader}>
            <div>
              ภูมิภาค {props.data?.projectData?.region} Scope : {props.data?.projectData?.workType}
            </div>
            <div>Week {props.data?.weekNo}</div>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.contentInputWrapper}>
              {!cellUserDataLoading &&
                userDataList?.map((user, index) => (
                  <div className={styles.inputContainer} key={user.userId}>
                    <Tooltip text={user.getUser.username}>
                      <p className={styles.inputLabel}>{user.getUser.username}</p>
                    </Tooltip>
                    <input
                      onChange={handleChangeUserDataList(index)}
                      value={user.quantity}
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </div>
                ))}
            </div>

            <div>
              <div className={styles.divider} />
              <div className={styles.total}>
                Total <span>{getTotal}</span>
              </div>
            </div>
          </div>
          <div className={styles.modalActionContainer}>
            <button onClick={() => props.onClose(false)} className={styles.cancelButton}>
              ยกเลิก
            </button>
            <button onClick={onSubmit} className={styles.saveButton}>
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MasterPlanModal
