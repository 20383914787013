import React, { useState, useEffect } from 'react'
import { Field, ErrorMessage, useField } from 'formik'

export const FormikInputCheckbox: any = ({ label, ...props }: any) => {
  // const [field, meta, helpers] = useField(props)

  return (
    <>
      <label>
        <Field type="checkbox" {...props} />
        {label}
      </label>
      {/* <ErrorMessage name={name} render={(msg) => <span className="error">{msg}</span>} /> */}
    </>
  )
}
