import React from 'react'
import styles from './index.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormikContext, setNestedObjectValues } from 'formik'
import uploadlogo from '../../image/uploadlogo.svg'
import filelogo from '../../image/filelogo.svg'
// import showIdCard from './showCardId.svg'
import AddIcon from '@mui/icons-material/Add'
import { handleUploadFile } from 'utils/common'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import showIdCard from './showCardId.svg'
import * as Yup from 'yup'
import { ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import allAction from 'state/actions'
import { CreateaVerifyUserInteam, CreateUserVerification } from 'adapter/xhr'
interface Props {
  setCreatTeamstep: any
}
const VerifyTeam: React.FC<Props> = (props: Props) => {
  const { values, errors, touched, setFieldValue, validateForm, setTouched } = useFormikContext<any>()
  const { userId } = useParams<any>()
  // const { userId, username } = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  async function validateSelfiePersonalIDFileAttachment(value: string) {
    if (!value) {
      return 'กรุณาแนบภาพเพื่อยืนยันตัวตน'
    }
    // try {
    //   await Yup.object()
    //     .shape({
    //       taxID: Yup.string().length(13, 'กรุณากรอกเลขประจำตัวผู้เสียภาษี 13 หลัก').required('eeee'),
    //     })
    //     .validate({ taxID: value.toString() })
    // } catch (error) {
    //   console.log('EEEEEEEEEEEEEEEEEE', error.message)
    //   return error.message
    // }
  }
  return (
    <>
      <div className={styles.verifythirdContainer}>
        <section className={styles.imageUploadWrapper}>
          <div className={styles.imgCardId}>
            {/* {console.log(values.selfiePersonalIDFileAttachment, 'file')} */}
            {values.selfiePersonalIDFileAttachment ? (
              <img className={styles.image} src={values.selfiePersonalIDFileAttachment} alt="idcard" />
            ) : (
              <img className={styles.image} src={showIdCard} alt="idcard" />
            )}
            <label htmlFor="selfiePersonalIDFileAttachment" className={styles.upLoadImage}>
              <AddIcon className={styles.addIcon} />
              <input
                type="file"
                id={'selfiePersonalIDFileAttachment'}
                style={{ display: 'none' }}
                accept=".pdf,.jpeg,.png"
                onChange={(e) => {
                  Promise.all(handleUploadFile(e.target.files, `VerificationTeam/${userId}/images/`)).then(
                    (selfiePersonalIDFileAttachment: any) => {
                      console.log(selfiePersonalIDFileAttachment, 'selfiePersonalIDFileAttachment')
                      setFieldValue('selfiePersonalIDFileAttachment', selfiePersonalIDFileAttachment[0])
                    },
                  )
                }}
              />
            </label>
          </div>
        </section>
        <section>
          <p className={styles.textTitle}>อัพโหลดไฟล์บัตรประชาชน </p>
          <input
            id={'personalIDFileAttachment'}
            type="file"
            accept=".pdf,.jpeg,.png"
            onChange={(e) => {
              Promise.all(handleUploadFile(e.target.files, `VerificationTeam/${userId}/file/`)).then(
                (personalIDFileAttachment: any) => {
                  console.log('personalIDFileAttachment==>', personalIDFileAttachment)
                  setFieldValue('personalIDFileAttachment', personalIDFileAttachment[0])
                },
              )
            }}
            // multiple
            style={{ display: 'none' }}
          />
          {/* {console.log('Card Id => ', values.userteam && values.userteam.userVerification)} */}
          {values.personalIDFileAttachment ? (
            <p>
              <label className={styles.labelshowfile} htmlFor="personalIDFileAttachment">
                <img className={styles.filelogo} src={filelogo} alt="filelogo" />
                <b>{values.personalIDFileAttachment}</b>
              </label>
            </p>
          ) : (
            <p className="margin-Bottom15px">
              <label className={styles.labelfile} htmlFor="personalIDFileAttachment">
                <span className={styles.primaryuploadspan}>
                  <img className={styles.uploadlogo} src={uploadlogo} alt="uploadlogo" />
                  Upload File
                </span>
                <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
              </label>
            </p>
          )}
        </section>
        <br />
      </div>
      <section className={styles.btnFooterWrapper}>
        <button type="button" className={styles.btnCancle} onClick={() => props.setCreatTeamstep('crtSecond')}>
          ยกเลิก
        </button>
        <button
          type="submit"
          className={styles.btnConfirm}
          onClick={() => {
            const UpdateVerificationVariable = {
              input: {
                userId: userId,
                selfiePersonalIDFileAttachment: values.selfiePersonalIDFileAttachment,
                personalIDFileAttachment: values.personalIDFileAttachment,
              },
            }
            console.log('Input ==>', UpdateVerificationVariable)
            if (!values.selfiePersonalIDFileAttachment) {
              dispatch(allAction.modalAction.setOpenModalAlertStatus('กรุณาแนบภาพเพื่อยืนยันตัวตน', '', 'error'))
            } else {
              CreateaVerifyUserInteam(UpdateVerificationVariable).then((res: any) =>
                console.log('Update  =====>  ', res),
              )
              dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันตัวตนเรียบร้อยแล้ว', '', 'success'))
              navigate('/technician/profileteamlist')
            }

            // CreateaVerifyUserInteam(UpdateVerificationVariable).then((res: any) => console.log('Update  =====>  ', res))
            // dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันตัวตนเรียบร้อยแล้ว', '', 'success'))
            // console.log('UpdateVerificationVariable', UpdateVerificationVariable)
            // {5
            //   validateForm().then((errors: any) => {
            //     // console.log('errors after vadlisad', errors)
            //     if (Object.keys(errors).length === 0) {
            //       console.log('Input ==>', UpdateVerificationVariable)
            //       CreateaVerifyUserInteam(UpdateVerificationVariable).then((res: any) =>
            //         console.log('Update  =====>  ', res),
            //       )
            //       dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันตัวตนเรียบร้อยแล้ว', '', 'success'))
            //       dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
            //       history.goBack()
            //     } else {
            //       console.log('No NextStep')
            //       setTouched(setNestedObjectValues(errors, true))
            //     }
            //   })
            // }
          }}
        >
          ยืนยัน
        </button>
      </section>
    </>
  )
}
export default VerifyTeam
