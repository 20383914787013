import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useFormikContext } from 'formik'
import dropLogo from 'image/droplogo.svg'
import ReportTable from 'components/ReportTable'
import { GetReportPatStatus, refreshPATStatus } from 'adapter/xhr'
import { exportReportStatusExcel } from './exportReportStatusExcel'

const ReportPatStatus: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [loadingRefresh, setLoadingRefresh] = useState(false)

  useEffect(() => {
    GetReportPatStatus({ projectId: values.unpackproject.projectId }).then((res: any) => {
      console.log(res, 'Portstatus')
      const listPat = res.data.data.reportPATStatus
      setFieldValue('patstatus', listPat)
      setFieldValue('showStatus', listPat)
    })

    console.log(values.unpackproject.projectId, 'Project')
    console.log(values.patstatus, 'values.patstatus')
  }, [])

  const refreshPATStatusOnPage = () => {
    setLoadingRefresh(true)
    refreshPATStatus(values.unpackproject.projectId).then((res: any) => {
      console.log('refreshPATStatusOnPage>refreshPATStatus>res', res)
      if (res.data.data.refreshPATStatus !== 'Success') {
        console.log('refreshPATStatus', res.data.data.refreshPATStatus)

        setLoadingRefresh(false)
      } else {
        GetReportPatStatus({ projectId: values.unpackproject.projectId }).then((res: any) => {
          console.log(res, 'Portstatus')
          const listPat = res.data.data.reportPATStatus
          setFieldValue('patstatus', listPat)
          setFieldValue('showStatus', listPat)

          setLoadingRefresh(false)
        })
      }
    })
  }

  const filterStatus = (e: any) => {
    const selectdata = e.target.value
    console.log('test', selectdata)
    const resultData = values.patstatus.filter((a: any) => {
      const data = a.PATStatusType
      return data == selectdata
    })
    setFieldValue('showStatus', resultData)
    console.log(resultData, 'resultData')
  }
  return (
    <div>
      {!values.unpackproject.configReportFieldID && <p>ไม่พบรายงานสถานะเอกสาร PAT</p>}
      {values.unpackproject.configReportFieldID && (
        <div className={styles.reportStatusContainer}>
          {' '}
          <section className={styles.head}>
            <p className={styles.titleHead}>รายงานสถานะเอกสาร PAT</p>
            <div className={styles.rightHead}>
              <button
                className={styles.refreshButton}
                type="button"
                onClick={() => {
                  refreshPATStatusOnPage()
                }}
              >
                {/* {loadingRefresh && <img src={loadingGIF} alt="loading..." style={{ marginRight: '5px' }} />} */}
                {loadingRefresh && <div className={styles.loader} />}
                {loadingRefresh && <span>Loading Data...</span>}
                {!loadingRefresh && <span>Refresh</span>}
              </button>
              <button
                type="button"
                className={'btExport'}
                disabled={loadingRefresh}
                onClick={() => {
                  exportReportStatusExcel(values.unpackproject.projectName, values)
                  // exportReportStatusExcel(values.unpackproject.projectName, values.patstatus)
                }}
              >
                <p>Export</p> <img src={dropLogo} alt="" />
              </button>
            </div>
          </section>
          <div>
            <section className={styles.tableContainer}>
              <ReportTable tablerow={values.patstatus ? values.patstatus : []} />
            </section>
          </div>
          <p>*หมายเหตุ: PAT Submit = เอกสาร PAT ที่ผู้รับเหมาทำการส่งเข้ามาในระบบช่างทำ [รอตรวจสอบ+ผ่าน+รอการแก้ไข]</p>
        </div>
      )}
    </div>
  )
}

export default ReportPatStatus
