import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
// import circlePic from './Ellipse18.svg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { getNotificationByUserId, putNotification, IputNotification } from 'adapter/xhr'

import profilePic from '../profile.svg'
import useInterval from 'hook/useInterval'

const thaidateFormat = (date: string) => {
  const dateResult = new Date(date).toLocaleDateString('th-TH', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  // console.log(new Date(date), 'new Date(date)')
  return dateResult
}

const Notification: React.FC<any> = ({ setIsReadFalseNum }: any) => {
  const [notificationList, setNotificationList] = useState<INotificationObject[]>([])
  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)

  useEffect(() => {
    setIsReadFalseNum(
      notificationList.filter((filterNotify: IputNotification) => {
        return filterNotify.isRead === false
      }).length,
    )
  }, [notificationList])

  // NOW POLLING EVERY 10 SEC. NOT OPTIMAL BUT IDK WHAT TO DO?!
  // TODO: Change to WebHook
  // TODO: BAckend change to pagination with notificaiton
  // https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197
  const NOTIFICATION_REFRESH_INTERVAL = 30 * 1000 // 10 seconds -- changed to 30 sec
  // Initial get notification
  useEffect(() => {
    const userId = decodedAccessToken.userId
    if (userId) {
      getNotificationByUserId(userId).then((NotificationResponse) => {
        if (NotificationResponse) {
          // TODO fix nextToken !! Output ++
          console.log('THIS IS NotificationResponse', NotificationResponse.data.data.notificationByUserId)
          NotificationResponse.data.data.notificationByUserId.items.sort(
            (a: INotificationObject, b: INotificationObject) => {
              const epochA = new Date(a.createdAt).getTime()
              const epochB = new Date(b.createdAt).getTime()
              return epochB - epochA
            },
          )

          setNotificationList(NotificationResponse.data.data.notificationByUserId.items)
          console.timeEnd('useEffect>getNotificationByUserIdListFunction')
        }
      })
    }
  }, [decodedAccessToken.userId])
  // useInterval kills once this notification component is dead! so Landing page isn't affected.
  // use Interval for every 10 seconds!!
  // EDIT: Disable this chit first, just annoying to work with
  if (false)
    useInterval(() => {
      const userId = decodedAccessToken.userId
      if (userId) {
        console.time('useInterval>getNotificationByUserIdListFunction')
        getNotificationByUserId(userId).then((NotificationResponse) => {
          if (NotificationResponse && NotificationResponse.data.data.notificationByUserId) {
            // TODO fix nextToken !! Output ++
            console.log('THIS IS NotificationResponse', NotificationResponse.data.data.notificationByUserId)
            NotificationResponse?.data.data?.notificationByUserId?.items.sort(
              (a: INotificationObject, b: INotificationObject) => {
                const epochA = new Date(a.createdAt).getTime()
                const epochB = new Date(b.createdAt).getTime()
                return epochB - epochA
              },
            )

            setNotificationList(NotificationResponse.data.data.notificationByUserId.items)
            console.timeEnd('useInterval>getNotificationByUserIdListFunction')
          }
        })
      }
    }, NOTIFICATION_REFRESH_INTERVAL)

  const putIsReadByClicking = (notificationElement: INotificationObject) => {
    notificationElement.isRead = true
    putNotification(notificationElement).then((res) => {
      console.log(res, 'ping putNotify isRead = true')
    })
  }

  return (
    <div className={styles.notificationContainer}>
      <h2 className={styles.notificationTopic}>การแจ้งเตือน</h2>
      <section className={styles.notificationContent}>
        <p className={styles.notificationContentTopic}>ล่าสุด</p>
        <article className={styles.notificationSubcontent}>
          {notificationList.map((notificationListElement: INotificationObject, notificationListIndex: number) => {
            return (
              <Link
                key={notificationListIndex}
                to={notificationListElement.toLink === null ? window.location.pathname : notificationListElement.toLink}
                state={
                  notificationListElement.toLink === '/customer/projectmanagementpage/'
                    ? 'BUTTON_VALUE.MASTERPLAN'
                    : null
                }
                onClick={() => {
                  putIsReadByClicking(notificationListElement)
                }}
                className={
                  notificationListElement.isRead === false ? styles.notificationItem2 : styles.notificationItem
                }
              >
                <section className={styles.notificationItemContent}>
                  <img
                    className={styles.notificationItemPic}
                    src={notificationListElement.iconUrl === '' ? profilePic : notificationListElement.iconUrl}
                  />
                  <p
                    className={styles.notificationDescription}
                    dangerouslySetInnerHTML={{ __html: notificationListElement.message }}
                  ></p>
                </section>
                <article className={styles.notificationTimeContainer}>
                  <p className={styles.notificationTime}>{thaidateFormat(notificationListElement.createdAt)}</p>
                </article>
              </Link>
            )
          })}
        </article>
      </section>
    </div>
  )
}

export default Notification

export interface INotificationObject {
  userId: string
  notificationId: string
  message: string
  iconUrl: string
  createdAt: string
  isRead: boolean
  toLink: string
}
