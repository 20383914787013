import React from 'react'
import styles from './index.module.scss'
// import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Field, FieldProps, setNestedObjectValues, useFormikContext } from 'formik'
import { getTheme, mergeStyleSets, Modal, TextField } from '@fluentui/react'
// import closeIcon from '../../../../image/closeButton.svg'
import closeIcon from './closeBtn.svg'
import { CreateIssueManagement, CreateSafetyUser } from 'adapter/xhr'
import allAction from 'state/actions'
import MultiDropdown from 'components/MultiDropdown'
import { handleUploadFile } from 'utils/common'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import uploadlogo from '../../image/uploadlogo.svg'
import filelogo from '../../image/filelogo.svg'

// import TableTeam from './tableTeam'

const ModalSafety: React.FC<any> = (props: any): JSX.Element => {
  const { userId, username } = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  // const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const { values, setValues, setTouched, isValid, validateForm, setFieldValue } = useFormikContext<any>()

  const dispatch = useDispatch()

  return (
    <Modal
      containerClassName={contentStyles.container}
      isOpen={props.open}
      onDismiss={() => {
        props.setOpen(false)
      }}
    >
      <div>
        <div className={styles.modal}>
          <div className={styles.card}>
            <div className={styles.Area}>
              <div className={styles.head}>
                <p className={styles.textHead}>SafetyPassport </p>
                <img
                  src={closeIcon}
                  alt=""
                  onClick={() => {
                    props.setOpen(false)
                  }}
                />
              </div>
              <div className={styles.groupInput}>
                <section className={styles.upload}>
                  <p>กรุณาเลือก SafetyPassport</p>
                  <MultiDropdown
                    options={values.safetyPassportOption}
                    // name={'safetyPassportType'}
                    onchange={(value: string[]) => {
                      Object.keys(values.safetyPassport).map((safetyPassportTypeElement: string) => {
                        if (!value.includes(safetyPassportTypeElement)) {
                          delete values.safetyPassport[`${safetyPassportTypeElement}`]
                        }
                      })
                      value.map((valuesElement: string) => {
                        if (!Object.keys(values.safetyPassport).includes(valuesElement)) {
                          values.safetyPassport[`${valuesElement}`] = { safetyPassportFileAttachment: '' }
                        }
                      })
                      setFieldValue('safetyPassport', values.safetyPassport)
                    }}
                    placeHolder="safetyPassport"
                    values={values.safetyPassport ? Object.keys(values.safetyPassport) : false}
                  />{' '}
                </section>

                <div className={styles.IssueInput}>
                  {values.safetyPassport &&
                    Object.keys(values.safetyPassport)?.map((safetyType: any, safetyIndex: number) => (
                      <div key={safetyIndex}>
                        {console.log('safety==========>', safetyType)}
                        <p>safetyPassportType : {safetyType}</p>{' '}
                        <input
                          // key={safetyType}
                          type="file"
                          id={`safetyPassportFileAttachment${safetyType}`}
                          style={{ display: 'none' }}
                          accept=".pdf,.jpeg,.png"
                          onChange={(e) => {
                            console.log(safetyType, 'safetyType')
                            Promise.all(
                              handleUploadFile(e.target.files, `SafetyPassport/${userId}/files/${safetyType}/`),
                            ).then((safetyPassportFileAttachmentURL: any) => {
                              console.log(safetyType, 'safetyType')
                              setFieldValue(
                                `safetyPassport.${safetyType}.safetyPassportFileAttachment`,
                                safetyPassportFileAttachmentURL[0],
                              )
                            })
                          }}
                        />
                        {console.log(values.safetyPassport)}
                        {!values.safetyPassport[`${safetyType}`].safetyPassportFileAttachment && (
                          <p className="margin-Bottom15px">
                            <label className={styles.labelfile} htmlFor={`safetyPassportFileAttachment${safetyType}`}>
                              <span className={styles.primaryuploadspan}>
                                <img className={styles.uploadlogo} src={uploadlogo} alt="uploadlogo" />
                                Upload File
                              </span>
                              <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
                            </label>
                          </p>
                        )}
                        {values.safetyPassport[`${safetyType}`].safetyPassportFileAttachment && (
                          <p>
                            <label
                              className={styles.labelshowfile}
                              htmlFor={`safetyPassportFileAttachment${safetyType}`}
                            >
                              <img className={styles.filelogo} src={filelogo} alt="filelogo" />
                              <b>
                                {/* Hello */}
                                {values.safetyPassport[`${safetyType}`].safetyPassportFileAttachment
                                  ? values.safetyPassport[`${safetyType}`].safetyPassportFileAttachment
                                  : false}
                              </b>
                            </label>
                          </p>
                        )}
                      </div>
                    ))}
                </div>
              </div>

              <section>
                <article className={styles.btnFooterWrapper}>
                  <button
                    type="button"
                    className={styles.btnCancle}
                    onClick={() => {
                      props.setOpen(false)
                    }}
                  >
                    ยกเลิก
                  </button>

                  <button
                    type="button"
                    className={styles.btnConfirm}
                    onClick={() => {
                      const safetyVariables: any = {
                        // input: {},
                        input:
                          values.safetyPassport &&
                          Object.keys(values.safetyPassport).map((safetyPassportElement: any) => {
                            return {
                              safetyPassportType: safetyPassportElement,
                              safetyPassportFileAttachment:
                                values.safetyPassport[`${safetyPassportElement}`].safetyPassportFileAttachment,
                            }
                          }),
                      }

                      console.log(safetyVariables, 'Input')

                      CreateSafetyUser(safetyVariables).then((res: any) => {
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus(
                            'เพิ่มข้อมูล Safety Passport เรีบร้อยแล้ว',
                            '',
                            'success',
                          ),
                        )
                        props.setOpen(false)

                        console.log(res, 'Create OK')
                      })
                    }}
                  >
                    บันทึก
                  </button>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    background: 'transparent',
    boxShadow: 'transparent',
  },
})
// const EnhancedModalApproveS = withFormik({
//   mapPropsToValues: () => ({
//     post: {},
//     options: [
//       { value: '', text: '' },
//       {
//         value: 'Group',
//         text: 'Group',
//       },
//       {
//         value: 'province',
//         text: 'จังหวัด',
//       },
//       {
//         value: 'region',
//         text: 'ภูมิภาค',
//       },
//     ],
//     isModalOpen: false,
//     fieldList: [
//       {
//         name: 'region',
//         allowedValueList: ['CR', 'ER', 'NER', 'NR', 'SR'],
//       },
//     ],
//     total: 300,
//     //FilterProps
//     Projectname: '',
//     customername: '',
//     startDate: new Date(),
//     endDate: new Date(),
//     price: 0,
//   }),
//   validate: (values) => {
//     const error = {}

//     return error
//   },
//   handleSubmit: (values, { setFieldValue }) => {
//     console.log(JSON.stringify(values))
//   },
// })(ModalApprove)

export default ModalSafety
// export default ModalApprove
