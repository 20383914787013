import React from 'react'
import styles from './index.module.scss'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import SearchIcon from '@mui/icons-material/Search'
import { SxProps, Theme } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'

interface Props {
  _type: 'button' | 'submit'
  _colorBG: 'blue' | 'green' | 'red' | 'white' | 'black' | 'secondary' | 'darkblue' | 'gray' | 'orange'
  _colorText: 'blue' | 'green' | 'red' | 'white' | 'black' | 'secondary' | 'gray'
  _variant: 'text' | 'outlined' | 'contained'
  _isIcon?: 'add' | 'search' | 'reset'
  _text: string
  _functionOnClick?: any
  _sx?: SxProps<Theme>
  disabled?: boolean
}

const listColor: any = [
  {
    name: 'gray',
    code: '#626262',
  },
  {
    name: 'darkblue',
    code: '#004CA8',
  },
  {
    name: 'blue',
    code: '#3A77DA',
  },
  {
    name: 'green',
    code: '#8AC82D',
  },
  {
    name: 'red',
    code: '#FF6161',
  },
  {
    name: 'white',
    code: '#FFFFFF',
  },
  {
    name: 'black',
    code: '#000000',
  },
  {
    name: 'secondary',
    code: '#5357BB',
  },
  {
    name: 'orange',
    code: '#F2994A',
  },
]

const ButtonComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const codeBG: string = listColor.find((ele: any) => ele.name === props._colorBG).code
  const codeText: string = listColor.find((ele: any) => ele.name === props._colorText).code

  return (
    <Button
      fullWidth
      type={props._type}
      sx={props._sx}
      onClick={() => props?._functionOnClick?.()}
      disabled={props.disabled}
      startIcon={
        props._isIcon === 'add' ? (
          <AddCircleOutlineIcon />
        ) : props._isIcon === 'search' ? (
          <SearchIcon />
        ) : props._isIcon === 'reset' ? (
          <AutorenewIcon />
        ) : (
          ''
        )
      }
      variant={props._variant}
      size="small"
      style={{
        backgroundColor: codeBG,
        color: codeText,
        height: 'inherit',
        borderColor: codeText,
        fontFamily: 'Kanit',
      }}
    >
      <span>{props._text}</span>
    </Button>
  )
}

export default ButtonComponent
