import { THAILAND_PROVINCE_MAPPER, THAILAND_REGION2CODE_MAPPER } from 'components/commons/thailandFact'
import ExcelJS from 'exceljs'
const colName = (n: number): string => {
  const ordA = 'A'.charCodeAt(0)
  const ordZ = 'Z'.charCodeAt(0)
  const len = ordZ - ordA + 1

  let s = ''
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s
    n = Math.floor(n / len) - 1
  }
  return s
}

function readFile(file: File): Promise<Buffer> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      resolve(reader.result as Buffer)
    }
  })
}

const getSafetyPassport = (...saftyPassports: any[]) => {
  const saftyPassportList = ['AIS_C', 'AIS_E', 'AIS_EE', 'AIS_H', 'AIS_G']
  return saftyPassports
    .map((safety: string, index) => {
      if (safety.trim())
        return {
          safetyPassportType: saftyPassportList[index],
          safetyPassportFileAttachment: safety,
        }
    })
    .filter((e) => e)
}

const RELATION: any = {
  โสด: 'SINGLE',
  สมรส: 'MARRIED',
  หย่า: 'DIVORCE',
}

const GENDER: any = {
  ชาย: 'MALE',
  หญิง: 'FEMALE',
  เพศทางเลือก: 'LGBT',
}

const TECHNICIAN_WORK_TYPE: any = {
  ช่างไฟฟ้า: 'Electrician',
  ช่างสี: 'Painter',
  ช่างประปา: 'Plumber',
  ช่างปูน: 'Plasterer',
  ช่างเหล็ก: 'Metalworker',
  ช่างไม้: 'Carpenter',
  ช่างยนต์: 'Mechanic',
  ช่างติดตั้ง: 'Installer',
}

const converseCSVToJSON = async (file: File) => {
  const workbook = new ExcelJS.Workbook()
  const buffer = await readFile(file)
  const csv = await workbook.xlsx.load(buffer)

  const sheet = csv.getWorksheet('teams')

  const mapData: any[][] = []

  sheet.eachRow((row, rindex) => {
    mapData[rindex - 1] = []
    for (let i = 0; i <= 24; i++) {
      const cell = sheet.getCell(`${colName(i + 1)}${rindex + 2}`)

      const value = (cell.value as any)?.text ? (cell.value as any)?.text : cell.value
      mapData[rindex - 1][i] = value || ' '
    }
  })

  // total 24 columns
  return mapData
    .filter((d) => !d.every((e) => e === ' '))
    .map((value: string[]) => {
      let birthDate: string | null = null
      try {
        birthDate = new Date(value[2]).toISOString().split('T')[0]
      } catch (error) {}

      return {
        firstName: value[0],
        lastName: value[1],
        birthDate: birthDate,
        nickName: value[3],
        gender: GENDER[value[4]] || null,
        personalRelation: RELATION[value[5]] || null,
        personalID: value[6],
        phone: value[7],
        addressDetail: value[8],
        email: value[9],
        emergencyContact: {
          firstName: value[10],
          lastName: value[11],
          phone: value[12],
          relation: value[13],
        },
        technicianJobType: value[14]
          ?.split(',')
          .map((e) => TECHNICIAN_WORK_TYPE[e.trim()])
          .filter(Boolean),
        serviceRegion: value[15]
          ?.split(',')
          .map((e) => THAILAND_REGION2CODE_MAPPER[e.trim() as any])
          .filter(Boolean),
        serviceProvince: value[16]
          ?.split(',')
          .map((e) => (THAILAND_PROVINCE_MAPPER as any)[e.trim() as any])
          .filter(Boolean),
        inputVerification: {
          personalIDFileAttachment: value[17],
          selfiePersonalIDFileAttachment: value[18],
        },
        isSafetyPassport: getSafetyPassport(value[19], value[20], value[21], value[22], value[23]).length !== 0,
        inputSafetyPassport: getSafetyPassport(value[19], value[20], value[21], value[22], value[23]),
      }
    })
}

export default converseCSVToJSON
