import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Field, setNestedObjectValues, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'
import ImagesGroup from 'components/imagesGroup'
import MultiDropdown from 'components/MultiDropdown'
import uploadlogo from '../../image/uploadlogo.svg'
import filelogo from '../../image/filelogo.svg'
import { handleUploadFile } from 'utils/common'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { THAILAND_PROVINCE_MAPPER, THAILAND_REGION_MAPPER } from '../../components/commons/thailandFact'
import { getUserProfile, UpdateUserProfile } from 'adapter/xhr'

const EditProfileTeam: React.FC = (): JSX.Element => {
  const { values, errors, touched, setFieldValue, validateForm, setTouched } = useFormikContext<any>()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userId } = useParams<any>()

  React.useEffect(() => {
    getUserProfile().then((profileres: any) => {
      const getdata = profileres.data.data.getUser
      console.log('Get Profile', profileres)
      setFieldValue('profile', profileres.data.data.getUser)
      //GetData
      setFieldValue('myWorkTeamName', getdata.myWorkTeamName)
      setFieldValue('myWorkTechnicianJobType', getdata.myWorkTechnicianJobType)
      setFieldValue('myWorkserviceProvince', getdata.myWorkserviceProvince)
      setFieldValue('myWorkserviceRegion', getdata.myWorkserviceRegion)
      setFieldValue('myWorkDescription', getdata.myWorkDescription)
      setFieldValue('myWorkFileAttachment', getdata.myWorkFileAttachment)
      setFieldValue('myWorkImageList', getdata.myWorkImageList)
    })
  }, [])
  const imageFileRef = useRef<any>([])
  // imageFileRef.current = []
  // const addToRefs = (el: any) => {
  //   if (el && !imageFileRef.current.includes(el)) {
  //     imageFileRef.current.push(el)
  //   }
  // }

  return (
    <>
      <div className={styles.center}>
        <div className={styles.container}>
          {' '}
          <div className={styles.flexbox}>
            <section className={styles.inputBox}>
              {' '}
              <article className={styles.inputGroup}>
                <p>ชื่อองค์กร /ทีม</p>
                <Field className={styles.inputfiled} placeholder="ชื่อองค์กร / สังกัด" name="myWorkTeamName" />
              </article>
              <article className={styles.inputGroup}>
                <p>ประเภทงานช่าง</p>
                <MultiDropdown
                  options={values.technicianWorkType}
                  name="myWorkTechnicianJobType"
                  placeHolder="เลือกประเภทงานช่างทั้งหมด"
                  values={values.myWorkTechnicianJobType}
                  // disabled
                />
              </article>
              <article className={styles.inputGroup}>
                <p>ภูมิภาคที่ให้บริการ</p>
                <MultiDropdown
                  options={values.optionRegion}
                  name="myWorkserviceRegion"
                  placeHolder="เลือกภูมิภาคที่ให้บริการ"
                  values={values.myWorkserviceRegion}
                  // disabled
                />
              </article>
              <article className={styles.inputGroup}>
                <p>จังหวัดที่ให้บริการ</p>
                <MultiDropdown
                  options={values.optionProvince}
                  name="myWorkserviceProvince"
                  placeHolder="จังหวัดที่ให้บริการ"
                  values={values.myWorkserviceProvince}
                />
              </article>
              <section>
                <p>
                  <b>อัพโหลดไฟล์เอกสาร</b>
                </p>
                <input
                  id={'myWorkFileAttachment'}
                  type="file"
                  accept=".pdf,.jpeg,.png"
                  onChange={(e) => {
                    Promise.all(handleUploadFile(e.target.files, `WorkFileAttachment/${userId}/file/`)).then(
                      (myWorkFileAttachment: any) => {
                        setFieldValue('myWorkFileAttachment', myWorkFileAttachment)
                      },
                    )
                  }}
                  multiple
                  style={{ display: 'none' }}
                />
                {console.log(values.profile && values.profile.myWorkFileAttachment, 'File')}
                <span className="errorMessage">
                  <ErrorMessage name="myWorkFileAttachment" />
                </span>

                {values.myWorkFileAttachment === null && (
                  <p className="margin-Bottom15px">
                    <label className={styles.labelfile} htmlFor="myWorkFileAttachment">
                      <span className={styles.primaryuploadspan}>
                        <img className={styles.uploadlogo} src={uploadlogo} alt="uploadlogo" />
                        Upload File
                      </span>
                      <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
                    </label>
                  </p>
                )}
                {values.myWorkFileAttachment &&
                  values.myWorkFileAttachment.map((fileURL: string, fileIndex: number) => {
                    return (
                      <p className="margin-Bottom15px" key={fileIndex}>
                        <label className={styles.labelshowfile} htmlFor="myWorkFileAttachment">
                          <img className={styles.filelogo} src={filelogo} alt="filelogo" />
                          <p className={styles.fileText}>{fileURL.split('/')[fileURL.split('/').length - 1]}</p>
                        </label>
                      </p>
                    )
                  })}
              </section>
            </section>
            <section className={styles.imagesBox}>
              {' '}
              <div className={styles.imgUploadeContainer}>
                {values.profile && (
                  <div>
                    <div className={styles.imgUploadeContainer}>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          Promise.all(
                            handleUploadFile(e.target.files, `Profile/${values.profile.userId}/images/`),
                          ).then((fileLocationList: any[]) => {
                            setFieldValue(`profile.myWorkImageList`, fileLocationList)
                          })
                        }}
                        style={{ display: 'none' }}
                        ref={imageFileRef}
                        multiple
                      />
                      <span className={styles.imageDescription}>เพิ่มไฟล์รูปได้สูงสุด 4 รูป</span>
                      <ImagesGroup
                        onClickImage={() => {
                          imageFileRef.current.click()
                        }}
                        image1={
                          values.profile.myWorkImageList &&
                          values.profile.myWorkImageList &&
                          values.profile.myWorkImageList[0]
                            ? values.profile.myWorkImageList[0]
                            : ''[0]
                            ? values.profile.myWorkImageList[0]
                            : ''
                        }
                        image2={
                          values.profile.myWorkImageList && values.profile.myWorkImageList[1]
                            ? values.profile.myWorkImageList[1]
                            : ''
                        }
                        image3={
                          values.profile.myWorkImageList && values.profile.myWorkImageList[2]
                            ? values.profile.myWorkImageList[2]
                            : ''
                        }
                        image4={
                          values.profile.myWorkImageList && values.profile.myWorkImageList[3]
                            ? values.profile.myWorkImageList[3]
                            : ''
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          <article className={styles.exBox}>
            <p className={styles.texthead}>ประสบการณ์/ประวัติโดยย่อ</p>
            <Field
              as="textarea"
              placeholder="ประสบการณ์/ประวัติโดยย่อ"
              className={styles.inputfiled}
              name="myWorkDescription"
            />
          </article>
        </div>

        <section className={styles.btnFooterWrapper}>
          <button type="button" className={styles.btnCancle} onClick={() => navigate('/technician/profileteamlist')}>
            Cancle
          </button>
          <button
            type="submit"
            className={styles.btnConfirm}
            onClick={() => {
              const UpdateUserProfileVariables = {
                input: {
                  myWorkTeamName: values.myWorkTeamName,
                  myWorkserviceProvince: values.myWorkserviceProvince,
                  myWorkTechnicianJobType: values.myWorkTechnicianJobType,
                  myWorkDescription: values.myWorkDescription,
                  myWorkserviceRegion: values.myWorkserviceRegion,
                  myWorkFileAttachment: values.myWorkFileAttachment,
                  myWorkImageList: values.profile.myWorkImageList,
                },
              }
              validateForm().then((errors) => {
                console.log('errors after vadlisad', errors)
                if (Object.keys(errors).length === 0) {
                  console.log(UpdateUserProfileVariables, 'UpdateUserProfileVariables')
                  console.log('value ==>', values)
                  UpdateUserProfile(UpdateUserProfileVariables).then((res: any) => console.log('Update  =====>  ', res))
                  dispatch(allAction.modalAction.setOpenModalAlertStatus('แก้ไขข้อมูลเรียบร้อยแล้ว', '', 'success'))
                  navigate(-1)
                } else {
                  setTouched(setNestedObjectValues(errors, true))
                }
              })
            }}
          >
            Save
          </button>
        </section>
      </div>
    </>
  )
}
// export default ProfileTeamList
const EnhancedEditProfileTeam = withFormik({
  mapPropsToValues: () => ({
    myWorkTeamName: '',
    myWorkFileAttachment: [''],
    myWorkDescription: '',
    myWorkImageList: [''],
    myWorkTechnicianJobType: [],
    technicianWorkType: [
      {
        value: 'Electrician',
        text: 'ช่างไฟฟ้า',
      },
      {
        value: 'Painter',
        text: 'ช่างสี',
      },
      {
        value: 'Plumber',
        text: 'ช่างประปา',
      },
      {
        value: 'Plasterer',
        text: 'ช่างปูน',
      },
      {
        value: 'Metalworker',
        text: 'ช่างเหล็ก',
      },
      {
        value: 'Carpenter',
        text: 'ช่างไม้',
      },
      {
        value: 'Mechanic',
        text: 'ช่างยนต์',
      },
      {
        value: 'Installer',
        text: 'ช่างติดตั้ง',
      },
    ],
    myWorkserviceRegion: [],
    optionRegion: [
      ...Object.entries(THAILAND_REGION_MAPPER).map((region: any) => ({
        value: region[0],
        text: region[1],
      })),
    ],
    myWorkserviceProvince: [],
    optionProvince: [
      ...Object.entries(THAILAND_PROVINCE_MAPPER).map((region: any) => ({
        value: region[1],
        text: region[0],
      })),
    ],
  }),
  validate: (values) => {
    const errors: any = {}
    if (values.myWorkFileAttachment?.length > 3) {
      errors.myWorkFileAttachment = 'อัปโหลดไฟล์ได้สูงสุด 3 ไฟล์'
    }
    return errors
  },
  handleSubmit: (values, { setFieldValue }) => {
    //
    console.log('Valueeeeeeeeeee=>>>>>>>>>>', values)
    console.log(JSON.stringify(values))
  },
})(EditProfileTeam)

export default EnhancedEditProfileTeam
