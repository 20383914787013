import React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useFormikContext } from 'formik'

import styles from './index.module.scss'
import { FormikInputText } from 'components/commons/input/formikInputText'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import { columnOption } from 'utils/common'

import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import addSVG from 'image/plusCircleGreen.svg'
import closeSVG from 'image/closeCircleRed.svg'

interface Props {
  headerList: { colName: string; name: string }[]
  name?: string
}

const CardHeaderPat: React.FC<Props> = ({ headerList, name }: Props) => {
  const { setFieldValue } = useFormikContext<any>()

  const reOrderList =
    headerList &&
    headerList.reduce((accumulator: any, currentValue, currentIndex, array: any) => {
      if (currentIndex % 2 === 0) {
        const arr = array.map((data: any, idx: number) => ({ ...data, idx: idx })).slice(currentIndex, currentIndex + 2)
        accumulator.push(arr)
      }
      return accumulator
    }, [])

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const items = reorder(reOrderList, result.source.index, result.destination.index)
    let newArr: any[] = []
    for (let i = 0; i < items.length; i++) {
      newArr = newArr.concat(items[i])
    }
    setFieldValue(`${name}`, newArr)
  }

  const handleUpdateRow = () => {
    const arrHeaderList = [...headerList, { name: '', colName: '' }, { name: '', colName: '' }]
    setFieldValue(`${name}`, arrHeaderList)
  }

  const handleDeleteRow = (idx: number) => {
    if (idx === 0) return
    for (let index = 0; index < 2; index++) {
      reOrderList[idx].map((item: any) => {
        return headerList.splice(item.idx, 1)
      })
    }
    setFieldValue(`${name}`, headerList)
  }

  return (
    <div className={`contentWrapper ${styles.container}`}>
      <div className={styles.card}>
        <p className={styles.text}>Header</p>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div className={styles.box} ref={provided.innerRef}>
                {reOrderList &&
                  reOrderList.map((item: any, idx: number) => (
                    <Draggable key={idx} draggableId={`${idx}`} index={idx}>
                      {(provided, snapshot) => (
                        <div
                          aria-checked={snapshot.isDragging}
                          className={styles.wrapperInput}
                          key={idx}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            className={styles.icon}
                            onClick={(e) => e.stopPropagation()}
                            {...provided.dragHandleProps}
                          >
                            <DragIndicatorIcon />
                          </div>
                          {item &&
                            item.map((data: any, index: number) => (
                              <div className={styles.wrapperData} key={index}>
                                <FormikInputText
                                  className={styles.inputLineBottom}
                                  name={`${name}[${data.idx}].name`}
                                  placeholder="กรอกชื่อ"
                                />
                                <FormikInputSelect
                                  className={styles.inputSelect}
                                  name={`${name}[${data.idx}].colName`}
                                  option={columnOption}
                                  // defaultValue={''}
                                />
                              </div>
                            ))}

                          <img src={closeSVG} alt="" onClick={() => handleDeleteRow(idx)} />
                          <img src={addSVG} alt="" onClick={handleUpdateRow} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}

export default CardHeaderPat
