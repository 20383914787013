import * as React from 'react'
import styles from './index.module.scss'
import Slider from '@mui/material/Slider'
import { TextField } from '@fluentui/react'
import { useParams } from 'react-router-dom'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import moment from 'moment'
// import { FormControl, InputAdornment, InputLabel, IconButton, OutlinedInput } from '@mui/material'
import {
  THAILAND_REGION,
  THAILAND_PROVINCE_MAPPER,
  THAILAND_REGION_MAPPER,
} from '../../../components/commons/thailandFact'

//svg Image
import FileIcon from '../../../image/filestick.svg'
// import fileLogo from '../../../image/fileIcon.svg'
import fileLogo from '../../../image/fileIcon.svg'

import TechLogo from '../../../image/tech.svg'
import ProfileIcon from '../../../image/profile.svg'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { getPostfilter, IProject, ListPostByUserId } from 'adapter/xhr'
import backIcon from '../../../image/back.svg'
import ModalApprove from './modal'
import TableShow from 'components/TableShow'
import ModalTeam from './modalteam'
import ModalAlert from 'components/commons/modalAlert'
import { downloadFile, packPostAndJobAppToJobAppList } from 'utils/common'
import companySVG from './mockUpCompanyImg.svg'
import { useDispatch } from 'react-redux'
import allAction from 'state/actions'
import { acceptJobapplication } from 'adapter/xhr'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import ImagesSlideShow from 'components/imagesSlideShow'
// import ModalApprove from './modal'

const FilterTech: React.FC<any> = (props: any) => {
  // const { values, setFieldValue } = useFormikContext<IFormikValues>()
  const filename = (file: string) => decodeURI(file.split('/')[file.split('/').length - 1])

  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  const { userId, username } = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const { projectId, postId } = useParams<any>()

  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const dispatch = useDispatch()

  const queryNewPost = () => {
    getPostfilter({ postId: postId }).then((getbypost: any) => {
      console.log('GetPostFilter =>', getbypost.data.data.post)
      const clonePost = getbypost.data.data.post
      clonePost.workTypePostList.map((workTypePost: any) => {
        workTypePost.totalJobList.map((totalPost: any) => {
          workTypePost.listWorkTypeJobApplication.items.map((jobApp: any) => {
            jobApp.totalJobList.map((totalJobApp: any) => {
              if (totalJobApp.fieldValue === totalPost.fieldValue) {
                totalJobApp.totalQty = totalPost.totalQty
                totalJobApp.balanceQty = totalPost.balanceQty
              }
            })
          })
        })
      })
      // const packJobApplication = packPostAndJobAppToJobAppList(clonePost)
      // const packJobApplication =
      // packJobApplication.project.workTypeList.map((workTypeProject: any) => {
      //   return packJobApplication.workTypePostList.map((workTypePost: any) => {
      //     workTypePost.imageURLList = workTypeProject.imageURLList
      //     workTypePost.jobUnitPrice = workTypeProject.jobUnitPrice
      //     return workTypePost
      //   })
      // })
      // console.log(packJobApplication, 'packJobApplication')
      const customOptionField: any = []
      clonePost.workTypePostList.map((workTypePost: any) => {
        customOptionField.push({ text: workTypePost.fieldID, value: workTypePost.fieldID })
      })
      setFieldValue('options', [...values.options, ...customOptionField])
      setFieldValue('getBypost', clonePost)
      setFieldValue('showPost', clonePost)
    })
  }

  React.useEffect(() => {
    queryNewPost()
  }, [])

  // BMR: 'กรุงเทพและปริมณฑล',
  // CR: 'ภาคกลาง',
  // NR: 'ภาคเหนือ',
  // NER: 'ภาคตะวันออกเฉียงเหนือ',
  // ER: 'ภาคตะวันออก',
  // WR: 'ภาคตะวันตก',
  // SR: 'ภาคใต้',
  const MockSaftey = [
    {
      name: 'AIS_G',
    },
    {
      name: 'AIS_E',
    },
    {
      name: 'AIS_D',
    },
    {
      name: 'AIS_DD',
    },
  ]

  function valuetext(value: any) {
    return `${value}`
  }

  const RegionEnum: any = {
    BMR: 'กรุงเทพและปริมณฑล',
    CR: 'ภาคกลาง',
    NR: 'ภาคเหนือ',
    NER: 'ภาคตะวันออกเฉียงเหนือ',
    ER: 'ภาคตะวันออก',
    WR: 'ภาคตะวันตก',
    SR: 'ภาคใต้',
  }

  const [query, setQuery] = React.useState('')
  const tableHeader = [
    // 'Group',
    'ทั้งหมด',
    'คงเหลือ',
    'สมัคร',
    'อนุมัติ',
  ]

  const filterregion = (e: any) => {
    const regionselect = e.target.value
    console.log('test', regionselect)

    console.log(values.getBypost, 'Post')

    // const resultData = values.postrespon.filter((a: any) => {
    //   const data = a.project.isPassport
    //   return data == selectdata
    // })
    // setFieldValue('postrespon', resultData)
  }

  return (
    <div className={styles.center}>
      <div>
        {/* Search Box */}
        {/* <div className={styles.topbar}>
          <img src={backIcon} alt="" className={styles.Icon} onClick={() => history.goBack()} />
          <p className={styles.textback}>ย้อนกลับ</p>
        </div> */}
        <div className={styles.filterbox}>
          <div className={styles.oneside}>
            <div className={styles.flexfiled}>
              {/* <div className={styles.boxtext}>
                <p>ภูมิภาคทั้งหมด</p>
                <FormikInputSelect
                  className={styles.inputfiled}
                  name="region"
                  option={[
                    ...Object.entries(THAILAND_REGION_MAPPER).map((region: any) => ({
                      value: region[0],
                      text: region[1],
                    })),
                  ]}
                  defaultValue={''}
                  onChange={(e: any) => {
                    filterregion(e)
                  }}
                />
              </div> */}
              {/* <div className={styles.boxtext}>
                <p>จังหวัดทั้งหมด</p>
                <FormikInputSelect
                  className={styles.inputfiled}
                  name={'province'}
                  option={[
                    ...Object.entries(THAILAND_PROVINCE_MAPPER).map((region: any) => ({
                      value: region[1],
                      text: region[0],
                    })),
                  ]}
                  defaultValue={''}
                />{' '}
              </div> */}
            </div>
            <div className={styles.flexfiled}>
              <div className={styles.boxtext}>
                <p>ชื่อประเภทงาน</p>
                <input
                  type="text"
                  className={styles.inputfiled}
                  value={props.textFilter}
                  placeholder="ค้นหาชื่อประเภทงาน"
                  id="textfiled"
                  onChange={(event) => setQuery(event.target.value)}
                />
              </div>
              <div className={styles.boxtext}>
                <p>จำนวนทีมงาน</p>

                <Field type="number" className={styles.inputfiled} placeholder="ค้นหาจำนวนทีมงาน" name="filterCount" />
                {/* <input
                  type="number"
                  min={0}
                  className={styles.inputfiled}
                  value={props.textFilter}
                  placeholder="ค้นหาจำนวนทีมงาน"
                  id="textfiled"
                  // onChange={(event) => setQuery(event.target.value)}
                  name="filterCount"
                /> */}
              </div>
            </div>
          </div>
          {/* <div className={styles.leftside}>
            {' '}
            <div className={styles.radioform}>
              <span className={styles.radio}>
                {' '}
                <input type="checkbox" value="safety" name="safety" defaultChecked /> มีบัตร Safety Passport
              </span>
            </div>
            <div className={styles.btnform}>
              <button className={styles.searchBt} type="button">
                ค้นหา
              </button>
            </div>
          </div> */}
        </div>

        <div className={styles.cardArea}>
          {values.getBypost && (
            <div className={styles.cardFilter}>
              <section className={styles.statusBar}>
                {' '}
                <p>
                  <span className={styles.head}>สร้างเมื่อ : </span>
                  {moment(`${values.getBypost?.createdAt}`).format('L')}
                </p>{' '}
                <p>
                  {' '}
                  <span className={styles.head}>สถานะ : </span>
                  {values.getBypost.project?.status}
                </p>
              </section>
              <div className={styles.headtext}>
                <p className={styles.texthead}>{values.getBypost?.project?.projectName}</p>
                <p className={styles.projecttext}>{values.getBypost?.title}</p>
                {values.getBypost.project?.projectDescription ? (
                  <section>
                    {' '}
                    <p>รายละเอียดของโครงการ</p>
                    <p>{values.getBypost.project?.projectDescription}</p>
                  </section>
                ) : (
                  ''
                )}
                <span>
                  ระยะเวลาโครงการ{' '}
                  <span>
                    {values.getBypost.project.startDate} - {values.getBypost.project.endDate}
                  </span>
                </span>
              </div>
              {console.log(
                values.getBypost.workTypePostList.map((item: any) => item.listWorkTypeJobApplication),
                '{values.getBypost.workTypePostList',
              )}
              {values.getBypost.workTypePostList &&
                values.getBypost.workTypePostList.map((workdata: any, workdataindex: number) => (
                  <div className={styles.workcard} key={workdataindex}>
                    <div className={styles.headtextJob}>
                      <p className={styles.textwork}>{workdata?.workType}</p>
                      <span className={styles.texttotal}>
                        <span className={styles.textsubs}>ทั้งหมด</span>
                        {workdata.totalJobList.map((total: any) => total.totalQty).reduce((a: any, b: any) => a + b, 0)}
                        <span className={styles.textsub}>งาน</span>
                      </span>
                    </div>

                    {workdata.listWorkTypeJobApplication.items &&
                      workdata.listWorkTypeJobApplication.items
                        .filter((jobdata: any) => {
                          console.log(jobdata.jobApplication, 'jobdata')
                          if (values.filterCount == null) {
                            return jobdata
                          } else if (jobdata.jobApplication?.technicianList?.length === values.filterCount) {
                            return jobdata
                          }
                        })
                        .map((jobdata: any, jobIndex: number) => (
                          <div className={styles.companycard} key={jobIndex}>
                            <div className={styles.areaCard}>
                              <div className={styles.leftside}>
                                {console.log(jobdata.jobApplication.user, 'jobdatajobdatajobdatajobdatajobdatajobdata')}
                                <p className={styles.textcompany}>
                                  <span>ชื่อบริษัท/ชื่อหัวหน้างาน : </span>
                                  <p className={styles.textTeamname}>
                                    {jobdata.jobApplication.user.myWorkTeamName
                                      ? jobdata?.jobApplication?.user?.myWorkTeamName
                                      : '-'}
                                  </p>
                                </p>
                                <section className={styles.safetypill}>
                                  {jobdata?.jobApplication?.user?.safetyPassport.map(
                                    (safetyPassportElement: any, safetyPassportIndex: number) => {
                                      return (
                                        <div className={styles.pill} key={safetyPassportIndex}>
                                          <p>{safetyPassportElement?.safetyPassportType}</p>
                                        </div>
                                      )
                                    },
                                  )}
                                </section>
                                <p className={styles.title}>ข้อมูลการติดต่อ</p>
                                {jobdata?.jobApplication?.user?.addressDetail && (
                                  <span className={styles.detail}>
                                    Address : ​{jobdata?.jobApplication?.user?.addressDetail}
                                  </span>
                                )}
                                <span className={styles.detail}>
                                  Telephone : ​{jobdata?.jobApplication?.user?.phone}​
                                </span>
                                <span className={styles.detail}>E-Mail : ​{jobdata?.jobApplication?.user?.email}</span>
                                <div className={styles.image}>
                                  <ImagesSlideShow imageList={jobdata?.jobApplication?.user?.myWorkImageList} />
                                </div>
                                {jobdata?.jobApplication?.user?.myWorkFileAttachment?.map(
                                  (fileitem: any, fileIndex: number) => (
                                    <div className={styles.showfile} key={fileIndex}>
                                      <span className={styles.textfile}>
                                        <img src={fileLogo} alt="image" />
                                        <div
                                          className={styles.filetext}
                                          onClick={() => downloadFile(fileitem, filename(fileitem))}
                                        >
                                          <p>{filename(fileitem)}</p>
                                        </div>
                                      </span>
                                    </div>
                                  ),
                                )}
                                <div className={styles.subdetail}>
                                  <p className={styles.title}>ประสบการณ์/ประวัติโดยย่อ</p>
                                  <p className={styles.detailtext}>
                                    {jobdata?.jobApplication?.user?.myWorkDescription}
                                  </p>
                                </div>
                              </div>
                              <div className={styles.rigthside}>
                                <div className={styles.head}>
                                  <div className={styles.countTeam}>
                                    <p className={styles.title}>
                                      จำนวนทีมงาน {jobdata.jobApplication?.technicianList?.length} คน
                                    </p>
                                    <img
                                      src={ProfileIcon}
                                      alt=""
                                      onClick={() => {
                                        setFieldValue('isModalTeamOpen', true, false)
                                        setFieldValue('selectedModalTeam', {
                                          username: jobdata.createdByUserID,
                                          selectedTeamList: jobdata.jobApplication.technicianList,
                                        })
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className={styles.table}>
                                  <TableShow
                                    tableTopic={values.getBypost.user.username}
                                    tableHeader={tableHeader}
                                    tableRowList={workdata}
                                    workType={jobdata}
                                    tableIndex={0}
                                  />
                                </div>
                                <div className={styles.buttonbottom}>
                                  {jobdata.status !== 'ACCEPT' && jobdata.status !== 'REJECT' ? (
                                    <>
                                      <button
                                        type="button"
                                        className={styles.btCancle}
                                        onClick={() => {
                                          dispatch(
                                            allAction.modalConfirmAction.setOpenModalConfirm(
                                              'ยืนยันการปฎิเสธงาน',
                                              'คุณต้องการปฎิเสธงานทั้งหมด',
                                              `ประเภทงาน ${workdata.workType}`,
                                              'ใช่, ทำรายการ',
                                              () => {
                                                const selectedJobApp = {
                                                  postID: postId,
                                                  workType: workdata.workType,
                                                  workTypeNumber: workdataindex + 1,
                                                  fieldID: workdata.fieldID,
                                                  imageURLList: workdata.imageURLList,
                                                  jobUnitPrice: workdata.jobUnitPrice,
                                                  totalJobList: jobdata.totalJobList,
                                                  applicationID: jobdata.jobApplication.applicationID,
                                                  createdByUserID: jobdata.jobApplication.createdByUserID,
                                                }
                                                const cloneTotalJobList = selectedJobApp.totalJobList
                                                const packTotalJobList = cloneTotalJobList.map(
                                                  (totalJobElement: any) => {
                                                    delete totalJobElement.totalQty
                                                    delete totalJobElement.balanceQty
                                                    return totalJobElement
                                                  },
                                                )
                                                const variables = {
                                                  input: {
                                                    postID: selectedJobApp.postID,
                                                    applicationID: selectedJobApp.applicationID,
                                                    workType: selectedJobApp.workType,
                                                    status: 'REJECT',
                                                    totalJobList: packTotalJobList,
                                                  },
                                                }
                                                console.log(variables, 'variables')
                                                acceptJobapplication(variables).then((res: any) => {
                                                  console.log(res, 'accept ressssssssssssss')
                                                  setTimeout(() => {
                                                    queryNewPost()
                                                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                                                    const initialSelectedJobApp = {
                                                      workTypeNumber: 0,
                                                      workType: '',
                                                      jobUnitPrice: 0,
                                                      totalJobList: [],
                                                      imageURLList: [''],
                                                      fieldID: '',
                                                      createdByUserID: '',
                                                    }
                                                    setFieldValue('selectedJobApp', initialSelectedJobApp)
                                                  }, 1000)
                                                })
                                              },
                                            ),
                                          )
                                        }}
                                      >
                                        ปฏิเสธงานทั้งหมด
                                      </button>
                                      <button
                                        className={styles.btApprove}
                                        onClick={() => {
                                          console.log(jobdata, 'jobdata')
                                          const packModal = {
                                            postID: postId,
                                            workType: workdata.workType,
                                            workTypeNumber: workdataindex + 1,
                                            fieldID: workdata.fieldID,
                                            imageURLList: workdata.imageURLList,
                                            jobUnitPrice: workdata.jobUnitPrice,
                                            totalJobList: jobdata.totalJobList,
                                            applicationID: jobdata.jobApplication.applicationID,
                                            createdByUserID: jobdata.jobApplication.createdByUserID,
                                          }
                                          setFieldValue('selectedJobApp', packModal)
                                          setFieldValue('isModalOpen', true, false)
                                        }}
                                      >
                                        อนุมัติงาน
                                      </button>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <ModalTeam
        isOpen={values.isModalTeamOpen}
        onDismiss={() => {
          setFieldValue('isModalTeamOpen', false, false)
          setFieldValue('selectedModalTeam', {
            username: '',
            selectedTeamList: [],
          })
        }}
      />
      <ModalApprove //This is a Modal before going to add projectpage
        username={username}
        isOpen={values.isModalOpen}
        onDismiss={() => {
          setFieldValue('isModalOpen', false, false)
          const initialSelectedJobApp = {
            workTypeNumber: 0,
            workType: '',
            jobUnitPrice: 0,
            totalJobList: [],
            imageURLList: [''],
            fieldID: '',
          }
          setFieldValue('selectedJobApp', initialSelectedJobApp)
        }}
        queryNewPost={queryNewPost}
      />
    </div>
  )
}

interface IButtonSelection {
  text: string
  value: BUTTON_VALUE
  img?: any
}

enum BUTTON_VALUE {
  DASHBOARD = 1,
  MASTERPLAN,
  PAT_MANAGEMENT,
}

const CUSTOMER_SELECTION: IButtonSelection[] = [
  {
    text: 'คัดเลือกผู้รับเหมาที่นี่',
    value: BUTTON_VALUE.DASHBOARD,
    img: <img src={TechLogo} alt="" />,
  },
]

const TECHNICIAN_SELECTION: IButtonSelection[] = [
  {
    text: 'สมัครงาน',
    value: BUTTON_VALUE.MASTERPLAN,
  },
]

interface Tabbar {
  text: string
}

const CUSTOMER_TAB: Tabbar[] = [
  {
    text: 'ประกาศของฉัน',
  },
  {
    text: 'ประวัติ',
  },
]

const TECHNICIAN_TAB: Tabbar[] = [
  {
    text: 'สมัครงาน',
  },
  {
    text: 'ประวัติการสมัครงานช่างทำ',
  },
]

const EnhancedFilterTech = withFormik({
  mapPropsToValues: () => ({
    selectedModalTeam: {
      username: '',
      selectedTeamList: [],
    },
    selectedJobApp: {
      workTypeNumber: 0,
      workType: '',
      jobUnitPrice: '',
      totalJobList: [{ fieldValue: '', totalQty: 0, balanceQty: 0, registerQty: 0, approveQty: 0 }],
      imageURLList: [''],
      fieldID: '',
      createdByUserID: '',
    },
    // jobApplicationList: [],
    post: {
      projectID: '',
      createdByUserID: '',
      title: '',
      description: '',
      startDate: '',
      endDate: '',
      imageURLList: [''],
      fileAttachmentList: [''],
      workTypePostList: [
        {
          workType: '',
          isLinked: false,
          fieldID: '',
          jobList: [''],
          imageURLList: [''],
          jobUnitPrice: 0,
          totalJobList: [
            {
              fieldValue: '',
              totalQty: 0,
              balanceQty: 0,
            },
          ],
          listWorkTypeJobApplication: {
            items: [],
            nextToken: '',
          },
        },
      ],
    },
    serviceRegion: [],
    optionRegion: [
      ...Object(THAILAND_REGION).map((region: any) => ({
        value: region,
        text: region,
      })),
    ],
    serviceProvince: [],
    optionProvince: [
      ...Object.entries(THAILAND_PROVINCE_MAPPER).map((region: any) => ({
        value: region[1],
        text: region[0],
      })),
    ],
    backUpJobList: [[]],
    isModalOpen: false,
    options: [
      { value: '', text: '' },
      {
        value: 'Group',
        text: 'Group',
      },
      {
        value: 'province',
        text: 'จังหวัด',
      },
      {
        value: 'region',
        text: 'ภูมิภาค',
      },
    ],
    fieldList: [
      {
        name: 'region',
        allowedValueList: ['CR', 'ER', 'NER', 'NR', 'SR'],
      },
    ],
    total: 300,
  }),
  validate: (values) => {
    const error = {}

    return error
  },
  handleSubmit: (values, { setFieldValue }) => {
    console.log('xxx')
  },
})(FilterTech)
interface IFormikValues {
  isModalOpen: boolean
}
export default EnhancedFilterTech

// export default FilterTech
