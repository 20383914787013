import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { IWeekElement, getWeeksInYear, filterWeekElement } from 'utils/week'

function columnToLetter(column: number) {
  let temp,
    letter = ''
  while (column > 0) {
    temp = (column - 1) % 26
    letter = String.fromCharCode(temp + 65) + letter
    column = (column - temp - 1) / 26
  }
  return letter
}

export const NewExportAIS = async (
  values: { masterPlan?: any[]; planDate?: any[]; actualDate?: any[] } | any,
  year: any,
  typeArr?: string[],
) => {
  // console.log('masterPlan', values.masterPlan)
  // console.log('planDate', values.planDate)
  // console.log('actualDate', values.actualDate)

  const workbook = new ExcelJS.Workbook()
  const sheet = workbook.addWorksheet('run 1')
  const topicFont = { name: 'Calibri', size: 11, bold: false }
  const allBorder: any = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  const FillWhite: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ffffff',
    },
  }
  const FillOrange: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ed7d31',
    },
  }
  const FillYellow: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ffd966',
    },
  }
  const FillGreen: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: '92d050',
    },
  }
  const FillSemiGreen: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'c6e0b4',
    },
  }
  const FillSemiOrange: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'f8cbad',
    },
  }
  const FillSemiYellow: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ffe699',
    },
  }
  const NewFillYellow: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFF00',
    },
  }
  const NewFillSemiOrange: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFC000',
    },
  }
  const FillGrey: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'D9D9D9',
    },
  }
  const setCell = (cellIndex: string, value: any, font: any, border: any, fill: any) => {
    sheet.getCell(cellIndex).value = value

    sheet.getCell(cellIndex).font = font
    sheet.getCell(cellIndex).border = border
    sheet.getCell(cellIndex).fill = fill
  }

  const selectFill = (type: string) => {
    if (type === 'masterPlan') {
      return NewFillYellow
    } else if (type === 'planDate') {
      return FillYellow
    } else if (type === 'actualDate') {
      return FillGreen
    }
  }
  const selectText = (type: string) => {
    if (type === 'masterPlan') {
      return 'Plan per acc'
    } else if (type === 'planDate') {
      return 'Plandate'
    } else if (type === 'actualDate') {
      return 'Actual per week '
    }
  }
  const selectTextSecond = (type: string) => {
    if (type === 'masterPlan') {
      return 'Plan per acc'
    } else if (type === 'planDate') {
      return 'Plandate'
    } else if (type === 'actualDate') {
      return 'Actual per week '
    }
  }
  const selectSemiFill = (type: string) => {
    if (type === 'masterPlan') {
      return FillSemiOrange
    } else if (type === 'planDate') {
      return FillSemiYellow
    } else if (type === 'actualDate') {
      return FillSemiGreen
    }
  }

  const currentDate = new Date()
  const weekInYear = getWeeksInYear(year)
  sheet.columns = []
  const columns = [
    { key: 'Region', width: 20 },
    { key: 'Scope', width: 20 },
    { key: 'Total', width: 20 },
  ]
  sheet.mergeCells('A1:BD1')

  sheet.getCell(`A1`).font = {
    name: 'Calibri',
    size: 16,
    bold: false,
    color: {
      argb: 'ff0000',
    },
  }
  sheet.getCell(`A1`).value = `ข้อมูล Master Plan -updated as of วัน ${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const initializeTableRowIndex = 3

  const initializeMonthRowLength = 1
  const initializeWeekRowLength = 1
  const initializeSumtotalRowLength = 2
  const gapBetweenTable = 1
  // === 5
  const sumAllGapBetweenTable =
    initializeMonthRowLength + initializeWeekRowLength + initializeSumtotalRowLength + gapBetweenTable

  let workTypeListLength = 0

  typeArr?.map((type: string | any, typeIndex: number) => {
    const firstLineRow = initializeTableRowIndex * typeIndex + 3 + typeIndex * 4 + workTypeListLength

    // console.log(
    //   {
    //     firstLineRow: firstLineRow,
    //     sumAllGapBetweenTable: sumAllGapBetweenTable,
    //     initializeTableRowIndex: initializeTableRowIndex,
    //     workTypeListLength: workTypeListLength,
    //     typeIndex: typeIndex,
    //     // beforeMultiple: initializeTableRowIndex + (typeIndex ? sumAllGapBetweenTable + 1 : 0) + workTypeListLength,
    //   },
    //   'sumAllGapBetweenTable',
    // )

    // generate date and topic

    // master plan - planDate - actualDate

    const newWorkTypeListPacking: any[] = []

    values[`${type}`].forEach((masterPlanElement: any, masterPlanIndex: number) => {
      masterPlanElement.workTypeList.forEach((workTypeElement: any, workTypeIndex: number) => {
        newWorkTypeListPacking.push({
          fieldValue: masterPlanElement.fieldValue,
          workType: workTypeElement.workType,
          owner: '',
          amount: '',
          total: '',
          workTypeTable: workTypeElement.workTypeTable,
          masterPlanIndex: masterPlanIndex,
        })
      })
    })

    // console.log(workTypeListLength, 'workTypeListLength')
    sheet.mergeCells(`D${firstLineRow}:BD${firstLineRow}`)
    // console.log('A${firstLineRow}', `A${firstLineRow}`)

    setCell(
      `D${firstLineRow} `,
      type === 'masterPlan' ? 'Plan' : type === 'planDate' ? 'Plan Date' : type === 'actualDate' ? 'Actual' : '',
      topicFont,
      allBorder,
      selectFill(type),
    )
    // console.log('selectFill(type)', selectFill(type))
    // console.log('selectFill(type)Zone', `A${firstLineRow} `)

    const initializeExcelWeekColumn = 3 //defined for column Index such as A B C D by function columnToLetter

    weekInYear.forEach((weekElement: any, weekIndex: number) => {
      // looping P1-12
      // P Line initialIndexRow = 4
      setCell(
        `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${firstLineRow + 1}`,
        'P' + weekElement.month,
        topicFont,
        allBorder,
        weekElement.month % 2 === 0 ? FillWhite : NewFillSemiOrange,
      )
      // week Line initialIndexRow = 5
      setCell(
        `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${firstLineRow + 2}`,
        'Wk' + weekElement.weekNo,
        topicFont,
        allBorder,
        FillWhite,
      )
    })

    // initializeIndexRow = 5 for Region Scope and Total
    columns.forEach((column, cindex) => {
      setCell(
        `${columnToLetter(cindex + 1)}${firstLineRow + 2}`,
        column.key,
        topicFont,
        allBorder,
        column.key == 'Total' ? NewFillYellow : FillWhite,
      )
    })

    // fieldValue Line initialIndexRow start with 6
    newWorkTypeListPacking.forEach((newWorkTypeElement: any, newWorkTypeIndex: number) => {
      setCell(
        `A${firstLineRow + 3 + newWorkTypeIndex}`,
        newWorkTypeElement.fieldValue,
        topicFont,
        allBorder,
        newWorkTypeElement.masterPlanIndex % 2 === 0 ? NewFillSemiOrange : FillWhite,
      )

      // for totalIncell
      weekInYear.forEach((weekElement: any, weekIndex: number) => {
        const totalInRow = newWorkTypeListPacking.map((workTypeElement) => {
          return Object.values<number>(workTypeElement.workTypeTable).reduce(
            (prev: number, curr: number) => prev + curr,
            0,
          )
        })
        setCell(
          `C${firstLineRow + initializeTableRowIndex + newWorkTypeIndex}`,
          totalInRow[newWorkTypeIndex],
          topicFont,
          allBorder,
          NewFillYellow,
        )
      })
      setCell(
        `B${firstLineRow + initializeTableRowIndex + newWorkTypeIndex}`,
        newWorkTypeElement.workType,
        topicFont,
        allBorder,
        FillWhite,
      )
      let isPresetBlank = true
      weekInYear.forEach((weekElement: any, weekIndex: number) => {
        const totalInRow = newWorkTypeListPacking.map((workTypeElement) => {
          return Object.values<number>(workTypeElement.workTypeTable).reduce(
            (prev: number, curr: number) => prev + curr,
            0,
          )
        })
        const planPerWeek = newWorkTypeListPacking
          .map((workTypeElement) => {
            return workTypeElement.workTypeTable[`week_${weekElement.weekNo}`]
          })
          .reduce((prev: number, curr: number) => ~~prev + ~~curr, 0)
        if (planPerWeek) {
          isPresetBlank = false
        }
        // console.log(totalInRow[newWorkTypeIndex], 'totalInRow[newWorkTypeIndex]')

        setCell(
          `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${
            firstLineRow + initializeTableRowIndex + newWorkTypeIndex
          }`,
          newWorkTypeElement.workTypeTable[`week_${weekElement.weekNo}`],
          topicFont,
          allBorder,
          // nutt
          isPresetBlank
            ? FillGrey
            : totalInRow[newWorkTypeIndex] === 0 || totalInRow[newWorkTypeIndex] == null
            ? FillGrey
            : FillWhite,
          //  == null
          //   ? FillGrey
          //   : FillWhite,
          // (allTotal <= 0 && planPerWeek == 0 && newWorkTypeElement.workTypeTable[`week_${weekElement.weekNo}`] == 0) ||
          //   (allTotal <= 0 &&
          //     planPerWeek == 0 &&
          //     newWorkTypeElement.workTypeTable[`week_${weekElement.weekNo}`] == null)
          //   ? FillGrey
          //   : FillWhite,
        )
      })
    })
    // console.log(type, 'type')
    // console.log(selectText(type), 'selectText')

    setCell(
      `A${firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length}`,
      'total',
      topicFont,
      allBorder,
      FillWhite,
    )
    setCell(
      `B${firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length}`,
      selectText(type),
      topicFont,
      allBorder,
      FillWhite,
    )
    setCell(
      `B${firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length + 1}`,
      selectTextSecond(type),
      topicFont,
      allBorder,
      FillWhite,
    )

    weekInYear.forEach((weekElement: any, weekIndex: number) => {
      const allTotal =
        ~~(
          sheet.getCell(
            `${columnToLetter(initializeExcelWeekColumn + weekIndex)}${
              firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length + 1
            }`,
          ).value || 0
        ) +
        ~~(
          sheet.getCell(
            `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${
              firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length
            }`,
          ).value || 0
        )
      // console.log(allTotal, 'allTotal')

      const planPerWeek = newWorkTypeListPacking
        .map((workTypeElement) => {
          return workTypeElement.workTypeTable[`week_${weekElement.weekNo}`]
        })
        .reduce((prev: number, curr: number) => ~~prev + ~~curr, 0)
      setCell(
        `C${firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length}`,
        allTotal,
        topicFont,
        allBorder,
        NewFillYellow,
      )
      setCell(
        `C${firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length + 1}`,
        '',
        topicFont,
        allBorder,
        NewFillYellow,
      )

      setCell(
        `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${
          firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length
        }`,
        planPerWeek,
        topicFont,
        allBorder,
        FillWhite,
        // allTotal <= 0 && planPerWeek == 0 ? FillWhite : FillOrange,
      )
      // console.log(planPerWeek, 'planPerWeek')

      // all total
      setCell(
        `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${
          firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length + 1
        }`,
        ~~(
          sheet.getCell(
            `${columnToLetter(initializeExcelWeekColumn + weekIndex)}${
              firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length + 1
            }`,
          ).value || 0
        ) +
          ~~(
            sheet.getCell(
              `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${
                firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length
              }`,
            ).value || 0
          ),
        topicFont,
        allBorder,
        FillWhite,
        // allTotal <= 0 && planPerWeek == 0 ? FillGreen : FillOrange,
      )
    })
    workTypeListLength = workTypeListLength + newWorkTypeListPacking.length
    // console.log(
    //   ' firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length + 1',
    //   firstLineRow + initializeTableRowIndex + newWorkTypeListPacking.length + 1,
    // )
  })
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const fileName = `Report_Master_Plan_Inno_${currentDate}`
  const buffer = await workbook.xlsx.writeBuffer()

  const excelToSave = new Blob([buffer], { type: fileType })

  saveAs(excelToSave, fileName + fileExtension)
}
