import React, { useState } from 'react'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import Fillforgotpasswordcomponent from './fillforgotpassword'
import Verifyforgotcomponent from './verifypassword'
import Emailverifycomponent from './emailverify'
import Fillnewpasswordcomponent from './fillnewpassword'
import Successcomponent from './success'

const Forgotpasswordcomponent: React.FC<any> = ({ setComponentStatus }: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const [forgotpasswordComponentStatus, setforgotpasswordComponentStatus] = useState('fill')
  return (
    <>
      {forgotpasswordComponentStatus === 'fill' ? (
        <Fillforgotpasswordcomponent
          setComponentStatus={setComponentStatus}
          setforgotpasswordComponentStatus={setforgotpasswordComponentStatus}
        />
      ) : forgotpasswordComponentStatus === 'verify' ? (
        <Verifyforgotcomponent setforgotpasswordComponentStatus={setforgotpasswordComponentStatus} />
      ) : forgotpasswordComponentStatus === 'email-verify' ? (
        <Emailverifycomponent setforgotpasswordComponentStatus={setforgotpasswordComponentStatus} />
      ) : forgotpasswordComponentStatus === 'fill-newpassword' ? (
        <Fillnewpasswordcomponent setforgotpasswordComponentStatus={setforgotpasswordComponentStatus} />
      ) : forgotpasswordComponentStatus === 'success' ? (
        <Successcomponent />
      ) : null}
    </>
  )
}

export default Forgotpasswordcomponent
