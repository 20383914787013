import React, { useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import styles from './index.module.scss'

import { getIn, useFormikContext } from 'formik'
import { EPropertyType } from 'utils/common'
import { FormikInputText } from 'components/commons/input/formikInputText'
import CheckboxComponent from 'components/Checkbox'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { parseWrap } from 'utils/equationUtil'
import ButtonComponent from 'components/Button'
import { ChecklistRowValue } from 'utils/generated'

const getNumberLeaf = (rowElement: ChecklistRowValue): number => {
  if (rowElement.children) return rowElement.children.reduce((prev, curr) => prev + getNumberLeaf(curr), 0)
  return 1
}

const flatData = (data1: any, numberRow: number, arrayIndex: number[]): number => {
  // console.log('FLAT DATA', data1, numberRow, arrayIndex)
  let flatIndex = 0
  const layer1Index = arrayIndex[0]
  const layer2Index = arrayIndex[1]
  const layer3Index = arrayIndex[2]
  if (numberRow === 2) {
    // Add all prev L1s ie. [2,0] would add all [0,.] , [1,.]
    for (let i = 0; i < layer1Index; i++) {
      flatIndex += getNumberLeaf(data1.rowList[i])
    }
    flatIndex += layer2Index
  }
  if (numberRow === 3) {
    // Add all prev L1s ie. [2,0,0] would add all [0,...] , [1,...]
    for (let i = 0; i < layer1Index; i++) {
      flatIndex += getNumberLeaf(data1.rowList[i])
    }
    // Add current L2 ie. [2,3,3] would add [2,0,...], [2,1,...], [2,2,...]
    for (let j = 0; j < layer2Index; j++) {
      flatIndex += getNumberLeaf(data1.rowList[layer1Index].children[j])
    }
    flatIndex += layer3Index
  }
  // console.log('FLAT IDX', flatIndex)
  return flatIndex
}

interface Props {
  indexData: number
  indexCard: number

  data: any
  formikPrefix: string
}
interface ITableList {
  name: string
  property: string
  children: any[]
}
const SectionCheckListUser: React.FC<Props> = ({ indexCard, indexData, data, formikPrefix }: Props) => {
  const { values, setFieldValue } = useFormikContext<any>()
  const dataNumberLegs = getIn(values, `${formikPrefix}[${indexCard}][${indexData}].numberLegs`)

  useEffect(() => {
    if (dataNumberLegs === null) {
      setFieldValue(`${formikPrefix}[${indexCard}][${indexData}].numberLegs`, 'THREE')
    }
  }, [dataNumberLegs])

  const optionDropdown1 = data?.dropdown1?.map((item: any) => ({ text: `${item}`, value: `${item}` })) || []
  const optionDropdown2 = data?.dropdown2?.map((item: any) => ({ text: `${item}`, value: `${item}` })) || []
  const optionDropdown3 = data?.dropdown3?.map((item: any) => ({ text: `${item}`, value: `${item}` })) || []

  const optionDropdownMap = {
    [EPropertyType.Dropdown1]: optionDropdown1,
    [EPropertyType.Dropdown2]: optionDropdown2,
    [EPropertyType.Dropdown3]: optionDropdown3,
  }

  const myarray2D = () => {
    const col = data?.columnList?.length + 2
    const row = data?.rowList?.length

    if (data?.levelRow === 2) {
      const arr: any = []
      const row: any[] = []
      data?.rowList.map((item: any) => {
        return row.push(...[...item?.children])
      })

      for (let i = 0; i < row?.length; i++) {
        // Creates an empty line
        arr.push([])
        // Adds cols to the empty line:
        arr[i].push(new Array(col))
        for (let j = 0; j < col; j++) {
          // Initializes:
          arr[i][j] = null
        }
      }

      return arr
    }

    const arr: any = []

    if (data?.levelRow === 3) {
      const arr: any = []
      const row: any[] = []
      data?.rowList.map((item: any) => {
        return item?.children.map((data: any) => {
          return row.push(...[...data?.children])
        })
      })

      for (let i = 0; i < row?.length; i++) {
        // Creates an empty line
        arr.push([])
        // Adds cols to the empty line:
        arr[i].push(new Array(col))
        for (let j = 0; j < col; j++) {
          // Initializes:
          arr[i][j] = null
        }
      }

      return arr
    }

    for (let i = 0; i < row; i++) {
      // Creates an empty line
      arr.push([])
      // Adds cols to the empty line:
      arr[i].push(new Array(col))
      for (let j = 0; j < col; j++) {
        // Initializes:
        arr[i][j] = null
      }
    }

    return arr
  }

  useEffect(() => {
    if (data?.data) {
      setFieldValue(`${formikPrefix}[${indexCard}][${indexData}].data`, data?.data)
    } else {
      setFieldValue(`${formikPrefix}[${indexCard}][${indexData}].data`, myarray2D())
    }
  }, [data.columnList, data.rowList])

  useEffect(() => {
    if (data && data.data && data.columnList) {
      data.data.map((item: any, rowIdx: number) => {
        const status = parseWrap(data.equation, {
          columnVariable: [
            ...data.columnList.map((colName: string, idxI: number) => ({
              text: colName,
              value: data.data[rowIdx][idxI] || 0,
            })),
            { text: 'Remark', value: data.data[rowIdx][data.columnList.length + 1] || 0 },
          ],
        })
        setFieldValue(`${formikPrefix}[${indexCard}][${indexData}].data[${rowIdx}][${item.length - 2}]`, status)
      })
    }
  }, [data.columnList])

  const RenderButtonStatus = (status: string) => {
    if (`${status}`.includes('Pass'))
      return (
        <ButtonComponent _type={'button'} _colorBG={'green'} _colorText={'white'} _variant={'text'} _text={status} />
      )
    else if (`${status}`.includes('N/A'))
      return (
        <ButtonComponent _type={'button'} _colorBG={'gray'} _colorText={'white'} _variant={'text'} _text={status} />
      )
    else
      return <ButtonComponent _type={'button'} _colorBG={'red'} _colorText={'white'} _variant={'text'} _text={status} />
  }

  // const debug = getIn(values, `${formikPrefix}[${indexCard}][${indexData}].numberLegs`)

  const renderRowProperty = (item: ITableList, indexRow: number) => {
    // console.log('I am rendering ', item, indexRow)
    const property = item.property ? item.property : data.defaultProperty
    const remarkProperty = data.remarkProperty ? data.remarkProperty : data.defaultProperty
    return (
      <table className={styles.tableMain}>
        <colgroup>
          <col style={{ width: '250px' }} />
          {data?.columnList.map((data: string, idxI: number) => (
            <col key={idxI} style={{ width: '100px' }} />
          ))}
          <col style={{ width: '100px' }} />
          <col style={{ width: '100px' }} />
        </colgroup>
        <tr className={styles.borderTR}>
          <td>{item.name}</td>
          {property === EPropertyType.Check ? (
            <>
              {data?.columnList.map((data: string, idxI: number) => (
                <td key={idxI}>
                  <CheckboxComponent
                    name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${idxI}]`}
                    value=""
                    checked={
                      getIn(values, `${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${idxI}]`) === true
                    }
                    disabled={values.modeAction === 'approve'}
                  />
                </td>
              ))}
            </>
          ) : property === EPropertyType.Text ? (
            <>
              {data?.columnList.map((data: string, idxI: number) => (
                <td key={idxI}>
                  <FormikInputText
                    name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${idxI}]`}
                    className={styles.inputInTable}
                  />
                </td>
              ))}
            </>
          ) : property === EPropertyType.Dropdown1 ||
            property === EPropertyType.Dropdown2 ||
            property === EPropertyType.Dropdown3 ? (
            <>
              {data?.columnList.map((item: string, idxI: number) => (
                <td key={idxI}>
                  <FormikInputSelect
                    name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${idxI}]`}
                    option={[
                      { text: 'Select Option', value: '' },
                      ...optionDropdownMap[property as keyof typeof optionDropdownMap],
                    ]}
                    defaultValue={``}
                    className={styles.selectInputTable}
                  />
                </td>
              ))}
            </>
          ) : property === EPropertyType.Number ? (
            <>
              {data?.columnList.map((data: string, idxI: number) => (
                <td key={idxI}>
                  <FormikInputText
                    name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${idxI}]`}
                    type="number"
                    className={styles.inputInTable}
                  />
                </td>
              ))}
            </>
          ) : (
            ''
          )}

          <td>
            {data &&
              data.columnList &&
              data.data &&
              RenderButtonStatus(
                parseWrap(data.equation, {
                  columnVariable: [
                    ...data.columnList.map((colName: string, idxI: number) => ({
                      text: colName,
                      value: data.data[indexRow][idxI] || 0,
                    })),
                    { text: 'Remark', value: data.data[indexRow][data.columnList.length + 1] || 0 },
                  ],
                }),
              )}
          </td>
          {/* <p>Remark</p> */}
          {/* <td>
            <FormikInputText
              name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${data.columnList?.length + 1}]`}
              className={styles.inputInTable}
            />
          </td> */}
          {remarkProperty === EPropertyType.Check ? (
            <td>
              <CheckboxComponent
                name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${data.columnList?.length + 1}]`}
                value=""
                checked={
                  getIn(
                    values,
                    `${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${data.columnList?.length + 1}]`,
                  ) === true
                }
                disabled={values.modeAction === 'approve'}
              />
            </td>
          ) : remarkProperty === EPropertyType.Dropdown1 ||
            remarkProperty === EPropertyType.Dropdown2 ||
            remarkProperty === EPropertyType.Dropdown3 ? (
            <td>
              <FormikInputSelect
                name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${data.columnList?.length + 1}]`}
                option={[
                  { text: 'Select Option', value: '' },
                  ...optionDropdownMap[remarkProperty as keyof typeof optionDropdownMap],
                ]}
                defaultValue={``}
                className={styles.selectInputTable}
              />
            </td>
          ) : remarkProperty === EPropertyType.Number ? (
            <td>
              <FormikInputText
                name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${data.columnList?.length + 1}]`}
                type="number"
                className={styles.inputInTable}
              />
            </td>
          ) : (
            <td>
              <FormikInputText
                name={`${formikPrefix}[${indexCard}][${indexData}].data[${indexRow}][${data.columnList?.length + 1}]`}
                className={styles.inputInTable}
              />
            </td>
          )}
        </tr>
      </table>
    )
  }

  return (
    <div className={styles.subCard}>
      <h4>{data.text}</h4>

      <table className={styles.tableHeader}>
        <colgroup>
          <col style={{ width: '250px' }} />
          {data?.columnList.map((data: string, idxI: number) => (
            <col key={idxI} style={{ width: '100px' }} />
          ))}
          <col style={{ width: '100px' }} />
          <col style={{ width: '100px' }} />
        </colgroup>
        <tr>
          <td></td>
          {data?.columnList.map((item: string, idx: number) => (
            <td key={idx}>{item}</td>
          ))}
          <td>Status</td>
          <td>Remark</td>
        </tr>
      </table>
      {data?.rowList &&
        data?.rowList.map((item: ITableList, idx: number) => (
          <div key={idx}>
            {item?.children?.length > 0 ? (
              <Accordion sx={{ boxShadow: 'none' }} key={idx} defaultExpanded={true} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                  sx={{ backgroundColor: '#FF8206', color: 'white' }}
                >
                  <p>{item.name}</p>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: 'white', border: '1px solid #FF8206', padding: '0.5em' }}>
                  {data?.levelRow === 2 ? (
                    <div className={styles.wrap}>
                      {item.children?.map((childrenData: any, idxData: number) => {
                        return (
                          <div key={`${idx}-${idxData}`} className={styles.wrapperRow}>
                            {renderRowProperty(childrenData, flatData(data, data.levelRow, [idx, idxData]))}
                          </div>
                        )
                      })}
                    </div>
                  ) : data?.levelRow === 3 ? (
                    <div className={styles.wrap}>
                      {item.children?.map((childrenData: any, idxData: number) => (
                        <div className={styles.wrapperRow} key={idxData}>
                          {childrenData.name}
                          {childrenData?.children?.map((childData: any, childDataIDX: number) => (
                            <React.Fragment key={`${idx}-${idxData}-${childDataIDX}`}>
                              {renderRowProperty(
                                childData,
                                flatData(data, data.levelRow, [idx, idxData, childDataIDX]),
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </AccordionDetails>
              </Accordion>
            ) : (
              <> {renderRowProperty(item, idx)}</>
            )}
          </div>
        ))}
    </div>
  )
}

export default SectionCheckListUser
