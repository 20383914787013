/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { getTheme, mergeStyleSets, FontWeights, Modal, IIconProps, IconButton, IButtonStyles } from '@fluentui/react'
import TimerSVG from './timerColor.svg'
import Timer from './timer'

interface IModalProps {
  isModalOpen: boolean
  closeModal?(): void
  title: string
  detail: string
  note: string
  status: string
  isBlocking?: boolean
}

const ModalAlert: React.FC<IModalProps> = ({
  isModalOpen,
  closeModal,
  title,
  detail,
  note,
  status,
  isBlocking = true,
}: IModalProps): JSX.Element => {
  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={closeModal ? closeModal : () => null}
      containerClassName={contentStyles.container}
      isBlocking={isBlocking}
    >
      <div className={contentStyles.header}>
        {closeModal && (
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeModal}
          />
        )}
      </div>

      <div className={contentStyles.body}>
        <img src={TimerSVG} width="60" />
        <h2>{title}</h2>
        <h4>{detail}</h4>
        <p>{note}</p>
        <h1>{status}</h1>
        <Timer initialSeconds={3} />
      </div>
    </Modal>
  )
}

export default ModalAlert

const cancelIcon: IIconProps = { iconName: 'Cancel' }

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: '30px',
  },
  header: {
    flex: '1 1 auto',
    color: theme.palette.neutralPrimary,
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '12px 12px 14px 24px',
  },

  body: {
    // flex: '4 4 auto',
    fontFamily: 'Kanit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    alignItems: 'center',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      h1: { margin: '20px 0 0 0 ' },
    },
  },
})

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}
