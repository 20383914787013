import React, { ChangeEvent } from 'react'
import styles from './index.module.scss'
import { useState } from 'react'
//Image
import ProfileBg from './images/profile-BG.svg'
import IconCamera from './images/camera.svg'
interface Props {
  image: any
  setImage: any
}
const ProfileUpload: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <label className={styles.container}>
        {/* {image} */}
        <div className={styles.imageContainer}>
          {props.image === '' ? <img src={ProfileBg} alt="" /> : <img src={props.image} alt="profileBG" />}
        </div>

        <div className={styles.dotCamera}>
          <img src={IconCamera} alt="camera" />
        </div>
        <input
          type="file"
          id="img-upload"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={(e) => {
            console.log(e.target.files, 'event change image')
            e.target.files && props.setImage(URL.createObjectURL(e.target.files[0]))
          }}
        />
      </label>
    </>
  )
}
export default ProfileUpload
