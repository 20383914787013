import React, { useState } from 'react'
import styles from './index.module.scss'
// import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Field, FieldProps, setNestedObjectValues, useFormikContext } from 'formik'
import { getTheme, mergeStyleSets, Modal, TextField } from '@fluentui/react'
// import closeIcon from '../../../../image/closeButton.svg'
import closeIcon from './closeBtn.svg'
import { CreateIssueManagement, IProject } from 'adapter/xhr'
import allAction from 'state/actions'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { CUSTOMER_PREFIX } from 'App'
import { destructureJobList, getUnpackedProjectByProjectId } from 'adapter/projectManagementPage'

// import TableTeam from './tableTeam'

const ModalIssue: React.FC<any> = (props: any): JSX.Element => {
  const [itemsFilter, setItemsFilter] = React.useState<string>('')
  const [itemsToShow, setItemsToShow] = React.useState<any[]>([])

  // const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const { values, setValues, setTouched, isValid, validateForm, setFieldValue } = useFormikContext<any>()
  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)

  const [isProjectLoaded, setIsProjectLoaded] = useState<boolean>(false)

  const dispatch = useDispatch()

  const queryNewTable = () => {
    const role = decodedAccessToken.role
    const jobFilter = role === CUSTOMER_PREFIX ? {} : { acceptedUserId: decodedAccessToken.userId }
    if (values.projectSelectedId) {
      console.time('useEffect>projectSelectedId')
      getUnpackedProjectByProjectId(values.projectSelectedId, jobFilter)
        .then((unpackedProjectResponse: IProject) => {
          console.log('unpackproject', unpackedProjectResponse)
          const jobListReference = destructureJobList(unpackedProjectResponse)
          console.log('jobListReference', jobListReference)
          setFieldValue('unpackproject', unpackedProjectResponse)
          setFieldValue('jobListReference', jobListReference)
          console.timeEnd('useEffect>projectSelectedId')
        })
        .catch((err) => {
          console.error('ROLLING STONES ERROR')
        })
        .finally(() => {
          setIsProjectLoaded(true)
        })
    }
  }

  return (
    <Modal
      containerClassName={contentStyles.container}
      isOpen={props.open}
      onDismiss={() => {
        props.setOpen(false)
      }}
    >
      <div className={styles.center}>
        <div className={styles.modal}>
          <div className={styles.card}>
            {' '}
            <div className={styles.Area}>
              <div className={styles.head}>
                <p className={styles.textHead}>บันทึกปัญหา</p>
                <img
                  src={closeIcon}
                  alt=""
                  onClick={() => {
                    props.setOpen(false)
                  }}
                />
              </div>

              <div className={styles.groupInput}>
                <section className={styles.inputGroup}>
                  <p className="errorMessage">
                    <ErrorMessage name="topic" />
                  </p>
                  <Field className={styles.inputfiled} name="topic" required />
                </section>

                <div className={styles.IssueInput}>
                  <span className={styles.text}>รายละเอียดปัญหา</span>

                  <Field className={styles.inputfiled} name="description" />
                </div>
              </div>

              <section>
                <article className={styles.btnFooterWrapper}>
                  <button
                    type="button"
                    className={styles.btnCancle}
                    onClick={() => {
                      props.setOpen(false)
                    }}
                  >
                    ยกเลิก
                  </button>
                  <button
                    type="button"
                    className={styles.btnConfirm}
                    onClick={() => {
                      const IssueVariables = {
                        input: {
                          projectId: props.projectId,
                          changTumId: props.changTumId,
                          topic: values.topic,
                          description: values.description,
                        },
                      }
                      validateForm().then((errors) => {
                        console.log('errors after vadlisad', errors)
                        if (Object.keys(errors).length === 0) {
                          // setpagestatus('secondstep')
                          console.log('Input => ', IssueVariables)
                          CreateIssueManagement(IssueVariables).then((issueres: any) => {
                            setFieldValue('topic', '')
                            setFieldValue('description', '')
                            console.log('Issue Update', issueres)
                            setTimeout(() => {
                              queryNewTable()
                            }, 1000)
                          })
                          dispatch(
                            allAction.modalAction.setOpenModalAlertStatus('บันทึกปัญหาเรียบร้อยแล้ว', '', 'success'),
                          )

                          props.setOpen(false)
                        } else {
                          setTouched(setNestedObjectValues(errors, true))
                        }
                      })
                    }}
                  >
                    บันทึกรายการ
                  </button>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    background: 'transparent',
    boxShadow: 'transparent',
  },
})
// const EnhancedModalApproveS = withFormik({
//   mapPropsToValues: () => ({
//     post: {},
//     options: [
//       { value: '', text: '' },
//       {
//         value: 'Group',
//         text: 'Group',
//       },
//       {
//         value: 'province',
//         text: 'จังหวัด',
//       },
//       {
//         value: 'region',
//         text: 'ภูมิภาค',
//       },
//     ],
//     isModalOpen: false,
//     fieldList: [
//       {
//         name: 'region',
//         allowedValueList: ['CR', 'ER', 'NER', 'NR', 'SR'],
//       },
//     ],
//     total: 300,
//     //FilterProps
//     Projectname: '',
//     customername: '',
//     startDate: new Date(),
//     endDate: new Date(),
//     price: 0,
//   }),
//   validate: (values) => {
//     const error = {}

//     return error
//   },
//   handleSubmit: (values, { setFieldValue }) => {
//     console.log(JSON.stringify(values))
//   },
// })(ModalApprove)

export default ModalIssue
// export default ModalApprove
