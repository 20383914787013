import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useFormikContext } from 'formik'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface IMultiDropDown {
  options?: IOption[] | any
  placeHolder?: string
  name?: string
  disabled?: boolean
  values?: IOption[] | any
  onchange?: any
}

interface IOption {
  text: string
  value: string
}

const MultiDropdown = (multiDropDownProps: IMultiDropDown) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      size="small"
      fullWidth
      value={
        multiDropDownProps.values
          ? multiDropDownProps.values.map((propValue: string) => {
              const findingOption = multiDropDownProps.options.find(
                (optionElement: any) => optionElement.value === propValue,
              )
              // console.log(findingOption, 'findingOption')
              return findingOption
            })
          : []
      }
      options={multiDropDownProps.options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.text}
      disablePortal
      disabled={multiDropDownProps.disabled ? multiDropDownProps.disabled : false}
      onChange={(event, value, reason, details: any) => {
        // console.log(value, 'frop down values')
        multiDropDownProps.onchange
          ? multiDropDownProps.onchange(value.map((valuesElement: any) => valuesElement.value))
          : multiDropDownProps.name &&
            setFieldValue(
              multiDropDownProps.name,
              value.map((valuesElement: any) => valuesElement.value),
            )
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.text}
        </li>
      )}
      // style={{ width: 500 }}
      renderInput={(params) => {
        // console.warn({ params })
        return (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
            placeholder={multiDropDownProps.placeHolder}
          />
        )
      }}
    />
  )
}

export default MultiDropdown
