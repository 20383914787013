import React from 'react'
import styles from './index.module.scss'
// import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import {
  getTheme,
  mergeStyleSets,
  Modal,
  DetailsList,
  TextField,
  SelectionMode,
  IColumn,
  DefaultButton,
} from '@fluentui/react'
import closeIcon from '../../../image/closeButton.svg'
import TableDropDown from 'components/dropdowntable'
import ImagesGroup from 'components/imagesGroup'
import { sumQty } from 'utils/common'
import { acceptJobapplication, createNotification } from 'adapter/xhr'

const jobSelectionColumns: IColumn[] = [
  {
    key: 'column1',
    name: 'ID ช่างทำ',
    fieldName: 'changTumId',
    minWidth: 70,
    maxWidth: 70,
    isResizable: false,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column2',
    name: 'Region',
    fieldName: 'region',
    minWidth: 50,
    maxWidth: 50,
    isResizable: false,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column3',
    name: 'Province',
    fieldName: 'province',
    minWidth: 50,
    maxWidth: 200,
    isResizable: true,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column4',
    name: 'Site Code',
    fieldName: 'siteCode',
    minWidth: 50,
    maxWidth: 200,
    isResizable: true,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column5',
    name: 'Site Name',
    fieldName: 'siteName',
    minWidth: 80,
    maxWidth: 200,
    isResizable: true,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column6',
    name: 'Status',
    fieldName: 'status',
    minWidth: 40,
    maxWidth: 40,
    isResizable: false,
    isFiltered: true,
    isSorted: true,
  },
  {
    key: 'column7',
    name: 'มี PAT',
    fieldName: 'isPatGenerated',
    minWidth: 40,
    maxWidth: 40,
    isResizable: false,
    isFiltered: true,
    isSorted: true,
  },
]

const tableHeader = ['ทั้งหมด', 'คงเหลือ', 'สมัคร', 'อนุมัติ']

const ModalApprove: React.FC<any> = (props: any): JSX.Element => {
  const [isPostsuccess, setIsPostsuccess] = React.useState<boolean>(false)
  const [itemsFilter, setItemsFilter] = React.useState<string>('')
  const [itemsToShow, setItemsToShow] = React.useState<any[]>([])

  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const dispatch = useDispatch()

  return (
    <Modal containerClassName={contentStyles.container} isOpen={values.isModalOpen} onDismiss={props.onDismiss}>
      <div className={styles.center}>
        <div className={styles.modal}>
          <div className={styles.card}>
            {' '}
            <div className={styles.Area}>
              <div className={styles.head}>
                <p className={styles.textHead}>อนุมัติงาน</p>
                <img
                  src={closeIcon}
                  alt=""
                  onClick={() => {
                    setFieldValue('isModalOpen', false, false)
                  }}
                />
              </div>
              <div className={styles.textCompany}>
                <span className={styles.text}>ผู้สมัคร :</span>
              </div>
              <div className={styles.cardJobs}>
                {' '}
                <div className={styles.areatext}>
                  <div className={styles.title}>
                    <p className={styles.jobtext}>ประเภทงาน {values.selectedJobApp.workTypeNumber}</p>
                    <p className={styles.jobtext}>ราคา {values.selectedJobApp.jobUnitPrice} บาท</p>
                  </div>
                </div>
                <div className={styles.twoside}>
                  <div className={styles.table}>
                    <TableDropDown
                      tableTopic={values.post.user && values.post.user.username}
                      tableHeader={tableHeader}
                      workType={values.selectedJobApp}
                      tableOption={values.options}
                      tableRowList={values.rowList}
                      // tableTotal={values.total}
                      totalJobListFieldName={`selectedJobApp`}
                      tableTotal={
                        values.selectedJobApp.totalJobList.length !== 0
                          ? sumQty(values.selectedJobApp.totalJobList, 'totalQty')
                          : 0
                      }
                      tableBalance={
                        values.selectedJobApp.totalJobList.length !== 0
                          ? sumQty(values.selectedJobApp.totalJobList, 'balanceQty')
                          : 0
                      }
                      tableRegister={
                        values.selectedJobApp.totalJobList.length !== 0
                          ? sumQty(values.selectedJobApp.totalJobList, 'registerQty')
                          : 0
                      }
                      tableApprove={
                        values.selectedJobApp.totalJobList.length !== 0
                          ? sumQty(values.selectedJobApp.totalJobList, 'approveQty')
                          : 0
                      }
                      isDisabledDropdown={true}
                      isDisabledTotalQty={true}
                      isDisabledBalanceQty={true}
                      isDisabledRegistryQty={true}
                      isDisabledApproveQty={false}
                    />
                  </div>
                  <div className={styles.imageURL}>
                    <ImagesGroup
                      image1={values.selectedJobApp.imageURLList ? values.selectedJobApp.imageURLList[0] : ''}
                      image2={values.selectedJobApp.imageURLList ? values.selectedJobApp.imageURLList[1] : ''}
                      image3={values.selectedJobApp.imageURLList ? values.selectedJobApp.imageURLList[2] : ''}
                      image4={values.selectedJobApp.imageURLList ? values.selectedJobApp.imageURLList[3] : ''}
                    />
                  </div>
                </div>
                <div>
                  {' '}
                  <div className={styles.footerTableContainer}>
                    <TextField
                      label="Filter by ChangtumId / JobId / SiteCode / SiteName:"
                      onChange={(
                        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                        text: string | undefined,
                      ): void => {
                        if (text) setItemsFilter(text)
                      }}
                    />
                    <DetailsList
                      compact={true}
                      items={itemsToShow}
                      columns={jobSelectionColumns}
                      selectionMode={SelectionMode.multiple}
                      setKey="multiple"
                      isHeaderVisible={true}
                      selectionPreservedOnEmptyClick={true}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.buttonform}>
                <div className="dp-flex justify-cont-ct f-gap50px">
                  <DefaultButton
                    className="dp-flex f-basis50 justify-cont-ct btnDefault3 "
                    onClick={props.onDismiss}
                    text="ยกเลิก"
                  />
                  <DefaultButton
                    className="dp-flex f-basis50 justify-cont-ct btnDefault"
                    text="ยืนยัน"
                    type="submit"
                    onClick={() => {
                      // dispatch(
                      //   allAction.modalConfirmAction.setOpenModalConfirm(
                      //     'ยืนยันการทำรายการ',
                      //     'คุณต้องการอนุมัติประเภทงานที่ 1 ?',
                      //     'ของบริษัท',
                      //     'ใช่,ทำรายการการ',
                      //     () => {
                      //       dispatch(
                      //         allAction.modalAction.setOpenModalAlertStatus('ยืนยันการอนุมัติงาน', '', 'success'),
                      //       )
                      //       setFieldValue('isModalOpen', false, false)
                      //       dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      //     },
                      //   ),
                      // )
                      const cloneTotalJobList = values.selectedJobApp.totalJobList
                      const packTotalJobList = cloneTotalJobList.map((totalJobElement: any) => {
                        delete totalJobElement.totalQty
                        delete totalJobElement.balanceQty
                        return totalJobElement
                      })
                      const variables = {
                        input: {
                          postID: values.selectedJobApp.postID,
                          applicationID: values.selectedJobApp.applicationID,
                          workType: values.selectedJobApp.workType,
                          status: 'ACCEPT',
                          totalJobList: packTotalJobList,
                        },
                      }
                      console.log(variables, 'variables')

                      acceptJobapplication(variables).then((res: any) => {
                        console.log(res, 'accept ressssssssssssss')
                        const CreateNotificationInput = {
                          userId: `${values.selectedJobApp.createdByUserID}`,
                          message: `  ${props.username} อนุมัติงาน ${values.getBypost.title}
                           ของ ${values.getBypost.project.projectName} เป็นงานทั้งหมด ${
                            values.selectedJobApp.totalJobList.length !== 0
                              ? sumQty(values.selectedJobApp.totalJobList, 'approveQty')
                              : 0
                          } คุณสามารถเริ่มงานได้แล้ว`,
                          iconUrl: '',
                          toLink: `/technician/projectmanagementpage`,
                          isRead: false,
                        }
                        createNotification(CreateNotificationInput)
                          .then(() => {
                            setTimeout(() => {
                              props.queryNewPost()
                              props.onDismiss()
                            }, 1000)
                          })
                          .catch((err) => {
                            console.log(err, 'err')
                          })
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    background: 'transparent',
    boxShadow: 'transparent',
    zIndex: 20,
  },
})
// const EnhancedModalApproveS = withFormik({
//   mapPropsToValues: () => ({
//     post: {},
//     options: [
//       { value: '', text: '' },
//       {
//         value: 'Group',
//         text: 'Group',
//       },
//       {
//         value: 'province',
//         text: 'จังหวัด',
//       },
//       {
//         value: 'region',
//         text: 'ภูมิภาค',
//       },
//     ],
//     isModalOpen: false,
//     fieldList: [
//       {
//         name: 'region',
//         allowedValueList: ['CR', 'ER', 'NER', 'NR', 'SR'],
//       },
//     ],
//     total: 300,
//     //FilterProps
//     Projectname: '',
//     customername: '',
//     startDate: new Date(),
//     endDate: new Date(),
//     price: 0,
//   }),
//   validate: (values) => {
//     const error = {}

//     return error
//   },
//   handleSubmit: (values, { setFieldValue }) => {
//     console.log(JSON.stringify(values))
//   },
// })(ModalApprove)

export default ModalApprove
// export default ModalApprove
