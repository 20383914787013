export interface Props {
  name: string
  label?: string
  value?: string
  disabled?: boolean
}

export const RadioSx = {
  color: `#FF8206`,
  '&.Mui-checked': {
    color: `#FF8206`,
  },
  '&.Mui-disabled': {
    color: `#B6B6B6`,
  },
}
