import React, { useState, useEffect } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'

interface IInputText {
  className?: string
  name: string
  placeholder?: string
  touched?: any
  errors?: any
  readOnly?: boolean
  onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | null
  onBlur?: ((e: React.FocusEvent<HTMLInputElement>) => void) | null
  validate?: (value: any) => undefined | string | Promise<any>
  additionalStyle?: any
  type?: string
  mask?: any[]
}

export const FormikInputText = ({
  className,
  name,
  placeholder,
  touched,
  errors,
  readOnly,
  onChange,
  onBlur,
  validate,
  additionalStyle = {},
  type,
  mask = [], // A List of Regex like [ /[1-9]/, /\d/, /\d/, /\d/, ]
}: IInputText) => {
  // console.log(readonly, 'name')
  const { handleChange, handleBlur } = useFormikContext<any>()

  // mask handling and onChange handling
  const fieldHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mask.length > 0) {
      if (onChange) {
        return handleMask(e, onChange)
      } else {
        return handleMask(e, handleChange)
      }
    } else {
      if (onChange) {
        return onChange(e)
      } else {
        return handleChange(e)
      }
    }
  }

  function handleMask(e: React.ChangeEvent<HTMLInputElement>, handleChange: any) {
    const newInput = e.target.value
    let allPass = true
    // Check if according to mask rule. If not then reject!
    for (let i = 0; i < newInput.length; i++) {
      // If NOT (mask length shorter  AND  regex Pass)
      if (!(mask.length - 1 >= i && mask[i].test(newInput.charAt(i)))) {
        allPass = false
        break
      }
    }
    if (allPass) {
      console.log(`I AM HANDLING CHANGE`)
      handleChange(e)
      return
    }
    console.log(`I DONT LET HANDLE CHANGE`)
    return
  }

  return (
    <>
      <Field
        name={name}
        // className="inputForSearch"
        className={`${className}`}
        placeholder={placeholder}
        validate={validate}
        type={type ? type : 'text'}
        readOnly={readOnly}
        // Choose between onChange or mask!! onChange will override Mask!
        onChange={fieldHandleChange}
        onBlur={onBlur ? onBlur : handleBlur}
        style={{
          ...additionalStyle,
          ...(touched?.[name] && errors?.[name] && { border: '1px solid red' }),
        }}
      />
      {/* <ErrorMessage name={name} render={(msg) => <span className="error">{msg}</span>} /> */}
    </>
  )
}
