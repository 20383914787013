import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { FormikInputText } from 'components/commons/input/formikInputText'
import FormikInputCustomizeType from 'components/commons/input/formikInputCustomizetype'
import { FormikInputCheckbox } from 'components/commons/input/formikInputCheckbox'
import { FormikInputSelectWithLink } from 'components/commons/input/formikInputSelectWithLink'
import { useFormikContext, ErrorMessage, Field, FieldProps } from 'formik'
import xpic from './X.svg'
import editpic from './edit.svg'
import { Iconbutton } from 'components/commons/iconButton'
import { getEmptyWorkTypeWithChangTumId, IFormikValues } from '../index'
import ChungTumTable from 'components/ChungTumTable'
import { useParams, useLocation, Navigate, useNavigate } from 'react-router-dom'
import { IWorkType } from 'adapter/xhr'
import CsvReader from './CsvReader'
import { FormikInputRadio } from 'components/commons/input/formikInputRadio'
import { TextField, DefaultButton } from '@fluentui/react'
import useStateInitializer from './useStateInitializer'
import MultiDropdown from 'components/MultiDropdown'
//Image
import ImagesGroup from '../../../components/imagesGroup'
// import Examing from '../../../image/examimg.svg'
import { handleUploadFile } from 'utils/common'

const Secondpage: React.FC<any> = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<IFormikValues>()
  const { customerOrTechnician, projectId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const imageFileRef = useRef<any>([])
  imageFileRef.current = []
  const addToRefs = (el: any) => {
    if (el && !imageFileRef.current.includes(el)) {
      imageFileRef.current.push(el)
    }
  }
  const [patTemplateOptionList, tableColumns] = useStateInitializer()

  const digitSix = Math.floor(100000 + Math.random() * 900000)
  function validateWorkType(value: string) {
    let error
    if (!value) {
      error = 'ต้องมีชื่อประเภทงาน'
    }
    return error
  }

  function validateJobUnitPrice(value: number, workTypeIndex: number) {
    let error
    if (values.workTypeList[workTypeIndex] && !values.workTypeList[workTypeIndex].isNoPrice) {
      error = value > 650 ? '' : 'ต้องมากกว่า 650 บาท'
    }
    return error
  }

  const validateSafetyPassport = (workTypeIndex: number) => {
    return (value: any[]) => {
      if (
        workTypeIndex < values.workTypeList.length &&
        values.workTypeList[workTypeIndex].isSafetyPassport &&
        value.length < 1
      ) {
        return '*Required'
      }
    }
  }

  useEffect(() => {
    values.workTypeList.forEach((workType, index) => {
      setFieldValue(
        `workTypeList[${index}].safetyPassport`,
        values.safetyPassport?.map((e) => e.value),
      )
    })
  }, [])

  // console.log(Math.floor(100000 + Math.random() * 900000)) //todo random 6 digit
  return (
    <>
      {/* Header of Download Template, Upload CSV, Add WorkType */}
      <div className={`dp-flex wrapper2 ${styles.workTypeWrapper}`}>
        <a
          // href="https://changtam-bucket.s3.ap-southeast-1.amazonaws.com/Data_Entry_Inno_21-07-16_R1_EMPTY.xlsx"
          // href="https://changtam-bucket.s3.ap-southeast-1.amazonaws.com/Data_Entry_Inno_22-05-03_R1_EMPTY.xlsx"
          href="https://changtam-bucket.s3.ap-southeast-1.amazonaws.com/Template_ChangTum_Table_V1_23-07-27.xlsx"
          download
        >
          <button className={styles.downloadTemplateButton} type="button">
            ดาวน์โหลด Template
          </button>
        </a>
        <CsvReader />
        <DefaultButton
          className={styles.addWorkTypeButton}
          onClick={() => {
            values.workTypeList.push(getEmptyWorkTypeWithChangTumId(values.safetyPassport?.map((e) => e.value)))
            setFieldValue('workTypeList', values.workTypeList)
          }}
          text="เพิ่มประเภทงาน"
          iconProps={{ iconName: 'CircleAdditionSolid' }}
        />
      </div>
      {/* Lower half of WorkType List */}
      <div className={`dp-flex justify-cont-ct wrapper2 ${styles.workTypeCardWrapper}`}>
        {values.workTypeList.map((workType: IWorkType, workTypeIndex: number) => {
          return (
            <div className={`${styles.workTypeCard}`} key={workTypeIndex}>
              <Iconbutton
                className={styles.Xbtn}
                buttonClassName={styles.Xbtnimg}
                type="button"
                functionOnClick={() => {
                  // pushlineType({ EMP_OBJ: EMPTY_PAT_LINE, patlineindex, lineType: 'TEXT' })
                  if (!(workTypeIndex === 0 && values.workTypeList.length === 1)) {
                    values.workTypeList.splice(workTypeIndex, 1)
                    setFieldValue(`workTypeList`, values.workTypeList)
                  }
                }}
                src={xpic}
                alt="xpic"
              />
              <section className={styles.workTypeLine}>
                <div style={{ flexBasis: '50%' }}>
                  <p>
                    ประเภทงาน
                    <span style={{ color: 'red' }}>*</span>
                    <span className="errorMessage">
                      <ErrorMessage name={`workTypeList[${workTypeIndex}].workType`} />
                    </span>
                  </p>
                  <FormikInputText
                    className={styles.inputBox}
                    name={`workTypeList[${workTypeIndex}].workType`}
                    validate={validateWorkType}
                    // placeholder="ฟอร์มไม่มีชื่อ"
                  />
                </div>
                <div style={{ flexBasis: '30%' }}>
                  <p>
                    ราคาต่องาน
                    <span style={{ color: 'red' }}>*</span>
                    <span className="errorMessage">
                      <ErrorMessage name={`workTypeList[${workTypeIndex}].jobUnitPrice`} />
                    </span>
                  </p>
                  <FormikInputCustomizeType
                    className={styles.inputBox}
                    name={`workTypeList[${workTypeIndex}].jobUnitPrice`}
                    type="number"
                    min="650"
                    disabled={values.workTypeList[workTypeIndex].isNoPrice}
                    validate={(value: number) => validateJobUnitPrice(value, workTypeIndex)}
                  />
                </div>
                <div className={`dp-flex`}>
                  <FormikInputCheckbox
                    name={`workTypeList[${workTypeIndex}].isNoPrice`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      // Reset the price and make the check out
                      if (e.target.checked) {
                        setFieldValue(`workTypeList[${workTypeIndex}].jobUnitPrice`, 0)
                      }
                      setFieldValue(`workTypeList[${workTypeIndex}].isNoPrice`, e.target.checked)
                    }}
                    label={'ไม่ระบุจำนวนเงิน'}
                  />
                </div>
              </section>
              <section className={styles.radioWrapper}>
                <FormikInputRadio
                  onChange={(e) => {
                    const value = e.target.value == 'true'
                    setFieldValue(`workTypeList[${workTypeIndex}].isSafetyPassport`, value)
                    setFieldValue(
                      `workTypeList[${workTypeIndex}].safetyPassport`,
                      value ? values.safetyPassport?.map((e) => e.value) : [],
                    )
                  }}
                  listRadio={[
                    {
                      text: 'มีsafety Passport',
                      value: true,
                      name: `workTypeList[${workTypeIndex}].isSafetyPassport`,
                    },
                    {
                      text: 'ไม่มีsafety Passport',
                      value: false,
                      name: `workTypeList[${workTypeIndex}].isSafetyPassport`,
                    },
                  ]}
                />
                <div style={{ display: 'none' }}>
                  <FormikInputText
                    name={`workTypeList[${workTypeIndex}].safetyPassport`}
                    validate={validateSafetyPassport(workTypeIndex)}
                  />
                </div>
                <span className="errorMessage">
                  <ErrorMessage name={`workTypeList[${workTypeIndex}].safetyPassport`} />
                </span>
              </section>
              <section>
                {/* <FormikInputText
                  className={styles.inputLongBox}
                  name={`workTypeList[${workTypeIndex}].safetyPassport`}
                  // validate={validateWorkType}
                  placeholder="ผู้รับเหมา"
                /> */}

                <MultiDropdown
                  options={values.safetyPassport}
                  name={`workTypeList[${workTypeIndex}].safetyPassport`}
                  placeHolder=""
                  disabled={!values.workTypeList[workTypeIndex].isSafetyPassport}
                  values={workType.safetyPassport}
                />
              </section>
              <section className={styles.rowWrapper}>
                <article className={styles.leftSide}>
                  <p className={styles.textAreaTopic}>รายละเอียดคุณสมบัติของผู้รับเหมา</p>
                  <div>
                    {/* <MultiDropdown
                options={`safetyPassport`}
                name="technicianWorkType"
                placeHolder=""
              /> */}
                    <Field name={`workTypeList[${workTypeIndex}].requirements`}>
                      {({ field }: FieldProps) => <TextField {...field} multiline resizable={false} autoAdjustHeight />}
                    </Field>
                  </div>
                </article>
                <article className={styles.rightSide}>
                  <p className={styles.textAreaTopic}>
                    อัพโหลดรูปภาพของงาน <span className={styles.imageDescription}>เพิ่มไฟล์รูปได้สูงสุด 4 รูป</span>
                  </p>

                  <ImagesGroup
                    onClickImage={() => {
                      imageFileRef.current[workTypeIndex].click()
                    }}
                    image1={workType.imageURLList && workType.imageURLList[0] ? workType.imageURLList[0] : ''}
                    image2={workType.imageURLList && workType.imageURLList[1] ? workType.imageURLList[1] : ''}
                    image3={workType.imageURLList && workType.imageURLList[2] ? workType.imageURLList[2] : ''}
                    image4={workType.imageURLList && workType.imageURLList[3] ? workType.imageURLList[3] : ''}
                  />
                </article>
                <article>
                  <input
                    type="file"
                    onChange={(e) => {
                      Promise.all(
                        handleUploadFile(
                          e.target.files,
                          `Project/${digitSix}/workTypeList.imageURLList[${workTypeIndex}]/filename`,
                        ),
                      ).then((fileLocationList: any[]) => {
                        setFieldValue(`workTypeList[${workTypeIndex}].imageURLList`, fileLocationList)
                      })
                    }}
                    accept="image/png, image/jpeg"
                    ref={addToRefs}
                    style={{ display: 'none' }}
                    multiple
                  />
                </article>
              </section>
              <ChungTumTable
                tableColumns={tableColumns}
                tableId={`T${values.workTypeList[workTypeIndex].lineNumber}`}
                data={workType.jobList ? workType.jobList : []}
                formikPrefix={`workTypeList[${workTypeIndex}].jobList`}
                formikDeleteListPrefix={`${projectId ? 'jobListToDelete' : ''}`}
                showTotal
                generateChangtumId
                showToolbar
              />

              <div className={styles.patSelectLine}>
                <p>สร้างฟอร์มใบงาน(PAT)</p>
                {values.workTypeList[workTypeIndex].patTemplateId === '' ? (
                  <FormikInputSelectWithLink
                    listSelect={{
                      name: `workTypeList[${workTypeIndex}].patTemplateId`,
                      selectclass: styles.patSelectItem,
                      option: patTemplateOptionList,
                    }}
                  />
                ) : values.workTypeList[workTypeIndex].patTemplateId === 'pat' ? (
                  <Navigate
                    to={`/${customerOrTechnician}/pat/pat`}
                    state={{
                      values,
                      selectedWorkType: values.workTypeList[workTypeIndex].workType,
                      fromLocation: pathname,
                    }}
                  />
                ) : (
                  <section className={`dp-flex ${styles.patSelectedShow}`}>
                    <p className={styles.patShowbox}>
                      {values.workTypeList[workTypeIndex].patTemplateId}{' '}
                      {values.workTypeList[workTypeIndex].patTemplateId &&
                        patTemplateOptionList?.find((optionData) => {
                          return optionData.value === values.workTypeList[workTypeIndex].patTemplateId
                        })?.text}
                    </p>
                    <Iconbutton
                      className={styles.patSelectBtn}
                      buttonClassName={styles.patSelectBtnimg}
                      type="button"
                      functionOnClick={() => {
                        if (values.workTypeList[workTypeIndex].patTemplateId !== '') {
                          navigate(`/${customerOrTechnician}/pat/` + values.workTypeList[workTypeIndex].patTemplateId, {
                            // search: '?query=abc',
                            state: {
                              values,
                              selectedWorkType: values.workTypeList[workTypeIndex].workType,
                              fromLocation: pathname,
                            },
                          })
                        }
                      }}
                      src={editpic}
                      alt="editpic"
                    />
                    <Iconbutton
                      className={styles.patSelectBtn}
                      buttonClassName={styles.patSelectBtnimg}
                      type="button"
                      functionOnClick={() => {
                        setFieldValue(`workTypeList[${workTypeIndex}].patTemplateId`, '')
                      }}
                      src={xpic}
                      alt="xpic"
                    />
                  </section>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Secondpage
