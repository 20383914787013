import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
//

interface Props {
  imageList: any[]
}
const ImagesSlideShow: React.FC<Props> = (props: Props): JSX.Element => {
  const [imageList, setImageList] = useState<any[]>([])
  const [imageShow, setImageShow] = useState<any>('')
  const [imageIndex, setImageIndex] = useState<number>(0)

  useEffect(() => {
    const newImageList: any[] = []
    if (props.imageList)
      props.imageList.map((imageElement: any) => {
        newImageList.push(imageElement)
        setImageList(newImageList)
        setImageShow(props.imageList[0])
      })
  }, [])
  return (
    <div className={styles.imageSlideShow}>
      <img className={styles.imageShow} src={imageShow} alt="" />
      <section className={styles.btnContainer}>
        {imageList?.map((imageElement: any, imageListIndex: number) => {
          return (
            <button
              className={styles.btnSelectImage}
              onClick={() => {
                setImageShow(imageElement)
                setImageIndex(imageListIndex)
              }}
              style={imageIndex === imageListIndex ? { backgroundColor: '#E0E0E0' } : {}}
              key={imageListIndex}
              type="button"
            />
          )
        })}
      </section>
    </div>
  )
}
export default ImagesSlideShow
