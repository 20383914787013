import React, { useState, useEffect } from 'react'
import styles from './alreadyindex.module.scss'
import homepic from './home.svg'
import voice from './voice.svg'
import dashboardpic from './Dashboard.svg'
import jobpic from './Job.svg'
import technicianpic from './FindTechnician.svg'
import projectmanagementpic from './projectmanagement.svg'
import financepic from './finance.svg'
import newspic from './News.svg'
import settingpic from './setting.svg'
import reportpic from './report.svg'
import insightpic from './Insights.svg'
import contractpic from './Contract.svg'
import helppic from './Help.svg'
import adspic from './ads.svg'
import { Link, useParams, useLocation } from 'react-router-dom'
import { CUSTOMER_PREFIX, TECHNICIAN_PREFIX } from '../../App'

const Menubar: React.FC<any> = () => {
  const { customerOrTechnician } = useParams<any>()
  const { pathname } = useLocation()

  // useEffect(() => {
  //   console.log('PATHNAME', pathname.split('/'))
  // }, [])

  const menuitems = () => {
    const menuItems =
      customerOrTechnician === CUSTOMER_PREFIX
        ? menuitemsCustomerSide
        : customerOrTechnician === TECHNICIAN_PREFIX
        ? menuitemsTechnicianSide
        : []
    return menuItems.map((e: any, index: number) => {
      return (
        <div key={index} className={styles.menuIcon}>
          {pathname.split('/')[2] === e.to && <div className={styles.circle}></div>}
          <Link to={`/${customerOrTechnician}/${e.to}`} key={index} className={styles.iconLink}>
            <img src={e.img} alt={e.img} className={styles.iconImg} />
            <p className={styles.iconText}>{e.text}</p>
          </Link>
        </div>
      )
    })
  }

  return (
    <div className={`wrapper2`}>
      {
        // Shouldn't be Login Page or Landing Page or termsandconditions page
        pathname.split('/')[2] &&
          !(pathname.split('/')[2] === 'login') &&
          !(pathname.split('/')[2] === 'termsandconditions') && <div className={`${styles.menubar}`}>{menuitems()}</div>
      }
    </div>
  )
}

export default Menubar

const menuitemsCustomerSide = [
  {
    img: homepic,
    to: 'home',
    text: 'หน้าแรก',
  },
  {
    img: voice,
    to: 'newfeeds',
    text: 'ประกาศ',
  },
  {
    img: jobpic,
    to: 'myproject',
    text: 'งาน',
  },
  // {
  //   img: dashboardpic,
  //   to: '/dashboardpage',
  //   text: 'Dashboard',
  // },
  {
    img: projectmanagementpic,
    to: 'projectmanagementpage',
    text: 'บริหารโครงการ',
  },
  // {
  //   img: technicianpic,
  //   to: 'technicianpage',
  //   text: 'หาผู้รับเหมา/ช่าง',
  // },

  // {
  //   img: reportpic,
  //   to: '/reportpage',
  //   text: 'รายงาน',
  // },
  // {
  //   img: financepic,
  //   to: '/financepage',
  //   text: 'การเงิน',
  // },
  // {
  //   img: insightpic,
  //   to: '/insightpage',
  //   text: 'ข้อมูลเชิงลึก',
  // },
  // {
  //   img: newspic,
  //   to: '/newspage',
  //   text: 'ข่าว',
  // },
  // {
  //   img: contractpic,
  //   to: '/contractpage',
  //   text: 'ติดต่อเรา',
  // },
  // {
  //   img: settingpic,
  //   // to: '/settingpage',
  //   to: '/addprojectpage', //temporary for demo
  //   text: 'การตั้งค่า',
  // },
  // {
  //   img: helppic,
  //   to: '/helppage',
  //   text: 'ศูนย์ช่วยเหลือ',
  // },
]

const menuitemsTechnicianSide = [
  {
    img: homepic,
    to: 'home',
    text: 'หน้าแรก',
  },
  {
    img: jobpic,
    to: 'newfeeds',
    text: 'รับงานช่างทำ',
  },
  // {
  //   img: dashboardpic,
  //   to: '/dashboardpage',
  //   text: 'Dashboard',
  // },
  {
    img: projectmanagementpic,
    to: 'projectmanagementpage',
    text: 'บริหารโครงการ',
  },
  {
    img: technicianpic,
    to: 'profileteamlist',
    text: 'โปรโมทงานของฉัน',
  },

  // {
  //   img: reportpic,
  //   to: '/reportpage',
  //   text: 'รายงาน',
  // },
  // {
  //   img: financepic,
  //   to: '/financepage',
  //   text: 'การเงิน',
  // },
  // {
  //   img: insightpic,
  //   to: '/insightpage',
  //   text: 'ข้อมูลเชิงลึก',
  // },
  // {
  //   img: newspic,
  //   to: '/newspage',
  //   text: 'ข่าว',
  // },
  // {
  //   img: contractpic,
  //   to: '/contractpage',
  //   text: 'ติดต่อเรา',
  // },
  // {
  //   img: settingpic,
  //   to: '/settingpage',
  //   text: 'การตั้งค่า',
  // },
  // {
  //   img: helppic,
  //   to: '/helppage',
  //   text: 'ศูนย์ช่วยเหลือ',
  // },
]
