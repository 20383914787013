import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { stringMap } from 'aws-sdk/clients/backup'
import { sumQty } from 'utils/common'

interface ITableDropDown {
  tableTopic: string
  tableInputSelectOnchange?: any
  tableHeader: string[]
  tableRowList?: ITableRow[]
  tablePostName?: string
  workType?: any
  jobApp?: string[]
  // tableTotal?: string | number
  // tableBalance?: string | number
  // tableRegister?: string | number
  // tableApprove?: string | number

  tableIndex: number
}

interface ITableRow {
  fieldValue: string
  totalQty?: number
  balanceQty?: number
  registerQty?: number
  approveQty?: number
}

const TableShow: React.FC<ITableDropDown> = (props: ITableDropDown & any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  return (
    <div className={styles.tableContainer}>
      <section className={styles.tableTopic}>{props.tableTopic}</section>
      <section className={styles.tableHeader}>
        <p className={styles.tableRowColumn}>{props.workType.fieldID}</p>
        {props.tableHeader.map((tableItems: stringMap, tableHeaderIndex: number) => {
          return (
            <p className={styles.tableRowColumn} key={tableHeaderIndex}>
              {tableItems as any}
            </p>
          )
        })}
      </section>
      {props.workType &&
        props.workType.totalJobList.map((tableRowItems: ITableRow, tableRowIndex: number) => {
          return (
            <section className={styles.tableRow} key={tableRowIndex}>
              <p className={styles.tableRowTopic} style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}>
                {tableRowItems.fieldValue}
              </p>
              <div className={styles.tableRowColumn} style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}>
                {tableRowItems.totalQty}
              </div>

              <div className={styles.tableRowColumn} style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}>
                {tableRowItems.balanceQty}
              </div>

              <div className={styles.tableRowColumn} style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}>
                {tableRowItems.registerQty}
              </div>
              <div className={styles.tableRowColumn} style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}>
                {tableRowItems.approveQty}
              </div>
            </section>
          )
        })}
      <section className={styles.tableFooter}>
        <p className={styles.tableRowTopic}>Total</p>
        <p className={styles.tableRowColumnFooter}>
          {props.workType.totalJobList.length !== 0 ? sumQty(props.workType.totalJobList, 'totalQty') : 0}
        </p>
        <p className={styles.tableRowColumnFooter}>
          {props.workType.totalJobList.length !== 0 ? sumQty(props.workType.totalJobList, 'balanceQty') : 0}
        </p>
        <p className={styles.tableRowColumnFooter}>
          {props.workType.totalJobList.length !== 0 ? sumQty(props.workType.totalJobList, 'registerQty') : 0}
        </p>
        <p className={styles.tableRowColumnFooter}>
          {props.workType.totalJobList.length !== 0 ? sumQty(props.workType.totalJobList, 'approveQty') : 0}
        </p>
      </section>
    </div>
  )
}

export default TableShow
