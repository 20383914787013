import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, Field, setNestedObjectValues, ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import VerifySecondPerson from './verifySecondperson'
import { UpdateUserProfile } from 'adapter/xhr'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import ModalSkip from './modalSkip'

interface Props {
  setVerifystep: any
}
const VerifySecond: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, validateForm, setTouched } = useFormikContext<any>()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const setStep = () => {
    props.setVerifystep('verifyThird')
  }
  async function texIDCoor(value: any) {
    if (!value) {
      return 'กรุณากรอกเลขประจำตัวประชาชน'
    }
    try {
      await Yup.object()
        .shape({
          taxIDCoor: Yup.string().length(13, 'กรุณากรอกเลขประจำตัวประชาชน 13 หลัก').required('eeee'),
        })
        .validate({ taxIDCoor: value.toString() })
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }

  async function ValidatephoneCoor(value: string) {
    if (!value) {
      return 'กรุณากรอกเบอร์ติดต่อ'
    }
    try {
      await Yup.object()
        .shape({
          phone: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'กรอกเฉพาะหมายเลขเท่านั้น')
            .min(10, 'กรอกเบอร์ติดต่อ 10 หลัก')
            .max(10, 'กรอกเบอร์ติดต่อ 10 หลัก'),
        })
        .validate({ phone: `${value}` })
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }

  return (
    <>
      {values.profileUp && values.profileUp.userType === 'JURISTIC' ? (
        <div className={styles.verifyFristContainer}>
          <section>
            <div className={styles.inputWrapper}>
              <article>
                <p>ชื่อผู้ประสานงาน</p>
                <Field
                  className={styles.inputText}
                  type="text"
                  name={'profileUp.coordinatorName'}
                  placeholder="ชื่อ - นามสกุลของผู้ประสานงาน"
                />
              </article>
              <article>
                <span>
                  เลขบัตรประชาชน ผู้ประสานงาน *{' '}
                  <span className="errorMessage">
                    <ErrorMessage name={'profileUp.coordinatorPersonalID'} />
                  </span>
                </span>
                <Field
                  className={styles.inputText}
                  type="number"
                  name={'profileUp.coordinatorPersonalID'}
                  placeholder="เลขบัตรประชาชน ผู้ประสานงาน"
                  validate={texIDCoor}
                />
              </article>
              <article>
                <p>ที่อยู่ของผู้ประสานงาน</p>

                <Field
                  as="textarea"
                  className={styles.inputText}
                  placeholder="ที่อยู่ของผู้ประสานงาน  "
                  style={{ resize: 'none', height: '50px', margin: '0 0 40px 0' }}
                  name={'profileUp.coordinatorAddress'}
                />
              </article>
              <article>
                <span>
                  เบอร์ติดต่อผู้ประสานงาน *{' '}
                  <span className="errorMessage">
                    <ErrorMessage name={'profileUp.coordinatorPhone'} />
                  </span>
                </span>{' '}
                <Field
                  className={styles.inputText}
                  type="text"
                  name={'profileUp.coordinatorPhone'}
                  placeholder="เบอร์ติดต่อ"
                  validate={ValidatephoneCoor}
                />
              </article>
            </div>
          </section>
        </div>
      ) : (
        <VerifySecondPerson />
      )}
      <section>
        <article className={styles.btnFooterWrapper}>
          <button type="button" className={styles.btnCancle} onClick={() => props.setVerifystep('verifyfrist')}>
            ย้อนกลับ
          </button>
          <button
            type="button"
            className={styles.btnConfirm}
            onClick={() => {
              const UpdateProfileVariables: any = {
                input: values.profileUp,
              }
              UpdateProfileVariables.input.coordinatorPersonalID = `${UpdateProfileVariables.input.coordinatorPersonalID}`
              UpdateProfileVariables.input.coordinatorPhone = `${UpdateProfileVariables.input.coordinatorPhone}`
              UpdateProfileVariables.input.phone = `${UpdateProfileVariables.input.phone}`
              UpdateProfileVariables.input.zipCode = `${UpdateProfileVariables.input.zipCode}`
              UpdateProfileVariables.input.safetyPassportType?.map((safetyPassportTypeStr: string) => {
                UpdateProfileVariables.input.safetyPassport.push({
                  safetyPassportType: safetyPassportTypeStr,
                  safetyPassportStatus: 'PENDING',
                  safetyPassportFileAttachment: [],
                })
              })
              delete UpdateProfileVariables.input.safetyPassportType

              validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  props.setVerifystep('verifysecond')
                  UpdateUserProfile(UpdateProfileVariables).then((res: any) =>
                    console.log('UpdatProfile =====>  ', res),
                  )
                  console.log(values.profileUp, 'VerifyStatus')
                  if (values.profile && values.profile.userVerification === null) {
                    setOpen(true)
                  } else {
                    dispatch(allAction.modalAction.setOpenModalAlertStatus('บันทึกข้อมูลเรียบร้อยแล้ว', '', 'success'))
                    navigate(-1)
                  }
                } else {
                  console.log('No NextStep')
                  setTouched(setNestedObjectValues(errors, true))
                }
              })
            }}
          >
            ยืนยัน
          </button>
        </article>
        <ModalSkip open={open} setOpen={setOpen} setStep={setStep} />
      </section>
    </>
  )
}
export default VerifySecond
