import React from 'react'
// Chartjs
import { Bar } from 'react-chartjs-2'
// import 'chartjs-plugin-datalabels'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

interface Props {
  responsive?: boolean
  data?: number[]
  labels?: string[]
  indexAxis?: 'x' | 'y'
}

const GraphBar: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <Bar
      data={{
        // labels: ['ST-1', 'ST-2', 'ST-3', 'ST-4', 'ST-5', 'ST-6', 'ST-7', 'ST-8', 'ST-9'],
        labels: props.labels,
        datasets: [
          {
            barPercentage: 0.5, // ขนาด Bar Chart
            // label: 'TEST A',
            // data: [5, 3, 1, 2, 2.5, 4, 3, 5, 2],
            data: props.data,
            // stack: 'combined',
            backgroundColor: ' #4E73BE',
            // borderColor: 'rgb(255, 99, 132)',
          },
        ],
      }}
      height={200}
      width={375}
      options={{
        indexAxis: props.indexAxis,
        maintainAspectRatio: true,
        responsive: props.responsive,

        plugins: {
          datalabels: {
            display: true,
            color: 'black',
            anchor: 'end',
            align: props.indexAxis === 'y' ? 'right' : props.indexAxis === 'x' ? 'top' : 'top',
            // labels: {
            //   value: {
            //     display: false,
            //   },
            //   title: {
            //     // แสดง data ใน Graph
            //     display: false,
            //   },
            // },
          },
          // type ข้อมูล
          legend: {
            position: 'bottom',
            labels: {
              color: 'black',
              font: {
                family: 'Kanit',
                size: 15,
              },
            },
            display: false,
          },
        },
        layout: {
          padding: {
            left: 0,
            right: props.indexAxis === 'y' ? 30 : 0,
            top: props.indexAxis === 'x' ? 30 : 0,
            bottom: 0,
          },
        },
        font: {
          family: 'Kanit',
        },
        scales: {
          x: {
            // suggestedMax: 5,
            // suggestedMin: 0,
            ticks: {
              font: {
                size: 12,
              },
            },
            grid: {
              display: props.indexAxis === 'x' ? false : true,
              lineWidth: 1,
            },
          },
          y: {
            // suggestedMax: 5,
            // suggestedMin: 0,
            ticks: {
              font: {
                size: 12,
              },
            },
            grid: {
              display: props.indexAxis === 'y' ? false : true,
              lineWidth: 1,
            },
          },
        },
      }}
    />
  )
}
export default GraphBar
