import { AnyAction, Reducer, configureStore } from '@reduxjs/toolkit'
import testReducer, { IInitialState } from './reducers/test'
import tokenReducer, { ITokenState } from './reducers/token'
import modalReducer, { IInitialStateModal } from './reducers/modal'
import modalComfirmReducer, { IInitialStateModalConfirm } from './reducers/modalConfirm'
import loaderSpinnerReducer, { IInitialStateLoaderSpinner } from './reducers/loaderSpinner'

const store = configureStore({
  reducer: {
    testReducer: testReducer,
    tokenReducer: tokenReducer,
    modalReducer: modalReducer,
    modalComfirmReducer: modalComfirmReducer,
    loaderSpinnerReducer: loaderSpinnerReducer,
  },
})

export default store
