import * as AWS from 'aws-sdk'
// import { withFormik, useFormikContext, Form } from 'formik'
import { albumBucketName, bucketRegion, IdentityPoolId } from 'components/commons/configAws'
import fileDownload from 'js-file-download'
import axios from 'axios'
import { CELL_TYPE, ITableColumn, JOB_STATUS_MAPPER } from 'components/ChungTumTable'
import { THAILAND_PROVINCE2REGION_MAPPER, THAILAND_REGION_MAPPER } from 'components/commons/thailandFact'

// downloadFile use to download any file example : pdf, png, jpg
export const downloadFile = async (url: string, filename: string): Promise<void> => {
  const res = await axios.get(url, { responseType: 'blob' })
  fileDownload(res.data, decodeURI(filename))
  console.log('hello file', url, filename)
}

export const calDateforInputDate = (date: any) => {
  const dateInput = `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(2, '0')}-${`${date.getDate()}`.padStart(
    2,
    '0',
  )}`

  return dateInput
}

export const handleUploadFile = (value: any, albumLocation: string) => {
  // const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId,
    }),
  })
  const files: any = Array.from(value)
  if (files.length === 0) return
  // const albumPhotosKey = `PAT/${patId}/customer/`
  const albumPhotosKey = albumLocation
  const imgURLList: any = []
  return files.map((file: any, index: number) => {
    console.log('file', file)
    const photoKey = albumPhotosKey + file.name
    // Use S3 ManagedUpload class as it supports multipart uploads
    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: file,
        ContentType: file.type,
      },
    })
    return upload
      .promise()
      .then(function (resAws) {
        console.log('resAws', resAws)
        return resAws.Location
      })
      .catch(function (err) {
        console.log(err)
      })
  })
  // console.log(imgURLList, ' imgURLList')
  // return imgURLList
}

export const packPostAndJobAppToJobAppList = (post: any) => {
  post.workTypePostList.map((workTypePost: any) => {
    workTypePost.jobApplication = []
    post.jobApplication.map((jobApplication: any) => {
      jobApplication.workTypeJobApplicationList.map((workTypeJob: any, workTypeJobIndex: number) => {
        console.log(workTypePost.workType === workTypeJob.workType, 'workTypeJob.workType')
        if (workTypePost.workType === workTypeJob.workType) {
          workTypeJob.totalJobList.map((totalJobElement: any) => {
            // console.log(totalJobElement, 'totalJobElement')
            const findingPost = workTypePost.totalJobList.find(
              (totalPostElement: any) => totalPostElement.fieldValue === totalJobElement.fieldValue,
            )
            if (findingPost) {
              totalJobElement.totalQty = findingPost.totalQty
              totalJobElement.balanceQty = findingPost.balanceQty
            }
          })
          workTypeJob.technicianList = jobApplication.technicianList
          workTypeJob.applicationID = jobApplication.applicationID
          workTypeJob.createdByUserID = jobApplication.createdByUserID
          workTypeJob.jobApplicationIndex = workTypeJobIndex
          workTypePost.jobApplication.push(workTypeJob)
        }
      })
      // .find(
      //   (workTypeJob: any) => ,
      // )
    })
    // console.log(findingJobApps, 'findingJobApps')
    // findingJobApps
    //   ? workTypePost.totalJobList.map((totalPostElement: any) => {
    //       // totalJobElement
  })

  // post.jobApplication.map((jobApplication: any) => {
  //   jobApplication.workTypeJobApplicationList.map((workTypeJob: any) => {
  //     const findingPost = post.workTypePostList.find(
  //       (workTypePost: any) => workTypeJob.workType === workTypePost.workType,
  //     )
  //     console.log(findingPost, 'findingPost')
  //     if (findingPost) {
  //       workTypeJob.totalJobList.map((totalJobElement: any) => {
  //         const findingJobList = findingPost.totalJobList.find(
  //           (totalPostElement: any) => totalPostElement.fieldValue === totalJobElement.fieldValue,
  //         )
  //         if (findingJobList) {
  // totalJobElement.totalQty = findingJobList.totalQty
  // totalJobElement.balanceQty = findingJobList.balanceQty
  //         }
  //       })
  //     }
  //   })
  // })
  return post
}

export const sumQty = (sumObjArr: any[], key: string) => {
  return sumObjArr
    .map((totalJob: any) => {
      if (isNaN(parseInt(totalJob[`${key}`]))) {
        return 0
      } else {
        return totalJob[`${key}`]
      }
    })
    .reduce((a: number, b: number) => a + b)
}

export const packMasterPlan = (inputMasterPlan: any, acceptedUserId: string) => {
  const newInputMasterPlan: any = {
    input: {
      fieldID: inputMasterPlan.input.fieldID,
      planningYear: `${inputMasterPlan.input.planningYear}`,
      projectId: inputMasterPlan.input.projectId,
      groupFieldList: [],
    },
  }
  inputMasterPlan.input.groupFieldList.forEach((groupFieldElement: any, groupFieldIndex: number) => {
    const newWorkTypeList: any[] = []
    groupFieldElement.workTypeList.forEach((workTypeElement: any) => {
      let newWorkTypeTable: any = {}
      Object.keys(workTypeElement.workTypeTable).forEach((weekKey: string) => {
        newWorkTypeTable = {
          ...newWorkTypeTable,
          [`${weekKey}`]: {
            acceptedUserId: acceptedUserId,
            quantity: workTypeElement.workTypeTable[`${weekKey}`],
          },
        }
      })
      newWorkTypeList.push({
        workType: workTypeElement.workType,
        workTypeTable: newWorkTypeTable,
      })
    })
    newInputMasterPlan.input.groupFieldList.push({
      fieldValue: groupFieldElement.fieldValue,
      workTypeList: newWorkTypeList,
    })
  })
  return newInputMasterPlan
}

export enum ELineListType {
  Text = 'TEXT',
  Approve = 'APPROVE',
  Image = 'IMAGE',
  Upload = 'UPLOAD',
}

export enum EPoleType {
  Monopole = 'MONOPOLE',
  Guyed = 'GUYED',
  SelfeSupport = 'SELFSUPPORT',
  Steelpipe = 'STEELPIPE',
  Concretepole = 'CONCRETEPOLE',
}

export enum EPropertyType {
  Check = 'CHECK',
  Number = 'NUMBER',
  Text = 'TEXT',
  Dropdown = 'DROPDOWN',
  Dropdown1 = 'DROPDOWN1',
  Dropdown2 = 'DROPDOWN2',
  Dropdown3 = 'DROPDOWN3',
}

interface IOption {
  text: string
  value: string
}

export const columnOption: IOption[] = [
  {
    text: 'index',
    value: 'index',
  },
  {
    value: 'siteCode',
    text: 'Site Code (รหัสไซต์)',
  },
  {
    value: 'siteName',
    text: 'Site Name (ชื่อไซต์)',
  },
  {
    value: 'number',
    text: 'number (เลขที่)',
  },
  {
    value: 'workType',
    text: 'Work Type (ประเภทงาน)',
  },
  {
    value: 'region',
    text: 'Region (ภูมิภาค)',
  },
  {
    value: 'fieldValue',
    text: 'Filed Value(ประเภทงาน)',
  },
  {
    value: 'province',
    text: 'Province (จังหวัด)',
  },
  {
    value: 'district',
    text: 'District (อำเภอ)',
  },
  {
    value: 'lat',
    text: 'Latitude (แลตติจูด)',
  },
  {
    value: 'long',
    text: 'Longitude (ลองติจูด)',
  },

  {
    value: 'zone',
    text: 'Zone (โซน)',
  },
  {
    value: 'changTumId',
    text: 'ChangTumID (ช่างทำไอดี)',
  },
  {
    value: 'jobId',
    text: 'JobID(รหัสงาน)',
  },
  {
    value: 'remark',
    text: 'Remark (หมายเหตุ)',
  },
  {
    value: 'status',
    text: 'Status (สถานะ)',
  },
  {
    value: 'acceptedUserId',
    text: 'Technician (ช่างรับงาน)',
  },
  {
    value: 'patStatus',
    text: 'PAT Status (สถานะเอกสาร PAT)',
  },
  // {
  //   value: 'contractor',
  //   text: 'Contractor (ผู้รับเหมา)',
  // },
  // {
  //   value: 'contractorDetail',
  //   text: 'Contractor Detail (รายละเอียดผู้รับเหมา)',
  // },
  {
    value: 'locationID',
    text: 'location ID',
  },
  {
    value: 'manufacturer',
    text: 'บริษัทผู้ผลิตเสา',
  },
  {
    value: 'installer',
    text: 'บริษัทผู้ติดตั้งเสา',
  },
  {
    value: 'room',
    text: 'Equipment Room',
  },
  {
    value: 'towerType',
    text: 'TowerType',
  },
  {
    value: 'towerSubType',
    text: 'TowerSubType',
  },

  {
    value: 'height',
    text: 'ความสูง (m)',
  },
  {
    value: 'siteArea',
    text: 'ขนาด Site (m)',
  },
  {
    value: 'dimension',
    text: 'ขนาด (กว้างxยาว)',
  },
  {
    value: 'gateWidth',
    text: 'ความกว้างประตูรั้ว',
  },
  {
    value: 'manufacturerDate',
    text: 'Manufacture date',
  },
]
