import React from 'react'
import { useState, useEffect } from 'react'

const Timer: React.FC<{ initialMinute?: number; initialSeconds?: number }> = (props: {
  initialMinute?: number
  initialSeconds?: number
}) => {
  const { initialMinute = 0, initialSeconds = 0 } = props
  const [minutes, setMinutes] = useState(initialMinute)
  const [seconds, setSeconds] = useState(initialSeconds)
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : minutes === 0 && seconds !== 0 ? (
        <h1>{seconds}s</h1>
      ) : (
        <h1>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h1>
      )}
    </div>
  )
}

export default Timer
