import React, { useEffect, useRef, useState } from 'react'
import { withFormik, useFormikContext, Form } from 'formik'
import { FormikInputText } from 'components/commons/input/formikInputText'
// import { FormikInputRadio } from 'components/commons/input/formikInputRadio'
import { IOption } from 'components/commons/input/formikInputSelect'
import FooterComponant from 'components/commons/btnFooter'

import styles from './index.module.scss'

import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from 'components/commons/configAws'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'

import {
  getUnpackedPatbyPatId,
  unpackPAT,
  EMPTY_PAT,
  packAndCreatePat,
  packAndPutPat,
  getTitleTemplatesByUserId,
} from 'adapter/pat'
import { connect, useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { ITitleTemplate } from 'adapter/xhr'
import { CardHeaderPat, SectionTypeUploadPat, CardUploadImgPat, SectionTitleTemplate } from 'container'
import { ThemeMenu, ThemeMenuAdd } from './config'
import { EPropertyType } from 'utils/common'
import RadioComponent from 'components/Radio'
import { Line_Type, PatLine, Tower_Type, Upload_Type } from 'utils/generated'

import imgPNG from 'image/pat/images.png'
import uploadPNG from 'image/pat/upload.png'
import checkPNG from 'image/pat/checkList.png'
import checkApprovePNG from 'image/pat/checkApprove.png'
import dupicatePNG from 'image/pat/duplicate.png'
import delPNG from 'image/pat/delete.png'
import titlePNG from 'image/pat/title.png'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import plusbox from './plusbox.svg'
import binpic from 'image/bin.svg'
import addSVG from 'image/addCircleGray.svg'
import h2SVG from 'image/pat/h2.svg'

const Pat: React.FC = (props: any) => {
  const { values, setFieldValue } = useFormikContext<any>()
  const { patId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const [refAdd, setRefAdd] = useState<HTMLDivElement | null>(null)

  // Fetch PAT Data
  useEffect(() => {
    if (patId) {
      if (patId === 'pat') {
        setFieldValue('formikUnPackPAT', {
          ...unpackPAT(EMPTY_PAT),
          headerList: [
            { name: '', colName: '' },
            { name: '', colName: '' },
          ],
        })
      } else {
        getUnpackedPatbyPatId(patId)
          .then((unpackedPat) => {
            setFieldValue('formikUnPackPAT', unpackedPat)

            if (unpackedPat?.headerList === null || unpackedPat?.headerList.length <= 0) {
              setFieldValue('formikUnPackPAT', {
                ...unpackedPat,
                headerList: [
                  { name: '', colName: '' },
                  { name: '', colName: '' },
                ],
              })
            }
          })
          .catch((err) => {
            console.log(err, 'err')
          })
      }
    }
  }, [patId])

  //MenuList MUI
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentSubIndex, setCurrentSubIndex] = useState(0)

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, subIdx: number) => {
    setCurrentIndex(index)
    setCurrentSubIndex(subIdx)
    event.stopPropagation()
    setRef(event.currentTarget)
  }

  const handleClose = () => {
    setRef(null)
  }
  //MenuList MUI

  const handleClickAdd = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setCurrentIndex(index)
    event.stopPropagation()

    setRefAdd(event.currentTarget)
  }

  const handleCloseAdd = () => {
    setRefAdd(null)
  }

  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const [titleTemplateOption, setTitleTemplateOption] = useState<IOption[]>([])

  const handleUploadFile = (value: any, patLineIndex: number, patsublineindex: number) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })
    const files: any = Array.from(value)
    if (files.length === 0) return
    const albumPhotosKey = `PAT/${patId}/customer/`
    files.map((file: any, index: number) => {
      const photoKey = albumPhotosKey + patLineIndex + '-' + patsublineindex + '-' + file.name
      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })
      upload
        .promise()
        .then(function (resAws) {
          console.log('resAws', resAws)
          setFieldValue(
            `formikUnPackPAT.lineList[${patLineIndex}][${patsublineindex}].text[${index}]`,
            resAws.Location,
            false,
          )
        })
        .catch(function (err) {
          console.log(err)
        })
    })
  }

  const goback = () => {
    const toLocation = (location.state && location.state.fromLocation) || -1
    const toState = props.router.location.state
      ? {
          values: location.state.values,
          selectedWorkType: location.state.selectedWorkType,
          patTemplateId: '',
        }
      : null
    navigate(toLocation, { state: toState })
  }

  // Fetch Title Template Data
  useEffect(() => {
    const userId = decodedAccessToken.userId
    if (userId) {
      getTitleTemplatesByUserId(userId)
        .then((res) => {
          const titleTemplateList: ITitleTemplate[] = res.data.data?.titleTemplateByUserId
          const optionData: IOption[] = titleTemplateList?.map((e) => {
            return { value: e.titleTemplateId, text: e.name ? e.name : '' }
          })
          optionData.splice(0, 0, { value: '', text: '' }) // Add the Blank at the beginning
          setTitleTemplateOption(optionData)
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    }
  }, [decodedAccessToken.userId])

  const pushlineType = (prop: any) => {
    const patSubLineListClone = [...values.formikUnPackPAT.lineList[prop.patLineIndex]]
    const cloneEMPTY_PAT_LINE = Object.assign({}, prop.EMP_OBJ)
    cloneEMPTY_PAT_LINE.lineType = prop.lineType
    patSubLineListClone.splice(patSubLineListClone.length, 0, cloneEMPTY_PAT_LINE)
    setFieldValue(`formikUnPackPAT.lineList[${prop.patLineIndex}]`, patSubLineListClone)
  }

  const replacelineType = (prop: any) => {
    const patSubLineListClone = [...values.formikUnPackPAT.lineList[prop.patLineIndex]]
    const cloneEMPTY_PAT_LINE = Object.assign({}, prop.EMP_OBJ)
    cloneEMPTY_PAT_LINE.lineType = prop.lineType
    patSubLineListClone.splice(prop.patsublineindex, 1)
    patSubLineListClone.splice(prop.patsublineindex, 0, cloneEMPTY_PAT_LINE)
    setFieldValue(`formikUnPackPAT.lineList[${prop.patLineIndex}]`, patSubLineListClone)
  }

  const deleteLineList = (patLineIndex: number, patsublineindex: number) => {
    const patSubLineListClone = [...values.formikUnPackPAT.lineList[patLineIndex]]
    patSubLineListClone.splice(patsublineindex, 1)
    setFieldValue(`formikUnPackPAT.lineList[${patLineIndex}]`, patSubLineListClone)
  }

  const duplicateLineList = (prop: any) => {
    const patSubLineListClone = [...values.formikUnPackPAT.lineList[prop.patLineIndex]]
    patSubLineListClone.splice(prop.patsublineindex + 1, 0, patSubLineListClone[prop.patsublineindex])
    setFieldValue(`formikUnPackPAT.lineList[${prop.patLineIndex}]`, patSubLineListClone)
  }

  const menuList = [
    {
      text: 'ข้อความเปล่า',
      img: titlePNG,
      funtionOnclick: (patLineIndex: number, patsublineindex: number) =>
        replacelineType({ EMP_OBJ: EMPTY_PAT_LINE, patLineIndex, patsublineindex, lineType: Line_Type.Text }),
    },
    {
      text: 'หัวข้อรอง',
      img: h2SVG,
      funtionOnclick: (patLineIndex: number, patsublineindex: number) =>
        replacelineType({ EMP_OBJ: EMPTY_PAT_LINE, patLineIndex, patsublineindex, lineType: Line_Type.Heading2 }),
    },
    {
      text: 'รูปภาพ',
      img: imgPNG,
      funtionOnclick: (patLineIndex: number, patsublineindex: number) =>
        replacelineType({ EMP_OBJ: EMPTY_PAT_LINE, patLineIndex, patsublineindex, lineType: Line_Type.Image }),
    },
    {
      text: 'อัพโหลดข้อมูล',
      img: uploadPNG,
      funtionOnclick: (patLineIndex: number, patsublineindex: number) =>
        replacelineType({
          EMP_OBJ: EMPTY_UPLOAD_PAT_LINE,
          patLineIndex,
          patsublineindex,
          lineType: Line_Type.Upload,
        }),
    },
    {
      text: 'Check Approve',
      img: checkApprovePNG,
      funtionOnclick: (patLineIndex: number, patsublineindex: number) =>
        replacelineType({
          EMP_OBJ: EMPTY_APPROVED_PAT_LINE,
          patLineIndex,
          patsublineindex,
          lineType: Line_Type.Approve,
        }),
    },
    {
      text: 'ทำซ้ำ',
      img: dupicatePNG,
      funtionOnclick: (patLineIndex: number, patsublineindex: number) =>
        duplicateLineList({ patLineIndex, patsublineindex }),
    },
    {
      text: 'ลบ',
      img: delPNG,
      funtionOnclick: (patLineIndex: number, patsublineindex: number) => {
        // Cant remove the first header text!!
        if (patsublineindex !== 0) deleteLineList(patLineIndex, patsublineindex)
      },
    },
  ]

  const menuListAdd = [
    {
      text: 'ข้อความเปล่า',
      img: titlePNG,
      funtionOnclick: (patLineIndex: number) =>
        pushlineType({ EMP_OBJ: EMPTY_PAT_LINE, patLineIndex, lineType: 'TEXT' }),
    },
    {
      text: 'หัวข้อรอง',
      img: h2SVG,
      funtionOnclick: (patLineIndex: number) =>
        pushlineType({ EMP_OBJ: EMPTY_PAT_LINE, patLineIndex, lineType: Line_Type.Heading2 }),
    },
    {
      text: 'รูปภาพ',
      img: imgPNG,
      funtionOnclick: (patLineIndex: number) =>
        pushlineType({ EMP_OBJ: EMPTY_PAT_LINE, patLineIndex, lineType: 'IMAGE' }),
    },
    {
      text: 'อัพโหลดข้อมูล',
      img: uploadPNG,
      funtionOnclick: (patLineIndex: number) =>
        pushlineType({
          EMP_OBJ: EMPTY_UPLOAD_PAT_LINE,
          patLineIndex,
          lineType: 'UPLOAD',
        }),
    },

    {
      text: 'Check Approve',
      img: checkApprovePNG,
      funtionOnclick: (patLineIndex: number) =>
        pushlineType({
          EMP_OBJ: EMPTY_APPROVED_PAT_LINE,
          patLineIndex,
          lineType: 'APPROVE',
        }),
    },
  ]

  return (
    <Form>
      {/* Breadcrumbs */}
      <div className={`contentWrapper`}>
        <b>
          <span
            onClick={() => {
              setFieldValue('isPanelOpen', false)
            }}
            style={{ cursor: 'pointer' }}
          >
            ชื่อเอกสาร ({values.formikUnPackPAT.name})
          </span>
          {' > '}
          การสร้างฟอร์มใบงาน
        </b>
      </div>
      <br />

      {/* Title and Cover Page */}
      <SectionTitleTemplate titleTemplateOption={titleTemplateOption} />
      <br />
      {/* Header Card */}
      <div className={`contentWrapper ${styles.panelcontent}`}>
        <CardHeaderPat headerList={values.formikUnPackPAT?.headerList} name={`formikUnPackPAT.headerList`} />
        <div
          className={styles.wrapperButton}
          onClick={() => {
            const formikLineListClone = [...values.formikUnPackPAT.lineList]
            formikLineListClone.splice(0, 0, [TEXT_PAT_LINE])
            setFieldValue(`formikUnPackPAT.lineList`, formikLineListClone)
            const formikUnPackClone = [...values.formikUnPackPAT.requiredList]
            formikUnPackClone.splice(0, 0, false)
            setFieldValue(`formikUnPackPAT.requiredList`, formikUnPackClone)
          }}
        >
          <img src={addSVG} alt="" />
        </div>
      </div>

      {/* Actual PAT LineList */}
      <div className={`contentWrapper ${styles.panelcontent}`}>
        {values.formikUnPackPAT.lineList?.map((patlineList: PatLine[], patLineIndex: number) => {
          return (
            <div key={patLineIndex} className={styles.panelsubcontent} draggable={false}>
              <div className={styles.lineIndexLabel}>{patLineIndex + 1}</div>
              <div className={styles.panelitems}>
                {patlineList.map((patsublinelist, patsublineindex: number) => {
                  return (
                    <div key={patsublineindex} className={`dp-flex ${styles.panelsubitems}`}>
                      {patsublinelist.lineType === Line_Type.Text ? (
                        <FormikInputText
                          className={`${styles.panelsubcontenttextfield} ${
                            patsublineindex === 0 ? styles.headerTextField : ''
                          }`}
                          name={`formikUnPackPAT.lineList[${patLineIndex}][${patsublineindex}].text`}
                          placeholder={patsublineindex === 0 ? 'หัวข้อหลัก' : 'ข้อความ'}
                        />
                      ) : patsublinelist.lineType === Line_Type.Heading2 ? (
                        <FormikInputText
                          className={`${styles.panelsubcontenttextfield} ${styles.h2TextField}`}
                          name={`formikUnPackPAT.lineList[${patLineIndex}][${patsublineindex}].text`}
                          placeholder="หัวข้อรอง"
                        />
                      ) : patsublinelist.lineType === Line_Type.Image ? (
                        <CardUploadImgPat
                          dataImg={values.formikUnPackPAT.lineList[patLineIndex][patsublineindex].text}
                          handleUpload={(e: React.FormEvent<HTMLInputElement>) =>
                            handleUploadFile((e.target as HTMLInputElement).files, patLineIndex, patsublineindex)
                          }
                        />
                      ) : patsublinelist.lineType === Line_Type.Upload ? (
                        <SectionTypeUploadPat
                          name={`formikUnPackPAT.lineList[${patLineIndex}][${patsublineindex}]`}
                          patLineIndex={patLineIndex}
                          patsublineindex={patsublineindex}
                        />
                      ) : patsublinelist.lineType === Line_Type.Approve ? (
                        <div className={styles.panelacceptancebox}>
                          <RadioComponent name="acceptancebox" label="ผ่านการตรวจสอบ" disabled />
                          <RadioComponent name="acceptancebox" label="ไม่ผ่านการตรวจสอบ" disabled />
                        </div>
                      ) : null}

                      {/* Ending with a button of lineType */}

                      <div
                        aria-checked={patsublineindex === 0}
                        className={styles.wrapperButtonImg}
                        onClick={(e) => handleClick(e, patLineIndex, patsublineindex)}
                      >
                        <img src={LineTypeImg[`${patsublinelist.lineType}`]} alt="" />
                        <p>{LineType[`${patsublinelist.lineType}`]}</p>
                      </div>
                    </div>
                  )
                })}

                {/* Extra PATLine Addition through dropdown */}
                <div className={styles.footerCard}>
                  <button
                    type="button"
                    className={styles.wrapperButtonFooter}
                    onClick={(e: any) => handleClickAdd(e, patLineIndex)}
                  >
                    <p>เพิ่มเมนู</p>
                    <ExpandMoreRoundedIcon />
                  </button>
                </div>
              </div>
              <hr style={{ margin: '20px 0' }} />
              {/* Button for Duplication and Delete */}
              <div className={styles.panelsubcontentfooter}>
                <img
                  className={styles.panelsubcontentfooterbtn}
                  onClick={() => {
                    const formikLineListClone = [...values.formikUnPackPAT.lineList]
                    formikLineListClone.splice(patLineIndex + 1, 0, values.formikUnPackPAT.lineList[patLineIndex])
                    setFieldValue(`formikUnPackPAT.lineList`, formikLineListClone)
                    const formikUnPackClone = [...values.formikUnPackPAT.requiredList]
                    formikUnPackClone.splice(patLineIndex + 1, 0, values.formikUnPackPAT.requiredList[patLineIndex])
                    setFieldValue(`formikUnPackPAT.requiredList`, formikUnPackClone)
                  }}
                  src={plusbox}
                  alt="plusbox"
                />
                <img
                  className={styles.panelsubcontentfooterbtn}
                  onClick={() => {
                    values.formikUnPackPAT.lineList.splice(patLineIndex, 1)
                    setFieldValue(`formikUnPackPAT.lineList`, values.formikUnPackPAT.lineList)
                  }}
                  src={binpic}
                  alt="binpic"
                />
                <hr style={{ margin: '0', height: '20px' }} />
              </div>
              {/* Button for adding new section */}
              <div
                className={styles.wrapperButton}
                onClick={() => {
                  const formikLineListClone = [...values.formikUnPackPAT.lineList]
                  formikLineListClone.splice(patLineIndex + 1, 0, [TEXT_PAT_LINE])
                  setFieldValue(`formikUnPackPAT.lineList`, formikLineListClone)
                  const formikUnPackClone = [...values.formikUnPackPAT.requiredList]
                  formikUnPackClone.splice(patLineIndex + 1, 0, false)
                  setFieldValue(`formikUnPackPAT.requiredList`, formikUnPackClone)
                }}
              >
                <img src={addSVG} alt="" />
              </div>
            </div>
          )
        })}
      </div>

      <Menu open={Boolean(ref)} anchorEl={ref ? ref : null} onClose={() => handleClose()} sx={ThemeMenu}>
        {menuList.map((item, idx: number) => (
          <MenuItem
            key={idx}
            onClick={() => {
              item.funtionOnclick?.(currentIndex, currentSubIndex)
              handleClose()
            }}
          >
            <img src={item.img} alt="" />
            <p>{item.text}</p>
          </MenuItem>
        ))}
      </Menu>

      <Menu open={Boolean(refAdd)} anchorEl={refAdd ? refAdd : null} onClose={() => handleCloseAdd()} sx={ThemeMenuAdd}>
        {menuListAdd.map((item, idx: number) => (
          <MenuItem
            key={idx}
            onClick={() => {
              item.funtionOnclick?.(currentIndex)
              handleCloseAdd()
            }}
          >
            <img src={item.img} alt="" />
            <p>{item.text}</p>
          </MenuItem>
        ))}
      </Menu>

      {/* Up Button */}
      <button
        className={styles.upButton}
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }}
      >
        Up
      </button>

      <FooterComponant
        listButtons={[
          {
            fucnctionOnclick: goback,
            text: 'ย้อนกลับ',
            type: 'button',
            class: 'btnDefault3',
          },
          {
            fucnctionOnclick: null,
            text: 'บันทึก',
            type: 'submit',
            class: 'btnDefault2',
          },
        ]}
      />
    </Form>
  )
}

const EnhancedPat = withFormik({
  mapPropsToValues: () => ({
    // ควรแก้ให้เป็นเหมือนหน้าที่พี่ทำ
    formikUnPackPAT: {},
  }),
  validate: (values) => {
    // console.log(values)
  },
  handleSubmit: (values: any, { props }: any) => {
    const createOrPutPat =
      location.pathname === '/customer/pat/pat'
        ? () => packAndCreatePat(values.formikUnPackPAT, props.userId)
        : () => packAndPutPat(values.formikUnPackPAT)

    createOrPutPat()
      .then((res) => {
        // console.log('After create or Put Pat res>> ', res)
        const toLocation = (props.router.location.state && props.router.location.state.fromLocation) || '../profilepage'
        const toState = props.router.location.state
          ? {
              values: props.router.location.state.values,
              selectedWorkType: props.router.location.state.selectedWorkType,
              patTemplateId: res.patId,
            }
          : null
        props.router.navigate(toLocation, { state: toState })
      })
      .catch((err) => {
        console.log(err, 'err')
        console.log(err)
      })
  },
})(Pat)

function mapStateToProps(state: ICombineReducers) {
  return { userId: state.tokenReducer.decodedAccessToken.userId }
}

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

const EnhancedPatHistory = withRouter(connect(mapStateToProps)(EnhancedPat) as any)

export default EnhancedPatHistory

const TEXT_PAT_LINE = {
  lineType: 'TEXT',
  text: 'Header Title',
}

const EMPTY_PAT_LINE = {
  lineType: '',
  text: '',
}

const EMPTY_UPLOAD_PAT_LINE = {
  text: '',
  uploadType: Upload_Type.Video,
  isNeedAfter: true,
  isNeedBefore: true,
  maximumFileSize: 4096,
  interval: 1,
  maximumFileNumber: 1,
  isDetail: false,
  levelRow: 1,
  defaultProperty: EPropertyType.Text,
  remarkProperty: EPropertyType.Text,
  rowList: [
    { name: '', property: null }, // This should be null --> defaultProperty
    { name: '', property: null }, // This should be null --> defaultProperty
  ],
  userVariable: [{ name: '', varname: '', value: 0 }],
  poleType: Tower_Type.Guyed,
  columnList: [''],
  equation: '',
  dropdown1: [''],
  dropdown2: [''],
  dropdown3: [''],
  checkTolerance: false,
}

const EMPTY_APPROVED_PAT_LINE = {
  isApproved: true,
  approvalRemark: '',
}

const LineType: any = {
  [Line_Type.Text]: 'ข้อความเปล่า',
  [Line_Type.Image]: 'รูปภาพ',
  [Line_Type.Upload]: 'อัพโหลดข้อมูล',
  [Line_Type.Approve]: 'Approve',
  [Line_Type.Group]: '',
  [Line_Type.Heading2]: 'หัวข้อรอง',
}

const LineTypeImg: any = {
  [Line_Type.Text]: titlePNG,
  [Line_Type.Image]: imgPNG,
  [Line_Type.Upload]: uploadPNG,
  [Line_Type.Approve]: checkPNG,
  [Line_Type.Group]: '',
  [Line_Type.Heading2]: h2SVG,
}
