import React from 'react'
import { Route, Routes, RouteProps, Navigate } from 'react-router-dom'
import LoaderSpinner from 'components/LoaderSpinner'
import ModalConfirm from './components/ModalConfirm'
import Pat from './pages/pat'
import PatUpload from './pages/patUpload'
import Addprojectpage from './pages/addprojectpage'
import Projectmanagementpage from './pages/projectmanagementpage'
import MyProjectPage from './pages/myProjectPage'
import Landingpage from './pages/landingpage'
import Loginpage from './pages/loginpage'
import GenerateChangTumIdPage from 'pages/GenerateChangTumIdPage'
import TermsConditionsPage from './pages/termsconditionspage'
import Homepage from './pages/homepage'
// import ComingSoonPage from './pages/ComingSoon'
import CreatePostPage from './pages/createpostpage'
import ProfileTeamList from './pages/profileTeamList'
import ProfilePage from './pages/profilePage'
import VerifyPage from './pages/VerifyPage'

import AcceptJob from 'pages/NewsFeedFilterTechPage/acceptJob'
import NewsFeedPage from 'pages/NewsFeed'
import NewsFeedFilterTechPage from 'pages/NewsFeedFilterTechPage'
import ModalAlertStatus from 'components/ModalGlobal'
import EditProfileTeam from 'pages/EditProfileTeam'
import ForgotPasswordpage from 'pages/ForgotPassword'
import CreateTeam from 'pages/createTeam'
import ProfileUserInTeamPage from 'pages/ProfileUserInTeam'
import { Layout } from 'layout'
import { RequireAuth } from 'layout/requireAuth'
// import VerifyThird from 'pages/VerifyPage/verifyThird'
// import VerifyTeam from 'pages/createTeam/verify'
export const CUSTOMER_PREFIX = 'customer'
export const TECHNICIAN_PREFIX = 'technician'
export const CUSTOMER_LABEL = 'ผู้จ้าง'
export const TECHNICIAN_LABEL = 'ผู้รับเหมา'
export const CUSTOMER_PREFIX2LABEL = {
  [CUSTOMER_PREFIX]: CUSTOMER_LABEL,
  [TECHNICIAN_PREFIX]: TECHNICIAN_LABEL,
}

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        {/* Development page for generating changtum Id */}
        <Route path="/generateChangTumId/:qtyIds" element={<GenerateChangTumIdPage />} />

        {/* Admin */}
        {/* <Route exact path="/admin">
          <AdminPage />
        </Route> */}

        {/* Production routes for customers */}
        <Route path="/:customerOrTechnician/*" element={<Layout />}>
          <Route index element={<Landingpage />} />

          {/* Switch is used for else if instead of i */}
          <Route path="termsandconditions" element={<TermsConditionsPage />} />
          <Route path="login" element={<Loginpage />} />

          {/* Change this to customer or technician later */}
          <Route element={<RequireAuth redirectTo="/customer/login" />}>
            <Route path="home" element={<Homepage />} />
            <Route path="myproject" element={<MyProjectPage />} />
            <Route path="myproject/createproject" element={<Addprojectpage />} />
            <Route path="myproject/:projectId" element={<Addprojectpage />} />
            <Route path="projectmanagementpage" element={<Projectmanagementpage />} />
            <Route path="addprojectpage" element={<Addprojectpage />} />

            {/* <Route exact path="newfeedsfilterTech" element={NewsFeedFilterTechPage} /> */}
            <Route path="newfeeds" element={<NewsFeedPage />} />
            <Route path="newfeedsfilterTech/:projectId" element={<NewsFeedFilterTechPage />} />
            <Route path="acceptJob/:postId" element={<AcceptJob />} />

            <Route path="pat/:patId" element={<Pat />} />
            <Route path="pat-upload/:patId" element={<PatUpload />} />
            <Route path="pat-approve/:patId" element={<PatUpload />} />
            <Route path="createPostPage/:projectID" element={<CreatePostPage />} />
            <Route path="applyJob/:postID" element={<CreatePostPage />} />
            {/* <ProtectedRoute path="createPostPage/:projectID" component={CreatePostPage} /> */}
            {/* <ProtectedRoute  path="verify/:userId" component={VerifyTeam} /> */}

            <Route path="profilepage" element={<ProfilePage />} />
            <Route path="profileteamlist" element={<ProfileTeamList />} />
            <Route path="profileteamlist/editprofile/:userId" element={<EditProfileTeam />} />
            <Route path="createTeam" element={<CreateTeam />} />
            <Route path="editTeam/:userId" element={<CreateTeam />} />
            <Route path="profileUserteam/:userId" element={<ProfileUserInTeamPage />} />
            <Route path="verifypage" element={<VerifyPage />} />

            <Route path="forgotpassword" element={<ForgotPasswordpage />} />

            {/* <ProtectedRoute path="/" component={ComingSoonPage} /> */}
          </Route>
        </Route>

        {/* https://stackoverflow.com/questions/69868956/how-can-i-redirect-in-react-router-v6 */}
        <Route path="*" element={<Navigate to="/customer" replace />} />
      </Routes>
      <ModalAlertStatus />
      <ModalConfirm />
      <LoaderSpinner />
    </div>
  )
}

export default App
