export const queryProjectByUserId = `
query($userId:ID!) {
  projectByUserId(userId: $userId){
    projectId
    status
    projectName
  }
}`

export const queryGetUser = `
query Query{
  getUser{
    userId
    username
    customAttrs
    friendList {
      userId
      nickname
    }
  }
}`

export const queryProjectByAcceptedUserId = `
query($acceptedUserId:ID!) {
  projectByAcceptedUserId(acceptedUserId: $acceptedUserId){
    projectId
    status
    projectName
  }
}`

/// OLD
// export const queryProjectByProjectId = `
// query($projectId:ID!, $jobFilter:JSON) {
//   project(projectId: $projectId, jobFilter: $jobFilter){
//     projectId
//     projectName
//     projectDescription
//     projectManagerName
//     createdByUserId
//     startDate
//     endDate
//     createdAt
//     updatedAt
//     status
//     TORFile
//     workTypeList {
//       workType
//       lineNumber
//       patTemplateId
//       isNoPrice
//       jobUnitPrice
//     }
//     jobList{
//       changTumId
//       jobId
//       workType
//       region
//       province
//       district
//       siteCode
//       siteName
//       zone
//       number
//       lat
//       long
//       status
//       applicationID
//       acceptedUserId
//       assignedUserId
//       assignedUserPhone
//       planDate
//       actualDate
//       description
//       remark
//       addition1
//       addition2
//       isPatGenerated
//       patStatus
//     }
//   }
// }`

export const queryProjectByProjectId = `
query Project($projectId: ID!) {
  project(projectId: $projectId) {
    projectId
    projectName
    projectDescription
    createdByUserId
    startDate
    endDate
    createdAt
    updatedAt
    status
    TORFile
    projectManagerName
    workTypeList {
      workType
      lineNumber
      patTemplateId
      isNoPrice
      jobUnitPrice
      totalJobQty
      isSafetyPassport
      safetyPassport
      requirements
      imageURLList
    }
    jobList {
      changTumId
      jobId
      workType
      region
      province
      district
      siteCode
      siteName
      zone
      number
      googleMap
      lat
      long
      status
      applicationID
      acceptedUserId
      assignedUserId
      assignedUserPhone
      planDate
      actualDate
      description
      remark
      addition1
      addition2
      isPatGenerated
      patStatus
      patUpdatedAt
    }
    customFieldList {
      name
      allowedValueList
    }
    minPrice
    maxPrice
    isPassport
    acceptedUserIdList {
      userId
      getUser {
        username
      }
    }
  }
}`

export const queryPatByPatId = `query Query($patId: ID) {
  pat(patId: $patId) {
     patId
    name
    description
    createdAt
    updatedAt
    versionNo
    status
    patExport
    headerList {
      name
      colName
    }
    titleTemplate {
      titleTemplateId
      name
      description
      userId
      attributeList {
        attributeName
        attributeType
        value {
          ... on StringLineValue {
            text
          }
          ... on UploadLineValue {
            text
            uploadType
            isNeedBefore
            isNeedAfter
            maximumFileNumber
            maximumFileSize
            uploadBeforeValue
            uploadAfterValue
          }
        }
      }
    }
    lineList {
      lineNo
      lineType
      value {
        ... on GroupLineValue {
          required
          lineList {
            lineNo
            lineType
            value {
              ... on StringLineValue {
                text
              }
              ... on UploadLineValue {
                text
                uploadType
                isNeedAfter
                isNeedBefore
                maximumFileSize
                maximumFileNumber
                uploadBeforeValue
                uploadAfterValue
              }

              ... on UploadChecklist {
                text
                uploadType
                equation
                defaultProperty
                remarkProperty
                levelRow
                rowList {
                  name
                  property
                  children {
                    name
                    property
                    children {
                      name
                      property
                    }
                  }
                }
                columnList
                dropdown1
                dropdown2
                dropdown3
                data
              }
              ... on UploadGuyedLegs {
                text
                uploadType
                numberLegs
                CTA
                CTB
                CTC
                CTD
                AB
                BC
                CD
                DA
                CA
              }

              ... on UploadMeasure {
                text
                uploadType
                checkTolerance
                maxTolerance
                minTolerance
                userVariable {
                  name
                  varname
                  value
                }
                columnList
                start
                end
                interval
                data
              }
              
              ... on UploadAntenna {
                text
                uploadType
                poleType
                isDetail
                poleList {
                  name
                  hasLegs
                  poleSize
                  planeList {
                    height
                    mounting
                    antenna {
                      colorGroup {
                        id
                        color
                        antennaType
                        w
                        l
                        d
                        dia
                      }
                      A
                      B
                      C
                      D
                    }
                    other {
                      colorGroup {
                        id
                        color
                        antennaType
                        w
                        l
                        d
                        dia
                      }
                      A
                      B
                      C
                      D
                    }
                  }
                }
              }
              ... on ApproveLineValue {
                isApproved
                approvalRemark
              }
            }
          }
        }
      }
    }
    titleTemplate {
      titleTemplateId
      name
      description
      userId
      attributeList {
        attributeName
        attributeType
        value {
          ... on StringLineValue {
            text
          }
          ... on UploadLineValue {
            text
            uploadType
            isNeedBefore
            isNeedAfter
            maximumFileNumber
            maximumFileSize
            uploadBeforeValue
            uploadAfterValue
          }
        }
      }
    }
  }
}`

export const queryJobByChangTumId = `
query Query($projectId: ID!, $changTumId: ID!) {
  job(projectId: $projectId, changTumId: $changTumId) {
    changTumId
    jobId
    region
    province
    district
    siteCode
    siteName
    zone
    number
    googleMap
    lat
    long
    workType
    planDate
    actualDate
    acceptedUserId
    room
    siteArea
    towerSubType
    towerType
    status
    dimension
    manufacturer
    manufacturerDate
    installer
    installerDate
    height
    gateWidth
  }
}`

export const queryNotificationByUserId = `
query ($notificationByUserIdUserId: ID!,$limit: Int) {
  notificationByUserId(userId: $notificationByUserIdUserId,limit: $limit) {
    items {
      userId
      notificationId
      message
      iconUrl
      createdAt
      toLink
      isRead
    }
    nextToken
  }
}
`
export const queryCreatedByUserIDFromProjectByProjectId = `
query($projectId:ID!, $jobFilter:JSON) {
  project(projectId: $projectId, jobFilter: $jobFilter){
    createdByUserId
    projectName
  }
}`

export const queryListPosts = `
query Query($sortDirection: ModelSortDirection) {
  listPostsForFix(sortDirection: $sortDirection) {
    postID
    projectID
    createdByUserID
    companyName
    title
    description
    startDate
    endDate
    createdAt
    updatedAt
    status
    postSortBy
    imageURLList
    fileAttachmentList
    workTypePostList {
      workType
      isLinked
      fieldID
      jobList
      totalJobList {
        fieldValue
        totalQty
        balanceQty
        approveQty
      }
      jobUnitPrice
    }
    project {
      projectId
      projectName
      projectDescription
      startDate
      endDate
      createdAt
      updatedAt
      status
      TORFile
      minPrice
      maxPrice
      isPassport
    }
    user {
      userId
      username
    }
  }
}
`

export const queryGetPost = `
query Post($postId: ID!) {
  post(postID: $postId) {
    postID
    projectID
    createdByUserID
    companyName
    title
    description
    startDate
    endDate
    createdAt
    updatedAt
    status
    postSortBy
    imageURLList
    fileAttachmentList
    workTypePostList {
      workType
      isLinked
      fieldID
      jobList
      totalJobList {
        fieldValue
        totalQty
        balanceQty
        approveQty
      }
      jobUnitPrice
    }
    project {
      projectName
      workTypeList {
        imageURLList
      }
    }
    user {
      username
      firstName
      lastName
    }
  }
}`

export const queryPostByUserId = `
query PostByCreatedByUserID($sortDirection: ModelSortDirection, $createdByUserId: ID!) {
  postByCreatedByUserID(sortDirection: $sortDirection, createdByUserID: $createdByUserId) {
    postID
    projectID
    createdByUserID
    companyName
    title
    description
    startDate
    endDate
    createdAt
    updatedAt
    postSortBy
    imageURLList
    fileAttachmentList
    user {
      username
      userId
    }
    workTypePostList {
      workType
      isLinked
      fieldID
      jobList
      totalJobList {
        fieldValue
        totalQty
        balanceQty
        approveQty
      }
      jobUnitPrice
    }
    project {
      projectId
      projectName
      projectDescription
      createdByUserId
      startDate
      endDate
      createdAt
      updatedAt
      status
      projectManagerName
      TORFile
      projectManagerPosition
      jobList {
        changTumId
        jobId
        region
        workType
        province
        district
        siteCode
        siteName
        zone
        number
        googleMap
        lat
        long
        status
        applicationID
        acceptedUserId
        assignedUserId
        assignedUserPhone
        planDate
        actualDate
        description
        remark
        addition1
        addition2
        isPatGenerated
        patStatus
        patUpdatedAt
      }
      minPrice
      maxPrice
      isPassport
      customFieldList {
        name
        allowedValueList
      }
    }
    jobApplication {
      workTypeJobApplicationList {
        status
      }
    }
  }
}
`

//JobHistory =====>> OLD
// export const queryListJobByUserID = `
// query Query($createdByUserId: ID!) {
//   listJobByUserID(createdByUserID: $createdByUserId) {
//     postID
//     createdByUserID
//     applicationID
//     workTypeJobApplicationList {
//       workType
//       isLinked
//       fieldID
//       jobList
//       totalJobList {
//         fieldValue
//         registerQty
//         approveQty
//       }
//     }
//     technicianList {
//       userID
//       getUser {
//         userId
//         username
//       }
//     }
//     createdAt
//     updatedAt
//     status
//     post {
//       postID
//       projectID
//       createdByUserID
//       companyName
//       title
//       description
//       startDate
//       endDate
//       createdAt
//       updatedAt
//       status
//       imageURLList
//       fileAttachmentList
//       workTypePostList {
//         workType
//         isLinked
//         fieldID
//         jobList
//         totalJobList {
//           fieldValue
//           totalQty
//           balanceQty
//           registerQty
//           approveQty
//         }
//         jobUnitPrice
//       }
//       project {
//         projectId
//         projectName
//       }
//       jobApplication {
//         postID
//         createdByUserID
//         applicationID
//         workTypeJobApplicationList {
//           workType
//           isLinked
//           fieldID
//           jobList
//           totalJobList {
//             fieldValue
//             registerQty
//             approveQty
//           }
//         }
//         createdAt
//         updatedAt
//         status
//       }
//       user {
//         username
//       }
//     }
//   }
// }
// `

export const queryListJobByUserID = `
query JobApplicationByMe {
  jobApplicationByMe {
    items {
      postID
      createdByUserID
      applicationID
      workTypeJobApplicationList {
        workType
        fieldID
        jobList
        status
        totalJobList {
          fieldValue
          registerQty
          approveQty
        }
      }
      technicianList {
        userId
        getUser {
          userId
          username
        }
      }
      createdAt
      updatedAt
      post {
        postID
        title
        startDate
        endDate
        imageURLList
        createdAt
        fileAttachmentList
        workTypePostList {
          workType
          isLinked
          fieldID
          totalJobList {
            fieldValue
            totalQty
            balanceQty
          }
        }
        user {
          username
          userId
        }
      }
    }
    nextToken
  }
}
`

//queryPostByProjectID
export const querypostByProjectID = `
query Query($projectId: ID!) {
  postByProjectID(projectID: $projectId) {
    postID
    projectID
    createdByUserID
    companyName
    title
    description
    startDate
    endDate
    createdAt
    status
    updatedAt
    postSortBy
    imageURLList
    fileAttachmentList
    workTypePostList {
      workType
      isLinked
      fieldID
      jobList
      totalJobList {
        fieldValue
        totalQty
        balanceQty
        approveQty
      }
      jobUnitPrice
    }
    project {
      projectId
      projectName
      projectDescription
      createdByUserId
      startDate
      endDate
      createdAt
      updatedAt
      status
      
      
      minPrice
      maxPrice
      isPassport
      customFieldList {
        name
        allowedValueList
      }
    }
    jobApplication {
      workTypeJobApplicationList {
        status
      }
    }
  }
}
`

export const queryGetTeam = `
query GetUser {
  getUser {
    technicianList {
      getUser {
        status
        userId
        username
        firstName
        lastName
        technicianJobType
        safetyPassport {
          safetyPassportType
          safetyPassportStatus
        }
      }
    }
  }
}`
//OLD
// export const queryGetPostfilter = `
// query Post($postId: ID!) {
//   post(postID: $postId) {
//     postID
//     projectID
//     createdByUserID
//     companyName
//     title
//     status
//     workTypePostList {
//       workType
//       isLinked
//       fieldID
//       jobList
//       totalJobList {
//         fieldValue
//         totalQty
//         balanceQty
//         registerQty
//         approveQty
//       }
//       jobUnitPrice
//     }
//     project {
//       projectId
//       projectName
//       workTypeList {
//         imageURLList
//         jobUnitPrice
//         workType
//       }
//     }
//     user {
//       userId
//       username

//     }
//     jobApplication {
//       postID
//       createdByUserID
//       applicationID
//       workTypeJobApplicationList {
//         fieldID
//         isLinked
//         workType
//         jobList
//         totalJobList {
//           fieldValue
//           registerQty
//           approveQty
//         }
//       }
//       technicianList {
//         userID
//         getUser {
//           userId
//           username
//           displayName
//           customAttrs
//           friendList {
//             userId
//             nickname
//             displayName
//           }
//           isVerification
//           userVerification {
//             status
//             companyCertificateFileAttachment
//             vatFileAttachment
//             personalIDFileAttachment
//             coordinatorPersonalIDFileAttachment
//           }
//           technicianList {
//             getUser {
//               userId
//               username
//             }
//           }
//           myWorkTeamName
//           myWorkFileAttachment
//           myWorkImageList
//           myWorkTechnicianJobType
//           myWorkDescription
//           myWorkserviceRegion
//           myWorkserviceProvince
//           email
//           phone
//           addressDetail
//           safetyPassport {
//             safetyPassportType
//             safetyPassportStatus
//           }
//         }
//       }
//       createdAt
//       updatedAt
//       status
//     }
//   }
// }
// `

export const queryGetPostfilter = `
query Post($postId: ID!) {
  post(postID: $postId) {
    postID
    projectID
    createdByUserID
    title
    description
    startDate
    endDate
    createdAt
    updatedAt
    workTypePostList {
      workType
      isLinked
      fieldID
      totalJobList {
        fieldValue
        totalQty
        balanceQty
        approveQty
      }
      jobUnitPrice
      listWorkTypeJobApplication {
        nextToken
        items {
          workType
          fieldID
          status
          totalJobList {
            fieldValue
            registerQty
            approveQty
          }
          jobApplication {
            applicationID
            createdByUserID
            createdAt
            updatedAt
            technicianList {
              userId
              getUser {
                username
                firstName
                lastName
                safetyPassport {
                  safetyPassportType
                }
                technicianJobType
              }
            }
            user {
              companyName
              technicianJobType
              safetyPassport {
                safetyPassportType
                safetyPassportFileAttachment
              }
              addressDetail
              phone
              email
              myWorkImageList
              myWorkDescription
              myWorkTeamName
              myWorkFileAttachment
            }
          }
        }
      }
    }
    project {
      projectId
      projectName
      projectDescription
      status
      startDate
      endDate
    }
    user {

      username

      zipCode
      district
      subDistrict
      province
      addressDetail
      phone
      email

      myWorkImageList
      myWorkFileAttachment
      myWorkDescription

    }
  }
}
`

//Profile Team

export const queryProfileTeam = `
query Query($userId: ID!) {
  adminGetUser(userId: $userId) {
    userId
    username
    displayName
    customAttrs
    isVerification
    userVerification {
      userId
      status
      companyCertificateFileAttachment
      vatFileAttachment
      personalIDFileAttachment
      coordinatorPersonalIDFileAttachment
      selfiePersonalIDFileAttachment
      createdAt
      updatedAt
    }
    myWorkTeamName
    myWorkFileAttachment
    myWorkImageList
    myWorkTechnicianJobType
    myWorkDescription
    myWorkserviceRegion
    myWorkserviceProvince
    userType
    companyName
    companyBranch
    taxID
    zipCode
    province
    district
    subDistrict
    addressDetail
    phone
    email
    isSafetyPassport
    
  }
}
`

//getUser

export const querygetUserProfile = `
query Query {
  getUser {
    logoUrl
    userId
    username
    myWorkTeamName
    myWorkFileAttachment
    myWorkImageList
    zipCode
    province
    district
    subDistrict
    addressDetail
    phone
    email
    companyName
    friendList{
      userId
      nickname
      displayName
    }
    technicianList {
      getUser {
        userId
        username
        phone
        firstName
        lastName
        technicianJobType
        serviceProvince
        safetyPassport {
          safetyPassportStatus
          safetyPassportType
          safetyPassportFileAttachment
        }
        createdByUserId
        password_hash
        isVerification
        userVerification {
          userId
          status
          companyCertificateFileAttachment
          vatFileAttachment
          selfieJuristicIDFileAttachment
          coordinatorPersonalIDFileAttachment
          selfiePersonalIDFileAttachment
          personalIDFileAttachment
          createdAt
          updatedAt
        }
        status
        taxID
        zipCode
        province
        district
        subDistrict
        addressDetail
        email
        coordinatorName
        coordinatorPersonalID
        birthDate
        nickName
        gender
        personalRelation
        personalID
        emergencyContact {
          lastName
          firstName
          relation
          phone
        }
        serviceRegion
        isSafetyPassport
      }
    }
    myWorkTechnicianJobType
    myWorkDescription
    myWorkserviceRegion
    myWorkserviceProvince
    firstName
    lastName
    coordinatorName
    coordinatorPersonalID
    coordinatorAddress
    coordinatorPhone
    birthDate
    nickName
    gender
    personalRelation
    personalID
    emergencyContact {
      firstName
      lastName
      relation
      phone
    }
    technicianJobType
    serviceRegion
    serviceProvince
    taxID
    companyBranch
    userType
    isVerification
    isSafetyPassport
    safetyPassport {
      safetyPassportStatus
      safetyPassportType
      safetyPassportFileAttachment
    }
    userVerification {
      userId
      status
      companyCertificateFileAttachment
      vatFileAttachment
      personalIDFileAttachment
      coordinatorPersonalIDFileAttachment
      selfiePersonalIDFileAttachment
      createdAt
      updatedAt
    }
    status
  }
  
}
`

export const queryMasterPlan = `
query project($projectId: ID!, $planningYear: String!, $acceptedUserId: ID!) {
  project(projectId: $projectId) {
    projectId
    acceptedUserIdList {
      userId
      getUser {
        username
      }
    }
    customFieldList {
      name
      allowedValueList
    }
    masterPlan: masterPlanByAcceptUserId(planningYear: $planningYear, acceptedUserId: $acceptedUserId) {
      projectId
      planningYear
      fieldID
      groupFieldList {
        fieldValue
        workTypeList {
          workType
          workTypeTable {
            week_1
            week_2
            week_3
            week_4
            week_5
            week_6
            week_7
            week_8
            week_9
            week_10
            week_11
            week_12
            week_13
            week_14
            week_15
            week_16
            week_17
            week_18
            week_19
            week_20
            week_21
            week_22
            week_23
            week_24
            week_25
            week_26
            week_27
            week_28
            week_29
            week_30
            week_31
            week_32
            week_33
            week_34
            week_35
            week_36
            week_37
            week_38
            week_39
            week_40
            week_41
            week_42
            week_43
            week_44
            week_45
            week_46
            week_47
            week_48
            week_49
            week_50
            week_51
            week_52
            week_53
          }
        }
      }
      updatedAt
    }
  }
}
`

export const queryMasterPlanExportInno = `
query Project($projectId: ID!, $planningYear: String!) {
  project(projectId: $projectId) {
    masterPlan(planningYear: $planningYear) {
      groupFieldList {
        fieldValue
        workTypeList {
          workType
          workTypeTable {
            week_1 {
              acceptedUserId
              quantity
            }
            week_2 {
              acceptedUserId
              quantity
            }
            week_3 {
              acceptedUserId
              quantity
            }
            week_4 {
              acceptedUserId
              quantity
            }
            week_5 {
              acceptedUserId
              quantity
            }
            week_6 {
              acceptedUserId
              quantity
            }
            week_7 {
              acceptedUserId
              quantity
            }
            week_8 {
              acceptedUserId
              quantity
            }
            week_9 {
              acceptedUserId
              quantity
            }
            week_10 {
              acceptedUserId
              quantity
            }
            week_11 {
              acceptedUserId
              quantity
            }
            week_12 {
              acceptedUserId
              quantity
            }
            week_13 {
              acceptedUserId
              quantity
            }
            week_14 {
              acceptedUserId
              quantity
            }
            week_15 {
              acceptedUserId
              quantity
            }
            week_16 {
              acceptedUserId
              quantity
            }
            week_17 {
              acceptedUserId
              quantity
            }
            week_18 {
              acceptedUserId
              quantity
            }
            week_19 {
              acceptedUserId
              quantity
            }
            week_20 {
              acceptedUserId
              quantity
            }
            week_21 {
              acceptedUserId
              quantity
            }
            week_22 {
              acceptedUserId
              quantity
            }
            week_23 {
              acceptedUserId
              quantity
            }
            week_24 {
              acceptedUserId
              quantity
            }
            week_25 {
              acceptedUserId
              quantity
            }
            week_26 {
              acceptedUserId
              quantity
            }
            week_27 {
              acceptedUserId
              quantity
            }
            week_28 {
              acceptedUserId
              quantity
            }
            week_29 {
              acceptedUserId
              quantity
            }
            week_30 {
              acceptedUserId
              quantity
            }
            week_31 {
              acceptedUserId
              quantity
            }
            week_32 {
              acceptedUserId
              quantity
            }
            week_33 {
              acceptedUserId
              quantity
            }
            week_34 {
              acceptedUserId
              quantity
            }
            week_35 {
              acceptedUserId
              quantity
            }
            week_36 {
              acceptedUserId
              quantity
            }
            week_37 {
              acceptedUserId
              quantity
            }
            week_38 {
              acceptedUserId
              quantity
            }
            week_39 {
              acceptedUserId
              quantity
            }
            week_40 {
              acceptedUserId
              quantity
            }
            week_41 {
              acceptedUserId
              quantity
            }
            week_42 {
              acceptedUserId
              quantity
            }
            week_43 {
              acceptedUserId
              quantity
            }
            week_44 {
              acceptedUserId
              quantity
            }
            week_45 {
              acceptedUserId
              quantity
            }
            week_46 {
              acceptedUserId
              quantity
            }
            week_47 {
              acceptedUserId
              quantity
            }
            week_48 {
              acceptedUserId
              quantity
            }
            week_49 {
              acceptedUserId
              quantity
            }
            week_50 {
              acceptedUserId
              quantity
            }
            week_51 {
              acceptedUserId
              quantity
            }
            week_52 {
              acceptedUserId
              quantity
            }
            week_53 {
              acceptedUserId
              quantity
            }
          }
        }
      }
      projectId
      planningYear
      fieldID
      updatedAt
    }
    
  }
}
`
export const queryPlanExportInno = `
query PAE_Export_plan($projectId: ID!) {
  project(projectId: $projectId) {

    projectId

    planDateReport {
      fieldID

      groupFieldList {
        fieldValue

        workTypeList {
          workType

          workTypeTable {
            week_1 {
              acceptedUserId
              quantity
            }
            week_2 {
              acceptedUserId
              quantity
            }
            week_3 {
              acceptedUserId
              quantity
            }
            week_4 {
              acceptedUserId
              quantity
            }
            week_5 {
              acceptedUserId
              quantity
            }
            week_6 {
              acceptedUserId
              quantity
            }
            week_7 {
              acceptedUserId
              quantity
            }
            week_8 {
              acceptedUserId
              quantity
            }
            week_9 {
              acceptedUserId
              quantity
            }
            week_10 {
              acceptedUserId
              quantity
            }
            week_11 {
              acceptedUserId
              quantity
            }
            week_12 {
              acceptedUserId
              quantity
            }
            week_13 {
              acceptedUserId
              quantity
            }
            week_14 {
              acceptedUserId
              quantity
            }
            week_15 {
              acceptedUserId
              quantity
            }
            week_16 {
              acceptedUserId
              quantity
            }
            week_17 {
              acceptedUserId
              quantity
            }
            week_18 {
              acceptedUserId
              quantity
            }
            week_19 {
              acceptedUserId
              quantity
            }
            week_20 {
              acceptedUserId
              quantity
            }
            week_21 {
              acceptedUserId
              quantity
            }
            week_22 {
              acceptedUserId
              quantity
            }
            week_23 {
              acceptedUserId
              quantity
            }
            week_24 {
              acceptedUserId
              quantity
            }
            week_25 {
              acceptedUserId
              quantity
            }
            week_26 {
              acceptedUserId
              quantity
            }
            week_27 {
              acceptedUserId
              quantity
            }
            week_28 {
              acceptedUserId
              quantity
            }
            week_29 {
              acceptedUserId
              quantity
            }
            week_30 {
              acceptedUserId
              quantity
            }
            week_31 {
              acceptedUserId
              quantity
            }
            week_32 {
              acceptedUserId
              quantity
            }
            week_33 {
              acceptedUserId
              quantity
            }
            week_34 {
              acceptedUserId
              quantity
            }
            week_35 {
              acceptedUserId
              quantity
            }
            week_36 {
              acceptedUserId
              quantity
            }
            week_37 {
              acceptedUserId
              quantity
            }
            week_38 {
              acceptedUserId
              quantity
            }
            week_39 {
              acceptedUserId
              quantity
            }
            week_40 {
              acceptedUserId
              quantity
            }
            week_41 {
              acceptedUserId
              quantity
            }
            week_42 {
              acceptedUserId
              quantity
            }
            week_43 {
              acceptedUserId
              quantity
            }
            week_44 {
              acceptedUserId
              quantity
            }
            week_45 {
              acceptedUserId
              quantity
            }
            week_46 {
              acceptedUserId
              quantity
            }
            week_47 {
              acceptedUserId
              quantity
            }
            week_48 {
              acceptedUserId
              quantity
            }
            week_49 {
              acceptedUserId
              quantity
            }
            week_50 {
              acceptedUserId
              quantity
            }
            week_51 {
              acceptedUserId
              quantity
            }
            week_52 {
              acceptedUserId
              quantity
            }
            week_53 {
              acceptedUserId
              quantity
            }
          }

        }
      }

    }

  }
}`

export const queryInstalledExportInno = `
query PAE_Export_actual($projectId: ID!) {
  project(projectId: $projectId) {

    projectId

    actualDateReport {
      fieldID

      groupFieldList {
        fieldValue

        workTypeList {
          workType

          workTypeTable {
            week_1 {
              acceptedUserId
              quantity
            }
            week_2 {
              acceptedUserId
              quantity
            }
            week_3 {
              acceptedUserId
              quantity
            }
            week_4 {
              acceptedUserId
              quantity
            }
            week_5 {
              acceptedUserId
              quantity
            }
            week_6 {
              acceptedUserId
              quantity
            }
            week_7 {
              acceptedUserId
              quantity
            }
            week_8 {
              acceptedUserId
              quantity
            }
            week_9 {
              acceptedUserId
              quantity
            }
            week_10 {
              acceptedUserId
              quantity
            }
            week_11 {
              acceptedUserId
              quantity
            }
            week_12 {
              acceptedUserId
              quantity
            }
            week_13 {
              acceptedUserId
              quantity
            }
            week_14 {
              acceptedUserId
              quantity
            }
            week_15 {
              acceptedUserId
              quantity
            }
            week_16 {
              acceptedUserId
              quantity
            }
            week_17 {
              acceptedUserId
              quantity
            }
            week_18 {
              acceptedUserId
              quantity
            }
            week_19 {
              acceptedUserId
              quantity
            }
            week_20 {
              acceptedUserId
              quantity
            }
            week_21 {
              acceptedUserId
              quantity
            }
            week_22 {
              acceptedUserId
              quantity
            }
            week_23 {
              acceptedUserId
              quantity
            }
            week_24 {
              acceptedUserId
              quantity
            }
            week_25 {
              acceptedUserId
              quantity
            }
            week_26 {
              acceptedUserId
              quantity
            }
            week_27 {
              acceptedUserId
              quantity
            }
            week_28 {
              acceptedUserId
              quantity
            }
            week_29 {
              acceptedUserId
              quantity
            }
            week_30 {
              acceptedUserId
              quantity
            }
            week_31 {
              acceptedUserId
              quantity
            }
            week_32 {
              acceptedUserId
              quantity
            }
            week_33 {
              acceptedUserId
              quantity
            }
            week_34 {
              acceptedUserId
              quantity
            }
            week_35 {
              acceptedUserId
              quantity
            }
            week_36 {
              acceptedUserId
              quantity
            }
            week_37 {
              acceptedUserId
              quantity
            }
            week_38 {
              acceptedUserId
              quantity
            }
            week_39 {
              acceptedUserId
              quantity
            }
            week_40 {
              acceptedUserId
              quantity
            }
            week_41 {
              acceptedUserId
              quantity
            }
            week_42 {
              acceptedUserId
              quantity
            }
            week_43 {
              acceptedUserId
              quantity
            }
            week_44 {
              acceptedUserId
              quantity
            }
            week_45 {
              acceptedUserId
              quantity
            }
            week_46 {
              acceptedUserId
              quantity
            }
            week_47 {
              acceptedUserId
              quantity
            }
            week_48 {
              acceptedUserId
              quantity
            }
            week_49 {
              acceptedUserId
              quantity
            }
            week_50 {
              acceptedUserId
              quantity
            }
            week_51 {
              acceptedUserId
              quantity
            }
            week_52 {
              acceptedUserId
              quantity
            }
            week_53 {
              acceptedUserId
              quantity
            }
          }

        }
      }

    }

  }
}`

//getTeam
export const queryGetUserInTeam = `
query Query($userId: ID!) {
  getUserInTeam(userId: $userId) {
   userId       
    username
    password_hash
    displayName
    userType
    firstName
    lastName
    birthDate
    nickName
    gender
    personalRelation
    personalID
    taxID
    zipCode
    province
    district
    subDistrict
    addressDetail
    phone
    email
    emergencyContact {
      firstName
      lastName
      relation
      phone
    }
    technicianJobType
    serviceRegion
    serviceProvince
    isSafetyPassport
    safetyPassport {
      safetyPassportStatus
      safetyPassportType
      safetyPassportFileAttachment

    }
    userVerification {
      userId
      status
      companyCertificateFileAttachment
      vatFileAttachment
      selfieJuristicIDFileAttachment
      coordinatorPersonalIDFileAttachment
      selfiePersonalIDFileAttachment
      personalIDFileAttachment
      createdAt
      updatedAt
    }
  }
}
`

export const queryPlanDateReport = `
query PAE_CUSTOM_Plan($projectId: ID!, $acceptedUserId: ID!) {
  project(projectId: $projectId) {

    projectId

    planDateReportByAcceptedUserId(acceptedUserId: $acceptedUserId) {
      
      fieldID

      groupFieldList {

        fieldValue

        workTypeList {
          workType

          workTypeTable {
            week_1
            week_2
            week_3
            week_4
            week_5
            week_6
            week_7
            week_8
            week_9
            week_10
            week_11
            week_12
            week_13
            week_14
            week_15
            week_16
            week_17
            week_18
            week_19
            week_20
            week_21
            week_22
            week_23
            week_24
            week_25
            week_26
            week_27
            week_28
            week_29
            week_30
            week_31
            week_32
            week_33
            week_34
            week_35
            week_36
            week_37
            week_38
            week_39
            week_40
            week_41
            week_42
            week_43
            week_44
            week_45
            week_46
            week_47
            week_48
            week_49
            week_50
            week_51
            week_52
            week_53
          }

        }

      }

    }

  }

}`

export const queryInstalledDateReport = `
query PAE_CUSTOM_Actual($projectId: ID!, $acceptedUserId: ID!) {
  project(projectId: $projectId) {

    projectId

    actualDateReportByAcceptedUserId(acceptedUserId: $acceptedUserId) {
      
      fieldID

      groupFieldList {

        fieldValue

        workTypeList {
          workType

          workTypeTable {
            week_1
            week_2
            week_3
            week_4
            week_5
            week_6
            week_7
            week_8
            week_9
            week_10
            week_11
            week_12
            week_13
            week_14
            week_15
            week_16
            week_17
            week_18
            week_19
            week_20
            week_21
            week_22
            week_23
            week_24
            week_25
            week_26
            week_27
            week_28
            week_29
            week_30
            week_31
            week_32
            week_33
            week_34
            week_35
            week_36
            week_37
            week_38
            week_39
            week_40
            week_41
            week_42
            week_43
            week_44
            week_45
            week_46
            week_47
            week_48
            week_49
            week_50
            week_51
            week_52
            week_53
          }

        }

      }

    }

  }

}`
export const queryListSafetyPassport = `
query ListSafetyPassport {
  listSafetyPassportByUser {
    safetyPassportStatus
    safetyPassportType
    safetyPassportFileAttachment
    createdAt
  }
}
`

export const queryRevision = `
query Project($projectId: ID!) {
  project(projectId: $projectId) {
    projectId
    revisionCountTechnician
    revisionCountWorkType
    revisionHistogram
    siteCountTechnician
  }
}
`
//queryListSafetyPassportByUserInTeam
export const queryListSafetyPassportByUserInTeam = `
query ListSafetyPassportByUserInTeam($userId: ID!) {
  listSafetyPassportByUserInTeam(userId: $userId) {
    safetyPassportType
    safetyPassportStatus
    userId
    safetyPassportFileAttachment
  }
}
`
export const queryListReportStatusPAT = `
query Query($projectId: ID!) {
  reportPATStatus(projectId: $projectId) {
    projectId
    PATStatusType
    fieldID
    fieldValue
  }
}
`

export const queryProjectPC13 = `
query QueryProjectPC13($projectId: ID!) {
  project(projectId: $projectId){
    projectId
    projectName
    revisionCountTechnician
    revisionCountWorkType
    revisionHistogram
    siteCountTechnician
  }
}
`

//queryTechnician
export const queryTechnician = `
query Query {
  listAllUsers {
    userId
    customAttrs
    firstName
    lastName
    username
    email
    technicianJobType

  }
}
`
