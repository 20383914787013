import React from 'react'
import moment from 'moment'
import styles from './index.module.scss'
import checkin from '../../image/checkin.svg'
// import checkinBlue from '../../image/checkinBlue.svg'
import checkinRed from './errorIcon.svg'
import { Modal, Backdrop } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { ICombineReducers } from '../../state/reducers'
import allAction from '../../state/actions/index'
import ButtonComponent from '../Button/index'

const ModalAlertStatus: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const stateRedux = useSelector((state: ICombineReducers) => state.modalReducer)

  return (
    <Modal
      open={stateRedux.openModalAlertStatus}
      onClose={() => dispatch(allAction.modalAction.setCloseModalAlertStatus())}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ zIndex: 2000000 }}
    >
      <div className={styles.modalConfirm}>
        <div className={styles.card}>
          <div className={styles.confirmicon}>
            {stateRedux.status === 'success' && <img src={checkin} alt="" />}
            {/* {stateRedux.status === 'info' && <img src={checkinBlue} alt="" />} */}
            {stateRedux.status === 'error' && <img src={checkinRed} alt="" />}
          </div>
          <p className={styles.textHead}>{stateRedux.textModalAlertStatus}</p>
          <p className={styles.textDes}>{stateRedux.desModalAlertStatus}</p>
          <p className={styles.timetext}>{moment().format('LT')} </p>
          <div className={styles.rowBtn}>
            {stateRedux.status === 'success' && (
              <ButtonComponent
                _type="button"
                _colorBG="green"
                _colorText="white"
                _variant="contained"
                _text="ตกลง"
                _functionOnClick={() => dispatch(allAction.modalAction.setCloseModalAlertStatus())}
              />
            )}
            {stateRedux.status === 'info' && (
              <ButtonComponent
                _type="button"
                _colorBG="blue"
                _colorText="white"
                _variant="contained"
                _text="ตกลง"
                _functionOnClick={() => dispatch(allAction.modalAction.setCloseModalAlertStatus())}
              />
            )}
            {stateRedux.status === 'error' && (
              <ButtonComponent
                _type="button"
                _colorBG="red"
                _colorText="white"
                _variant="contained"
                _text="ตกลง"
                _functionOnClick={() => dispatch(allAction.modalAction.setCloseModalAlertStatus())}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalAlertStatus
