import React, { useState, useEffect } from 'react'

export const Iconbutton: any = ({ buttonClassName, className, type, functionOnClick, src, alt, width }: any) => {
  // console.log(name, 'name')
  return (
    <>
      <button className={`${className}`} onClick={functionOnClick} type={type}>
        <img className={buttonClassName} src={src} alt={alt} width={width} />
      </button>
    </>
  )
}
