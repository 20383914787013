import React, { useState, useEffect } from 'react'
import { Field, ErrorMessage, useFormikContext, getIn } from 'formik'
import { parse } from 'utils/equation'
import { styled } from '@mui/material'
import cn from 'classnames'

const FormikInputEquation: any = ({ className, name, placeholder, readOnly, onBlur, type }: any) => {
  // console.log(readonly, 'name')
  const { handleChange, handleBlur, touched, errors } = useFormikContext<any>()

  const validator = (str: string) => {
    try {
      parse(str)
    } catch (err) {
      return err.message
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Field
        name={name}
        className={cn(className, { errorEquation: getIn(touched, name) && getIn(errors, name) })}
        placeholder={placeholder}
        validate={validator}
        type={type ? type : 'text'}
        readOnly={readOnly}
        // Choose between onChange or mask!! onChange will override Mask!
        onChange={handleChange}
        onBlur={onBlur ? onBlur : handleBlur}
      />
      <ErrorMessage name={name} render={(msg) => <ErrorDiv>{msg}</ErrorDiv>} />
    </div>
  )
}

const ErrorDiv = styled('div')({
  whiteSpace: 'pre-wrap',
  fontFamily: 'monospace',
  color: 'red',
  position: 'absolute',
  top: '50%',
  left: 'calc(100% + 10px)',
  transform: 'translate(0, -50%)',
  width: '20rem',
  backgroundColor: '#FFE0C1',
  borderRadius: '1rem',
  padding: '0.5rem',
  '::after': {
    content: '""',
    position: 'absolute',
    left: '-10px',
    top: '50%',
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '10px solid #FFE0C1',
  },
})

export default FormikInputEquation
