import React from 'react'
import { Field, useFormikContext } from 'formik'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

export const FormikInputSelect: React.FC<IInputSelect> = (inputSelect: IInputSelect): JSX.Element => {
  const { handleChange, handleBlur } = useFormikContext<any>()
  return (
    <Field
      as="select"
      className={inputSelect.className}
      defaultValue={inputSelect.defaultValue ? inputSelect.defaultValue : ''}
      name={inputSelect.name}
      onChange={inputSelect.onChange ? inputSelect.onChange : handleChange}
      onBlur={inputSelect.onBlur ? inputSelect.onBlur : handleBlur}
      disabled={inputSelect.disabled && inputSelect.disabled}
    >
      {inputSelect.option.map((data, index) => (
        <option key={index} className={data.opclass} value={data.value}>
          {data.text}
        </option>
      ))}
    </Field>
  )
}

interface IInputSelect {
  name: string
  defaultValue?: string | number | readonly string[]
  className?: string
  option: IOption[]
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

export interface IOption {
  text: string
  value: string | number | readonly string[]
  alt?: string
  opclass?: string
  src?: string
  img?: string
}
