import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'

import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'

import Bell from './bell.svg'
import Profile from './profile.svg'
import Logo from './ChangTum-Logo.png'
import burger from './burger.svg'
import xpic from './X.svg'
import addfriendpic from '../footercomponent/addfriendbtn.svg'

import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { CUSTOMER_PREFIX, TECHNICIAN_PREFIX } from 'App'
// import { useFormikContext } from 'formik'
import NotificationComponent from './notificationComponent'
import { getUserProfile } from 'adapter/xhr'
import VerifyLogo from './Verify.svg'
import UnVerifyLogo from './UnVerify.svg'
import WaitVerifyLogo from './waitVerify.svg'

const NavTopBar: React.FC = () => {
  // const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const { customerOrTechnician } = useParams()
  const [isShowDropdown, setIsShowDropDown] = useState(false)
  const [statusVerify, setStatusVerify] = useState('')

  const [isShowNotiDropdown, setIsShowNotiDropdown] = useState(false)
  const [isMobileNav, setIsMobileNav] = useState(false)
  const [isReadFalseNum, setIsReadFalseNum] = useState<number>(0)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const userinfo = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken.username)

  const isLoggedIn = useSelector((state: ICombineReducers) => state.tokenReducer.isLoggedIn)
  const queryTeam = () => {
    getUserProfile().then((profileres: any) => {
      setStatusVerify(profileres.data.data.getUser?.userVerification?.status)
    })
  }

  useEffect(() => {
    if (isLoggedIn) {
      queryTeam()
    }
  }, [isLoggedIn])

  const StatusVerifyE: Record<string, string> = {
    NON_VERIFIED: 'คลิกที่นี่เพื่อยืนยันตัวตน *',
    WAIT_VERIFIED: 'รอการอนุมัติการยืนยันตัวตน *',
    REJECTED: 'การยืนยันตัวตนไม่สมบูรณ์ *',
    VERIFIED: 'ยืนยันตัวตนแล้ว *',
  }

  const MAP2OPPOSITE: Record<string, string> = {
    [CUSTOMER_PREFIX]: 'ผู้รับเหมา',
    [TECHNICIAN_PREFIX]: 'ผู้จ้าง',
  }

  const mobileNavMenu = [
    {
      text: `เป็น${MAP2OPPOSITE[customerOrTechnician!]}`,
      function: () => {
        const oppositeString = customerOrTechnician === CUSTOMER_PREFIX ? TECHNICIAN_PREFIX : CUSTOMER_PREFIX
        navigate(pathname.replace(customerOrTechnician!, oppositeString))
      },
    },
    {
      text: `หน้าแรก`,
      function: () => {
        navigate(`/${customerOrTechnician}`)
        setIsMobileNav(false)
      },
    },
    {
      text: `เกี่ยวกับเรา`,
      function: () => {
        navigate(`/${customerOrTechnician}`)
        window.scrollTo({ top: 290 })
        setIsMobileNav(false)
      },
    },
    {
      text: `ติดต่อเรา`,
      function: () => {
        navigate(`/${customerOrTechnician}`)
        if (customerOrTechnician === 'customer') {
          window.scrollTo({ top: 3420 })
          setIsMobileNav(false)
        } else {
          window.scrollTo({ top: 3400 })
          setIsMobileNav(false)
        }
      },
    },
    {
      text: `เข้าสู่ระบบ`,
      function: () => {
        navigate(`/${customerOrTechnician}/login`)
        setIsMobileNav(false)
      },
    },
  ]

  return (
    <nav className={styles.navTopBar}>
      <button className={styles.logoButton} onClick={(e) => navigate(`/${customerOrTechnician}`)}>
        <img className={styles.logoImage} src={Logo} />
        <h1>Changtum</h1>
      </button>
      {isLoggedIn === true ? (
        <div className={styles.menues}>
          {/* Loggin True -> Show Profile and Logout button */}
          <div
            className={styles.menu}
            tabIndex={-1}
            onClick={() => {
              setIsShowDropDown(!isShowDropdown)
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsShowDropDown(false)
              }, 300)
            }}
          >
            <div className={styles.VerifyContainer}>
              {/* Status Verified */}
              <p
                className={
                  `${statusVerify}` === 'NON_VERIFIED'
                    ? styles.nonVerify
                    : `${statusVerify}` === 'WAIT_VERIFIED'
                    ? styles.waitVerify
                    : `${statusVerify}` === 'VERIFIED'
                    ? styles.Verify
                    : ''
                }
              >
                {StatusVerifyE[`${statusVerify}`]}
              </p>

              <img
                src={
                  `${statusVerify}` === 'NON_VERIFIED'
                    ? `${UnVerifyLogo}`
                    : `${statusVerify}` === 'WAIT_VERIFIED'
                    ? `${WaitVerifyLogo}`
                    : `${statusVerify}` === 'VERIFIED'
                    ? `${VerifyLogo}`
                    : ''
                }
                alt=""
              />
            </div>

            {/* Profile Dropdown */}
            <span className={styles.dropDownContainer}>
              <img src={Profile} className={styles.icon} style={{ cursor: 'pointer' }} />
              <div className={styles.dropDownContent} style={isShowDropdown === false ? { display: 'none' } : {}}>
                <button
                  className={styles.dropDownItems}
                  type="button"
                  onClick={() => navigate(`/${customerOrTechnician}/profilepage`)}
                >
                  Profile
                </button>
                <button
                  className={styles.dropDownItems}
                  type="button"
                  onClick={() => {
                    navigate(`/${customerOrTechnician}/projectmanagementpage`)
                  }}
                >
                  บริหารโครงการ
                </button>
                <button
                  className={styles.logOutBtn}
                  type="button"
                  onClick={() => {
                    localStorage.clear()
                    navigate(`/${customerOrTechnician}/login`)
                  }}
                >
                  Logout
                </button>
              </div>
            </span>
          </div>
          <span className="hiddenInMobile" style={{ cursor: 'pointer' }}>
            <span>{userinfo}</span>
          </span>
          <div
            className={styles.menu}
            tabIndex={-1}
            onBlur={() => {
              setTimeout(() => {
                setIsShowNotiDropdown(false)
              }, 300)
            }}
            style={{ justifyContent: 'flex-end' }}
          >
            <img
              tabIndex={-1}
              src={Bell}
              className={styles.icon}
              onClick={() => {
                setIsShowNotiDropdown(!isShowNotiDropdown)
              }}
              style={{ cursor: 'pointer' }}
            />
            {isReadFalseNum > 0 && (
              <p
                onClick={() => {
                  setIsShowNotiDropdown(!isShowNotiDropdown)
                }}
                className={styles.notifyNumber}
              >
                {isReadFalseNum}
              </p>
            )}

            <div className={styles.dropDownNotification} style={isShowNotiDropdown === true ? {} : { display: 'none' }}>
              <NotificationComponent setIsReadFalseNum={setIsReadFalseNum} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={`${styles.menues} ${styles.notMobile}`}>
            {/* Loggin False -> Show เป็นผู้จ้าง/เป็นผู้รับเหมา , หน้าแรก , Feature, ติดต่อเรา เข้าสู่ระบบ */}
            <button
              className={styles.changeStatusBtn}
              type="button"
              onClick={(e) => {
                const oppositeString = customerOrTechnician === CUSTOMER_PREFIX ? TECHNICIAN_PREFIX : CUSTOMER_PREFIX
                navigate(pathname.replace(customerOrTechnician!, oppositeString))
              }}
            >
              เป็น{MAP2OPPOSITE[customerOrTechnician!]}
            </button>
            <button className={styles.isNotLogInBtn} type="button" onClick={() => navigate(`/${customerOrTechnician}`)}>
              หน้าแรก
            </button>

            {/* If it is customer -> เกี่ยวกับเรา else Feature */}
            {customerOrTechnician === 'customer' ? (
              <button
                className={styles.isNotLogInBtn}
                type="button"
                onClick={() => {
                  navigate(`/${customerOrTechnician}`)
                  window.scrollTo({ top: 600 })
                }}
              >
                เกี่ยวกับเรา
              </button>
            ) : (
              <button
                className={styles.isNotLogInBtn}
                type="button"
                onClick={() => {
                  navigate(`/${customerOrTechnician}`)
                  window.scrollTo({ top: 600 })
                }}
              >
                Feature
              </button>
            )}

            <button
              className={styles.isNotLogInBtn}
              type="button"
              onClick={() => {
                navigate(`/${customerOrTechnician}`)
                if (customerOrTechnician === 'customer') {
                  window.scrollTo({ top: 2380 })
                } else {
                  window.scrollTo({ top: 2730 })
                }
              }}
            >
              ติดต่อเรา
            </button>
            <button
              className={styles.isNotLogInBtn}
              type="button"
              onClick={() => navigate(`/${customerOrTechnician}/login`)}
            >
              เข้าสู่ระบบ
            </button>
          </div>

          <img
            className={styles.burgerimg}
            onClick={() => {
              setIsMobileNav(true)
            }}
            src={burger}
          />
          <section className={styles.mobileNAv} style={isMobileNav === false ? { display: 'none' } : {}}>
            <article className={styles.btnLine}>
              <img
                src={xpic}
                onClick={() => {
                  setIsMobileNav(false)
                }}
                style={{ width: '20px', cursor: 'pointer', float: 'right' }}
                alt=""
              />
            </article>
            {mobileNavMenu.map((mobileNavEle: any, mobileNavIndex: number) => {
              return (
                <React.Fragment key={mobileNavIndex}>
                  <section className={styles.mobileNavItems} key={mobileNavIndex} onClick={mobileNavEle.function}>
                    {customerOrTechnician === 'technician'
                      ? mobileNavEle.text === 'เกี่ยวกับเรา'
                        ? 'Feature'
                        : mobileNavEle.text
                      : mobileNavEle.text}
                    <hr />
                  </section>
                </React.Fragment>
              )
            })}
            <section>
              <img
                src={addfriendpic}
                style={{ width: '150px', cursor: 'pointer', float: 'right', margin: '150px 0 0 0' }}
                onClick={() => {
                  window.location.href = 'https://lin.ee/GWOQvOo'
                }}
                alt="addfriendpic"
              />
            </section>
          </section>
        </>
      )}
    </nav>
  )
}

export default NavTopBar
