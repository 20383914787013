import React from 'react'
import styles from './index.module.scss'
import changtumpic from './changtumlogo.svg'
import { useNavigate, useParams } from 'react-router-dom'

const Leftsidecontent: React.FC = () => {
  const { customerOrTechnician } = useParams<any>()
  const navigate = useNavigate()
  return (
    <section className={styles.leftSideContent}>
      <article className={styles.leftSideTopicContainer} onClick={() => navigate(`/${customerOrTechnician}`)}>
        <h1 className={styles.leftSideTopic}>Changtum</h1>
      </article>
      <img
        className={styles.changtumlogo}
        src={changtumpic}
        alt="changtumlogo"
        onClick={() => navigate(`/${customerOrTechnician}`)}
      />
      <p className={styles.changtumdescription}>ช่างทำ Community ช่าง และผู้รับเหมา ครบจบในที่เดียว </p>
      <p className={styles.changtumdescription2}>เข้าสู่ระบบ</p>
    </section>
  )
}

export default Leftsidecontent
