import {
  post,
  IProject,
  IWorkType,
  IJob,
  getProjectByProjectId,
  putProject,
  // unpackProject,
  IPutJobs,
  putJobs,
  IPutProject,
  getProjectIdByUserId,
  getProjectIdByAcceptedUserId,
  unpackProjectManagement,
} from 'adapter/xhr'

// Actually this doesn't really help in dynamodb.
// Because it will query everything until completion anyways.
// We need to make jobList nested QUery in GraphQL for this to show Benefit
export async function getProjectIdsByUserId(userId: string) {
  console.time('getProjectIdsByUserId>projectIdList')
  const projectIdList = (await getProjectIdByUserId(userId)).data.data.projectByUserId
  console.timeEnd('getProjectIdsByUserId>projectIdList')
  return projectIdList
}

// Actually this doesn't really help in dynamodb.
// Because it will query everything until completion anyways.
// We need to make jobList nested QUery in GraphQL for this to show Benefit
export async function getProjectIdsByAcceptedUserId(acceptedUserId: string) {
  console.time('getProjectIdsByAcceptedUserId>projectIdList')
  const projectIdList = (await getProjectIdByAcceptedUserId(acceptedUserId)).data.data.projectByAcceptedUserId
  console.timeEnd('getProjectIdsByAcceptedUserId>projectIdList')
  return projectIdList
}

// Optimization without isPatGenerated from 13 seconds to 6 seconds!
export async function getUnpackedProjectByProjectId(projectId: string, jobFilter = {}): Promise<IProject> {
  const project: IProject = (
    await getProjectByProjectId(projectId, jobFilter, queryProjectByProjectIdWithoutIsPatGenerated)
  ).data.data.project
  return unpackProjectManagement(project)
}

export async function packAndPutJobs(unpackedProject: IProject): Promise<IJob[]> {
  const putJobsPacked = packProjectToPutJobs(unpackedProject)

  console.log('packAndPutJobs>>', putJobsPacked)

  // Actually we can return value. but im lazy
  return putJobs(putJobsPacked)
    .then((res) => {
      console.log('THIS IS RES ', res)
      return res.data.data.putJobs
    })
    .catch((err) => {
      console.error('I HAVE ERROR err', err)
      console.error('I HAVE ERROR err.response', err.response)
      throw new Error(err.response)
    })
}

function packProjectToPutJobs(unpackedProject: IProject): IPutJobs {
  return {
    projectId: unpackedProject.projectId,
    editFieldID: unpackedProject.configReportFieldID,
    jobList: unpackedProject.workTypeList.flatMap((workTypeElement: IWorkType, workTypeIndex: number) => {
      return workTypeElement.jobList
        ? workTypeElement.jobList.map((jobElement) => {
            // console.log(jobElement, 'packjob jobElement')
            // Remove isPatGenerated, patStatus
            // delete jobElement.issueCount
            // delete jobElement.revisionNo
            // delete jobElement.versionNo
            // delete jobElement.userId
            // delete jobElement.updatedAt
            // delete jobElement.createdAt
            // delete jobElement.name
            // delete jobElement.projectId

            const {
              isPatGenerated,
              patStatus,
              patUpdatedAt,
              issueCount,
              issueList,
              revisionNo,
              versionNo,
              userId,
              updatedAt,
              createdAt,
              name,
              projectId,
              customAttributeEditFieldID,
              ...otherJobElement
            } = jobElement
            return {
              ...otherJobElement,
              fieldValue: customAttributeEditFieldID,
            }
          })
        : []
    }),
  }
}

export async function packAndPutProjectArchiveStatus(unpackedProject: IProject): Promise<IProject> {
  const putProjectPacked = packProjectToPutProjectArchiveStatus(unpackedProject)

  // Actually we can return value. but im lazy
  return putProject(putProjectPacked)
    .then((res) => {
      console.log('THIS IS RES ', res)
      return res.data.data.putProject
    })
    .catch((err) => {
      console.error('I HAVE ERROR err', err)
      console.error('I HAVE ERROR err.response', err.response)
      throw new Error(err.response)
    })
}

function packProjectToPutProjectArchiveStatus(unpackedProject: IProject): IPutProject {
  return {
    projectId: unpackedProject.projectId,
    projectName: unpackedProject.projectName, //IDK WHY THIS IS REQUIRED TOO
    // workTypeList: unpackedProject.workTypeList,
    status: 'ARCHIVED',
  }
}

export function destructureJobList(unpackedProjectResponse: IProject): IJob[] {
  const clonedProjectResponse: IProject = JSON.parse(JSON.stringify(unpackedProjectResponse))

  const jobList = clonedProjectResponse.workTypeList
    ? clonedProjectResponse.workTypeList.flatMap((workTypeElement) => {
        if (workTypeElement.jobList) return [...workTypeElement.jobList]
        else return []
      })
    : []
  return jobList
}

const queryProjectByProjectIdWithoutIsPatGenerated = `
query($projectId: ID!, $jobFilter: JSON) {
  project(projectId: $projectId, jobFilter: $jobFilter){
    projectId
    projectName
    projectDescription
    projectManagerName
    createdByUserId
    startDate
    endDate
    createdAt
    updatedAt
    status
    TORFile
    configReportFieldID
    acceptedUserIdList {
      userId
      getUser {
        username
      }
    }
    customFieldList {
      name
      allowedValueList
    }
    workTypeList {
      workType
      lineNumber
      patTemplateId
      isNoPrice
      jobUnitPrice
    }
    jobList{
      changTumId
      jobId
      workType
      region
      province
      district
      siteCode
      siteName
      zone
      number
      lat
      long
      status
      applicationID
      acceptedUserId
      assignedUserId
      assignedUserPhone
      planDate
      actualDate
      description
      remark
      addition1
      addition2
      patStatus
      patUpdatedAt
      issueCount
      getPAT {
        revisionNo
        versionNo
        userId
        updatedAt
        createdAt
        name
      }
      issueList {
        createdByUserId
        topic
        description
        getUser {
          username
        }
      }
      customAttributeEditFieldID
    }
    acceptedUserIdList {
      userId
      getUser {
        username
      }
    }
  }
}`
