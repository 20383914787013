import React, { useState, useEffect } from 'react'
import styles from './CsvReader.module.scss'
import { useFormikContext } from 'formik'
import { IFormikValues } from '../index'
import { removeEmpty } from 'components/ChungTumTable'
import { swap } from 'components/commons/shared'

// Way to Style input type File
// https://stackoverflow.com/questions/572768/styling-an-input-type-file-button
// https://stackoverflow.com/questions/572768/styling-an-input-type-file-button/25825731#25825731

// How to upload Files in react without libraries
// https://dev.to/theallegrarr/read-csv-files-in-react-apps-without-installing-any-package-hn7
const CsvReader: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IFormikValues>()

  let TECHNICIAN2USERID_MAPPER: any = {}
  values?.acceptedUserIdList?.map((acceptedUserIdElement: any) => {
    TECHNICIAN2USERID_MAPPER = {
      [`${acceptedUserIdElement.getUser.username}`]: acceptedUserIdElement.getUser.userId,
      ...TECHNICIAN2USERID_MAPPER,
    }
  })

  const handleCsvFile = (csvFile: any) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      if (e.target) {
        const text = e.target.result
        // console.log(text)
        if (text && typeof text === 'string') {
          const data = processCsv(text)
          // console.log('Data', data)

          // Set the damn data for each worktype
          // console.log(TECHNICIAN2USERID_MAPPER, 'TECHNICIAN2USERID_MAPPER')
          values.workTypeList.map((workType, workTypeIndex) => {
            const newJobList = data
              .filter((dataElement) => dataElement[DB2EXCEL_HEADER_MAPPER['workType']] === workType.workType)
              .map((dataElement) => {
                const newData: any = {}
                // console.log(dataElement, 'dataElement')
                Object.keys(EXCEL2DB_HEADER_MAPPER).map((key: string) => {
                  // Special Case when it is acceptedUserId
                  if (EXCEL2DB_HEADER_MAPPER[key] === 'acceptedUserId') {
                    // console.log(TECHNICIAN2USERID_MAPPER, dataElement[key], 'dataElement[key]')
                    if (Object.keys(TECHNICIAN2USERID_MAPPER).includes(dataElement[key]))
                      newData[EXCEL2DB_HEADER_MAPPER[key]] = TECHNICIAN2USERID_MAPPER[dataElement[key]]
                    else newData[EXCEL2DB_HEADER_MAPPER[key]] = ''
                  } else {
                    // console.log(key, '===>', dataElement[key], 'jobli')
                    newData[EXCEL2DB_HEADER_MAPPER[key]] = dataElement[key]
                  }
                })
                console.log(newData, 'newData')
                return newData
              })

            // Need to see if there is any job conflicts
            const jobList = workType.jobList ? removeEmpty(workType.jobList) : []
            newJobList.map((newJob) => {
              const indexToReplace = jobList.findIndex((job) => job.changTumId === newJob.changTumId)
              if (indexToReplace !== -1) {
                jobList.splice(indexToReplace, 1, newJob)
              } else {
                jobList.push(newJob)
              }
            })
            // console.log(
            //   `IN ${workType.workType} dataMap`,
            //   data.map((dataElement) => {
            //     const newData: any = {}
            //     Object.keys(EXCEL2DB_HEADER_MAPPER).map((key: string) => {
            //       newData[EXCEL2DB_HEADER_MAPPER[key]] = dataElement[key]
            //     })
            //     return newData
            //   }),
            // )
            // console.log(`IN ${workType.workType} newJobList`, newJobList)
            // console.log(`IN ${workType.workType} adding`, jobList)

            console.log('JOBLIST', jobList)

            setFieldValue(`workTypeList[${workTypeIndex}].jobList`, jobList)
          })
        }
      }
    }
    reader.readAsText(csvFile)
  }

  const processCsv = (str: string) => {
    const lines = str.split('\n')
    const headers = CSVtoArray(lines[0])
    // Remove the first header
    lines.splice(0, 1)
    const data = lines.map((row) => {
      const values = CSVtoArray(row)
      const eachObject = headers.reduce((obj: any, header, i) => {
        obj[header.trim()] = values[i] ? values[i].trim() : ''
        return obj
      }, {})
      return eachObject
    })

    return data
  }

  return (
    <label className={styles.customCsvUploadButton}>
      <input
        type="file"
        accept=".csv"
        id="csvFile"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            console.log(e.target.files[0])
            handleCsvFile(e.target.files[0])
            // Reset the target file so that reupload the same file is possible
            e.target.value = ''
          }
        }}
      />
      Upload CSV
    </label>
  )
}

export default CsvReader

// Processing CSV with split() is not enough, need quotes!!
// https://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data
// Return array of string values, or NULL if CSV string not well formed.
function CSVtoArray(text: any): string[] {
  const ret = ['']
  let i = 0
  let p = ''
  let s = true
  for (let l in text) {
    l = text[l]
    if ('"' === l) {
      s = !s
      if ('"' === p) {
        ret[i] += '"'
        l = '-'
      } else if ('' === p) l = '-'
    } else if (s && ',' === l) l = ret[++i] = ''
    else ret[i] += l
    p = l
  }
  return ret
}

const EXCEL2DB_HEADER_MAPPER: any = {
  ChangTumId: 'changTumId',
  รหัสงาน: 'jobId',
  ประเภทงาน: 'workType',
  ภูมิภาค: 'region',
  จังหวัด: 'province',
  อำเภอ: 'district',
  Latitude: 'lat',
  Long: 'long',
  รหัสไซต์: 'siteCode',
  ชื่อไซต์: 'siteName',
  โซน: 'zone',
  เลขที่: 'number',
  รายละเอียด: 'description',
  หมายเหตุ: 'remark',
  Subcontract: 'acceptedUserId',
  ชื่อช่าง: 'assignedUserId',
  'Plan date': 'planDate',
  'Custom Attribute': 'fieldValue',
  // Added in Version 2
  TowerType: 'towerType',
  TowerSubType: 'towerSubType',
  'ความสูง (m)': 'height',
  'ขนาด Site (m)': 'siteArea',
  'ขนาด (กว้างxยาว)': 'dimension',
  ความกว้างประตูรั้ว: 'gateWidth',
  'location ID': 'locationID',
  บริษัทผู้ผลิตเสา: 'manufacturer',
  'Manufacture date': 'manufacturerDate',
  บริษัทผู้ติดตั้งเสา: 'installer',
}

const DB2EXCEL_HEADER_MAPPER: any = swap(EXCEL2DB_HEADER_MAPPER)

// const TECHNICIAN2USERID_MAPPER: any = {
//   Sacho: 'krw82bmfXvL6N',
//   NTC: 'krw83dvskqHAw',
//   Naja: 'krw83npiymUFc',
//   Promporn: 'krw83wekeP33r',
//   GH884: 'krw844nsTGtQJ',
//   HiSpeed: 'kwqe8lhvJASLs',
//   changTraining: 'kspru0lbviSrB',
// }
