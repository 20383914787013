import React, { useState } from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik } from 'formik'
import { THAILAND_REGION_MAPPER, THAILAND_PROVINCE_MAPPER } from '../../components/commons/thailandFact'
import VerifyFrist from './verifyFrist'
import VerifySecond from './verifySecond'
import VerifyThird from './verifyThird'
import { GetSafetyPassport, getUserProfile } from 'adapter/xhr'

const VerifyPage: React.FC = (): JSX.Element => {
  const [verifystep, setVerifystep] = useState('verifyfrist')
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  React.useEffect(() => {
    getUserProfile().then((profileres: any) => {
      console.log(' Profile ====>', profileres)
      setFieldValue('profile', profileres.data.data.getUser)
      const responseProfile = profileres.data.data.getUser
      const UpdateProfile: any = {
        companyName: responseProfile.companyName,
        companyBranch: responseProfile.companyBranch,
        taxID: responseProfile.taxID,
        zipCode: responseProfile.zipCode,
        province: responseProfile.province,
        addressDetail: responseProfile.addressDetail,
        phone: responseProfile.phone,
        email: responseProfile.email,
        coordinatorName: responseProfile.coordinatorName,
        coordinatorPersonalID: responseProfile.coordinatorPersonalID,
        coordinatorAddress: responseProfile.coordinatorAddress,
        coordinatorPhone: responseProfile.coordinatorPhone,
        firstName: responseProfile.firstName,
        lastName: responseProfile.lastName,
        nickName: responseProfile.nickName,
        birthDate: responseProfile.birthDate,
        personalID: responseProfile.personalID,
        emergencyContact: {
          firstName: responseProfile.emergencyContact?.firstName,
          lastName: responseProfile.emergencyContact?.lastName,
          relation: responseProfile.emergencyContact?.relation,
          phone: responseProfile.emergencyContact?.phone,
        },
        personalRelation: responseProfile.personalRelation,
        gender: responseProfile.gender,
        technicianJobType: responseProfile.technicianJobType,
        serviceRegion: responseProfile.serviceRegion,
        serviceProvince: responseProfile.serviceProvince,
        isSafetyPassport: responseProfile.isSafetyPassport,
        userType: responseProfile.userType,
      }

      setFieldValue('profileUp', UpdateProfile)

      //VerifySetFieldValue
      setFieldValue('selfiePersonalIDFileAttachment', responseProfile.userVerification?.selfiePersonalIDFileAttachment)
      setFieldValue('personalIDFileAttachment', responseProfile.userVerification?.personalIDFileAttachment)
      setFieldValue(
        'companyCertificateFileAttachment',
        responseProfile.userVerification?.companyCertificateFileAttachment,
      )
      setFieldValue('vatFileAttachment', responseProfile.userVerification?.vatFileAttachment)
      setFieldValue(
        'coordinatorPersonalIDFileAttachment',
        responseProfile.userVerification?.coordinatorPersonalIDFileAttachment,
      )
    })
    GetSafetyPassport().then((safetyres: any) => {
      // console.log(' SafteyPass ====>', safetyres)
      // setFieldValue('safety', safetyres.data.data.listSafetyPassportByUser)
      // const responSafety = safetyres.data.data.listSafetyPassportByUser
      // // setFieldValue('safetyPassportType', responSafety.safetyPassportStatus)
      // const UpdateSafety: any = {}

      // setFieldValue(
      //   'safetyPassportType',
      //   safetyres.data.data.listSafetyPassportByUser.map(
      //     (safetyPassportElement: any) => safetyPassportElement.safetyPassportType,
      //   ),
      // )

      const unpackingListSafetyPassport: any = {}
      safetyres.data.data.listSafetyPassportByUser.map((safetyPassportElement: any) => {
        if (safetyPassportElement.safetyPassportFileAttachment) {
          unpackingListSafetyPassport[`${safetyPassportElement.safetyPassportType}`] = {
            safetyPassportFileAttachment: safetyPassportElement.safetyPassportFileAttachment,
            safetyPassportStatus: safetyPassportElement.safetyPassportStatus,
          }
        } else {
          unpackingListSafetyPassport[`${safetyPassportElement.safetyPassportType}`] = {
            safetyPassportFileAttachment: '',
            safetyPassportStatus: safetyPassportElement.safetyPassportStatus,
          }
        }
      })
      setFieldValue(`safetyPassport`, unpackingListSafetyPassport)
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        {verifystep === 'verifyfrist' ? (
          <VerifyFrist setVerifystep={setVerifystep} />
        ) : verifystep === 'verifysecond' ? (
          <VerifySecond setVerifystep={setVerifystep} />
        ) : verifystep === 'verifyThird' ? (
          <VerifyThird setVerifystep={setVerifystep} />
        ) : (
          ''
        )}
      </div>
    </>
  )
}

const EnhancedVerifyPage = withFormik({
  mapPropsToValues: () => ({
    userName: '',
    userType: 'JURISTIC',
    //* verify 1 legalEntity
    companyName: '',
    companyBranch: '',
    taxID: '',
    zipCode: '',
    province: '',
    district: '',
    subDistrict: '',
    addressDetail: '',
    phone: '',
    email: '',
    technicianJobType: [],
    technicianWorkType: [
      {
        value: 'Electrician',
        text: 'ช่างไฟฟ้า',
      },
      {
        value: 'Painter',
        text: 'ช่างสี',
      },
      {
        value: 'Plumber',
        text: 'ช่างประปา',
      },
      {
        value: 'Plasterer',
        text: 'ช่างปูน',
      },
      {
        value: 'Metalworker',
        text: 'ช่างเหล็ก',
      },
      {
        value: 'Carpenter',
        text: 'ช่างไม้',
      },
      {
        value: 'Mechanic',
        text: 'ช่างยนต์',
      },
      {
        value: 'Installer',
        text: 'ช่างติดตั้ง',
      },
    ],

    serviceRegion: [],
    optionRegion: [
      ...Object.entries(THAILAND_REGION_MAPPER).map((region: any) => ({
        value: region[0],
        text: region[1],
      })),
    ],
    serviceProvince: [],
    optionProvince: [
      ...Object.entries(THAILAND_PROVINCE_MAPPER).map((region: any) => ({
        value: region[1],
        text: region[0],
      })),
    ],

    //* verify 1 person
    birthDate: '',
    nickName: '',
    gender: 'MALE',
    personalRelation: 'SINGLE',
    firstName: '',
    lastName: '',
    personalID: '',
    emergencyContact: {
      firstName: '',
      lastName: '',
      relation: '',
      phone: '',
    },

    //verify2
    coordinatorName: '',
    coordinatorPersonalID: '',
    coordinatorAddress: '',
    coordinatorPhone: '',
    isSafetyPassport: '',
    safetyPassportStatus: '',
    // safetyPassportType: '',
    safetyPassportFileAttachment: '',
    // safetyPassport: {
    //   safetyPassportStatus: '',
    //   safetyPassportType: '',
    //   safetyPassportFileAttachment: '',
    // },
    safetyPassportOption: [
      {
        value: 'AIS_G',
        text: 'AIS [G]',
      },
      {
        value: 'AIS_E',
        text: 'AIS [E]',
      },
      {
        value: 'AIS_EE',
        text: 'AIS [EE]',
      },
      {
        value: 'AIS_H',
        text: 'AIS [H]',
      },
      {
        value: 'AIS_C',
        text: 'AIS [C]',
      },
    ],
    //Verify3
    userVerification: {
      companyCertificateFileAttachment: '',
      vatFileAttachment: '',
      personalIDFileAttachment: '',
      coordinatorPersonalIDFileAttachment: '',
      selfiePersonalIDFileAttachment: '',
    },
  }),
  validate: (values) => {
    console.log('I AM VALIDATING', values)
    const errors: any = {}

    return errors
  },
  handleSubmit: (values, { setFieldValue }) => {
    //
    console.log(values)
    console.log(JSON.stringify(values))
  },
})(VerifyPage)

export default EnhancedVerifyPage
