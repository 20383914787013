export const mutationCreateJobApplication = `
mutation CreateJobApplication($input: CreateJobApplicationInput!) {
  createJobApplication(input: $input) {
    postID
    createdByUserID
    applicationID
    workTypeJobApplicationList {
      workType
      fieldID
      jobList
      status
      totalJobList {
        fieldValue
        registerQty
        approveQty
      }
    }
    technicianList {
      userId
    }
    createdAt
    updatedAt
  }
}`

export const mutationCreatePost = `
mutation CreatePost($input: CreatePostInput!) {
  createPost(input: $input) {
    postID
  }
}`

export const mutationPutProjectInput = `
mutation($putProjectInput: PutProjectInput!) {
  putProject(input: $putProjectInput) {
    projectId
    projectName
    projectDescription
    projectManagerName
    createdByUserId
    startDate
    endDate
    createdAt
    updatedAt
    status
    TORFile
    workTypeList {
      workType
      lineNumber
      patTemplateId
      isNoPrice
      jobUnitPrice
    }
    jobList{
      jobId
      workType
      region
      province
      district
      siteCode
      siteName
      number
      googleMap
      lat
      long
      remark
      status
      applicationID
      acceptedUserId
      assignedUserId
      planDate
      actualDate
    }
  }
}`

export const mutationCreateProjectInput = `
mutation Mutation($input: CreateProjectInput!) {
  createProject(input: $input) {
    projectId
    projectName
    projectDescription
    createdByUserId
    startDate
    endDate
    createdAt
    updatedAt
    status
    TORFile
    workTypeList {
      workType
      lineNumber
      patTemplateId
      isNoPrice
      jobUnitPrice
      isSafetyPassport
      safetyPassport
      requirements
      imageURLList
      totalJobQty
    }
    projectManagerName
    projectManagerPosition
    isPassport
    customFieldList {
      name
      allowedValueList
    }
  }
}
`

export const mutationPutJobsInput = `
mutation($putJobsInput: PutJobsInput!) {
  putJobs(input: $putJobsInput) {
    changTumId
    jobId
    workType
    province
    region
    district
    siteCode
    siteName
    zone
    number
    lat
    long
    status
    applicationID
    acceptedUserId
    assignedUserId
    assignedUserPhone
    planDate
    actualDate
    description
    remark
    addition1
    addition2
    customAttributeEditFieldID
  }
}`

export const mutationDeleteJobInput = `
mutation ($projectId: ID!, $changTumId: ID!){
  deleteJob(projectId: $projectId, changTumId: $changTumId){
    changTumId
    jobId
    workType
    region
    province
    district
    siteCode
    siteName
    number
    googleMap
    lat
    long
    remark
    status
    applicationID
    acceptedUserId
    assignedUserId
    planDate
    actualDate
  }
}`

export const mutationPutPATInput = `
mutation ($putPATInput: PutPATInput!){
  putPAT(input:$putPATInput){
    patId
    name
    description
    status
    lineList {
      lineNo
      lineType
      value{
        ... on GroupLineValue {
          required
          lineList{
            lineNo
            lineType
            value{
              ... on StringLineValue{
                text
              }
              ... on UploadLineValue {
                text
                uploadType
                isNeedAfter
                isNeedBefore
                maximumFileSize
                maximumFileNumber
                uploadBeforeValue
                uploadAfterValue
              }
              ... on ApproveLineValue {
                isApproved
                approvalRemark
              }
            }
          }
        }
      }
    }
  }
}`

export const mutationRedoPATInput = `
mutation ($redoPATInput: RedoPATInput!){
  redoPAT(input:$redoPATInput){
    patId
    name
    description
    status
    lineList {
      lineNo
      lineType
      value{
        ... on GroupLineValue {
          required
          lineList{
            lineNo
            lineType
            value{
              ... on StringLineValue{
                text
              }
              ... on UploadLineValue {
                text
                uploadType
                isNeedAfter
                isNeedBefore
                maximumFileSize
                maximumFileNumber
                uploadBeforeValue
                uploadAfterValue
              }
              ... on ApproveLineValue {
                isApproved
                approvalRemark
              }
            }
          }
        }
      }
    }
  }
}`

export const mutationCreatePATInput = `
mutation($createPATInput: CreatePATInput!) {
  createPAT(input:$createPATInput, isTemplate: true){
    patId
    name
    description
    versionNo
    createdAt
    updatedAt
    status
    lineList {
      lineNo
      lineType
      value{
        ... on GroupLineValue {
          required
          lineList{
            lineNo
            lineType
            value{
              ... on StringLineValue{
                text
              }
              ... on UploadLineValue {
                text
                uploadType
                isNeedAfter
                isNeedBefore
                maximumFileSize
                maximumFileNumber
                uploadBeforeValue
                uploadAfterValue
              }
              ... on ApproveLineValue {
                isApproved
                approvalRemark
              }
            }
          }
        }
      }
    }
  }
}`

export const mutationCreateNotification = `
mutation Mutation($createNotificationInput: CreateNotificationInput!) {
  createNotification(input: $createNotificationInput) {
    userId
    message
    iconUrl
    isRead
  }
}`

export const mutationPutNotification = `
mutation Mutation($putNotificationInput: UpdateNotificationInput!) {
  putNotification(input: $putNotificationInput) {
    userId
    notificationId
    message
    iconUrl
    isRead
    toLink
  }
}
`

//Update Juristic
export const mutationUpdateJuristic = `
mutation icuser_Update_Jurict($input: AdminUserUpdateInput!) {
  adminUserUpdate(input: $input) {
    userId
    username
    password_hash
    userType
    companyName
    companyBranch
    taxID
    zipCode
    province
    district
    subDistrict
    addressDetail
    phone
    email
    coordinatorName
    coordinatorPersonalID
    coordinatorAddress
    coordinatorPhone
  }
}
`

export const mutationPutMasterPlan = `
mutation putMasterPlan($input: PutMasterPlan!) {
  putMasterPlan(input: $input) {
    projectId
    planningYear
    fieldID
    groupFieldList {
      fieldValue
      workTypeList {
        workType
        workTypeTable {
            week_1 {
              acceptedUserId
              quantity
            }
            week_2 {
              acceptedUserId
              quantity
            }
            week_3 {
              acceptedUserId
              quantity
            }
            week_4 {
              acceptedUserId
              quantity
            }
            week_5 {
              acceptedUserId
              quantity
            }
            week_6 {
              acceptedUserId
              quantity
            }
            week_7 {
              acceptedUserId
              quantity
            }
            week_8 {
              acceptedUserId
              quantity
            }
            week_9 {
              acceptedUserId
              quantity
            }
            week_10 {
              acceptedUserId
              quantity
            }
            week_11 {
              acceptedUserId
              quantity
            }
            week_12 {
              acceptedUserId
              quantity
            }
            week_13 {
              acceptedUserId
              quantity
            }
            week_14 {
              acceptedUserId
              quantity
            }
            week_15 {
              acceptedUserId
              quantity
            }
            week_16 {
              acceptedUserId
              quantity
            }
            week_17 {
              acceptedUserId
              quantity
            }
            week_18 {
              acceptedUserId
              quantity
            }
            week_19 {
              acceptedUserId
              quantity
            }
            week_20 {
              acceptedUserId
              quantity
            }
            week_21 {
              acceptedUserId
              quantity
            }
            week_22 {
              acceptedUserId
              quantity
            }
            week_23 {
              acceptedUserId
              quantity
            }
            week_24 {
              acceptedUserId
              quantity
            }
            week_25 {
              acceptedUserId
              quantity
            }
            week_26 {
              acceptedUserId
              quantity
            }
            week_27 {
              acceptedUserId
              quantity
            }
            week_28 {
              acceptedUserId
              quantity
            }
            week_29 {
              acceptedUserId
              quantity
            }
            week_30 {
              acceptedUserId
              quantity
            }
            week_31 {
              acceptedUserId
              quantity
            }
            week_32 {
              acceptedUserId
              quantity
            }
            week_33 {
              acceptedUserId
              quantity
            }
            week_34 {
              acceptedUserId
              quantity
            }
            week_35 {
              acceptedUserId
              quantity
            }
            week_36 {
              acceptedUserId
              quantity
            }
            week_37 {
              acceptedUserId
              quantity
            }
            week_38 {
              acceptedUserId
              quantity
            }
            week_39 {
              acceptedUserId
              quantity
            }
            week_40 {
              acceptedUserId
              quantity
            }
            week_41 {
              acceptedUserId
              quantity
            }
            week_42 {
              acceptedUserId
              quantity
            }
            week_43 {
              acceptedUserId
              quantity
            }
            week_44 {
              acceptedUserId
              quantity
            }
            week_45 {
              acceptedUserId
              quantity
            }
            week_46 {
              acceptedUserId
              quantity
            }
            week_47 {
              acceptedUserId
              quantity
            }
            week_48 {
              acceptedUserId
              quantity
            }
            week_49 {
              acceptedUserId
              quantity
            }
            week_50 {
              acceptedUserId
              quantity
            }
            week_51 {
              acceptedUserId
              quantity
            }
            week_52 {
              acceptedUserId
              quantity
            }
            week_53 {
              acceptedUserId
              quantity
            }
          }
      }
    }
    updatedAt
  }
}`

export const mutationApproveJobApplication = `
mutation AcceptOrRejectWorkTypeJobApplication($input: AcceptOrRejectWorkTypeJobApplicationInput!) {
  acceptOrRejectWorkTypeJobApplication(input: $input)
}`

//Update Profile
export const mutationUpdateuserProfile = `
mutation Mutation($input: userUpdateInput!) {
  userUpdate(input: $input) {
    logoUrl
    userId
    createdByUserId
    username
    password_hash
    displayName
    customAttrs
    isVerification
    myWorkTeamName
    myWorkFileAttachment
    myWorkImageList
    myWorkTechnicianJobType
    myWorkDescription
    myWorkserviceRegion
    myWorkserviceProvince
    userType
    companyName
    companyBranch
    taxID
    zipCode
    province
    district
    subDistrict
    addressDetail
    email
    phone
    coordinatorName
    coordinatorPersonalID
    coordinatorAddress
    coordinatorPhone
    firstName
    lastName
    birthDate
    nickName
    gender
    personalID
    personalRelation
    emergencyContact {
      firstName
      lastName
      relation
      phone
    }
    technicianJobType
    serviceRegion
    serviceProvince
    isSafetyPassport
    safetyPassport {
      safetyPassportStatus
      safetyPassportType
      safetyPassportFileAttachment
    }
  }
}
`

//Vertify Person
export const mutationCreateUserVerification = `
mutation Mutation($input: CreateUserVerificationInput!) {
  createUserVerification(input: $input) {
    userId
    status
    companyCertificateFileAttachment
    vatFileAttachment
    personalIDFileAttachment
    coordinatorPersonalIDFileAttachment
    selfiePersonalIDFileAttachment
    createdAt
    updatedAt
  }
}
`

//CreateTeam
export const MutationCreateTeamlist = `
mutation CreateUserInTeam($input: userInTeamInput!) {
  createUserInTeam(input: $input) {
    createdByUserId
    username
    password_hash
    displayName
    customAttrs
    isVerification
    userVerification {
      userId
      status
      companyCertificateFileAttachment
      vatFileAttachment
      selfieJuristicIDFileAttachment
      coordinatorPersonalIDFileAttachment
      selfiePersonalIDFileAttachment
      personalIDFileAttachment
      createdAt
      updatedAt
    }
    myWorkTeamName
    myWorkFileAttachment
    myWorkImageList
    myWorkTechnicianJobType
    myWorkDescription
    myWorkserviceRegion
    myWorkserviceProvince
    userType
    companyName
    companyBranch
    taxID
    zipCode
    province
    district
    subDistrict
    addressDetail
    phone
    email
    coordinatorName
    coordinatorPersonalID
    coordinatorAddress
    coordinatorPhone
    firstName
    lastName
    birthDate
    nickName
    gender
    personalRelation
    personalID
    emergencyContact {
      firstName
      lastName
      relation
      phone
    }
    technicianJobType
    serviceRegion
    serviceProvince
    isSafetyPassport
    safetyPassport {
      safetyPassportStatus
      safetyPassportType
      safetyPassportFileAttachment
    }
    userId
  }
}
`

//UpdateUserInTeam
export const MutationUpdateUserInTeam = `
mutation ME_P1_UPDATE_USER_IN_TEAM($input: userUpdateTeamInput!) {
  updateUserInTeam(input: $input) {
    userId       
    username
    password_hash
    displayName
    userType
    firstName
    lastName
    birthDate
    nickName
    gender
    personalRelation
    personalID
    taxID
    zipCode
    province
    district
    subDistrict
    addressDetail
    phone
    email
    emergencyContact {
      firstName
      lastName
      relation
      phone
    }
    technicianJobType
    serviceRegion
    serviceProvince
    isSafetyPassport
    safetyPassport {
      safetyPassportStatus
      safetyPassportType
      safetyPassportFileAttachment

    }
  }
}
`
//CreatIssue
export const MutationCreateIssue = `
mutation Mutation($input: AppendIssueInput!) {
  appendIssue(input: $input) {
    topic
    description
    createdByUserId
  }
}
`

//CreateUserVerifileInteam
export const MutationCreateVerifyInTeam = `
mutation CreateUserVerificationInTeam($input: CreateUserVerificationInTeamInput!) {
  createUserVerificationInTeam(input: $input) {
    userId
    selfiePersonalIDFileAttachment
    personalIDFileAttachment
  }
}
`
//DeleteUserInTeam
export const MutationDelUserInTeam = `
mutation DeleteUserInTeam($input: UserIDInput!) {
  deleteUserInTeam(input: $input) {
    userId
  }
}
`

//CreateSafetyUser
export const MutationCreateSafteyUser = `
mutation Create_Safety_LOGIN($input: [SafetyPassportInput!]!) {
  requestApprovalSafetyPassport(input: $input) {
    safetyPassportType
    safetyPassportFileAttachment
    createdAt
  }
}
`

//CreateSafetyInTeam
export const MutationCreateSafteyTeam = `
mutation RequestApprovalSafetyPassportUserInTeam($input: [SafetyPassportUserInTeamInput!]!, $userId: ID!) {
  requestApprovalSafetyPassportUserInTeam(input: $input, userId: $userId) {
    userId
    safetyPassportStatus
    safetyPassportType
    safetyPassportFileAttachment
  }
}
`

//ChangePassword
export const MutationChangePassword = `
mutation UserChangePassword($input: userChangPasswordInput!) {
  userChangePassword(input: $input) {
    userId
    password_hash
  }
}
`

//createAcceptedUserInProject
export const MutationcreateAcceptedUserInProject = `
mutation CreateAcceptedUserInProject($projectId: ID!, $acceptedUserId: ID!) {
  createAcceptedUserInProject(projectId: $projectId, acceptedUserId: $acceptedUserId) {
    projectId
  }
}
`

//DelAcceptUserInproject
export const MutationDeleteAcceptedUserInProject = `
mutation Mutation($projectId: ID!, $acceptedUserId: ID!) {
  deleteAcceptedUserInProject(projectId: $projectId, acceptedUserId: $acceptedUserId) {
    acceptedUserIdList {
      userId
    }
  }
}

`

export const mutationRefreshPATStatus = `
mutation Mutation($projectId: ID!) {
  refreshPATStatus(projectId: $projectId)
}
`

export const mutationRefreshPATNC = `
mutation RefreshPATNC($projectId: ID!) {
  refreshPATNC(projectId: $projectId)
}
`
