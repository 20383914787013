import React from 'react'
import { useSelector } from 'react-redux'
import { ICombineReducers } from '../../state/reducers'

const LoaderSpinner: React.FC = (): JSX.Element => {
  const stateRedux = useSelector((state: ICombineReducers) => state.loaderSpinnerReducer)

  return stateRedux.openLoaderSpinner ? (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : (
    <></>
  )
}

export default LoaderSpinner
