import React from 'react'
import useTokenInitializer from 'hook/useTokenInitializer'
import useRoleProtection from 'hook/useRoleProtection'
import { Navigate, Outlet } from 'react-router-dom'

// https://www.robinwieruch.de/react-router-private-routes/

export const RequireAuth: React.FC<RequireAuthProps> = ({ redirectTo }: RequireAuthProps) => {
  const isLoggedIn: boolean = useTokenInitializer()
  useRoleProtection()
  return isLoggedIn ? <Outlet /> : <Navigate to={redirectTo} />
}
interface RequireAuthProps {
  redirectTo: string
}
