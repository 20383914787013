const setOpenLoaderSpinner = () => {
  return {
    type: 'SET_OPEN_LOADER_SPINNER',
  }
}

const setCloseLoaderSpinner = (): { type: string } => {
  return {
    type: 'SET_CLOSE_LOADER_SPINNER',
  }
}

export default {
  setOpenLoaderSpinner,
  setCloseLoaderSpinner,
}
