import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import successlogo from './successlogo.svg'

const Successcomponent: React.FC<any> = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <section className={styles.rightSideContent}>
      <p className={styles.successTopic}>ตั้งรหัสผ่านใหม่สำเร็จ</p>
      <img className={styles.successlogo} src={successlogo} alt="successlogo" />
      <article>
        <p>คุณได้เปลี่ยนรหัสผ่านบัญชีผู้ใช้ที่เชื่อมกับหมายเลข</p>
        <p>
          <span>โทรศัพท์</span>
          <span className={styles.phonenumHighlight}>(+66) {values.emailorphonenumber}</span>
        </p>
      </article>
      <button
        onClick={() => {
          window.location.reload()
        }}
        type="button"
        className={`orangeBtn`}
      >
        ตกลง
      </button>
    </section>
  )
}

export default Successcomponent
