export const ThemeMenu = {
  '& .MuiPaper-root': {
    padding: '0',
    boxShadow:
      '0px 33px 74px rgba(0, 0, 0, 0.11), 0px 16.9254px 16.5289px rgba(0, 0, 0, 0.0655718), 0px 5.87201px 4.92108px rgba(0, 0, 0, 0.0444282)',
  },
  '& .MuiMenuItem-root': {
    padding: '1em',
    fontFamily: 'Kanit',
    display: 'flex',
    gap: '16px',
    ':last-child': {
      color: 'red',
    },
  },
}

export const ThemeMenuAdd = {
  '& .MuiPaper-root': {
    padding: '0',
    boxShadow:
      '0px 33px 74px rgba(0, 0, 0, 0.11), 0px 16.9254px 16.5289px rgba(0, 0, 0, 0.0655718), 0px 5.87201px 4.92108px rgba(0, 0, 0, 0.0444282)',
  },
  '& .MuiMenuItem-root': {
    padding: '1em',
    fontFamily: 'Kanit',
    display: 'flex',
    gap: '16px',
  },
}
