import React from 'react'
import { Field, useFormikContext, getIn } from 'formik'

export const FormikInputRadio: React.FC<IFormikInputRadioProps> = ({
  listRadio,
  defaultValue,
  onChange,
}: IFormikInputRadioProps): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<any>()
  const str2bool = (value: string) => {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true
      if (value.toLowerCase() === 'false') return false
    }
    return value
  }
  // console.log(values.userteam)

  const getDefaultValue = (data: any) => {
    try {
      // console.log(`values.${data.name}`, eval(`values.${data.name}`))
      // console.log(eval(`values.${data.name}`) === data.value)
      return getIn(values, `${data.name}`) === data.value
    } catch (error) {
      if (defaultValue) {
        setFieldValue(data.name, defaultValue)
      }
    }
    return false
  }

  return (
    <>
      {listRadio.map((data: any, index: number) => (
        <section key={index}>
          <Field
            disabled={data.disabled ? data.disabled : false}
            // defaultChecked={index === 0}
            checked={getDefaultValue(data)}
            name={data.name}
            type="radio"
            value={data.value}
            onChange={(e: any) => {
              onChange?.call(this, e)
              if (typeof data.value === 'boolean') {
                // console.log('THIS IS VALUE', e.target.value)
                setFieldValue(data.name, str2bool(e.target.value))
              } else {
                setFieldValue(data.name, e.target.value)
              }
            }}
          />
          <span>{data.text}</span>
        </section>
      ))}
    </>
  )
}
interface IFormikInputRadioProps {
  listRadio: IListRadio[]
  defaultValue?: string | number | boolean
  onChange?: (value: any) => void
}

interface IListRadio {
  // className: any
  name: string
  value: string | boolean
  text: string
  disabled?: boolean
}
