import React, { useState } from 'react'
import styles from './index.module.scss'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { TextField } from '@fluentui/react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import fileLogo from '../../image/fileIcon.svg'
import TableTeam from './TableTeam'
import editIcon from '../../image/editGray.svg'
import detailIcon from '../../image/detail.svg'
import { useNavigate } from 'react-router-dom'
import view from './view.svg'
import Fade from '@mui/material/Fade'
import { useSelector } from 'react-redux'
import { THAILAND_PROVINCE_MAPPER, THAILAND_PROVINCE2CODE_MAPPER } from '../../components/commons/thailandFact'
import { ICombineReducers } from 'state/reducers'
import { getProfileTeam, getUserProfile, post } from 'adapter/xhr'
import ImagesSlideShow from 'components/imagesSlideShow'
import { saveAs } from 'file-saver'
import converseCSVToJSON from './converseCSVToJSON'
import { Modal } from '@mui/material'
import CloseSVG from './closeBtn.svg'
import templateCSV from './templateCSV'
// import { generatenewTeamExcel } from './exportTeamExcel'
// import ModalView from './modalView'

import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { downloadFile } from 'utils/common'

const ProfileTeamList: React.FC = (): JSX.Element => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [openM, setOpenM] = React.useState(false)
  const [query, setQuery] = React.useState('')

  const [selectFile, setSeletFile] = React.useState<File | undefined | null>(null)
  const [importFileValid, setImportFileValid] = useState<boolean>(true)

  const [showImportModal, setShowImportModal] = useState<boolean>(false)

  const [modalIndex, setModalIndex] = React.useState<any>(-1)
  const show = Boolean(anchorEl)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const filename = (file: string) => decodeURI(file.split('/')[file.split('/').length - 1])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    // setModalIndex(-1)
  }

  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const TechJobTypeArr: any = {
    Electrician: 'ช่างไฟฟ้า',
    Painter: 'ช่างสี',
    Plumber: 'ช่างปะปา',
    Plasterer: 'ช่างปูน',
    Metalworker: 'ช่างเหล็ก',
    Carpenter: 'ช่างไม้',
    Mechanic: 'ช่างยนต์',
    Installer: 'ช่างติดตั้ง',
  }

  const RegionArr: any = {
    BMR: 'กรุงเทพและปริมณฑล',
    CR: 'ภาคกลาง',
    NR: 'ภาคเหนือ',
    NER: 'ภาคตะวันออกเฉียงเหนือ',
    ER: 'ภาคตะวันตก',
    WR: 'ภาคตะวันตก',
    SR: 'ภาคใต้',
  }

  const queryTeam = () => {
    getUserProfile().then((profileres: any) => {
      console.log('Data Profile', profileres)
      setFieldValue('profile', profileres.data.data.getUser)
      setFieldValue('Showprofile', profileres.data.data.getUser)
    })
  }

  React.useEffect(() => {
    queryTeam()
  }, [])

  const handleDownloadExcel = async (teamList: any[]) => {
    const currentDate = new Date()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `Team_Member_${currentDate}`

    const buffer = await import('./exportTeamExcel').then((genExcel) => {
      return genExcel.generatenewTeamExcel(teamList)
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    // console.log('THIS IS savesad ', excelToSave)
    saveAs(excelToSave, fileName + fileExtension)
    // console.log('SAVE SUCCESS')
  }

  const handleImportFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.length) return
    setImportFileValid(true)
    setSeletFile(e.target.files[0])
  }

  const handleImportCSV = () => {
    dispatch(
      allAction.modalConfirmAction.setOpenModalConfirm('ยืนยันการบันทึกข้อมูล', '', '', 'ใช่, OK', async () => {
        const data = await converseCSVToJSON(selectFile!)
        const query = `
      mutation Mutation($input: [CreateUserInTeamBatch!]) {
        createUserInTeamBatch(input: $input) {
          userId
        }
      }
    `
        await post('/', {
          query,
          variables: {
            input: data,
          },
        })
        queryTeam()
        setShowImportModal(false)
        setSeletFile(null)
        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
      }),
    )
  }

  const downloadTemplate = () => {
    templateCSV()
  }

  return (
    <>
      <div className={styles.container}>
        {/* {console.log(values.profile, 'Data')} */}
        {values.profile && (
          <div className={styles.contentWrapper}>
            {' '}
            <section className={styles.card}>
              <div className={styles.head}>
                {' '}
                <button
                  type="button"
                  className={styles.btDetail}
                  id="fade-button"
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  {' '}
                  <img src={detailIcon} alt="" />
                </button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={show}
                  TransitionComponent={Fade}
                  onClose={handleClose}
                >
                  {/* <MenuItem>
                    <span className={styles.menulist}>
                      <img src={view} alt="" />
                      <p>View</p>
                    </span>
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => navigate(`/technician/profileteamlist/editprofile/${values.profile.userId}`)}
                  >
                    <span className={styles.menulist}>
                      <img src={editIcon} alt="" />
                      <p>Edit</p>
                    </span>
                  </MenuItem>
                </Menu>
              </div>
              <div className={styles.rowWrapper}>
                <div className={styles.leftside}>
                  <article>
                    <p className={styles.textTopic}>ชื่อองค์กร/ทีม</p>
                    <p className={styles.textsubcompany}>{values.profile.myWorkTeamName}</p>
                  </article>
                  {values.profile.myWorkTechnicianJobType ? (
                    <article>
                      <p className={styles.textHead}>ประเภทงานช่าง</p>{' '}
                      <article className={styles.textsubflex}>
                        {values.profile.myWorkTechnicianJobType?.slice(0, 3).map((item: any, index: number) => (
                          <p key={index} className={styles.textsub}>
                            {TechJobTypeArr[`${item}`]}
                          </p>
                        ))}
                        {3 < values.profile.myWorkTechnicianJobType?.length && (
                          <div>
                            {' '}
                            <p className={styles.more}>+{values.profile.myWorkTechnicianJobType?.length - 3}</p>
                          </div>
                        )}
                      </article>
                    </article>
                  ) : (
                    ''
                  )}

                  {values.profile.myWorkserviceRegion ? (
                    <article>
                      <p className={styles.textHead}>ภูมิภาคที่ให้บริการ</p>{' '}
                      <article className={styles.textsubflex}>
                        {values.profile.myWorkserviceRegion?.slice(0, 3).map((item: any, index: number) => (
                          <p key={index} className={styles.textsub}>
                            {RegionArr[`${item}`]}
                          </p>
                        ))}
                        {3 < values.profile.myWorkserviceRegion?.length && (
                          <div>
                            {' '}
                            <p className={styles.more}>+{values.profile.myWorkserviceRegion?.length - 3}</p>
                          </div>
                        )}
                      </article>
                    </article>
                  ) : (
                    ''
                  )}

                  {values.profile.myWorkserviceProvince ? (
                    <article>
                      <p className={styles.textHead}>จังหวัดที่ให้บริการ</p>{' '}
                      <article className={styles.textsubflex}>
                        {console.log(values.profile.myWorkserviceProvince, 'Province')}
                        {values.profile.myWorkserviceProvince?.slice(0, 3).map((item: any, index: number) => (
                          <p key={index} className={styles.textsub}>
                            {THAILAND_PROVINCE2CODE_MAPPER[`${item}`]}
                          </p>
                        ))}
                        {3 < values.profile.myWorkserviceProvince?.length && (
                          <div>
                            {' '}
                            <p className={styles.more}>+{values.profile.myWorkserviceProvince?.length - 2}</p>
                          </div>
                        )}
                      </article>
                    </article>
                  ) : (
                    ''
                  )}

                  {values.profile.myWorkDescription ? (
                    <article>
                      <p className={styles.textHead}>ประสบการณ์/ประวัติโดยย่อ</p>
                      <p className={styles.textsub}>{values.profile.myWorkDescription}</p>
                    </article>
                  ) : (
                    ''
                  )}
                </div>

                <div className={styles.rigthtside}>
                  <div className={styles.imageGroup}>
                    <ImagesSlideShow imageList={values.profile?.myWorkImageList} />
                  </div>

                  {values.profile &&
                    values.profile?.myWorkFileAttachment?.map((fileitem: any, fileIndex: number) => (
                      <div className={styles.showfile} key={fileIndex}>
                        <span className={styles.textfile}>
                          <img src={fileLogo} alt="image" />
                          <div className={styles.filetext} onClick={() => downloadFile(fileitem, filename(fileitem))}>
                            <p>{filename(fileitem)}</p>
                          </div>
                        </span>
                      </div>
                    ))}
                  {console.log('file', values.profile?.myWorkFileAttachment)}
                </div>
              </div>
            </section>{' '}
            <section className={styles.card}>
              <article className={styles.topSearchWrapper}>
                <div className={styles.searchBox}>
                  <Field name="search">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        iconProps={{ iconName: 'Search' }}
                        placeholder="ค้นหา Name, Safety passport"
                      />
                    )}
                  </Field>
                </div>
              </article>
              <article className={styles.tableCard}>
                <p className={styles.textTableTopic}>จัดการทีมงาน</p>
                <div className={styles.topTableBtnWrapper}>
                  <button type="button" className={styles.btnImport} onClick={() => setShowImportModal(true)}>
                    นำเข้าข้อมูล
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      handleDownloadExcel(values.profile.technicianList)
                    }}
                    className={styles.btnDownload}
                  >
                    ดาวน์โหลดCSV
                  </button>
                  <button
                    type="button"
                    className={styles.btnAddTeam}
                    onClick={() => navigate('/technician/createTeam')}
                  >
                    <AddCircleOutlineIcon />
                    <p>เพิ่มชื่อทีมงาน</p>
                  </button>
                </div>
                <div className={styles.table}>
                  {values.profile && values.profile.technicianList ? (
                    <TableTeam openM={openM} setOpenM={setOpenM} setModalIndex={setModalIndex} queryTeam={queryTeam} />
                  ) : (
                    <div className={styles.emptyTeam}>
                      {' '}
                      <p className={styles.text}>----- ยังไม่มีข้อมูลทีมงาน -----</p>
                    </div>
                  )}
                </div>
              </article>
            </section>
          </div>
        )}
      </div>
      <Modal
        open={showImportModal}
        onClose={() => {
          setShowImportModal(false)
        }}
      >
        <div className={styles.importCSVModal}>
          <div className={styles.importCSVModalContainer}>
            <div className={styles.importCSVModalContentContainer}>
              <div className={styles.importCSVModalHeader}>
                <p className={styles.importCSVModalTitle}>นำเข้ารายชื่อทีมงาน</p>

                <img src={CloseSVG} onClick={() => setShowImportModal(false)} alt="" />
              </div>
              <p>
                ดาวน์โหลด ไฟล์สำหรับอัพเดทข้อมูล
                <button onClick={downloadTemplate} className={styles.downloadExampleCSV}>
                  ตัวอย่างไฟล์ CSV
                </button>
                เพื่อดูตัวอย่างข้อมูลที่ต้องกรอก
              </p>
              <div>
                {!importFileValid && <p className={styles.importFileValidError}>กรุณา browse ไฟล์</p>}
                <div
                  className={styles.selectFileContainer}
                  style={{ borderColor: importFileValid ? '#F2C94C' : '#FD3E3E' }}
                >
                  <button
                    className={styles.selectFileBtn}
                    style={{ backgroundColor: importFileValid ? '#F2C94C' : '#FD3E3E' }}
                  >
                    เลือกไฟล์
                    <input type="file" className={styles.inputImport} onChange={handleImportFileChange} />
                  </button>
                  <p className={styles.selectFileName}>{selectFile?.name}</p>
                </div>
              </div>
            </div>
            <div className={styles.importCSVModalButtonActions}>
              <button onClick={() => setShowImportModal(false)} className={styles.buttonActionsCancel}>
                ยกเลิก
              </button>
              <button
                className={styles.buttonActionsOk}
                onClick={() => {
                  if (!selectFile) return setImportFileValid(false)
                  handleImportCSV()
                }}
              >
                อัพโหลดไฟล์
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
// export default ProfileTeamList
const EnhancedProfileTeamList = withFormik({
  mapPropsToValues: () => ({
    search: '',
    optionProvince: [
      ...Object.entries(THAILAND_PROVINCE_MAPPER).map((region: any) => ({
        value: region[1],
        text: region[0],
      })),
    ],
  }),
  validate: (values) => {
    const error = {}

    return error
  },
  handleSubmit: (values, { setFieldValue }) => {
    //
    console.log(values)
    console.log(JSON.stringify(values))
  },
})(ProfileTeamList)

export default EnhancedProfileTeamList
