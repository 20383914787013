import { post, IProject, getProjectByProjectId, getProjectIdByUserId, getProjectIdByAcceptedUserId } from 'adapter/xhr'

// Actually this doesn't really help in dynamodb.
// Because it will query everything until completion anyways.
// We need to make jobList nested QUery in GraphQL for this to show Benefit
export async function getProjectsByUserId(userId: string) {
  const projectIdList = (await getProjectIdByUserId(userId)).data.data.projectByUserId

  if (projectIdList.length == 0) {
    return []
  }

  const projectList = await getProjectsByProjectIdList(projectIdList)

  // Return array of Projects instead of an object with project0,1,2,... as keys
  return Object.keys(projectList).map((key: string) => ({
    ...projectList[key],
  }))
}

// Actually this doesn't really help in dynamodb.
// Because it will query everything until completion anyways.
// We need to make jobList nested QUery in GraphQL for this to show Benefit
export async function getProjectsByAcceptedUserId(acceptedUserId: string) {
  console.time('getProjectsByAcceptedUserId>projectIdList')
  const projectIdList = (await getProjectIdByAcceptedUserId(acceptedUserId)).data.data.projectByAcceptedUserId
  console.timeEnd('getProjectsByAcceptedUserId>projectIdList')

  if (projectIdList.length == 0) {
    return []
  }

  const projectList = await getProjectsByProjectIdList(projectIdList)

  // Return array of Projects instead of an object with project0,1,2,... as keys
  return Object.keys(projectList).map((key: string) => ({
    ...projectList[key],
  }))
}

// Make This projectWithoutJobFor Optimization! -> Cant because the friggin myProject needs totalJobQty
async function getProjectsByProjectIdList(projectIdList: IProject[]) {
  // Somehow Graphql doesn't allow usage of "dash in its characters"
  // So we need to make do with index first!
  const query = `query {
    ${projectIdList
      .map((projectElement: IProject) => projectElement.projectId)
      .map(
        (projectId: string, projectIndex: number) => `
      project${projectIndex}: project(projectId: "${projectId}" ){
        projectId
        projectName
        projectDescription
        TORFile
        status
        createdAt
        workTypeList { 
          workType
          totalJobQty
          imageURLList
        }
        acceptedUserIdList {
          userId
          getUser {
            username
            userId
            email
            technicianJobType
          }
        }
      }`,
      )
      .join('\n')}
  }`
  console.time('getProjectsByProjectIdList>projectList')
  const projectList = (await post('/', { query })).data.data
  console.timeEnd('getProjectsByProjectIdList>projectList')
  return projectList
}

// Same as Raw Project, but customize the query variable to optimize
export async function getProjectForHandleByProjectId(projectId: string): Promise<IProject> {
  const query = `
  query($projectId:ID!) {
    project(projectId: $projectId){
      projectId
      workTypeList {
        workType
        patTemplateId
      }
      jobList{
        changTumId
        jobId
        region
        province
        siteCode
        siteName
        lat
        long
        status
        acceptedUserId
        workType
        isPatGenerated
      }
    }
  }`
  console.time('getProjectForHandleByProjectId>getProjectByProjectId')
  const project: IProject = (await getProjectByProjectId(projectId, {}, query)).data.data.project
  console.timeEnd('getProjectForHandleByProjectId>getProjectByProjectId')
  return project
}

// generatePATFromPATTemplate
export async function generatePatFromPatTemplate(projectWithJobToGenerate: IProject): Promise<string> {
  // Select only required fields to decrease load
  const generatePATByPATTemplateInput = {
    projectId: projectWithJobToGenerate.projectId,
    workTypeList: projectWithJobToGenerate.workTypeList.map(({ workType, patTemplateId }) => ({
      workType,
      patTemplateId,
    })),
    jobList: projectWithJobToGenerate.jobList?.map(({ changTumId, workType }) => ({
      changTumId,
      workType,
    })),
  }

  const query = `mutation ($generatePATByPATTemplateInput: GeneratePATByPATTemplateInput!){
    generatePATByPATTemplate(input:$generatePATByPATTemplateInput)
  }`
  const variables = {
    generatePATByPATTemplateInput,
  }
  try {
    const successString = (await post('/', { query, variables })).data.data.generatePATByPATTemplate
    return successString
  } catch (error) {
    console.log(error.response)
  }
  return 'FAILURE'
}
