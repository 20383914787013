import * as React from 'react'
import styles from './index.module.scss'
import { useParams } from 'react-router-dom'
import { CUSTOMER_PREFIX } from 'App'
//svg Image
import TechLogo from '../../image/tech.svg'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { getPostbyUserId, ListPostByUserId, getProjectByProjectId, getPostbyProjectID, IListPost } from 'adapter/xhr'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'

import { useNavigate } from 'react-router-dom'
import CardComponent from 'components/Card'
import TableShow from 'components/TableShow'
import ModalApprove from './acceptJob/modal'

const NewsFeedFilterTechPage: React.FC<any> = (props: any) => {
  const { projectId, postId } = useParams<any>()
  // const [selectedPostElement, setSelectedPostElement] = React.useState<>()
  const [selectedProjectElement, setSelectedProjectElement] = React.useState<any>(postId)

  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  const navigate = useNavigate()
  const { customerOrTechnician } = useParams()

  const { userId, username } = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const [currentHeaderButton, setCurrentHeaderButton] = React.useState(BUTTON_VALUE.DASHBOARD)

  const buttonHeaderList = customerOrTechnician === CUSTOMER_PREFIX ? CUSTOMER_SELECTION : TECHNICIAN_SELECTION
  console.log('buttonHeaderList', buttonHeaderList)

  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const [checkuser, setCheckuser] = React.useState(customerOrTechnician === CUSTOMER_PREFIX)

  function valuetext(value: any) {
    return `${value}`
  }

  enum PageProjectStatus {
    CURRENT,
    ARCHIVED,
  }

  const [pageProjectStatus, setPageProjectStatus] = React.useState<PageProjectStatus>(PageProjectStatus.CURRENT)

  const [query, setQuery] = React.useState('')

  React.useEffect(() => {
    console.log({ projectId: projectId }, '{ projectId: projectId }')
    console.log(
      'helloooasdasdasdasdasdsaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaao',
      values,
    )

    getPostbyProjectID({ projectId: projectId }).then((post: any) => {
      console.log('PostByProject=>', post)
      setFieldValue('postbyproject', post.data.data.postByProjectID)
    })
  }, [])

  return (
    <div className={styles.center}>
      <div className={styles.cardArea}>
        {' '}
        {values.postbyproject &&
          values.postbyproject
            ?.filter((data: any) => {
              if (query == '') {
                return data
              } else if (data.title.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
                return data
              }
            })
            .map((data: any, index: number) => {
              console.log('nut ====>', data)

              return (
                <div className={styles.cardNews} key={index}>
                  <CardComponent
                    title={data.title}
                    companyName={data.companyName}
                    description={data.description}
                    post={{ startDate: data.startDate, endDate: data.endDate }}
                    project={{ startDate: data.project.startDate, endDate: data.project.endDate }}
                    fileAttachmentList={data.fileAttachmentList}
                    workTypePostList={data.workTypePostList}
                    imageURLList={data.imageURLList}
                    createdAt={data.createdAt}
                    acceptUserId={data.createdByUserID.length}
                    jobApplication={data.jobApplication}
                  />
                  <div className={styles.bottom}>
                    <div className={styles.btnform}>
                      {buttonHeaderList.map(({ text, value, img }, buttonHeaderIndex: number) => (
                        <button
                          key={buttonHeaderIndex}
                          className="dp-flex f-basis50 justify-cont-ct btnDefault2"
                          style={{ alignItems: 'center', width: '350px' }}
                          type="button"
                          onClick={() => navigate(`/customer/acceptJob/${data.postID}`)}
                        >
                          {img}
                          {text}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )
            })}
        {values.postbyproject?.length === 0 && (
          <div className={styles.emptyContainer}>
            <div className={styles.emptyHeading}>
              หากต้องการสร้าง New Post
              <div onClick={() => navigate(`/customer/createPostPage/${projectId}`)} className={styles.emptyButton}>
                คลิกที่นี่
              </div>
            </div>
            <div className={styles.emptyLineText}>-------- ยังไม่มีข้อมูล -------- </div>
          </div>
        )}
      </div>
    </div>
  )
}

interface IButtonSelection {
  text: string
  value: BUTTON_VALUE
  img?: any
}

enum BUTTON_VALUE {
  DASHBOARD = 1,
  MASTERPLAN,
  PAT_MANAGEMENT,
}

const CUSTOMER_SELECTION: IButtonSelection[] = [
  {
    text: 'คัดเลือกผู้รับเหมาที่นี่',
    value: BUTTON_VALUE.DASHBOARD,
    img: <img src={TechLogo} alt="" />,
  },
]

const TECHNICIAN_SELECTION: IButtonSelection[] = [
  {
    text: 'สมัครงาน',
    value: BUTTON_VALUE.MASTERPLAN,
  },
]

const EnhancedNewsFeedFilterTechPage = withFormik({
  mapPropsToValues: () => ({
    post: {},
    filterCount: '',
    options: [
      { value: '', text: '' },
      {
        value: 'Group',
        text: 'Group',
      },
      {
        value: 'province',
        text: 'จังหวัด',
      },
      {
        value: 'region',
        text: 'ภูมิภาค',
      },
    ],
    isModalOpen: false,
    fieldList: [
      {
        name: 'region',
        allowedValueList: ['CR', 'ER', 'NER', 'NR', 'SR'],
      },
    ],
    total: 300,
    //FilterProps
    Projectname: '',
    customername: '',
    startDate: new Date(),
    endDate: new Date(),
    price: 0,
  }),
  validate: (values) => {
    const error = {}

    return error
  },
  handleSubmit: (values, { setFieldValue }) => {
    console.log(JSON.stringify(values))
  },
})(NewsFeedFilterTechPage)
export default EnhancedNewsFeedFilterTechPage
