import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from 'components/commons/configAws'
import { FormikInputRadio } from 'components/commons/input/formikInputRadio'
import { FormikInputText } from 'components/commons/input/formikInputText'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { IValues } from './index'
import allAction from '../../state/actions'
import { useDispatch } from 'react-redux'
import {
  AntennaPlaneInput,
  AntennaPole,
  AntennaPoleInput,
  Line_Type,
  PatLine,
  PatLineValue,
  Tower_Type,
  Upload_Type,
  UploadAntenna,
  UploadLineValue,
} from 'utils/generated'
import AntennaComponent from 'components/Antenna'
// import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import SectionGuyedUser from 'container/SectionGuyedUser'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import ImgUploadPNG from 'image/pat/ImgUpload.png'
import { SectionCheckListUser, SectionMeasureUser } from 'container'
import { IJob } from 'adapter/xhr'

const LineGroupComponant = (
  patLineList: PatLine[],
  indexCard: number,
  formikPrefix: string,
  jobInformation: IJob,
): JSX.Element => {
  return (
    <>
      {patLineList.map((data: any, indexData) => (
        <LineTypeComponent
          key={indexData}
          lineValue={data}
          indexCard={indexCard}
          indexData={indexData}
          formikPrefix={formikPrefix}
          jobInformation={jobInformation}
        />
      ))}
    </>
  )
}

interface LineTypeProps {
  lineValue: PatLine &
    PatLineValue &
    UploadLineValue &
    UploadAntenna &
    AntennaPole & {
      text: any
    }
  indexCard: number
  indexData: number
  formikPrefix: string
  jobInformation: IJob
}

const LineTypeComponent = ({
  lineValue,
  indexCard,
  indexData,
  formikPrefix,
  jobInformation,
}: LineTypeProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<IValues>()
  const dispatch = useDispatch()
  const { patId } = useParams()
  const GetFileName = (filename: any) => {
    const lenFile = filename.split('/')
    return filename.split('/')[lenFile.length - 1]
  }

  const handleUploadFile = (
    value: any,
    indexCard: number,
    indexData: number,
    name: string,
    maximumFileSize: number,
  ) => {
    let isFileSizeExceed = false
    Array.from(value).forEach((e: any) => {
      console.log(e.size > maximumFileSize * 1000, 'e.size > maximumFileSize * 1000')
      console.log(e.size, 'e.size')
      console.log(maximumFileSize * 1000, 'maximumFileSize')
      if (e.size > maximumFileSize * 1000) {
        isFileSizeExceed = true
      }
    })
    if (isFileSizeExceed) {
      dispatch(allAction.modalAction.setOpenModalAlertStatus('ไฟล์มีขนาดใหญ่มากเกินไป', '', 'error'))
    } else {
      AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId,
        }),
      })
      const file: any = value[0]
      if (file?.name) {
        const albumPhotosKey = `PAT/${patId}/`
        const photoKey = albumPhotosKey + indexCard + '-' + indexData + '-' + name + file.name
        // Use S3 ManagedUpload class as it supports multipart uploads
        const upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: albumBucketName,
            Key: photoKey,
            Body: file,
            ContentType: file.type,
          },
        })
        dispatch(allAction.loaderSpinnerAction.setOpenLoaderSpinner())
        const promise = upload.promise()
        promise
          .then(function (data) {
            setFieldValue(`${formikPrefix}[${indexCard}][${indexData}][${name}]`, data.Location, true)
            dispatch(allAction.loaderSpinnerAction.setCloseLoaderSpinner())
          })
          .catch(function (err) {
            console.log(err)
            dispatch(allAction.loaderSpinnerAction.setCloseLoaderSpinner())
          })
      } else {
        console.log('err')
      }
    }
  }
  if (lineValue.lineType === Line_Type.Text)
    return (
      <div className={`${styles.subCardText} ${indexData === 0 ? styles.headerTextField : ''}`}>
        <p>{lineValue.text}</p>
      </div>
    )
  if (lineValue.lineType === Line_Type.Heading2)
    return (
      <div className={`${styles.subCardText} ${styles.h2TextField}`}>
        <p>{lineValue.text}</p>
      </div>
    )

  if (lineValue.lineType === Line_Type.Image) {
    return (
      <div className={styles.subCard}>
        <div className={styles.flexImg}>
          {lineValue.text.map((url: string, indexUrl: number) => (
            <a key={indexUrl} href={url}>
              <div className={styles.imageBorder} key={indexUrl}>
                <img className={styles.imageInside} src={url} alt={`Uploaded Image ${indexUrl}`} />
              </div>
            </a>
          ))}
        </div>
      </div>
    )
  }
  // Row for upload Text
  // If not before and after -> just show text
  // If before -> text for before
  // If after -> text for after
  if (lineValue.lineType === Line_Type.Upload && lineValue.uploadType === Upload_Type.Text)
    return (
      <div className={styles.subCard}>
        {/* Case no text */}
        {!(lineValue.isNeedBefore && lineValue.isNeedAfter) && (
          <TextComponent
            label={lineValue.text}
            modeAction={values.modeAction}
            formikPrefix={`${formikPrefix}[${indexCard}][${indexData}]`}
            fieldname="uploadBeforeValue"
            value={lineValue.uploadBeforeValue!}
          />
        )}
        {/* Case before text */}
        {lineValue.isNeedBefore && (
          <TextComponent
            label={lineValue.text + 'ก่อนทำ'}
            modeAction={values.modeAction}
            formikPrefix={`${formikPrefix}[${indexCard}][${indexData}]`}
            fieldname="uploadBeforeValue"
            value={lineValue.uploadBeforeValue!}
          />
        )}
        {/* Case after text */}
        {lineValue.isNeedAfter && (
          <TextComponent
            label={lineValue.text + 'หลังทำ'}
            modeAction={values.modeAction}
            formikPrefix={`${formikPrefix}[${indexCard}][${indexData}]`}
            fieldname="uploadAfterValue"
            value={lineValue.uploadAfterValue!}
          />
        )}
      </div>
    )
  // Special: Antenna Component
  if (lineValue.lineType === Line_Type.Upload && lineValue.uploadType === Upload_Type.Antenna)
    return (
      <div className={styles.subCard}>
        <p className={styles.titleImage}>{lineValue.text}</p>
        <AntennaComponent
          modeAction={values.modeAction}
          formikPrefix={`${formikPrefix}[${indexCard}][${indexData}]`}
          fieldname="poleList"
          poleType={lineValue.poleType}
          jobInformation={jobInformation}
        />
      </div>
    )

  // Other types of Generic Uploads (Image/Video/PDF/Sound/File)
  if (lineValue.lineType === 'UPLOAD') {
    let acceptFile = ''
    if (lineValue.uploadType === 'IMAGE') acceptFile = 'image/*'
    else if (lineValue.uploadType === 'VIDEO') acceptFile = 'video/*'
    // if (data.uploadType === 'TEXT') acceptFile = '.txt'
    else if (lineValue.uploadType === 'PDF') acceptFile = '.pdf'
    else if (lineValue.uploadType === 'SOUND') acceptFile = 'audio/*'
    else if (lineValue.uploadType === 'FILE') acceptFile = ''

    if (lineValue.uploadType === Upload_Type.Checklist) {
      return (
        <SectionCheckListUser
          key={indexData}
          indexData={indexData}
          indexCard={indexCard}
          data={lineValue}
          formikPrefix={formikPrefix}
        />
      )
    }
    if (lineValue.uploadType === Upload_Type.Guyedlegs) {
      return (
        <SectionGuyedUser
          key={indexData}
          indexCard={indexCard}
          indexData={indexData}
          data={lineValue}
          formikPrefix={formikPrefix}
        />
      )
    }
    if (lineValue.uploadType === Upload_Type.Measure) {
      return (
        <SectionMeasureUser
          key={indexData}
          indexCard={indexCard}
          indexData={indexData}
          data={lineValue}
          formikPrefix={formikPrefix}
        />
      )
    }
    return (
      <div className={styles.subCard}>
        <p className={styles.title}>{lineValue.text}</p>

        {/* Row for Img Showing and Upload File 
          1. If isNeedBefore and isNeedAfter ->  uploadBeforeValue & uploadAfterValue
          2. If none, just show uploadBeforeValue */}
        <div className={styles.flexImg}>
          {/* If all OR any is Empty, show only one and put in uploadBeforeValue */}
          {!lineValue.isNeedBefore && !lineValue.isNeedAfter && (
            <div className={styles.rowElement}>
              {lineValue.uploadType === 'SOUND' ? (
                <div className={styles.box}>
                  {values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue ? (
                    <audio controls>
                      <source src={values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue} />
                    </audio>
                  ) : (
                    <img className={styles.imageInside} src={ImgUploadPNG} alt={`Uploaded Image ${indexData}`} />
                  )}
                </div>
              ) : null}
              <div className={styles.imageBorder}>
                {lineValue.uploadType === 'IMAGE' ? (
                  <img
                    className={styles.imageInside}
                    src={
                      values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue
                        ? values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue
                        : ImgUploadPNG
                    }
                    alt={`Uploaded Image ${indexData}`}
                  />
                ) : lineValue.uploadType === 'VIDEO' ? (
                  values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue ? (
                    <video className={styles.imageInside} controls>
                      <source src={values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue} />
                    </video>
                  ) : (
                    <img className={styles.imageInside} src={ImgUploadPNG} alt={`Uploaded Image ${indexData}`} />
                  )
                ) : null}
              </div>

              {values.modeAction === 'upload' && (
                <input
                  type="file"
                  id={`${indexCard}-${indexData}-uploadBeforeValue`}
                  name={`${indexCard}-${indexData}-uploadBeforeValue`}
                  accept={acceptFile}
                  disabled={
                    values.unpackedPat.status === 'WAIT_FILL' || values.unpackedPat.status === 'REJECT' ? false : true
                  }
                  onChange={(e) =>
                    handleUploadFile(
                      e.target.files,
                      indexCard,
                      indexData,
                      'uploadBeforeValue',
                      lineValue.maximumFileSize!,
                    )
                  }
                />
              )}
              <p>
                {lineValue.uploadType === 'IMAGE'
                  ? 'รูปภาพ'
                  : lineValue.uploadType === 'VIDEO'
                  ? 'วิดีโอ'
                  : lineValue.uploadType === 'SOUND'
                  ? 'เสียง'
                  : ''}
              </p>
            </div>
          )}
          {/* If isNeedBefore, just show*/}
          {lineValue.isNeedBefore && (
            <div className={styles.rowElement}>
              <div className={styles.containerBox}>
                {lineValue.uploadType === 'SOUND' ? (
                  <div>
                    {values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue ? (
                      <audio controls>
                        <source src={values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue} />
                      </audio>
                    ) : (
                      <img className={styles.imageInside} src={ImgUploadPNG} alt={`Uploaded Image ${indexData}`} />
                    )}
                  </div>
                ) : lineValue.uploadType === Upload_Type.Pdf ? (
                  values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue ? (
                    <div className={styles.wrapperTextFile}>
                      <PictureAsPdfIcon sx={{ color: '#FF8206' }} />
                      <p className={styles.text}>
                        {GetFileName(values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue)}
                      </p>
                    </div>
                  ) : (
                    <img className={styles.imageInside} src={ImgUploadPNG} alt={`Uploaded Image ${indexData}`} />
                  )
                ) : null}
              </div>
              <div
                className={styles.imageBorder}
                aria-checked={lineValue.uploadType === 'SOUND' || lineValue.uploadType === Upload_Type.Pdf}
              >
                {lineValue.uploadType === 'IMAGE' ? (
                  <img
                    className={styles.imageInside}
                    src={
                      values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue
                        ? values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue
                        : ImgUploadPNG
                    }
                    alt={`Uploaded Image ${indexData}`}
                  />
                ) : lineValue.uploadType === 'VIDEO' ? (
                  values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue ? (
                    <video className={styles.imageInside} controls>
                      <source src={values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue} />
                    </video>
                  ) : (
                    <img className={styles.imageInside} src={ImgUploadPNG} alt={`Uploaded Image ${indexData}`} />
                  )
                ) : null}
              </div>

              {values.modeAction === 'upload' && (
                <input
                  type="file"
                  id={`${indexCard}-${indexData}-uploadBeforeValue`}
                  name={`${indexCard}-${indexData}-uploadBeforeValue`}
                  accept={acceptFile}
                  disabled={
                    values.unpackedPat.status === 'WAIT_FILL' || values.unpackedPat.status === 'REJECT' ? false : true
                  }
                  onChange={(e) =>
                    handleUploadFile(
                      e.target.files,
                      indexCard,
                      indexData,
                      'uploadBeforeValue',
                      lineValue.maximumFileSize!,
                    )
                  }
                />
              )}
              <p>
                {lineValue.uploadType === 'IMAGE'
                  ? 'รูปภาพ'
                  : lineValue.uploadType === 'VIDEO'
                  ? 'วิดีโอ'
                  : lineValue.uploadType === 'SOUND'
                  ? 'เสียง'
                  : ''}
                ก่อนทำ
              </p>
            </div>
          )}
          {/* If isNeedBefore, just show*/}
          {lineValue.isNeedAfter && (
            <div className={styles.rowElement}>
              <div className={styles.containerBox}>
                {lineValue.uploadType === 'SOUND' ? (
                  <div>
                    {values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue ? (
                      <audio controls>
                        <source src={values.unpackedPat.lineList[indexCard][indexData].uploadBeforeValue} />
                      </audio>
                    ) : (
                      <img className={styles.imageInside} src={ImgUploadPNG} alt={`Uploaded Image ${indexData}`} />
                    )}
                  </div>
                ) : lineValue.uploadType === Upload_Type.Pdf ? (
                  values.unpackedPat.lineList[indexCard][indexData].uploadAfterValue ? (
                    <div className={styles.wrapperTextFile}>
                      <PictureAsPdfIcon sx={{ color: '#FF8206' }} />
                      <p className={styles.text}>
                        {GetFileName(values.unpackedPat.lineList[indexCard][indexData].uploadAfterValue)}
                      </p>
                    </div>
                  ) : (
                    <img className={styles.imageInside} src={ImgUploadPNG} alt={`Uploaded Image ${indexData}`} />
                  )
                ) : null}
              </div>
              <div
                className={styles.imageBorder}
                aria-checked={lineValue.uploadType === 'SOUND' || lineValue.uploadType === Upload_Type.Pdf}
              >
                {lineValue.uploadType === 'IMAGE' ? (
                  <img
                    className={styles.imageInside}
                    src={
                      values.unpackedPat.lineList[indexCard][indexData].uploadAfterValue
                        ? values.unpackedPat.lineList[indexCard][indexData].uploadAfterValue
                        : ImgUploadPNG
                    }
                    alt={`Uploaded Image ${indexData}`}
                  />
                ) : lineValue.uploadType === 'VIDEO' ? (
                  values.unpackedPat.lineList[indexCard][indexData].uploadAfterValue ? (
                    <video className={styles.imageInside} controls>
                      <source src={values.unpackedPat.lineList[indexCard][indexData].uploadAfterValue} />
                    </video>
                  ) : (
                    <img className={styles.imageInside} src={ImgUploadPNG} alt={`Uploaded Image ${indexData}`} />
                  )
                ) : null}
              </div>
              {values.modeAction === 'upload' && (
                <input
                  type="file"
                  id={`${indexCard}-${indexData}-uploadAfterValue`}
                  name={`${indexCard}-${indexData}-uploadAfterValue`}
                  accept={acceptFile}
                  disabled={
                    values.unpackedPat.status === 'WAIT_FILL' || values.unpackedPat.status === 'REJECT' ? false : true
                  }
                  onChange={(e) =>
                    handleUploadFile(
                      e.target.files,
                      indexCard,
                      indexData,
                      'uploadAfterValue',
                      lineValue.maximumFileSize!,
                    )
                  }
                />
              )}
              <p>
                {lineValue.uploadType === 'IMAGE'
                  ? 'รูปภาพ'
                  : lineValue.uploadType === 'VIDEO'
                  ? 'วิดีโอ'
                  : lineValue.uploadType === 'SOUND'
                  ? 'เสียง'
                  : ''}
                หลังทำ
              </p>
            </div>
          )}
        </div>
      </div>
    )
  } //Approval line needs to check if it is approve or upload  and reject
  if (
    lineValue.lineType === 'APPROVE' &&
    (values.modeAction === 'approve' || (values.modeAction === 'upload' && values.unpackedPat.status === 'REJECT'))
  ) {
    return (
      <div
        className={`${styles.rowApprove} ${
          !values.unpackedPat.lineList[indexCard][indexData].isApproved ? styles.redTab : ''
        }`}
      >
        <div className={styles.groupApprove}>
          {/* Radio button of the approvalStatus */}
          <FormikInputRadio
            listRadio={[
              {
                text: 'ผ่านการตรวจสอบ',
                value: true,
                name: `${formikPrefix}[${indexCard}][${indexData}].isApproved`,
                disabled: values.modeAction === 'upload' && values.unpackedPat.status === 'REJECT' ? true : false,
              },
              {
                text: 'ไม่ผ่านการตรวจสอบ',
                value: false,
                name: `${formikPrefix}[${indexCard}][${indexData}].isApproved`,
                disabled: values.modeAction === 'upload' && values.unpackedPat.status === 'REJECT' ? true : false,
              },
            ]}
          />
        </div>
        {/* If isApproved false, show approvalRemark */}
        {!values.unpackedPat.lineList[indexCard][indexData].isApproved && (
          <FormikInputText
            readOnly={values.modeAction === 'upload' && values.unpackedPat.status === 'REJECT' ? true : false}
            className={styles.approvalRemarkTextField}
            name={`${formikPrefix}[${indexCard}][${indexData}].approvalRemark`}
            placeholder="หมายเหตุเพิ่มเติม"
            additionalStyle={{
              color: 'red',
            }}
          />
        )}
      </div>
    )
  }
  return <></>
}

export default LineGroupComponant

interface TextComponentProps {
  label: string
  modeAction: string
  formikPrefix: string
  fieldname: string
  value: string
}

const TextComponent = ({ label, modeAction, formikPrefix, fieldname, value }: TextComponentProps) => {
  return (
    <div className={styles.rowUploadText}>
      {label}
      {modeAction === 'upload' ? (
        <FormikInputText className="inputBorder" name={`${formikPrefix}[${fieldname}]`} />
      ) : (
        <span className={styles.textValue}>{value}</span>
      )}
    </div>
  )
}
