import React, { useState } from 'react'
import styles from './index.module.scss'

import ReportNC from './reportNC'
import ReportPatStatus from './reportStatus'

const Fourthcontent: React.FC = () => {
  const [reportTab, setReportTab] = useState(REPORT_VALUE.STATUS_PAT)

  return (
    <>
      <div className={styles.reportWrapper}>
        {REPORTTAB_SELECTION.map(({ text, value }, tabIndex: number) => {
          return (
            <button
              key={tabIndex}
              className={`${styles.selectionButton} ${reportTab === value ? styles.selected : ''}`}
              type="button"
              onClick={() => setReportTab(value)}
            >
              {text}
            </button>
          )
        })}
      </div>
      {reportTab === REPORT_VALUE.STATUS_PAT ? (
        <ReportPatStatus />
      ) : reportTab === REPORT_VALUE.NC ? (
        <ReportNC />
      ) : null}
    </>
  )
}

export default Fourthcontent

interface IReportSelection {
  text: string
  value: REPORT_VALUE
}

enum REPORT_VALUE {
  STATUS_PAT,
  NC,
}

const REPORTTAB_SELECTION: IReportSelection[] = [
  {
    text: 'รายงานสถานะเอกสาร PAT',
    value: REPORT_VALUE.STATUS_PAT,
  },
  {
    text: 'รายงาน NC',
    value: REPORT_VALUE.NC,
  },
]
