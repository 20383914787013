import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import editIcon from './edit.svg'
import detailIcon from '../../image/detail.svg'
import Verified from './verified.svg'
import Unverified from './Unverify.svg'
import viewIcon from './view.svg'
import Active from './active.svg'
import DelIcon from './del.svg'

import InactiveLogo from './Inactive.svg'
import { useFormikContext } from 'formik'
// import deleteIcon from '../../image/delete.svg'
import Fade from '@mui/material/Fade'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
// import ModalView from '../Leave/modalView'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useParams } from 'react-router-dom'
import ModalViewTeam from './modalTeamView'
import { DeleteUserInTeam, UpdateUserTeam } from 'adapter/xhr'
import Fuse from 'fuse.js'

const TableTeam: React.FC<any> = (props: any): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const { id } = useParams<any>()
  const [open, setOpen] = React.useState(false)
  const [modalIndex, setModalIndex] = React.useState<any>(-1)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [visible, setVisible] = React.useState(2)
  const show = Boolean(anchorEl)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClick = (event: any, index: number) => {
    setAnchorEl(event.currentTarget)
    setModalIndex(index)
    console.log(values.profile?.technicianList[index], 'this is values profile')
  }

  const handleClose = () => {
    setAnchorEl(null)
    setModalIndex(-1)
  }

  const ShowModal = (index: any) => {
    setOpen(true)
  }
  const TechJobTypeArr: any = {
    Electrician: 'ช่างไฟฟ้า',
    Painter: 'ช่างสี',
    Plumber: 'ช่างปะปา',
    Plasterer: 'ช่างปูน',
    Metalworker: 'ช่างเหล็ก',
    Carpenter: 'ช่างไม้',
    Mechanic: 'ช่างยนต์',
    Installer: 'ช่างติดตั้ง',
  }

  const RegionArr: any = {
    BMR: 'กรุงเทพและปริมณฑล',
    CR: 'ภาคกลาง',
    NR: 'ภาคเหนือ',
    NER: 'ภาคตะวันออกเฉียงเหนือ',
    ER: 'ภาคตะวันตก',
    WR: 'ภาคตะวันตก',
    SR: 'ภาคใต้',
  }

  const updateStatus = (status: string) => {
    return new Promise<void>((resolve) => {
      dispatch(
        allAction.modalConfirmAction.setOpenModalConfirm(
          'ปิดการใช้งานสมาชิก',
          `ต้องการปิดการใช้งานสมาชิก ใช่หรือไม่ ${values.profile.technicianList[modalIndex].getUser.firstName} ? `,
          '',
          'ปิดการใช้งาน',
          () => {
            {
              UpdateUserTeam({
                input: {
                  userId: values.profile.technicianList[modalIndex].getUser.userId,
                  status,
                },
              }).then((updateres: any) => {
                resolve()
                props.queryTeam()
                console.log('Update Status Ok', updateres)
              })
            }
            dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
          },
        ),
      )
    })
  }
  const technicianList = React.useMemo(() => {
    if (!values.search) return values.Showprofile?.technicianList || []

    const options = {
      isCaseSensitive: true,
      threshold: 0.1,
      fieldNormWeight: 1,
      keys: [
        'getUser.firstName',
        'getUser.lastName',
        'getUser.fullname',
        'getUser.safetyPassport.safetyPassportType',
        'getUser.safetyPassport.safetyPassportTypePrefix',
      ],
    }
    const fuse = new Fuse(
      values.Showprofile?.technicianList.map((t: any) => ({
        ...t,
        getUser: {
          ...t.getUser,
          fullname: t.getUser.firstName + ' ' + t.getUser.lastName,
          safetyPassport: t.getUser.safetyPassport.map((p: any) => ({
            ...p,
            safetyPassportTypePrefix: `safetyPassport ${p.safetyPassportType}`,
          })),
        },
      })) || [],
      options,
    )
    const result = fuse.search(values.search.trim())
    return result.map((r) => r.item)
  }, [values.search, values.Showprofile?.technicianList])

  return (
    <>
      {/* {console.log(values.profile && values.profile.technicianList, 'technicianList')} */}

      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <h4 className={styles.texthead}>No</h4>
            </TableCell>
            <TableCell align="center">
              <h4 className={styles.texthead}>Name</h4>
            </TableCell>
            <TableCell align="center">
              <h4 className={styles.texthead}>ประเภทงาน</h4>
            </TableCell>
            <TableCell align="center">
              <h4 className={styles.texthead}>พื้นที่ให้บริการ</h4>
            </TableCell>
            <TableCell align="center">
              <h4 className={styles.texthead}>เบอร์ติดต่อ</h4>
            </TableCell>
            <TableCell align="center">
              <h4 className={styles.texthead}>safety Passport</h4>
            </TableCell>
            <TableCell align="center">
              <h4 className={styles.texthead}>สถานะ</h4>
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.Showprofile &&
            technicianList.map((techItem: any, index: number) => (
              <TableRow sx={{ '&:last-child td, &:last-child th': {} }} key={index}>
                <TableCell component="th" scope="row" align="center">
                  <p className={styles.textsub}>{index + 1}</p>
                </TableCell>
                <TableCell align="left">
                  <span className={styles.textsub}>
                    {techItem.getUser?.firstName}
                    <span> {techItem.getUser?.lastName}</span>
                  </span>
                </TableCell>
                <TableCell align="left">
                  {techItem.getUser.technicianJobType?.slice(0, visible).map((type: any, typeIndex: number) => (
                    <div key={typeIndex}>
                      {' '}
                      <p className={styles.textsub}>{TechJobTypeArr[`${type}`]}</p>
                    </div>
                  ))}
                  {visible < techItem.getUser.technicianJobType?.length && (
                    <div>
                      {' '}
                      <p className={styles.more}>+{techItem.getUser.technicianJobType?.length - visible}</p>
                    </div>
                  )}
                </TableCell>
                <TableCell align="left">
                  {techItem.getUser.serviceRegion?.slice(0, visible).map((region: any, pvIndex: number) => (
                    <div key={pvIndex}>
                      {' '}
                      <p className={styles.textsub}>{RegionArr[`${region}`]}</p>
                    </div>
                  ))}
                  {visible < techItem.getUser.serviceRegion?.length && (
                    <div>
                      {' '}
                      <p className={styles.more}>+{techItem.getUser.serviceRegion?.length - visible}</p>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">
                  {' '}
                  <p className={styles.textsub}>{techItem.getUser.phone}</p>
                </TableCell>
                <TableCell align="center">
                  {techItem.getUser.safetyPassport?.slice(0, visible).map((item: any, passIndex: number) => (
                    <div className={styles.saftypass} key={passIndex}>
                      <div
                        className={
                          item.safetyPassportStatus === 'ACCEPT'
                            ? styles.dotacceptStatus
                            : item.safetyPassportStatus === 'PENDING'
                            ? styles.dotpendingStatus
                            : ''
                        }
                      ></div>
                      <p className={styles.textsub}>safetyPassport {item.safetyPassportType}</p>
                    </div>
                  ))}
                  {visible < techItem.getUser.safetyPassport?.length && (
                    <div>
                      {' '}
                      <p className={styles.more}>+{techItem.getUser.safetyPassport?.length - visible}</p>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">
                  <div className={styles.status}>
                    <div
                      className={
                        techItem.getUser?.status === 'ACTIVE'
                          ? styles.ActiveStatus
                          : techItem.getUser?.status === 'INACTIVE'
                          ? styles.InactiveStatus
                          : ''
                      }
                    >
                      {techItem.getUser.status}
                    </div>
                  </div>{' '}
                </TableCell>
                <TableCell align="center">
                  <div className={styles.action}>
                    {' '}
                    <div>
                      {' '}
                      <button
                        type="button"
                        className={styles.btDetail}
                        id="fade-button"
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        aria-expanded={props.openM ? 'true' : undefined}
                        onClick={(event: any) => {
                          handleClick(event, index)
                          console.log(index, 'mydata')
                        }}
                      >
                        {' '}
                        <img src={detailIcon} alt="" />
                      </button>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={show}
            TransitionComponent={Fade}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                if (
                  values.profile.technicianList[modalIndex] &&
                  values.profile.technicianList[modalIndex].getUser.userVerification === null
                ) {
                  console.log('Go To verify')
                  navigate(
                    `/:customerOrTechnician/editTeam/${
                      values.profile.technicianList[modalIndex] &&
                      values.profile.technicianList[modalIndex].getUser.userId
                    }`,
                  )
                }
              }}
            >
              {values.profile.technicianList[modalIndex] &&
                values.profile.technicianList[modalIndex].getUser.userVerification === null && (
                  <span className={styles.menulist}>
                    <img src={Unverified} alt="" />
                    <p>Verified</p>
                  </span>
                )}
              {values.profile.technicianList[modalIndex] &&
                values.profile.technicianList[modalIndex].getUser.userVerification?.status === 'VERIFIED' && (
                  <span className={styles.menulist}>
                    <img src={Verified} alt="" />
                    <p>Verified</p>
                  </span>
                )}
              {values.profile.technicianList[modalIndex] &&
                values.profile.technicianList[modalIndex].getUser.userVerification?.status === 'WAIT_VERIFIED' && (
                  <span className={styles.menulist}>
                    <img src={Verified} alt="" />
                    <p>Verified</p>
                  </span>
                )}
            </MenuItem>
            <MenuItem
              onClick={() => {
                console.log('Data', values.profile.technicianList[modalIndex])
                navigate(`/technician/profileUserteam/${values.profile.technicianList[modalIndex].getUser.userId}`)
              }}
            >
              <span className={styles.menulist}>
                <img src={viewIcon} alt="" />
                <p>View</p>
              </span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                console.log('Tech', values.profile.technicianList[modalIndex])
                navigate(`/technician/editTeam/${values.profile.technicianList[modalIndex].getUser.userId}`)
              }}
            >
              <span className={styles.menulist}>
                <img src={editIcon} alt="" />
                <p>Edit</p>
              </span>
            </MenuItem>

            <MenuItem
              onClick={async () => {
                if (values.profile.technicianList[modalIndex].getUser.status == 'ACTIVE') {
                  await updateStatus('INACTIVE')
                } else if (values.profile.technicianList[modalIndex].getUser.status == 'INACTIVE') {
                  await updateStatus('ACTIVE')
                }
              }}
            >
              {values.profile.technicianList[modalIndex] &&
                values.profile.technicianList[modalIndex].getUser.status == 'ACTIVE' && (
                  <span className={styles.menulist}>
                    <img src={InactiveLogo} alt="" />
                    <p>Inactive</p>
                  </span>
                )}
              {values.profile.technicianList[modalIndex] &&
                values.profile.technicianList[modalIndex].getUser.status == 'INACTIVE' && (
                  <span className={styles.menulist}>
                    <img src={Active} alt="" />
                    <p>Active</p>
                  </span>
                )}
            </MenuItem>

            <MenuItem
              onClick={() => {
                dispatch(
                  allAction.modalConfirmAction.setOpenModalConfirm(
                    `ลบทีมงาน `,
                    `คุณต้องการลบทีมงาน  ${values.profile.technicianList[modalIndex].getUser.firstName}  ${values.profile.technicianList[modalIndex].getUser.lastName} ?`,
                    'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                    'ใช่,ลบทีมงาน',
                    () => {
                      const DelVariables = {
                        input: {
                          userId: values.profile.technicianList[modalIndex].getUser.userId,
                        },
                      }
                      console.log('Input ===>', DelVariables)
                      DeleteUserInTeam(DelVariables).then((delres: any) => console.log('Del Ok =>', delres))
                      // DelLeave(`${mydata.id}`)
                      dispatch(
                        allAction.modalAction.setOpenModalAlertStatus(
                          `ลบทีมงาน  ${values.profile.technicianList[modalIndex].getUser.firstName}  ${values.profile.technicianList[modalIndex].getUser.lastName} เรียบร้อย`,
                          '',
                          'success',
                        ),
                      )
                      dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      setTimeout(() => {
                        props.queryTeam()
                      }, 1000)
                    },
                  ),
                )
              }}
            >
              <span className={styles.menulist}>
                <img src={DelIcon} alt="" />
                <p>Delete</p>
              </span>
            </MenuItem>
          </Menu>

          {/* ))} */}
        </TableBody>
      </Table>
      {values.profile ? (
        <ModalViewTeam
          open={open}
          setOpen={setOpen}
          value={values.profile && values.profile.technicianList[modalIndex]}
        />
      ) : (
        ''
      )}
    </>
  )
}
export default TableTeam
