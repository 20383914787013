import * as React from 'react'

import styles from './index.module.scss'
import TreeNode from 'components/TreeNode'

interface Props {
  data: any[]
  name: string
  valuesData: any
  subIndex: number
  lineIndex: number
}

const Tree: React.FC<Props> = ({ data, name, valuesData, subIndex, lineIndex }: Props) => {
  return (
    <div className={styles.container}>
      {data.map((item: any, idx: number) => (
        <TreeNode
          node={item}
          key={idx}
          index={idx}
          name={`${name}[${idx}]`}
          valuesData={valuesData}
          subIndex={subIndex}
          lineIndex={lineIndex}
        />
      ))}
    </div>
  )
}

export default Tree
