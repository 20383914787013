import React, { useState, useEffect } from 'react'
import { Field, useFormikContext, FieldProps } from 'formik'
import styles from './index.module.scss'
import { SortAsc, SortDsc } from './SortIcon'

import { ReactComponent as RemoveIconSVG } from './remove.svg'

import { FormikInputText } from '../commons/input/formikInputText'
import { getChangTumId } from '../commons/shared'
import ToolbarComponent from './toolbar'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { DatePicker } from '@fluentui/react'
import { FormikInputSelect, IOption } from 'components/commons/input/formikInputSelect'
import ModalIssue from './modalIssue'
import Tooltip from 'components/Tooltip'

export function checkLastEmpty(list: any[], keysToIgnore = 'changTumId'): boolean {
  if (list.length === 0) return false
  return Object.keys(list[list.length - 1]).every((key: string) => {
    if (key === keysToIgnore) return true
    return !list[list.length - 1][key]
  })
}

// see if the last one is empty, if it is just return the slice of it.
export function removeEmpty(list: any[], keysToIgnore = 'changTumId'): any[] {
  if (checkLastEmpty(list, keysToIgnore)) {
    return list.slice(0, list.length - 1)
  }
  return list
}

const ChungTumTable: React.FC<IChungTumTable> = ({
  tableColumns,
  data,
  formikPrefix,
  formikDeleteListPrefix,
  showTotal,
  showToolbar,
  generateChangtumId,
  disableRemove,
  width,
  tableId, // Optional for id
  pagination = 25, // How many to show on one page
  disableValidation = true,
  regionFilterInput = '',
  patStatusFilterInput = '',
  textFilterInput = '',
}: IChungTumTable) => {
  const { customerOrTechnician } = useParams()
  const { values, setFieldValue } = useFormikContext<any>()
  const [page, setPage] = useState<number>(0)

  // 4 Types of Filter
  // 1. Region 2. Job Status 3. text Filter 4. Column Filter
  const [regionFilter, setRegionFilter] = useState<string>(regionFilterInput)
  const [patStatusFilter, setPatStatusFilter] = useState<string>(patStatusFilterInput)
  const [textFilter, setTextFilter] = useState<string>(textFilterInput)
  const [columnFilter, setColumnFilter] = useState<any>({})
  const [changtumIdModal, setChangtumIdModal] = useState<string>('')
  const [open, setOpen] = React.useState(false)

  // Sorting Column
  const [sortingColumn, setSortingColumn] = useState<string>('')
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true)
  function handleSortable(tableColumn: ITableColumn): void {
    if (sortingColumn === '') setSortingColumn(tableColumn.name)
    else if (tableColumn.name === sortingColumn && isSortAsc) setIsSortAsc(false)
    else if (tableColumn.name === sortingColumn && !isSortAsc) {
      setSortingColumn('') //RESET
      setIsSortAsc(true)
    } else if (tableColumn.name !== sortingColumn) {
      setSortingColumn(tableColumn.name)
      setIsSortAsc(true)
    }
  }

  useEffect(() => {
    if (generateChangtumId) {
      // Check if there is NO JOB LIST in workType at all!!!
      if (data.length === 0) {
        setFieldValue(formikPrefix, [...data, { ...EMPTY_JOB, changTumId: getChangTumId() }])
      }
      // Check if there is a LAST JOBLIST value in any of the workType List and no EMPTY
      else if (!checkLastEmpty(data)) {
        setFieldValue(formikPrefix, [...data, { ...EMPTY_JOB, changTumId: getChangTumId() }], false)
      }
    }
  }, [data[data.length - 1], generateChangtumId])

  // Filters
  function filterData(dataElement: any) {
    if (regionFilter) {
      if (dataElement.region !== regionFilter) return false
    }
    // console.log(patStatusFilter)
    if (patStatusFilter) {
      if (dataElement.patStatus !== patStatusFilter) return false
    }
    if (textFilter) {
      if (
        !(
          dataElement.jobId?.toLowerCase().trim().includes(textFilter.toLowerCase().trim()) ||
          dataElement.siteCode?.toLowerCase().trim().includes(textFilter.toLowerCase().trim()) ||
          dataElement.changTumId?.toLowerCase().trim().includes(textFilter.toLowerCase().trim()) ||
          dataElement.siteName?.toLowerCase().trim().includes(textFilter.toLowerCase().trim())
        )
      )
        return false
    }
    return true
  }
  function filterTableColumns(column: ITableColumn) {
    if (columnFilter[column.name]) return false
    return true
  }

  function deleteRow(rowIndex: number): void {
    // if not the last one!!
    if (rowIndex !== data.length - 1) {
      if (formikDeleteListPrefix) {
        // setValues can kill initial Values
        const previousDeleteList = values[formikDeleteListPrefix] ? values[formikDeleteListPrefix] : []
        setFieldValue(formikDeleteListPrefix, [...previousDeleteList, data[rowIndex]])
      }

      data.splice(rowIndex, 1)
      // values.workTypeList[worktypelistindex].jobList.splice(joblistfilterindex, 1)
      setFieldValue(formikPrefix, data)
    }
  }

  function handleChangeWithoutValidation(e: React.ChangeEvent<HTMLInputElement>): void {
    // console.log(e.target.value)
    // console.log(e.target.name)
    e.preventDefault()
    setFieldValue(e.target.name, e.target.value, false)
  }
  function handleBlurWithoutValidation(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault()
  }

  function handleSelectChangeWithoutValidation(e: React.ChangeEvent<HTMLSelectElement>): void {
    e.preventDefault()
    setFieldValue(e.target.name, e.target.value, false)
  }
  function handleSelectBlurWithoutValidation(e: React.FocusEvent<HTMLSelectElement>): void {
    e.preventDefault()
  }

  // Change page everytime the filter changes: RegionFilter, statusFilter, textFilter
  useEffect(() => {
    setPage(0)
  }, [regionFilter, patStatusFilter, textFilter])

  // console.log('I WANT =========>', values.jobListReference)

  //https://softwareengineering.stackexchange.com/questions/277778/why-are-people-making-tables-with-divs
  //https://webmasters.stackexchange.com/questions/6036/why-arent-we-supposed-to-use-table-in-a-design/6037
  // Somehow Table with pure CSS is better ?? https://www.freecodecamp.org/news/https-medium-com-nakayama-shingo-creating-responsive-tables-with-pure-css-using-the-grid-layout-module-8e0ea8f03e83/
  // Another Table with pure CSS https://medium.com/evodeck/responsive-data-tables-with-css-grid-3c58ecf04723\
  // Making Table with table and CSS.. but somehow it breaks with border collapse https://adamlynch.com/flexible-data-tables-with-css-grid/
  // Actually, u can make border collapse with css grid https://stackoverflow.com/questions/43686698/collapsing-borders-using-css-grid
  // But u don't use the CSS for border collapse cause it doesn't work. You just control everything in the grid container level!!!
  // There are several ways to do this: (1) Make grid-gap and make box-shadow instead of borders
  //                                    (2) Make the addition with row and column template, and use the borders
  return (
    // Somehow this is used for styling grid gaps! Since there are no way to style the gaps itself otherwise
    // Source: https://stackoverflow.com/questions/13792755/show-border-grid-lines-only-between-elements/47914693#47914693
    <>
      {/* Toolbar */}
      {showToolbar && (
        <ToolbarComponent
          tableId={tableId}
          tableColumns={tableColumns}
          regionFilter={regionFilter}
          patStatusFilter={patStatusFilter}
          textFilter={textFilter}
          columnFilter={columnFilter}
          setRegionFilter={setRegionFilter}
          setPatStatusFilter={setPatStatusFilter}
          setTextFilter={setTextFilter}
          setColumnFilter={setColumnFilter}
        />
      )}

      {/* Actual Table */}
      <div
        className={styles.overflowContainer}
        style={{ paddingLeft: disableRemove ? '0px' : '30px', width: width ? '900px' : 'auto' }}
      >
        <div className={styles.gridContainer}>
          <table
            className={styles.tableWrapper}
            style={{
              gridTemplateColumns: `${tableColumns
                .filter(filterTableColumns)
                .map((column) => column.columnSize)
                .join(' ')}`,
            }}
          >
            <thead>
              {/* English Header */}
              <tr>
                {tableColumns.filter(filterTableColumns).map((ele: ITableColumn, index: number) => {
                  return (
                    <th key={index}>
                      {ele.engText}
                      {ele.sortable !== false && (
                        <>
                          {isSortAsc || ele.name !== sortingColumn ? (
                            <SortAsc
                              className={`${styles.sortButton} ${ele.name === sortingColumn ? styles.active : ''}`}
                              onClick={() => handleSortable(ele)}
                            />
                          ) : (
                            <SortDsc
                              className={`${styles.sortButton} ${ele.name === sortingColumn ? styles.active : ''}`}
                              onClick={() => handleSortable(ele)}
                            />
                          )}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
              {/* Thai Header */}
              <tr>
                {tableColumns.filter(filterTableColumns).map((ele: ITableColumn, index: number) => {
                  return <th key={index}>{`${ele.text}`}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {data
                .map((dataElement: any, actualRowIndex) => ({ ...dataElement, actualRowIndex }))
                .filter(filterData)
                .sort((a, b) => {
                  // If compareFunction(a, b) returns a value > than 0, sort b before a.
                  // If compareFunction(a, b) returns a value < than 0, sort a before b.
                  // If compareFunction(a, b) returns 0, a and b are considered equal.
                  if (a[sortingColumn] > b[sortingColumn]) return isSortAsc ? 1 : -1
                  else if (a[sortingColumn] < b[sortingColumn]) return isSortAsc ? -1 : 1
                  return 0
                })
                .slice(page * pagination, (page + 1) * pagination)
                .map((dataElement: any) => {
                  // const actualRowIndex = rowIndex + page * pagination
                  // .filter(filterData) CANT USE THIS ELSE THE actualRowIndex will FAIL!!!!!!!
                  const { actualRowIndex } = dataElement
                  // if (!filterData(dataElement)) return
                  return (
                    <tr key={`${actualRowIndex}`}>
                      {/* Actual Key only need Row Index, but THIS DOESNT WORK WITH FILTER!!!! */}
                      {tableColumns.filter(filterTableColumns).map((tableColumn: ITableColumn, colIndex: number) => (
                        <td
                          key={`${actualRowIndex}${colIndex}`}
                          style={{
                            position: 'relative',
                            justifyContent: tableColumn.name === 'index' ? 'center' : '',
                          }}
                        >
                          {tableColumn.name === 'index' ? (
                            <>
                              {actualRowIndex + 1}
                              {!disableRemove && (
                                <span
                                  style={{
                                    position: 'absolute',
                                    left: '-23px',
                                    top: '50%',
                                    transform: 'translate(0%, -50%)',
                                  }}
                                >
                                  {/* This SVG uses the way to fill with current, so we can change the fill whenever we want!
                      https://stackoverflow.com/questions/54519654/how-do-i-add-color-to-my-svg-image-in-react*/}
                                  <button
                                    className={styles.removeButton}
                                    onClick={() => deleteRow(actualRowIndex)}
                                    type="button"
                                  >
                                    <RemoveIconSVG />
                                  </button>
                                </span>
                              )}
                            </>
                          ) : tableColumn.name === 'approvePAT' ? (
                            <Link
                              className={styles.patLinkWrapper}
                              to={`/${customerOrTechnician}/pat-approve/I${values.projectSelectedId}${dataElement.changTumId}`}
                              state={{
                                tableId,
                                regionFilter,
                                patStatusFilter,
                                textFilter,
                                projectSelectedId: values.projectSelectedId,
                              }}
                            >
                              <button type="button" className={styles.patLinkButton}>
                                ตรวจสอบ
                              </button>
                            </Link>
                          ) : tableColumn.name === 'uploadPAT' ? (
                            <Link
                              className={styles.patLinkWrapper}
                              to={`/${customerOrTechnician}/pat-upload/I${values.projectSelectedId}${dataElement.changTumId}`}
                              state={{
                                tableId,
                                regionFilter,
                                patStatusFilter,
                                textFilter,
                                projectSelectedId: values.projectSelectedId,
                              }}
                            >
                              <button type="button" className={styles.patLinkButton}>
                                อัพโหลด
                              </button>
                            </Link>
                          ) : tableColumn.name === 'IssueManagement' ? (
                            <button
                              type="button"
                              className={dataElement.issueCount ? styles.buttonnoneIssue : styles.buttonIssue}
                              onClick={() => {
                                setChangtumIdModal(dataElement.changTumId)
                                setOpen(true)
                              }}
                            >
                              {/* {console.log(values.jobListReference, 'Data')}{' '} */}
                              {dataElement.issueCount === null ? (
                                <p>บันทึกปัญหา</p>
                              ) : (
                                <p>บันทึกปัญหา ({dataElement.issueCount})</p>
                              )}
                            </button>
                          ) : // {/* </Link> */}
                          (tableColumn.name === 'planDate' || tableColumn.name === 'actualDate') &&
                            !tableColumn.readOnly ? (
                            <Field name={`${formikPrefix}[${actualRowIndex}].${tableColumn.name}`}>
                              {({ field }: FieldProps) => (
                                <DatePicker
                                  className={styles.dateTextField}
                                  {...field}
                                  value={field.value ? new Date(field.value) : undefined}
                                  // Min date of actual date is plan date
                                  minDate={
                                    tableColumn.name === 'actualDate' && dataElement.planDate
                                      ? new Date(dataElement.planDate)
                                      : undefined
                                    // new Date('2021-08-12')
                                  }
                                  // Max date of plan date is actual date
                                  maxDate={
                                    tableColumn.name === 'planDate' && dataElement.actualDate
                                      ? new Date(dataElement.actualDate)
                                      : undefined
                                  }
                                  onSelectDate={(date) => {
                                    if (date) {
                                      setFieldValue(
                                        field.name,
                                        `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(
                                          2,
                                          '0',
                                        )}-${`${date.getDate()}`.padStart(2, '0')}`,
                                      )
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          ) : tableColumn.readOnly ? (
                            <div
                              className={`${styles.tdReadOnly} `}
                              // WAIT_FILL -> Blue [Navy]
                              // WAIT_APPROVAL ->  Purple [MediumOrchid]
                              // ACCEPT -> Green [SeaGreen]
                              // REJECT -> Red
                              style={{
                                color: tableColumn.colorMapper
                                  ? tableColumn.colorMapper[dataElement[tableColumn.name]]
                                  : '',
                              }}
                            >
                              <Tooltip
                                text={
                                  tableColumn.mapper
                                    ? tableColumn.mapper(dataElement[tableColumn.name])
                                    : dataElement[tableColumn.name]
                                }
                              >
                                <>
                                  {tableColumn.mapper
                                    ? tableColumn.mapper(dataElement[tableColumn.name])
                                    : dataElement[tableColumn.name]}
                                </>
                              </Tooltip>
                            </div>
                          ) : tableColumn.type === CELL_TYPE.INPUT_DROPDOWN ? (
                            <FormikInputSelect
                              className={styles.tdDropdownField}
                              name={`${formikPrefix}[${actualRowIndex}].${tableColumn.name}`}
                              option={tableColumn.optionList ? tableColumn.optionList : []}
                              defaultValue={tableColumn.defaultOption ? tableColumn.defaultOption : ''}
                              onChange={disableValidation ? handleSelectChangeWithoutValidation : undefined}
                              onBlur={disableValidation ? handleSelectBlurWithoutValidation : undefined}
                            />
                          ) : (
                            <FormikInputText
                              readOnly={tableColumn.name === 'changTumId'}
                              className={styles.tdtextfield}
                              // placeholder={dataElement[tableColumn.name]}
                              name={`${formikPrefix}[${actualRowIndex}].${tableColumn.name}`}
                              onChange={disableValidation ? handleChangeWithoutValidation : null}
                              onBlur={disableValidation ? handleBlurWithoutValidation : null}
                              mask={tableColumn.name === 'assignedUserPhone' ? PHONE_MASK : []}
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              <ModalIssue
                open={open}
                setOpen={setOpen}
                changTumId={changtumIdModal}
                projectId={values.projectSelectedId}
              />
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination Section */}
      <section className={styles.paginationWrapper}>
        {[...Array(Math.ceil(data.filter(filterData).length / pagination)).keys()].map((pageNumber) => (
          <button
            className={`${styles.paginationButton} ${page === pageNumber ? styles.paginationSelected : ''}`}
            key={pageNumber}
            type="button"
            onClick={(e) => {
              e.preventDefault()
              setPage(pageNumber)
            }}
          >
            {pageNumber + 1}
          </button>
        ))}
      </section>
      {showTotal && (
        <div>
          จำนวนงานทั้งหมด: {removeEmpty(data).filter(filterData).length} / {removeEmpty(data).length} งาน
        </div>
      )}
    </>
  )
}

export default ChungTumTable

export const EMPTY_JOB = {
  changTumId: '',
  jobId: '',
  workType: '', // SOMEHOW IDK HOW TO MAKE THIS GENERATE PER WORKTYPE !!
  region: '',
  province: '',
  district: '',
  googleMap: '',
  siteCode: '',
  siteName: '',
  zone: '',
  number: '',
  description: '', // For Container Type
  remark: '',
  acceptedUserId: '',
  lat: '',
  long: '',
  locationID: '',
  manufacturer: '',
  installer: '',
  room: '',
  towerType: undefined,
  towerSubType: undefined,
  height: '',
  siteArea: '',
  dimension: '',
  gateWidth: '',
  manufacturerDate: '',
}

export enum CELL_TYPE {
  TEXT,
  INPUT_TEXT,
  INPUT_DROPDOWN,
  INPUT_DATETIME,
}

// This needs to be inside a formik form. Because we will use the Field which uses the Formik Context
// special keyword names: index, delete
export interface ITableColumn {
  name: string
  text: string
  engText: string
  sortable?: boolean
  columnSize?: string
  readOnly?: boolean
  mapper?: (data: any) => string
  colorMapper?: any
  type: CELL_TYPE
  // For TYPE CELL_TYPE.INPUT_DROPDOWN
  optionList?: IOption[]
  defaultOption?: string
}

interface IChungTumTable {
  tableColumns: ITableColumn[]
  data: any[]
  formikPrefix: string
  formikDeleteListPrefix?: string
  showTotal?: boolean
  buttonExport?: boolean
  showToolbar?: boolean
  generateChangtumId?: boolean
  disableRemove?: boolean
  width?: number
  disableValidation?: boolean
  pagination?: number
  tableId?: string
  regionFilterInput?: string
  patStatusFilterInput?: string
  textFilterInput?: string
}

// begin with 0, 9-10 Digits
const PHONE_MASK = [/[0]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const patStatusColorMapper: any = {
  WAIT_FILL: 'gray',
  WAIT_APPROVAL: 'blue',
  ACCEPT: 'green',
  REJECT: 'red',
}

export const PAT_STATUS_MAPPER: any = {
  WAIT_FILL: 'รอการดำเนินการ',
  WAIT_APPROVAL: 'รอการตรวจสอบ',
  ACCEPT: 'ผ่าน',
  REJECT: 'รอการแก้ไข',
}

export const jobStatusColorMapper: any = {
  WAIT: 'gray',
  ACCEPTED: 'blue',
  PLANNED: 'purple',
  FINISHED: 'green',
}

export const JOB_STATUS_MAPPER: any = {
  WAIT: 'รอรับงาน',
  ACCEPTED: 'ทำงาน',
  PLANNED: 'วางแผนเสร็จ',
  FINISHED: 'เสร็จสิ้น',
}
