import React, { useEffect, useMemo, useState } from 'react'
import { getIn, useFormikContext } from 'formik'

import styles from './index.module.scss'
import { FormikInputText } from 'components/commons/input/formikInputText'
import ButtonComponent from 'components/Button'
import { Job, UploadMeasure } from 'utils/generated'
import { parseUnpack } from 'utils/equationUtil'

interface Props {
  indexData: number
  indexCard: number

  data: UploadMeasure
  formikPrefix: string
}

const SectionMeasureUser: React.FC<Props> = ({ indexCard, indexData, data, formikPrefix }: Props) => {
  const [interval, setInterval] = useState<any[]>([])
  // const dispatch = useDispatch()
  const { values, setFieldValue, errors } = useFormikContext<any>()
  const [max, setMax] = useState<number>()
  const [min, setMin] = useState<number>()
  const [errStart, setErrStart] = useState<string | null>('')
  const [errEnd, setErrEnd] = useState<string | null>('')
  const [errInterval, setErrInterval] = useState<string | null>('')
  const actualData = useMemo(() => {
    // console.log('RETURNING DATA DATA', data.data)
    // const a = data.data?.flat().filter(Number.isFinite).map(Math.abs) as number[]
    // a && console.log('MAXIMUS', a, Math.max(...a))
    // a && console.log('MINIMUS', a, Math.min(...a))
    return data.data
  }, [data])
  const maxActualData =
    actualData && actualData.length > 0 ? Math.max(...actualData.flat().filter(Number.isFinite).map(Math.abs)) : 0
  const minActualData =
    actualData && actualData.length > 0 ? Math.min(...actualData.flat().filter(Number.isFinite).map(Math.abs)) : 0

  const myarray2D = () => {
    const col = data.columnList?.length || 0
    const row = actualData ? actualData.length : interval.length

    const arr: any = []
    for (let i = 0; i < row; i++) {
      // Creates an empty line
      arr.push([])
      // Adds cols to the empty line:
      arr[i].push(new Array(col))
      for (let j = 0; j < col; j++) {
        // Initializes:
        arr[i][j] = null
      }
    }

    return arr
  }

  // useEffect(() => {
  //   console.log(errStart, errInterval, errEnd)
  // }, [errStart, errInterval, errEnd])

  const intervalMeasure = (start: any, end: any, interval: any) => {
    !start ? setErrStart('ระบุค่าเริ่มต้น') : setErrStart(null)
    !end ? setErrEnd('ระบุค่าสิ้นสุด') : setErrEnd(null)
    !interval || interval <= 0 || interval == '' ? setErrInterval('ระบุค่าระยะห่าง') : setErrInterval(null)
    start >= end ? setErrStart('ค่าเริ่มต้นต้องน้อยกว่าค่าสิ้นสุด') : setErrStart(null)

    if (
      (errEnd === '' || errEnd === null) &&
      (errStart === '' || errStart === null) &&
      (errInterval === '' || errInterval === null)
    ) {
      const arr = []
      let i = 0
      while (start + i * parseInt(interval) <= end) {
        arr.push(start + i * parseInt(interval))
        i++
      }
      setInterval(arr.reverse())
      return arr
    } else {
      return []
    }
  }

  useEffect(() => {
    if (actualData) {
      setFieldValue(`${formikPrefix}[${indexCard}][${indexData}].data`, actualData)
    } else {
      setFieldValue(`${formikPrefix}[${indexCard}][${indexData}].data`, myarray2D())
    }
  }, [data.columnList, interval.length])

  const job = getIn(values, 'job') as Job | undefined

  useEffect(() => {
    if (data.minTolerance) {
      setMin(parseUnpack(data.minTolerance, { userVariable: data.userVariable, job }))
    }

    if (data.maxTolerance) {
      setMax(parseUnpack(data.maxTolerance, { userVariable: data.userVariable, job }))
    }
  }, [data.minTolerance, data.maxTolerance, data.userVariable])

  const checkdStatus = () => {
    if (maxActualData > (max as number) || minActualData < (min as number)) {
      return 'Fail'
    } else {
      return 'Pass'
    }
  }

  return (
    <div className={styles.subCard}>
      <h4>{data.text}</h4>

      <TextInputComponent
        label="เริ่มต้น (ม)"
        name={`${formikPrefix}[${indexCard}][${indexData}].start`}
        validate={errStart}
        disabled={values.modeAction === 'approve'}
      />
      <TextInputComponent
        label="สิ้นสุด (ม)"
        name={`${formikPrefix}[${indexCard}][${indexData}].end`}
        validate={errEnd}
        disabled={values.modeAction === 'approve'}
      />
      <TextInputComponent
        label="ระยะห่าง (ม)"
        name={`${formikPrefix}[${indexCard}][${indexData}].interval`}
        validate={errInterval}
        disabled={values.modeAction === 'approve'}
      />

      {data.userVariable?.map((uv, uIndex) => (
        <TextInputComponent
          key={uIndex}
          label={uv.name}
          name={`${formikPrefix}[${indexCard}][${indexData}].userVariable[${uIndex}].value`}
          disabled={values.modeAction === 'approve'}
        />
      ))}

      <div className={styles.wrapperContainerButton}>
        กรุณาระบุเป็นหน่วย (mm)
        <div className={styles.wrapperButton}>
          <ButtonComponent
            _type={'button'}
            _colorBG={'orange'}
            _colorText={'white'}
            _variant={'text'}
            _functionOnClick={() => {
              intervalMeasure(data.start, data.end, data.interval)
            }}
            _text={actualData ? 'ดูตาราง' : 'คำนวณตาราง'}
          />
        </div>
      </div>

      {interval?.length > 0 && (
        <div className={styles.wrapperTable}>
          <div className={styles.box}>
            {interval &&
              interval?.map((_, idx: number) => (
                <div className={styles.wrapperInput} key={idx}>
                  <p className={styles.text}>{interval[idx]}</p>
                  {data?.columnList &&
                    data?.columnList.map((table: any, index: number) => (
                      <div
                        className={styles.boxInput}
                        key={index}
                        // aria-checked={
                        //   (data?.data as [])[idx][index] === getMax(data?.data) ||
                        //   (data?.data as [])[idx][index] === getMin(data?.data)
                        // }
                      >
                        <FormikInputText
                          name={`${formikPrefix}[${indexCard}][${indexData}].data[${idx}][${index}]`}
                          className={'inputLineBottom'}
                          type="number"
                          placeholder="ข้อความ"
                          readOnly={values.modeAction === 'approve'}
                        />
                      </div>
                    ))}
                </div>
              ))}

            <div className={styles.footerBox}>
              <p className={styles.text}>ระยะการวัด</p>
              {data.columnList &&
                data.columnList.map((table: any, index: number) => (
                  <div key={index} style={{ width: '100%', textAlign: 'center' }}>
                    {table}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {interval?.length > 0 && data.checkTolerance && (
        <div className={styles.bottomBox}>
          <div className={styles.groupInput}>
            <div className={styles.wrapBox}>
              <p className={styles.title}>ค่าเบี่ยงเบนสูงสุดที่ยอมรับได้ </p>
              <div className={styles.inputDisbles}>{typeof max === 'number' ? max : 0}</div>
            </div>
            <div className={styles.wrapBox}>
              <p className={styles.title}>ค่าเบี่ยงเบนสูงสุดที่วัดได้ </p>
              <div
                className={styles.inputDisbles}
                data-visible={maxActualData > (max as number)}
                aria-checked={maxActualData > (max as number)} // Check means RED
              >
                {maxActualData}
              </div>
            </div>
            <div className={styles.wrapBox}>
              <p className={styles.title}>ค่าเบี่ยงเบนต่ำสุดที่ยอมรับได้ </p>
              <div className={styles.inputDisbles}>{typeof min === 'number' ? min : 0}</div>
            </div>
            <div className={styles.wrapBox}>
              <p className={styles.title}>ค่าเบี่ยงเบนต่ำสุดที่ได้ </p>
              <div
                className={styles.inputDisbles}
                data-visible={minActualData < (min as number)}
                aria-checked={minActualData < (min as number)}
              >
                {minActualData}
              </div>
            </div>
            <div className={styles.wrapBox}>
              <p className={styles.title}>ผลการตรวจสอบ </p>
              <div className={styles.inputDisblesBT} aria-checked={checkdStatus() === 'Pass'}>
                <p>{checkdStatus()}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SectionMeasureUser

interface TextInputProps {
  label: string
  name: string
  validate?: string | null
  disabled?: boolean
}
const TextInputComponent = ({ label, name, validate, disabled }: TextInputProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.wrapperInput}>
        <p className={styles.text}>{label}</p>
        <FormikInputText
          name={name}
          className={validate ? 'inputLineBottomErr' : 'inputLineBottom'}
          type="number"
          placeholder="ข้อความ"
          readOnly={disabled}
        />
      </div>
      <p className={styles.textErr}>{validate ? validate : ''}</p>
    </div>
  )
}
