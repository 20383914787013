import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import uploadlogo from '../../image/uploadlogo.svg'
import filelogo from '../../image/filelogo.svg'
import showIdCard from './showCardId.svg'
import AddIcon from '@mui/icons-material/Add'
import { handleUploadFile } from 'utils/common'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { profile } from 'console'

const VerifyThirdPerson: React.FC = (): JSX.Element => {
  const { userId, username } = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const [idCardImage, setIdCardImage] = React.useState<any>('')

  return (
    <>
      <div className={styles.verifythirdContainer}>
        <section className={styles.imageUploadWrapper}>
          <div className={styles.imgCardId}>
            {values.selfiePersonalIDFileAttachment ? (
              <img className={styles.image} src={values.selfiePersonalIDFileAttachment} alt="idcard" />
            ) : (
              <img className={styles.image} src={showIdCard} alt="idcard" />
            )}
            <label htmlFor="selfiePersonalIDFileAttachment" className={styles.upLoadImage}>
              <AddIcon className={styles.addIcon} />
              <input
                type="file"
                id={'selfiePersonalIDFileAttachment'}
                style={{ display: 'none' }}
                accept=".pdf,.jpeg,.png"
                onChange={(e) => {
                  Promise.all(handleUploadFile(e.target.files, `Verification/${userId}/images/`)).then(
                    (selfiePersonalIDFileAttachment: any) => {
                      console.log(selfiePersonalIDFileAttachment, 'selfiePersonalIDFileAttachment')
                      setFieldValue('selfiePersonalIDFileAttachment', selfiePersonalIDFileAttachment[0])
                    },
                  )
                }}
              />
            </label>
          </div>
        </section>
        <section>
          <p className={styles.textTitle}>อัพโหลดไฟล์บัตรประชาชน </p>
          <input
            id={'personalIDFileAttachment'}
            type="file"
            accept=".pdf,.jpeg,.png"
            onChange={(e) => {
              Promise.all(handleUploadFile(e.target.files, `Verification/${userId}/file/`)).then(
                (personalIDFileAttachment: any) => {
                  console.log('personalIDFileAttachment==>', personalIDFileAttachment)
                  setFieldValue('personalIDFileAttachment', personalIDFileAttachment[0])
                },
              )
            }}
            // multiple
            style={{ display: 'none' }}
          />
          {values.personalIDFileAttachment ? (
            <p>
              <label className={styles.labelshowfile} htmlFor="personalIDFileAttachment">
                <img className={styles.filelogo} src={filelogo} alt="filelogo" />
                <b>{values.personalIDFileAttachment}</b>
              </label>
            </p>
          ) : (
            <p className="margin-Bottom15px">
              <label className={styles.labelfile} htmlFor="personalIDFileAttachment">
                <span className={styles.primaryuploadspan}>
                  <img className={styles.uploadlogo} src={uploadlogo} alt="uploadlogo" />
                  Upload File
                </span>
                <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
              </label>
            </p>
          )}
        </section>
        <br />
      </div>
    </>
  )
}
export default VerifyThirdPerson
