import * as React from 'react'
import styles from './index.module.scss'
//svg Image
import FileTOR from '../../image/file.svg'
import ImagesSlideShow from 'components/imagesSlideShow'
import { downloadFile } from '../../utils/common'
import TechLogo from '../../image/tech.svg'
import { useParams } from 'react-router-dom'

interface Icard {
  title?: string
  projectName?: string
  companyName?: string
  fileAttachmentList?: string[]
  // startDate?: string
  // endDate?: string
  description?: string
  workTypePostList?: string[]
  imageURLList?: string[]
  createdAt?: string
  jobUnitPrice?: number
  buttonHeaderList?: any
  minprice?: any
  maxprice?: any
  fileTOR?: any
  acceptUserId?: string[]
  jobApplication?: any
  post?: IDate
  project?: IDate
}

interface IDate {
  startDate?: string
  endDate?: string
}
const eRegionArr: any = {
  BKK: 'กรุงเทพ',
  CR: 'ภาคกลาง',
  ER: 'ภาคตะวันออก',
  NER: 'ภาคตะวันออกเฉียงเหนือ',
  WR: 'ภาคตะวันตก',
  SR: 'ภาคใต้',
}

const CardComponent: React.FC<Icard> = (props: Icard & any) => {
  // console.log('UNIQ  ==>', props.workTypePostList)
  const filename = (file: string) => decodeURI(file.split('/')[file.split('/').length - 1])
  const { customerOrTechnician } = useParams()

  // console.log(
  //   props.jobApplication &&
  //     props.jobApplication
  //       .map((jobAppElement: any) => {
  //         return jobAppElement.workTypeJobApplicationList.map((workTypeJobApp: any) => {
  //           if (workTypeJobApp.status === 'PENDING') {
  //             return 1
  //           } else {
  //             return 0
  //           }
  //         })
  //       })
  //       .flat()
  //       .reduce((prev: number, curr: number) => prev + curr),
  //   'pingggg',
  // )
  // )
  return (
    <div className={styles.center}>
      <div className={styles.cardArea}>
        <div className={styles.cardNews}>
          <div className={styles.header}>
            <div className={styles.headZone}>
              <section className={styles.titleText}>
                {' '}
                <p className={styles.headtext}>{props.projectName}</p>
                <p className={styles.posttext}>{props.title}</p>
              </section>

              {/* nut */}
              {customerOrTechnician === 'customer' && (
                <div className={styles.countUser}>
                  <img className={styles.countItem2} src={TechLogo} alt="user" />
                  <p className={styles.countItem}>
                    {props.jobApplication?.length &&
                      props.jobApplication
                        .map((jobAppElement: any) => {
                          return jobAppElement.workTypeJobApplicationList.map((workTypeJobApp: any) => {
                            if (workTypeJobApp.status === 'PENDING') {
                              return 1
                            } else {
                              return 0
                            }
                          })
                        })
                        .flat()
                        .reduce((prev: number, curr: number) => prev + curr)}
                    {/* (wait backend) */}
                  </p>
                </div>
              )}
            </div>
            <div className={styles.detail}>
              <div className={styles.companyName}>{props.companyName}</div>
              <div className={styles.rigthead}>
                {' '}
                {props.minprice && props.maxprice ? (
                  <p className={styles.subtext}>
                    {' '}
                    <span className={styles.titletext}>ราคา</span>
                    {props.minprice} - {props.maxprice}
                  </p>
                ) : (
                  <p className={styles.subtext}>
                    {' '}
                    <span className={styles.titletext}>ไม่ระบุจำนวนเงิน</span>
                  </p>
                )}
              </div>
            </div>

            <div className={styles.detail}>
              <div className={styles.file}>
                {props.fileTOR === '' && ''}
                {props.fileTOR && (
                  <>
                    <p className={styles.texthead}>เอกสารแนบโครงการ</p>
                    <div className={styles.filebox}>
                      <img src={FileTOR} alt="" />
                      {/* FIXED DOWNLOAD FILE BY FUNCTION downloadFile WITH LIBRARY js-file-download*/}
                      {/* IF FILE IS NOT S3 TYPE IT CANT DOWNLOAD */}
                      <div
                        className={styles.filetext}
                        onClick={() => downloadFile(props.fileTOR, filename(props.fileTOR))}
                      >
                        <p>{filename(props.fileTOR)}</p>
                      </div>
                    </div>
                  </>
                )}
                <p className={styles.subtext}>
                  <span className={styles.titletext}>ระยะเวลาโครงการ</span>
                  {props.project.startDate} - {props.project.endDate}
                </p>
              </div>

              <div className={styles.rigthead}>
                <p className={styles.subtext}>
                  {' '}
                  <span className={styles.titletext}>ระยะเวลาประกาศงาน</span>
                  {props.post.startDate} - {props.post.endDate}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.line}></div>

          <div className={styles.twosides}>
            <div className={styles.descript}>
              <div>
                <p className={styles.textdes}>รายละเอียด</p>
                <p>{props.description}</p>
              </div>

              <div className={styles.workbox}>
                {props.workTypePostList.map((workType: any, workTypeIndex: number) => (
                  <div key={workTypeIndex} className={styles.textwork}>
                    <p>{workType.workType}</p>
                    <span className={styles.textsub}>
                      {workType.totalJobList &&
                        workType.totalJobList
                          .map((joblist: any) => joblist.totalQty)
                          .reduce((a: any, b: any) => a + b, 0)}
                      <p className={styles.worktext}>งาน</p>
                    </span>
                  </div>
                ))}
              </div>

              <div className={styles.detail}>
                {props.fileAttachmentList && (
                  <div className={styles.file}>
                    <p className={styles.texthead}>เอกสารแนบการประกาศ</p>{' '}
                    {props.fileAttachmentList?.map((file: any, fileIndex: number) => {
                      return (
                        <div key={fileIndex} className={styles.filebox}>
                          <img src={FileTOR} alt="" />
                          {/* FIXED DOWNLOAD FILE BY FUNCTION downloadFile WITH LIBRARY js-file-download*/}
                          {/* IF FILE IS NOT S3 TYPE IT CANT DOWNLOAD */}
                          <div className={styles.filetext} onClick={() => downloadFile(file, filename(file))}>
                            <p>{filename(file)}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.imageside}>
              <div className={styles.imgshow}>
                {' '}
                <ImagesSlideShow imageList={props.imageURLList} />
              </div>
            </div>
          </div>
          <div className={styles.bottomtext}>
            <div className={styles.tRegion}>
              {/* <p className={styles.headtext}>ภูมิภาค</p>
              {props.workTypePostList &&
                props.workTypePostList.map((item: any) =>
                  item.totalJobList.map((joblist: any, index: number) => (
                    <div key={index}>{joblist.fieldValue}</div>
                    // <div key={index}>{joblist.fieldValue}</div>
                  )),
                )} */}
            </div>
            <div className={styles.tdatecreate}>
              <p className={styles.textdate}>
                {new Date(props.createdAt).toLocaleDateString('th-TH', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardComponent
