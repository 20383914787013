import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { THAILAND_REGION2CODE_MAPPER } from 'components/commons/thailandFact'

const colName = (n: number): string => {
  const ordA = 'A'.charCodeAt(0)
  const ordZ = 'Z'.charCodeAt(0)
  const len = ordZ - ordA + 1

  let s = ''
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s
    n = Math.floor(n / len) - 1
  }
  return s
}

const columnNameGenerator = (n: number): string[] => {
  if (n < 1) return []

  const col: string[] = []

  for (let i = 0; i < n; i++) {
    col.push(colName(i))
  }

  return col
}

export const exportIssueExcel = async (workType: string, data: any[]) => {
  const workbook = new ExcelJS.Workbook()

  const sheet = workbook.addWorksheet('Form response 1')

  const topicFont = { name: 'Calibri', size: 11, bold: false, color: { argb: 'FFFFFF' } }

  /**
   * colums -> Group: region, region: region, Site Code: siteCode, Site Name: siteName, เลขที่: number, Installed Date: ??, Issue Topic: ??, Issue Description, Messenger: ??, Required Action, By when, By whom, Status (RAG), Timestamp
   */

  sheet.columns = [
    { key: 'group', width: 20 },
    { key: 'region', width: 20 },
    { key: 'siteCode', width: 20 },
    { key: 'siteName', width: 20 },
    { key: 'number', width: 20 },
    { key: 'installedDate', width: 20 },
    { key: 'issueTopic', width: 20 },
    { key: 'issueDescription', width: 20 },
    { key: 'messenger', width: 20 },
    { key: 'rquiredAction', width: 20 },
    { key: 'byWhen', width: 20 },
    { key: 'byWhom', width: 20 },
    { key: 'rag', width: 20 },
    { key: 'timestamp', width: 20 },
  ]

  const columnKeys: any = {
    group: 'region',
    region: 'region',
    siteCode: 'siteCode',
    siteName: 'siteName',
    number: 'number',
    issueTopic: 'topic',
    issueDescription: 'description',
    messenger: 'messenger',
    installedDate: 'actualDate',
  }

  const columns = [
    'Group',
    'region',
    'Site Code',
    'Site Name',
    'เลขที่',
    'Installed Date',
    'Issue Topic',
    'Issue Description',
    'Messenger',
    'Required Action',
    'By when',
    'By whom',
    'Status (RAG)',
    'Timestamp',
  ]

  const alphabets = columnNameGenerator(columns.length)

  const currentDate = new Date()

  // set sheet properties
  sheet.properties.defaultRowHeight = 30

  // title
  sheet.getCell('A1').value = `${workType} ${currentDate}`
  sheet.getCell('A1').font = {
    size: 20,
    bold: true,
  }

  const groupBy: Record<string, any[]> = {}

  const newData: any[] = []

  //  Map data to Column key
  data.forEach((e) => {
    if (e.issueList) {
      e.issueList.forEach((issue: any) => {
        newData.push({
          ...e,
          region: THAILAND_REGION2CODE_MAPPER[e.region!],
          topic: issue.topic,
          description: issue.description,
          messenger: issue.getUser.username,
        })
      })
      console.log('ASDF', e.changTumId, e.issueList.length)
    }
    // else {
    //   newData.push({ ...e, region: THAILAND_REGION2CODE_MAPPER[e.region!] })
    // }
  })

  // group by region
  newData.forEach((e) => {
    if (groupBy[e.region] === undefined) groupBy[e.region] = []
    groupBy[e.region].push(e)
  })
  for (const [region, issue] of Object.entries(groupBy)) {
    console.log('SSS', region, issue.length)
  }

  // start row
  let rowFlag = 2

  // insert row data
  Object.entries(groupBy).forEach(([groupKey, groups]) => {
    // insert topic
    sheet.columns.forEach((_, cindex) => {
      const row = sheet.getCell(`${alphabets[cindex]}${rowFlag}`)
      row.value = columns[cindex]
      row.font = topicFont
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: cindex < 9 ? 'FF8206' : '000000',
        },
      }
      row.style.alignment = { horizontal: 'center', vertical: 'middle' }
      row.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
    })
    rowFlag++

    // loop inside group data
    groups.forEach((e) => {
      sheet.columns.forEach((column, cindex) => {
        const key = columnKeys[column.key!]
        const row = sheet.getCell(`${alphabets[cindex]}${rowFlag}`)
        row.value = e[key] as any
        row.style.alignment = { vertical: 'middle' }
        row.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
      })
      rowFlag++
    })
  })

  // export file
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const fileName = `${workType} ${currentDate}`

  const buffer = await workbook.xlsx.writeBuffer()

  const excelToSave = new Blob([buffer], { type: fileType })

  saveAs(excelToSave, fileName + fileExtension)
}
