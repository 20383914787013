import React, { useState, useEffect, useMemo } from 'react'
import AntennaRenderComponent, { isCircle } from 'components/AntennaRender'
import { FormikInputText } from 'components/commons/input/formikInputText'
import { FormikInputSelect, IOption } from 'components/commons/input/formikInputSelect'
import { getIn, useFormikContext } from 'formik'
import { IValues } from 'pages/patUpload'
import cn from 'classnames'
import styles from './index.module.scss'
import {
  Tower_Type,
  AntennaPoleInput,
  AntennaPlaneInput,
  ColorGroup,
  Antenna_Type,
  Antenna,
  AntennaInput,
  ColorGroupInput,
} from 'utils/generated'
import addButtonSVG from 'image/plusCircleBlack.svg'
import addButtonWhiteSVG from 'image/plusCircleWhite.svg'
import AntennaModal, { ColorGroupModal, ColorGroupSubset } from './modal'
import binSVG from 'image/binRed.svg'
import duplicatePNG from 'image/pat/duplicate.png'
import { IJob } from 'adapter/xhr'
import { FormikInputRadio } from 'components/commons/input/formikInputRadio'

interface AntennaComponentProps {
  modeAction: string
  formikPrefix: string
  fieldname: string
  poleType: Tower_Type
  jobInformation: IJob
}
const AntennaComponent = ({ modeAction, formikPrefix, fieldname, poleType, jobInformation }: AntennaComponentProps) => {
  const { values, setFieldValue } = useFormikContext<IValues>()
  // Use this because nesting arguments to child doesn't update!!
  const value = getIn(values, `${formikPrefix}[${fieldname}]`) as AntennaPoleInput[] | undefined
  useEffect(() => {
    if (value === undefined || value === null) {
      // console.log('SETTING EMPTY POLE')
      setFieldValue(`${formikPrefix}[${fieldname}]`, [{ ...EMPTY_POLE }])
    }
  }, [value])
  // console.log('This is antenna component ', formikPrefix, fieldname, poleType, value)

  // useEffect(() => {
  //   console.log('PARSE ', parse(`@Diameter = 0.1016; $height = 1;  90 + ARCTAN(@Diameter /2/$height) * 180/3.14`))
  // }, [])
  if (!value) return <></>
  return (
    <>
      {value.length > 0 &&
        value.map((pole, poleIndex) => (
          <AntennaPoleComponent
            key={poleIndex}
            modeAction={modeAction}
            formikPrefix={`${formikPrefix}[${fieldname}]`}
            fieldname={poleIndex}
            poleType={poleType}
            jobInformation={jobInformation}
          />
        ))}
    </>
  )
}

interface AntennaPoleComponentProps {
  modeAction: string
  formikPrefix: string
  fieldname: number // This is the index
  poleType: Tower_Type
  jobInformation: IJob
}
const AntennaPoleComponent = ({
  modeAction,
  formikPrefix,
  fieldname,
  poleType,
  jobInformation,
}: AntennaPoleComponentProps) => {
  const { values, setFieldValue } = useFormikContext<IValues>()
  // Use this because nesting arguments to child doesn't update!!
  const poleList = getIn(values, `${formikPrefix}`) as AntennaPoleInput[] | undefined
  if (!poleList) return <></>
  const pole = poleList[fieldname]

  const addPole = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    poleList.splice(fieldname + 1, 0, { ...EMPTY_POLE })
    setFieldValue(`${formikPrefix}`, poleList)
  }
  const removePole = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (fieldname !== 0) {
      poleList.splice(fieldname, 1)
      setFieldValue(`${formikPrefix}`, poleList)
    }
  }

  return (
    <div className={styles.antennaPole}>
      <TextInputComponent label="ชื่อเสา" name={`${formikPrefix}[${fieldname}].name`} />
      <div className={styles.wrapperInput}>
        <p className={styles.text}>Tower Size</p>
        <FormikInputSelect
          // className={styles.inputSelect}
          className={'inputLineBottom'}
          name={`${formikPrefix}[${fieldname}].poleSize`}
          option={poleSizeOption}
          defaultValue={''}
        />
      </div>
      {jobInformation?.towerType === Tower_Type.Selfsupport ? (
        <div className={styles.wrapperInput}>
          <p className={styles.text}>กรุณาเลือกขา</p>
          <p>
            <FormikInputRadio
              // className={'inputLineBottom'}
              // defaultValue={false}
              listRadio={[
                {
                  text: '4 ขา',
                  value: false,
                  name: `${formikPrefix}['${fieldname}'].hasLegs`,
                },
                {
                  text: '3 ขา',
                  value: true,
                  name: `${formikPrefix}['${fieldname}'].hasLegs`,
                },
              ]}
            />
          </p>
        </div>
      ) : null}
      {pole.planeList &&
        pole.planeList.map((plane, planeIndex) => (
          <AntennaPlaneComponent
            key={planeIndex}
            modeAction={modeAction}
            formikPrefix={`${formikPrefix}[${fieldname}].planeList`}
            fieldname={planeIndex}
            poleType={poleType}
            hasLegs={!!pole.hasLegs}
          />
        ))}
      <div className={styles.poleFooter}>
        <button className={cn(styles.button, styles.red)} onClick={removePole}>
          <img src={binSVG} />
          ลบเสา
        </button>
        {fieldname === poleList.length - 1 && (
          <button className={styles.addPoleButton} onClick={addPole}>
            <img src={addButtonWhiteSVG} />
            เพิ่มเสา
          </button>
        )}
      </div>
    </div>
  )
}

interface AntennaPlaneComponentProps {
  modeAction: string
  formikPrefix: string
  fieldname: number // This is the index
  poleType: Tower_Type
  hasLegs: boolean
}
const AntennaPlaneComponent = ({
  modeAction,
  formikPrefix,
  fieldname,
  poleType,
  hasLegs,
}: AntennaPlaneComponentProps) => {
  const [tab, setTab] = useState<'antenna' | 'other'>('antenna')
  const [openModal, setOpenModal] = React.useState(false)
  const [openModalAnt, setOpenModalAnt] = React.useState(false)
  const { values, setFieldValue } = useFormikContext<IValues>()
  // Use this because nesting arguments to child doesn't update!!
  const antennaPlaneList = getIn(values, `${formikPrefix}`) as AntennaPlaneInput[] | undefined
  if (!antennaPlaneList) return <></>
  const antennaPlane = antennaPlaneList[fieldname]

  const [selectedColorGroup, setSelectedColorGroup] = useState<ColorGroup | ColorGroupSubset>({ id: 1, color: '#FFF' })

  const setColorGroup = (colorGroup: ColorGroupInput) => {
    // Check index if it is add or edit!
    const colorGroupList = antennaPlane[tab]?.colorGroup || []
    const colorGroupIndex = colorGroupList.findIndex((cg) => cg.id === colorGroup.id)
    if (colorGroupIndex >= 0) {
      console.log('COLOR set ', `${formikPrefix}[${fieldname}].${tab}.colorGroup[${colorGroupIndex}]`, colorGroup)
      setFieldValue(`${formikPrefix}[${fieldname}].${tab}.colorGroup[${colorGroupIndex}]`, colorGroup)
    } else {
      const newColorGroupList = [...colorGroupList, colorGroup]
      console.log('COLOR set ', `${formikPrefix}[${fieldname}].${tab}.colorGroup`, newColorGroupList)
      setFieldValue(`${formikPrefix}[${fieldname}].${tab}.colorGroup`, newColorGroupList)
    }
  }
  const setAntenna = (antenna: AntennaInput) => {
    // console.log('SET FIELD VALUE ', `${formikPrefix}[${fieldname}].${tab}`)
    setFieldValue(`${formikPrefix}[${fieldname}].${tab}`, antenna)
  }
  const addPlane = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    antennaPlaneList.splice(fieldname + 1, 0, { ...EMPTY_PLANE })
    setFieldValue(`${formikPrefix}`, antennaPlaneList)
  }
  const removePlane = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (fieldname !== 0) {
      antennaPlaneList.splice(fieldname, 1)
      setFieldValue(`${formikPrefix}`, antennaPlaneList)
    }
  }
  const duplicatePlane = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    antennaPlaneList.splice(fieldname + 1, 0, JSON.parse(JSON.stringify(antennaPlane)) as AntennaPlaneInput)
    setFieldValue(`${formikPrefix}`, antennaPlaneList)
  }
  // Remove the selectedColorGroup
  // 1. Remove Color Group From the Color Group
  // 2. Remove any color Group in A,B,C,D
  const removeColorGroup = () => {
    const idToRemove = selectedColorGroup.id

    // 1. Remove Color Group From the Color Group
    const colorGroupList = antennaPlane[tab]?.colorGroup || []
    const colorGroupIndex = colorGroupList.findIndex((cg) => cg.id === idToRemove)
    colorGroupList.splice(colorGroupIndex, 1)
    setFieldValue(`${formikPrefix}[${fieldname}].${tab}.colorGroup`, colorGroupList)
    // 2. Remove any color Group in A,B,C,D
    const A = antennaPlane[tab]?.A || []
    const AFilter = A.filter((x) => x !== idToRemove)
    if (A.length !== AFilter.length) setFieldValue(`${formikPrefix}[${fieldname}].${tab}.A`, AFilter)
    const B = antennaPlane[tab]?.B || []
    const BFilter = B.filter((x) => x !== idToRemove)
    if (B.length !== BFilter.length) setFieldValue(`${formikPrefix}[${fieldname}].${tab}.B`, BFilter)
    const C = antennaPlane[tab]?.C || []
    const CFilter = C.filter((x) => x !== idToRemove)
    if (C.length !== CFilter.length) setFieldValue(`${formikPrefix}[${fieldname}].${tab}.C`, CFilter)
    const D = antennaPlane[tab]?.D || []
    const DFilter = D.filter((x) => x !== idToRemove)
    if (D.length !== DFilter.length) setFieldValue(`${formikPrefix}[${fieldname}].${tab}.D`, DFilter)
  }

  return (
    <div className={styles.antennaPlane}>
      {/* Antenna / Other Tabs */}
      <div className={styles.tabWrapper}>
        <button className={cn(styles.tab, { [styles.active]: tab === 'antenna' })} onClick={() => setTab('antenna')}>
          Antenna
        </button>
        <button className={cn(styles.tab, { [styles.active]: tab === 'other' })} onClick={() => setTab('other')}>
          Other
        </button>
        {/* Justify self is not supported in flexbox because it justifies all items as a group
        https://stackoverflow.com/questions/49658425/flexbox-justify-self-flex-end-not-working */}
        <h2 style={{ alignSelf: 'flex-end', marginLeft: 'auto' }}>Plane {fieldname + 1}</h2>
      </div>
      {/* Actual Plane */}
      <article className={styles.planeCard}>
        {/* Pic and ColorGroup */}
        <div className={styles.planeContent}>
          <div className="f-split">
            <div className="dp-flex" style={{ justifyContent: 'space-between' }}>
              <h3>ต่ำแหน่งติดตั้ง Antenna</h3>
              <button className={cn(styles.button, styles.blue)} onClick={() => setOpenModalAnt(true)}>
                แก้ไข
              </button>
            </div>
            {antennaPlane && antennaPlane[tab] && (
              <AntennaRenderComponent poleType={poleType} hasLegs={hasLegs} antenna={antennaPlane[tab]!} />
            )}
            {/* Input Fields */}
            <div className="dp-flex">
              <div className="f-basis50">
                <span>ติดตั้งที่ระดับความสูง (m)</span>
                <FormikInputText
                  name={`${formikPrefix}[${fieldname}].height`}
                  type="number"
                  onChange={(e) => {
                    setFieldValue(`${formikPrefix}[${fieldname}].height`, e.target.value, false)
                  }}
                  onBlur={() => {
                    const value = parseFloat((antennaPlane.height as never as string) || '0')
                    setFieldValue(`${formikPrefix}[${fieldname}].height`, value, false)
                  }}
                />
              </div>
              <div className="f-basis50">
                <span>Mounting</span>
                <FormikInputText
                  name={`${formikPrefix}[${fieldname}].mounting`}
                  type="number"
                  onChange={(e) => {
                    setFieldValue(`${formikPrefix}[${fieldname}].mounting`, e.target.value, false)
                  }}
                  onBlur={() => {
                    const value = parseInt((antennaPlane.mounting as never as string) || '0')
                    setFieldValue(`${formikPrefix}[${fieldname}].mounting`, value, false)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="f-split">
            <div className="dp-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <h3>Color Group Antenna</h3>
              <button
                className={cn(styles.button, styles.bigImg)}
                onClick={() => {
                  const maxID = Math.max(...(antennaPlane[tab]?.colorGroup || []).map((cg) => cg.id))
                  const setID = Number.isFinite(maxID) ? maxID + 1 : 0 // If it doesn't have anything, this will be -Infinity!
                  setOpenModal(true)
                  setSelectedColorGroup({ id: setID, color: getColorFromIndex(setID) })
                }}
              >
                <img src={addButtonSVG} />
              </button>
            </div>

            <hr />
            <ColorGroupComponent
              colorGroupList={antennaPlane[tab]?.colorGroup || []}
              allAntenna={flattenAntenna(antennaPlane[tab])}
              setSelectedColorGroup={setSelectedColorGroup}
              setOpenModal={setOpenModal}
            />
          </div>
        </div>
        {/* Footer of Card */}
        <div className={styles.planeFooter}>
          <button className={cn(styles.button, styles.red)} onClick={(e) => removePlane(e)}>
            <img src={binSVG} />
            ลบ Plane
          </button>
          <button className={styles.button} onClick={(e) => addPlane(e)}>
            <img src={addButtonSVG} />
            เพิ่ม Plane
          </button>
          <button className={styles.button} onClick={(e) => duplicatePlane(e)}>
            <img src={duplicatePNG} />
            ทำซ้ำ
          </button>
        </div>
      </article>
      <ColorGroupModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        colorGroup={selectedColorGroup}
        setColorGroup={setColorGroup}
        removeColorGroup={removeColorGroup}
      />
      <AntennaModal
        openModal={openModalAnt}
        poleType={poleType}
        hasLegs={hasLegs}
        setOpenModal={setOpenModalAnt}
        antenna={antennaPlane[tab]}
        setAntenna={setAntenna}
      />
    </div>
  )
}

const EMPTY_PLANE: AntennaPlaneInput = {
  height: undefined,
  mounting: undefined,
  antenna: {
    colorGroup: [
      // { id: 1, color: '#FF00FF', antennaType: Antenna_Type.Cellular, w: 12, l: 34, d: 34 },
      // { id: 2, color: '#FFDDFF', antennaType: Antenna_Type.Parabolic, dia: 34 },
    ],
    // A: [1, 1, 1, 1, 2, 2, 2],
    // B: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    // C: [1, 2],
    A: [],
    B: [],
    C: [],
    D: [],
  },
  other: {
    colorGroup: [],
    A: [],
    B: [],
    C: [],
    D: [],
  },
}
const EMPTY_POLE = {
  name: 'PoleName',
  hasLegs: false, // Used only for Steel Pipe! render Image with or without Legs
  planeList: [{ ...EMPTY_PLANE }],
}

export default AntennaComponent

interface ColorGroupProps {
  colorGroupList: ColorGroup[]
  allAntenna: number[]
  setSelectedColorGroup: React.Dispatch<React.SetStateAction<ColorGroup | ColorGroupSubset>>
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
const ColorGroupComponent = ({ colorGroupList, allAntenna, setSelectedColorGroup, setOpenModal }: ColorGroupProps) => {
  return (
    <div className={styles.colorGroupGrid}>
      <div className={styles.cell}>Type</div>
      <div className={styles.cell}>Area</div>
      <div className={styles.cell}>No</div>
      <div className={styles.cell}>Total Area</div>
      {colorGroupList.map((cg, cgIndex) => {
        const area = calculateArea(cg)
        const number = sumType(allAntenna, cg.id)
        const totalArea = area * number
        return (
          <React.Fragment key={cgIndex}>
            <div
              className={cn('dp-flex', styles.cell)}
              style={{
                gap: '0.5rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                // console.log('set selected color group ', colorGroupList[cgIndex])
                setSelectedColorGroup(colorGroupList[cgIndex])
                setOpenModal(true)
              }}
            >
              <span
                className={cn(styles.colorGroupBox, {
                  [styles.circle]: isCircle(colorGroupList[cgIndex].antennaType),
                  [styles.grid]: colorGroupList[cgIndex].antennaType === Antenna_Type.ParabolicGrid,
                })}
                style={{
                  backgroundColor: cg.color,
                }}
              />
              <span
                style={{
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {cg.antennaType}
              </span>
            </div>
            <div className={styles.cell}>{area.toFixed(2)}</div>
            <div className={styles.cell}>{number}</div>
            <div className={styles.cell}>{totalArea.toFixed(2)}</div>
          </React.Fragment>
        )
      })}
      <div style={{ gridColumn: '1/-1' }}>
        <hr />
      </div>
      <div style={{ gridColumn: '1/-2', padding: '0.25rem 0.2rem' }}>
        <b>Grand Total</b>
      </div>
      <div className={styles.cell}>
        <b>{sumGrandTotalArea(allAntenna, colorGroupList).toFixed(2)}</b>
      </div>
    </div>
  )
}

export const flattenAntenna = (antenna: Antenna | null | undefined) => {
  if (!antenna) return []
  const combineAll: number[] = []
  combineAll.push(...(antenna.A || []), ...(antenna.B || []), ...(antenna.C || []), ...(antenna.D || []))
  return combineAll.flat()
}

export const calculateArea = (cg: ColorGroup) => {
  if (cg.antennaType === Antenna_Type.Parabolic || cg.antennaType === Antenna_Type.ParabolicGrid)
    return (3.14 * cg.dia! ** 2) / 4
  return (cg.w || 0) * (cg.l || 0)
}
export const sumType = (allAntenna: number[], id: number) => allAntenna.filter((a) => a === id).length

export const sumGrandTotalArea = (allAntenna: number[], colorGroupList: ColorGroup[]) => {
  const colorMap: Record<string, number> = {}
  colorGroupList.forEach((cg) => (colorMap[cg.id] = calculateArea(cg)))
  return allAntenna.reduce((prev, curr) => prev + colorMap[curr], 0)
}

const getColorFromIndex = (index: number) => COLOR_RANDOM[index % COLOR_RANDOM.length]

const COLOR_RANDOM = [
  '#DB504A',
  '#E3B505',
  '#56A3A6',
  '#084C61',
  '#9A348E',
  '#D8B4E2',
  '#90AA86',
  '#D62828',
  '#B4436C',
  '#14591D',
]

const poleSizeOption: IOption[] = [
  { text: '', value: '' },
  { text: 'GOG26', value: 'GOG26' },
  { text: 'GOG35', value: 'GOG35' },
  { text: 'GOG42', value: 'GOG42' },
  { text: 'GOG20(10-30m)', value: 'GOG20(10-30m)' },
  { text: 'GOG20(10-25m)', value: 'GOG20(10-25m)' },
  { text: 'GOG26(10-25m)', value: 'GOG26(10-25m)' },
]

interface TextInputProps {
  label: string
  name: string
}
const TextInputComponent = ({ label, name }: TextInputProps) => {
  return (
    <div className={styles.wrapperInput}>
      <p className={styles.text}>{label}</p>
      <FormikInputText name={name} className={'inputLineBottom'} placeholder="ข้อความ" />
    </div>
  )
}
