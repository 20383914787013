import testAction from './test'
import modalConfirmAction from './modalConfirm'
import modalAction from './modal'
import loaderSpinnerAction from './loaderSpinner'

const allAction = {
  testAction,
  modalAction,
  modalConfirmAction,
  loaderSpinnerAction,
}

export default allAction
