import React, { useState, useEffect } from 'react'

import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { getPATTemplatesByUserId } from 'adapter/createProjectPageAdapter'
import { IOption } from 'components/commons/input/formikInputSelect'
import pluscircle from './pluscircle.svg'
import {
  CELL_TYPE,
  ITableColumn,
  jobStatusColorMapper,
  JOB_STATUS_MAPPER,
  patStatusColorMapper,
  PAT_STATUS_MAPPER,
} from 'components/ChungTumTable'
import { getUser } from 'adapter/xhr'
import { THAILAND_PROVINCE2REGION_MAPPER, THAILAND_REGION_MAPPER } from 'components/commons/thailandFact'
import { IFormikValues } from '..'
import { useFormikContext } from 'formik'

const useStateInitializer = (): IStateInitializer => {
  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const { values } = useFormikContext<IFormikValues>()

  const [patTemplateOptionList, setPatTemplateOptionList] = useState<IOption[]>([])
  const [tableColumns, setTableColumns] = useState<ITableColumn[]>(DEFAULT_COLUMNS)

  // Populate WorkType Column Options
  useEffect(() => {
    if (values.workTypeList && values.workTypeList.length > 0) {
      const workTypeListElementReference = tableColumns.find((e) => e.name === 'workType')
      if (workTypeListElementReference) {
        workTypeListElementReference.optionList = [{ value: '', text: 'เลือก -ประเภทงาน-' }]
        values.workTypeList.map((workType) => {
          workTypeListElementReference.optionList?.push({
            value: workType.workType,
            text: workType.workType,
          })
        })
      }
      setTableColumns(tableColumns)
    }
  }, [values.workTypeList.length, values.workTypeList?.map((workTypeElement) => workTypeElement.workType).join(',')])

  // Get Pat Template By UserID
  useEffect(() => {
    const userId = decodedAccessToken.userId
    if (userId)
      getPATTemplatesByUserId(userId)
        .then((res) => {
          const patDataList = res.data.data?.patByUserId
          const optionData = patDataList?.map((e: any) => {
            return { value: e.patId, text: e.name, src: null, alt: null }
          })
          optionData.push({
            value: 'pat',
            text: '+สร้าง PAT ใหม่',
            opclass: styles.opclass,
            src: pluscircle,
            alt: 'pluscircle',
          })
          setPatTemplateOptionList(optionData)
        })
        .catch((err) => {
          console.log(err, 'err')
        })
  }, [decodedAccessToken.userId])

  // Table Columns Friend options
  useEffect(() => {
    const userId = decodedAccessToken.userId
    // if (values)
    // getUser()
    //   .then((res) => {
    const userObject = values.acceptedUserIdList
    // const userObject = res.data.data?.getUser

    const acceptedUserIdElementReference = tableColumns.find((e) => e.name === 'acceptedUserId')

    if (acceptedUserIdElementReference) {
      acceptedUserIdElementReference.optionList = [{ value: '', text: 'เลือก -ช่าง-' }]
      // userObject?.friendList?.map((friendElement: any) => {
      userObject?.map((acceptedUserElement: any) => {
        acceptedUserIdElementReference.optionList?.push({
          value: acceptedUserElement.getUser.userId,
          text: acceptedUserElement.getUser.username,
        })
      })
    }
    setTableColumns(tableColumns)
    // })
    // .catch((err) => {
    //   console.log(err, 'err')
    // })
    // }, [decodedAccessToken.userId])
  }, [values])

  useEffect(() => {
    const dropDownfieldValue = tableColumns?.find((e) => e.name === 'fieldValue')

    if (dropDownfieldValue) {
      const allowedValueList = values.customFieldList?.find((e) => e.name === values.editFieldID)?.allowedValueList
      // console.log(allowedValueList, 'allowedValueList')
      dropDownfieldValue.optionList = [{ value: '', text: 'เลือก - กลุ่มประเภทงาน-' }]
      if (allowedValueList) {
        allowedValueList?.map((e) =>
          dropDownfieldValue.optionList?.push({
            value: e,
            text: e,
          }),
        )
      }
      setTableColumns(tableColumns)
    }
  }, [values.editFieldID])

  return [patTemplateOptionList, tableColumns]
}
export default useStateInitializer

type IStateInitializer = [IOption[], ITableColumn[]]

const DEFAULT_COLUMNS: ITableColumn[] = [
  {
    name: 'index',
    text: '#',
    engText: 'No',
    columnSize: '30px',
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteCode',
    text: '(รหัสไซต์)',
    engText: 'Site Code',
    columnSize: '58px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'siteName',
    text: '(ชื่อไซต์)',
    engText: 'Site Name',
    columnSize: '90px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'number',
    text: '(เลขที่)',
    engText: 'Number',
    columnSize: '40px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'workType',
    text: '(ประเภทงาน)',
    engText: 'Work Type',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_DROPDOWN,
    optionList: [{ value: '', text: 'เลือก -ประเภทงาน-' }],
    defaultOption: '',
  },
  {
    name: 'region',
    text: '(ภูมิภาค)',
    engText: 'Region',
    columnSize: '60px',
    type: CELL_TYPE.INPUT_DROPDOWN,
    optionList: [
      { value: '', text: 'เลือก -ภูมิภาค-' },
      ...Object.keys(THAILAND_REGION_MAPPER).map((regionCode) => ({
        value: THAILAND_REGION_MAPPER[regionCode],
        text: regionCode,
      })),
    ],
    defaultOption: '',
  },
  {
    name: 'fieldValue',
    text: '(ประเภทงาน)',
    engText: 'CustomAttribute',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_DROPDOWN,
    optionList: [{ value: '', text: 'เลือก - กลุ่มประเภทงาน-' }],
    defaultOption: '',
  },
  {
    name: 'province',
    text: '(จังหวัด)',
    engText: 'Province',
    columnSize: '80px',
    type: CELL_TYPE.INPUT_DROPDOWN,
    optionList: [
      { value: '', text: 'เลือก -จังหวัด-' },
      ...Object.keys(THAILAND_PROVINCE2REGION_MAPPER).map((provinceName) => ({
        value: provinceName,
        text: provinceName,
      })),
    ],
    defaultOption: '',
  },
  {
    name: 'district',
    text: '(อำเภอ)',
    engText: 'District',
    columnSize: '80px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'lat',
    text: '(แลตติจูด)',
    engText: 'Latitude',
    columnSize: '70px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'long',
    text: '(ลองติจูด)',
    engText: 'Longitude',
    columnSize: '70px',
    type: CELL_TYPE.INPUT_TEXT,
  },

  {
    name: 'zone',
    text: '(โซน)',
    engText: 'Zone',
    columnSize: '40px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'changTumId',
    text: '(ช่างทำไอดี)',
    engText: 'ChangTumID',
    columnSize: '88px',
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'jobId',
    text: '(รหัสงาน)',
    engText: 'JobID',
    columnSize: '80px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'remark',
    text: '(หมายเหตุ)',
    engText: 'Remark',
    columnSize: '130px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'status',
    text: '(สถานะ)',
    engText: 'Status',
    columnSize: '60px',
    readOnly: true,
    mapper: (data) => JOB_STATUS_MAPPER[data],
    colorMapper: jobStatusColorMapper,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'acceptedUserId',
    text: '(ช่างรับงาน)',
    engText: 'Technician',
    columnSize: '100px',
    type: CELL_TYPE.INPUT_DROPDOWN,
    optionList: [{ value: '', text: 'เลือก -ช่าง-' }],
    defaultOption: '',
  },
  {
    name: 'patStatus',
    text: '(สถานะเอกสาร PAT)',
    engText: 'PAT Status',
    columnSize: '110px',
    readOnly: true,
    mapper: (data) => PAT_STATUS_MAPPER[data],
    colorMapper: patStatusColorMapper,
    type: CELL_TYPE.TEXT,
  },
  // {
  //   name: 'contractor',
  //   text: '(ผู้รับเหมา)',
  //   engText: 'Contractor',
  //   columnSize: '80px',
  //   type: CELL_TYPE.INPUT_TEXT,
  // },
  // {
  //   name: 'contractorDetail',
  //   text: '(รายละเอียดผู้รับเหมา)',
  //   engText: 'Contractor Detail',
  //   columnSize: '80px',
  //   type: CELL_TYPE.INPUT_TEXT,
  // },
  {
    name: 'locationID',
    text: 'location ID',
    engText: 'location ID',
    columnSize: '80px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'manufacturer',
    text: 'บริษัทผู้ผลิตเสา',
    engText: 'บริษัทผู้ผลิตเสา',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'installer',
    text: 'บริษัทผู้ติดตั้งเสา',
    engText: 'บริษัทผู้ติดตั้งเสา',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'room',
    text: 'Equipment Room',
    engText: 'Equipment Room',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'towerType',
    text: 'TowerType',
    engText: 'TowerType',
    columnSize: '80px',
    type: CELL_TYPE.INPUT_DROPDOWN,
    optionList: [
      { value: undefined, text: 'เลือก -Tower Type-' },
      { value: 'GUYED', text: 'Guyed' },
      { value: 'SELFSUPPORT', text: 'Self Support' },
      { value: 'STEELPIPE', text: 'Steel Pipe' },
      { value: 'MONOPOLE', text: 'Mono Pole' },
      { value: 'CONCRETEPOLE', text: 'Concrete Pole' },
    ],
    defaultOption: '',
  },
  {
    name: 'towerSubType',
    text: 'TowerSubType',
    engText: 'TowerSubType',
    columnSize: '80px',
    type: CELL_TYPE.INPUT_DROPDOWN,
    optionList: [
      { value: undefined, text: 'เลือก -Tower SubType-' },
      { value: 'ONGROUND', text: 'On Ground' },
      { value: 'ONROOF', text: 'On Roof' },
      { value: 'S', text: 'S' },
      { value: 'LL', text: 'LL' },
      { value: 'A*', text: 'A*' },
      { value: 'A', text: 'A' },
      { value: 'B', text: 'B' },
      { value: 'C', text: 'C' },
      { value: 'N', text: 'N' },
      { value: 'UL', text: 'UL' },
      { value: 'ULS', text: 'ULS' },
      { value: 'T*', text: 'T*' },
      { value: 'EP', text: 'EP' },
    ],
    defaultOption: '',
  },
  {
    name: 'height',
    text: 'ความสูง (m)',
    engText: 'ความสูง (m)',
    columnSize: '80px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'siteArea',
    text: 'ขนาด Site (m)',
    engText: 'ขนาด Site (m)',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'dimension',
    text: 'ขนาด (กว้างxยาว)',
    engText: 'ขนาด (กว้างxยาว)',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'gateWidth',
    text: 'ความกว้างประตูรั้ว',
    engText: 'ความกว้างประตูรั้ว',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  {
    name: 'manufacturerDate',
    text: 'Manufacture date',
    engText: 'Manufacture date',
    columnSize: '110px',
    type: CELL_TYPE.INPUT_TEXT,
  },
  // {
  //   name: 'contractor',
  //   text: '(ผู้รับเหมา)',
  //   engText: 'Contractor',
  //   columnSize: '110px',
  //   type: CELL_TYPE.INPUT_DROPDOWN,
  //   optionList: [{ value: '', text: 'เลือก -ผู้รับเหมา-' }],
  //   defaultOption: '',
  // },
]
