import React from 'react'

const FooterComponant: React.FC<{ listButtons: IListButton[] }> = ({
  listButtons,
}: {
  listButtons: IListButton[]
}): JSX.Element => {
  return (
    <footer className="btnGroupFooter">
      {listButtons.map((data: IListButton, index: number) => (
        <button
          key={index}
          type={data.type}
          className={data.class}
          disabled={data.disabled ? data.disabled : false}
          onClick={(e) => data.fucnctionOnclick?.(e)}
        >
          {data.text}
        </button>
      ))}
    </footer>
  )
}

export interface IListButton {
  fucnctionOnclick?: null | ((e: any) => void)
  text?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  class?: string
  disabled?: boolean
}

export default FooterComponant
