enum EOpenLoaderSpinner {
  SET_OPEN_LOADER_SPINNER = 'SET_OPEN_LOADER_SPINNER',
  SET_CLOSE_LOADER_SPINNER = 'SET_CLOSE_LOADER_SPINNER',
}

interface IAction {
  type: EOpenLoaderSpinner
}
export interface IInitialStateLoaderSpinner {
  openLoaderSpinner: boolean
}

const initialState: IInitialStateLoaderSpinner = {
  openLoaderSpinner: false,
}

const reducer = (state = initialState, action: IAction): any => {
  switch (action.type) {
    case EOpenLoaderSpinner.SET_OPEN_LOADER_SPINNER:
      return {
        ...state,
        openLoaderSpinner: true,
      }
    case EOpenLoaderSpinner.SET_CLOSE_LOADER_SPINNER:
      return {
        ...state,
        openLoaderSpinner: false,
      }
    default:
      return state
  }
}

export default reducer
