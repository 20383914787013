import React, { useState } from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import {
  THAILAND_REGION,
  THAILAND_PROVINCE_MAPPER,
  THAILAND_REGION_MAPPER,
} from '../../components/commons/thailandFact'
import { GetListSafetyInTeam, getUserInTeam, getUserProfile } from 'adapter/xhr'
import CreateMyTeam from './createteam'
import CreateMyTeamSecond from './createteamsecond'
import VerifyTeam from './verify'
import { ICombineReducers } from 'state/reducers'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ModalSkip from './modalskip'

const CreateTeam: React.FC = (): JSX.Element => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const [creatTeamstep, setCreatTeamstep] = useState('crtOne')
  const [mode, setMode] = React.useState<'create' | 'update'>('create')
  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const { userId } = useParams<any>()

  React.useEffect(() => {
    const userId = decodedAccessToken.userId
    if (userId) {
      console.log(userId, 'id')
      setMode('update')
    } else {
      setMode('create')
    }
  }, [])
  React.useEffect(() => {
    if (userId) {
      getUserInTeam({ userId: userId }).then((Teamlistres: any) => {
        console.log('Get Profile', userId)
        console.log('Get Profile', Teamlistres)
        setFieldValue('Teamget', Teamlistres.data.data.getUserInTeam)
        const getTeamList = Teamlistres.data.data.getUserInTeam
        const UpdateProfileTeam: any = {
          userId: getTeamList.userId,
          firstName: getTeamList.firstName,
          lastName: getTeamList.lastName,
          birthDate: getTeamList.birthDate,
          nickName: getTeamList.nickName,
          gender: getTeamList.gender,
          personalRelation: getTeamList.personalRelation,
          personalID: getTeamList.personalID,
          taxID: getTeamList.taxID,
          zipCode: getTeamList.zipCode,
          province: getTeamList.province,
          district: getTeamList.district,
          subDistrict: getTeamList.subDistrict,
          addressDetail: getTeamList.addressDetail,
          phone: getTeamList.phone,
          email: getTeamList.email,
          technicianJobType: getTeamList.technicianJobType,
          serviceProvince: getTeamList.serviceProvince,
          serviceRegion: getTeamList.serviceRegion,
          isSafetyPassport: getTeamList.isSafetyPassport,
        }

        if (getTeamList.emergencyContact) {
          UpdateProfileTeam.emergencyContact = {
            firstName: getTeamList.emergencyContact.firstName,
            lastName: getTeamList.emergencyContact.lastName,
            relation: getTeamList.emergencyContact.relation,
            phone: getTeamList.emergencyContact.phone,
          }
        } else {
          UpdateProfileTeam.emergencyContact = {
            firstName: '',
            lastName: '',
            relation: '',
            phone: '',
          }
        }

        console.log(Object.entries(UpdateProfileTeam), 'object entries')
        setFieldValue('userteam', UpdateProfileTeam)

        //Verify
        // setFieldValue('safetyPassportType', getTeamList.safetyPassport?.safetyPassportType)
        // setFieldValue('status', getTeamList.userVerification?.status)

        setFieldValue('selfiePersonalIDFileAttachment', getTeamList.userVerification?.selfiePersonalIDFileAttachment)
        setFieldValue('personalIDFileAttachment', getTeamList.userVerification?.personalIDFileAttachment)
      })
      console.log(userId, 'id')
      setMode('update')
    } else {
      setMode('create')
    }

    QueryNewSafetyInTeam()
  }, [])

  const QueryNewSafetyInTeam = () => {
    GetListSafetyInTeam({ userId: userId }).then((teamsafetyres: any) => {
      // if (userId) {
      const unpackingListSafetyPassport: any = {}
      teamsafetyres?.data.data.listSafetyPassportByUserInTeam?.map((safetyPassportElement: any) => {
        if (safetyPassportElement.safetyPassportFileAttachment) {
          unpackingListSafetyPassport[`${safetyPassportElement.safetyPassportType}`] = {
            safetyPassportFileAttachment: safetyPassportElement.safetyPassportFileAttachment,
            safetyPassportStatus: safetyPassportElement.safetyPassportStatus,
          }
        } else {
          unpackingListSafetyPassport[`${safetyPassportElement.safetyPassportType}`] = {
            safetyPassportFileAttachment: '',
            safetyPassportStatus: safetyPassportElement.safetyPassportStatus,
          }
        }
      })
      setFieldValue(`safetyPassport`, unpackingListSafetyPassport)
      // }
      // setFieldValue('safetypassTeam', teamsafetyres.data.data.listSafetyPassportByUserInTeam)
      console.log(teamsafetyres, 'API')
    })
  }
  return (
    <>
      <div>
        {' '}
        <div className={styles.container}>
          {creatTeamstep === 'crtOne' ? (
            <CreateMyTeam setCreatTeamstep={setCreatTeamstep} />
          ) : creatTeamstep === 'crtSecond' ? (
            <CreateMyTeamSecond setCreatTeamstep={setCreatTeamstep} />
          ) : creatTeamstep === 'crtThird' ? (
            <VerifyTeam setCreatTeamstep={setCreatTeamstep} />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

const EnhancedCreateTeam = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    birthDate: '',
    nickName: '',
    personalID: '',
    gender: 'MALE',
    personalRelation: 'SINGLE',
    zipCode: '',
    province: '',
    phone: '',
    email: '',
    emergencyContact: {
      firstName: '',
      lastName: '',
      relation: '',
      phone: '',
    },
    // district: '',
    // subDistrict: '',
    // addressDetail: '',
    technicianJobType: [],
    technicianWorkType: [
      {
        value: 'Electrician',
        text: 'ช่างไฟฟ้า',
      },
      {
        value: 'Painter',
        text: 'ช่างสี',
      },
      {
        value: 'Plumber',
        text: 'ช่างประปา',
      },
      {
        value: 'Plasterer',
        text: 'ช่างปูน',
      },
      {
        value: 'Metalworker',
        text: 'ช่างเหล็ก',
      },
      {
        value: 'Carpenter',
        text: 'ช่างไม้',
      },
      {
        value: 'Mechanic',
        text: 'ช่างยนต์',
      },
      {
        value: 'Installer',
        text: 'ช่างติดตั้ง',
      },
    ],
    isSafetyPassport: '',
    safetyPassport: {
      safetyPassportStatus: '',
      safetyPassportType: '',
      safetyPassportFileAttachment: '',
    },
    safetyPassportOption: [
      {
        value: 'AIS_G',
        text: 'AIS [G]',
      },
      {
        value: 'AIS_E',
        text: 'AIS [E]',
      },
      {
        value: 'AIS_EE',
        text: 'AIS [EE]',
      },
      {
        value: 'AIS_H',
        text: 'AIS [H]',
      },
      {
        value: 'AIS_C',
        text: 'AIS [C]',
      },
    ],

    serviceRegion: [],
    optionRegion: [
      ...Object.entries(THAILAND_REGION_MAPPER).map((region: any) => ({
        value: region[0],
        text: region[1],
      })),
    ],
    serviceProvince: [],
    optionProvince: [
      ...Object.entries(THAILAND_PROVINCE_MAPPER).map((region: any) => ({
        value: region[1],
        text: region[0],
      })),
    ],
    userVerification: {
      personalIDFileAttachment: '',
      selfiePersonalIDFileAttachment: '',
    },
  }),
  validate: (values) => {
    const errors: any = {}

    return errors
  },
  handleSubmit: (values, { setFieldValue }) => {
    //
    console.log(values)
    console.log(JSON.stringify(values))
  },
})(CreateTeam)

export default EnhancedCreateTeam
