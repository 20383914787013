import React, { useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { TextField } from '@fluentui/react'
import facebookpic from '../logincomponent/facebooklogo.svg'
import googlepic from '../logincomponent/googlelogo.svg'
import Fillcomponent from './fillcomponent'
import Verifynumbercomponent from './verifycomponent'

const Phonelogincomponent: React.FC<any> = ({ setComponentStatus }: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const [loginComponentStatus, setloginComponentStatus] = useState('fill')
  return (
    <>
      {loginComponentStatus === 'fill' ? (
        <Fillcomponent setComponentStatus={setComponentStatus} setloginComponentStatus={setloginComponentStatus} />
      ) : loginComponentStatus === 'verify' ? (
        <Verifynumbercomponent setloginComponentStatus={setloginComponentStatus} />
      ) : null}
    </>
  )
}

export default Phonelogincomponent
