import React, { useState, useEffect } from 'react'
import styles from './tableComponent.module.scss'

import {
  THAILAND_REGION,
  THAILAND_REGION_MAPPER,
  THAILAND_REGION2PROVINCE_MAPPER,
  THAILAND_PROVINCE,
  THAILAND_REGION2CODE_MAPPER,
} from '../commons/thailandFact'
// import { ITotalJobPlanOrActual, ITotalJobProvince, ITotalJobRegion, ITotalWeekQty } from './useStateInitializer'
import { IWeekElement, getWeeksInYear, filterWeekElement } from 'utils/week'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { IMasterPlanCellParamData } from 'pages/projectmanagementpage/secondcontent/MasterPlanModal'

const TableComponent: React.FC<ITableComponent> = ({
  year,
  isDisabledDropdown,
  tableOption,
  FieldName,
  month,
  isDisabledInput,
  theme,
  tableTopic,
  onMasterPlanCellClick,
}: ITableComponent) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const MONTH_FORWARD = 4
  const [tableColumns, setTableColumns] = useState<IWeekElement[]>(
    getWeeksInYear(year).filter((weekElement: any) => filterWeekElement(weekElement, year, month, MONTH_FORWARD)),
  )

  // If year, tableColumns
  useEffect(() => {
    setTableColumns(
      getWeeksInYear(year).filter((e) => {
        return filterWeekElement(e, year, month, MONTH_FORWARD)
      }),
    )
  }, [year, month])

  const sumPlusArrayNumberNoneZero = (allArrayNumber: number[], arrayInputNumber: number) => {
    let sumNumber = 0
    if (allArrayNumber[arrayInputNumber - 1] !== 0) {
      sumNumber = allArrayNumber.splice(0, arrayInputNumber).reduce((prev: number, curr: number) => prev + curr)
      return sumNumber
    }
  }

  return (
    <>
      <div className={styles.tableHighestWrapper}>
        <div
          className={`${styles.tableHeaderBar} ${
            theme === 'masterplan'
              ? styles.themeMasterPlanPrimary
              : theme === 'plan'
              ? styles.themePlanPrimary
              : theme === 'installed'
              ? styles.themeInstalledPrimary
              : ''
          }`}
        >
          {tableTopic}
        </div>
        {/* Separate between Header and Content */}
        <div className={styles.tableWrapper}>
          {/* Header */}
          <div className={styles.tableHeader}>
            {/* Left Content */}
            <div className={styles.leftHeaders}>
              <div
                className={`${styles.headerBox} ${styles.dropdownHeader}  ${
                  theme === 'masterplan'
                    ? styles.themeMasterPlanPrimary
                    : theme === 'plan'
                    ? styles.themePlanSecondary
                    : theme === 'installed'
                    ? styles.themeInstalledSecondary
                    : ''
                }`}
              >
                <FormikInputSelect
                  className={`${styles.dropDownInput} ${
                    theme === 'masterplan'
                      ? styles.themeMasterPlanPrimary
                      : theme === 'plan'
                      ? styles.themePlanSecondary
                      : theme === 'installed'
                      ? styles.themeInstalledSecondary
                      : ''
                  }`}
                  name={`${FieldName}.fieldID`}
                  option={tableOption && [...tableOption]}
                  disabled={isDisabledDropdown && isDisabledDropdown}
                  onChange={(events) => {
                    const findingObj = values.fieldList.find((field: any) => field.name === events.target.value)
                    // console.log(values.unpackproject, 'valuesssss')
                    const groupFieldList: any = []
                    if (findingObj) {
                      findingObj.allowedValueList.map((allowedValues: string) => {
                        groupFieldList.push({
                          fieldValue: allowedValues,
                          workTypeList: values.unpackproject.workTypeList.map((workTypeElement: any) => {
                            return { workType: workTypeElement.workType, workTypeTable: {} }
                          }),
                          // [
                          //   {
                          //     workType: '',
                          //   },
                          // ],
                        })
                      })
                    }
                    console.log(groupFieldList, 'groupFieldList')
                    setFieldValue(`${FieldName}.fieldID`, events.target.value)
                    // setFieldValue(`${FieldName}.selectTechnician`, 'ALL')
                    setFieldValue(`${FieldName}.groupFieldList`, groupFieldList)
                  }}
                  defaultValue={''}
                />
                {/* Region */}
              </div>
              <div
                className={`${styles.headerBox}  ${
                  theme === 'masterplan'
                    ? styles.themeMasterPlanPrimary
                    : theme === 'plan'
                    ? styles.themePlanSecondary
                    : theme === 'installed'
                    ? styles.themeInstalledSecondary
                    : ''
                } ${styles.scopeHeader}`}
              >
                Scope
              </div>
              <div
                className={`${styles.headerBox}  ${
                  theme === 'masterplan'
                    ? styles.themeMasterPlanPrimary
                    : theme === 'plan'
                    ? styles.themePlanSecondary
                    : theme === 'installed'
                    ? styles.themeInstalledSecondary
                    : ''
                } ${styles.sumHeader}`}
              >
                รวมงานทั้งหมด
              </div>
            </div>
            {/* Right Content */}
            <div
              className={styles.rightHeaders}
              style={{ gridTemplateColumns: `${tableColumns.map((tableColumn) => '1fr').join(' ')}` }}
            >
              {/* The Month at the Top, calculated from the number of months (unique changing month values of weekElements) */}
              {[
                ...Array(
                  tableColumns.reduce((monthCount, weekElement, weekIndex, arr) => {
                    if (weekIndex === 0) return monthCount
                    if (weekElement.month !== arr[weekIndex - 1].month) return monthCount + 1
                    return monthCount
                  }, 1),
                ).keys(),
              ].map((monthIndex) => (
                <div
                  className={`${styles.headerBox}  ${
                    theme === 'masterplan'
                      ? styles.themeMasterPlanPrimary
                      : theme === 'plan'
                      ? styles.themePlanSecondary
                      : theme === 'installed'
                      ? styles.themeInstalledSecondary
                      : ''
                  }`}
                  key={monthIndex}
                  // Note that dis may fail if you don't have 1 element !!
                  style={{
                    gridColumn: `auto / span ${
                      tableColumns.filter(
                        // Modulo is quicker by 1 since it will be 0 at 12, not 12 at 12. I -1 inside and +1 outside to make it 0 at 1
                        (weekElement) => weekElement.month === ((tableColumns[0].month + monthIndex - 1) % 12) + 1,
                      ).length
                    }`,
                  }}
                >
                  {new Date(year, tableColumns[0].month + monthIndex - 1, 1).toLocaleDateString('th-TH', {
                    month: 'short',
                  })}
                </div>
              ))}
              {/* The week at the bottom */}
              {tableColumns.map((weekElement, weekIndex) => (
                <div
                  key={weekIndex}
                  className={`${styles.headerBox}  ${
                    theme === 'masterplan'
                      ? styles.themeMasterPlanPrimary
                      : theme === 'plan'
                      ? styles.themePlanSecondary
                      : theme === 'installed'
                      ? styles.themeInstalledSecondary
                      : ''
                  } ${styles.weekHeader}`}
                >
                  W{weekElement.weekNo}
                </div>
              ))}
            </div>
          </div>
          {/* ContentWrapper: Separate between Left Labels and Right Content */}
          <div className={styles.contentWrapper}>
            {/* Region and (maybe) Province */}
            <div className={styles.leftLabels}>
              {values[`${FieldName}`].groupFieldList &&
                values[`${FieldName}`].groupFieldList.map((groupField: any, groupFieldIndex: number) => {
                  //  There will be 2/4 Sections: The Region / (maybe Province) / (maybe Province Qty) / RegionQty
                  return (
                    <div
                      className={`${styles.leftLabelWrapper}  ${
                        theme === 'masterplan'
                          ? styles.themeMasterPlanSecondary
                          : theme === 'plan'
                          ? styles.themePlanSecondary
                          : theme === 'installed'
                          ? styles.themeInstalledSecondary
                          : ''
                      }`}
                      key={groupFieldIndex}
                    >
                      {/* <div style={{ width: '50px', backgroundColor: 'yellow' }}>Plan Date</div> */}
                      {/* The Region Label */}
                      <div className={styles.regionLabel}>
                        <p className={styles.regionText}>{groupField.fieldValue}</p>
                      </div>
                      {/* The Province (Maybe) */}
                      <div className={styles.provinceLabelWrapper}>
                        {groupField.workTypeList.map((workTypeObj: any, workTypeIndex: number) => {
                          return (
                            <>
                              <div
                                className={styles.provinceName}
                                style={
                                  groupFieldIndex % 2 === 0 && workTypeIndex % 2 !== 0
                                    ? { backgroundColor: '#DFDFDF' }
                                    : groupFieldIndex % 2 !== 0 && workTypeIndex % 2 === 0
                                    ? { backgroundColor: '#DFDFDF' }
                                    : {}
                                }
                              >
                                {workTypeObj.workType}
                              </div>
                              <div
                                className={styles.provinceQty}
                                style={
                                  groupFieldIndex % 2 === 0 && workTypeIndex % 2 !== 0
                                    ? { backgroundColor: '#DFDFDF' }
                                    : groupFieldIndex % 2 !== 0 && workTypeIndex % 2 === 0
                                    ? { backgroundColor: '#DFDFDF' }
                                    : {}
                                }
                              >
                                {workTypeObj.workTypeTable &&
                                  Object.values(workTypeObj.workTypeTable).length !== 0 &&
                                  Object.values(workTypeObj.workTypeTable)
                                    .map((numberValue: number | any) => {
                                      if (numberValue) {
                                        return numberValue
                                      } else {
                                        return 0
                                      }
                                    })
                                    .reduce((prev: number, curr: number) => prev + curr)}
                              </div>
                            </>
                          )
                        })}
                      </div>
                      {/* The Region Qty */}
                      {/* <div className={styles.regionQty}>{totalJobRegion[regionCode]}</div> */}
                    </div>
                  )
                })}
              {/* Label of the weekly Report */}
              <div className={styles.weeklyReportLabel}>
                <div
                  className={`${styles.sumHeader} ${
                    theme === 'masterplan'
                      ? styles.themeMasterPlanPrimary
                      : theme === 'plan'
                      ? styles.themePlanPrimary
                      : theme === 'installed'
                      ? styles.themeInstalledPrimary
                      : ''
                  }`}
                >
                  รวมทั้งหมด
                </div>
                <div
                  className={`${styles.planContainer} ${
                    theme === 'masterplan'
                      ? styles.themeMasterPlanPrimary
                      : theme === 'plan'
                      ? styles.themePlanPrimary
                      : theme === 'installed'
                      ? styles.themeInstalledPrimary
                      : ''
                  }`}
                >
                  {theme === 'installed' ? <div>Install per week</div> : <div>Plan per week</div>}
                  <div>Plan Acc</div>
                </div>
                <div
                  className={`${styles.planSumTotal} ${
                    theme === 'masterplan'
                      ? styles.themeMasterPlanPrimary
                      : theme === 'plan'
                      ? styles.themePlanPrimary
                      : theme === 'installed'
                      ? styles.themeInstalledPrimary
                      : ''
                  }`}
                >
                  <div className={styles.sumValueTable}>
                    {values[`${FieldName}`].groupFieldList
                      .map((item: any) => {
                        return item.workTypeList
                          .map((itemTable: any) => {
                            return Object.values(itemTable.workTypeTable)
                              .map((ValueWork: any) => {
                                return ValueWork
                              })
                              .reduce((prev: number, curr: number) => {
                                return prev + curr
                              }, 0)
                          })
                          .reduce((prev: number, curr: number) => prev + curr)
                      })
                      .reduce((prev: number, curr: number) => prev + curr)}
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
            {/* Right Grid Content: Row as region & province..  Column as Week */}
            <div
              className={styles.rightGridWrapper}
              style={{ gridTemplateColumns: `${tableColumns.map((tableColumn) => '1fr').join(' ')}` }}
            >
              {/* 3 For loops: REGION/PROVINCE/WEEK */}
              {values[`${FieldName}`].groupFieldList &&
                values[`${FieldName}`].groupFieldList.map((groupField: any, groupFieldIndex: number) => {
                  return groupField.workTypeList.map((workTypeObj: any, workTypeIndex: number) => {
                    return (
                      <>
                        {tableColumns.map((tableColumn, weekIndex) => {
                          /** if values.masterPlan.selectTechnician == "all" then Open Modal
                           * else show input
                           */

                          const shouldShowMasterPlanModal =
                            values.masterPlan.selectTechnician === 'ALL' ||
                            values.masterPlan.selectTechnician === null ||
                            values.masterPlan.selectTechnician === undefined

                          return (
                            <input
                              // key={`${regionIndex}-${provinceIndex}-${weekIndex}`}
                              onClick={() => {
                                if (!shouldShowMasterPlanModal) return

                                // console.log('pankorn', { groupFieldIndex, workTypeIndex, values })

                                onMasterPlanCellClick?.call(this, {
                                  weekNo: tableColumn.weekNo,
                                  cellData: workTypeObj.workTypeTable[`week_${tableColumn.weekNo}`],
                                  projectData: {
                                    workType: workTypeObj.workType,
                                    region: groupField.fieldValue,
                                    groupFieldIndex,
                                    workTypeIndex,
                                    projectId: values.projectSelectedId,
                                    acceptedUserIdList: values.acceptedUserIdList,
                                    planningYear: values.masterPlan.planningYear,
                                  },
                                })
                              }}
                              readOnly={shouldShowMasterPlanModal}
                              type="number"
                              min={0}
                              disabled={isDisabledInput && isDisabledInput}
                              onKeyPress={(event: any) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                                if (event.target.value === '0' && event.key === '0') {
                                  event.preventDefault()
                                }
                              }}
                              key={`${weekIndex}`}
                              value={
                                workTypeObj.workTypeTable[`week_${tableColumn.weekNo}`]
                                  ? workTypeObj.workTypeTable[`week_${tableColumn.weekNo}`]
                                  : ''
                              }
                              className={styles.box}
                              style={
                                groupFieldIndex % 2 === 0 && workTypeIndex % 2 !== 0
                                  ? { backgroundColor: '#DFDFDF' }
                                  : groupFieldIndex % 2 !== 0 && workTypeIndex % 2 === 0
                                  ? { backgroundColor: '#DFDFDF' }
                                  : {}
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  `${FieldName}.groupFieldList[${groupFieldIndex}].workTypeList[${workTypeIndex}].workTypeTable[week_${tableColumn.weekNo}]`,
                                  parseInt(e.target.value),
                                )
                              }}
                            ></input>
                          )
                        })}
                      </>
                    )
                  })
                })}
              {tableColumns.map((tableColumn, weekIndex) => {
                return (
                  <div
                    key={`${weekIndex}`}
                    className={`${styles.planPerWeekBox} ${
                      theme === 'masterplan'
                        ? styles.themeMasterPlanPrimary
                        : theme === 'plan'
                        ? styles.themePlanPrimary
                        : theme === 'installed'
                        ? styles.themeInstalledPrimary
                        : ''
                    }`}
                  >
                    {values[`${FieldName}`].groupFieldList.length &&
                      values[`${FieldName}`].groupFieldList
                        .map((groupField: any) => {
                          return groupField.workTypeList.map((workType: any) => {
                            if (workType.workTypeTable[`week_${tableColumn.weekNo}`]) {
                              return workType.workTypeTable[`week_${tableColumn.weekNo}`]
                            } else {
                              return 0
                            }
                            // return workType.workTypeTable[`week_${tableColumn.weekNo}`]
                          })
                        })
                        .flat(1)
                        .reduce((prev: number, curr: number) => prev + curr)}
                  </div>
                )
              })}
              {tableColumns.map((tableColumn, weekIndex) => {
                return (
                  <div
                    key={`${weekIndex}`}
                    className={`${styles.planPerWeekBox} ${
                      theme === 'masterplan'
                        ? styles.themeMasterPlanPrimary
                        : theme === 'plan'
                        ? styles.themePlanPrimary
                        : theme === 'installed'
                        ? styles.themeInstalledPrimary
                        : ''
                    }`}
                    // style={{ backgroundColor: regionIndex % 2 === 0 ? '' : '#e5d9c0' }}
                  >
                    {sumPlusArrayNumberNoneZero(
                      getWeeksInYear(year).map((weekElement: any, weekIndex: number) => {
                        if (values[`${FieldName}`].groupFieldList.length) {
                          return values[`${FieldName}`].groupFieldList
                            .map((groupField: any) => {
                              return groupField.workTypeList.map((workType: any) => {
                                if (workType.workTypeTable[`week_${weekElement.weekNo}`]) {
                                  return workType.workTypeTable[`week_${weekElement.weekNo}`]
                                } else {
                                  return 0
                                }
                              })
                            })
                            .flat(1)
                            .reduce((prev: number, curr: number) => prev + curr)
                        } else {
                          return 0
                        }
                      }),
                      tableColumn.weekNo,
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TableComponent

interface ITableComponent {
  year: number
  month: number
  isDisabledDropdown?: boolean
  tableOption?: ITableOption[] | any
  FieldName?: string
  isDisabledInput?: boolean
  theme?: 'masterplan' | 'plan' | 'installed'
  tableTopic?: string
  onMasterPlanCellClick?: (data: IMasterPlanCellParamData) => any
}

interface ITableOption {
  text: string
  value: string
}
