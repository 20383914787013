import React, { useEffect } from 'react'

import { ICombineReducers } from 'state/reducers'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

const useRoleProtection = () => {
  const isLoggedIn = useSelector((state: ICombineReducers) => state.tokenReducer.isLoggedIn)
  const role = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken.role)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    const customerOrTechnician = pathname.split('/')[1]
    const rightPath = pathname.split('/').slice(2)
    if (isLoggedIn && role && customerOrTechnician !== role) {
      console.error(`Deny access to other than role! Redirecting to /${role}/${rightPath.join('/')}`)
      // console.log('customerOrTechnician ====>', customerOrTechnician)
      console.log('customerOrTechnician ====>', pathname.split('/')[1])
      console.log('role ====>', role)
      navigate(`/${role}/${rightPath.join('/')}`)
    }
  }, [isLoggedIn, role, pathname])
}

export default useRoleProtection
