import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useFormikContext } from 'formik'

const TableReportComponent: React.FC<ITableComponent> = (props: ITableComponent & any) => {
  const { values } = useFormikContext<any>()

  const FindObj = values.unpackproject.customFieldList.find(
    (findObj: any) => findObj.name === values.unpackproject.configReportFieldID,
  )

  const EStatusType: any = {
    PROJECT_ORDER: 'Project Order',
    INSTALLED: 'Installed',
    WAIT_FILL: 'รอการดำเนินการ',
    WAIT_APPROVAL: 'PAT รอตรวจสอบ',
    ACCEPT: 'PAT ผ่าน',
    REJECT: 'PAT รอการแก้ไข',
  }

  const EStatusSubmit: any = {
    ACCEPT: 'PAT ผ่าน',
    WAIT_APPROVAL: 'PAT รอตรวจสอบ',
    REJECT: 'PAT รอการแก้ไข',
  }

  return (
    <div>
      {' '}
      {values.patstatus && (
        <div className={styles.tableHighestWrapper}>
          <table className={styles.TableReportStatus}>
            <th>No</th>
            <th>รายการสถานะเอกสาร PAT</th>
            {values.unpackproject.configReportFieldID
              ? values.unpackproject.configReportFieldID.length &&
                FindObj.allowedValueList.length &&
                FindObj.allowedValueList.map((tableItems: any, tableHeaderIndex: number) => (
                  <th key={tableHeaderIndex}>{tableItems}</th>
                ))
              : null}
            {console.log(values.unpackproject, 'values.unpackproject.configReportFieldID')}
            <th>รวมทั้งหมด</th>
            {Object.keys(EStatusType).map((status: any, statusIndex: number) => (
              <tr key={statusIndex} className={styles.rowTable}>
                <td className={status === 'PROJECT_ORDER' ? styles.total : ''}>{statusIndex + 1}</td>
                <td className={status === 'PROJECT_ORDER' ? styles.total : ''}>{EStatusType[`${status}`]}</td>
                {values.unpackproject.configReportFieldID
                  ? values.unpackproject.configReportFieldID.length &&
                    FindObj.allowedValueList.length &&
                    FindObj.allowedValueList.map((tableItems: any, tableHeaderIndex: number) => (
                      <td className={status === 'PROJECT_ORDER' ? styles.total : ''} key={tableHeaderIndex}>
                        {values.patstatus.find((patElement: any) => patElement.PATStatusType === status)?.fieldValue[
                          `${tableItems}`
                        ]
                          ? values.patstatus.find((patElement: any) => patElement.PATStatusType === status)?.fieldValue[
                              `${tableItems}`
                            ]
                          : 0}
                      </td>
                    ))
                  : null}
                <td className={status === 'PROJECT_ORDER' ? styles.total : ''}>
                  {values.unpackproject.configReportFieldID
                    ? values.unpackproject.configReportFieldID.length &&
                      FindObj.allowedValueList.length &&
                      FindObj.allowedValueList
                        .map((tableItems: any) => {
                          if (
                            values.patstatus.find((patElement: any) => patElement.PATStatusType === status)?.fieldValue[
                              `${tableItems}`
                            ]
                          ) {
                            return values.patstatus.find((patElement: any) => patElement.PATStatusType === status)
                              ?.fieldValue[`${tableItems}`]
                          } else {
                            return 0
                          }
                        })
                        .reduce((a: number, b: number) => a + b, 0)
                    : null}
                  {!values.unpackproject.configReportFieldID && 0}
                </td>
              </tr>
            ))}
            <td className={styles.total}>7</td>
            <td className={styles.total}>รวม PAT Submit</td>
            {values.unpackproject.configReportFieldID
              ? values.unpackproject.configReportFieldID.length &&
                FindObj.allowedValueList.length &&
                FindObj.allowedValueList.map((tableItems: any, tableItemIndex: number) => (
                  <td className={styles.total} key={tableItemIndex}>
                    {Object.keys(EStatusSubmit)
                      .map((EstatusItem: any) => {
                        if (
                          values.patstatus.find((itemTable: any) => itemTable.PATStatusType === EstatusItem)
                            ?.fieldValue[`${tableItems}`]
                        ) {
                          return values.patstatus.find((itemTable: any) => itemTable.PATStatusType === EstatusItem)
                            ?.fieldValue[`${tableItems}`]
                        } else {
                          return 0
                        }
                      })
                      .reduce((a: number, b: number) => a + b, 0)}
                  </td>
                ))
              : null}

            <td className={styles.total}>
              {' '}
              {values.unpackproject.configReportFieldID
                ? values.unpackproject.configReportFieldID.length &&
                  FindObj.allowedValueList.length &&
                  FindObj.allowedValueList
                    .map((tableItems: any) => {
                      return Object.keys(EStatusSubmit)
                        .map((EstatusItem: any) => {
                          if (
                            values.patstatus.find((itemTable: any) => itemTable.PATStatusType === EstatusItem)
                              ?.fieldValue[`${tableItems}`]
                          ) {
                            return values.patstatus.find((itemTable: any) => itemTable.PATStatusType === EstatusItem)
                              ?.fieldValue[`${tableItems}`]
                          } else {
                            return 0
                          }
                        })
                        .reduce((a: number, b: number) => a + b, 0)
                    })
                    .reduce((a: number, b: number) => a + b, 0)
                : null}
              {!values.unpackproject.configReportFieldID && 0}
            </td>
          </table>
        </div>
      )}
    </div>
  )
}

export default TableReportComponent

interface ITableComponent {
  fieldValueHeader?: any[]
  tablerow?: any[]
}
