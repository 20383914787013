import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { TextField } from '@fluentui/react'
import arrowpic from './arrow.svg'

const mockUpDigit = [
  {
    name: 'digit-1',
  },
  {
    name: 'digit-2',
  },
  {
    name: 'digit-3',
  },
  {
    name: 'digit-4',
  },
  {
    name: 'digit-5',
  },
  {
    name: 'digit-6',
  },
]

const Verifyforgotcomponent: React.FC<any> = ({ setloginComponentStatus }: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <section className={styles.rightSideContent}>
      <div>
        <img
          onClick={() => {
            setloginComponentStatus('fill')
          }}
          className={`arrowPic`}
          src={arrowpic}
          alt="arrowpic"
        />
        <section className={styles.verifyContent}>
          <article className={styles.topicGroup}>
            <p className={styles.verifyTopic}>กรุณาใส่รหัสยืนยันตัวตน</p>
            <p className={styles.verifySubTopic}>รหัสยืนยันตัวตนจะถูกส่งไปทาง SMS ที่</p>
            <p className={styles.showingPhoneNum}>(+66) {values.phonenumber}</p>
          </article>
          <article className={styles.verifyContentItems}>
            <Form>
              <section className={styles.digitLine}>
                {mockUpDigit.map((digitElement: any, digitIndex: number) => {
                  return (
                    <Field
                      key={digitIndex}
                      className={styles.digitBox}
                      maxlength="1"
                      type="tel"
                      name={digitElement.name}
                      onKeyUp={(e: KeyboardEvent): void => {
                        // setFieldValue(`${digitElement.name}`, e.key)
                        console.log(e, 'e')
                        e.key === 'Backspace'
                          ? document.querySelector<HTMLInputElement>(`input[name=digit-${digitIndex}]`)?.focus()
                          : new RegExp('^(?=.*[0-9])').test(e.key)
                          ? document.querySelector<HTMLInputElement>(`input[name=digit-${digitIndex + 1 + 1}]`)?.focus()
                          : null
                      }}
                    />
                  )
                })}
              </section>
            </Form>
            <p className={styles.description}>กรุณารอ 60 วินาทีก่อนกดส่งอีกครั้ง</p>
          </article>
        </section>
      </div>
      <button
        onClick={() => {
          window.location.reload()
        }}
        type="button"
        className={`orangeBtn`}
      >
        เข้าสู่ระบบ
      </button>
    </section>
  )
}

export default Verifyforgotcomponent
