import { parse } from 'utils/equation'
import { Job, UserVariable } from './generated'
// @ = UserVariable
// # = colName
// $ = CT Table  / Job Attribute

const userVariableRegex = /@(\w+)/g
const colNameRegex = /#(\w+)/g
const jobRegex = /\$(\w+)/g

export const getUserVariable = (eq: string) => {
  const match = eq.matchAll(userVariableRegex)
  return Array.from(match, (x) => x[1])
}
export const getColName = (eq: string) => {
  const match = eq.matchAll(colNameRegex)
  return Array.from(match, (x) => x[1])
}
export const getJobAttribute = (eq: string) => {
  const match = eq.matchAll(jobRegex)
  return Array.from(match, (x) => x[1])
}

interface IVariable {
  text: string
  value: number | string | boolean
}

interface ParseArgs {
  userVariable?: IVariable[]
  columnVariable?: IVariable[]
  jobVariable?: IVariable[]
}

export const parseWrap = (eq: string | null, args: ParseArgs | null = null) => {
  let parseString = ''
  if (args && args.columnVariable)
    parseString += args.columnVariable.map((v) => `#${v.text} = ${renderValue(v.value)};`).join('')
  if (args && args.userVariable)
    parseString += args.userVariable.map((v) => `@${v.text} = ${renderValue(v.value)};`).join('')
  if (args && args.jobVariable)
    parseString += args.jobVariable.map((v) => `$${v.text} = ${renderValue(v.value)};`).join('')
  parseString += eq
  // console.log('parseARgs', args)
  // console.log('parseString', parseString, parse(parseString))
  return eq ? parse(parseString) || '' : ''
}

interface ParseUnpackArgs {
  userVariable?: UserVariable[] | null
  job?: Job | undefined

  // Doesn't work cuz idk row idx of data...
  // columnList?: string[] | null | undefined
  // data?: any[][] | null | undefined
}

export const parseUnpack = (eq: string, args: ParseUnpackArgs | null = null) => {
  const userVariable = args?.userVariable?.map((uv) => ({
    text: uv.varname.substring(1),
    value: uv.value || 0,
  }))
  const jobVariable =
    args && args.job
      ? listAttr(args.job).map((k) => ({
          text: k,
          value: args.job![k as keyof Job] as string | number | boolean,
        }))
      : undefined

  return parseWrap(eq, {
    userVariable,
    jobVariable,
  })
}

const renderValue = (s: number | string | boolean) => {
  if (typeof s === 'string') return `"${s}"`
  return s
}

const listAttr = (object: any, allowedTypes = ['string', 'number', 'boolean']) => {
  return Object.entries(object).flatMap(([k, v]) => (allowedTypes.includes(typeof v) ? [k] : []))
}
