import React, { useState, useEffect } from 'react'
import styles from './ThreeSteps.module.scss'

const ThreeStepComponent: React.FC<any> = ({ setpagestatus, pagestatus, isValid }: any) => (
  <div className={styles.pagestatus}>
    <hr className={styles.pagestatusline} />
    <div className={styles.pagestatusgroupbtn}>
      <button
        className={`${styles.pagestatusbtn} ${pagestatus === 'firststep' ? styles.highlighted : ''}`}
        // onClick={() => {
        //   setpagestatus('firststep')
        // }}
      >
        1
      </button>
      <button
        className={`${styles.pagestatusbtn} ${pagestatus === 'secondstep' ? styles.highlighted : ''}`}
        // onClick={() => {
        //   if (isValid) setpagestatus('secondstep')
        // }}
      >
        2
      </button>
      <button
        className={`${styles.pagestatusbtn} ${pagestatus === 'thirdstep' ? styles.highlighted : ''}`}
        // onClick={() => {
        //   if (isValid) setpagestatus('thirdstep')
        // }}
      >
        3
      </button>
    </div>
  </div>
)
export default ThreeStepComponent
