import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps, ErrorMessage, setNestedObjectValues } from 'formik'
import { FormikInputRadio } from 'components/commons/input/formikInputRadio'
import { FormikInputSelect, IOption } from 'components/commons/input/formikInputSelect'
import * as Yup from 'yup'
import VerifyFristPerson from './verifyFirtperson'
import { useNavigate } from 'react-router-dom'
import { THAILAND_REGION, THAILAND_PROVINCE_MAPPER } from '../../components/commons/thailandFact'

interface Props {
  setVerifystep: any
}
const VerifyFrist: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, touched, setFieldValue, validateForm, setTouched } = useFormikContext<any>()
  // console.log('verify value 1', values)
  const navigate = useNavigate()

  const UserTypeE: any = {
    JURISTIC: 'นิติบุคคล',
    PERSON: 'บุคคลธรรมดา',
  }

  async function validateTaxID(value: string) {
    if (!value) {
      return 'กรุณากรอกเลขประจำตัวผู้เสียภาษี'
    }
    try {
      await Yup.object()
        .shape({
          taxID: Yup.string().length(13, 'กรุณากรอกเลขประจำตัวผู้เสียภาษี 13 หลัก').required('eeee'),
        })
        .validate({ taxID: value.toString() })
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }
  //phone
  async function validatePhone(value: string) {
    if (!value) {
      return 'กรุณากรอกเบอร์ติดต่อ'
    }
    try {
      await Yup.object()
        .shape({
          phone: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'กรอกเฉพาะหมายเลขเท่านั้น')
            .min(10, 'กรอกเบอร์ติดต่อ 10 หลัก')
            .max(10, 'กรอกเบอร์ติดต่อ 10 หลัก'),
        })
        .validate({ phone: `${value}` })
      console.log(value, 'value phone')
    } catch (error) {
      console.log('EEEEEEEEEEEEEEEEEE', error.message)
      return error.message
    }
  }
  return (
    <>
      <div className={styles.verifyFristContainer}>
        <div className={styles.radioWrapper}>
          {values.profileUp && values.profileUp.userType ? (
            <FormikInputRadio
              listRadio={[
                {
                  text: `${UserTypeE[`${values.profileUp.userType}`]}`,
                  value: values.profileUp.userType,
                  name: `profileUp.userType`,
                },
              ]}
            />
          ) : (
            <FormikInputRadio
              listRadio={[
                {
                  text: 'นิติบุคคล',
                  value: 'JURISTIC',
                  name: `profileUp.userType`,
                },
                {
                  text: 'บุคคลธรรมดา',
                  value: 'PERSON',
                  name: `profileUp.userType`,
                },
              ]}
            />
          )}
        </div>
        {values.profileUp && values.profileUp.userType === 'JURISTIC' ? (
          <section>
            <div className={styles.inputWrapper}>
              <article>
                <p>ชื่อบริษัท</p>
                <Field
                  className={styles.inputText}
                  type="text"
                  name={'profileUp.companyName'}
                  placeholder="ชื่อบริษัท"
                />
              </article>
              <article>
                <p>ชื่อสำนักงานใหญ่/สาขา</p>
                <Field
                  className={styles.inputText}
                  type="text"
                  name={'profileUp.companyBranch'}
                  placeholder="ชื่อสำนักงานใหญ่/สาขา"
                />
              </article>
              <article>
                <span>
                  เลขประจำตัวผู้เสียภาษี *{' '}
                  <span className="errorMessage">
                    <ErrorMessage name="profileUp.taxID" />
                  </span>
                </span>

                <Field
                  className={styles.inputText}
                  type="number"
                  name={'profileUp.taxID'}
                  placeholder="เลขประจำตัวผู้เสียภาษี"
                  required
                  validate={validateTaxID}
                />
              </article>

              <article>
                <p>ที่อยู่ของบริษัท</p>
                <div className={styles.inputSelectBox}></div>
                <div className={styles.selectWrapper}>
                  <Field
                    className={styles.inputText}
                    type="number"
                    name={'profileUp.zipCode'}
                    placeholder="รหัสไปรษณีย์"
                  />
                  <FormikInputSelect
                    className={styles.inputText}
                    name={'profileUp.province'}
                    option={[
                      ...Object.entries(THAILAND_PROVINCE_MAPPER).map((region: any) => ({
                        value: region[1],
                        text: region[0],
                      })),
                    ]}
                    defaultValue={''}
                  />
                </div>

                <Field
                  as="textarea"
                  className={styles.inputText}
                  placeholder="ที่อยู่  "
                  style={{ resize: 'none', height: '50px', margin: '20px 0 40px 0' }}
                  name={'profileUp.addressDetail'}
                />
              </article>
              <article>
                <span>
                  เบอร์ติดต่อบริษัท *{' '}
                  <span className="errorMessage">
                    <ErrorMessage name={'profileUp.phone'} />
                  </span>
                </span>

                <Field
                  className={styles.inputText}
                  type="text"
                  name={'profileUp.phone'}
                  placeholder="เบอร์ติดต่อบริษัท"
                  validate={validatePhone}
                />
              </article>
              <article>
                <p>อีเมล</p>
                <Field className={styles.inputText} type="text" name={'profileUp.email'} placeholder="อีเมล" />
              </article>
            </div>
          </section>
        ) : (
          <div>
            <VerifyFristPerson />
          </div>
        )}

        <section>
          <article className={styles.btnFooterWrapper}>
            <button type="button" className={styles.btnCancle} onClick={() => navigate(-1)}>
              ยกเลิก
            </button>
            {/* props.setVerifystep('verifysecond') */}
            <button
              type="button"
              className={styles.btnNext}
              onClick={
                () => {
                  validateForm().then((errors) => {
                    // console.log('errors after vadlisad', errors)
                    if (Object.keys(errors).length === 0) {
                      props.setVerifystep('verifysecond')
                      // setpagestatus('secondstep')
                      console.log('Ok')
                    } else {
                      console.log('No NextStep')

                      setTouched(setNestedObjectValues(errors, true))
                    }
                  })
                }
                // props.setVerifystep('verifysecond')
              }
            >
              ถัดไป
            </button>
          </article>
        </section>
      </div>
    </>
  )
}
export default VerifyFrist
