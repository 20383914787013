import {
  post,
  IProject,
  IWorkType,
  IJob,
  getProjectByProjectId,
  putProject,
  unpackProject,
  deleteJob,
  createProject,
  ICreateProject,
} from 'adapter/xhr'
import { removeEmpty } from 'components/ChungTumTable'
// import { queryProjectByProjectId } from 'adapter/xhr/query'
// import { mutationPutProjectInput } from 'adapter/xhr/mutation'

export async function getUnpackedProjectByProjectId(projectId: string): Promise<IProject> {
  console.time('getUnpackedProjectByProjectId>getProjectByProjectId')
  const project: IProject = (await getProjectByProjectId(projectId, {}, queryProjectByProjectIdWithoutPatStatus)).data
    .data.project
  console.timeEnd('getUnpackedProjectByProjectId>getProjectByProjectId')
  return unpackProject(project)
}

// export async function getRawProjectByProjectId(projectId: string): Promise<IProject> {
//   console.time('getRawProjectByProjectId>getProjectByProjectId')
//   const project: IProject = (await getProjectByProjectId(projectId)).data.data.project
//   console.timeEnd('getRawProjectByProjectId>getProjectByProjectId')
//   return project
// }

// packing Data
export function packProject(project: IProject | ICreateProject, isAutoJobStatus = false): IProject {
  // Move all the jobList to a new array and delete the jobList in each workType
  const jobList: IJob[] = []
  // https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
  const projectClone = JSON.parse(JSON.stringify(project))
  projectClone.workTypeList.map((workTypeElement: IWorkType, workTypeIndex: number) => {
    if (workTypeElement.jobList) {
      // REMOVE LAST ELEMENT if empty
      workTypeElement.jobList = removeEmpty(workTypeElement.jobList)
      jobList.push(
        ...workTypeElement.jobList.map((jobElement: IJob) => {
          // Remove calculated Value
          delete jobElement.isPatGenerated
          delete jobElement.patStatus
          delete jobElement.revisionNo
          // delete jobElement.revisionNo

          // THIS IS TEMPORARY SOLUTION. status of JOB SHOULD NOT BE DONE IN THIS METHOD!
          // SHOULD THIS BE HERE?!?!? now we use only two job statuses!
          if (jobElement.acceptedUserId) {
            return {
              ...jobElement,
              // If Generate from Table without workType, it will fill in worktype for you
              // If Change workType from Table, will use the one you changed
              workType: jobElement.workType ? jobElement.workType : workTypeElement.workType,
              status: isAutoJobStatus ? 'ACCEPTED' : jobElement.status,
            }
          } else {
            // YOU NEED THIS BECAUSE INDEXING IN DYNAMO DOESNT WANT ANY EMPTY STRINGS! Will work withOUT
            delete jobElement.acceptedUserId
            return {
              ...jobElement,
              // If Generate from Table without workType, it will fill in worktype for you
              // If Change workType from Table, will use the one you changed
              workType: jobElement.workType ? jobElement.workType : workTypeElement.workType,
              status: isAutoJobStatus ? 'WAIT' : jobElement.status,
            }
          }
        }),
      )
      delete projectClone.workTypeList[workTypeIndex].jobList
    }
    // Append Line Number ALREADY HAVE?
    projectClone.workTypeList[workTypeIndex].lineNumber = workTypeIndex
    //Remove totalJobQty sent from input
    delete projectClone.workTypeList[workTypeIndex].totalJobQty
  })
  projectClone.jobList = jobList

  delete projectClone.createdAt
  delete projectClone.updatedAt

  // Delete Extended of Project
  delete projectClone.isShowSuccessModal
  delete projectClone.jobListToDelete
  delete projectClone.maxPrice
  delete projectClone.minPrice
  delete projectClone.isPassport
  delete projectClone.safetyPassport
  delete projectClone.options
  delete projectClone.fieldList
  delete projectClone.acceptedUserIdList
  return projectClone
}

export async function packAndPutProject(project: IProject): Promise<void> {
  const projectPacked: IProject = packProject(project, true)
  console.log('THIS IS PACKED PROJECT', projectPacked)
  // Actually we can return value. but im lazy
  putProject(projectPacked).catch((err) => {
    console.error('I HAVE ERROR ', err.response)
    throw new Error(err.response)
  })
}

export async function packAndCreateProject(project: ICreateProject): Promise<void> {
  const projectPacked: IProject = packProject(project, true)
  console.log('THIS IS PACKED PROJECT', projectPacked)
  // Actually we can return value. but im lazy
  console.log('>>IS Create')
  createProject(projectPacked).catch((err) => {
    console.error('I HAVE ERROR ', err.response)
    throw new Error(err.response)
  })
}

// Get PAT by User ID
export function getPATTemplatesByUserId(userId: string) {
  // Default are only templates!!
  const query = `
  query($userId: ID!){
    patByUserId(userId: $userId){
      patId
      name
    }
  }`
  const variables = {
    userId,
  }
  return post('/', {
    query,
    variables,
  })
}

// Delete Multiple Jobs
export async function deleteJobs(projectId: string, changTumIds: string[]) {
  const query = `mutation {
    ${changTumIds
      .map(
        (changTumId: string, changTumIndex: number) => `
      deleteIndex${changTumIndex}: deleteJob(projectId: "${projectId}", changTumId: "${changTumId}"){
        changTumId
      }`,
      )
      .join('\n')}
  }`
  const response = (await post('/', { query })).data.data
  return response
}

// CHAGNE FROM isPatGenerated to PatStatus!!
export const queryProjectByProjectIdWithoutPatStatus = `
query($projectId:ID!, $jobFilter:JSON) {
  project(projectId: $projectId, jobFilter: $jobFilter){
    projectId
    projectName
    projectDescription
    projectManagerName
    projectManagerPosition
    createdByUserId
    startDate
    endDate
    createdAt
    updatedAt
    status
    TORFile
    workTypeList {
      workType
      lineNumber
      patTemplateId
      isNoPrice
      jobUnitPrice
      isSafetyPassport
      safetyPassport
      requirements
      imageURLList
      totalJobQty
    }
    jobList{
      changTumId
      jobId
      workType
      region
      province
      district
      siteCode
      siteName
      zone
      number
      lat
      long
      status
      applicationID
      acceptedUserId
      assignedUserId
      assignedUserPhone
      planDate
      actualDate
      description
      remark
      addition1
      addition2
      patStatus
      customAttributeEditFieldID
      issueList {
        createdByUserId
        topic
        description
      }
      room
      siteArea
      towerSubType
      towerType
      status
      dimension
      manufacturer
      manufacturerDate
      installer
      height
      gateWidth
    }
    customFieldList {
      name
      allowedValueList
    }
    minPrice
    maxPrice
    isPassport
    configReportFieldID
    editFieldID
    acceptedUserIdList {
      userId
      getUser {
        userId
        username
      }
    }
  }
}
`
