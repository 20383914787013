import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import Tooltip from '@mui/material/Tooltip'

interface ITableTeam {
  technicianList?: ITechnician[]
}

interface ITechnician {
  userID: string
  getUser: IGetUser
}

interface IGetUser {
  username: string
  firstName: string
  lastName: string
  technicianJobType: string[]
  safetyPassport: ISafetyPassport[]
}

interface ISafetyPassport {
  safetyPassportType: string
  safetyPassportStatus: string
}

const technicianWorkType: any = {
  Electrician: 'ช่างไฟฟ้า',
  Painter: 'ช่างสี',
  Plumber: 'ช่างประปา',
  Plasterer: 'ช่างปูน',
  Metalworker: 'ช่างเหล็ก',
  Carpenter: 'ช่างไม้',
  Mechanic: 'ช่างยนต์',
  Installer: 'ช่างติดตั้ง',
}

const tableHeader = ['No.', 'ชื่อ-นามสกุล', 'ประเภทงานช่าง', 'Safety Passport']

const TableTeam: React.FC<ITableTeam> = (props: ITableTeam & any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()

  return (
    <div className={styles.tableContainer}>
      <section className={styles.tableTopic}>{props.tableTopic}</section>
      <section className={styles.tableHeader}>
        {/* <p className={styles.tableRowColumn}>{props.workType.fieldID}</p> */}
        {tableHeader.map((tableItems: string, tableHeaderIndex: number) => {
          return (
            <p className={styles.tableRowColumn} key={tableHeaderIndex}>
              {tableItems}
            </p>
          )
        })}
      </section>
      <section className={styles.tableContent}>
        {props.technicianList &&
          props.technicianList.map((technicianElement: ITechnician, technicianIndex: number) => {
            console.log(technicianElement, 'technicianElement')
            return (
              <section className={styles.tableRow} key={technicianIndex}>
                <p className={styles.tableRowTopic} style={technicianIndex % 2 !== 0 ? { background: 'white' } : {}}>
                  {technicianIndex + 1}
                </p>
                <div className={styles.tableRowColumn} style={technicianIndex % 2 !== 0 ? { background: 'white' } : {}}>
                  {technicianElement.getUser &&
                    technicianElement.getUser.firstName + ' ' + technicianElement.getUser.lastName}
                </div>
                <Tooltip
                  title={
                    technicianElement.getUser?.technicianJobType &&
                    technicianElement.getUser.technicianJobType.map((technicianJobType: string) => {
                      return `${technicianWorkType[`${technicianJobType}`]},`
                    })
                  }
                >
                  <div
                    className={styles.tableRowColumn}
                    style={technicianIndex % 2 !== 0 ? { background: 'white' } : {}}
                  >
                    {technicianElement.getUser?.technicianJobType &&
                      technicianElement.getUser.technicianJobType.map((technicianJobType: string) => {
                        return `${technicianWorkType[`${technicianJobType}`]},`
                      })}
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    technicianElement.getUser?.safetyPassport &&
                    technicianElement.getUser.safetyPassport.map((safetyPassportElement: ISafetyPassport) => {
                      return `${safetyPassportElement.safetyPassportType},`
                    })
                  }
                >
                  <div
                    className={styles.tableRowColumn}
                    style={technicianIndex % 2 !== 0 ? { background: 'white' } : {}}
                  >
                    {technicianElement.getUser?.safetyPassport &&
                      technicianElement.getUser.safetyPassport.map((safetyPassportElement: ISafetyPassport) => {
                        return `${safetyPassportElement.safetyPassportType},`
                      })}
                  </div>
                </Tooltip>
              </section>
            )
          })}
      </section>
      <section className={styles.tableFooter}>
        <p className={styles.tableRowLeftSide}>รวมทั้งหมด</p>
        <p className={styles.tableRowRightSide}>{props.technicianList.length} คน</p>
      </section>
    </div>
  )
}

export default TableTeam
