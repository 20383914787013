import React, { useState, useEffect } from 'react'
import { useId, useBoolean } from '@fluentui/react-hooks'
import {
  TextField,
  DefaultButton,
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IButtonStyles,
  IconButton,
  DetailsList,
  Selection,
  IColumn,
  ISelection,
  IObjectWithKey,
  SelectionMode,
} from '@fluentui/react'
import styles from './index.module.scss'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import { Avatar, AvatarGroup } from '@mui/material'
import AddTechLogo from './AddTech.svg'
import editIcon from './edit.svg'
// import { ReactComponent as WorkerIcon } from './normalworker.svg'
import { ReactComponent as RepairWorkIcon } from './repairwork.svg'
import { ReactComponent as ContractWorkIcon } from './contractorwork.svg'

import ProgressBar from 'components/ProgressBar'

// import myjobpic from './myjob.svg'
// import filepic from './file.svg'
import detailIcon from './detailIcon.svg'
// import normalworker from './normalworker.svg'
import shareIcon from './shared.svg'
import applicants from './applicants.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import {
  getProjectsByUserId,
  getProjectForHandleByProjectId,
  generatePatFromPatTemplate,
  getProjectsByAcceptedUserId,
} from 'adapter/myProjectPageAdapter'
import { GetTechnicianList, IJob, IProject, IWorkType } from 'adapter/xhr'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { CUSTOMER_PREFIX } from 'App'
import ModalConfirm from 'components/commons/modalConfirm'
import { JOB_STATUS_MAPPER } from 'components/ChungTumTable'
import { THAILAND_REGION2CODE_MAPPER } from 'components/commons/thailandFact'
// import { MenuAndMenuItems } from 'components/menuAndMenuItems'

import ImagesSlideShow from 'components/imagesSlideShow'

//Image
import ModalAddTech from './modalTech'
import ModalMenu from './modalMenu'

enum PageProjectStatus {
  CURRENT,
  ARCHIVED,
}

const PAGE_PROJECT_STATUS2STATUS_LIST = {
  [PageProjectStatus.CURRENT]: ['DRAFT', 'PUBLIC'],
  [PageProjectStatus.ARCHIVED]: ['ARCHIVED'],
}

const MyProjectPage: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IFormikValues>()
  const [pageProjectStatus, setPageProjectStatus] = useState<PageProjectStatus>(PageProjectStatus.CURRENT)
  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const [selectedProjectElement, setSelectedProjectElement] = useState<IProject>()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [openmenu, setOpenmenu] = useState(false)

  const show = Boolean(anchorEl)

  const navigate = useNavigate()

  // Modals
  // Unused Project Publish Modal
  const [isProjectPublishModal, setIsProjectPublishModal] = useState<boolean>(false)

  // Pat Template Generation Modal Flow
  // 1. isGeneratePatTemplate -> Yes/No
  const [
    isGeneratePatTemplateModal,
    { setTrue: setShowGeneratePatTemplateModal, setFalse: setHideGeneratePatTemplateModal },
  ] = useBoolean(false)
  // 2. Job Selection Modal -> Check Boxes of all jobs!
  const [isJobSelectionModal, { setTrue: setShowJobSelectionModal, setFalse: setHideJobSelectionModal }] =
    useBoolean(false)
  // 3. Pat Gen Modal -> Progress Bar
  const [isPatGenModal, { setTrue: setShowPatGenModal, setFalse: setHidePatGenModal }] = useBoolean(false)
  const [projectIdModal, setProjectIdModal] = useState<string>('')

  // After clicking on the Buttom, will set projectIdToGenerate
  const [projectIdToGenerate, setProjectIdToGenerate] = useState<string>('')
  // After clicking #1 isGeneratePatTemplate Modal, will fetch for the Project
  const [projectToHandle, setProjectToHandle] = useState<IProject | null>(null)
  // After Fetch for the project -> Make DetailsList
  const jobSelection = new Selection<IJob>({
    getKey: getChangTumIdKey,
    onSelectionChanged: () => handleSelectionChange(),
  })
  // I CANT MAKE THIS WORK WITHOUT STATE. HOW?!?
  const [selectionCopy, setSelectionCopy] = useState<IJob[]>([])
  function getChangTumIdKey(jobElement: IJob) {
    return jobElement.changTumId
  }
  const jobSelectionColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'ID ช่างทำ',
      fieldName: 'changTumId',
      minWidth: 70,
      maxWidth: 70,
      isResizable: false,
      isFiltered: true,
      isSorted: true,
    },
    {
      key: 'column2',
      name: 'Region',
      fieldName: 'region',
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      isFiltered: true,
      isSorted: true,
    },
    {
      key: 'column3',
      name: 'Province',
      fieldName: 'province',
      minWidth: 80,
      maxWidth: 200,
      isResizable: true,
      isFiltered: true,
      isSorted: true,
    },
    {
      key: 'column4',
      name: 'Site Code',
      fieldName: 'siteCode',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isFiltered: true,
      isSorted: true,
    },
    {
      key: 'column5',
      name: 'Site Name',
      fieldName: 'siteName',
      minWidth: 80,
      maxWidth: 200,
      isResizable: true,
      isFiltered: true,
      isSorted: true,
    },
    {
      key: 'column6',
      name: 'Status',
      fieldName: 'status',
      minWidth: 40,
      maxWidth: 40,
      isResizable: false,
      isFiltered: true,
      isSorted: true,
    },
    {
      key: 'column7',
      name: 'มี PAT',
      fieldName: 'isPatGenerated',
      minWidth: 40,
      maxWidth: 40,
      isResizable: false,
      isFiltered: true,
      isSorted: true,
    },
  ]
  function handleSelectionChange() {
    console.log(jobSelection.getSelection())
    setSelectionCopy(jobSelection.getSelection())
    setSelectionCount(jobSelection.getSelectedCount())
  }
  // Make Items To Show a new list instead of the jobList
  const [itemsFilter, setItemsFilter] = useState<string>('')
  const [itemsToShow, setItemsToShow] = useState<IJob[]>(
    projectToHandle && projectToHandle.jobList ? projectToHandle.jobList : [],
  )
  useEffect(() => {
    if (projectToHandle && projectToHandle.jobList) {
      const items = projectToHandle.jobList.filter((jobElement) => {
        //Apply itemsFilter
        if (
          !(
            jobElement.jobId?.toLowerCase().trim().includes(itemsFilter.toLowerCase().trim()) ||
            jobElement.siteCode?.toLowerCase().trim().includes(itemsFilter.toLowerCase().trim()) ||
            jobElement.changTumId?.toLowerCase().trim().includes(itemsFilter.toLowerCase().trim()) ||
            jobElement.siteName?.toLowerCase().trim().includes(itemsFilter.toLowerCase().trim())
          )
        )
          return false
        return true
      })

      setItemsToShow(items)
    }
  }, [projectToHandle?.jobList, itemsFilter])

  const [selectionCount, setSelectionCount] = useState<number>(0)
  // After clicking #2 Modal, will do the Pat Generation and show #3 Modal the progress bar
  const [jobGenerateCount, setJobGenerateCount] = useState<number>(0)
  // END Pat Template Generation Modal Flow

  const titleId = useId('title')
  const [projectList, setProjectList] = useState<IProject[]>([])
  const [isProjectListLoad, setIsProjectListLoad] = useState<boolean>(false)
  // Get my projects with that particular userId
  const queryProjectList = () => {
    const userId = decodedAccessToken.userId
    const role = decodedAccessToken.role
    const getProjectIdListFunction = role === CUSTOMER_PREFIX ? getProjectsByUserId : getProjectsByAcceptedUserId
    console.time('useEffect>getProjectIdListFunction')
    getProjectIdListFunction(userId).then((projectListResponse: IProject[]) => {
      console.log('THIS IS projectListResponse', projectListResponse)
      setProjectList(projectListResponse)
      setIsProjectListLoad(true)
      console.timeEnd('useEffect>getProjectIdListFunction')
    })
  }
  useEffect(() => {
    queryProjectList()
  }, [])

  // Handle #2. Job Selection Modal -> Check Boxes of all jobs!
  async function fetchProjectForJobSelectionModal(projectId: string) {
    setHideGeneratePatTemplateModal()
    setShowJobSelectionModal()
    setProjectToHandle(null)
    console.time('fetchProjectForJobSelectionModal>getProjectForHandleByProjectId')
    const projectElement = await getProjectForHandleByProjectId(projectId)

    const jobListMapped = projectElement.jobList?.map((jobElement) => {
      return {
        ...jobElement,
        status: jobElement.status ? JOB_STATUS_MAPPER[jobElement.status] : '',
        region: jobElement.region ? THAILAND_REGION2CODE_MAPPER[jobElement.region] : '',
      }
    })

    setProjectToHandle({
      ...projectElement,
      jobList: jobListMapped,
    })
    console.timeEnd('fetchProjectForJobSelectionModal>getProjectForHandleByProjectId')
    console.log('fetchProjectForJobSelectionModal>projectElement', projectElement)
  }

  // Somehow setProjectToHandle doesn't trigger immediately, so the setIndexSelected doesn't work right after it.
  useEffect(() => {
    if (projectToHandle) {
      // Filter all the jobs with isPatGenerated === false
      // ?.filter((jobElement) => jobElement.isPatGenerated === false)
      // Don't Filter, but set the selection to ones which are isPatGenerated === false
      if (projectToHandle.jobList) {
        projectToHandle.jobList.map((jobElement, jobIndex) => {
          if (jobElement.isPatGenerated === false) {
            jobSelection.setIndexSelected(jobIndex, true, false)
          }
        })
        console.log(jobSelection.getSelection())
      }
    }
  }, [projectToHandle])

  // Handle project publishing
  // 1. Generate all PAT from the patTemplateId
  // 2. Put Modal of the project publishing (select)
  async function handleGeneratePatTemplate() {
    setShowPatGenModal()
    setHideJobSelectionModal()
    setJobGenerateCount(0)

    if (projectToHandle) {
      // SOMEHOW DIS FCKER DOESNT WORK WITH 10k ITEMS (BatchWriteItem)
      // Selection.getSelection() SOMEHOW DOESN"T WORK HERE?!?!? IT DOESN"T UPDATE on OnCLick below.
      const jobListToGenerate = selectionCopy.map((jobElement) => {
        // Remove this before Push
        delete jobElement.isPatGenerated
        return jobElement
      })
      console.log('handleProjectPublish>jobListToGenerate', jobListToGenerate)

      // console.log('JobList to Generate ', jobListToGenerate)
      if (jobListToGenerate && jobListToGenerate.length > 0) {
        const CHUNK_SIZE = 250

        for (let i = 0, j = jobListToGenerate.length; i < j; i += CHUNK_SIZE) {
          const projectWithJobToGenerate = {
            ...projectToHandle,
            jobList: jobListToGenerate.slice(i, i + CHUNK_SIZE),
          }
          const successString = await generatePatFromPatTemplate(projectWithJobToGenerate)
          console.log(`generatePatFromPatTemplate ${i},${i + CHUNK_SIZE}`, successString)
          if (successString === 'Success') {
            setJobGenerateCount(i + CHUNK_SIZE <= jobListToGenerate.length ? i + CHUNK_SIZE : jobListToGenerate.length)
            console.log('jobGenerateCount', jobGenerateCount)
          }
        }

        // setIsProjectPublishModal(true)
        // TODO Publicsh Project
      }
      setHidePatGenModal()
    }
  }

  //QueryTechnicianListInmodal
  const queryAcceptUserInproject = () => {
    GetTechnicianList().then((res: any) => {
      setFieldValue('techList', res.data.data.listAllUsers)
      setFieldValue('techListSearch', res.data.data.listAllUsers)
    })
  }
  useEffect(() => {
    queryAcceptUserInproject()
  }, [])
  // const menuAndMenuItems = [
  //   { icon: editIcon, text: 'Edit', link: 'editproject/${projectElement.projectId}' },
  //   { icon: shareIcon, text: 'New Post', link: '' },
  //   { icon: applicants, text: 'Applicants', link: '' },
  // ]

  return (
    <Form>
      {/* งานของฉัน & ประวัติงาน */}
      <div className={`${styles.projectSelectionWrapper}`}>
        <div className="wrapper2">
          <button
            className={`${styles.projectSelectionButton} ${
              pageProjectStatus === PageProjectStatus.CURRENT ? `${styles.projectSelectionButtonSelected}` : ''
            }`}
            type="button"
            onClick={() => {
              setPageProjectStatus(PageProjectStatus.CURRENT)
            }}
          >
            งานของฉัน
          </button>
          <button
            className={`${styles.projectSelectionButton} ${
              pageProjectStatus === PageProjectStatus.ARCHIVED ? `${styles.projectSelectionButtonSelected}` : ''
            }`}
            type="button"
            onClick={() => {
              setPageProjectStatus(PageProjectStatus.ARCHIVED)
            }}
          >
            ประวัติงาน
          </button>
        </div>
      </div>
      {/* Wrapper of the bottom Content */}
      <div className={`wrapper2 ${styles.projectListWrapper}`}>
        {/* Add Project Button and Search */}
        <section className={styles.projectListHeader}>
          {pageProjectStatus === PageProjectStatus.CURRENT ? (
            <DefaultButton
              className={styles.addProjectButton}
              text="เพิ่มโครงการ / งาน"
              onClick={() => {
                setFieldValue('isModalOpen', true, false)
              }}
              styles={{ label: { fontWeight: '500' } }}
              iconProps={{ iconName: 'CircleAddition' }}
            />
          ) : (
            <div className="spacer"></div>
          )}
          <Field name="projectFilter">
            {({ field }: FieldProps) => <TextField {...field} iconProps={{ iconName: 'Search' }} />}
          </Field>
        </section>

        {/* Actual Project List */}
        <section className={styles.projectCardWrapper}>
          {!isProjectListLoad && (
            <div className="loaderWrapper">
              <div className="loader" />
            </div>
          )}
          {projectList
            .filter((projectElement: IProject) =>
              projectElement.status
                ? PAGE_PROJECT_STATUS2STATUS_LIST[pageProjectStatus].includes(projectElement.status)
                : false,
            )
            .filter((projectElement: IProject) =>
              projectElement.projectName
                ? new RegExp(values.projectFilter, 'i').test(projectElement.projectName)
                : true,
            )
            .map((projectElement: IProject, projectIndex: number) => (
              <article className={styles.projectCard} key={projectIndex}>
                <div className={styles.projectCardHeader}>
                  <h2>
                    {projectElement.projectName} <span className={styles.subTitle}>{projectElement.projectId}</span>
                  </h2>
                  {/* <Link to={`myproject/editproject/${projectElement.projectId}`}>
                    <EditIcon />
                  </Link> */}
                  <button
                    type="button"
                    className={styles.btDetail}
                    id="fade-button"
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    // aria-expanded={props.open ? 'true' : undefined}
                    onClick={(events: any) => {
                      // setIsCallOutVisible(true)
                      setSelectedProjectElement(projectElement)
                      setAnchorEl(events.currentTarget)
                      console.log(events.currentTarget, 'curren btn')
                    }}
                  >
                    <img src={detailIcon} alt="" />
                  </button>
                  {/* {isCalloutVisible && <MenuAndMenuItems menu={menuAndMenuItems} />} */}
                </div>

                <div>Created: {new Date(projectElement.createdAt || '').toLocaleString()}</div>
                <div>{projectElement.projectDescription}</div>
                <section className={styles.AddTechArea}>
                  <AvatarGroup
                    max={4}
                    onClick={() => {
                      setOpenmenu(true)
                      setProjectIdModal(projectElement.projectId)
                    }}
                  >
                    {projectElement.acceptedUserIdList?.map((listUser: any, ListIndex: number) => (
                      <Avatar
                        key={ListIndex}
                        alt={`${listUser.getUser.username}`}
                        src="/static/images/avatar/5.jpg"
                        className={styles.avatar}
                      />
                    ))}
                  </AvatarGroup>

                  <img
                    src={AddTechLogo}
                    alt=""
                    className={styles.IconAdd}
                    onClick={() => {
                      setOpen(true)
                      setProjectIdModal(projectElement.projectId)
                    }}
                  />
                </section>

                {projectElement.workTypeList?.map((workTypeElement: IWorkType, workTypeIndex: number) => {
                  return (
                    <div className={styles.workTypeCard} key={workTypeIndex}>
                      <section>
                        <div className={styles.imageside}>
                          <div className={styles.imgshow}>
                            <ImagesSlideShow
                              imageList={workTypeElement.imageURLList ? workTypeElement.imageURLList : ['']}
                            />
                          </div>
                        </div>
                        {/* <img className={styles.imageJob} src={workTypeElement.imageURLList} alt="" srcSet="" /> */}
                      </section>
                      <div className={styles.leftWrapper}>
                        <section className={styles.workTypeSection}>
                          <p className={styles.topicCard}>
                            ประเภทงานที่ {workTypeIndex + 1} : {workTypeElement.workType}
                          </p>
                          <p className={styles.title}>ทั้งหมด {workTypeElement.totalJobQty} งาน</p>
                          <p className={styles.title}>ต้องมี safety Passport</p>
                          <p className={styles.title}>ผู้รับเหมา AIS (General Safety) -G</p>
                          <ul className={styles.list}>
                            <li>ประสบการณ์การทำงานด้านการติดตั้งตู้ 3 ปีขึ้นไป</li>
                            <li>ประสบการณ์ด้านการซ่อมบำรุง 3 ปีขึ้นไป</li>
                            <li>จำนวนทีมงานขั้นต่ำ 10 ทีม</li>
                            <li>สังกัดหน่วยงาน</li>
                          </ul>
                          <p className={styles.title}>
                            <b>ภูมิภาค</b> กรุงเทพ, ภาคเหนือ, ภาคอีสาน, ภาคกลาง, ภาคใต้
                          </p>
                          {/* <div className="dp-flex">
                          <div className={styles.spacer}></div>
                          <p></p>
                        </div> */}
                        </section>
                        {/* <div className={styles.workTypeSection}>
                        <h3>ปริมาณงาน</h3>
                        <div className="dp-flex">
                          <div className={styles.spacer}></div>
                          <p>{workTypeElement.totalJobQty} งาน</p>
                        </div>
                      </div> */}
                        {/* <div className={styles.workTypeSection}>
                        <h3>เอกสาร Template PAT</h3>
                        <div className="dp-flex">
                          {projectElement.TORFile === '' ? '' : <div className={styles.spacer}></div>}
                          <button
                            className={styles.torFileButton}
                            style={projectElement.TORFile === '' ? { flex: '0 0', padding: '5px 10px' } : {}}
                          >
                            <img src={filepic} alt="filepic" style={{ width: '25px' }} />
                            {projectElement.TORFile}
                          </button>
                          <div className={styles.spacer}></div>
                        </div>
                      </div> */}
                      </div>
                      {/* <hr /> */}
                      {/* <div className={styles.rightWrapper}>
                        <button className={styles.buttonWrapper}>
                          <WorkerIcon />
                        </button>
                        <p>ผู้รับเหมา/ช่างของฉัน</p>
                      </div> */}
                    </div>
                  )
                })}
                <section className={`dp-flex justify-cont-ct`}>
                  <button
                    className={`btnDefault`}
                    onClick={() => {
                      // handleProjectPublish(projectElement.projectId)
                      setShowGeneratePatTemplateModal()
                      setProjectIdToGenerate(projectElement.projectId)
                    }}
                  >
                    สร้างเอกสาร PAT
                  </button>
                </section>
              </article>
            ))}
        </section>

        <ModalAddTech
          open={open}
          setOpen={setOpen}
          projectIdModal={projectIdModal}
          projectList={projectList}
          queryProjectList={queryProjectList}
          // queryUser={queryAcceptUserInproject}
        />
        <ModalMenu
          open={openmenu}
          setOpen={setOpenmenu}
          projectIdModal={projectIdModal}
          projectList={projectList}
          queryProjectList={queryProjectList}
          // queryUser={queryAcceptUserInproject}
        />
      </div>

      <Modal //This is a Modal before going to add projectpage
        titleAriaId={titleId}
        isOpen={values.isModalOpen}
        onDismiss={() => {
          setFieldValue('isModalOpen', false, false)
        }}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={() => {
              setFieldValue('isModalOpen', false, false)
            }}
          />
        </div>
        <div className={contentStyles.body}>
          <Link to={`createproject`}>
            <button className={styles.createProjectButton}>
              <RepairWorkIcon width={120} />
              <h2>งานซ่อมแซ่ม</h2>
              <p>สำหรับงานซ่อมแซ่มทั่วไป อาทิ ล้างแอร์ งานซ่อมทั่วไป และงานต่อเติม</p>
            </button>
          </Link>
          <Link to={`createproject`}>
            <button className={styles.createProjectButton}>
              <ContractWorkIcon width={120} />
              <h2>โครงการรับเหมา</h2>
              <p>สำหรับงานโครงการรับเหมาติดตั้งตู้ เสาไฟ หรือโครงการ TOR อื่นๆ</p>
            </button>
          </Link>
        </div>
      </Modal>

      <Modal // Project Publishing Modal -> NOT USED FOR NOW!
        titleAriaId={titleId}
        isOpen={isProjectPublishModal}
        onDismiss={() => {
          setIsProjectPublishModal(false)
        }}
        isBlocking={false}
        containerClassName={styles.announcementmodalcontainer}
      >
        <div className={styles.header}>
          <section style={{ textAlign: 'center' }}>
            <h1>Are you sure?</h1>
          </section>
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={() => {
              setIsProjectPublishModal(false)
            }}
          />
        </div>
        <div className={styles.body}>
          <article style={{ textAlign: 'center' }}>
            <p>คุณต้องการประกาศ</p>
            <p>งานนี้เพื่อหาช่างทำงาน ใช่ไหม ?</p>
          </article>
          <section className={styles.btnline}>
            <button
              type="button"
              className={`btnPrimary`}
              onClick={() => {
                setIsProjectPublishModal(false)
              }}
            >
              ยกเลิก
            </button>
            {/* Confirm should change the project to status PUBLIC */}
            <button type="button" className={`btnDefault`}>
              ยืนยัน
            </button>
          </section>
        </div>
      </Modal>

      {/* Pat Template Generation Modal Flow */}
      <ModalConfirm // 1. isGeneratePatTemplate -> Yes/No
        isModalOpen={isGeneratePatTemplateModal}
        closeModal={() => setHideGeneratePatTemplateModal()}
        submitModal={() => fetchProjectForJobSelectionModal(projectIdToGenerate)}
        description={`ข้อมูลของ Project ${projectIdToGenerate} จะถูกสร้างเอกสาร PAT ใหม่`}
        status={`ถ้าคุณเคยกดสร้างเอกสาร PAT แล้ว และคุณทำการกดสร้างอีกครั้ง ข้อมูล PAT จะถูกลบทั้งหมด คุณแน่ใจใช่หรือไม่`}
      />
      <Modal // 2. Job Selection Modal -> Check Boxes of all jobs!
        titleAriaId={titleId}
        isOpen={isJobSelectionModal}
        onDismiss={setHideJobSelectionModal}
        isBlocking={true}
        containerClassName={styles.jobSelectionContainer}
      >
        <div className={styles.jobSelectionModalWrapper}>
          <h1>งานที่ต้องการสร้างเอกสาร PAT</h1>
          {!projectToHandle && <div className="loader">Loading Project...</div>}
          {projectToHandle && (
            <div>
              <p>Project {projectToHandle.projectId}</p>

              <TextField
                label="Filter by ChangtumId / JobId / SiteCode / SiteName:"
                onChange={(
                  ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                  text: string | undefined,
                ): void => {
                  if (text) setItemsFilter(text)
                }}
              />

              <DetailsList
                compact={true}
                items={itemsToShow}
                columns={jobSelectionColumns}
                selection={jobSelection as unknown as ISelection<IObjectWithKey>}
                selectionMode={SelectionMode.multiple}
                getKey={getChangTumIdKey}
                setKey="multiple"
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
              />

              <div>{selectionCount} items selected</div>

              <section className={styles.btnLine}>
                <button type="button" className={`btnPrimary`} onClick={setHideJobSelectionModal}>
                  ยกเลิก
                </button>
                <button type="button" className={`btnDefault`} onClick={() => handleGeneratePatTemplate()}>
                  ยืนยัน
                </button>
              </section>
            </div>
          )}
        </div>
      </Modal>
      <Modal // 3. Pat Gen Modal -> Progress Bar
        titleAriaId={titleId}
        isOpen={isPatGenModal}
        onDismiss={setHidePatGenModal}
        isBlocking={true}
        containerClassName={styles.genPatModalContainer}
      >
        <div className={styles.genPatModalWrapper}>
          <h1>Generating PAT</h1>
          {!projectToHandle && <div className="loader">Loading...</div>}
          {projectToHandle && (
            <div>
              <p>Project {projectToHandle.projectId}</p>
              <p>TOTAL JOBS: </p>
              <ProgressBar
                bgcolor="#ef6c00"
                completed={projectToHandle.jobList ? Math.floor((jobGenerateCount / selectionCopy.length) * 100) : 0}
              />

              <h2>
                {jobGenerateCount} /{selectionCopy.length}
              </h2>
            </div>
          )}
        </div>
      </Modal>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={show}
        TransitionComponent={Fade}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: '#F2994A',
            },
          }}
          onClick={() => navigate(`${selectedProjectElement?.projectId}`)}
        >
          <span className={styles.menulist}>
            <img className={styles.menuIcon} src={editIcon} alt="" /> Edit
          </span>
        </MenuItem>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: '#F2994A',
            },
          }}
          onClick={() => navigate(`createPostPage/${selectedProjectElement?.projectId}`)}
        >
          <span className={styles.menulist}>
            <img className={styles.menuIcon} src={shareIcon} alt="" /> New Post
          </span>
        </MenuItem>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: '#F2994A',
            },
          }}
          onClick={() => navigate(`newfeedsfilterTech/${selectedProjectElement?.projectId}`)}
        >
          <span className={styles.menulist}>
            <img className={styles.menuIcon} src={applicants} alt="" /> Applicants
          </span>
        </MenuItem>
      </Menu>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorElM}
        open={showTech}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={styles.menuTech}
      >
        {projectList.map((item: any) =>
          item.acceptedUserIdList?.map((acceptUser: any, acceptIndex: number) => (
            <MenuItem onClick={handleClose} key={acceptIndex} className={styles.menuTech}>
              <div className={styles.GroupAvatar}>
                {' '}
                <Avatar
                  alt={`${acceptUser.getUser.username}`}
                  src="/static/images/avatar/5.jpg"
                  className={styles.avatar}
                />{' '}
                {acceptUser.getUser.username}
              </div>

              <img src={CloseCircle} alt="" />
            </MenuItem>
          )),
        )}
      </Menu> */}
      {/* END Pat Template Generation Modal Flow */}
    </Form>
  )
}

const EnhancedMyProjectPage = withFormik({
  mapPropsToValues: () => ({
    projectFilter: '',
    isModalOpen: false,
    technicianListSearch: '',
  }),
  validate: (values) => {
    const error = {}

    return error
  },
  handleSubmit: (values) => {
    //
    console.log(values)
    console.log(JSON.stringify(values))
  },
})(MyProjectPage)

export default EnhancedMyProjectPage

interface IFormikValues {
  projectFilter: string
  isModalOpen: boolean
}

const theme = getTheme()

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    // width: '200px',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    // flex: '4 4 auto',
    display: 'flex',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
})

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    position: 'absolute',
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginRight: '2px',
    width: '30px',
    height: '30px',
    right: '9px',
    top: '9px',
  },
  icon: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}
