import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form } from 'formik'
import FirstContent from './firstcontent'
import SecondContent from './secondcontent'
import Thirdcontent from './thirdcontent'
import Fourthcontent from './fourthcontent'
import { IProject, getMasterPlan, getPlanDateReport, getInstalledDateReport, getRevisionReportCount } from 'adapter/xhr'
import {
  destructureJobList,
  getProjectIdsByAcceptedUserId,
  getProjectIdsByUserId,
  getUnpackedProjectByProjectId,
} from 'adapter/projectManagementPage'
import { useLocation, useParams } from 'react-router-dom'
import { CUSTOMER_PREFIX } from 'App'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { THAILAND_PROVINCE, THAILAND_REGION } from 'components/commons/thailandFact'

enum PageProjectStatus {
  CURRENT,
  ARCHIVED,
}

const PAGE_PROJECT_STATUS2STATUS_LIST = {
  [PageProjectStatus.CURRENT]: ['DRAFT', 'PUBLIC'],
  [PageProjectStatus.ARCHIVED]: ['ARCHIVED'],
}

const Projectmanagementpage: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>()
  const { customerOrTechnician } = useParams()
  const location = useLocation()
  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const [year, setYear] = useState<number>(new Date().getFullYear())

  // State on the menuOnPage to see if it is current Project or History Project
  const [pageProjectStatus, setPageProjectStatus] = useState<PageProjectStatus>(PageProjectStatus.CURRENT)
  // State on the current Header Button
  // This should be dashboard, but im doing the pat management first
  const [currentHeaderButton, setCurrentHeaderButton] = useState(BUTTON_VALUE.DASHBOARD)

  const [isProjectLoaded, setIsProjectLoaded] = useState<boolean>(false)

  const initialOptions = [
    { value: '', text: '' },
    {
      value: 'province',
      text: 'จังหวัด',
    },
    {
      value: 'region',
      text: 'ภูมิภาค',
    },
  ]

  const EMPTY_PLAN_VALUE = {
    fieldID: '',
    groupFieldList: [
      {
        fieldValue: '',
        workTypeList: [
          {
            workType: '',
            workTypeTable: {},
          },
        ],
      },
    ],
  }

  //Check is there any state send for Redirect to currentHeaderButton Step the state was fixed and I place a state sender on
  //function handleSubmitAcceptReject from patUpload
  //I was trying to send state from props.history.goback() but it not work so I only send from submit btn (accept or reject).
  useEffect(() => {
    console.log(location.state, 'ping props.location.state')
    if (location.state?.status === 'PAT_MANAGEMENT') {
      setCurrentHeaderButton(BUTTON_VALUE.PAT_MANAGEMENT)
    } else if (location.state === 'BUTTON_VALUE.MASTERPLAN') {
      setCurrentHeaderButton(BUTTON_VALUE.MASTERPLAN)
    }
  }, [])

  const buttonHeaderList = customerOrTechnician === CUSTOMER_PREFIX ? CUSTOMER_SELECTION : TECHNICIAN_SELECTION

  // Finding all the projects for that user and displaying on load
  useEffect(() => {
    const userId = decodedAccessToken.userId
    const role = decodedAccessToken.role
    const getProjectIdListFunction = role === CUSTOMER_PREFIX ? getProjectIdsByUserId : getProjectIdsByAcceptedUserId
    console.time('useEffect>getProjectIdListFunction')
    getProjectIdListFunction(userId).then((projectListResponse: IProject[]) => {
      console.log('useEffect>getProjectIdListFunction>projectListResponse', projectListResponse)
      setFieldValue('projectList', projectListResponse)
      console.timeEnd('useEffect>getProjectIdListFunction')

      const filteredProject = projectListResponse.filter((projectElement: IProject) =>
        projectElement.status
          ? PAGE_PROJECT_STATUS2STATUS_LIST[pageProjectStatus].includes(projectElement.status)
          : false,
      )

      if (location.state?.projectSelectedId) {
        setFieldValue('projectSelectedId', location.state.projectSelectedId)
      } else if (filteredProject.length > 0) {
        console.log('projectSelectedId', projectListResponse[0].projectId)
        setFieldValue('projectSelectedId', projectListResponse[0].projectId)
      } else {
        setIsProjectLoaded(true)
      }
    })
  }, [])

  // Everytime we change the pageProjectStatus, clear projectSelection and unpackproject
  useEffect(() => {
    if (isProjectLoaded) {
      setFieldValue('projectSelectedId', '')
      setFieldValue('unpackproject', '')
    }
  }, [pageProjectStatus])

  const queryPlan = async (queryMasterPlanInput: any) => {
    const { planningYear, ...queryPlanAndInstalledInput } = queryMasterPlanInput
    await getMasterPlan(queryMasterPlanInput).then((res: any) => {
      console.log(res, 'resQMasterPlan')
      if (res && res.data.data.project && res.data.data.project.masterPlan) {
        res.data.data.project.masterPlan.selectTechnician = values.masterPlan.selectTechnician
        setFieldValue('masterPlan', res.data.data.project.masterPlan)
        setFieldValue('isNoMasterPlan', null)
      } else {
        setFieldValue('isNoMasterPlan', true)
        setFieldValue('masterPlan', {
          planningYear: year,
          selectTechnician: values.masterPlan.selectTechnician ? values.masterPlan.selectTechnician : 'ALL',
          fieldID: '',
          groupFieldList: [
            {
              fieldValue: '',
              workTypeList: [
                {
                  workType: '',
                  workTypeTable: {},
                },
              ],
            },
          ],
        })
      }
    })
    await getPlanDateReport({
      projectId: values.projectSelectedId,
      acceptedUserId: values.masterPlan.selectTechnician || 'ALL',
    }).then((res: any) => {
      console.log(res.data.data.project.planDateReportByAcceptedUserId, 'res.data.data.project planDate')
      if (
        res?.data?.data?.project?.planDateReportByAcceptedUserId?.fieldID &&
        res?.data?.data?.project?.planDateReportByAcceptedUserId?.groupFieldList
      ) {
        setFieldValue('planDateReport', res.data.data.project.planDateReportByAcceptedUserId)
      } else {
        setFieldValue('planDateReport', EMPTY_PLAN_VALUE)
      }
    })
    await getInstalledDateReport({
      projectId: values.projectSelectedId,
      acceptedUserId: values.masterPlan.selectTechnician || 'ALL',
    }).then((res: any) => {
      console.log(res.data.data.project.actualDateReportByAcceptedUserId, 'res.data.data.project installedDate')
      if (
        res?.data?.data?.project?.actualDateReportByAcceptedUserId?.fieldID &&
        res?.data?.data?.project?.actualDateReportByAcceptedUserId?.groupFieldList
      ) {
        setFieldValue('actualDateReport', res.data.data.project.actualDateReportByAcceptedUserId)
      } else {
        setFieldValue('actualDateReport', EMPTY_PLAN_VALUE)
      }
    })
  }

  // Everytime the projectSelectedId is changed, axios it
  // This is sub optimal but i guess state wont be fcked
  const queryTable = () => {
    const role = decodedAccessToken.role
    const jobFilter = role === CUSTOMER_PREFIX ? {} : { acceptedUserId: decodedAccessToken.userId }
    if (values.projectSelectedId) {
      console.time('useEffect>projectSelectedId')
      const queryMasterPlanInput = {
        projectId: values.projectSelectedId,
        planningYear: `${year}`,
        acceptedUserId: values.masterPlan.selectTechnician || 'ALL',
      }
      Promise.all([
        getUnpackedProjectByProjectId(values.projectSelectedId, jobFilter)
          .then((unpackedProjectResponse: IProject) => {
            console.log('unpackproject', unpackedProjectResponse)
            const jobListReference = destructureJobList(unpackedProjectResponse)
            console.log('jobListReference', jobListReference)
            if (unpackedProjectResponse?.acceptedUserIdList) {
              setFieldValue('acceptedUserIdList', unpackedProjectResponse.acceptedUserIdList)
            } else {
              setFieldValue('acceptedUserIdList', [])
            }
            setFieldValue('unpackproject', unpackedProjectResponse)
            setFieldValue('jobListReference', jobListReference)
            const customOptionField: any = []
            if (unpackedProjectResponse.customFieldList) {
              unpackedProjectResponse.customFieldList.map((customField: any) => {
                customOptionField.push({ text: customField.name, value: customField.name })
              })
              setFieldValue('fieldList', [...values.fieldList, ...unpackedProjectResponse.customFieldList])
            }
            setFieldValue('options', [...initialOptions, ...customOptionField])
            console.timeEnd('useEffect>projectSelectedId')
          })
          .catch((err) => {
            console.error('ROLLING STONES ERROR')
          }),

        getRevisionReportCount({ projectId: values.projectSelectedId }).then((res: any) => {
          console.log(res.data.data, 'res RevisionReportCount')
          if (res && res.data.data.project && res.data.data.project.revisionCountTechnician) {
            setFieldValue('revisionCountTechnician', res.data.data.project.revisionCountTechnician)
          }
          if (res && res.data.data.project && res.data.data.project.revisionCountWorkType) {
            setFieldValue('revisionCountWorkType', res.data.data.project.revisionCountWorkType)
          }
          if (res && res.data.data.project && res.data.data.project.revisionHistogram) {
            setFieldValue('revisionHistogram', res.data.data.project.revisionHistogram)
          }
          if (res && res.data.data.project && res.data.data.project.siteCountTechnician) {
            setFieldValue('siteCountTechnician', res.data.data.project.siteCountTechnician)
          }
        }),
        queryPlan(queryMasterPlanInput),
      ]).finally(() => {
        setIsProjectLoaded(true)
      })
    }
  }

  useEffect(() => {
    queryTable()
  }, [values.projectSelectedId])

  useEffect(() => {
    // setFieldValue('masterPlan.planningYear', `${year}`)
    if (values.projectSelectedId) {
      console.log('year Trigger !!! Inside')

      const queryMasterPlanInput = {
        projectId: values.projectSelectedId,
        planningYear: `${year}`,
        acceptedUserId: values.masterPlan.selectTechnician || 'ALL',
      }
      Promise.all([queryPlan(queryMasterPlanInput)]).finally(() => {
        setIsProjectLoaded(true)
      })
    }
  }, [year, values.masterPlan.selectTechnician])

  return (
    <Form>
      {/*  งานของฉัน & ประวัติงาน  */}
      <div className={`menuOnPage`}>
        <div className="wrapper2">
          <button
            className={`menuOnPageItems ${
              pageProjectStatus === PageProjectStatus.CURRENT ? `menuOnPageItemsSelected` : ''
            }`}
            type="button"
            onClick={() => {
              if (isProjectLoaded) {
                setPageProjectStatus(PageProjectStatus.CURRENT)
              }
            }}
          >
            โครงการของฉัน
          </button>
          <button
            className={`menuOnPageItems ${
              pageProjectStatus === PageProjectStatus.ARCHIVED ? `menuOnPageItemsSelected` : ''
            }`}
            type="button"
            onClick={() => {
              if (isProjectLoaded) {
                setPageProjectStatus(PageProjectStatus.ARCHIVED)
              }
            }}
          >
            ประวัติโครงการ
          </button>
        </div>
      </div>

      <div className={`wrapper2 ${styles.projectWrapper}`}>
        {/* The Header section  */}
        <section className={styles.projectHeader}>
          {/* The Project List To Select*/}
          <div className={styles.projectList}>
            <h1 className={styles.projectListHeader}>รายการโครงการ</h1>
            {values.projectList
              ?.filter((projectElement: IProject) =>
                projectElement.status
                  ? PAGE_PROJECT_STATUS2STATUS_LIST[pageProjectStatus].includes(projectElement.status)
                  : false,
              )
              .map((projectElement: IProject, projectindex: number) => (
                <button
                  key={projectindex}
                  className={`${styles.projectlistcontent} ${
                    values.projectSelectedId === projectElement.projectId ? styles.projectlistcontentSelected : ''
                  }`}
                  type="button"
                  onClick={() => {
                    setFieldValue('projectSelectedId', projectElement.projectId)
                    if (projectElement.projectId !== values.projectSelectedId) setIsProjectLoaded(false)
                  }}
                >
                  {projectElement.projectName}
                </button>
              ))}
          </div>
          {/* The 3 buttons ภาพรวมแผนงาน วางแผนงาน ตรวจสอนงาน (ผู้จ้าง) 
          ภาพรวมแผนงาน การวางแผนงาน ลงงาน/Upload รูป (ช่าง)*/}
          <div className={styles.projectHeaderButtonWrapper}>
            {buttonHeaderList.map(({ text, value }, buttonHeaderIndex: number) => (
              <button
                key={buttonHeaderIndex}
                className={`${styles.projectHeaderButton} ${
                  value === currentHeaderButton ? styles.projectHeaderButtonSelected : ''
                }`}
                type="button"
                onClick={() => {
                  setCurrentHeaderButton(value)
                }}
              >
                {text}
              </button>
            ))}
          </div>
        </section>
        {/* The Conent: Dashborad/ Masterplan/ PAT Job List  */}
        <section className={styles.projectContent}>
          {!isProjectLoaded && (
            <div className="loaderWrapper">
              <div className="loader" />
            </div>
          )}
          {isProjectLoaded && (
            <>
              {currentHeaderButton === BUTTON_VALUE.DASHBOARD ? (
                <FirstContent year={year} setYear={setYear} />
              ) : currentHeaderButton === BUTTON_VALUE.MASTERPLAN ? (
                <SecondContent year={year} setYear={setYear} />
              ) : currentHeaderButton === BUTTON_VALUE.PAT_MANAGEMENT ? (
                <Thirdcontent />
              ) : currentHeaderButton === BUTTON_VALUE.REPORT ? (
                <Fourthcontent />
              ) : null}
            </>
          )}
        </section>
      </div>
    </Form>
  )
}

const EnhancedProjectmanagementpage = withFormik({
  mapPropsToValues: () => ({
    // Project List for that user ID!
    projectList: [],
    // The selected project on the projectList
    projectSelectedId: '',
    topic: '',
    description: '',
    projectId: '',

    unpackproject: '',
    jobListReference: '', //This is for the planActual Page Update, to use as reference to check if there are any changes

    //state to use under element
    options: [
      { value: '', text: '' },
      {
        value: 'province',
        text: 'จังหวัด',
      },
      {
        value: 'region',
        text: 'ภูมิภาค',
      },
    ],
    optionsStatus: [
      { value: '', text: '' },
      {
        value: 'all',
        text: 'สถานะทั้งหมด',
      },
      {
        value: 'WAIT_APPROVAL',
        text: 'รอการดำเนินการ',
      },
      {
        value: 'WAIT_FILL',
        text: 'รอการตรวจสอบ',
      },
      {
        value: 'ACCEPT',
        text: 'ผ่าน',
      },
      {
        value: 'REJECT',
        text: 'รอการแก้ไข',
      },
    ],
    fieldList: [
      {
        name: 'region',
        allowedValueList: THAILAND_REGION,
      },
      {
        name: 'province',
        allowedValueList: THAILAND_PROVINCE,
      },
    ],
    masterPlan: {
      selectTechnician: 'ALL',
      planningYear: `${new Date().getFullYear()}`,
      fieldID: '',
      groupFieldList: [
        {
          fieldValue: '',
          workTypeList: [
            {
              workType: '',
              workTypeTable: {},
            },
          ],
        },
      ],
    },
    revisionCountTechnician: {},
    revisionCountWorkType: {},
    revisionHistogram: {},
    siteCountTechnician: {},
  }),
  validate: (values) => {
    console.log('THIS IS VALUES ', values)
    const errors: any = {}

    if (!values.topic) {
      errors.topic = 'กรุณากรอกหัวข้อปัญหา'
    }

    return errors
  },
  handleSubmit: (values, { setFieldValue }) => {
    //
    console.log(values)
    // console.log(JSON.stringify(values))
  },
})(Projectmanagementpage)

export default EnhancedProjectmanagementpage

interface IButtonSelection {
  text: string
  value: BUTTON_VALUE
}

enum BUTTON_VALUE {
  DASHBOARD = 1,
  MASTERPLAN,
  PAT_MANAGEMENT,
  REPORT,
}

const CUSTOMER_SELECTION: IButtonSelection[] = [
  {
    text: 'ภาพรวมแผนงาน',
    value: BUTTON_VALUE.DASHBOARD,
  },
  {
    text: 'วางแผนงาน',
    value: BUTTON_VALUE.MASTERPLAN,
  },
  {
    text: 'ตรวจสอบงาน',
    value: BUTTON_VALUE.PAT_MANAGEMENT,
  },
  {
    text: 'รายงาน',
    value: BUTTON_VALUE.REPORT,
  },
]

const TECHNICIAN_SELECTION: IButtonSelection[] = [
  {
    text: 'ภาพรวมแผนงาน',
    value: BUTTON_VALUE.DASHBOARD,
  },
  {
    text: 'วางแผนงาน',
    value: BUTTON_VALUE.MASTERPLAN,
  },
  {
    text: 'ลงงาน/Upload รูป',
    value: BUTTON_VALUE.PAT_MANAGEMENT,
  },
]
