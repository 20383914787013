import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { TextField } from '@fluentui/react'
import arrowpic from '../arrow.svg'

const mockUpvalidation = [
  {
    text: 'ตัวพิมพ์เล็กอย่างน้อย 1 ตัว',
    regex: '^(?=.*[a-z])',
  },
  {
    text: 'ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว',
    regex: '(?=.*[A-Z])',
  },
  {
    text: 'ตัวเลขอย่างน้อย 1 ตัว',
    regex: '(?=.*[0-9])',
  },
  {
    text: 'ตัวอักษรหรือตัวเลขรวมกันอย่างน้อย 8 ตัว',
    regex: '(?=.{8,})',
  },
]

const Fillnewpasswordcomponent: React.FC<any> = ({ setComponentStatus, setforgotpasswordComponentStatus }: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <section className={styles.rightSideContent}>
      <div>
        <img
          onClick={() => {
            setforgotpasswordComponentStatus('email-verify')
          }}
          className={`arrowPic`}
          src={arrowpic}
          alt="arrowpic"
        />
        <div className={styles.fillNewPasswordItems}>
          <p className={styles.fillNewPasswordTopic}>ตั้งรหัสผ่านใหม่</p>
          <article>
            <p>ตั้งรหัสผ่านใหม่</p>
            <p>(+66) {values.emailorphonenumber}</p>
          </article>
          <Form>
            <section className={styles.textFieldLine}>
              <Field name="newpassword">
                {({ field }: FieldProps) => (
                  <TextField
                    type="password"
                    placeholder="รหัสผ่าน"
                    canRevealPassword
                    revealPasswordAriaLabel="Show password"
                    {...field}
                  />
                )}
              </Field>
            </section>
          </Form>
          <div className={styles.validationTextGroup}>
            {mockUpvalidation.map((validationElement: any, validationIndex: number) => {
              return (
                <p
                  key={validationIndex}
                  style={new RegExp(`${validationElement.regex}`).test(values.newpassword) ? { color: '#00e600' } : {}}
                >
                  {validationElement.text}
                </p>
              )
            })}
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setforgotpasswordComponentStatus('success')
        }}
        type="button"
        className={`orangeBtn`}
      >
        ต่อไป
      </button>
    </section>
  )
}

export default Fillnewpasswordcomponent
