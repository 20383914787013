import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { FormikInputText } from 'components/commons/input/formikInputText'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { TextField } from '@fluentui/react'
import filepic from './file.svg'
import { removeEmpty } from 'components/ChungTumTable'
//image
import ImagesGroup from '../../../components/imagesGroup'

import Examing from '../../../image/examimg.svg'
import moment from 'moment'

const Thirdpage: React.FC = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  console.error('#3 values', values)
  return (
    <div className="dp-flex wrapper2">
      <section className={styles.projectSummaryCard}>
        <article>
          <p className={styles.textTopic}>
            ชื่อโครงการ: <span>{values.projectName}</span>
          </p>
          {/* <span>
            <img className={styles.filelogo} src={filepic} alt="filepic" />
            <span>{values.projectName}</span>
          </span> */}
        </article>

        <article className={styles.letterLine}>
          <u>วันที่เริ่ม:</u>
          <span>
            {moment(new Date(values.startDate)).format('DD/MM/YYYY')} -{' '}
            {moment(new Date(values.endDate)).format('DD/MM/YYYY')}
          </span>
        </article>
        <article>
          <u>รายละเอียดเพิ่มเติม</u>
          <p>{values.projectDescription}</p>
        </article>

        <article className={styles.letterLine}>
          <p>ชื่อผู้ดูแลโครงการ</p>
          <span>{values.projectManagerName}</span>
        </article>
        <article className={styles.letterLine}>
          <u>แผนก/ตำแหน่ง</u>
          <span>{values.projectManagerPosition}</span>
        </article>
        {/* {values.TORFile && ( */}
        <article>
          <p>เอกสารแนบ</p>
          {/* I DONNO HOW THIS WORK!! */}
          <section
            className={styles.thirdpageworkType}
            // key={index}
          >
            <article>
              <img className={styles.filelogo} src={filepic} alt="filepic" /> &#9679; <span>{values.TORFile}</span>
            </article>

            {/* TODO: This should change to a function that calculates the length based on 
              empty-ness. Else this will fail when we refresh this page only - because it will 
              not have the required empty cells. */}
          </section>
        </article>
        {/* )} */}
        {values.workTypeList.map((ele: any, index: number) => (
          <div className={styles.cardJobType} key={index}>
            <article className={styles.topicWrapper}>
              <p className={styles.textTopic}>ประเภทงานที่ : {ele.workType}</p>
              <p className={styles.textTopic}>
                <article className={styles.workTypeQty}>
                  ราคา {(Math.round(ele.jobUnitPrice * 100) / 100).toLocaleString()} บาท
                </article>
                <article className={styles.workTypeQty}>จำนวนงานทั้งหมด {removeEmpty(ele.jobList).length} งาน</article>
              </p>
            </article>
            <article className={styles.cardJobContent}>
              <p className={styles.title}>
                {ele.isSafetyPassport === true ? 'มี safety Passport' : 'ไม่มี safety Passport'}
              </p>
              {ele.isSafetyPassport && <p className={styles.subTitle}>{ele.safetyPassport.join(', ')}</p>}
            </article>
            {/* <p className={styles.subTitle}>ผู้รับเหมา {ele.contractor}</p> */}
            <article className={styles.cardJobContent}>
              <p className={styles.title}>รายละเอียดคุณสมบัติของผู้รับเหมา</p>
              {ele.requirements &&
                ele.requirements.split('\n').map((requirementLine: string, requirementLineIndex: number) =>
                  requirementLine.length ? (
                    <li className={styles.subTitle} key={requirementLineIndex}>
                      {requirementLine}
                    </li>
                  ) : null,
                )}
            </article>
            <article className={styles.cardJobContent}>
              <p className={styles.title}>รูปภาพของงาน</p>
              <ImagesGroup
                onClickImage={() => console.log('image >>', ele)}
                image1={ele.imageURLList && ele.imageURLList[0] ? ele.imageURLList[0] : ''}
                image2={ele.imageURLList && ele.imageURLList[1] ? ele.imageURLList[1] : ''}
                image3={ele.imageURLList && ele.imageURLList[2] ? ele.imageURLList[2] : ''}
                image4={ele.imageURLList && ele.imageURLList[3] ? ele.imageURLList[3] : ''}
              />
            </article>
          </div>
        ))}
        <article className={styles.summaryWorkQty}>
          รวมปริมาณงานทั้งหมด&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {values.workTypeList &&
            values.workTypeList
              .map((workType: any) => removeEmpty(workType.jobList).length)
              .reduce((prev: number, curr: number) => prev + curr)}
          &nbsp; งาน
        </article>
        <article className={styles.summaryWorkPrice}>
          รวมราคางานทั้งหมด&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {values.workTypeList &&
            values.workTypeList
              .map((workType: any) => workType.jobUnitPrice * removeEmpty(workType.jobList).length)
              .reduce((prev: number, curr: number) => prev + curr)}
          &nbsp; บาท
        </article>
      </section>
    </div>
  )
}

export default Thirdpage
