import React from 'react'
import styles from './index.module.scss'
import closeIcon from './close.svg'
import TechImg from './TechImg.svg'
import { Modal, Backdrop, Avatar } from '@mui/material'
import DelIcon from './del.svg'
import { useDispatch } from 'react-redux'

import allAction from '../../state/actions'
import { Field, useFormikContext } from 'formik'
import { CreateTechInProject, DeleteUserAcceptInProject } from 'adapter/xhr'
const ModalMenu: React.FC<any> = (props: any): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const dispatch = useDispatch()

  const TechJobTypeArr: any = {
    Electrician: 'ช่างไฟฟ้า',
    Painter: 'ช่างสี',
    Plumber: 'ช่างปะปา',
    Plasterer: 'ช่างปูน',
    Metalworker: 'ช่างเหล็ก',
    Carpenter: 'ช่างไม้',
    Mechanic: 'ช่างยนต์',
    Installer: 'ช่างติดตั้ง',
  }
  return (
    <Modal
      open={props.open}
      disablePortal={true}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      sx={{ border: 'none' }}
      // BackdropComponent={Backdrop}
    >
      <div className={styles.modalBox}>
        <div className={styles.cardModal}>
          <div className={styles.head}>
            <p className={styles.textHead}>รายชื่อทีมงาน</p>
            <img
              src={closeIcon}
              alt=""
              onClick={() => {
                props.setOpen(false)
              }}
              className={styles.Closeicon}
            />
          </div>
          <section className={styles.InputFiled}>
            <Field
              className={styles.inputText}
              type="text"
              name="technicianListSearch"
              placeholder="Username ชื่อทีม/ชื่อองค์กร"
            />
          </section>
          <section className={styles.TechBox}>
            {/* {console.log(
              props.projectList
                .find((findEle: any) => findEle.projectId === props.projectIdModal)
                ?.acceptedUserIdList?.filter((item: any) => {
                  if (
                    props.projectList.find((findEle: any) => findEle.projectId === props.projectIdModal)
                      ?.acceptedUserIdList === ''
                  ) {
                    return item
                  }
                })
                .map((item: any) => {
                  return item
                }),
              'props.projectList',
            )} */}

            {props.projectList
              .find((findEle: any) => findEle.projectId === props.projectIdModal)
              ?.acceptedUserIdList?.filter((TechItem: any) => {
                if (values.technicianListSearch === '') {
                  return TechItem
                } else if (
                  TechItem.getUser.username
                    ?.toLocaleLowerCase()
                    .includes(values.technicianListSearch.toLocaleLowerCase())
                ) {
                  return TechItem
                }
              })
              .map((TechItem: any, TechIndex: number) => (
                <div key={TechIndex} className={styles.dataBox}>
                  <div className={styles.grop}>
                    <Avatar alt={`${TechItem.userId}`} src="/static/images/avatar/5.jpg" className={styles.avatar} />
                    <div className={styles.TextGroup}>
                      <span>
                        {TechItem.getUser.username}
                        {TechItem.getUser.technicianJobType && (
                          <span className={styles.textType}>
                            {' '}
                            -
                            {TechItem.getUser.technicianJobType?.slice(0, 2).map((TypeJob: any, index: number) => (
                              <span className={styles.textJobType} key={index}>
                                {TechJobTypeArr[`${TypeJob}`]}
                              </span>
                            ))}
                            {2 < TechItem.technicianJobType?.length && (
                              <span className={styles.textJobType}>+{TechItem.technicianJobType?.length - 2}</span>
                            )}
                          </span>
                        )}
                      </span>
                      <p className={styles.textType}>{TechItem.getUser.email}</p>
                    </div>
                  </div>

                  <img
                    src={DelIcon}
                    alt=""
                    className={styles.addIcon}
                    onClick={() => {
                      dispatch(
                        allAction.modalConfirmAction.setOpenModalConfirm(
                          `คุณต้องการลบทีมงาน ${TechItem.getUser.username}`,
                          '',
                          '',
                          'ใช่,ลบทีมงาน',
                          () => {
                            DeleteUserAcceptInProject({
                              projectId: props.projectIdModal,
                              acceptedUserId: TechItem.userId,
                            }).then((res: any) => {
                              dispatch(
                                allAction.modalAction.setOpenModalAlertStatus(
                                  `ลบทีมงาน ${TechItem.getUser.username} เรียบร้อยแล้ว`,
                                  '',
                                  'success',
                                ),
                              )
                              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                              props.setOpen(false)
                              setTimeout(() => {
                                props.queryProjectList()
                              }, 1000)

                              console.log(res, 'Accept User In Project')
                            })
                          },
                        ),
                      )
                    }}
                  />
                </div>
              ))}
          </section>
        </div>
      </div>
    </Modal>
  )
}
export default ModalMenu
