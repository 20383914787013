import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { useFormikContext, withFormik, Form, Field, FieldProps, ErrorMessage, setNestedObjectValues } from 'formik'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import ModalView from './modal'
import { ChangePasswordUser } from 'adapter/xhr'
import allAction from 'state/actions'

const ForgotPasswordpage: React.FC = (): JSX.Element => {
  const { customerOrTechnician } = useParams<any>()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [image, setImage] = useState('')
  const { values, errors, touched, setFieldValue, validateForm, setTouched } = useFormikContext<any>()

  console.log('valueeee', values)
  return (
    <>
      <div className={styles.container}>
        <div className={styles.cardforgot}>
          <section className={styles.title}>
            <p className={styles.texthead}>เปลี่ยนรหัสผ่าน</p>
            <p>กรุณาอย่าเปิดเผยรหัสผ่านให้ผู้อื่นรู้ เพื่อความปลอดภัยของบัญชีผู้ใช้เอง </p>
          </section>
          <section className={styles.input}>
            <div className={styles.GroupInput}>
              <p>รหัสผ่านปัจจุบัน</p>
              <Field className={styles.inputfiled} type="text" name="currentPassword" placeholder="รหัสผ่านปัจจุบัน" />
            </div>
            <div className={styles.GroupInput}>
              {' '}
              <p></p>
              <p className={styles.errorBox}>
                <ErrorMessage name="currentPassword" />
              </p>
            </div>

            <div className={styles.GroupInput}>
              <p></p>
              <a className={styles.forgotBox} onClick={() => setOpen(true)}>
                ลืมรหัสผ่าน ?
              </a>
            </div>

            <div className={styles.GroupInput}>
              <p>รหัสผ่านใหม่</p>
              <Field className={styles.inputfiled} type="text" name="newPassword" placeholder="รหัสผ่านใหม่" />
            </div>
            <div className={styles.GroupInput}>
              {' '}
              <p></p>
              <p className={styles.errorBox}>
                <ErrorMessage name="newPassword" />
              </p>
            </div>
            <div className={styles.GroupInput}>
              <p>ยืนยันรหัสผ่าน</p>

              <Field
                className={styles.inputfiled}
                type="text"
                name="confirmNewPassword"
                placeholder="ยืนยันรหัสผ่าน"
                validate
              />
            </div>
            <div className={styles.GroupInput}>
              {' '}
              <p></p>
              <p className={styles.errorBox}>
                <ErrorMessage name="confirmNewPassword" />
              </p>
            </div>
          </section>
        </div>
        <section className={styles.button}>
          <article className={styles.btnFooterWrapper}>
            <button type="button" className={styles.btnCancle} onClick={() => navigate(-1)}>
              ยกเลิก
            </button>
            <button
              type="button"
              className={styles.btnConfirm}
              onClick={() => {
                validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    // setpagestatus('secondstep')
                    const ChangPasswordInput: any = {
                      input: {
                        currentPassword: values.currentPassword,
                        newPassword: values.newPassword,
                        confirmNewPassword: values.confirmNewPassword,
                      },
                    }
                    console.log(ChangPasswordInput, 'ChangPasswordInput')
                    ChangePasswordUser(ChangPasswordInput).then((res: any) => {
                      if (res.data.errors) {
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus(
                            'รหัสผ่านปัจจุบันไม่ถูกต้องกรุณาลองอีกครั้ง',
                            '',
                            'error',
                          ),
                        )
                      } else {
                        dispatch(allAction.modalAction.setOpenModalAlertStatus('เปลี่ยนรหัสผ่านสำเร็จ', '', 'success'))
                        localStorage.clear()
                        navigate(`/${customerOrTechnician}/login`)
                      }
                    })
                  } else {
                    setTouched(setNestedObjectValues(errors, true))
                  }
                })
              }}
            >
              ยืนยัน
            </button>
          </article>
        </section>
        <ModalView open={open} setOpen={setOpen} />
      </div>
    </>
  )
}

const EnhancedForgotPasswordpage = withFormik({
  mapPropsToValues: () => ({
    userName: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }),
  validate: (values) => {
    const errors: any = {}
    const psswordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    if (values.newPassword !== values.confirmNewPassword) {
      errors.confirmNewPassword = 'รหัสผ่านยืนยันไม่ตรงกัน'
    }
    if (!values.currentPassword) {
      errors.currentPassword = 'กรุณาใส่รหัสผ่านปัจจุบัน'
    }

    if (!values.newPassword) {
      errors.newPassword = 'กรุณาใส่รหัสผ่านใหม่'
    } else if (!psswordReg.test(values.newPassword)) {
      errors.newPassword =
        'รหัสผ่านต้องมีความอย่างอย่างน้อย 8 อักขระ ประกอบด้วยอักษรตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว อักษรตัวพิมพ์เล็กหนึ่งตัว ตัวเลขหนึ่งตัว และอักขระพิเศษหนึ่งตัว'
    }
    return errors
  },
  handleSubmit: (values, { setFieldValue }) => {
    console.log(values)
    console.log(JSON.stringify(values))
  },
})(ForgotPasswordpage)

export default EnhancedForgotPasswordpage
