import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps, ErrorMessage, setNestedObjectValues } from 'formik'
import filepic from '../myProjectPage/file.svg'
import { Modal } from '@fluentui/react'
import xSVG from './xsvg.svg'
import MultiDropdown from '../../components/MultiDropdown'
import FooterComponant from 'components/commons/btnFooter'
import CheckboxTable from 'components/CheckboxTable'
import { downloadFile } from '../../utils/common'

export const ApplyJobTopic = () => {
  const filename = (file: string) => decodeURI(file.split('/')[file.split('/').length - 1])
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <>
      <div className={styles.leftSide}>
        <h1 className={styles.title}>{values.post.title}</h1>
        <article></article>
        <article>
          <b>ระยะเวลาโครงการ</b> {'   '}
          {new Date(values.post.startDate).toLocaleDateString('th-TH', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}{' '}
          -{' '}
          {new Date(values.post.endDate).toLocaleDateString('th-TH', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </article>
        <section className={`dp-flex ${styles.applyJobfileContainer}`}>
          {values.post.fileAttachmentList &&
            values.post.fileAttachmentList.map((fileURL: string, fileIndex: number) => {
              return (
                <div className={styles.fileLine} key={fileIndex}>
                  <img src={filepic} alt="filepic" style={{ width: '25px' }} />
                  <div className={styles.filetext} onClick={() => downloadFile(fileURL, filename(fileURL))}>
                    <p>{filename(fileURL)}</p>
                  </div>
                </div>
              )
            })}
        </section>
      </div>
      <div className={styles.rightSide}>
        <article>{new Date().toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</article>
      </div>
    </>
  )
}

export const packPostFromPost = (post: any) => {
  post.workTypePostList.map((workType: any, workTypeIndex: number) => {
    workType.imageURLList = post.project.workTypeList[workTypeIndex].imageURLList
  })
  delete post.project
  return post
}

const mockUpRows = [
  {
    name: 'changTop',
    myWorkTechnicianJobType: ['Electrician', 'Painter', 'Plumber'],
    safetyPassport: [
      {
        safetyPassportStatus: true,
        safetyPassportType: 'AIS_G',
      },
      {
        safetyPassportStatus: true,
        safetyPassportType: 'AIS_EE',
      },
      {
        safetyPassportStatus: true,
        safetyPassportType: 'AIS_H',
      },
    ],
  },
]

const generateMockUpRows = [
  {
    name: 'changTop',
    myWorkTechnicianJobType: 'Electrician, Painter, Plumber',
    safetyPassport: 'AIS_G, AIS_EE, AIS_H',
  },
]
const TechJobTypeArr: any = {
  Electrician: 'ช่างไฟฟ้า',
  Painter: 'ช่างสี',
  Plumber: 'ช่างปะปา',
  Plasterer: 'ช่างปูน',
  Metalworker: 'ช่างเหล็ก',
  Carpenter: 'ช่างไม้',
  Mechanic: 'ช่างยนต์',
  Installer: 'ช่างติดตั้ง',
}
const filterBox = (values: any) => {
  let cloneTechnicianList = values.technicianList.filter((technician: any) => technician.status === 'ACTIVE')
  if (values.searchName) {
    cloneTechnicianList = cloneTechnicianList.filter((technicianElement: any) => {
      const firstAndLastName = technicianElement.firstName + technicianElement.lastName
      if (firstAndLastName.toLowerCase().startsWith(values.searchName.replace(' ', '').toLowerCase())) {
        return technicianElement
      }
    })
  }
  console.log(cloneTechnicianList, 'cloneTechnicianList')
  if (values.selectedTechnicianWorkType.length > 0) {
    cloneTechnicianList = cloneTechnicianList.filter((technicianElement: any) => {
      console.log(technicianElement.technicianJobType, 'RES')
      if (
        technicianElement.technicianJobType
          // ?.split(',')
          .filter((jobType: string) => values.selectedTechnicianWorkType.includes(jobType)).length ===
        values.selectedTechnicianWorkType.length
      ) {
        return technicianElement
      }
    })
  }
  if (values.selectedSafetyPassport.length > 0) {
    cloneTechnicianList = cloneTechnicianList.filter((technicianElement: any) => {
      if (
        technicianElement.safetyPassport
          .split(',')
          .filter((jobType: string) => values.selectedSafetyPassport.includes(jobType)).length ===
        values.selectedSafetyPassport.length
      ) {
        return technicianElement
      }
    })
  }
  return cloneTechnicianList
}

export const ModalSelectTeamMember = (props: any) => {
  const { values, errors, setFieldValue, validateForm, setTouched } = useFormikContext<any>()

  console.log(values.technicianList, 'values.technicianList')

  React.useEffect(() => {
    setFieldValue('isHaveSafety', 'true')
    setFieldValue(
      'selectedSafetyPassport',
      values.safetyPassport.map((e: any) => e.value),
    )
  }, [])

  React.useEffect(() => {
    if (values.selectedSafetyPassport.length) {
      setFieldValue('isHaveSafety', 'true')
    } else {
      setFieldValue('isHaveSafety', 'false')
    }
  }, [values.selectedSafetyPassport])

  const validateSelectedTechnicianList = (value: any[]) => {
    if (!value.length) return '*Required'
  }

  return (
    <Modal
      isOpen={props.isShowModalSelectTeam}
      onDismiss={() => {
        props.setIsShowModalSelectTeam(false)
      }}
      // isBlocking={true}
      containerClassName={`${styles.modalSelect} wrapper2`}
    >
      <div className={styles.innerContainer}>
        <section className={styles.title}>
          <Field style={{ display: 'none' }} name="selectedTechnicianList" validate={validateSelectedTechnicianList} />
          <h1>
            จำนวนทีมงาน ({values.selectedTechnicianList.length}){' '}
            <span className="errorMessage">
              <ErrorMessage name="selectedTechnicianList" />
            </span>
          </h1>
          <img
            onClick={() => {
              props.setIsShowModalSelectTeam(false)
            }}
            src={xSVG}
            alt=""
            style={{ cursor: 'pointer' }}
          />
        </section>
        <section className={styles.contentCard}>
          <h3 className={styles.contentCardHeading}>รายชื่อทีมงาน</h3>
          <div className={styles.inputSection}>
            <article className={styles.inputGroup}>
              <p className={styles.inputLabel}>ชื่อ - นามสกุล</p>
              <Field className={styles.searchName} type="text" name="searchName" />
            </article>
            <article className={`${styles.inputGroup} ${styles.technicianWorkType}`}>
              <p className={styles.inputLabel}>ประเภทการช่าง</p>
              <MultiDropdown
                options={values.technicianWorkType}
                name="selectedTechnicianWorkType"
                placeHolder="เลือกประเภทงานช่างทั้งหมด"
                values={values.selectedTechnicianWorkType}
              />
            </article>
          </div>
          <article className={`${styles.inputGroup} ${styles.safetyPassport}`}>
            <p className={styles.inputLabel}>SafetyPassport</p>
            <section className={styles.inputLine}>
              <div className={styles.safetyPassportRadioGroup}>
                <Field
                  onChange={() => {
                    setFieldValue(
                      'selectedSafetyPassport',
                      values.safetyPassport.map((e: any) => e.value),
                    )
                    setFieldValue('isHaveSafety', 'true')
                  }}
                  type="radio"
                  name="isHaveSafety"
                  value={'true'}
                />
                มี
                <Field
                  onChange={() => {
                    setFieldValue('selectedSafetyPassport', [])
                    setFieldValue('isHaveSafety', 'false')
                  }}
                  type="radio"
                  name="isHaveSafety"
                  value={'false'}
                />
                ไม่มี
              </div>

              <MultiDropdown
                options={values.safetyPassport}
                name="selectedSafetyPassport"
                placeHolder="เลือกSafetyPassport ทั้งหมด"
                values={values.selectedSafetyPassport}
              />
            </section>
          </article>
          <div className={styles.technicianTableContainer}>
            <CheckboxTable rows={filterBox(values)} />
          </div>
        </section>
      </div>
      <FooterComponant
        listButtons={[
          {
            fucnctionOnclick: () => {
              props.setIsShowModalSelectTeam(false)
            },
            text: 'ย้อนกลับ',
            type: 'button',
            class: 'btnDefault3',
          },
          {
            fucnctionOnclick: (e) => {
              validateForm().then((err) => {
                if (Object.keys(err).length) {
                  setTouched(setNestedObjectValues(errors, true))
                  return
                }
                props.confirmOnClick(e)
              })
            },
            // fucnctionOnclick: () => {
            //   console.log(values, 'valuess')
            // },
            text: 'ยืนยัน',
            type: 'button',
            class: 'btnDefault2',
          },
        ]}
      />
    </Modal>
  )
}
