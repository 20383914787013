import React, { useEffect, useMemo } from 'react'
import useChartDimensions, { Dimensions } from 'hook/useChartDimensions'
import { ReactComponent as AntennaGuyed } from './antennaGuyed.svg'
import { ReactComponent as AntennaSelfSupport } from './antennaSelfSupport.svg'
import { ReactComponent as AntennaSteelPipe } from './antennaSteelPipe.svg'
import { ReactComponent as AntennaSteelPipeNoLegs } from './antennaSteelPipeNoLegs.svg'
import { ReactComponent as AntennaConcretePole } from './antennaConcretePole.svg'
import { ReactComponent as AntennaMonoPole } from './antennaMonoPole.svg'
import { Antenna, ColorGroup, Tower_Type, Antenna_Type } from 'utils/generated'
import { renderToString } from 'react-dom/server'

const defaultChartSettings = {
  marginTop: 60,
  marginBottom: 65,
  marginLeft: 80,
  marginRight: 80,
  height: 300,
}

// Each is 30x15, with Gap 5
// Need to handle 4*4 = 16 max ants
const ANT_WIDTH = 20
const ANT_HEIGHT = 12
const ANT_GAP = 4
const GROUP_MAX_X = 4
const GROUP_MAX_Y = 4
// Width 30*4 + Gap 5*3
const GROUP_WIDTH = ANT_WIDTH * GROUP_MAX_X + ANT_GAP * (GROUP_MAX_X - 1)
// Height 15*4 + Gap 5:3
const GROUP_HEIGHT = ANT_HEIGHT * GROUP_MAX_Y + ANT_GAP * (GROUP_MAX_Y - 1)

interface AntennaRenderProps {
  poleType: Tower_Type
  hasLegs: boolean
  antenna: Antenna
  setAntenna?: React.Dispatch<React.SetStateAction<Antenna>>
  draggable?: boolean
  exportSVG?: boolean
  chartSettings?: Dimensions
}

const AntennaRenderComponent = ({
  poleType,
  hasLegs,
  draggable,
  antenna,
  setAntenna,
  exportSVG,
  chartSettings,
}: AntennaRenderProps) => {
  const [ref, dms] = useChartDimensions({ ...defaultChartSettings, ...chartSettings })
  const [AntennaSVG, { A, B, C, D }] = useAntennaSVG(poleType, hasLegs, dms.boundedWidth, dms.boundedHeight)
  const colorMap = useMemo(
    () =>
      (antenna.colorGroup || []).reduce<ColorMap>((prev, curr) => {
        prev[curr.id] = {
          antennaType: curr.antennaType,
          color: curr.color,
        }
        return prev
      }, {}),
    [antenna],
  )

  const onDragover = (e: React.DragEvent<SVGRectElement>) => {
    e.preventDefault()
    console.log('dragover')
  }
  const onDragLeave = (e: React.DragEvent<SVGRectElement>) => {
    console.log('dragLeave')
  }
  const onDrop = (e: React.DragEvent<SVGRectElement>) => {
    e.preventDefault()
    if (draggable && setAntenna) {
      console.log('ROLLOING ON DROP', e)
      console.log(e.dataTransfer.getData('Text'))
      console.log(e.currentTarget)
      console.log(e.currentTarget.getAttribute('name'))
      const colorGroupID = parseInt(e.dataTransfer.getData('Text'))
      const pointName = e.currentTarget.getAttribute('name') as 'A' | 'B' | 'C' | 'D'

      if (antenna[pointName]) {
        antenna[pointName]!.push(colorGroupID)
        antenna[pointName]!.sort()
        setAntenna({ ...antenna })
      }
    }
  }
  const onMouseDown = (e: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    console.log('CLIKERY')

    if (draggable && setAntenna) {
      const pointName = e.currentTarget.getAttribute('name') as 'A' | 'B' | 'C' | 'D'
      const index = e.currentTarget.getAttribute('id')
      console.log(pointName, index)
      if (pointName && index) {
        antenna[pointName]?.splice(parseInt(index), 1)
        setAntenna({ ...antenna })
      }
    }
  }

  const Boundary = ({ point, pointName }: { point: Point; pointName: 'A' | 'B' | 'C' | 'D' }) => {
    return (
      <g transform={`translate(${point.x}, ${point.y}) ${point.rotate ? `rotate(${point.rotate})` : ''}`}>
        {/* <circle x="0" y="0" r={10} fill="red" /> */}

        {/* Rectangle to handle Dragging Events Area */}
        <rect
          x={-ANT_GAP}
          y={-ANT_GAP}
          width={GROUP_WIDTH + ANT_GAP * 2}
          height={GROUP_HEIGHT + ANT_GAP * 2}
          rx="8"
          fill="lavender"
          stroke="lightgray"
          fillOpacity={0.2}
          name={pointName}
          onDragOver={onDragover}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onMouseDown={onMouseDown}
        />

        {/* Actual Data */}
        {antenna[pointName] &&
          antenna[pointName]!.length > 0 &&
          antenna[pointName]!.map((cgIndex, index) => {
            const antType = colorMap[cgIndex].antennaType
            const xPoint = isCircle(antType)
              ? indexToX(index, GROUP_MAX_X) * (ANT_WIDTH + ANT_GAP) + ANT_WIDTH / 8
              : indexToX(index, GROUP_MAX_X) * (ANT_WIDTH + ANT_GAP)
            const yPoint =
              point.orientation === 'bottomUp'
                ? (GROUP_MAX_Y - 1 - indexToY(index, GROUP_MAX_X)) * (ANT_HEIGHT + ANT_GAP)
                : indexToY(index, GROUP_MAX_X) * (ANT_HEIGHT + ANT_GAP)
            return (
              <React.Fragment key={index}>
                <rect
                  name={pointName}
                  id={`${index}`}
                  x={xPoint}
                  y={yPoint}
                  width={isCircle(antType) ? ANT_HEIGHT + 3 : ANT_WIDTH}
                  height={isCircle(antType) ? ANT_HEIGHT + 3 : ANT_HEIGHT}
                  rx={getRadius(antType, ANT_HEIGHT)}
                  style={{ cursor: draggable ? 'pointer' : 'auto' }}
                  fill={colorMap[cgIndex].color}
                  onDragOver={onDragover}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  onMouseDown={onMouseDown}
                />
                {antType === Antenna_Type.ParabolicGrid &&
                  [-1, 0, 1].map((dashV, dashI) => (
                    <React.Fragment key={dashI}>
                      <path
                        stroke="white"
                        d={`M${xPoint + dashV * 5},${yPoint} L${xPoint + ANT_HEIGHT + ANT_GAP + dashV * 5},${
                          yPoint + ANT_HEIGHT + ANT_GAP
                        }Z`}
                      />
                      <path
                        stroke="white"
                        d={`M${xPoint + dashV * 5},${yPoint + ANT_HEIGHT + ANT_GAP} L${
                          xPoint + ANT_HEIGHT + ANT_GAP + dashV * 5
                        },${yPoint}Z`}
                      />
                    </React.Fragment>
                  ))}
              </React.Fragment>
            )
          })}
      </g>
    )
  }
  const renderGraph = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={dms.width}
      height={dms.height}
      viewBox={`0 0 ${dms.width} ${dms.height}`}
    >
      <rect width="100%" height="100%" fill="lightyellow" fillOpacity={0} />

      {/* Graph Boundary */}
      <g transform={`translate(${dms.marginLeft}, ${dms.marginTop})`}>
        <rect width={dms.boundedWidth} height={dms.boundedHeight} fill="papayawhip" fillOpacity={0} />

        {/* Check Points */}
        {/* {[A, B, C, D].map(
            (point, pointIndex) => point && <circle key={pointIndex} fill="purple" cx={point.x} cy={point.y} r={10} />,
          )} */}

        {/* A Boundary */}
        <Boundary point={A} pointName="A" />

        {/* B Boundary */}
        <Boundary point={B} pointName="B" />

        {/* C Boundary */}
        <Boundary point={C} pointName="C" />

        {/* D Boundary */}
        {D && <Boundary point={D} pointName="D" />}

        <AntennaSVG width={dms.boundedWidth} height={dms.boundedHeight} style={{ border: '2px solid red' }} />
      </g>
    </svg>
  )
  if (exportSVG) return <>{renderGraph()}</>
  return (
    <div className="Chart_wrapper" ref={ref}>
      {renderGraph()}
    </div>
  )
}

export default AntennaRenderComponent

export const getAntennaSVGString = ({ poleType, hasLegs, antenna }: AntennaRenderProps) => {
  return renderToString(<AntennaRenderComponent poleType={poleType} hasLegs={hasLegs} antenna={antenna} />)
}

// How to convert SVG to PNG with JavaScript
// https://gist.github.com/tatsuyasusukida/1261585e3422da5645a1cbb9cf8813d6

export const getAntennaPNGBase64 = async ({ poleType, hasLegs, antenna }: AntennaRenderProps) => {
  const svgString = renderToString(
    <AntennaRenderComponent
      poleType={poleType}
      hasLegs={hasLegs}
      antenna={antenna}
      exportSVG
      chartSettings={{ width: 367, height: 300 }}
    />,
  )
  // const svgString = renderToString(
  //   <svg xmlns="http://www.w3.org/2000/svg" height="100" width="100">
  //     <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="3" fill="red" />
  //   </svg>,
  // )

  const svgDataBase64 = btoa(unescape(encodeURIComponent(svgString))) // Similar to Buffer.from(text).toString('base64')
  const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`

  // const image = await loadImage(svgDataUrl)

  // const canvas = document.createElement('canvas')
  // const width = '250'
  // const height = '250'
  // canvas.setAttribute('width', width)
  // canvas.setAttribute('height', height)
  // const ctx = canvas.getContext('2d')
  // ctx?.beginPath()
  // ctx?.moveTo(20, 20)
  // ctx?.lineTo(20, 100)
  // ctx?.lineTo(70, 100)
  // ctx?.stroke()
  // ctx?.drawImage(image, 0, 0, parseInt(width), parseInt(height))
  // const dataurl = canvas.toDataURL('image/png')
  console.log('WAIT FOR DATAURL')
  let dataurl
  const img = new Image(367, 300)
  if (img.complete) {
    img.src = svgDataUrl
    console.log('I LOADED IMAGE!', img)
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    ctx?.drawImage(img, 0, 0, img.width, img.height)
    dataurl = canvas.toDataURL('image/png')
  }
  // const dataurl = await base64SvgToBase64Png(svgDataUrl, 250)
  console.log('dataurl', dataurl)

  return dataurl
}
// Making an image is a promise!!
//  Inspired from https://stackoverflow.com/questions/3975499/convert-svg-to-image-jpeg-png-etc-in-the-browser
const loadImage = async (url: string): Promise<HTMLImageElement> => {
  const image = document.createElement('img')
  image.src = url
  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image)
    image.onerror = reject
    image.src = url
  })
}

/**
 * converts a base64 encoded data url SVG image to a PNG image
 * https://stackoverflow.com/questions/3975499/convert-svg-to-image-jpeg-png-etc-in-the-browser
 * @param originalBase64 data url of svg image
 * @param width target width in pixel of PNG image
 * @return {Promise<String>} resolves to png data url of the image
 */
function base64SvgToBase64Png(originalBase64: string, width: number): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.addEventListener('load', function () {
      // document.body.appendChild(img)
      const canvas = document.createElement('canvas')
      const ratio = img.clientWidth / img.clientHeight || 1
      // document.body.removeChild(img)
      canvas.width = width
      canvas.height = width / ratio
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)
      try {
        const data = canvas.toDataURL('image/png')
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
    img.addEventListener('error', function (e) {
      reject(e)
    })
    img.src = originalBase64
  })
}

type ColorMap = Record<
  string,
  {
    antennaType: Antenna_Type
    color: string
  }
>

interface Point {
  x: number
  y: number
  rotate?: number
  orientation?: 'bottomUp' | 'topDown'
}
interface ABCDPoint {
  A: Point
  B: Point
  C: Point
  D?: Point
}
// Returns the Left Top of the group area
const useAntennaSVG = (
  poleType: Tower_Type = Tower_Type.Guyed,
  hasLegs: boolean,
  boundedWidth: number,
  boundedHeight: number,
): [React.FC<React.SVGProps<SVGSVGElement>>, ABCDPoint] => {
  if (poleType === Tower_Type.Guyed)
    return [
      AntennaGuyed,
      {
        A: { x: boundedWidth / 2 - GROUP_WIDTH / 2, y: -GROUP_HEIGHT + 5, orientation: 'bottomUp' },
        B: { x: boundedWidth - GROUP_WIDTH / 2, y: boundedHeight - 15 },
        C: { x: 0 + 15 - GROUP_WIDTH / 2, y: boundedHeight - 15 },
      },
    ]
  else if (poleType === Tower_Type.Selfsupport) {
    if (hasLegs) {
      return [
        AntennaGuyed,
        {
          A: { x: boundedWidth / 2 - GROUP_WIDTH / 2, y: -GROUP_HEIGHT + 5, orientation: 'bottomUp' },
          B: { x: boundedWidth - GROUP_WIDTH / 2, y: boundedHeight - 15 },
          C: { x: 0 + 15 - GROUP_WIDTH / 2, y: boundedHeight - 15 },
        },
      ]
    } else {
      return [
        AntennaSelfSupport,
        {
          A: { x: 0 - GROUP_WIDTH / 2, y: -GROUP_HEIGHT + 5, orientation: 'bottomUp' },
          B: { x: boundedWidth - GROUP_WIDTH / 2, y: -GROUP_HEIGHT + 5, orientation: 'bottomUp' },
          C: { x: boundedWidth - GROUP_WIDTH / 2, y: boundedHeight },
          D: { x: 0 - GROUP_WIDTH / 2, y: boundedHeight },
        },
      ]
    }
  } else if (poleType === Tower_Type.Steelpipe && !hasLegs)
    return [
      AntennaSteelPipeNoLegs,
      {
        A: { x: 0, y: -GROUP_HEIGHT, orientation: 'bottomUp' },
        B: { x: boundedWidth - GROUP_WIDTH / 2, y: boundedHeight - 15 },
        C: { x: 0 + 20, y: boundedHeight - GROUP_WIDTH, rotate: 105 },
      },
    ]
  else if (poleType === Tower_Type.Steelpipe)
    return [
      AntennaSteelPipe,
      {
        A: { x: 0, y: -GROUP_HEIGHT, orientation: 'bottomUp' },
        B: { x: boundedWidth - GROUP_WIDTH / 2, y: boundedHeight - 15 },
        C: { x: 0 + 20, y: boundedHeight - GROUP_WIDTH, rotate: 105 },
      },
    ]
  else if (poleType === Tower_Type.Concretepole)
    return [
      AntennaConcretePole,
      {
        A: { x: 0 + GROUP_WIDTH / 2 + 15, y: -GROUP_HEIGHT, orientation: 'bottomUp' },
        B: {
          x: boundedWidth + GROUP_WIDTH / 2 - 15,
          y: boundedHeight / 2 - GROUP_HEIGHT / 2 - 25,
          rotate: 90,
          orientation: 'bottomUp',
        },
        C: { x: 0 + GROUP_WIDTH / 2 + 15, y: boundedHeight },
        D: { x: 0 + 25, y: boundedHeight / 2 - GROUP_HEIGHT / 2 - 25, rotate: 90 },
      },
    ]
  else if (poleType === Tower_Type.Monopole)
    return [
      AntennaMonoPole,
      {
        A: { x: 0 + GROUP_WIDTH / 2 + 15, y: -GROUP_HEIGHT, orientation: 'bottomUp' },
        B: {
          x: boundedWidth + GROUP_WIDTH / 2 - 15,
          y: boundedHeight / 2 - GROUP_HEIGHT / 2 - 25,
          rotate: 90,
          orientation: 'bottomUp',
        },
        C: { x: 0 + GROUP_WIDTH / 2 + 15, y: boundedHeight },
        D: { x: 0 + 25, y: boundedHeight / 2 - GROUP_HEIGHT / 2 - 25, rotate: 90 },
      },
    ]
  return [
    AntennaGuyed,
    {
      A: { x: boundedWidth / 2 - GROUP_WIDTH / 2, y: -GROUP_HEIGHT + 5, orientation: 'bottomUp' },
      B: { x: boundedWidth - GROUP_WIDTH / 2, y: boundedHeight - 15 },
      C: { x: 0 + 15 - GROUP_WIDTH / 2, y: boundedHeight - 15 },
    },
  ]
}

const indexToX = (index: number, max_x: number) => index % max_x
const indexToY = (index: number, max_x: number) => Math.floor(index / max_x)

export const isCircle = (antennaType: Antenna_Type) =>
  antennaType === Antenna_Type.Parabolic || antennaType === Antenna_Type.ParabolicGrid ? true : false
const getRadius = (antennaType: Antenna_Type, length: number) => {
  if (antennaType === Antenna_Type.Parabolic || antennaType === Antenna_Type.ParabolicGrid) return (length + 3) / 2
  return 5
}
