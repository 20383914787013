import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { Link, useLocation } from 'react-router-dom'
import changtumlogo from './changtumlogo.svg'
import addfriendpic from './addfriendbtn.svg'
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  ContextualMenu,
  Toggle,
  Modal,
  IDragOptions,
  IIconProps,
  Stack,
  IStackProps,
} from '@fluentui/react'
import { useState } from 'react'
import qrCode from './lineQRCode.png'

const mockUpInput = [
  {
    inputLine: [
      {
        placeholder: 'ชื่อ*',
        name: 'firstname',
      },
      {
        placeholder: 'นามสกุล*',
        name: 'lastname',
      },
    ],
  },
  {
    inputLine: [
      {
        placeholder: 'เบอร์*',
        name: 'tel',
      },
      {
        placeholder: 'บริษัท',
        name: 'companyname',
      },
    ],
  },
]

const Footercomponent: React.FC = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const { pathname } = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const lineBtnFunction = () => {
    if (window.innerWidth <= 426) {
      window.location.href = 'https://lin.ee/GWOQvOo'
      // window.open('https://lin.ee/GWOQvOo', '_blank')
    } else {
      setIsModalOpen(true)
    }
  }

  const sendMail = () => {
    const mailto = `mailto:nattawatjantar@gmail.com?subject=LandingpageChangTumWebsite&body=ชื่อ${values.firstname}`
    window.location.href = mailto
    // window.open(mailto)
  }

  return (
    <div className={styles.footerBlock}>
      <h1>ติดต่อเรา</h1>
      <p style={{ color: '#C66F29', margin: '0 0 20px 0' }}>แสดงความคิดเห็น แนะนำ หรือคำถามเพิ่มเติมอื่นๆ </p>
      <div className={styles.inputGroup}>
        {mockUpInput.map((inputElement: any, inputIndex: number) => {
          return (
            <div className={styles.inputline} key={inputIndex}>
              {inputElement.inputLine.map((inputLineElement: any, inputLineIndex: number) => {
                return (
                  <Field
                    key={inputLineIndex}
                    name={inputLineElement.name}
                    className={`${styles.footerInputText}`}
                    placeholder={inputLineElement.placeholder}
                    type="text"
                  />
                )
              })}
            </div>
          )
        })}
        <Field
          as="textarea"
          className={styles.footerInputTextarea}
          placeholder="รายละเอียด"
          style={{ resize: 'none', height: '50px', margin: '0 0 40px 0' }}
          name="footerRemark"
        />
      </div>
      <button
        className={styles.footerBtn}
        onClick={() => {
          sendMail()
        }}
        type="button"
      >
        ส่งข้อความ
      </button>
      <section className={styles.footerContactCard}>
        <article className={styles.footerContactCardTopic}>
          <img className={styles.footerContactCardImg} src={changtumlogo} alt="changtumlogo" />
          <h1 style={{ fontSize: '30px' }}>ChangTum</h1>
        </article>
        <section className={styles.footerSideComponent}>
          <article className={styles.footerLeftSideContent}>
            <div className={styles.contactGroupText}>
              <h4>ติดต่อสอบถาม</h4>
              <p>E-mail: ChungTum@gmail.com</p>
            </div>
            <div className={styles.contactGroupText}>
              <h4>TERMS AND CONDITIONS (ข้อกำหนดและเงื่อนไขในการใช้บริการ)</h4>
              <Link
                to={`/${pathname.split('/')[1]}/termsandconditions`}
                style={{ color: 'black', textDecoration: 'underline' }}
              >
                Terms & Conditions and Privacy Policy
              </Link>
            </div>
          </article>
          <article className={styles.footerRightSideContent}>
            <h1>ที่อยู่บริษัท</h1>
            <p>88 หมู่ 18 ถ.ลำลูกกา ต.บึงทองหลาง</p>
            <p>อ.ลำลูกกา ปทุมธานี 12150 โทรศัพท์: 02-549-5318 - 9, 089 8136529,</p>
            <p>โทรศัพท์: 02-549-5318 - 9, 089 8136529,</p>
            <img
              style={{ width: '150px', cursor: 'pointer' }}
              src={addfriendpic}
              onClick={() => {
                lineBtnFunction()
              }}
              alt="addfriendpic"
            />
            <Modal
              isOpen={isModalOpen}
              onDismiss={() => {
                setIsModalOpen(false)
              }}
              containerClassName={modalStyles.container}
            >
              <div style={{ margin: '0px' }}>
                <img src={qrCode} alt="qrCode" />
              </div>
            </Modal>
          </article>
        </section>
      </section>
    </div>
  )
}

export default Footercomponent

const modalStyles = mergeStyleSets({
  container: {
    minWidth: '0',
  },
})
