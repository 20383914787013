// Lib
import React, { useMemo } from 'react'
import { Form, getIn, useFormikContext } from 'formik'
import { Modal } from '@mui/material'

// Include in project
import styles from './index.module.scss'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import { EPropertyType } from 'utils/common'
import RadioComponent from 'components/Radio'
import { FormikInputText } from 'components/commons/input/formikInputText'

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

//Images

interface Props {
  open: boolean
  handleClose: () => void
  name: string
  dataLineList: any
  indexLineList: number
  subIndex: number
}

const ModalProperty: React.FC<Props> = ({
  open,
  handleClose,
  name,
  dataLineList,
  indexLineList,
  subIndex,
}): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const type = useMemo(() => {
    return getIn(values, `${name}.property`)
  }, [values])

  const handleAddItem = (key: string) => {
    const keyData = key.toLocaleLowerCase()
    const intitialValue = dataLineList[`${keyData}`]
    console.log([...intitialValue, ''], 'intitialValueintitialValue')
    setFieldValue(`formikUnPackPAT.lineList[${indexLineList}][${subIndex}].${keyData}`, [...intitialValue, ''])
  }

  const deleteRowColumn = (idx: number, key: string) => {
    if (idx === 0) return
    const keyData = key.toLocaleLowerCase()
    const intitialValue = dataLineList[`${keyData}`]
    intitialValue.splice(idx, 1)
    setFieldValue(`formikUnPackPAT.lineList[${indexLineList}][${subIndex}].${keyData}`, intitialValue)
  }

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Form className={styles.container}>
        <h4>Property</h4>
        <FormikInputSelect
          className={styles.selectInput}
          name={`${name}.property`}
          defaultValue={''}
          option={[
            { text: 'Default', value: '' },
            { text: 'Text', value: EPropertyType.Text },
            { text: 'Number', value: EPropertyType.Number },
            { text: 'Check', value: EPropertyType.Check },
            { text: 'Dropdown1', value: EPropertyType.Dropdown1 },
            { text: 'Dropdown2', value: EPropertyType.Dropdown2 },
            { text: 'Dropdown3', value: EPropertyType.Dropdown3 },
          ]}
        />

        {type === EPropertyType.Dropdown ||
        type === EPropertyType.Dropdown1 ||
        type === EPropertyType.Dropdown2 ||
        type === EPropertyType.Dropdown3 ? (
          <div className={styles.box}>
            <h4>Dropdown group</h4>
            <div className={styles.wrapperRadio}>
              <RadioComponent name={`${name}.property`} label="1" value="DROPDOWN1" />
              <RadioComponent name={`${name}.property`} label="2" value="DROPDOWN2" />
              <RadioComponent name={`${name}.property`} label="3" value="DROPDOWN3" />
            </div>

            <div className={styles.wrapperBox}>
              <div className={styles.header}>
                <button
                  className={`wrapperButtonSmall`}
                  type="button"
                  onClick={() => handleAddItem(type)}
                  style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                >
                  <AddCircleRoundedIcon fontSize="small" />
                  <p>เพิ่มตัวเลือกใหม่</p>
                </button>
              </div>

              {type === EPropertyType.Dropdown1 ? (
                <div className={styles.boxInput}>
                  {dataLineList?.dropdown1.map((item: string, idx: number) => (
                    <div className={styles.warp} key={idx}>
                      <FormikInputText
                        className={styles.panelcheckboxconfigtopic}
                        name={`formikUnPackPAT.lineList[${indexLineList}][${subIndex}].dropdown1[${idx}]`}
                        placeholder="ข้อความ"
                      />
                      <div onClick={() => deleteRowColumn(idx, type)} className={styles.icon}>
                        <CloseRoundedIcon />
                      </div>
                    </div>
                  ))}
                </div>
              ) : type === EPropertyType.Dropdown2 ? (
                <div className={styles.boxInput}>
                  {dataLineList?.dropdown2.map((item: string, idx: number) => (
                    <div className={styles.warp} key={idx}>
                      <FormikInputText
                        className={styles.panelcheckboxconfigtopic}
                        name={`formikUnPackPAT.lineList[${indexLineList}][${subIndex}].dropdown2[${idx}]`}
                        placeholder="ข้อความ"
                      />
                      <div onClick={() => deleteRowColumn(idx, type)} className={styles.icon}>
                        <CloseRoundedIcon />
                      </div>
                    </div>
                  ))}
                </div>
              ) : type === EPropertyType.Dropdown3 ? (
                <div className={styles.boxInput}>
                  {dataLineList?.dropdown3.map((item: string, idx: number) => (
                    <div className={styles.warp} key={idx}>
                      <FormikInputText
                        className={styles.panelcheckboxconfigtopic}
                        name={`formikUnPackPAT.lineList[${indexLineList}][${subIndex}].dropdown3[${idx}]`}
                        placeholder="ข้อความ"
                      />
                      <div onClick={() => deleteRowColumn(idx, type)} className={styles.icon}>
                        <CloseRoundedIcon />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={styles.bottom}>
          <button className={`wrapperButtonOutlined`} type="button" onClick={handleClose}>
            <p>ยกเลิก</p>
          </button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalProperty
