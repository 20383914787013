// 3 Things to export: Thailand Region; Thailand Direct Region -> list of Provinces; Thailand provinces -> region
// https://github.com/parsilver/thailand-provinces

import { swap } from 'components/commons/shared'

export const THAILAND_REGION_MAPPER: any = {
  BMR: 'กรุงเทพและปริมณฑล',
  CR: 'ภาคกลาง',
  NR: 'ภาคเหนือ',
  NER: 'ภาคตะวันออกเฉียงเหนือ',
  ER: 'ภาคตะวันออก',
  WR: 'ภาคตะวันตก',
  SR: 'ภาคใต้',
}

export const THAILAND_REGION2CODE_MAPPER = swap(THAILAND_REGION_MAPPER)
export const THAILAND_REGION = Object.keys(THAILAND_REGION_MAPPER)

export const THAILAND_REGION2PROVINCE_MAPPER: any = {
  BMR: ['กรุงเทพ', 'ปทุมธานี', 'สมุทรปราการ', 'นนทบุรี', 'นครปฐม', 'สมุทรสาคร'],
  NR: ['เชียงใหม่', 'ลำพูน', 'ลำปาง', 'อุตรดิตถ์', 'แพร่', 'น่าน', 'พะเยา', 'เชียงราย', 'แม่ฮ่องสอน'],
  CR: [
    'พระนครศรีอยุธยา',
    'อ่างทอง',
    'ลพบุรี',
    'สิงห์บุรี',
    'ชัยนาท',
    'สระบุรี',
    'นครนายก',
    'นครสวรรค์',
    'อุทัยธานี',
    'กำแพงเพชร',
    'สุโขทัย',
    'พิษณุโลก',
    'พิจิตร',
    'เพชรบูรณ์',
    'สมุทรสงคราม',
    'สุพรรณบุรี',
  ],
  NER: [
    'นครราชสีมา',
    'บุรีรัมย์',
    'สุรินทร์',
    'ศรีสะเกษ',
    'อุบลราชธานี',
    'ยโสธร',
    'ชัยภูมิ',
    'อำนาจเจริญ',
    'หนองบัวลำภู',
    'ขอนแก่น',
    'อุดรธานี',
    'เลย',
    'หนองคาย',
    'มหาสารคาม',
    'ร้อยเอ็ด',
    'กาฬสินธุ์',
    'สกลนคร',
    'นครพนม',
    'มุกดาหาร',
    'บึงกาฬ',
  ],
  WR: ['ตาก', 'ราชบุรี', 'กาญจนบุรี', 'เพชรบุรี', 'ประจวบคีรีขันธ์'],
  ER: ['ชลบุรี', 'ระยอง', 'จันทบุรี', 'ตราด', 'ฉะเชิงเทรา', 'ปราจีนบุรี', 'สระแก้ว'],
  SR: [
    'นครศรีธรรมราช',
    'กระบี่',
    'พังงา',
    'ภูเก็ต',
    'สุราษฎร์ธานี',
    'ระนอง',
    'ชุมพร',
    'สงขลา',
    'สตูล',
    'ตรัง',
    'พัทลุง',
    'ปัตตานี',
    'ยะลา',
    'นราธิวาส',
  ],
}

export const THAILAND_PROVINCE2REGION_MAPPER = {
  กรุงเทพ: 'BMR',
  ปทุมธานี: 'BMR',
  สมุทรปราการ: 'BMR',
  นนทบุรี: 'BMR',
  นครปฐม: 'BMR',
  สมุทรสาคร: 'BMR',

  พระนครศรีอยุธยา: 'CR',
  อ่างทอง: 'CR',
  ลพบุรี: 'CR',
  สิงห์บุรี: 'CR',
  ชัยนาท: 'CR',
  สระบุรี: 'CR',
  นครนายก: 'CR',
  นครสวรรค์: 'CR',
  อุทัยธานี: 'CR',
  กำแพงเพชร: 'CR',
  สุโขทัย: 'CR',
  พิษณุโลก: 'CR',
  พิจิตร: 'CR',
  เพชรบูรณ์: 'CR',
  สุพรรณบุรี: 'CR',
  สมุทรสงคราม: 'CR',

  ชลบุรี: 'ER',
  ระยอง: 'ER',
  จันทบุรี: 'ER',
  ตราด: 'ER',
  ฉะเชิงเทรา: 'ER',
  ปราจีนบุรี: 'ER',
  สระแก้ว: 'ER',

  นครราชสีมา: 'NER',
  บุรีรัมย์: 'NER',
  สุรินทร์: 'NER',
  ศรีสะเกษ: 'NER',
  อุบลราชธานี: 'NER',
  ยโสธร: 'NER',
  ชัยภูมิ: 'NER',
  อำนาจเจริญ: 'NER',
  หนองบัวลำภู: 'NER',
  ขอนแก่น: 'NER',
  อุดรธานี: 'NER',
  เลย: 'NER',
  หนองคาย: 'NER',
  มหาสารคาม: 'NER',
  ร้อยเอ็ด: 'NER',
  กาฬสินธุ์: 'NER',
  สกลนคร: 'NER',
  นครพนม: 'NER',
  มุกดาหาร: 'NER',
  บึงกาฬ: 'NER',

  เชียงใหม่: 'NR',
  ลำพูน: 'NR',
  ลำปาง: 'NR',
  อุตรดิตถ์: 'NR',
  แพร่: 'NR',
  น่าน: 'NR',
  พะเยา: 'NR',
  เชียงราย: 'NR',
  แม่ฮ่องสอน: 'NR',

  ตาก: 'WR',
  ราชบุรี: 'WR',
  กาญจนบุรี: 'WR',
  เพชรบุรี: 'WR',
  ประจวบคีรีขันธ์: 'WR',

  นครศรีธรรมราช: 'SR',
  กระบี่: 'SR',
  พังงา: 'SR',
  ภูเก็ต: 'SR',
  สุราษฎร์ธานี: 'SR',
  ระนอง: 'SR',
  ชุมพร: 'SR',
  สงขลา: 'SR',
  สตูล: 'SR',
  ตรัง: 'SR',
  พัทลุง: 'SR',
  ปัตตานี: 'SR',
  ยะลา: 'SR',
  นราธิวาส: 'SR',
}

export const THAILAND_PROVINCE = Object.keys(THAILAND_PROVINCE2REGION_MAPPER)

export const THAILAND_PROVINCE_MAPPER = {
  กรุงเทพมหานคร: 'BANGKOK',
  สมุทรปราการ: 'SAMUT_PRAKAN',
  นนทบุรี: 'NONTHABURI',
  ปทุมธานี: 'PATHUM_THANI',
  พระนครศรีอยุธยา: 'PHRA_NAKHON_SI_AYUTTHAYA',
  อ่างทอง: 'ANG_THONG',
  ลพบุรี: 'LOBURI',
  สิงห์บุรี: 'SING_BURI',
  ชัยนาท: 'CHAI_NAT',
  สระบุรี: 'SARABURI',
  ชลบุรี: 'CHON_BURI',
  ระยอง: 'RAYONG',
  จันทบุรี: 'CHANTHABURI',
  ตราด: 'TRAT',
  ฉะเชิงเทรา: 'CHACHOENGSAO',
  ปราจีนบุรี: 'PRACHIN_BURI',
  นครนายก: 'NAKHON_NAYOK',
  สระแก้ว: 'SA_KAEO',
  นครราชสีมา: 'NAKHON_RATCHASIMA',
  บุรีรัมย์: 'BURI_RAM',
  สุรินทร์: 'SURIN',
  ศรีสะเกษ: 'SI_SA_KET',
  อุบลราชธานี: 'UBON_RATCHATHANI',
  ยโสธร: 'YASOTHON',
  ชัยภูมิ: 'CHAIYAPHUM',
  อำนาจเจริญ: 'AMNAT_CHAROEN',
  หนองบัวลำภู: 'NONG_BUA_LAM_PHU',
  ขอนแก่น: 'KHON_KAEN',
  อุดรธานี: 'UDON_THANI',
  เลย: 'LOEI',
  หนองคาย: 'NONG_KHAI',
  มหาสารคาม: 'MAHA_SARAKHAM',
  ร้อยเอ็ด: 'ROI_ET',
  กาฬสินธุ์: 'KALASIN',
  สกลนคร: 'SAKON_NAKHON',
  นครพนม: 'NAKHON_PHANOM',
  มุกดาหาร: 'MUKDAHAN',
  เชียงใหม่: 'CHIANG_MAI',
  ลำพูน: 'LAMPHUN',
  ลำปาง: 'LAMPANG',
  อุตรดิตถ์: 'UTTARADIT',
  แพร่: 'PHRAE',
  น่าน: 'NAN',
  พะเยา: 'PHAYAO',
  เชียงราย: 'CHIANG_RAI',
  แม่ฮ่องสอน: 'MAE_HONG_SON',
  นครสวรรค์: 'NAKHON_SAWAN',
  อุทัยธานี: 'UTHAI_THANI',
  กำแพงเพชร: 'KAMPHAENG_PHET',
  ตาก: 'TAK',
  สุโขทัย: 'SUKHOTHAI',
  พิษณุโลก: 'PHITSANULOK',
  พิจิตร: 'PHICHIT',
  เพชรบูรณ์: 'PHETCHABUN',
  ราชบุรี: 'RATCHABURI',
  กาญจนบุรี: 'KANCHANABURI',
  สุพรรณบุรี: 'SUPHAN_BURI',
  นครปฐม: 'NAKHON_PATHOM',
  สมุทรสาคร: 'SAMUT_SAKHON',
  สมุทรสงคราม: 'SAMUT_SONGKHRAM',
  เพชรบุรี: 'PHETCHABURI',
  ประจวบคีรีขันธ์: 'PRACHUAP_KHIRI_KHAN',
  นครศรีธรรมราช: 'NAKHON_SI_THAMMARAT',
  กระบี่: 'KRABI',
  พังงา: 'PHANGNGA',
  ภูเก็ต: 'PHUKET',
  สุราษฎร์ธานี: 'SURAT_THANI',
  ระนอง: 'RANONG',
  ชุมพร: 'CHUMPHON',
  สงขลา: 'SONGKHLA',
  สตูล: 'SATUN',
  ตรัง: 'TRANG',
  พัทลุง: 'PHATTHALUNG',
  ปัตตานี: 'PATTANI',
  ยะลา: 'YALA',
  นราธิวาส: 'NARATHIWAT',
  บึงกาฬ: 'BUOGKAN',
}

export const THAILAND_PROVINCE2CODE_MAPPER = swap(THAILAND_PROVINCE_MAPPER)
