import * as React from 'react'
import styles from './index.module.scss'
import Slider from '@mui/material/Slider'
import { useParams, useNavigate } from 'react-router-dom'
import { FormikInputText } from 'components/commons/input/formikInputText'
import { FormControl, InputAdornment, InputLabel, IconButton, OutlinedInput } from '@mui/material'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
// import { calDateforInputDate } from '../../utils/common'
import { DatePicker } from '@fluentui/react'
import { CUSTOMER_PREFIX, TECHNICIAN_PREFIX } from 'App'

//svg Image
import TechLogo from '../../image/tech.svg'
import SearchIcon from '../../image/seach.svg'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import { getJobbyUserId, getPostbyUserId, ListPostByUserId } from 'adapter/xhr'
import CardComponent from 'components/Card'
import CardHistoryComponent from 'components/CardHistory'
import AddLogo from './add.svg'
const NewsFeedPage: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>()
  const navigate = useNavigate()
  const { customerOrTechnician } = useParams()
  const [currentHeaderButton, setCurrentHeaderButton] = React.useState(BUTTON_VALUE.DASHBOARD)
  const buttonHeaderList = customerOrTechnician === CUSTOMER_PREFIX ? CUSTOMER_SELECTION : TECHNICIAN_SELECTION
  const TabbarList = customerOrTechnician === CUSTOMER_PREFIX ? CUSTOMER_TAB : TECHNICIAN_TAB
  const decodedAccessToken = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken)
  const [checkUserCustomer, setCheckUserCustomer] = React.useState(customerOrTechnician === CUSTOMER_PREFIX)

  function valuetext(value: any) {
    return `${value}`
  }
  const [value, setValue] = React.useState([0, 6500])

  const queryListPost = () => {
    const userId = decodedAccessToken.userId
    const role = decodedAccessToken.role

    if (role == TECHNICIAN_PREFIX) {
      ListPostByUserId(userId)
        .then((postres: any) => {
          console.log('res====>', postres)
          setFieldValue('postrespon', postres.data.data.listPostsForFix)
          setFieldValue('showPost', postres.data.data.listPostsForFix)
        })
        .catch((err) => {
          console.error('ERROR')
        })

      getJobbyUserId({ createdByUserId: userId })
        .then((historyres: any) => {
          console.log('History ===>', historyres)
          const cloneHistoryres = historyres.data.data.jobApplicationByMe.items
          cloneHistoryres.map((jobHistoryElement: any) => {
            jobHistoryElement.post.workTypePostList.map((workTypePost: any) => {
              const findingJobApps = jobHistoryElement.workTypeJobApplicationList.find(
                (workTypeJob: any) => workTypePost.workType === workTypeJob.workType,
              )
              findingJobApps
                ? workTypePost.totalJobList.map((totalPostElement: any) => {
                    // totalJobElement
                    const findingJobList = findingJobApps.totalJobList.find(
                      (totalJobElement: any) => totalPostElement.fieldValue === totalJobElement.fieldValue,
                    )
                    if (findingJobList) {
                      totalPostElement.registerQty = findingJobList.registerQty
                      totalPostElement.approveQty = findingJobList.approveQty
                    }
                  })
                : []
            })
          })
          console.log(cloneHistoryres, 'cloneHistoryres')
          setFieldValue('historyres', cloneHistoryres)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    } else if (role == CUSTOMER_PREFIX) {
      getPostbyUserId({ createdByUserId: userId })
        .then((getPost: any) => {
          console.log('resGetPost====>', getPost)
          setFieldValue('getpost', getPost.data.data.postByCreatedByUserID)
        })
        .catch((err) => {
          console.error('ERROR')
        })
    }
  }
  React.useEffect(() => {
    queryListPost()
  }, [])

  enum PageProjectStatus {
    CURRENT,
    ARCHIVED,
  }

  const [pageProjectStatus, setPageProjectStatus] = React.useState<PageProjectStatus>(PageProjectStatus.CURRENT)

  const [query, setQuery] = React.useState('')

  const newLocal = '5000'
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
    console.log(newValue[0], 'Rang Price Min')
    console.log(newValue[1], 'Rang Price Max')
    const minPrice = newValue[0]
    const maxPrice = newValue[1]
    const resultRang = values.postrespon.filter((a: any) => {
      const price = a.project.minPrice
      return price >= minPrice && price <= maxPrice
    })
    setFieldValue('showPost', resultRang)
  }

  const filterdate = () => {
    const startdate = values.startDate
    const enddate = values.endDate
    const resultDate = values.postrespon.filter((a: any) => {
      const date = a.project.startDate
      return date >= startdate && date <= enddate
    })
    setFieldValue('showPost', resultDate)
    console.log(values.postrespon, 'Values')
    console.log(startdate, 'startdate')
    console.log(enddate, 'enddate')
  }

  // const filterSafety = (e: any) => {
  //   const selectdata = e.target.checked
  //   console.log('test', selectdata)
  //   const resultData = values.postrespon.filter((a: any) => {
  //     const data = a.project.isPassport
  //     return data == selectdata
  //   })
  //   setFieldValue('showPost', resultData)
  // }
  return (
    <Form>
      <div className={styles.center}>
        <div className={styles.tab}>
          <div className={styles.buttontab}>
            <div className={`${styles.projectSelectionWrapper}`}>
              <div className="wrapper2">
                <button
                  className={`${styles.projectSelectionButton} ${
                    pageProjectStatus === PageProjectStatus.CURRENT ? `${styles.projectSelectionButtonSelected}` : ''
                  }`}
                  type="button"
                  onClick={() => {
                    setPageProjectStatus(PageProjectStatus.CURRENT)
                  }}
                >
                  {!checkUserCustomer && <div>สมัครงานช่างทำ</div>}
                  {checkUserCustomer && <div>ประกาศของฉัน</div>}
                </button>
                <button
                  className={`${styles.projectSelectionButton} ${
                    pageProjectStatus === PageProjectStatus.ARCHIVED ? `${styles.projectSelectionButtonSelected}` : ''
                  }`}
                  type="button"
                  onClick={() => {
                    setPageProjectStatus(PageProjectStatus.ARCHIVED)
                  }}
                >
                  {!checkUserCustomer && <div>ประวัติการสมัครงานช่างทำ</div>}
                  {checkUserCustomer && <div>ประวัติการลงประกาศ</div>}
                </button>
              </div>
            </div>
          </div>
        </div>

        {pageProjectStatus === PageProjectStatus.CURRENT ? (
          <div className="wrapper2">
            {/* Search Box */}
            {!checkUserCustomer && (
              <div className={styles.filterbox}>
                <div className={styles.layouttext}>
                  <div className={styles.flexfiled}>
                    <div className={styles.boxtext}>
                      <p>ชื่อโครงการ</p>
                      <Field
                        className={styles.inputfiled}
                        name="Projectname"
                        value={query}
                        placeholder="ค้นหาจากชื่อโครงการ"
                        onChange={(event: any) => setQuery(event.target.value)}
                      />
                    </div>
                    <div className={styles.boxtext}>
                      <p>ผู้จ้าง</p>
                      <FormikInputText
                        className={styles.inputfiled}
                        name="CustomerSearch"
                        placeholder="ค้นหาจากชื่อผู้ประกาศ"
                        // onChange={(event: any) => setQuery(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.bottomfiled}>
                    <p>ระยะเวลาโครงการ</p>
                    <div className={styles.flexarea}>
                      <div className={styles.boxdate}>
                        <p className={styles.title}>เริ่มต้น</p>
                        <Field name="startDate">
                          {({ field }: FieldProps) => (
                            <DatePicker
                              {...field}
                              // allowTextInput
                              value={field.value ? new Date(field.value) : undefined}
                              onSelectDate={(date: any) => {
                                // console.log(date, 'DatePickers')
                                const startdate = new Date(date).toDateString()
                                // const enddate = values.endDate
                                const resultDate = values.postrespon.filter((a: any) => {
                                  const date = new Date(a.project.startDate).toDateString()
                                  return date == startdate
                                })
                                setFieldValue('showPost', resultDate)
                                console.log(startdate, 'DatePickers')
                                console.log(resultDate, 'resultDate')

                                // if (date) {
                                //   setFieldValue(field.name, calDateforInputDate(date))
                                //   // filterdate()
                                // }
                                // filterdate()
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={styles.boxdate}>
                        <p className={styles.title}>สิ้นสุด</p>
                        <Field name="endDate">
                          {({ field }: FieldProps) => (
                            <DatePicker
                              {...field}
                              // allowTextInput
                              value={field.value ? new Date(field.value) : undefined}
                              onSelectDate={(date: any) => {
                                const enddate = new Date(date).toDateString()
                                // const enddate = values.endDate
                                const resultDate = values.postrespon.filter((a: any) => {
                                  const date = new Date(a.project.endDate).toDateString()
                                  return date == enddate
                                })
                                setFieldValue('showPost', resultDate)
                                console.log(enddate, 'DatePickers')
                                console.log(resultDate, 'resultDate')
                                // if (date) {
                                //   setFieldValue(field.name, calDateforInputDate(date))
                                //   // console.log(date, 'DatePickers')
                                //   // filterdate()
                                // }
                                // filterdate()
                              }}
                              style={{ flexGrow: 1 }}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={styles.btnform}>
                        {/* <button
                          className={styles.searchBt}
                          type="button"
                          // onClick={
                          //   () => filterdate()
                          //   // console.log('search')
                          // }
                        >
                          ค้นหา
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.leftside}>
                  {' '}
                  {/* <div className={styles.radioform}>
                    <span className={styles.radio}>
                      {' '}
                      <input
                        type="checkbox"
                        id="safety"
                        name="safety"
                        value="false"
                        onChange={(e: any) => {
                          filterSafety(e)
                        }}
                      />
                      มีบัตร Safety Passport
                    </span>
                  </div> */}
                  <div className={styles.rangePrice}>
                    <p>เรทราคา</p>
                    <Slider
                      sx={{ color: '#EF8834' }}
                      getAriaLabel={() => 'Temperature range'}
                      min={0}
                      max={6500}
                      value={[value[0], value[1]]}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                    />

                    <div className={styles.areaRang}>
                      <div className={styles.Labelrang}>
                        <p>
                          {[value[0]]}-{[value[1]]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {checkUserCustomer && (
              <div className={`wrapper2 ${styles.searchbar}`}>
                <button className={styles.btAddPost} onClick={() => navigate('/customer/myproject')}>
                  <img src={AddLogo} alt="" /> ลงประกาศงาน
                </button>
                <div className={styles.searchbox}>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel sx={{ fontFamily: 'Kanit' }}>ค้นหา</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      sx={{ fontFamily: 'Kanit' }}
                      placeholder="ค้นหาชื่อโครงการ"
                      value={query}
                      onChange={(event) => setQuery(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <img src={SearchIcon} alt="" />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="ค้นหาโครงการ"
                    />
                  </FormControl>
                </div>
              </div>
            )}
            {/* Card Data */}

            {checkUserCustomer && (
              <div className={styles.cardArea}>
                {console.log('customer', values.getpost)}
                {values.getpost &&
                  values.getpost
                    ?.filter((dataFilter: any) => new Date().getTime() < new Date(`${dataFilter.endDate}`).getTime())
                    ?.filter((data: any) => {
                      if (query == '') {
                        return data
                      } else if (
                        data.project.projectName.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
                        data.user.username.toLocaleLowerCase().includes(query.toLocaleLowerCase())
                      ) {
                        return data
                      }
                    })
                    .map((data: any, index: number) => (
                      <div className={styles.cardNews} key={index}>
                        <CardComponent
                          projectName={data.project.projectName}
                          title={data.title}
                          companyName={data.user.username}
                          description={data.description}
                          post={{ startDate: data.startDate, endDate: data.endDate }}
                          project={{ startDate: data.project.startDate, endDate: data.project.endDate }}
                          fileTOR={data.project.TORFile}
                          fileAttachmentList={data.fileAttachmentList}
                          workTypePostList={data.workTypePostList}
                          imageURLList={data.imageURLList}
                          createdAt={data.createdAt}
                          minprice={data.project.minPrice}
                          maxprice={data.project.maxPrice}
                          jobApplication={data.jobApplication}
                        />
                        <div className={styles.bottom}>
                          <div className={styles.btnform}>
                            {buttonHeaderList.map(({ text, value, img }, buttonHeaderIndex: number) => (
                              <button
                                key={buttonHeaderIndex}
                                className="dp-flex f-basis50 justify-cont-ct btnDefault2"
                                style={{ alignItems: 'center', width: '350px' }}
                                type="button"
                                onClick={() => navigate(`/customer/acceptJob/${data.postID}`)}
                              >
                                {img}
                                {text}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                {values.getpost?.length === 0 && (
                  <div className={styles.emptyContainer}>
                    <div className={styles.emptyLineText}>-------- ยังไม่มีข้อมูลการประกาศ -------- </div>
                  </div>
                )}
              </div>
            )}
            {!checkUserCustomer && (
              <div className={styles.cardArea}>
                {console.log('PostForFix ==>', values.showPost)}
                {values.showPost &&
                  values.showPost
                    ?.filter((data: any) => {
                      if (query == '') {
                        return data
                      } else if (data.project.projectName?.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
                        return data
                      }
                    })
                    .filter((data: any) => {
                      if (values.CustomerSearch == '') {
                        return data
                      } else if (
                        data.user.username?.toLocaleLowerCase().includes(values.CustomerSearch.toLocaleLowerCase())
                      ) {
                        return data
                      }
                    })
                    .map((data: any, index: number) => (
                      <div className={styles.cardNews} key={index}>
                        <CardComponent
                          projectName={data.project.projectName}
                          title={data.title}
                          companyName={data.user.username}
                          description={data.description}
                          post={{ startDate: data.startDate, endDate: data.endDate }}
                          project={{ startDate: data.project.startDate, endDate: data.project.endDate }}
                          fileTOR={data.project.TORFile}
                          fileAttachmentList={data.fileAttachmentList}
                          workTypePostList={data.workTypePostList}
                          imageURLList={data.imageURLList}
                          createdAt={data.createdAt}
                          minprice={data.project.minPrice}
                          maxprice={data.project.maxPrice}
                        />

                        <div className={styles.bottom}>
                          <div className={styles.btnform}>
                            {buttonHeaderList.map(({ text, value, img }, buttonHeaderIndex: number) => (
                              <button
                                key={buttonHeaderIndex}
                                className="dp-flex f-basis50 justify-cont-ct btnDefault2"
                                style={{ alignItems: 'center', width: '350px' }}
                                type="button"
                                onClick={() => {
                                  console.log(data.postID, 'buttonHeaderList')
                                  setCurrentHeaderButton(value)
                                  navigate(`/technician/applyJob/${data.postID}`)
                                }}
                              >
                                {img}
                                {text}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.center}>
            {!checkUserCustomer && (
              <div className={styles.center}>
                <div className={`wrapper2 ${styles.searchbar}`}>
                  <div className={styles.searchbox}>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                      <InputLabel sx={{ fontFamily: 'Kanit' }}>ค้นหา</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        placeholder="ค้นหาชื่อโครงการ"
                        sx={{ fontFamily: 'Kanit' }}
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <img src={SearchIcon} alt="" />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="ค้นหาโครงการ"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className={styles.cardArea}>
                  {' '}
                  {values.historyres &&
                    values.historyres
                      ?.filter((historyResElement: any) => {
                        if (query == '') {
                          return historyResElement
                        } else if (
                          historyResElement.post?.title.toLocaleLowerCase().includes(query.toLocaleLowerCase())
                          // data.companyName.toLocaleLowerCase().includes(query.toLocaleLowerCase())
                        ) {
                          return historyResElement
                        }
                      })
                      .map((historyResElement: any, index: number) => (
                        <div key={index}>
                          {' '}
                          <CardHistoryComponent
                            title={historyResElement.post.title}
                            username={historyResElement.post.user.username}
                            fileAttachmentList={historyResElement.post.fileAttachmentList}
                            startDate={historyResElement.post.startDate}
                            endDate={historyResElement.post.endDate}
                            description={historyResElement.post.description}
                            createdAt={historyResElement.createdAt}
                            imageURLList={historyResElement.post.imageURLList}
                            workTypePostList={historyResElement.post.workTypePostList}
                          />
                          {/* <TableShow
                          tableTopic={history.post.title}
                          tableHeader={tableHeader}
                          tableRowList={history.post.workTypePostList}
                          
                          workType={history.post.workTypePostList}
                          jobApp={history.workTypeJobApplicationList}
                          tableIndex={0}
                        /> */}
                          {/* <TableShow
                          tableTopic={history.post.createdByUserID}
                          tableHeader={tableHeader}
                          tableRowList={Newdata}
                          workType={Newdata}
                          tableIndex={0}
                        /> */}
                        </div>
                      ))}
                </div>
              </div>
            )}
            {checkUserCustomer && (
              <div className={styles.cardArea}>
                {/* {console.log('values.getpost', values.getpost)} */}
                {values.getpost &&
                  values.getpost
                    ?.filter((dataFilter: any) => new Date().getTime() > new Date(`${dataFilter.endDate}`).getTime())
                    ?.filter((data: any) => {
                      if (query === '') {
                        return data
                      } else if (
                        data.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
                        data.companyName.toLocaleLowerCase().includes(query.toLocaleLowerCase())
                      ) {
                        return data
                      }
                    })
                    .map((data: any, index: number) => (
                      <div className={styles.cardNews} key={index}>
                        <CardComponent
                          title={data.title}
                          companyName={data.companyName}
                          description={data.description}
                          post={{ startDate: data.startDate, endDate: data.endDate }}
                          project={{ startDate: data.project.startDate, endDate: data.project.endDate }}
                          // fileAttachmentList={data.fileAttachmentList}
                          workTypePostList={data.workTypePostList}
                          imageURLList={data.imageURLList}
                          createdAt={data.createdAt}
                        />
                      </div>
                    ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Form>
  )
}

interface IButtonSelection {
  text: string
  value: BUTTON_VALUE
  img?: any
}

enum BUTTON_VALUE {
  DASHBOARD = 1,
  MASTERPLAN,
  PAT_MANAGEMENT,
}

const CUSTOMER_SELECTION: IButtonSelection[] = [
  {
    text: 'คัดเลือกผู้รับเหมาที่นี่',
    value: BUTTON_VALUE.DASHBOARD,
    img: <img src={TechLogo} alt="" />,
  },
]

const TECHNICIAN_SELECTION: IButtonSelection[] = [
  {
    text: 'สมัครงาน',
    value: BUTTON_VALUE.MASTERPLAN,
  },
]

interface Tabbar {
  text: string
}

const CUSTOMER_TAB: Tabbar[] = [
  {
    text: 'ประกาศของฉัน',
  },
  {
    text: 'ประวัติ',
  },
]

const TECHNICIAN_TAB: Tabbar[] = [
  {
    text: 'สมัครงาน',
  },
  {
    text: 'ประวัติการสมัครงานช่างทำ',
  },
]

const EnhancedNewsFeedPage = withFormik({
  mapPropsToValues: () => ({
    post: {},
    showPost: [],
    options: [
      { value: '', text: '' },
      {
        value: 'Group',
        text: 'Group',
      },
      {
        value: 'province',
        text: 'จังหวัด',
      },
      {
        value: 'region',
        text: 'ภูมิภาค',
      },
    ],
    isModalOpen: false,
    fieldList: [
      {
        name: 'region',
        allowedValueList: ['CR', 'ER', 'NER', 'NR', 'SR'],
      },
    ],
    total: 300,
    //FilterProps
    Projectname: '',
    customername: '',
    startDate: new Date(),
    endDate: new Date(),
    price: 0,
    CustomerSearch: '',
  }),
  validate: (values) => {
    const error = {}

    return error
  },
  handleSubmit: (values, { setFieldValue }) => {
    console.log(JSON.stringify(values))
  },
})(NewsFeedPage)

interface IFormikValues {
  projectFilter: string
  isModalOpen: boolean
}

export default EnhancedNewsFeedPage
