import { getChangTumId } from 'components/commons/shared'
import React from 'react'
import { useParams } from 'react-router-dom'

const GenerateChangTumIdPage: React.FC = () => {
  const { qtyIds } = useParams<any>()

  return (
    <div>
      {[...Array(parseInt(qtyIds!))].map((_, index) => {
        return <div key={index}>{getChangTumId()}</div>
      })}
    </div>
  )
}
export default GenerateChangTumIdPage
