import {
  getPatByPatId,
  PATGraphQLObject,
  PATObject,
  getJobByChangTumId,
  IJob,
  putPat,
  createPat,
  redoPat,
  post,
} from 'adapter/xhr'
import { Line_Type, Pat_Export, Upload_Type } from 'utils/generated'

// Change this to urlq later!!
// I'll make the svg first
const patRequests = {
  // Get PAT by User ID
  listPATbyUserId: async (userId: string) => {
    const result = await post('/', {
      query: queryPATByUserId,
      variables: {
        userId,
      },
    })
    return result.data.data?.patByUserId
  },
  // Delete PAT
  deletePAT: async (patId: string) => {
    const result = await post('/', {
      query: deletePAT,
      variables: {
        input: { patId },
      },
    })
    return result.data.data?.deletePAT
  },
  // Duplicate PAT
  duplicatePAT: async (patId: string) => {
    const result = await post('/', {
      query: duplicatePAT,
      variables: {
        input: { patId },
      },
    })
    return result.data.data?.duplicatePAT
  },
}

export default patRequests

const queryPATByUserId = `
query($userId: ID!){
  patByUserId(userId: $userId){
    patId
    name
  }
}`

const deletePAT = `
mutation DeletePAT($input: DeletePATInput!) {
  deletePAT(input: $input) {
    patId
  }
}`

const duplicatePAT = `
mutation DuplicatePAT($input: DuplicatePATInput!) {
  duplicatePAT(input: $input) {
    patId
  }
}`

export async function getUnpackedPatbyPatId(patId: string): Promise<PATObject> {
  const pat: PATGraphQLObject[] = (await getPatByPatId(patId)).data.data.pat
  return unpackPAT(pat)
}

export async function packAndCreatePat(unpackedPat: PATObject, userId: string): Promise<PATGraphQLObject> {
  const packedPat: PATGraphQLObject = packPAT(unpackedPat, userId)
  // Actually we can return value. but im lazy

  return createPat(packedPat)
    .then((res) => res.data.data.createPAT)
    .catch((err) => {
      console.error('I HAVE ERROR ', err.response)
      throw new Error(err.response)
    })
}

export async function packAndPutPat(unpackedPat: PATObject): Promise<PATGraphQLObject> {
  const packedPat: PATGraphQLObject = packPAT(unpackedPat)
  // Actually we can return value. but im lazy
  return putPat(packedPat)
    .then((res) => {
      return res.data.data.putPAT
    })
    .catch((err) => {
      console.error('I HAVE ERROR ', err.response)
      throw new Error(err.response)
    })
}

export async function packAndRedoPat(unpackedPat: PATObject): Promise<PATGraphQLObject> {
  const packedPat: PATGraphQLObject = packPAT(unpackedPat)
  // Actually we can return value. but im lazy
  return redoPat(packedPat)
    .then((res) => {
      return res.data.data.redoPat
    })
    .catch((err) => {
      console.error('I HAVE ERROR ', err.response)
      throw new Error(err.response)
    })
}

// Get Title Templates by User ID
export function getTitleTemplatesByUserId(userId: string): Promise<any> {
  // Default are only templates!!
  const query = `
  query Query($userId: ID!) {
    titleTemplateByUserId(userId: $userId) {
      titleTemplateId
      name
      description
    }
  }`
  const variables = {
    userId,
  }
  return post('/', {
    query,
    variables,
  })
}

// When we want to receive from graphql
// Input: List of PAT -> Assume will have only one element
//        Assume Grouped Elements, 2D List Sorted by Line No.
//        Contents will be inside the 2D List
// Output: PAT Cleaned
export function unpackPAT(patGraphQLObject: PATGraphQLObject[]): PATObject {
  const PATSelection = patGraphQLObject[0]

  // Making Sure if the input line Number is all Group Type
  PATSelection.lineList.map((lineGroup: any) => {
    if (lineGroup.lineType !== 'GROUP') {
      console.error('Line Number on top level is not all Groups')
      throw 'Line Number on top level is not all Groups'
    }
  })

  // Destructuring All line Number by moving all the keys inside the value out
  const newPATSelectionList = PATSelection.lineList.map((lineGroup: any) => {
    const destructuredLineList = lineGroup.value.lineList
      .sort((a: any, b: any) => {
        if (parseInt(a.lineNo) < parseInt(b.lineNo)) return -1
        else return 1
      })
      .map((lineElement: any) => {
        // console.log(lineElement, 'lineElement')
        Object.keys(lineElement?.value || {})?.map((key) => {
          // console.log(key, 'key')
          lineElement[key] = lineElement.value[key]
        })
        if (lineElement.lineType === 'IMAGE') {
          lineElement = { text: lineElement.text.split(','), lineType: 'IMAGE' }
          // console.log(lineElement.text.split(','), 'Arr.lineElement.lineType')
          // console.log('hey')
        }

        // console.log(lineElement, 'lineElement')
        delete lineElement.lineNo
        delete lineElement.value
        // console.log(lineElement, 'lineElement')
        return lineElement
      })
    return destructuredLineList
  })

  const newRequiredSelectionList = PATSelection.lineList.map((lineGroup: any) => {
    return lineGroup.value.required
  })

  // console.log(newPATSelectionList, 'newPATSelectionList')

  const patObject: PATObject = {
    patId: PATSelection.patId ? PATSelection.patId : '',
    name: PATSelection.name,
    description: PATSelection.description,
    versionNo: PATSelection.versionNo,
    status: PATSelection.status,
    lineList: newPATSelectionList,
    titleTemplate: PATSelection.titleTemplate,
    requiredList: newRequiredSelectionList,
    headerList: PATSelection.headerList ? PATSelection.headerList : [],
    patExport: PATSelection.patExport,
  }
  return patObject
}

// When we want to send it back to the graphql
// userId is for createPat where we need userId and there is no need for patId
export function packPAT(patFilledObject: PATObject, userId: string | null = null): PATGraphQLObject {
  const patFilledObjectCloned = JSON.parse(JSON.stringify(patFilledObject))
  const PATPackingLineList = patFilledObjectCloned.lineList.map((packingLineGroup: any, key: any) => {
    // console.log(packingLineGroup, 'second')
    const structuringLineList = packingLineGroup.map((packingLineElement: any, index: number) => {
      packingLineElement.lineNo = index.toString()
      if (packingLineElement.lineType === 'IMAGE') {
        packingLineElement.value = { text: packingLineElement.text.toString() }
      } else if (packingLineElement.lineType === 'TEXT') {
        packingLineElement.value = { text: packingLineElement.text }
      } else if (packingLineElement.lineType === Line_Type.Heading2) {
        packingLineElement.value = { text: packingLineElement.text }
      } else if (packingLineElement.lineType === 'UPLOAD') {
        if (packingLineElement.uploadType === Upload_Type.Guyedlegs) {
          packingLineElement.value = {
            text: packingLineElement.text,
            uploadType: packingLineElement.uploadType,
            numberLegs: packingLineElement.numberLegs,
            CTA: packingLineElement.CTA,
            CTB: packingLineElement.CTB,
            CTC: packingLineElement.CTC,
            CTD: packingLineElement.CTD,
            AB: packingLineElement.AB,
            BC: packingLineElement.BC,
            CD: packingLineElement.CD,
            DA: packingLineElement.DA,
            CA: packingLineElement.CA, //
          }
        } else if (packingLineElement.uploadType === Upload_Type.Antenna) {
          packingLineElement.value = {
            text: packingLineElement.text,
            uploadType: packingLineElement.uploadType,
            isDetail: packingLineElement.isDetail,
            poleType: packingLineElement.poleType,
            poleList: packingLineElement.poleList,
          }
        } else if (packingLineElement.uploadType === Upload_Type.Checklist) {
          packingLineElement.value = {
            text: packingLineElement.text,
            uploadType: packingLineElement.uploadType,
            levelRow: parseInt(packingLineElement.levelRow),
            rowList: packingLineElement.rowList,
            dropdown1: packingLineElement.dropdown1,
            dropdown2: packingLineElement.dropdown2,
            dropdown3: packingLineElement.dropdown3,
            equation: packingLineElement.equation,
            columnList: packingLineElement.columnList,
            defaultProperty: packingLineElement.defaultProperty,
            remarkProperty: packingLineElement.remarkProperty,
            data: packingLineElement.data,
          }
        } else if (packingLineElement.uploadType === Upload_Type.Measure) {
          packingLineElement.value = {
            text: packingLineElement.text,
            uploadType: packingLineElement.uploadType,
            checkTolerance: packingLineElement.checkTolerance,
            maxTolerance: packingLineElement.maxTolerance,
            minTolerance: packingLineElement.minTolerance,
            userVariable: packingLineElement.userVariable,
            columnList: packingLineElement.columnList,
            data: packingLineElement.data,
            start: packingLineElement.start,
            end: packingLineElement.end,
            interval: packingLineElement.interval,
          }
        } else if (
          packingLineElement.uploadType === Upload_Type.Image ||
          packingLineElement.uploadType === Upload_Type.Sound ||
          packingLineElement.uploadType === Upload_Type.Video ||
          packingLineElement.uploadType === Upload_Type.Text ||
          packingLineElement.uploadType === Upload_Type.Pdf
        ) {
          packingLineElement.value = {
            text: packingLineElement.text,
            uploadType: packingLineElement.uploadType,
            isNeedBefore: packingLineElement.isNeedBefore,
            isNeedAfter: packingLineElement.isNeedAfter,
            maximumFileNumber: packingLineElement.maximumFileNumber,
            maximumFileSize: parseInt(packingLineElement.maximumFileSize), // I'm lazy to change in the code (select-option), make it int here first!
            uploadAfterValue: packingLineElement.uploadAfterValue ? packingLineElement.uploadAfterValue : '',
            uploadBeforeValue: packingLineElement.uploadBeforeValue ? packingLineElement.uploadBeforeValue : '',
          }
        }
      } else if (packingLineElement.lineType === 'APPROVE')
        packingLineElement.value = {
          approvalRemark: packingLineElement.approvalRemark ? packingLineElement.approvalRemark : '',
          isApproved: packingLineElement.isApproved,
        }

      delete packingLineElement.uploadAfterValue
      delete packingLineElement.uploadBeforeValue

      delete packingLineElement.isApproved
      delete packingLineElement.approvalRemark
      delete packingLineElement.text
      delete packingLineElement.uploadType
      delete packingLineElement.isNeedBefore
      delete packingLineElement.isNeedAfter
      delete packingLineElement.maximumFileNumber
      delete packingLineElement.maximumFileSize

      // CheckList
      delete packingLineElement.equation
      delete packingLineElement.defaultProperty
      delete packingLineElement.remarkProperty
      delete packingLineElement.levelRow
      delete packingLineElement.rowList
      delete packingLineElement.levelRow
      delete packingLineElement.columnList
      delete packingLineElement.dropdown1
      delete packingLineElement.dropdown2
      delete packingLineElement.dropdown3
      delete packingLineElement.data
      // GuyedLegs
      delete packingLineElement.numberLegs
      delete packingLineElement.AB
      delete packingLineElement.BC
      delete packingLineElement.CA
      delete packingLineElement.CD
      delete packingLineElement.DA
      delete packingLineElement.CTA
      delete packingLineElement.CTB
      delete packingLineElement.CTC
      delete packingLineElement.CTD
      // Measure
      delete packingLineElement.checkTolerance
      delete packingLineElement.maxTolerance
      delete packingLineElement.minTolerance
      delete packingLineElement.userVariable
      delete packingLineElement.start
      delete packingLineElement.end
      delete packingLineElement.interval
      // Antenna
      delete packingLineElement.isDetail
      delete packingLineElement.poleType
      delete packingLineElement.poleList

      // console.log(packingLineElement, 'packingLineElementpackingLineElementpackingLineElement')
      return packingLineElement
    })

    //  Remove everything from packingLineGroup
    packingLineGroup.splice(0, packingLineGroup.length)
    // Make the packingLineGroup a higher LineGroup
    packingLineGroup.value = {
      lineList: structuringLineList,
      required: patFilledObjectCloned.requiredList[key] ? true : false,
    }
    packingLineGroup.lineNo = key.toString()
    packingLineGroup.lineType = 'GROUP'
    const packingPatline = Object.assign({}, packingLineGroup)
    // return { packingLineGroup }
    // console.log(packingLineGroup, 'third')
    return packingPatline
  })
  // console.log('PACK PAT Line List ', PATPackingLineList)

  if (userId) {
    return {
      name: patFilledObject.name,
      description: patFilledObject.description,
      titleTemplate: patFilledObject.titleTemplate,
      lineList: PATPackingLineList,
      headerList: patFilledObject.headerList,
      userId,
      patExport: patFilledObject.patExport,
    }
  } else {
    return {
      patId: patFilledObject.patId,
      name: patFilledObject.name,
      description: patFilledObject.description,
      titleTemplate: patFilledObject.titleTemplate,
      lineList: PATPackingLineList,
      headerList: patFilledObject.headerList,
      status: patFilledObject.status,
      patExport: patFilledObject.patExport,
    }
  }
}

export const EMPTY_PAT = [
  {
    patId: 'P1',
    name: '',
    description: '',
    status: '',
    patExport: Pat_Export.LongForm,
    lineList: [
      {
        lineNo: '0',
        lineType: 'GROUP',
        value: {
          required: false,
          lineList: [
            {
              lineNo: '0',
              lineType: 'TEXT',
              value: {
                text: 'Header Title',
              },
            },
          ],
        },
      },
    ],
  },
]

export async function getRawJobByChangTumId(projectId: string, changTumId: string): Promise<IJob> {
  const job: IJob = (await getJobByChangTumId(projectId, changTumId)).data.data.job
  return job
}
