import React, { useRef } from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop, Avatar } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { handleUploadFile } from 'utils/common'
import { UpdateUserProfile } from 'adapter/xhr'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'

const ModalUploadLogo: React.FC<any> = (props: any): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const imageFileRef = useRef<any>([])
  const dispatch = useDispatch()

  return (
    <Modal
      open={props.open}
      disablePortal={true}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      sx={{ border: 'none' }}
    >
      <div className={styles.modalBox}>
        <div className={styles.cardModal}>
          <div className={styles.head}>
            <p className={styles.textHead}>รูปโลโก้</p>
          </div>
          <img className={styles.modalLogo} src={values.uploadLogo || undefined} alt="" />
          <button type="button" className={styles.btnUpload} onClick={() => imageFileRef.current.click()}>
            อัพโหลดโลโก้
          </button>
          <button
            type="button"
            className={styles.btnDeleteUpload}
            onClick={() => {
              setFieldValue(`uploadLogo`, '')
              setFieldValue(`profile.logoUrl`, '')
              const UpdateUserProfileVariables = {
                input: {
                  logoUrl: null,
                },
              }
              UpdateUserProfile(UpdateUserProfileVariables)
            }}
          >
            ลบรูปโลโก้
          </button>
          <input
            type="file"
            onChange={(e) => {
              dispatch(allAction.loaderSpinnerAction.setOpenLoaderSpinner())
              Promise.all(handleUploadFile(e.target.files, `Profile/${values.profile.userId}/`))
                .then((fileLocationList: any[]) => {
                  setFieldValue(`uploadLogo`, fileLocationList[0])
                })
                .then(() => {
                  dispatch(allAction.loaderSpinnerAction.setCloseLoaderSpinner())
                })
                .catch((err) => {
                  console.log(err)
                  dispatch(allAction.loaderSpinnerAction.setCloseLoaderSpinner())
                })
            }}
            accept="image/png, image/jpeg"
            ref={imageFileRef}
            style={{ display: 'none' }}
          />
          <div className={styles.bottomBtnGroup}>
            <button
              type="button"
              className={styles.btnCancel}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              ยกเลิก
            </button>
            <button
              type="button"
              className={styles.btnSubmit}
              onClick={() => {
                const UpdateUserProfileVariables = {
                  input: {
                    logoUrl: values.uploadLogo,
                  },
                }
                UpdateUserProfile(UpdateUserProfileVariables)
                setFieldValue('profile.logoUrl', values.uploadLogo)
                props.setOpen(false)
              }}
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalUploadLogo
