/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { getTheme, mergeStyleSets, FontWeights, Modal, IIconProps, IconButton, IButtonStyles } from '@fluentui/react'

interface IModalProps {
  isModalOpen: boolean
  closeModal?(): void
  submitModal(): void
  areYouSureMsg?: string
  description?: string
  status?: string
  isBlocking?: boolean
}

const ModalConfirm: React.FC<IModalProps> = ({
  isModalOpen,
  closeModal,
  submitModal,
  areYouSureMsg = 'คุณแน่ใจแล้วใช่ไหม?',
  description = 'ข้อมูลของรหัสไซต์ PCTUM ,สถานที่ซอยเพิ่มชัย, ถนนตำหรุ กับช่างผู้รับผิดชอบ สมยศ ใจดี',
  status = 'ผ่านการตรวจสอบ  ใช่หรือไม่',
  isBlocking = false,
}: IModalProps): JSX.Element => {
  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={closeModal ? closeModal : () => null}
      containerClassName={contentStyles.container}
      isBlocking={isBlocking}
    >
      <div className={contentStyles.header}>
        <section style={{ textAlign: 'center' }}>
          <h1>{areYouSureMsg}</h1>
        </section>
        {closeModal && (
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeModal}
          />
        )}
      </div>

      <div className={contentStyles.body}>
        <div style={{ textAlign: 'center' }}>
          <p>{description}</p>
          <p>{status}</p>
        </div>
        <section className={contentStyles.btnLine}>
          <button type="button" className={`btnPrimary`} onClick={closeModal ? closeModal : () => null}>
            ยกเลิก
          </button>
          <button type="button" className={`btnDefault`} onClick={submitModal}>
            ยืนยัน
          </button>
        </section>
      </div>
    </Modal>
  )
}

export default ModalConfirm

const cancelIcon: IIconProps = { iconName: 'Cancel' }

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: '10px',
    padding: '15px 20px',
    width: '250px',
  },
  header: {
    display: 'flex',
    fontFamily: 'Kanit',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    padding: '0 0 10px 0px',
  },

  body: {
    // flex: '4 4 auto',
    fontFamily: 'Kanit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    alignItems: 'center',
    selectors: {
      p: { margin: '14px 0' },
      h1: { margin: '20px 0 0 0 ' },
    },
  },
  btnLine: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
})

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    position: 'absolute',
    right: '10px',
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    // marginTop: '4px',
    // marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}
