export interface Props {
  name: string
  value: string
  label?: string
  checked?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const ColorTheme = {
  color: `black`,
  borderRadius: '100px',
  '&.Mui-checked': {
    color: `#FF8206`,
  },
  '&.Mui-disabled': {
    color: `#B6B6B6`,
  },
}
