import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'formik'

export const FormikInputSelectWithLink: React.FC<{ listSelect: IListSelect }> = ({ listSelect }: any): JSX.Element => {
  return (
    <Field as="select" className={listSelect.selectclass} name={listSelect.name}>
      <option value="" disabled selected style={{ display: 'none' }}></option>
      {listSelect.option &&
        listSelect.option.map((data: any, index: number) => (
          <option className={data.opclass} key={index} value={data.value}>
            {/* {console.log(data, 'data')} */}
            {/* {data.src !== null ? <img src={data.src} alt="" /> : null} */}
            {/* <img src={data.src} alt={data.alt} />  */}

            {data.text}
          </option>
        ))}
    </Field>
  )
}

interface IListSelect {
  name: string
  selectclass: any
  option: { value: any; text: string; opclass?: string }[]
}
