import React from 'react'
import styles from './index.module.scss'
import { Link, useParams } from 'react-router-dom'
import Footercomponent from 'components/footercomponent'

//first section
import indexpic from './indexpic.svg'

//second section
import frontplanpic from './frontplan.svg'
import backplanpic from './backplan.svg'

//third section
import planningsketchpic from './planningsketch.svg'
import imacpic from './imacsketch.svg'
import certifiedpic from './certified.svg'
import wrenchpic from './wrench.svg'

//fourth section

//fifth section
import drillpic from './drill.svg'
import TechnicianLandingPage from 'pages/technicianlandingpage'
import { withFormik } from 'formik'

const mockUpThirdIndexItems = [
  {
    pic: planningsketchpic,
    header: 'วางแผนและบริหารโครงการได้อย่างยืดหยุ่น',
    body: 'คุณสามารถวางแผนงานหรือโครงการของคุณ และทำการติดตามงานจากผู้ให้บริการได้',
  },
  {
    pic: imacpic,
    header: 'ออกแบบและจัดการเอกสาร PAT',
    body: 'คุณออกแบบเอกสาร PATและตรวจสอบ การทำงานของผู้ให้บริการได้จากที่นี่ แล้วคุณสามารถดาวน์โหลดเป็๋น PDF, Excel ได้',
  },
  {
    pic: certifiedpic,
    header: 'ผู้ให้บริการมืออาชีพและมีใบรับรอง',
    body: 'ผู้ให้บริการที่เข้าร่วมกับช่างทำ ผ่านการ อบร, และมีใบรับรอง เซฟตี้พาสปอร์ต ทุกคน',
  },
  {
    pic: wrenchpic,
    header: 'รับประกันคุณภาพของบริการ',
    body: 'พวกเรารับประกันคุณภาพของงานทุกโครงการที่ผ่านแพลตฟอร์ม ช่างทำ',
  },
]

const LandingPage: React.FC = () => {
  const { customerOrTechnician } = useParams()
  if (customerOrTechnician === 'customer') return <CustomerLandingPage />
  else return <TechnicianLandingPage />
}

const CustomerLandingPage: React.FC = () => {
  return (
    <div className={`wrapper2 ${styles.indexBlock}`}>
      <section className={styles.firstIndexBox}>
        <img className={styles.firstIndexImg} src={indexpic} alt="indexpic" />
        <div className={styles.frontGround}></div>
        <article className={styles.firstIndexContent}>
          <p className={styles.firstIndexText}>“คำนึงถึงคุณภาพการทำงาน”เป็นอันดับหนึ่ง”</p>
          <Link to="/customer/login">
            <button className={styles.inputBtn} type="button">
              เริ่มวางแผนโครงการ {'>'}
            </button>
          </Link>
        </article>
      </section>
      <section className={styles.secondIndexBox}>
        <article className={styles.secondIndexTextGroup}>
          <p style={{ color: 'white' }}>รวบรวมช่างจากทั่วประเทศไทย พร้อมระบบบริหารโครงการ</p>
          <h1 className={styles.secondIndexTextTopic}>ChangTum สำหรับรับเหมาโครงการ</h1>
          <p>
            บริการติดตั้งอุปกรณ์ และซ่อมบำรุงสำหรับองค์กร ที่ต้องการผู้รับเหมาหรือช่างที่มีประสบการณ์และใบอนุญาต เซฟตี้
            พาสปอร์ต (Safety Passport) โดยเฉพาะ
          </p>
        </article>
        <article className={styles.secondIndexImgLine}>
          <div className={styles.leftSideImgContainer}>
            <img className={styles.leftSideImg} src={backplanpic} alt="backplanpic" />
          </div>
          <div className={styles.rightSideImgContainer}>
            <img className={styles.rightSideImg} src={frontplanpic} alt="frontplanpic" />
          </div>
        </article>
      </section>
      <section className={styles.thirdIndexBox}>
        <h1 style={{ fontSize: '30px' }}>ทำไมคุณต้องใช้บริการ ChangTum</h1>
        <article className={styles.thirdIndexContent}>
          {mockUpThirdIndexItems.map((thirdIndexElement: any, thirdIndexIndex: number) => {
            return (
              <div className={styles.thirdIndexCard} key={thirdIndexIndex}>
                <img
                  className={styles.thirdIndexCardImg}
                  src={thirdIndexElement.pic}
                  alt={`${thirdIndexElement.pic}`}
                />
                <p className={styles.thirdIndexCardHeader}>{thirdIndexElement.header}</p>
                <p className={styles.thirdIndexCardBody}>{thirdIndexElement.body}</p>
              </div>
            )
          })}
        </article>
      </section>
      <section className={styles.fourthIndexBox}>
        <div className={styles.fourthIndexLeftSideContent}>
          <h2>ขั้นตอนการสมัครใช้บริการ</h2>
          <p>สมัครเข้าสู่ระบบเพื่อใช้บริการกับ ChangTum ง่ายๆ เพียงทำตามขั้นตอนดังนี้</p>
        </div>
        <div className={styles.fourthIndexRightSideContent}>
          <article className={styles.fourthIndexRightSideItems}>
            <p className={styles.fourthIndexIndex}>1.</p>
            <p>กดเข้าสู่ www.ช่างทำ.com หรือ </p>
            <Link to="/customer/login" style={{ color: 'black', textDecoration: 'underline' }}>
              คลิกที่นี่
            </Link>
          </article>
          <article className={styles.fourthIndexRightSideItems}>
            <p className={styles.fourthIndexIndex}>2.</p>
            <section>
              <p style={{ fontSize: '18px' }}>สมัครและลงทะเบียนพร้อม</p>
              <p>กรอกข้อมูลพร้อมอัปโหลดเอกสารให้ครบถ้วน เพื่อลงทะเบียน</p>
            </section>
          </article>
          <article className={styles.fourthIndexRightSideItems}>
            <p className={styles.fourthIndexIndex}>3.</p>
            <p>เปิดการใช้งาน เริ่มวางแผนงานได้ทันที</p>
          </article>
        </div>
      </section>
      <section className={styles.fifthIndexBox}>
        <article className={styles.fifthIndexHeader}>
          <h1 style={{ margin: '0 0 20px 0', fontSize: '25px' }}>
            มากกว่า 100 ทีมผู้ให้บริการ ครอบคุมกว่า 2,500 พื้นที่ ในประเทศไทย
          </h1>
          <p>พวกเรามีทีมผู้ให้บริการมืออาชีพที่มีประสบการณ์ และความชำนาญเฉพาะทาง</p>
          <p>ครอบคลุมทุกพื้นที่บริการในประเทศไทย</p>
        </article>
        <article className={styles.fifthIndexContent}>
          <img className={styles.fifthIndexContentImg} src={drillpic} alt="handholdingdrill" />
          <div className={styles.fifthIndexContentRightSide}>
            <h1 style={{ margin: '0 0 20px 0', fontSize: '25px' }}>ChangTum</h1>
            <p style={{ fontSize: '15px' }}>
              ทีมผู้ให้บริการของเรา เป็นผู้มีประสบการณ์ และความชำราญเฉพาะทาง พร้อมทั้งมีใบรับรอง
              การทำงานสำหรับงานโครงการด้วย
            </p>
            <div className={styles.fifthIndexContentRightSideFooter}>
              <p style={{ fontWeight: 'bold', margin: '0 0 20px 0' }}>
                ถ้าคุณต้องการเป็นผู้ให้บริการงานโครงการ ร่วมกับพวกเรา
              </p>
              <Link to="/technician">
                <button className={styles.fifthIndexContentRightSideFooterBtn} type="button">
                  สมัครเลย
                </button>
              </Link>
            </div>
          </div>
        </article>
      </section>
      <Footercomponent />
    </div>
  )
}

const EnhancedLandingpage = withFormik({
  mapPropsToValues: () => ({
    firstname: '',
    lastname: '',
    tel: '',
    companyname: '',
    footerRemark: '',
  }),
  validate: (values) => {
    //   console.log('I AM VALIDATING')
    //   console.log(values)
  },
  handleSubmit: (values, { props, setFieldValue }: any) => {
    // console.log(values, 'values')
  },
})(LandingPage)

export default EnhancedLandingpage
