import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

const colName = (n: number): string => {
  const ordA = 'A'.charCodeAt(0)
  const ordZ = 'Z'.charCodeAt(0)
  const len = ordZ - ordA + 1

  let s = ''
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s
    n = Math.floor(n / len) - 1
  }
  return s
}

const columnNameGenerator = (n: number): string[] => {
  if (n < 1) return []

  const col: string[] = []

  for (let i = 0; i < n; i++) {
    col.push(colName(i))
  }

  return col
}

const EStatusType: any = {
  PROJECT_ORDER: 'Project Order',
  INSTALLED: 'Installed',
  WAIT_APPROVAL: 'PAT รอตรวจสอบ',
  WAIT_FILL: 'รอการดำเนินการ',
  ACCEPT: 'PAT ผ่าน',
  REJECT: 'PAT รอการแก้ไข',
  TOTAL_SUBMIT: 'รวม PAT Submit',
}

/**
 * column -> unpackproject.customFieldList
 * check -> unpackproject.configReportFieldID and unpackproject.customFieldList.name
 * data -> patstatus -> {
 *  PATStatusType
 * }
 */

export const exportReportStatusExcel = async (projectName: string, data: any) => {
  const patStatusReportTemplate: Record<string, any> = {
    PROJECT_ORDER: {},
    INSTALLED: {},
    WAIT_FILL: {},
    WAIT_APPROVAL: {},
    ACCEPT: {},
    REJECT: {},
    TOTAL_SUBMIT: {},
  }

  const workbook = new ExcelJS.Workbook()
  // console.log('pankorn', { data })

  const sheet = workbook.addWorksheet('Form response 1')
  const topicFont = { name: 'Calibri', size: 11, bold: false }

  const { configReportFieldID, customFieldList } = data.unpackproject as any
  const { patstatus } = data

  sheet.columns = [
    { key: 'no', width: 5 },
    { key: 'PatStatusType', width: 20 },
  ]

  const columns = ['ลำดับ', 'กระบวนการ']

  const customField = customFieldList.find((field: any) => field.name === configReportFieldID)
  const currentDate = new Date()

  // set sheet properties
  sheet.properties.defaultRowHeight = 30

  // Header
  sheet.mergeCells('A1:I1')
  sheet.getCell('A1').value = `Update สถานะ การปรับปรุง POE 2021 ${projectName} ${currentDate}`
  sheet.getCell('A1').style = { alignment: { horizontal: 'center', vertical: 'middle' } }

  if (customField) {
    columns.push(...customField.allowedValueList, 'รวมทั้งหมด')

    const alphabets = columnNameGenerator(columns.length)

    const columnKeys = ['no', 'PatStatusType', ...customField.allowedValueList, 'total']

    // fill exist patStatus
    patstatus.forEach((e: any) => {
      const values = customField.allowedValueList.reduce((acc: any, curr: any) => {
        acc[curr] = e.fieldValue[curr] || 0
        return acc
      }, {})

      patStatusReportTemplate[e.PATStatusType] = {
        ...values,
        total: Object.values(values).reduce((acc: number, curr: any) => acc + curr, 0),
      }
    })

    // fill empty patstatus with 0 value
    Object.entries(patStatusReportTemplate).forEach(([k, v]) => {
      if (Object.values(v).length) return

      patStatusReportTemplate[k] = customField.allowedValueList.reduce((acc: any, curr: any) => {
        acc[curr] = 0
        return acc
      }, {})
      patStatusReportTemplate[k].total = 0
    })

    Object.entries(patStatusReportTemplate).forEach(([k, v]) => {
      if (k === 'ACCEPT' || k == 'REJECT' || k === 'WAIT_APPROVAL') {
        Object.entries(v).forEach(([_k, _v]) => {
          patStatusReportTemplate.TOTAL_SUBMIT[_k] += _v
        })
      }
    })

    // create topic
    columns.forEach((_, index) => {
      const row = sheet.getCell(`${alphabets[index]}2`)
      row.value = columns[index]
      row.font = topicFont
      row.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FF8206',
          },
        },
        alignment: { horizontal: 'center', vertical: 'middle' },
      }
      row.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
    })

    const newData = Object.entries(patStatusReportTemplate).map(([k, v], index) => {
      return {
        no: index + 1,
        PatStatusType: k,
        ...v,
      }
    })

    newData.forEach((data, index) => {
      columnKeys.forEach((column, cindex) => {
        const row = sheet.getCell(`${alphabets[cindex]}${index + 3}`)
        row.value = column === 'PatStatusType' ? EStatusType[data.PatStatusType] : data[column]
        row.style = {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: index % 2 === 0 ? 'f8cbad' : 'FFFFFF',
            },
          },
          alignment: { horizontal: 'center', vertical: 'middle' },
        }
        row.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
      })
    })
  } else {
    // columns.push('รวมทั้งหมด')
    // const columnKeys = ['no', 'PatStatusType', 'total']
    // const values = Object.keys(patStatusReportTemplate).map((e, index) => ({
    //   no: index + 1,
    //   PatStatusType: EStatusType[e],
    //   total: 0,
    // }))
    // const alphabets = columnNameGenerator(columnKeys.length)
    // // create topic
    // columns.forEach((_, index) => {
    //   const row = sheet.getCell(`${alphabets[index]}2`)
    //   row.value = columns[index]
    //   row.font = topicFont
    //   row.style = {
    //     fill: {
    //       type: 'pattern',
    //       pattern: 'solid',
    //       fgColor: {
    //         argb: 'FF8206',
    //       },
    //     },
    //     alignment: { horizontal: 'center', vertical: 'middle' },
    //   }
    //   row.border = {
    //     top: { style: 'thin' },
    //     left: { style: 'thin' },
    //     bottom: { style: 'thin' },
    //     right: { style: 'thin' },
    //   }
    // })
    // values.forEach((data: any, index) => {
    //   columnKeys.forEach((column, cindex) => {
    //     const row = sheet.getCell(`${alphabets[cindex]}${index + 3}`)
    //     row.value = data[column]
    //     row.style = {
    //       fill: {
    //         type: 'pattern',
    //         pattern: 'solid',
    //         fgColor: {
    //           argb: index % 2 === 0 ? 'f8cbad' : 'FFFFFF',
    //         },
    //       },
    //       alignment: { horizontal: 'center', vertical: 'middle' },
    //     }
    //     row.border = {
    //       top: { style: 'thin' },
    //       left: { style: 'thin' },
    //       bottom: { style: 'thin' },
    //       right: { style: 'thin' },
    //     }
    //   })
    // })
  }

  // Footer
  const footerRow = sheet.rowCount + 1
  sheet.mergeCells(`A${footerRow}:I${footerRow}`)
  sheet.getCell(`A${footerRow}`).value =
    '**หมายเหตุ: PAT Submit = เอกสาร PAT ที่ผู้รับเหมาทำการส่งเข้ามาในระบบช่างทำ [รอตรวจสอบ+ผ่าน+รอการแก้ไข]'
  sheet.getCell(`A${footerRow}:I${footerRow}`).style = { alignment: { horizontal: 'center', vertical: 'middle' } }

  // console.log('pankorn', { configReportFieldID, customFieldList, patstatus, columns, patStatusReportTemplate })

  // Export File
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const fileName = `StatusPAT_${projectName}_${currentDate}`

  const buffer = await workbook.xlsx.writeBuffer()

  const excelToSave = new Blob([buffer], { type: fileType })

  saveAs(excelToSave, fileName + fileExtension)
}
