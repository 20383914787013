import React, { useState, useEffect } from 'react'
import styles from './toolbar.module.scss'
import MoreIcon from '../../image/more.svg'
import arrowboxpic from 'components/ChungTumTable/arrowbox.svg'
import { useFormikContext } from 'formik'
import { NewExportAIS } from 'utils/newExportAIS'
import { NewExportInno } from 'utils/newExportInno'

const monthFilterOptions: IOption[] = []
for (let monthIndex = 1; monthIndex <= 12; monthIndex++) {
  monthFilterOptions.push({
    value: monthIndex,
    text: new Date(2000, monthIndex - 1, 1).toLocaleDateString('th-TH', { month: 'long' }),
  })
}

interface IOption {
  value: string | number
  text: string
}

const ToolbarComponent: React.FC<any> = (props: any) => {
  const [showExportSwitch, setShowExportSwitch] = useState(false)
  const { values, setFieldValue } = useFormikContext<any>()

  return (
    <section className={styles.toolbarWrapper}>
      <article className={styles.lefttool}>
        {/* Year Filter */}
        <select
          className={styles.thirdcontentselect}
          value={props.year}
          onChange={(e) => props.setYear(parseInt(e.target.value))}
        >
          {[...Array(20).keys()].map((yearIndex) => {
            const yearSelection = props.year + yearIndex - 10
            return (
              <option key={yearIndex} value={yearSelection}>
                {yearSelection}
              </option>
            )
          })}
        </select>
        <select
          className={styles.thirdcontentselect}
          value={props.month}
          onChange={(e) => props.setMonth(parseInt(e.target.value))}
        >
          {monthFilterOptions.map((optionElement, optionIndex) => (
            <option key={optionIndex} value={optionElement.value}>
              {optionElement.text}
            </option>
          ))}
        </select>
        <select
          value={values.masterPlan.selectTechnician}
          onChange={(e) => {
            setFieldValue('masterPlan.selectTechnician', e.target.value)
            // setFieldValue('masterPlan.selectTechnician', e.target.value)
            // setFieldValue('masterPlan.selectTechnician', e.target.value)
          }}
          className={styles.thirdcontentselect}
        >
          {console.log(values.masterPlan.selectTechnician, 'MasterPlannnnnnnnnnnnnnnn')}
          <option value="ALL">เลือกทีมงานช่างทั้งหมด</option>

          {values.acceptedUserIdList?.map((user: any) => (
            <option key={user.userId} value={user.userId}>
              {user.getUser.username}
            </option>
          ))}
        </select>
      </article>
      <article className={styles.righttool}>
        {/* nut */}
        <div onClick={() => setShowExportSwitch(!showExportSwitch)} className={styles.exportBtn}>
          <div className={styles.TextInBtn}>Export</div>
          <img src={MoreIcon} alt="" />
          {showExportSwitch && (
            <div className={styles.exportZone}>
              <div
                onClick={() => {
                  NewExportAIS(
                    {
                      masterPlan: props.values.masterPlan.groupFieldList,
                      planDate: props.values.planDateReport.groupFieldList,
                      actualDate: props.values.actualDateReport.groupFieldList,
                    },
                    props.year,
                    ['masterPlan', 'actualDate'],
                  )
                }}
                className={styles.exportItem}
              >
                Export Master Plan
              </div>
              <div
                onClick={() => {
                  NewExportInno(props.values.projectSelectedId, props.year, props.month, true, true, true, props.values)
                  // console.log(props, 'new nut')
                }}
                className={styles.exportItem}
              >
                Export Master Plan Detail
              </div>
            </div>
          )}
        </div>
        <div className={styles.arrowParentWrapper}>
          <label className={styles.btnnobackground} htmlFor={props.tableId.replace(/ /g, '')}>
            <img className={styles.btnnobackgroundimg} src={arrowboxpic} alt="arrowboxpic" />
          </label>
          <input
            className={styles.hiddenColumnToggle}
            type="checkbox"
            defaultChecked={true}
            style={{ display: 'none' }}
            id={props.tableId.replace(/ /g, '')}
          />
          <div className={styles.columnCheckboxWrapper}>
            <label>
              <input
                type="checkbox"
                checked={!props.provinceFilter}
                onChange={(e) => {
                  props.setProvinceFilter(!(e.target as any).checked)
                }}
              />
              จังหวัด
            </label>
          </div>
        </div>
      </article>
    </section>
  )
}

export default ToolbarComponent
