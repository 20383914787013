import React, { useState } from 'react'
import styles from './index.module.scss'
import { useLocation } from 'react-router-dom'
import { useFormikContext } from 'formik'
import dropLogo from 'image/droplogo.svg'
import Graph from './graph'
import ChungTumTable, { CELL_TYPE, ITableColumn } from 'components/ChungTumTable'
// import { THAILAND_REGION2CODE_MAPPER, THAILAND_REGION_MAPPER } from 'components/commons/thailandFact'
import { getProjectPC13, IProject, IWorkType, refreshPATNC } from 'adapter/xhr'
import { saveAs } from 'file-saver'

// import { generatePATExcelReportNCBuffer } from './generatePATExcelReportNC'

const ReportNC: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>()
  const { search } = useLocation()
  const [loadingRefresh, setLoadingRefresh] = useState(false)
  const params = new URLSearchParams(search)

  const handleDownloadExcel = async (newJobList: any[]) => {
    const currentDate = new Date()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `PAT_${values.projectSelectedId}_${currentDate}`
    const buffer = await import('./generatePATExcelReportNC').then((genExcel) => {
      return genExcel.generatePATExcelReportNCBuffer(newJobList)
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    // console.log('THIS IS savesad ', excelToSave)
    saveAs(excelToSave, fileName + fileExtension)
    // console.log('SAVE SUCCESS')
  }
  const refreshPATNCOnPage = () => {
    setLoadingRefresh(true)
    refreshPATNC(values.unpackproject.projectId).then((res: any) => {
      console.log('refreshPATNCOnPage>refreshPATNC>res', res)
      if (res.data.data.refreshPATNC !== 'Success') {
        console.log('refreshPATNC', res.data.data.refreshPATNC)

        setLoadingRefresh(false)
      } else {
        getProjectPC13(values.unpackproject.projectId).then((res: any) => {
          const project = res.data.data.project

          setFieldValue('values.revisionCountTechnician', project.revisionCountTechnician)
          setFieldValue('values.revisionCountWorkType', project.revisionCountWorkType)
          setFieldValue('values.revisionHistogram', project.revisionHistogram)
          setFieldValue('values.siteCountTechnician', project.siteCountTechnician)

          setLoadingRefresh(false)
        })
      }
    })
  }

  function getUsernameFromID(userId: string) {
    const userId2Name: any = {}
    values.unpackproject.acceptedUserIdList.map((technician: any) => {
      userId2Name[technician.userId] = technician.getUser.username || technician.userId
    })
    return userId2Name[userId]
  }

  return (
    <div className={styles.reportNCContainer}>
      <section className={styles.exportBtnContainer}>
        <button
          className={styles.refreshButton}
          type="button"
          onClick={() => {
            refreshPATNCOnPage()
          }}
        >
          {/* {loadingRefresh && <img src={loadingGIF} alt="loading..." style={{ marginRight: '5px' }} />} */}
          {loadingRefresh && <div className={styles.loader} />}
          {loadingRefresh && <span>Loading Data...</span>}
          {!loadingRefresh && <span>Refresh</span>}
        </button>
        <button
          type="button"
          className={'btExport'}
          onClick={() => {
            const newJobList: any[] = []
            values.unpackproject.workTypeList.map((workTypeElement: IWorkType, workTypeIndex: number) => {
              if (workTypeElement.jobList && values.unpackproject.workTypeList[workTypeIndex].jobList.length > 0) {
                workTypeElement.jobList.map((jobElement: any) => {
                  newJobList.push(jobElement)
                })
              }
            })
            console.log(newJobList, 'excel workTypeElement')
            handleDownloadExcel(newJobList)
          }}
        >
          <p>Export</p> <img src={dropLogo} alt="" />
        </button>
      </section>
      <section className={styles.graphCardContainer}>
        <article className={styles.graph1}>
          <p className={styles.graphTopic}>#Revision vs. Technician</p>
          <Graph
            labels={
              Object.keys(values.revisionCountTechnician).length > 0
                ? Object.keys(values.revisionCountTechnician).map(getUsernameFromID)
                : []
            }
            data={
              Object.values(values.revisionCountTechnician).length > 0
                ? Object.values(values.revisionCountTechnician)
                : []
            }
            indexAxis="y"
          />
        </article>
        <article className={styles.graph2}>
          <p className={styles.graphTopic}>Revision Frequency per Site</p>

          <Graph
            labels={Object.keys(values.revisionHistogram).length > 0 ? Object.keys(values.revisionHistogram) : []}
            data={Object.values(values.revisionHistogram).length > 0 ? Object.values(values.revisionHistogram) : []}
            indexAxis="x"
          />
        </article>
        <article className={styles.graph3}>
          <p className={styles.graphTopic}>#Revision vs. WorkType</p>
          <Graph
            labels={
              Object.keys(values.revisionCountWorkType).length > 0
                ? Object.keys(values.revisionCountWorkType).map((workType) => workType.substr(0, 15))
                : []
            }
            data={
              Object.values(values.revisionCountWorkType).length > 0 ? Object.values(values.revisionCountWorkType) : []
            }
            indexAxis="y"
          />
        </article>
        <article className={styles.graph4}>
          <p className={styles.graphTopic}>Average #Revision per Site vs.Technician</p>
          <Graph
            labels={
              Object.keys(values.siteCountTechnician).length > 0
                ? Object.keys(values.siteCountTechnician).sort().map(getUsernameFromID)
                : []
            }
            data={
              Object.keys(values.siteCountTechnician).length > 0
                ? Object.keys(values.siteCountTechnician)
                    .sort()
                    .map((acceptedUserId) => {
                      return values.revisionCountTechnician[acceptedUserId] / values.siteCountTechnician[acceptedUserId]
                    })
                : []
            }
            indexAxis="y"
          />
        </article>
      </section>
      <section className={styles.tableSectionContainer}>
        {values.unpackproject &&
          values.unpackproject.workTypeList.map((workTypeElement: IWorkType, workTypeIndex: number) => {
            if (workTypeElement.jobList && values.unpackproject.workTypeList[workTypeIndex].jobList.length > 0) {
              console.log(workTypeElement.jobList, 'workTypeElement.jobList')
              return (
                <div className={styles.tableWorkTypeContainer} key={workTypeIndex}>
                  <h1>{workTypeElement.workType}</h1>
                  <ChungTumTable
                    tableColumns={TABLE_COLUMNS_CUSTOMER}
                    data={workTypeElement.jobList}
                    formikPrefix={``}
                    disableRemove
                    showTotal
                    showToolbar
                    tableId={`T${workTypeElement.lineNumber}`}
                    regionFilterInput={
                      params.get('tableId') === `T${workTypeElement.lineNumber}` && params.get('regionFilter')
                        ? (params.get('regionFilter') as string)
                        : ''
                    }
                    patStatusFilterInput={
                      params.get('tableId') === `T${workTypeElement.lineNumber}` && params.get('patStatusFilter')
                        ? (params.get('patStatusFilter') as string)
                        : ''
                    }
                    textFilterInput={
                      params.get('tableId') === `T${workTypeElement.lineNumber}` && params.get('textFilter')
                        ? (params.get('textFilter') as string)
                        : ''
                    }
                  />
                </div>
              )
            }
          })}
      </section>
    </div>
  )
}

export default ReportNC

const TABLE_COLUMNS_CUSTOMER: ITableColumn[] = [
  {
    name: 'revisionNo',
    text: '',
    engText: 'revision',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteCode',
    text: '(รหัสไซต์)',
    engText: 'siteCode',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'siteName',
    text: '(ชื่อไซต์)',
    engText: 'siteName',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'workType',
    text: '(ประเภทงาน)',
    engText: 'workType',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  // {
  //   name: '',
  //   text: '(ช่าง)',
  //   engText: 'technician',
  //   columnSize: '100px',
  //   readOnly: true,
  //   type: CELL_TYPE.TEXT,
  // },
  {
    name: 'customAttributeEditFieldID',
    text: '#',
    engText: 'CustomField',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'zone',
    text: '(โซน)',
    engText: 'zone',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'province',
    text: '(จังหวัด)',
    engText: 'Province',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'region',
    text: '(ภูมิภาค)',
    engText: 'Region',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'district',
    text: '(อำเภอ)',
    engText: 'District',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'number',
    text: '(เลขที่)',
    engText: 'number',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'description',
    text: '(รายละเอียด)',
    engText: 'description',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'name',
    text: '#',
    engText: 'name',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'status',
    text: '(สเตตัส)',
    engText: 'statue',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'updatedAt',
    text: '(อัพเดตเมื่อ)',
    engText: 'updatedAt',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'acceptedUserId',
    text: '(รหัสผู้ใช้งาน)',
    engText: 'userId',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'versionNo',
    text: '(เวอร์ชั่น)',
    engText: 'versionNo',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'changTumId',
    text: '(รหัสช่างทำ)',
    engText: 'changtamID',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'projectId',
    text: '(รหัสโปรเจค)',
    engText: 'projectID',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'assignedUserId',
    text: 'ช่างผู้รับผิดชอบ',
    engText: 'Res.Person',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
  {
    name: 'createdAt',
    text: '(สร้างเมื่อ)',
    engText: 'createAt',
    columnSize: '100px',
    readOnly: true,
    type: CELL_TYPE.TEXT,
  },
]
