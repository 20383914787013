import { useState, useEffect, useRef } from 'react'

export interface Dimensions {
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  width?: number
  height?: number
}

interface ReturnDimensions {
  marginTop: number
  marginRight: number
  marginBottom: number
  marginLeft: number
  width?: number
  height?: number
  boundedHeight: number
  boundedWidth: number
}

const combineChartDimensions = (dimensions: Dimensions): ReturnDimensions => {
  const parsedDimensions = {
    ...dimensions,
    marginTop: dimensions.marginTop || 10,
    marginRight: dimensions.marginRight || 10,
    marginBottom: dimensions.marginBottom || 40,
    marginLeft: dimensions.marginLeft || 75,
  }
  return {
    ...parsedDimensions,
    boundedHeight: Math.max(parsedDimensions.height! - parsedDimensions.marginTop - parsedDimensions.marginBottom, 0),
    boundedWidth: Math.max(parsedDimensions.width! - parsedDimensions.marginLeft - parsedDimensions.marginRight, 0),
  }
}

const useChartDimensions = (passedSettings: Dimensions): [React.MutableRefObject<any>, ReturnDimensions] => {
  const ref = useRef<any>()
  const dimensions = combineChartDimensions(passedSettings)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  if (dimensions.width && dimensions.height) return [ref, dimensions]
  useEffect(() => {
    const element = ref.current
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) return
      if (!entries.length) return
      const entry = entries[0]
      if (width != entry.contentRect.width) setWidth(entry.contentRect.width)
      if (height != entry.contentRect.height) setHeight(entry.contentRect.height)
    })
    resizeObserver.observe(element)
    return () => resizeObserver.unobserve(element)
  }, [])
  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height,
  })
  return [ref, newSettings]
}

export default useChartDimensions
