import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import styles from './index.module.scss'
// import PdfIcon from './pdfIcon.svg'
import ExcelIcon from './excelIcon2.svg'
// import ExcelIcon from './excelIcon'
import FooterComponant, { IListButton } from 'components/commons/btnFooter'
import { useFormikContext, withFormik, Form } from 'formik'
import ModalAlert from 'components/commons/modalAlert'
import ModalConfirm from 'components/commons/modalConfirm'
import LineGroupComponant from './lineTypeComponant'
// Try out Code Splitting
// import { generateAISPATExcelBuffer } from './generatePATExcel'
import { saveAs } from 'file-saver'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { IJob, createNotification, getCreatedbyUserId, ITitleTemplate, getUserProfile } from 'adapter/xhr'
import { getUnpackedPatbyPatId, getRawJobByChangTumId, packAndPutPat, packAndRedoPat } from 'adapter/pat'
import HeaderRowComponent from './headerRow'
import { PatLine, Pat_Export, User } from 'utils/generated'
// import { IOption } from 'components/commons/input/formikInputSelect'

const PatUpload: React.FC = (): JSX.Element => {
  const { values, setFieldValue, validateForm, errors } = useFormikContext<IValues>()
  const { patId, customerOrTechnician } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [jobInformation, setJobInformation] = useState<IJob>()
  const [isPatLoaded, setIsPatLoaded] = useState<boolean>(false)
  const [isJobLoaded, setIsJobLoaded] = useState<boolean>(false)
  const userinfo = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken.username)
  // 3 Modals: UploadSaveSuccess, UploadSubmitSuccess, ApproveConfirm
  const [isModalUploadSaveSuccess, setIsModalUploadSaveSuccess] = useState<boolean>(false)
  const [isModalUploadSubmitSuccess, setIsModalUploadSubmitSuccess] = useState<boolean>(false)
  const [isModalApproveConfirm, setIsModalApproveConfirm] = useState<boolean>(false)

  // useEffect(() => {
  //   console.log('ROOT VALUE CHANGE', values)
  // }, [values])

  const generateSearchParams = () => {
    let searchParams = '?'
    if (location.state && location.state.tableId) {
      searchParams += `tableId=${location.state.tableId}`
      searchParams += location.state.patStatusFilter ? `&patStatusFilter=${location.state.patStatusFilter}` : ''
      searchParams += location.state.regionFilter ? `&regionFilter=${location.state.regionFilter}` : ''
      searchParams += location.state.textFilter ? `&textFilter=${location.state.textFilter}` : ''
    }
    return searchParams
  }

  const onClickGoback = () => {
    // Fix back to PAT MANAGEMENT ONLY FIRST!
    navigate(
      {
        pathname: `/${customerOrTechnician}/projectmanagementpage`,
        hash: '#PAT_MANAGEMENT',
        search: generateSearchParams(),
      },
      {
        state: {
          status: 'PAT_MANAGEMENT',
          projectSelectedId: location.state.projectSelectedId,
        },
      },
    )
  }

  // Set Mode action
  useEffect(() => {
    if (location.pathname.split('/')[2] === 'pat-upload') {
      setFieldValue('modeAction', 'upload', false)
    } else if (location.pathname.split('/')[2] === 'pat-approve') {
      setFieldValue('modeAction', 'approve', false)
    }
    getUserProfile().then((profile: any) => {
      console.log('Get Profile', profile)
      setFieldValue('profile', profile.data.data.getUser)
    })
  }, [location.pathname])

  // Set Mode Action and Query PAT
  useEffect(() => {
    getUnpackedPatbyPatId(patId!)
      .then((unpackedPat) => {
        setFieldValue('unpackedPat', unpackedPat, false)
        setIsPatLoaded(true)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [patId])

  // Query Job and Put in Job (If the pat is the PAT Instantiated not template)
  useEffect(() => {
    if (patId && patId.substr(0, 1) === 'I') {
      console.log('HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      const projectId = patId.substr(1, 14)
      const changTumId = patId.substr(15) // Edit: To the end of the string
      // console.log('getRawJobByChangTumId', projectId, changTumId)

      getRawJobByChangTumId(projectId, changTumId)
        .then((jobResponse) => {
          console.log('jobResponse >>>>>>>>>>>>>>', jobResponse)
          setJobInformation(jobResponse)
          setIsJobLoaded(true)
          setFieldValue('job', jobResponse)
        })
        .catch((err) => {
          console.error(err, 'useEffect>getRawJobByChangTumId')
        })
    } else {
      //Note: The loader will fail if it is pat template T. Just make Job load true if it is not I
      setIsJobLoaded(true)
    }
  }, [patId])

  // For PatUpload -> WAIT_FILL - WAIT_FILL / WAIT_FILL - WAIT_APPROVAL
  // Modal After submission - Wait for Admin
  // TODO : Add REDO if V2
  const handleSubmit = (approve = false) => {
    // If pat status is ACCEPT or WAIT_APPROVAL, just go back to projectmanagementpage
    // Only allow REJECT and WAIT_FILL statuses
    if (values.unpackedPat.status === 'WAIT_APPROVAL' || values.unpackedPat.status === 'ACCEPT') {
      navigate(
        {
          pathname: `/${customerOrTechnician}/projectmanagementpage`,
          hash: '#PAT_MANAGEMENT',
          search: generateSearchParams(),
        },
        {
          state: {
            status: 'PAT_MANAGEMENT',
            projectSelectedId: location.state.projectSelectedId,
          },
        },
      )
    }

    // console.log('patUpload>handleSubmit>values.unpackedPat', values.unpackedPat)

    const packAndPutOrRedoPat =
      values.modeAction === 'upload' && values.unpackedPat.status === 'REJECT' ? packAndRedoPat : packAndPutPat

    const unpackedPAT = {
      ...values.unpackedPat,
      status: approve ? 'WAIT_APPROVAL' : values.unpackedPat.status,
    }

    packAndPutOrRedoPat(unpackedPAT)
      .then(() => {
        if (approve) {
          setIsModalUploadSubmitSuccess(true)
        } else {
          setIsModalUploadSaveSuccess(true)
        }
        const projectId = patId!.substr(1, 14)

        getCreatedbyUserId(projectId).then((res) => {
          const createdByUserId = res.data.data.project.createdByUserId
          const projectName = res.data.data.project.projectName

          const CreateNotificationInput = {
            userId: `${createdByUserId}`,
            message: `<b>${userinfo}.</b> ได้ทำการอัพโหลดงาน ลง" ชื่อโปรเจ็ค <b>${projectName}</b> + ชื่อประเภทโปรเจ็ค ${jobInformation?.workType} + รหัสไซต์งาน ${jobInformation?.siteCode} + ชื่อไซต์งาน ${jobInformation?.siteName}"  คุณสามารถเข้ามาตรวจสอบได้แล้ว <span style="color:#FFD709>(รอการตรวจสอบ)</span>`,
            iconUrl: '',
            toLink: `/customer/pat-approve/${patId}`,
            isRead: false,
          }
          createNotification(CreateNotificationInput)
            .then(() => {
              setTimeout(() => {
                navigate(
                  {
                    pathname: `/${customerOrTechnician}/projectmanagementpage`,
                    hash: '#PAT_MANAGEMENT',
                    search: generateSearchParams(),
                  },
                  {
                    state: {
                      status: 'PAT_MANAGEMENT',
                      projectSelectedId: location.state && location.state.projectSelectedId,
                    },
                  },
                )
              }, 3000)
            })
            .catch((err) => {
              console.log(err, 'err')
            })
        })
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }
  const handleSubmitSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    validateForm().then(() => handleSubmit(false))
  }
  const handleSubmitApprove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    validateForm().then(() => handleSubmit(true))
  }

  const blockingAcceptModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    validateForm().then(() => {
      setIsModalApproveConfirm(true)
    })
  }

  // For PatApprove -> WAIT_APPROVAL - ACCEPT/REJECT
  // Modal Before submission - confirmation
  // Modal After submission ??
  const handleSubmitAcceptReject = (): void => {
    // If pat status is ACCEPT or REJECT, just go back to projectmanagementpage
    if (values.unpackedPat.status === 'ACCEPT' || values.unpackedPat.status === 'REJECT') {
      navigate(
        {
          pathname: `/${customerOrTechnician}/projectmanagementpage`,
          hash: '#PAT_MANAGEMENT',
          search: generateSearchParams(),
        },
        {
          state: {
            status: 'PAT_MANAGEMENT',
            projectSelectedId: location.state.projectSelectedId,
          },
        },
      )
      return
    }

    // console.log('patUpload>handleSubmitAcceptReject', values.unpackedPat)

    validateForm().then(() => {
      // Assuming lineList has only one group, so using only flat with depth of 1
      const approveLines = values.unpackedPat.lineList
        .flat()
        .filter((lineElement: any) => lineElement.lineType === 'APPROVE')

      // Case If all isApproved: true = ACCEPT, else REJECT
      const isAllApproved = approveLines.every((lineElement: any) => lineElement.isApproved === true)

      const unpackedPAT = {
        ...values.unpackedPat,
        status: isAllApproved ? 'ACCEPT' : 'REJECT',
      }

      const projectId = patId!.substr(1, 14)

      packAndPutPat(unpackedPAT)
        .then(() => {
          setIsModalUploadSaveSuccess(true)
          getCreatedbyUserId(projectId).then((res) => {
            const projectName = res.data.data.project.projectName

            const CreateNotificationInput = {
              userId: `${jobInformation?.acceptedUserId}`,
              message: `<b>${userinfo}.</b> ได้ทำการตรวจสอบงาน" ชื่อโปรเจ็ค <b>${projectName}</b> + ชื่อประเภทโปรเจ็ค ${
                jobInformation?.workType
              } + รหัสไซต์งาน ${jobInformation?.siteCode} + ชื่อไซต์งาน ${
                jobInformation?.siteName
              }"  คุณสามารถเข้ามาตรวจสอบได้แล้ว <span style="color :${
                unpackedPAT.status === 'REJECT' ? '#FF0909;' : unpackedPAT.status === 'ACCEPT' ? '#12C73C;' : ''
              }">${unpackedPAT.status}</span>`,
              iconUrl: '',
              toLink: `/technician/pat-upload/${patId}`,
              isRead: false,
            }
            createNotification(CreateNotificationInput)
              .then(() => {
                setTimeout(() => {
                  navigate(
                    {
                      pathname: `/${customerOrTechnician}/projectmanagementpage`,
                      hash: '#PAT_MANAGEMENT',
                      search: generateSearchParams(),
                    },
                    {
                      state: {
                        status: 'PAT_MANAGEMENT',
                        projectSelectedId: location.state.projectSelectedId,
                      },
                    },
                  )
                }, 3000)
              })
              .catch((err) => {
                console.log(err, 'err')
              })
          })
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    })
  }

  const handleDownloadExcel = async (
    jobInformation: IJob,
    unpackedPAT: IUnpackPAT,
    isGenerateAISBayface = true,
    companyName: string,
    logoUrl: string | null,
  ) => {
    const patExport: Pat_Export = unpackedPAT.patExport as Pat_Export
    const patDescription = unpackedPAT.description || 'งานปรับปรุงตู้ POE ในโครงการ AIS'
    const region = jobInformation?.region || 'BMR'
    const siteName = jobInformation?.siteName || 'สถานีรถไฟลพบุรี'
    const siteCode = jobInformation?.siteCode || 'LBRWM'
    const number = jobInformation?.number || 'C1'
    const actualDate = jobInformation?.actualDate || '17/11/2020'
    const province = jobInformation?.province || 'ลพบุรี'
    const titleTemplate: ITitleTemplate = unpackedPAT.titleTemplate || {
      titleTemplateId: '',
      attributeList: [],
    }
    const lineList: any[] = unpackedPAT.lineList
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `PAT_${region}-${siteCode}-${number}`

    // Try out code Splitting as done here:
    // https://reactjs.org/docs/code-splitting.html
    // source map documents exceljs as 1.11MB 18.5% of all node_modules, maybe 2.69 mb in no source?
    let buffer: Buffer | null = null
    if (patExport === Pat_Export.LongForm || patExport === Pat_Export.SplitPage) {
      buffer = (await import('./generatePATTower').then((genPat) => {
        return genPat.generateTowerPATExcelBuffer(
          jobInformation,
          unpackedPAT,
          isGenerateAISBayface,
          patExport,
          companyName,
          logoUrl,
        )
      })) as Buffer
    } else if (patExport === Pat_Export.LongFormPoe || !patExport) {
      buffer = (await import('./generatePATExcel').then((genPat) => {
        return genPat.generateAISPATExcelBuffer(
          patDescription,
          siteName,
          siteCode,
          actualDate,
          province,
          lineList,
          isGenerateAISBayface,
          titleTemplate,
        )
      })) as Buffer
    }

    const excelToSave = new Blob([buffer as Buffer], { type: fileType })

    // // console.log('THIS IS savesad ', excelToSave)
    saveAs(excelToSave, fileName + fileExtension)
    // console.log('SAVE SUCCESS')
  }
  // FooterComponentButtonList
  // modeAction                    pat-upload (ช่าง)                        pat-approve (ผู้รับเหมา)
  //                           /         |          \                          /              \
  // patStatus         REJECT         WAIT_FILL   WAIT_APPROVAL         WAIT_APPROVAL       WAIT_FILL
  //                                                ACCEPT                                 ACCEPT, REJECT
  // ButtonList    [Back, แก้ไขงาน] [Back,Save,Send]  [Back]              [Back,Confirm]        [Back]
  const [footerComponentButtonList, setFooterComponentButtonList] = useState<IListButton[]>([])
  useEffect(() => {
    if (values.modeAction && values.unpackedPat.status) {
      const buttonList = [backButton]
      if (values.modeAction === 'upload') {
        if (values.unpackedPat.status === 'REJECT') buttonList.push(redoButton)
        else if (values.unpackedPat.status === 'WAIT_FILL') buttonList.push(saveButton, sendButton)
      } else if (values.modeAction === 'approve') {
        if (values.unpackedPat.status === 'WAIT_APPROVAL') buttonList.push(confirmButton)
      }
      setFooterComponentButtonList(buttonList)
    }
    // DANGEROUS. values is VERY required here else the buttonList will push the functions with OLD VALUES!!!!!!!!
  }, [values.modeAction, values.unpackedPat.status, values])
  const backButton: IListButton = {
    fucnctionOnclick: onClickGoback,
    text: 'ย้อนกลับ',
    type: 'button',
    class: 'btnDefault3',
  }
  const redoButton: IListButton = {
    fucnctionOnclick: handleSubmitApprove,
    text: 'แก้ไขงาน',
    type: 'button',
    class: 'btnDefault4',
  }
  const saveButton: IListButton = {
    fucnctionOnclick: handleSubmitSave,
    text: 'บันทึก',
    type: 'button',
    class: 'btnDefault',
  }
  const sendButton: IListButton = {
    fucnctionOnclick: handleSubmitApprove,
    text: 'ส่งงาน',
    type: 'button',
    class: 'btnDefault4',
  }
  const confirmButton: IListButton = {
    fucnctionOnclick: blockingAcceptModal,
    text: 'ยืนยัน',
    type: 'submit',
    class: 'btnDefault',
  }

  return (
    <Form style={{ backgroundColor: 'white' }}>
      <div className={`wrapper2 ${styles.navigationPage}`}>
        {`บริหารโครงการ > Pre-Acceptance Test (PAT) > ประเภทงาน ${jobInformation?.workType} > ${jobInformation?.siteCode} >`}
        <b>{`${values.unpackedPat.name}`}</b>
      </div>

      <section className="wrapper2" style={{ paddingTop: '20px' }}>
        {!(isJobLoaded && isPatLoaded) && (
          <div className="loaderWrapper">
            <div className="loader" />
          </div>
        )}
        {isJobLoaded && isPatLoaded && (
          <>
            {/* Header of the Job Information */}
            <div className={`${styles.detailProject} justifyContentBetween`}>
              <div>
                <h3>
                  {jobInformation?.region} {jobInformation?.zone}
                </h3>
                <p>
                  รหัสไซต์: {jobInformation?.siteCode} {jobInformation?.siteName} {jobInformation?.number}
                  {jobInformation?.jobId}
                </p>
                <p>
                  สถานที่: {jobInformation?.province} {jobInformation?.district}
                </p>
                <p>
                  Latitude: {jobInformation?.lat} Longitude: {jobInformation?.long}
                </p>
              </div>
              <div>
                <div className={`row alignItemCenter ${styles.flexEnd}`}>
                  ดาวน์โหลดไฟล์
                  <button
                    className={styles.excelButton}
                    type="button"
                    onClick={(e) =>
                      handleDownloadExcel(
                        jobInformation as IJob,
                        values.unpackedPat,
                        true,
                        values.profile.companyName ? values.profile.companyName : '',
                        values.profile.logoUrl || null,
                      )
                    }
                  >
                    <img src={ExcelIcon} width="40" />
                  </button>
                  {/* <button
                    className={styles.excelButton}
                    type="button"
                    onClick={(e) =>
                      handleDownloadExcel(
                        values.unpackedPat.description,
                        jobInformation?.siteName,
                        jobInformation?.siteCode,
                        jobInformation?.actualDate,
                        jobInformation?.province,
                        values.unpackedPat.lineList,
                      )
                    }
                  >
                    <img src={PdfIcon} width="33" />
                  </button> */}
                </div>
                <h2 className={`row alignItemCenter ${styles.flexEnd}`}>
                  สถานะ: {`${PAT_STATUS_MAPPER[values.unpackedPat.status]} V${values.unpackedPat.versionNo}`}
                </h2>
              </div>
            </div>

            {/* PAT Information: Name and Description */}
            <div className={`column ${styles.headerWrapper}`}>
              <div className={styles.headCard}>
                <h3>รายละเอียดงาน </h3>
              </div>
              <div className={styles.bodyCard}>
                <h2>{values.unpackedPat.name}</h2>
                <p>{values.unpackedPat.description}</p>
              </div>
            </div>

            <br />

            {values.unpackedPat.headerList && (
              <div className={`column ${styles.cardHeader}`}>
                <h4>Header</h4>

                <div className={styles.box}>
                  {values.unpackedPat.headerList &&
                    values.unpackedPat.headerList.map((item: any, idx: number) => (
                      <div className={styles.wrapperBox} key={idx}>
                        <div className={'inputLineBottom'}>
                          <p>{item.name}</p>
                        </div>
                        <div className={'inputLineBottom'}>
                          <p>{(jobInformation && jobInformation[`${item.colName}`]) || ''}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {/* Title Page Components If Have! */}
            {values.unpackedPat.titleTemplate?.titleTemplateId && (
              <div className={`column ${styles.card}`}>
                <div className={styles.bodyCardRadius}>
                  <div className={`${styles.lineIndexLabel} ${styles.titleIndexLabel}`}>Title</div>
                  {values.unpackedPat.titleTemplate?.attributeList?.length &&
                    values.unpackedPat?.titleTemplate?.attributeList?.map((row, rowIndex) => (
                      <HeaderRowComponent
                        key={rowIndex}
                        rowData={row}
                        filePrefix={`header-${rowIndex}`}
                        formikPrefix={`unpackedPat.titleTemplate.attributeList[${rowIndex}]`}
                      />
                    ))}
                </div>
              </div>
            )}

            {/* Actual Line Type Components */}
            <div className={`column ${styles.card}`}>
              {values.unpackedPat.lineList.map((card: PatLine[], indexCard: number) => {
                return (
                  <div key={indexCard} className={styles.bodyCardRadius}>
                    <div className={styles.lineIndexLabel}>{indexCard + 1}</div>
                    {LineGroupComponant(card, indexCard, 'unpackedPat.lineList', jobInformation as IJob)}
                  </div>
                )
              })}
            </div>
          </>
        )}
      </section>
      <FooterComponant
        listButtons={footerComponentButtonList}
        // listButtons={[saveButton]}
      />
      <ModalAlert
        isModalOpen={isModalUploadSaveSuccess}
        title={values.unpackedPat.name}
        detail={`${jobInformation?.region} ${jobInformation?.zone}`}
        note={`รหัสไซต์: ${jobInformation?.siteCode} ${jobInformation?.siteName} ${jobInformation?.number} ${jobInformation?.jobId}`}
        status={'บันทึกเสร็จสมบูรณ์'}
      />
      <ModalAlert
        isModalOpen={isModalUploadSubmitSuccess}
        title={values.unpackedPat.name}
        detail={`${jobInformation?.region} ${jobInformation?.zone}`}
        note={`รหัสไซต์: ${jobInformation?.siteCode} ${jobInformation?.siteName} ${jobInformation?.number} ${jobInformation?.jobId}`}
        status={'รอการอนุมัติจากแอดมิน'}
      />
      <ModalConfirm
        isModalOpen={isModalApproveConfirm}
        closeModal={() => setIsModalApproveConfirm(false)}
        submitModal={() => handleSubmitAcceptReject()}
        description={`ข้อมูลของ${jobInformation?.region} ${jobInformation?.zone} รหัสไซต์: ${jobInformation?.siteCode} ${jobInformation?.siteName} ${jobInformation?.number} ${jobInformation?.jobId}`}
        status={
          values.modeAction === 'approve' &&
          (values.unpackedPat.status === 'ACCEPT' || values.unpackedPat.status === 'REJECT')
            ? 'ถูกตรวจสอบเรียบร้อยแล้ว ไม่สามารถเปลี่ยนสถานะได้'
            : `${
                values.unpackedPat.lineList
                  .flat()
                  .filter((lineElement: any) => lineElement.lineType === 'APPROVE')
                  .every((lineElement: any) => lineElement.isApproved === true)
                  ? 'ผ่านการตรวจสอบ'
                  : 'ไม่ผ่านการตรวจสอบ'
              }  ใช่หรือไม่`
        }
      />
    </Form>
  )
}

const EnhancedPatUpload = withFormik({
  mapPropsToValues: () => ({
    isModalOpen: false,
    modeAction: '', // 'upload' or 'approve

    unpackedPat: {
      patId: '',
      name: '',
      description: '',
      versionNo: -1,
      status: '',
      lineList: [],
      requiredList: [],
      headerList: [],
    },
  }),
  validate: (values) => {
    // console.log('THIS IS VALUES ', values)
  },
  // validationSchema: yupPatUpload,

  handleSubmit: (values) => {
    console.log('Dummy Handle Submit for Formik')
  },
})(PatUpload)

export default EnhancedPatUpload

export interface IUnpackPAT {
  patId: string
  name: string
  description: string
  versionNo: number
  status: string
  lineList: any[]
  titleTemplate: ITitleTemplate
  requiredList: boolean[]
  headerList: any[]
  patExport?: Pat_Export
}

export interface IValues {
  modeAction: string
  profile: User
  unpackedPat: {
    patId: string
    name: string
    description: string
    versionNo: number
    status: string
    lineList: any[]
    titleTemplate: ITitleTemplate
    requiredList: boolean[]
    headerList: any[]
    patExport: Pat_Export
  }
}

const PAT_STATUS_MAPPER: any = {
  WAIT_FILL: 'รอการใส่ข้อมูล',
  WAIT_APPROVAL: 'รอการตรวจสอบ',
  ACCEPT: 'ผ่าน',
  REJECT: 'รอการแก้ไข',
}
