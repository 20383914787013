import React from 'react'

import styles from './index.module.scss'

import { FormikInputSelect, IOption } from 'components/commons/input/formikInputSelect'
import { FormikInputText } from 'components/commons/input/formikInputText'
import RadioComponent from 'components/Radio'
import { Pat_Export } from 'utils/generated'

interface Props {
  titleTemplateOption: IOption[]
}

const SectionTitleTemplate: React.FC<Props> = ({ titleTemplateOption }: Props) => {
  return (
    <div className={`contentWrapper ${styles.panelfirstline}`}>
      <div className={styles.panelform}>
        <FormikInputText className={styles.patname} name="formikUnPackPAT.name" placeholder="ฟอร์มไม่มีชื่อ" />
        <FormikInputText
          className={styles.patdescription}
          name="formikUnPackPAT.description"
          placeholder="คำอธิบายแบบฟอร์ม"
        />

        <p className={styles.text}>ใบปะหน้า</p>

        <FormikInputSelect
          className={styles.selectForm}
          name={`formikUnPackPAT.titleTemplate.titleTemplateId`}
          option={[...titleTemplateOption]}
          defaultValue={''}
        />

        <p className={styles.text}>รูปแบบฟอร์มเอกสาร PAT</p>

        <div className={styles.wrap}>
          <RadioComponent name={'formikUnPackPAT.patExport'} label="Long Form" value={Pat_Export.LongForm} />
          <RadioComponent name={'formikUnPackPAT.patExport'} label="Split Page" value={Pat_Export.SplitPage} />
          <RadioComponent name={'formikUnPackPAT.patExport'} label="Long Form (POE)" value={Pat_Export.LongFormPoe} />
        </div>
      </div>
    </div>
  )
}

export default SectionTitleTemplate
