import React, { useEffect } from 'react'

import styles from './index.module.scss'

import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import { getIn, useFormikContext } from 'formik'
import { FormikInputText } from 'components/commons/input/formikInputText'

interface Props {
  indexData: number
  indexCard: number

  data: any
  formikPrefix: string
}

const SectionGuyedUser: React.FC<Props> = ({ indexCard, indexData, data, formikPrefix }: Props) => {
  const { values, setFieldValue } = useFormikContext<any>()

  const dataNumberLegs = getIn(values, `${formikPrefix}[${indexCard}][${indexData}].numberLegs`)

  useEffect(() => {
    if (dataNumberLegs === null) {
      setFieldValue(`${formikPrefix}[${indexCard}][${indexData}].numberLegs`, 'THREE')
    }
  }, [dataNumberLegs])

  const radian2degree = (a: number) => (a * 360) / (2 * Math.PI)

  const calculateDegree = (a: number, b: number, c: number) => {
    if (!a || !b || !c) return 0
    const cosA = (Math.pow(a, 2) + Math.pow(b, 2) - Math.pow(c, 2)) / (2 * a * b)

    return radian2degree(Math.acos(cosA))
  }
  return (
    <div className={styles.subCard}>
      <div className={styles.box}>
        <div className={'inputLineBottom'}>
          <p>{data.text}</p>
        </div>
      </div>

      <FormikInputSelect
        name={`${formikPrefix}[${indexCard}][${indexData}].numberLegs`}
        option={[
          { text: '3', value: 'THREE' },
          { text: '4', value: 'FOUR' },
        ]}
        defaultValue={'THREE'}
        className={'selectInput'}
        disabled={values.modeAction === 'approve'}
      />

      <h4>ระยะ GUY ({dataNumberLegs === 'THREE' ? '3' : '4'} Legs)</h4>

      {dataNumberLegs === 'THREE' ? (
        <div className={styles.wrapperInput}>
          {THREE_LEGS.map((item, index) => (
            <div key={index}>
              <p>{item.text}</p>
              <FormikInputText
                name={`${formikPrefix}[${indexCard}][${indexData}].${item.value}`}
                className={'inputLineBottom'}
                type="number"
                placeholder={item.text}
                readOnly={values.modeAction === 'approve'}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.wrapperInput} aria-checked={dataNumberLegs === 'FOUR'}>
          {FOUR_LEGS.map((item, index) => (
            <div key={index}>
              <p>{item.text}</p>
              <FormikInputText
                name={`${formikPrefix}[${indexCard}][${indexData}].${item.value}`}
                className={'inputLineBottom'}
                type="number"
                placeholder={item.text}
                readOnly={values.modeAction === 'approve'}
              />
            </div>
          ))}
        </div>
      )}

      <h4>มุมระหว่างสมอบก (degree)</h4>
      {dataNumberLegs === 'THREE' ? (
        <div className={styles.wrapperInput} aria-checked={dataNumberLegs === 'FOUR'}>
          <div className={styles.inputDisbles}>{calculateDegree(data?.CTA, data?.CTB, data?.AB).toFixed(2)}</div>
          <div className={styles.inputDisbles}>{calculateDegree(data?.CTB, data?.CTC, data?.BC).toFixed(2)}</div>
          <div className={styles.inputDisbles}>{calculateDegree(data?.CTC, data?.CTA, data?.CA).toFixed(2)}</div>
        </div>
      ) : (
        <div className={styles.wrapperInput} aria-checked={dataNumberLegs === 'FOUR'}>
          <div className={styles.inputDisbles}>{calculateDegree(data?.CTA, data?.CTB, data?.AB).toFixed(2)}</div>
          <div className={styles.inputDisbles}>{calculateDegree(data?.CTB, data?.CTC, data?.BC).toFixed(2)}</div>
          <div className={styles.inputDisbles}>{calculateDegree(data?.CTC, data?.CTD, data?.CD).toFixed(2)}</div>
          <div className={styles.inputDisbles}>{calculateDegree(data?.CTD, data?.CTA, data?.DA).toFixed(2)}</div>
        </div>
      )}

      <div className={styles.bottomGroup}>
        <h4>รวม (degree)</h4>
        <div className={styles.inputDisbles}>
          {dataNumberLegs === 'THREE' ? (
            <>
              {(
                calculateDegree(data?.CTA, data?.CTB, data?.AB) +
                calculateDegree(data?.CTB, data?.CTC, data?.BC) +
                calculateDegree(data?.CTC, data?.CTA, data?.CA)
              ).toFixed(2)}
            </>
          ) : (
            <>
              {(
                calculateDegree(data?.CTA, data?.CTB, data?.AB) +
                calculateDegree(data?.CTB, data?.CTC, data?.BC) +
                calculateDegree(data?.CTC, data?.CTA, data?.CA) +
                calculateDegree(data?.CTD, data?.CTA, data?.DA)
              ).toFixed(2)}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SectionGuyedUser

const THREE_LEGS = [
  { text: 'Ct - A (m)', value: 'CTA' },
  { text: 'Ct - B (m)', value: 'CTB' },
  { text: 'Ct - C (m)', value: 'CTC' },
  { text: 'A - B', value: 'AB' },
  { text: 'B - C', value: 'BC' },
  { text: 'C - A', value: 'CA' },
]

const FOUR_LEGS = [
  { text: 'Ct - A (m)', value: 'CTA' },
  { text: 'Ct - B (m)', value: 'CTB' },
  { text: 'Ct - C (m)', value: 'CTC' },
  { text: 'Ct - D (m)', value: 'CTD' },

  { text: 'A - B', value: 'AB' },
  { text: 'B - C', value: 'BC' },
  { text: 'C - D', value: 'CD' },
  { text: 'D - A', value: 'DA' },
]
