import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from '@mui/material'
import styles from './modal.module.scss'
import AntennaRenderComponent from 'components/AntennaRender'
import { Antenna, Tower_Type, AntennaInput, ColorGroup, Antenna_Type } from 'utils/generated'
import { flattenAntenna, sumType } from '.'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import { ColorPicker } from '@fluentui/react'
import cn from 'classnames'
import binSVG from 'image/binRed.svg'

interface AntennaModalProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  antenna: Antenna | null | undefined
  poleType: Tower_Type
  hasLegs: boolean
  setAntenna: (antenna: AntennaInput) => void
}
const AntennaModal = ({ openModal, setOpenModal, antenna, poleType, hasLegs, setAntenna }: AntennaModalProps) => {
  if (!antenna) return <></>
  const [antennaCopy, setAntennaCopy] = useState<Antenna>(JSON.parse(JSON.stringify(antenna)) as Antenna)
  //   const antennaCopy = useMemo(() => JSON.parse(JSON.stringify(antenna)) as Antenna, [antenna])
  const colorGroup = antenna.colorGroup || []
  const allAntenna = flattenAntenna(antennaCopy)

  //   Change everytime antenna changes
  useEffect(() => {
    setAntennaCopy(JSON.parse(JSON.stringify(antenna)) as Antenna)
  }, [antenna])

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setAntennaCopy(JSON.parse(JSON.stringify(antenna)) as Antenna)
        setOpenModal(false)
      }}
    >
      <div className={styles.antModalContainer}>
        <h3>ตำแหน่งติดตั้ง Antenna</h3>
        <AntennaRenderComponent
          poleType={poleType}
          hasLegs={hasLegs}
          draggable
          antenna={antennaCopy}
          setAntenna={setAntennaCopy}
        />
        <div>
          <h3>Color Group</h3>
          <div className={styles.colorWrapper}>
            {colorGroup.map((cg, cgIndex) => (
              <div key={cgIndex} className={styles.colorGroupWrapper}>
                <div
                  className={styles.colorGrab}
                  style={{ backgroundColor: cg.color }}
                  draggable="true"
                  onDragStart={(e) => e.dataTransfer.setData('Text', `${cg.id}`)}
                ></div>
                <div>{sumType(allAntenna, cg.id)}</div>
              </div>
            ))}
          </div>
        </div>
        <button
          className={styles.saveButton}
          onClick={() => {
            setAntenna(antennaCopy)
            setOpenModal(false)
          }}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default AntennaModal

export interface ColorGroupSubset {
  id: number
  color: string
  antennaType?: Antenna_Type
  dia?: number
  w?: number
  l?: number
  d?: number
}

interface ColorGroupModalProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  colorGroup: ColorGroup | ColorGroupSubset
  setColorGroup: (colorGroup: ColorGroup) => void
  removeColorGroup: () => void
}
export const ColorGroupModal = ({
  openModal,
  setOpenModal,
  colorGroup,
  setColorGroup,
  removeColorGroup,
}: ColorGroupModalProps) => {
  const [colorGroupCopy, setColorGroupCopy] = useState<ColorGroup | ColorGroupSubset>(
    JSON.parse(JSON.stringify(colorGroup)) as ColorGroup,
  )
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false)

  // update values as after effect since useState will not clear once component is mounted
  useEffect(() => {
    // console.log('SET COLOR GROUP COPY ', JSON.parse(JSON.stringify(colorGroup)))
    setColorGroupCopy(JSON.parse(JSON.stringify(colorGroup)) as ColorGroup)
    // console.log('colorGroupCopy.antennaType', colorGroupCopy.antennaType)
  }, [colorGroup, openModal])
  // console.log('colorGroupCopy', colorGroupCopy)

  const options = Object.values(Antenna_Type)
  const onOptionChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    colorGroupCopy.antennaType = event.target.value as Antenna_Type
    setColorGroupCopy({ ...colorGroupCopy })
  }

  const handleClose = () => {
    setColorGroupCopy({ id: -1, color: '#FFF' })
    setOpenColorPicker(false)
    setOpenModal(false)
  }
  const handleRemove = () => {
    removeColorGroup()
    handleClose()
  }

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div className={styles.cgModalContainer}>
        <div className="dp-flex" style={{ justifyContent: 'space-between' }}>
          <h3>Color Group Antenna</h3>
          <button className={cn(styles.deleteButton)} onClick={handleRemove}>
            <img src={binSVG} />
            Remove
          </button>
        </div>
        <div className="dp-flex" style={{ gap: '0.5rem' }}>
          <div
            className={styles.colorBox}
            style={{ backgroundColor: colorGroupCopy.color }}
            onClick={() => setOpenColorPicker(true)}
          />
          <div className={cn(styles.colorPickerModal, { [styles.active]: openColorPicker })}>
            <button onClick={() => setOpenColorPicker(false)}>x</button>
            <ColorPicker
              color={colorGroupCopy.color}
              onChange={(e, colorObj) => {
                colorGroupCopy.color = '#' + colorObj.hex
                setColorGroupCopy({ ...colorGroupCopy })
              }}
              alphaType="none"
            />
          </div>
          <select value={colorGroupCopy.antennaType} onChange={onOptionChangeHandler}>
            <option>Please choose one option</option>
            {options.map((option, index) => {
              return <option key={index}>{option}</option>
            })}
          </select>
        </div>
        {colorGroupCopy.antennaType === Antenna_Type.Parabolic ||
        colorGroupCopy.antennaType === Antenna_Type.ParabolicGrid ? (
          <div className={styles.inputWrapper}>
            <CustomNumberInput
              text="Dia."
              fieldname="dia"
              colorGroup={colorGroupCopy}
              setColorGroup={setColorGroupCopy}
            />
            <div className={styles.label}>
              <span>PI</span>
              <input type="text" className={styles.inputText} value={3.14} disabled />
            </div>
            <div className={styles.label}>
              <span>
                <b>Area</b>
              </span>
              {((3.14 * (colorGroupCopy.dia || 0) ** 2) / 4).toFixed(2)}
            </div>
          </div>
        ) : colorGroupCopy.antennaType ? (
          <div className={styles.inputWrapper}>
            <CustomNumberInput text="W" fieldname="w" colorGroup={colorGroupCopy} setColorGroup={setColorGroupCopy} />
            <CustomNumberInput text="L" fieldname="l" colorGroup={colorGroupCopy} setColorGroup={setColorGroupCopy} />
            <CustomNumberInput text="D" fieldname="d" colorGroup={colorGroupCopy} setColorGroup={setColorGroupCopy} />
            <div className={styles.label}>
              <span>
                <b>Area</b>
              </span>
              {((colorGroupCopy.w || 0) * (colorGroupCopy.l || 0)).toFixed(2)}
            </div>
          </div>
        ) : (
          <></>
        )}
        <button
          className={styles.saveButton}
          onClick={() => {
            setColorGroupCopy({ id: -1, color: '#FFF' })
            setColorGroup(colorGroupCopy as ColorGroup)
            setOpenModal(false)
          }}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
const CustomNumberInput = ({
  text,
  fieldname,
  colorGroup,
  setColorGroup,
}: {
  text: string
  fieldname: keyof ColorGroupSubset
  colorGroup: ColorGroup | ColorGroupSubset
  setColorGroup: (colorGroup: ColorGroup | ColorGroupSubset) => void
}) => (
  <div className={styles.label}>
    <span>{text}</span>
    <input
      type="number"
      className={styles.inputText}
      value={colorGroup[fieldname] || ''}
      onChange={(e) => {
        colorGroup[fieldname] = e.target.value as never
        // console.log('COLORGROUP ', colorGroup)
        setColorGroup({ ...colorGroup })
      }}
      onBlur={() => {
        colorGroup[fieldname] = parseFloat(colorGroup[fieldname] as string) as never
        // console.log('COLORGROUPBLUR ', colorGroup)
        setColorGroup({ ...colorGroup })
      }}
    />
  </div>
)
