import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { useFormikContext, Form, Field, FieldProps } from 'formik'
import { TextField } from '@fluentui/react'
import facebookpic from './facebooklogo.svg'
import googlepic from './googlelogo.svg'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ICombineReducers } from 'state/reducers'
import { CUSTOMER_PREFIX2LABEL } from 'App'
import axios from 'axios'
import { login } from 'adapter/xhr'
import { setLoggedInFalse, setLoggedInTrue, decodeNewToken, setLoggingInTrue } from 'state/reducers/token'
import xcircle from './xcircle.svg'

const Logincomponent: any = ({ setComponentStatus, componentStatus }: any) => {
  const { values, errors, touched, setFieldValue, setFieldError } = useFormikContext<any>()
  const [errorText, setErrorText] = useState('')
  const isLoggedIn = useSelector((state: ICombineReducers) => state.tokenReducer.isLoggedIn)
  const isLoggingIn = useSelector((state: ICombineReducers) => state.tokenReducer.isLoggingIn)
  const role = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken.role)
  const { customerOrTechnician } = useParams()
  const dispatch = useDispatch()

  const loginWithUsernameAndPassword = () => {
    dispatch(setLoggingInTrue())
    login(values.username, values.password)
      .then(({ accessToken, refreshToken }) => {
        if (accessToken) localStorage.setItem('accessToken', accessToken)
        if (refreshToken) localStorage.setItem('refreshToken', refreshToken)
        dispatch(setLoggedInTrue())
        dispatch(decodeNewToken(accessToken, refreshToken))
      })
      .catch((err) => {
        console.log(err.response)
        setFieldError('username', 'Invalid username or password')
        if (err.response.data.error === 'User Not Found') {
          setErrorText('ไม่พบผู้ใช้งานนี้')
        } else if (err.response.data.error === 'Incorrect Password') {
          setErrorText('รหัสผ่านผิด')
        }
        dispatch(setLoggedInFalse())
      })
  }

  return (
    <section className={styles.rightSideContent}>
      <article className={styles.rightSideTopicContainer}>
        <h1 className={styles.rightSideTopic}>
          {CUSTOMER_PREFIX2LABEL[customerOrTechnician as 'customer' | 'technician']}เข้าสู่ระบบ
        </h1>
      </article>
      <Form>
        <article className={styles.rightSideItems}>
          <section className={styles.textFieldGroup}>
            <Field name="username">
              {({ field }: FieldProps) => (
                <TextField {...field} placeholder="หมายเลขโทรศัพท์ / อีเมล์ / ชื่อผู้ใช้งาน" />
              )}
            </Field>
            <Field name="password">
              {({ field }: FieldProps) => (
                <TextField
                  {...field}
                  type="password"
                  placeholder="รหัสผ่าน"
                  canRevealPassword
                  revealPasswordAriaLabel="Show password"
                />
              )}
            </Field>
          </section>
          {errorText !== '' ? (
            <article className={styles.errorLine}>
              <img className={styles.errorImg} src={xcircle} alt="xcircle" />
              <p className={styles.errorText}>{errorText} โปรดลองอีกครั้ง</p>
            </article>
          ) : (
            ''
          )}
          <section className={styles.loginBtnContainer}>
            <button className={styles.loginBtn} onClick={loginWithUsernameAndPassword} type="submit">
              เข้าสู่ระบบ
            </button>

            <section className={styles.linkLine}>
              <button
                type="button"
                onClick={() => {
                  setComponentStatus('forgotpassword')
                }}
                className={styles.linkitems}
              >
                ลืมรหัส
              </button>
              <button
                type="button"
                onClick={() => {
                  setComponentStatus('smslogin')
                }}
                className={styles.linkitems}
              >
                เข้าสู่ระบบด้วย SMS
              </button>
            </section>
          </section>
          <section className={styles.btnContainer}>
            <section className={styles.orLine}>
              <hr />
              or
              <hr />
            </section>
            <article className={styles.idpsBtnGroup}>
              <button className={styles.idpsBtn} type="button">
                <img className={styles.idpsLogo} src={facebookpic} alt="facebookpic" />
                Facebook
              </button>
              <button className={styles.idpsBtn} type="button">
                <img className={styles.idpsLogo} src={googlepic} alt="googlepic" />
                Google
              </button>
            </article>
            <article className={styles.registerline}>
              <p className={styles.registertext}>เพิ่งเคยเข้ามาใน ChangTum ใช่หรือไม่</p>
              <Link to="/" className={styles.registerBtn}>
                สมัครใหม่
              </Link>
            </article>
          </section>
        </article>
      </Form>
      {isLoggingIn && (
        <div className="loaderWrapper">
          <div className="loader" />
        </div>
      )}
    </section>
  )
}

export default Logincomponent
