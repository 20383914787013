import React, { FC, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styles from './index.module.scss'

interface Props {
  text: string
  children: JSX.Element | JSX.Element[]
}

const Tooltip: FC<Props> = (props) => {
  const [show, setShow] = useState<boolean>(false)
  const [text, setText] = useState<string>(props.text)
  const ref = useRef<HTMLDivElement | null>(null)

  const [coord, setCoord] = useState({ x: 0, y: 0 })

  const onClose = () => {
    setTimeout(() => {
      setShow(false)
    }, 0)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(props.text)
    setText(`คัดลอก ${props.text} แล้ว`)
    setTimeout(() => {
      setText(props.text)
    }, 1000)
  }

  return (
    <div
      className={styles.tooltipContainer}
      ref={ref}
      onMouseEnter={() => {
        const el = ref.current?.getBoundingClientRect()
        // console.log(
        //   'pankorn',
        //   [posX, posY],
        //   [e.clientX, e.clientY],
        //   [ref.current?.getBoundingClientRect().top, ref.current?.getBoundingClientRect().left],
        // )
        setCoord({
          x: el?.left || 0,
          y: el?.top || 0,
        })

        setShow(true)
      }}
      onMouseLeave={onClose}
      onDoubleClick={handleCopy}
    >
      {show &&
        createPortal(
          <div
            className={styles.tooltipPopup}
            style={{
              position: 'fixed',
              top: coord.y - 28,
              left: coord.x,
              zIndex: 9999999,
            }}
          >
            {text}
          </div>,
          document.getElementById('portal')!,
        )}
      {props.children}
    </div>
  )
}

export default Tooltip
