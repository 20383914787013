import { THAILAND_REGION2PROVINCE_MAPPER, THAILAND_REGION_MAPPER } from 'components/commons/thailandFact'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
const colName = (n: number): string => {
  const ordA = 'A'.charCodeAt(0)
  const ordZ = 'Z'.charCodeAt(0)
  const len = ordZ - ordA + 1

  let s = ''
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s
    n = Math.floor(n / len) - 1
  }
  return s
}

const templateCSV = async () => {
  const workbook = new ExcelJS.Workbook()

  const sheet = workbook.addWorksheet('teams')

  sheet.properties.defaultRowHeight = 30

  const columns = [
    'ลำดับ',
    'ชื่อ',
    'นามสกุล',
    'วันเกิด',
    'ชื่อเล่น',
    'เพศ',
    'สถานภาพ',
    'เลขบัตรประชาชน',
    'เบอร์ติดต่อ',
    'ที่อยู่ตามบัตรประชาชน',
    'เมลล์',
    {
      column: 'ผู้ติดต่อ กรณีฉุกเฉิน',
      subcolumns: ['ชื่อ', 'นามสกุล', 'เบอร์โทร', 'ความสัมพันธ์'],
    },
    'ประเภทงานช่าง',
    'ภูมิภาคที่ให้บริการ',
    'จังหวัดที่ให้บริการ',
    'บัตรประชาชน',
    'รูปคู่กับบัตรประชาชน',
    {
      column: 'Safety Passport',
      subcolumns: ['AIS_C', 'AIS_E', 'AIS_EE', 'AIS_H', 'AIS_G'],
    },
  ]

  // create Column
  let columnCountFlag = 0
  columns.forEach((column, index) => {
    let row: any
    if (typeof column === 'string') {
      row = sheet.getCell(`${colName(index + columnCountFlag)}1`)
      row.value = column
      sheet.mergeCells(`${colName(index + columnCountFlag)}1:${colName(index + columnCountFlag)}2`)
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF8206',
        },
      }
      row.style.alignment = { horizontal: 'center', vertical: 'middle' }
      row.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
    } else {
      row = sheet.getCell(`${colName(index + columnCountFlag)}1`)
      sheet.mergeCells(
        `${colName(index + columnCountFlag)}1: ${colName(index + columnCountFlag + column.subcolumns.length - 1)}1`,
      )
      row.value = column.column
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF8206',
        },
      }
      row.style.alignment = { horizontal: 'center', vertical: 'middle' }
      row.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      column.subcolumns.forEach((sub, sindex) => {
        row = sheet.getCell(`${colName(index + sindex + columnCountFlag)}2`)
        row.value = sub
        row.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FF8206',
          },
        }
        row.style.alignment = { horizontal: 'center', vertical: 'middle' }
        row.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
      })

      columnCountFlag += column.subcolumns.length - 1
    }
  })

  sheet.getRow(3).values = [
    1,
    'นายสมชาย',
    'ใจดี',
    '1988-03-12',
    'สมชาย',
    'ชาย',
    'โสด',
    '1234567890123',
    '0123456789',
    '74 หมู่ 3 ถ.เสรีไทยแขวงคลองจั่น เขตบางกะปิ จ.กรุงเทพฯ 10240',
    'sc@gmail.com',
    'นางสมหญิง',
    'ใจดี',
    '0123456777',
    'ญาติ',
    'ช่างไฟ, ช่างเหล็ก',
    'ภาคกลาง, ภาคใค้',
    'เพชรบูรณ์, กระบี่',
    'https://example.com',
    'https://example.com',
    ' ',
    'https://example.com',
  ]

  const reference = workbook.addWorksheet('Reference')

  const topicStyle: any = {
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'FF8206',
      },
    },
  }

  reference.properties.defaultRowHeight = 30
  reference.properties.defaultColWidth = 20

  const createRegion = () => {
    const A1 = reference.getCell('A1')
    A1.style = topicStyle
    A1.value = 'ภูมิภาคและจังหวัด'

    reference.mergeCells('A1:B1')

    let rowFlag = 2
    Object.entries(THAILAND_REGION_MAPPER).forEach(([regionKey, region]) => {
      reference.getCell(`A${rowFlag}`).value = region as string
      reference.getCell(`A${rowFlag}`).alignment = {
        vertical: 'top',
      }

      const provices = THAILAND_REGION2PROVINCE_MAPPER[regionKey]
      reference.mergeCells(`A${rowFlag}:A${rowFlag + provices.length - 1}`)
      provices.forEach((provice: string) => {
        reference.getCell(`B${rowFlag}`).value = provice

        rowFlag++
      })
    })
  }

  const C1 = reference.getCell('C1')
  C1.style = topicStyle
  reference.getColumn('C').values = [
    'ประเภทช่าง',
    'ช่างไฟฟ้า',
    'ช่างสี',
    'ช่างประปา',
    'ช่างปูน',
    'ช่างเหล็ก',
    'ช่างไม้',
    'ช่างยนต์',
    'ช่างติดตั้ง',
  ]

  const D1 = reference.getCell('D1')
  D1.style = topicStyle
  reference.getColumn('D').values = ['เพศ', 'ชาย', 'หญิง', 'เพศทางเลือก']

  const E1 = reference.getCell('E1')
  E1.style = topicStyle
  reference.getColumn('E').values = ['สถานะ', 'โสด', 'สมรส', 'หย่า']

  createRegion()

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const fileName = 'Team_Member_Template_'
  const buffer = await workbook.xlsx.writeBuffer()

  const excelToSave = new Blob([buffer], { type: fileType })

  saveAs(excelToSave, fileName + fileExtension)
}

export default templateCSV
