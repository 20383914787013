import React, { useEffect, useMemo } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { getIn, useFormikContext } from 'formik'

import styles from './index.module.scss'
import { ThemeMenu } from './config'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import { FormikInputText } from 'components/commons/input/formikInputText'
import FormikInputEquation from 'components/commons/input/formikInputEquation'
import { EPropertyType } from 'utils/common'
import Tree from 'components/Tree'
import CheckboxComponent from 'components/Checkbox'
// import Autocomplete from 'components/Autocomplete'

import videoPNG from 'image/pat/video.png'
import imgPNG from 'image/pat/images.png'
import pdfPNG from 'image/pat/pdf.png'
import textPNG from 'image/pat/title.png'
import soundPNG from 'image/pat/sound.png'
import radioPNG from 'image/pat/radio.png'
import measurementPNG from 'image/pat/measurement.png'
import antennaPNG from 'image/pat/antena.png'
import unitPNG from 'image/pat/unit.png'
import dropdownPNG from 'image/pat/dropdown.png'
import guyedPNG from 'image/pat/guyed.png'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import addSVG from 'image/plusCircleGreen.svg'
import closeSVG from 'image/closeCircleRed.svg'
import checkListPNG from 'image/pat/checkList.png'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Tower_Type, Upload_Type, UserVariable } from 'utils/generated'
import { getUserVariable } from 'utils/equationUtil'

interface Props {
  name: string
  patLineIndex: number
  patsublineindex: number
}

const SectionTypeUploadPat: React.FC<Props> = ({ name, patsublineindex, patLineIndex }: Props) => {
  const { values, errors, setFieldValue } = useFormikContext<any>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const uploadTypeValue = useMemo(() => {
    return getIn(values, `${name}.uploadType`)
  }, [name, values])

  const dataRowList = getIn(values, `${name}.rowList`)
  const data = getIn(values, `${name}`)

  // User Variable in Checklist
  const dataUserVariables = getIn(values, `${name}.userVariable`) as UserVariable[] | undefined
  // Check if Max/Min Tolerance has new User Variables
  const maxTolerance = getIn(values, `${name}.maxTolerance`) as string | undefined
  const maxToleranceError = getIn(errors, `${name}.maxTolerance`)
  const minTolerance = getIn(values, `${name}.minTolerance`) as string | undefined
  const minToleranceError = getIn(errors, `${name}.minTolerance`)
  useEffect(() => {
    // console.log('MAX ERROR AND MIN ', !maxToleranceError, !minToleranceError)
    // if (maxTolerance || minTolerance) {
    const userVarMatches: UserVariable[] = []
    // Get only names from old uservariables
    const varname2Name: Record<string, string> =
      dataUserVariables?.reduce<Record<string, string>>((prev, curr) => {
        prev[curr.varname] = curr.name
        return prev
      }, {}) || {}

    if (maxTolerance) {
      // console.log('matchall', maxTolerance.match(/@(\w+)/g))
      const matches = getUserVariable(maxTolerance).map((varname) => ({
        varname: `@${varname}`,
        name: varname in varname2Name ? varname2Name[varname] : varname,
      }))
      matches.forEach((m) => {
        if (!userVarMatches.some((e) => e.varname === m.varname)) userVarMatches.push(m)
      })
    }
    if (minTolerance) {
      const matches = getUserVariable(minTolerance).map((varname) => ({
        varname: `@${varname}`,
        name: varname in varname2Name ? varname2Name[varname] : varname,
      }))
      matches.forEach((m) => {
        if (!userVarMatches.some((e) => e.varname === m.varname)) userVarMatches.push(m)
      })
      // }
      // Remove Dupes
    }
    setFieldValue(`${name}.userVariable`, userVarMatches)
  }, [maxTolerance, minTolerance, maxToleranceError, minToleranceError])

  // const handleAddUserVarible = () => {
  //   const cloneUserVariable = [...getIn(values, `${name}.userVariable`), '']
  //   setFieldValue(`${name}.userVariable`, cloneUserVariable)
  // }

  // const deleteUserVarible = (idx: number) => {
  //   if (idx === 0) return
  //   data?.userVariable.splice(idx, 1)
  //   setFieldValue(`${name}.userVariable`, data?.userVariable)
  // }

  const RenderForm = () => {
    switch (uploadTypeValue) {
      case Upload_Type.Measure:
        return (
          <div className={styles.wrapperData}>
            <CheckboxComponent
              name={`${name}.checkTolerance`}
              value={''}
              checked={data?.checkTolerance === true}
              label="ระบุค่ามาตรฐาน"
            />

            {data?.checkTolerance && (
              <div className={styles.box}>
                <p className={styles.text}>ระบุค่ามาตรฐานที่ยอมรับได้</p>
                <div className={styles.wrapperInput}>
                  <FormikInputEquation
                    className={`inputLineBottom ${styles.panelcheckboxconfigtopic}`}
                    name={`${name}.maxTolerance`}
                    placeholder="Max"
                  />
                  <p>Max</p>
                </div>
                <div className={styles.wrapperInput}>
                  <FormikInputEquation
                    className={`inputLineBottom ${styles.panelcheckboxconfigtopic}`}
                    name={`${name}.minTolerance`}
                    placeholder="Min"
                  />
                  <p>Min</p>
                </div>
                <p className={styles.text}>กำหนดตัวแปร</p>

                {dataUserVariables &&
                  dataUserVariables.map((item, idx: number) => (
                    <div className={styles.wrapperRow} key={idx}>
                      <FormikInputText
                        className={`inputLineBottom ${styles.panelcheckboxconfigtopic}`}
                        name={`${name}.userVariable[${idx}].name`}
                        placeholder="ชื่อตัวแปร"
                      />
                      <FormikInputSelect
                        className={styles.selectInput}
                        disabled
                        name={`${name}.userVariable[${idx}].varname`}
                        option={[{ text: item.varname, value: item.varname }]}
                        defaultValue={4096}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        )

      case Upload_Type.Antenna:
        return (
          <div className={styles.boxGrid}>
            <p className={styles.text}>ประเภทเสา</p>
            <FormikInputSelect
              name={`${name}.poleType`}
              option={Object.values(Tower_Type).map((tt) => ({ value: tt, text: TowerTypeText[tt] }))}
              defaultValue={''}
              className={styles.selectInput}
            />
          </div>
        )
      case Upload_Type.Guyedlegs:
        return (
          <div className={styles.wrapperAreaText}>
            <p>ช่างเลือกจำนวนขาของเสา Guyed Legs</p>
          </div>
        )
      case Upload_Type.File:
        return (
          <div className={styles.wrap}>
            <p>ระบุหน่วย</p>
            <FormikInputText
              className={`inputLineBottom ${styles.panelcheckboxconfigtopic}`}
              name={`${name}.text`}
              placeholder="ข้อความ"
            />
          </div>
        )
      case Upload_Type.Checklist:
        return <></>
      case Upload_Type.Text:
        return <></>
      default:
        return (
          <div className={styles.boxGrid}>
            <p className={styles.text}>สถานะไฟล์</p>
            <section className={styles.wrapperSelect}>
              <p>ขนาดไฟล์สูงสุด</p>
              <FormikInputSelect
                name={`${name}.maximumFileSize`}
                option={uploadTypeOption[uploadTypeValue]}
                defaultValue={4096}
                className={styles.selectInput}
              />
            </section>
          </div>
        )
    }
  }

  // useEffect(() => {
  //   console.log('THIS IS dataRowList', dataRowList)
  // }, [dataRowList])

  //Set Tree With LevelRow
  useEffect(() => {
    if (data?.levelRow === '1') {
      const cloneRowList = data.rowList.map((item: any) => ({
        ...item,
        children: null,
      }))
      setFieldValue(`${name}.rowList`, cloneRowList)
    } else if (data?.levelRow === '2') {
      const cloneRowList = data.rowList.map((item: any) => ({
        ...item,
        children: item?.children
          ? [
              ...item.children.map((data: any) => ({
                ...data,
                children: null,
              })),
            ]
          : [{ name: '', property: null }],
      }))
      setFieldValue(`${name}.rowList`, cloneRowList)
    } else if (data?.levelRow === '3') {
      const cloneRowList = data.rowList.map((item: any) => ({
        ...item,
        children:
          item?.children?.length > 0
            ? [
                ...item.children.map((data: any) => ({
                  ...data,
                  children:
                    data?.children?.length > 0
                      ? data?.children.map((child: any) => ({ ...child }))
                      : [{ name: '', property: null }],
                })),
              ]
            : [{ name: '', property: null }],
      }))

      setFieldValue(`${name}.rowList`, cloneRowList)
    }
  }, [data.levelRow])

  const RenderisNeedBeforeAfter = () => {
    switch (uploadTypeValue) {
      case Upload_Type.Video:
      case Upload_Type.Image:
      case Upload_Type.Sound:
      case Upload_Type.Text:
      case Upload_Type.Pdf:
      case Upload_Type.File:
        return (
          <div className={styles.box}>
            <p className={styles.text}>สถานะ</p>
            <div className={styles.wrapperCheck}>
              <CheckboxComponent
                name={`${name}.isNeedBefore`}
                label="ก่อนทำ"
                value={getIn(values, `${name}.isNeedBefore`)}
                checked={getIn(values, `${name}.isNeedBefore`) === true}
              />
              <CheckboxComponent
                name={`${name}.isNeedAfter`}
                label="หลังทำ"
                value={getIn(values, `${name}.isNeedAfter`)}
                checked={getIn(values, `${name}.isNeedAfter`) === true}
              />
            </div>
          </div>
        )
      default:
        return <></>
    }
  }

  //ADD CHECKLIST

  const handleAddRowCheckList = () => {
    const rowList = data?.rowList
    const numberLevel = data?.levelRow

    const emtypRow1 = {
      name: '',
      property: null,
    }

    const emtypRow2 = {
      name: '',
      property: null,
      children: [{ name: '', property: null }],
    }

    const emtypRow3 = {
      name: '',
      property: null,
      children: [{ name: '', property: null, children: [{ name: '', property: null }] }],
    }
    if (parseInt(numberLevel) === 1) {
      const cloneRowList = [...rowList, emtypRow1]
      setFieldValue(`${name}.rowList`, cloneRowList)
    } else if (parseInt(numberLevel) === 2) {
      const cloneRowList = [...rowList, emtypRow2]
      setFieldValue(`${name}.rowList`, cloneRowList)
    } else if (parseInt(numberLevel) === 3) {
      const cloneRowList = [...rowList, emtypRow3]
      setFieldValue(`${name}.rowList`, cloneRowList)
    }
  }

  const handleAddRowColumn = () => {
    const cloneRowList = [...getIn(values, `${name}.columnList`), '']
    setFieldValue(`${name}.columnList`, cloneRowList)
  }

  const deleteRowColumn = (idx: number) => {
    if (idx === 0) return
    data?.columnList.splice(idx, 1)
    setFieldValue(`${name}.columnList`, data?.columnList)
  }
  return (
    <div className={styles.container}>
      <FormikInputText
        className={`inputLineBottom ${styles.panelcheckboxconfigtopic}`}
        name={`${name}.text`}
        placeholder="เพิ่มตัวเลือก 1 หรือ เพิ่มอื่นๆ"
      />

      {RenderisNeedBeforeAfter()}

      {uploadTypeValue === Upload_Type.Antenna && (
        <CheckboxComponent
          name={`${name}.isDetail`}
          label="Detail Report"
          value={getIn(values, `${name}.isDetail`)}
          checked={getIn(values, `${name}.isDetail`) === true}
        />
      )}

      <div className={styles.wrapperBox}>
        <div className={styles.boxGrid}>
          <p className={styles.text}>นำเข้าข้อมูล</p>
          <button className={styles.wrapperBt} type="button" onClick={(e) => handleClick(e)}>
            <div className={styles.group}>
              <img src={UploadTypeImg[uploadTypeValue]} alt="Upload" />
              <p>{UploadTypeText[uploadTypeValue]}</p>
            </div>
            <ExpandMoreRoundedIcon />
          </button>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={ThemeMenu}>
            {menuList.map((item, idx: number) => (
              <MenuItem
                key={idx}
                className={styles.wrapperMenu}
                disabled={item.typ === 'NONE'}
                onClick={() => {
                  setFieldValue(`${name}.uploadType`, item.typ)
                  handleClose()
                }}
              >
                <img src={item.img} alt="" />
                <p>{item.text}</p>
              </MenuItem>
            ))}
          </Menu>
        </div>

        {uploadTypeValue === Upload_Type.Checklist ? (
          <></>
        ) : uploadTypeValue === Upload_Type.Text ? (
          <></>
        ) : (
          <div className={styles.line} />
        )}

        {RenderForm()}
      </div>

      {uploadTypeValue === Upload_Type.Checklist && (
        <div className={styles.wrapperData}>
          <FormikInputEquation
            className={`inputLineBottom ${styles.panelcheckboxconfigtopic}`}
            name={`${name}.equation`}
            placeholder="Equation"
          />
          <div>
            <section className={styles.wrapperSelect}>
              <h4>Remark Property</h4>
              <FormikInputSelect
                name={`${name}.remarkProperty`}
                option={[
                  { text: 'Select Property', value: '' },
                  { text: 'Check', value: EPropertyType.Check },
                  { text: 'Number', value: EPropertyType.Number },
                  { text: 'Dropdown1', value: EPropertyType.Dropdown1 },
                  { text: 'Dropdown2', value: EPropertyType.Dropdown2 },
                  { text: 'Dropdown3', value: EPropertyType.Dropdown3 },
                  { text: 'Text', value: EPropertyType.Text },
                ]}
                defaultValue={''}
                className={styles.selectInput}
              />
            </section>
          </div>

          <div className={styles.main}>
            <div className={styles.wrapBox}>
              <div className={styles.header}>
                <div className={styles.wrapLeft}>
                  <section className={styles.wrapperSelect}>
                    <h4>Row Level</h4>
                    <FormikInputSelect
                      name={`${name}.levelRow`}
                      option={[
                        { text: '1', value: 1 },
                        { text: '2', value: 2 },
                        { text: '3', value: 3 },
                      ]}
                      defaultValue={''}
                      className={styles.selectInput}
                    />
                  </section>

                  <section className={styles.wrapperSelect}>
                    <h4>Property</h4>
                    <FormikInputSelect
                      name={`${name}.defaultProperty`}
                      option={[
                        { text: 'Check', value: EPropertyType.Check },
                        { text: 'Number', value: EPropertyType.Number },
                        { text: 'Dropdown1', value: EPropertyType.Dropdown1 },
                        { text: 'Dropdown2', value: EPropertyType.Dropdown2 },
                        { text: 'Dropdown3', value: EPropertyType.Dropdown3 },
                        { text: 'Text', value: EPropertyType.Text },
                      ]}
                      defaultValue={''}
                      className={styles.selectInput}
                    />
                  </section>
                </div>

                <button
                  className=" wrapperButtonSmall"
                  type="button"
                  onClick={handleAddRowCheckList}
                  style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                >
                  <AddCircleRoundedIcon fontSize="small" />
                  <p>เพิ่มแถวใหม่</p>
                </button>
              </div>

              {dataRowList && (
                <Tree
                  data={dataRowList}
                  name={`${name}.rowList`}
                  valuesData={getIn(values, `${name}`)}
                  subIndex={patsublineindex}
                  lineIndex={patLineIndex}
                />
              )}
            </div>

            <div className={styles.wrapBox}>
              <div className={styles.flexBox}>
                <button
                  className=" wrapperButtonSmall"
                  type="button"
                  onClick={handleAddRowColumn}
                  style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                >
                  <AddCircleRoundedIcon fontSize="small" />
                  <p>เพิ่มแถวใหม่</p>
                </button>
              </div>

              <h4>Column</h4>
              {data?.columnList &&
                data?.columnList.map((item: string, idx: number) => (
                  <div className={styles.boxInput} key={idx}>
                    <FormikInputText
                      className={`inputLineBottom ${styles.panelcheckboxconfigtopic}`}
                      name={`${name}.columnList[${idx}]`}
                      placeholder="Column Name"
                    />

                    <div onClick={() => deleteRowColumn(idx)} className={styles.icon}>
                      <CloseRoundedIcon />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {uploadTypeValue === Upload_Type.Measure && (
        <div className={styles.boxBottom}>
          <p className={styles.text}>Column</p>

          {data?.columnList &&
            data?.columnList.map((item: string, idx: number) => (
              <div className={styles.boxInput} key={idx}>
                <FormikInputText
                  className={`inputLineBottom ${styles.panelcheckboxconfigtopic}`}
                  name={`${name}.columnList[${idx}]`}
                  placeholder="Column Name"
                />

                <div className={styles.groupIcon}>
                  <img src={closeSVG} alt="" onClick={() => deleteRowColumn(idx)} />
                  <img src={addSVG} alt="" onClick={handleAddRowColumn} />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default SectionTypeUploadPat

const uploadTypeOption: any = {
  VIDEO: [
    { value: 5120, text: '5 MB' },
    { value: 10240, text: '10 MB' },
    { value: 20480, text: '20 MB' },
  ],
  IMAGE: [
    { value: 1024, text: '1 MB' },
    { value: 2048, text: '2 MB' },
    { value: 4096, text: '4 MB' },
  ],
  PDF: [
    { value: 1024, text: '1 MB' },
    { value: 2048, text: '2 MB' },
    { value: 4096, text: '4 MB' },
  ],
  TEXT: [
    { value: 1024, text: '1 MB' },
    { value: 2048, text: '2 MB' },
    { value: 4096, text: '4 MB' },
  ],
  SOUND: [
    { value: 5120, text: '5 MB' },
    { value: 10240, text: '10 MB' },
    { value: 20480, text: '20 MB' },
  ],
  FILE: [
    { value: 1024, text: '1 MB' },
    { value: 2048, text: '2 MB' },
    { value: 4096, text: '4 MB' },
  ],
}

const menuList = [
  { text: 'วิดีโอ', img: videoPNG, typ: Upload_Type.Video },
  { text: 'รูปภาพ', img: imgPNG, typ: Upload_Type.Image },
  { text: 'PDF', img: pdfPNG, typ: Upload_Type.Pdf },
  { text: 'Text', img: textPNG, typ: Upload_Type.Text },
  { text: 'เสียง', img: soundPNG, typ: Upload_Type.Sound },
  { text: 'ระบุหน่วย', img: unitPNG, typ: Upload_Type.File },
  { text: 'Check List', img: checkListPNG, typ: Upload_Type.Checklist },
  { text: 'Guyed Legs', img: guyedPNG, typ: Upload_Type.Guyedlegs },
  { text: 'การวัดค่า', img: measurementPNG, typ: Upload_Type.Measure },
  { text: 'Antenna', img: antennaPNG, typ: Upload_Type.Antenna },
  { text: 'Dropdown', img: dropdownPNG, typ: 'NONE' },
  { text: 'Radio', img: radioPNG, typ: 'NONE' },
]

const UploadTypeText: Record<string, string> = {
  VIDEO: 'วิดิโอ',
  IMAGE: 'รูปภาพ',
  PDF: 'PDF',
  TEXT: 'Text',
  SOUND: 'เสียง',
  FILE: 'ระบุหน่วย',
  MEASURE: 'การวัดค่า',
  ANTENNA: 'Antenna',
  GUYEDLEGS: 'Guyed Legs',
  CHECKLIST: 'Check List',
}

const TowerTypeText: Record<string, string> = {
  [Tower_Type.Concretepole]: 'Concrete Pole',
  [Tower_Type.Guyed]: 'Guyed',
  [Tower_Type.Monopole]: 'Monopole',
  [Tower_Type.Selfsupport]: 'Self Support',
  [Tower_Type.Steelpipe]: 'Steel Pipe',
}

const UploadTypeImg: Record<string, string> = {
  VIDEO: videoPNG,
  IMAGE: imgPNG,
  PDF: pdfPNG,
  TEXT: textPNG,
  SOUND: soundPNG,
  FILE: unitPNG,
  MEASURE: measurementPNG,
  ANTENNA: antennaPNG,
  GUYEDLEGS: guyedPNG,
  CHECKLIST: checkListPNG,
}
