import React from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { TextField } from '@fluentui/react'
import arrowpic from '../arrow.svg'

const Fillforgotpasswordcomponent: React.FC<any> = ({ setComponentStatus, setforgotpasswordComponentStatus }: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <section className={styles.rightSideContent}>
      <img
        onClick={() => {
          setComponentStatus('login')
        }}
        className={`arrowPic`}
        src={arrowpic}
        alt="arrowpic"
      />
      <p className={styles.forgotPasswordTopic}>ตั้งรหัสผ่านใหม่</p>
      <Form>
        <section className={styles.textFieldLine}>
          <Field name="emailorphonenumber">
            {({ field }: FieldProps) => <TextField type="text" {...field} placeholder=" หมายเลขโทรศัพท์ / อีเมล / " />}
          </Field>
        </section>
      </Form>
      <button
        onClick={() => {
          values.emailorphonenumber !== '' ? setforgotpasswordComponentStatus('verify') : null
        }}
        type="button"
        className={`orangeBtn`}
      >
        ต่อไป
      </button>
    </section>
  )
}

export default Fillforgotpasswordcomponent
