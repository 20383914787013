import React from 'react'
import { Field, useFormikContext } from 'formik'
// import { any } from 'prop-types'

const FormikInputCustomizeType: any = (inputdat: any): JSX.Element => {
  const { touched, errors } = useFormikContext<any>()
  return (
    <Field
      className={inputdat.className}
      type={inputdat.type}
      name={inputdat.name}
      id={inputdat.name}
      placeholder={inputdat.placeholder}
      readOnly={inputdat.readonly}
      disabled={inputdat.disabled}
      min={inputdat.min}
      validate={inputdat.validate}
      style={{
        ...(touched?.[inputdat.name] && errors?.[inputdat.name] && { border: '1px solid red' }),
      }}
      required={true}
    />
  )
}
export default FormikInputCustomizeType

interface IInputcustomize {
  name: string
  className: any
  placeholder: string
  type: string
}
