import Axios from 'axios'
// import axiosRetry from 'axios-retry'
import {
  queryProjectByUserId,
  queryProjectByProjectId,
  queryProjectByAcceptedUserId,
  queryPatByPatId,
  queryJobByChangTumId,
  queryNotificationByUserId,
  queryCreatedByUserIDFromProjectByProjectId,
  queryGetUser,
  queryListPosts,
  queryGetPost,
  queryPostByUserId,
  queryListJobByUserID,
  querypostByProjectID,
  queryGetTeam,
  queryGetPostfilter,
  queryProfileTeam,
  querygetUserProfile,
  queryMasterPlan,
  queryGetUserInTeam,
  queryPlanDateReport,
  queryInstalledDateReport,
  queryListSafetyPassport,
  queryRevision,
  queryListSafetyPassportByUserInTeam,
  queryListReportStatusPAT,
  queryTechnician,
  queryMasterPlanExportInno,
  queryProjectPC13,
  queryPlanExportInno,
  queryInstalledExportInno,
} from './query'

import {
  mutationPutProjectInput,
  mutationDeleteJobInput,
  mutationPutPATInput,
  mutationCreatePATInput,
  mutationPutJobsInput,
  mutationRedoPATInput,
  mutationCreateProjectInput,
  mutationCreateNotification,
  mutationPutNotification,
  mutationCreatePost,
  mutationCreateJobApplication,
  mutationUpdateJuristic,
  mutationApproveJobApplication,
  mutationUpdateuserProfile,
  mutationCreateUserVerification,
  mutationPutMasterPlan,
  MutationCreateTeamlist,
  MutationUpdateUserInTeam,
  MutationCreateIssue,
  MutationCreateVerifyInTeam,
  MutationDelUserInTeam,
  MutationCreateSafteyUser,
  MutationCreateSafteyTeam,
  MutationChangePassword,
  MutationcreateAcceptedUserInProject,
  MutationDeleteAcceptedUserInProject,
  mutationRefreshPATStatus,
  mutationRefreshPATNC,
} from './mutation'

import { setLoggedInFalse, decodeNewToken } from 'state/reducers/token'
import jwt_decode from 'jwt-decode'
import store from 'state/store'
import history from 'historyCommon'
import { Job_Status, Pat_Export, Tower_Type, User } from 'utils/generated'

const STAGE: 'dev' | 'prod' = 'prod'

console.log(STAGE, 'dev?prod')
//  This actually should be nested in the string when the API GATE WAY is configured correctly.

// API [ DEVTOP ENV ]
// export const BASE_URL = `https://g9ckhzjrck.execute-api.ap-southeast-1.amazonaws.com/devtop/api`
// export const GRAPHQL_URL = `https://g9ckhzjrck.execute-api.ap-southeast-1.amazonaws.com/devtop/api/graphql`

// API [ DEV ENV ]
// export const BASE_URL = `https://swgf98h43f.execute-api.ap-southeast-1.amazonaws.com/dev/api`
// export const GRAPHQL_URL = `https://g9ckhzjrck.execute-api.ap-southeast-1.amazonaws.com/devtop/api/graphql`

// API [ PROD ENV ]
export const BASE_URL = `https://jum716bkef.execute-api.ap-southeast-1.amazonaws.com/${STAGE}/api`
export const GRAPHQL_URL = `https://jum716bkef.execute-api.ap-southeast-1.amazonaws.com/${STAGE}/api/graphql`

function returnAxiosInstance() {
  const axiosInstance = Axios.create({
    baseURL: GRAPHQL_URL,
    timeout: 1000 * 30, // default is `0` (no timeout) 30 seconds 1000*30
  })

  // Setting headers for authorization. You can do this in instances or interceptors
  // https://stackoverflow.com/questions/45578844/how-to-set-header-and-options-in-axios
  // eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJhZG1pbiIsInVzZXJuYW1lIjoiYWRtaW4iLCJ0eXBlIjoiYWNjZXNzIiwicm9sZVR5cGUiOiJBRE1JTiIsInBhcnR5SUQiOiJMSU5LTE9PSyIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTY0NTA0MDA5OSwiZXhwIjoxOTQ1MDQwOTk5fQ.oFu7fpLfZTr7fz0VL5kKSho1oQp_N1FCfIO4RJjHbKM
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    // Set for all requests (common) , or should we just do for post?
    axiosInstance.defaults.headers.common['Authorization'] = accessToken
  }

  // Hacked 3 year Expire Access Token : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJBRE1JTjAxIiwiY3VzdG9tQXR0cnMiOnsicm9sZSI6ImFkbWluIn0sInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE2MjgwMTUwOTQsImV4cCI6MTcyODAxNTM5NH0.SPxtOAbFcDqFkC9G2_9rLUvFfp5QoxqufuzkL8QQc-s
  // Working Token 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJBRE1JTjAxIiwiY3VzdG9tQXR0cnMiOnsicm9sZSI6ImFkbWluIn0sInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE2MjgwMTUwOTQsImV4cCI6MTcyODAxNTM5NH0.SPxtOAbFcDqFkC9G2_9rLUvFfp5QoxqufuzkL8QQc-s',
  //error Access Denied : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJrcnZweDB3eWprOHh5IiwiY3VzdG9tQXR0cnMiOnsicm9sZSI6ImFkbWluIn0sInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE2MjgxMzgyNjIsImV4cCI6MTYyODEzODU2Mn0.rorTnP1f3zgIMllPGHM-lkTJ-ugIHOzTpB7UiwjA6fk
  // NTC access Denied : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJrcnc4M2R2c2txSEF3IiwidXNlcm5hbWUiOiJudGNBZG1pbiIsInJvbGUiOiJ0ZWNobmljaWFuIiwidHlwZSI6ImFjY2VzcyIsImlhdCI6MTYyODc0Nzk4NywiZXhwIjoxNjI4NzQ4Mjg3fQ.Tzepb1TtHDq5Dx5k5tt8mTQXIeGAQi49DPwtjx68Oms

  // Add a response intercepter
  axiosInstance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      // console.log('INTERECEPTIOER response', response)
      // console.log('STORE STTATE', store.getState())
      // history.push('/asdpopk')
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // There are 3 cases it can fall to this error response
      // 1. accessToken Expires in between page refresh, refreshToken not expire -> need to refreshToken, and shoot again
      // 2. accessToken Expires and refreshToken expire -> unfortunately, just handleFailure
      // 2. accessToken is compromised and the signature is not legit -> need to clear tokens

      // console.log('INTERECEPTIOER ERROR error', error)
      // console.log('INTERECEPTIOER ERROR error.config', error.config)
      // console.log('INTERECEPTIOER ERROR error.response', error.response)
      if (!error.response) {
        Promise.reject(error) // Hotfix: sometimes error doesn't have response, header will be undefined
        return
      }
      console.log(
        'INTERECEPTIOER ERROR error.response.headers[x-amzn-errortype]',
        error.response.headers['x-amzn-errortype'],
      )
      const originalRequest = error.config

      // This is still not correct because you need to do refresh token and THEN original Request
      if (
        error.response.headers['x-amzn-errortype'] === 'AccessDeniedException' || // 403 When Authorizer wrong key? (Expire, or Verify Fail)
        error.response.headers['x-amzn-errortype'] === 'UnauthorizedException' || // 401 When No Authorizer
        error.response.headers['x-amzn-errortype'] === 'AuthorizerConfigurationException' // 500 When Authorizer asdf -> not sure if this breaks something?
      ) {
        console.info('Access Denied, I am attempting to Check all Tokens')
        // 1. Check if we should refresh
        // Need to delay original request by returning https://stackoverflow.com/questions/51563821/axios-interceptors-retry-original-request-and-access-original-promise
        return checkAllTokens()
          .then((refreshTokenResponse) => {
            const newAccessToken = refreshTokenResponse.accessToken
            const newRefreshToken = refreshTokenResponse.refreshToken
            // Immediately reject if no access Token Provided
            if (!newAccessToken) handleFailure()
            else localStorage.setItem('accessToken', newAccessToken)
            if (newRefreshToken) localStorage.setItem('refreshToken', newRefreshToken)
            console.info('[INFO] Successful refresh of tokens and kept in local storage', newAccessToken)

            store.dispatch(decodeNewToken(newAccessToken, newRefreshToken))
            console.info('[INFO] Successful dispatch decodeNewToken')
            //2. Do the original Request
            const originalRequestNewHeaders = {
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: newAccessToken,
              },
            }
            console.log(
              'Successfull refresh, I am shooting the original request with new headers ',
              originalRequestNewHeaders,
            )
            return Axios(originalRequestNewHeaders)
              .then((originalRequestResponse) => {
                console.log('Successfull originalRequestResponse', originalRequestResponse)
                return originalRequestResponse
              })
              .catch((originalRequestError) => {
                console.log('UNsuccessfull originalRequestResponse', originalRequestError.response)
                Promise.reject(originalRequestError)
              })
          })
          .catch((tokenErrorMessage) => {
            console.error(tokenErrorMessage)
            handleFailure()
            Promise.reject(tokenErrorMessage)
          })

        // TODO make the refresh token go on repeater?
        //   repeater(
        //     function () {
        //       return Axios(originalRequest)
        //     },
        //     3,
        //     1000,
        //   )
      }
      // If not in the if statement case, just reject the error as normal
      console.log('I DONT HAVE ERROR TOKEN, REJECTING')
      Promise.reject(error)
    },
  )

  return axiosInstance
}

// Similar to refreshNewTokens but also check for expiry date
// 1. Check if have refreshToken and if the refreshToken is not expired.
// 2. Check if accessToken is not expired, else refreshToken
export const checkAllTokens = (): Promise<IRefreshTokenResponse> =>
  new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')

    // 1. Check if have refreshToken and if the refreshToken is not expired.
    if (!(refreshToken && isNotExpired(refreshToken))) {
      reject('No refresh token')
    } else if (accessToken && isNotExpired(accessToken)) {
      // 2. Check if accessToken is not expired
      console.log('access token is not expired!')
      resolve({ accessToken, refreshToken })
    } else {
      console.log('I am attempting to refresh Tokens')
      refreshNewTokens()
        .then((refreshTokenResponse) => {
          resolve(refreshTokenResponse)
        })
        .catch((err) => {
          reject(`Failed to refresh ${err} ${err.response}`)
        })
    }
  })

export function handleFailure(): void {
  const customerOrTechnician = location.pathname.split('/')[1]
  localStorage.clear()
  store.dispatch(setLoggedInFalse())
  history.push(`/${customerOrTechnician}/login`)
}

// Check if the Token is NOT expired
// TODO improvements would be to check if it will expire in X minutes instead to prefetch the token
// else we will just shoot out expired tokens when saving
export function isNotExpired(rawToken: string): boolean {
  const { exp } = jwt_decode(rawToken) as any
  if (Date.now() >= exp * 1000) return false
  return true
}

// const repeater = function (axiosRepeater: any, times: number, interval: number) {
//   console.log('times', times)
//   const ID = window.setInterval(
//     (function (times) {
//       return async function () {
//         // console.log('TIME IS CALLED', times)
//         try {
//           const data = await axiosRepeater()
//           console.log('I TRY WITHOUT ERROR, clearing interval', data)
//           clearInterval(ID)
//         } catch (error) {
//           // 500
//           console.log('THIS IS ERROR ', error, error.response)
//         }
//         if (--times <= 0) {
//           clearInterval(ID)
//           localStorage.clear()
//           location.pathname = `/${pathname}/login`
//         }
//       }
//     })(times),
//     interval,
//   )
// }

// Export Get and POST
export function get(url = '/'): Promise<any> {
  const axios = returnAxiosInstance()
  return axios.get(url)
}

export function post(url = '/', requestData: any): Promise<any> {
  const axios = returnAxiosInstance()
  return axios.post(url, requestData)
}
// END Export Get and POST

interface IRefreshTokenResponse {
  accessToken: string | undefined | null
  refreshToken: string | undefined | null
}

// BEGIN Common Functions
export const login = (username: string, password: string): Promise<IRefreshTokenResponse> =>
  new Promise((resolve, reject) => {
    // New axios instance
    Axios.post(`${BASE_URL}/auth/login`, { username, password })
      .then((res) => {
        resolve({ accessToken: res.data.accessToken, refreshToken: res.data.refreshToken })
      })
      .catch((err) => {
        reject(err)
      })
  })
export const refreshNewTokens = (): Promise<IRefreshTokenResponse> =>
  new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refreshToken')
    const refreshTokenPayload = { refreshToken }
    // New axios instance
    Axios.post(`${BASE_URL}/auth/refreshToken`, refreshTokenPayload)
      .then((res) => {
        console.log('Refresh response', res)
        resolve({ accessToken: res.data.accessToken, refreshToken: res.data.refreshToken })
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

// query

export function createJobApplication(createJobApplicationInput: any, query = mutationCreateJobApplication) {
  const variables = {
    ...createJobApplicationInput,
  }
  return post('/', {
    query,
    variables,
  })
}

// mutationUpdateJobApplication
export function acceptJobapplication(acceptJobapplicationInput: any, query = mutationApproveJobApplication) {
  const variables = {
    ...acceptJobapplicationInput,
  }
  return post('/', {
    query,
    variables,
  })
}

export function getPost(postID: any, query = queryGetPost) {
  const variables = {
    ...postID,
  }
  return post('/', {
    query,
    variables,
  })
}

export function ListPostByUserId(listPostInput: any, query = queryListPosts) {
  const variables = {
    ...listPostInput,
    sortDirection: 'DESC',
  }
  return post('/', {
    query,
    variables,
  })
}

export function getPostbyUserId(userId: any, query = queryPostByUserId) {
  const variables = {
    ...userId,
    sortDirection: 'DESC',
  }
  return post('/', {
    query,
    variables,
  })
}

export function getPostbyProjectID(projectId: any, query = querypostByProjectID) {
  const variables = {
    ...projectId,
  }
  return post('/', {
    query,
    variables,
  })
}

//postByproject
export function getJobbyUserId(userId: any, query = queryListJobByUserID) {
  const variables = {
    ...userId,
  }
  return post('/', {
    query,
    variables,
  })
}

//GetTeam
// export function getTeamDetail(userId: any, query = queryTeam) {
//   const variables = {
//     ...userId,
//   }
//   return post('/', {
//     query,
//     variables,
//   })
// }

//GetProfileTeam
export function getProfileTeam(userId: any, query = queryProfileTeam) {
  const variables = {
    ...userId,
  }
  return post('/', {
    query,
    variables,
  })
}

//GetPost Page FilterTech
export function getPostfilter(postId: any, query = queryGetPostfilter) {
  const variables = {
    ...postId,
  }
  return post('/', {
    query,
    variables,
  })
}
export function getProjectIdByUserId(userId: string, query = queryProjectByUserId): Promise<any> {
  const variables = {
    userId,
  }
  return post('/', {
    query,
    variables,
  })
}

export function getProjectIdByAcceptedUserId(
  acceptedUserId: string,
  query = queryProjectByAcceptedUserId,
): Promise<any> {
  const variables = {
    acceptedUserId,
  }
  return post('/', {
    query,
    variables,
  })
}

export function getUser(query = queryGetUser): Promise<any> {
  return post('/', {
    query,
  })
}

export function getProjectByProjectId(
  projectId: string,
  jobFilter = {},
  query = queryProjectByProjectId,
): Promise<any> {
  const variables = {
    projectId,
    jobFilter,
  }
  return post('/', {
    query,
    variables,
  })
}

export function getJobByChangTumId(projectId: string, changTumId: string, query = queryJobByChangTumId): Promise<any> {
  const variables = {
    projectId,
    changTumId,
  }
  return post('/', {
    query,
    variables,
  })
}

export function getMasterPlanExportInno(
  projectId: string,
  planningYear: string,
  query = queryMasterPlanExportInno,
): Promise<any> {
  const variables = {
    projectId,
    planningYear,
  }
  return post('/', {
    query,
    variables,
  })
}
export function getPlanExportInno(projectId: string, planningYear: string, query = queryPlanExportInno): Promise<any> {
  const variables = {
    projectId,
    planningYear,
  }
  return post('/', {
    query,
    variables,
  })
}
export function getInstalledExportInno(
  projectId: string,
  planningYear: string,
  query = queryInstalledExportInno,
): Promise<any> {
  const variables = {
    projectId,
    planningYear,
  }
  return post('/', {
    query,
    variables,
  })
}

//createPost
export function createPost(createPostInput: any, query = mutationCreatePost): Promise<any> {
  const variables = {
    ...createPostInput,
  }
  return post('/', {
    query,
    variables,
  })
}

// createProject
export function createProject(createProjectInput: IPutProject, query = mutationCreateProjectInput): Promise<any> {
  const variables = {
    input: createProjectInput,
  }
  return post('/', {
    query,
    variables,
  })
}

// putProject
export function putProject(putProjectInput: IPutProject, query = mutationPutProjectInput): Promise<any> {
  const variables = {
    putProjectInput,
  }
  return post('/', {
    query,
    variables,
  })
}

// putJobs
export function putJobs(putJobsInput: IPutJobs, query = mutationPutJobsInput): Promise<any> {
  const variables = {
    putJobsInput,
  }
  return post('/', {
    query,
    variables,
  })
}

// createPat
export function createPat(createPATInput: PATGraphQLObject, query = mutationCreatePATInput): Promise<any> {
  const variables = {
    createPATInput,
  }
  return post('/', {
    query,
    variables,
  })
}

// putPat
export function putPat(putPATInput: PATGraphQLObject, query = mutationPutPATInput): Promise<any> {
  const variables = {
    putPATInput,
  }
  return post('/', {
    query,
    variables,
  })
}
// putPat
export function redoPat(redoPATInput: PATGraphQLObject, query = mutationRedoPATInput): Promise<any> {
  const variables = {
    redoPATInput,
  }
  return post('/', {
    query,
    variables,
  })
}

// deleteJob
export function deleteJob(projectId: string, changTumId: string, query = mutationDeleteJobInput): Promise<any> {
  const variables = {
    projectId,
    changTumId,
  }
  return post('/', {
    query,
    variables,
  })
}

// GetPAT
export function getPatByPatId(patId: string, query = queryPatByPatId): Promise<any> {
  const variables = {
    patId,
  }
  return post('/', {
    query,
    variables,
  })
}

//GetNotification
export function getNotificationByUserId(userId: string, limit = 500, query = queryNotificationByUserId) {
  const variables = {
    notificationByUserIdUserId: userId,
    limit: limit,
  }
  return post('/', {
    query,
    variables,
  })
}

function temporaryFillAssignedUserPhone(jobElement: IJob): IJob {
  if (!jobElement.assignedUserPhone)
    jobElement = {
      ...jobElement,
      assignedUserPhone: '',
    }

  jobElement.fieldValue = jobElement.customAttributeEditFieldID
  delete jobElement.customAttributeEditFieldID

  return jobElement
}

function temporaryProjectManagementFillAssignedUserPhone(jobElement: IJob, projectId: string): IJob {
  const jobElementTemporaryFillAssignedUserPhoneAndGetPAT: IJob | any = {
    ...jobElement,
    revisionNo: jobElement.getPAT ? jobElement.getPAT.revisionNo : null,
    versionNo: jobElement.getPAT ? jobElement.getPAT.versionNo : null,
    userId: jobElement.getPAT ? jobElement.getPAT.userId : null,
    updatedAt: jobElement.getPAT ? jobElement.getPAT.updatedAt : null,
    createdAt: jobElement.getPAT ? jobElement.getPAT.createdAt : null,
    name: jobElement.getPAT ? jobElement.getPAT.name : null,
    projectId: projectId,
  }
  if (!jobElement.assignedUserPhone) {
    jobElementTemporaryFillAssignedUserPhoneAndGetPAT.assignedUserPhone = ''
  }
  delete jobElementTemporaryFillAssignedUserPhoneAndGetPAT.getPAT
  // console.log(jobElementTemporaryFillAssignedUserPhoneAndGetPAT, 'jobElement')
  return jobElementTemporaryFillAssignedUserPhoneAndGetPAT
}

//
export function unpackProjectManagement(project: IProject): IProject {
  // Add a jobList for each of the workType
  project.workTypeList.map((workTypeElement: IWorkType, workTypeIndex: number) => {
    project.workTypeList[workTypeIndex].jobList = project.jobList
      ? [
          ...project.jobList
            .filter((jobElement: IJob) => jobElement.workType === workTypeElement.workType)
            .map((mapJobElement: IJob) =>
              temporaryProjectManagementFillAssignedUserPhone(mapJobElement, project.projectId),
            ),
        ]
      : []
  })
  delete project.jobList
  return project
}

// UnPacking data from the response data to allowed the allowed frontend state
export function unpackProject(project: IProject): IProject {
  // Add a jobList for each of the workType
  project.workTypeList.map((workTypeElement: IWorkType, workTypeIndex: number) => {
    project.workTypeList[workTypeIndex].jobList = project.jobList
      ? [
          ...project.jobList
            .filter((jobElement: IJob) => jobElement.workType === workTypeElement.workType)
            .map((mapJobElement: IJob) => temporaryFillAssignedUserPhone(mapJobElement)),
        ]
      : []
  })
  delete project.jobList
  return project
}

//CreateNotification
export function createNotification(
  CreateNotificationInput: createNotification,
  query = mutationCreateNotification,
): Promise<any> {
  const variables = {
    createNotificationInput: CreateNotificationInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//Put Notification
export function putNotification(PUTNotificationInput: IputNotification, query = mutationPutNotification): Promise<any> {
  const variables = {
    putNotificationInput: PUTNotificationInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//GetCreatedbyUserId
export function getCreatedbyUserId(
  projectId: string,
  jobFilter = {},
  query = queryCreatedByUserIDFromProjectByProjectId,
): Promise<any> {
  const variables = {
    projectId,
    jobFilter,
  }
  return post('/', {
    query,
    variables,
  })
}

// Get Team
export function getTeam(query = queryGetTeam): Promise<any> {
  const variables = {}
  return post('/', {
    query,
    variables,
  })
}

export function GetSafetyPassport(query = queryListSafetyPassport): Promise<any> {
  const variables = {}
  return post('/', {
    query,
    variables,
  })
}

//Mutation
export function UpdateJuristic(JuristicInput: any, query = mutationUpdateJuristic): Promise<any> {
  const variables = {
    ...JuristicInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//EditUserProfile
export function UpdateUserProfile(userProfileInput: any, query = mutationUpdateuserProfile): Promise<any> {
  const variables = {
    ...userProfileInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//VerifyPerson
//EditUserProfile
export function CreateUserVerification(
  userVerificationInput: any,
  query = mutationCreateUserVerification,
): Promise<any> {
  const variables = {
    ...userVerificationInput,
  }
  return post('/', {
    query,
    variables,
  })
}
//GetUserProfile
export function getUserProfile(query = querygetUserProfile): Promise<User> {
  const variables = {}
  return post('/', {
    query,
    variables,
  })
}

export function putMasterPlan(input: any, query = mutationPutMasterPlan): Promise<any> {
  const variables = { ...input }
  return post('/', {
    query,
    variables,
  })
}

//CreateSaftety
export function CreateSafetyUser(safteyInput: any, query = MutationCreateSafteyUser): Promise<any> {
  const variables = {
    ...safteyInput,
  }
  return post('/', {
    query,
    variables,
  })
}

export function refreshPATStatus(projectId: string, query = mutationRefreshPATStatus): Promise<any> {
  const variables = {
    projectId,
  }
  return post('/', {
    query,
    variables,
  })
}

export function refreshPATNC(projectId: string, query = mutationRefreshPATNC): Promise<any> {
  const variables = {
    projectId,
  }
  return post('/', {
    query,
    variables,
  })
}

//*Query
export function getProjectPC13(projectId: string, query = queryProjectPC13): Promise<any> {
  const variables = {
    projectId,
  }
  return post('/', {
    query,
    variables,
  })
}

export function GetReportPatStatus(projectId: any, query = queryListReportStatusPAT): Promise<any> {
  const variables = {
    ...projectId,
  }
  return post('/', {
    query,
    variables,
  })
}

export function getUserInTeam(userId: any, query = queryGetUserInTeam): Promise<any> {
  const variables = {
    ...userId,
  }
  return post('/', {
    query,
    variables,
  })
}

export function getMasterPlan(input: any, query = queryMasterPlan): Promise<any> {
  const variables = { ...input }
  return post('/', {
    query,
    variables,
  })
}

export function getPlanDateReport(input: any, query = queryPlanDateReport): Promise<any> {
  const variables = { ...input }
  return post('/', {
    query,
    variables,
  })
}

export function getInstalledDateReport(input: any, query = queryInstalledDateReport): Promise<any> {
  const variables = { ...input }
  return post('/', {
    query,
    variables,
  })
}

export function getRevisionReportCount(input: any, query = queryRevision): Promise<any> {
  const variables = { ...input }
  return post('/', {
    query,
    variables,
  })
}

//ListSafetyInTeam
export function GetListSafetyInTeam(userId: any, query = queryListSafetyPassportByUserInTeam): Promise<any> {
  const variables = {
    ...userId,
  }
  return post('/', {
    query,
    variables,
  })
}

//QueryTech
export function GetTechnicianList(query = queryTechnician): Promise<any> {
  const variables = {}
  return post('/', {
    query,
    variables,
  })
}
//*Mutation
//CreateTeamList
export function CreateTeamList(TeamlistInput: any, query = MutationCreateTeamlist): Promise<any> {
  const variables = {
    ...TeamlistInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//UpdateTeamList
export function UpdateUserTeam(UserTeamInput: any, query = MutationUpdateUserInTeam): Promise<any> {
  const variables = {
    ...UserTeamInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//CreateIssue
export function CreateIssueManagement(IssueInput: any, query = MutationCreateIssue): Promise<any> {
  const variables = {
    ...IssueInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//CreateVerifyUserInteam
export function CreateaVerifyUserInteam(verifyTeamInput: any, query = MutationCreateVerifyInTeam): Promise<any> {
  const variables = {
    ...verifyTeamInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//CreateSafetyTeam
export function CreateSafetyTeam(safetypassInput: any, query = MutationCreateSafteyTeam): Promise<any> {
  const variables = {
    ...safetypassInput,
  }
  return post('/', {
    query,
    variables,
  })
}
//ChangePassword
export function ChangePasswordUser(changepasswordInput: any, query = MutationChangePassword): Promise<any> {
  const variables = {
    ...changepasswordInput,
  }
  return post('/', {
    query,
    variables,
  })
}
//DelUserInteam
export function DeleteUserInTeam(userId: any, query = MutationDelUserInTeam): Promise<any> {
  const variables = {
    ...userId,
  }
  return post('/', {
    query,
    variables,
  })
}

//CreateAcceptedUserInProject
export function CreateTechInProject(
  TechInputVariables: any,
  query = MutationcreateAcceptedUserInProject,
): Promise<any> {
  const variables = {
    ...TechInputVariables,
  }
  return post('/', {
    query,
    variables,
  })
}

//DelAcceptUserInProject
//CreateAcceptedUserInProject
export function DeleteUserAcceptInProject(
  UserAcceptVaraibles: any,
  query = MutationDeleteAcceptedUserInProject,
): Promise<any> {
  const variables = {
    ...UserAcceptVaraibles,
  }
  return post('/', {
    query,
    variables,
  })
}
// BEGIN Common Interfaces
export interface IProject {
  projectId: string
  projectName?: string
  projectDescription?: string
  projectManagerName?: string
  createdByUserId?: string
  startDate?: string
  endDate?: string
  createdAt?: string
  updatedAt?: string
  status?: string

  TORFile?: string
  workTypeList: IWorkType[]
  jobList?: IJob[]
  customFieldList?: ICustomFields[]
  minPrice?: number
  maxPrice?: number
  isPassport?: boolean
  editFieldID?: string
  configReportFieldID?: string
  acceptedUserIdList?: any[]
}

export interface ICreateProject {
  projectName?: string
  projectDescription?: string
  projectManagerName?: string
  createdByUserId?: string

  startDate?: string
  endDate?: string
  createdAt?: string
  updatedAt?: string
  status?: string

  TORFile?: string
  workTypeList: IWorkType[]
  jobList?: IJob[]
}

export interface IPutProject {
  projectId: string
  projectName?: string
  projectDescription?: string
  projectManagerName?: string
  createdByUserId?: string

  startDate?: string
  endDate?: string
  createdAt?: string
  updatedAt?: string
  status?: string

  TORFile?: string
  workTypeList?: IWorkType[]
  jobList?: IJob[]
}

export interface IPutJobs {
  projectId: string
  editFieldID?: string
  jobList: IJob[]
}

export interface IWorkType {
  workType: string
  lineNumber?: number

  patTemplateId?: string
  isNoPrice?: boolean
  jobUnitPrice?: number
  totalJobQty?: number
  isSafetyPassport?: boolean
  safetyPassport?: string[]
  requirements?: string
  imageURLList?: string[]
  // Maybe we need to make new, but the one we use in the frontend is the joblist for each worktype.
  jobList?: IJob[]
}

export interface IJob {
  changTumId: string
  jobId: string
  workType?: string
  region?: string
  province?: string
  district?: string
  siteCode?: string
  siteName?: string
  number?: string
  zone?: string
  googleMap?: string
  lat?: string
  long?: string
  remark?: string
  status?: string
  applicationID?: string
  acceptedUserId?: string
  assignedUserId?: string
  planDate?: string
  actualDate?: string
  locationID?: string
  room?: string
  siteArea?: string
  towerSubType?: string
  towerType?: Tower_Type
  dimension?: string
  manufacturer?: string
  manufacturerDate?: string
  installer?: string
  installerDate?: string
  height?: string
  gateWidth?: string

  assignedUserPhone?: string
  customAttributeEditFieldID?: string
  fieldValue?: string
  isPatGenerated?: boolean
  patStatus?: string
  [key: string]: any
}

export interface ICustomFields {
  name: string
  allowedValueList: string[]
}

// PAT Returned From GraphQL
export interface PATGraphQLObject {
  patId?: string // For Pat Creation
  name: string
  description: string
  status?: string
  versionNo?: string
  createdAt?: string
  updatedAt?: string
  lineList: PATGraphQLLine[]
  titleTemplate?: ITitleTemplate
  userId?: string
  headerList?: IHeaderKey[]
  patExport: Pat_Export
}

export interface PATGraphQLLine {
  lineNo: string
  lineType: string
  value: any
}

export interface IHeaderKey {
  colName: string
  name: string
}

// PAT Destructured
export interface PATObject {
  patId: string
  name: string
  description: string
  status?: string
  versionNo?: string | number
  createdAt?: string
  updatedAt?: string
  titleTemplate?: ITitleTemplate
  lineList: PATLine[][] // Changed To 2d List
  requiredList: any
  headerList: IHeaderKey[]
  patExport: Pat_Export
}

export interface PATLine {
  lineType: string
  text?: string
  image?: string[]
  uploadType?: string
  isNeedAfter?: boolean
  isNeedBefore?: boolean
  maximumFileSize?: number
  maximumFileNumber?: number
  uploadBeforeValue?: string
  uploadAfterValue?: string
  [key: string]: any
}

export interface ITitleTemplate {
  titleTemplateId: string
  name?: string
  description?: string
  userId?: string
  attributeList: ITitleAttribute[]
}

export interface ITitleAttribute {
  attributeName: string
  attributeType: string
  value: ITitleAttributeValue
}

export interface ITitleAttributeValue {
  text?: string
  uploadType?: string
  isNeedBefore?: boolean
  isNeedAfter?: boolean
  maximumFileNumber?: number
  maximumFileSize?: number
  uploadBeforeValue?: string
  uploadAfterValue?: string
}

export interface createNotification {
  userId: string
  message: string
  iconUrl: string
  toLink: string
  isRead: boolean
}

export interface IputNotification {
  userId: string
  notificationId: string
  message: string
  iconUrl: string
  isRead: boolean
  toLink: string
}

export interface IListPost {
  postID?: string
  projectID?: string
  createdByUserID: string
  companyName?: string
  title?: string
  description?: string
  startDate?: string
  endDate?: string
  createdAt?: string
  updatedAt?: string
  status?: string
  postSortBy?: string
  imageURLList?: string[]
  fileAttachmentList?: string
  workTypePostList?: WorkType[]
}

export interface WorkType {
  workType: string
  fieldID?: string
  jobUnitPrice?: number
  totalJobList?: IJobList[]
  // Maybe we need to make new, but the one we use in the frontend is the joblist for each worktype.
  jobList?: IJob[]
}

export interface IJobList {
  fieldValue?: string
  totalQty?: number
  balanceQty?: number
  registerQty?: string
  approveQty?: string
}
