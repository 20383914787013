export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Any: { input: any; output: any }
  Date: { input: any; output: any }
  DateTime: { input: any; output: any }
  JSON: { input: any; output: any }
}

export enum Antenna_Type {
  Aau = 'AAU',
  Cellular = 'CELLULAR',
  Grid = 'GRID',
  Omni = 'OMNI',
  Parabolic = 'PARABOLIC',
  ParabolicGrid = 'PARABOLIC_GRID',
  Rru = 'RRU',
  Solid = 'SOLID',
}

/** For APPROVE */
export type AcceptOrRejectWorkTypeJobApplicationInput = {
  applicationID: Scalars['ID']['input']
  jobList?: InputMaybe<Array<Scalars['ID']['input']>>
  postID: Scalars['ID']['input']
  /** Should be ACCEPT/REJECT ONLY! */
  status: EJobApplicationStatus
  totalJobList?: InputMaybe<Array<UpdateTotalJobApplication>>
  workType: Scalars['String']['input']
}

export type AdminUserUpdateInput = {
  addressDetail?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['String']['input']>
  companyBranch?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  coordinatorAddress?: InputMaybe<Scalars['String']['input']>
  coordinatorName?: InputMaybe<Scalars['String']['input']>
  coordinatorPersonalID?: InputMaybe<Scalars['String']['input']>
  coordinatorPhone?: InputMaybe<Scalars['String']['input']>
  customAttrs?: InputMaybe<Scalars['JSON']['input']>
  district?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  friendList?: InputMaybe<Array<InputMaybe<FriendInput>>>
  gender?: InputMaybe<EGender>
  isSafetyPassport?: InputMaybe<Scalars['Boolean']['input']>
  isVerification?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  myWorkDescription?: InputMaybe<Scalars['String']['input']>
  myWorkFileAttachment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  myWorkImageList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  myWorkTeamName?: InputMaybe<Scalars['String']['input']>
  myWorkTechnicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  myWorkserviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  myWorkserviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  nickName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  personalID?: InputMaybe<Scalars['ID']['input']>
  personalRelation?: InputMaybe<EPersonalRelation>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<EThaiProvinces>
  /** [userSafetyPassport] Input */
  safetyPassport?: InputMaybe<Array<InputMaybe<SafetyPassportInput>>>
  serviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  serviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  subDistrict?: InputMaybe<Scalars['String']['input']>
  taxID?: InputMaybe<Scalars['ID']['input']>
  technicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  technicianList?: InputMaybe<Array<InputMaybe<UserIdInput>>>
  userId: Scalars['ID']['input']
  userType?: InputMaybe<EUserType>
  username?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}

export type Antenna = {
  __typename?: 'Antenna'
  A?: Maybe<Array<Scalars['Int']['output']>>
  B?: Maybe<Array<Scalars['Int']['output']>>
  C?: Maybe<Array<Scalars['Int']['output']>>
  D?: Maybe<Array<Scalars['Int']['output']>>
  colorGroup?: Maybe<Array<ColorGroup>>
}

export type AntennaInput = {
  A?: InputMaybe<Array<Scalars['Int']['input']>>
  B?: InputMaybe<Array<Scalars['Int']['input']>>
  C?: InputMaybe<Array<Scalars['Int']['input']>>
  D?: InputMaybe<Array<Scalars['Int']['input']>>
  colorGroup?: InputMaybe<Array<ColorGroupInput>>
}

export type AntennaPlane = {
  __typename?: 'AntennaPlane'
  antenna?: Maybe<Antenna>
  height?: Maybe<Scalars['Float']['output']>
  mounting?: Maybe<Scalars['Int']['output']>
  other?: Maybe<Antenna>
}

export type AntennaPlaneInput = {
  antenna?: InputMaybe<AntennaInput>
  height?: InputMaybe<Scalars['Float']['input']>
  mounting?: InputMaybe<Scalars['Int']['input']>
  other?: InputMaybe<AntennaInput>
}

export type AntennaPole = {
  __typename?: 'AntennaPole'
  /** For SteelPipe only */
  hasLegs?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  planeList?: Maybe<Array<AntennaPlane>>
  poleSize?: Maybe<Scalars['String']['output']>
}

export type AntennaPoleInput = {
  /** For SteelPipe only */
  hasLegs?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  planeList?: InputMaybe<Array<AntennaPlaneInput>>
  poleSize?: InputMaybe<Scalars['String']['input']>
}

export type AppendIssueInput = {
  changTumId: Scalars['ID']['input']
  createdByUserId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  projectId: Scalars['ID']['input']
  topic: Scalars['String']['input']
}

export type ApproveLineValue = {
  __typename?: 'ApproveLineValue'
  approvalRemark?: Maybe<Scalars['String']['output']>
  /** In PAT Instantiation, at PAT Review phase needs to check the approval message (ONLY ON OUTERMOST GROUP!!) */
  isApproved: Scalars['Boolean']['output']
}

export type ChecklistRowInput = {
  children?: InputMaybe<Array<ChecklistRowInput>>
  name?: InputMaybe<Scalars['String']['input']>
  property?: InputMaybe<Scalars['String']['input']>
}

export type ChecklistRowValue = {
  __typename?: 'ChecklistRowValue'
  children?: Maybe<Array<ChecklistRowValue>>
  name?: Maybe<Scalars['String']['output']>
  property?: Maybe<Scalars['String']['output']>
}

export type ColorGroup = {
  __typename?: 'ColorGroup'
  antennaType: Antenna_Type
  color: Scalars['String']['output']
  d?: Maybe<Scalars['Float']['output']>
  dia?: Maybe<Scalars['Float']['output']>
  id: Scalars['Int']['output']
  l?: Maybe<Scalars['Float']['output']>
  w?: Maybe<Scalars['Float']['output']>
}

export type ColorGroupInput = {
  antennaType: Antenna_Type
  color: Scalars['String']['input']
  d?: InputMaybe<Scalars['Float']['input']>
  dia?: InputMaybe<Scalars['Float']['input']>
  id: Scalars['Int']['input']
  l?: InputMaybe<Scalars['Float']['input']>
  w?: InputMaybe<Scalars['Float']['input']>
}

/** Setting Input */
export type CreateJobApplicationInput = {
  createdByUserID?: InputMaybe<Scalars['ID']['input']>
  postID: Scalars['ID']['input']
  technicianList?: InputMaybe<Array<UserIdInput>>
  workTypeJobApplicationList: Array<UpdateWorkTypeJobApplication>
}

export type CreateNotificationInput = {
  iconUrl?: InputMaybe<Scalars['String']['input']>
  isRead?: InputMaybe<Scalars['Boolean']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  toLink?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['ID']['input']
}

export type CreatePatInput = {
  description?: InputMaybe<Scalars['String']['input']>
  /**  Header List: Custom  */
  headerList?: InputMaybe<Array<HeaderKeyInput>>
  jobId?: InputMaybe<Scalars['String']['input']>
  lineList?: InputMaybe<Array<CreatePatLineInput>>
  name?: InputMaybe<Scalars['String']['input']>
  patExport?: InputMaybe<Pat_Export>
  /** Used for when isTemplate=false */
  projectId?: InputMaybe<Scalars['String']['input']>
  titleTemplate?: InputMaybe<PutTitleTemplateInput>
  /**
   * Used for when isTemplate=true.
   * This should be inferred from the ACCESS_TOKEN but just send it first
   */
  userId?: InputMaybe<Scalars['String']['input']>
}

export type CreatePatLineInput = {
  lineNo: Scalars['String']['input']
  lineType: Line_Type
  value?: InputMaybe<PutPatLineValueInput>
}

export type CreatePostInput = {
  createdByUserID?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  endDate: Scalars['String']['input']
  fileAttachmentList?: InputMaybe<Array<Scalars['String']['input']>>
  imageURLList: Array<InputMaybe<Scalars['String']['input']>>
  postID?: InputMaybe<Scalars['ID']['input']>
  projectID: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  title: Scalars['String']['input']
  workTypePostList?: InputMaybe<Array<PutWorkTypePostInput>>
}

export type CreateProjectInput = {
  /**   One TOR File in S3 String   */
  TORFile?: InputMaybe<Scalars['String']['input']>
  configReportFieldID?: InputMaybe<Scalars['ID']['input']>
  /**   คนที่สร้าง project นี้   */
  createdByUserId: Scalars['ID']['input']
  /**
   * Optional Custom Fields
   * AIS would use this Custom Field for their own AISRegion, for example.
   */
  customFieldList?: InputMaybe<Array<PutCustomFieldInput>>
  /**   Edit fieldID: Need if want to edit fieldValue   */
  editFieldID?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  jobList?: InputMaybe<Array<UpdateJobInput>>
  projectDescription?: InputMaybe<Scalars['String']['input']>
  projectManagerName?: InputMaybe<Scalars['String']['input']>
  projectManagerPosition?: InputMaybe<Scalars['String']['input']>
  projectName: Scalars['String']['input']
  /**   Start date and End Date of the Project   */
  startDate?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Project_Status>
  /**   All the ประเภทงาน/Work Types in the Project   */
  workTypeList?: InputMaybe<Array<UpdateWorkTypeInput>>
}

export type CreateTitleAttributeInput = {
  attributeName: Scalars['String']['input']
  attributeType?: InputMaybe<Line_Type>
  value?: InputMaybe<CreateTitleAttributeValueInput>
}

export type CreateTitleAttributeValueInput = {
  isNeedAfter?: InputMaybe<Scalars['Boolean']['input']>
  isNeedBefore?: InputMaybe<Scalars['Boolean']['input']>
  maximumFileNumber?: InputMaybe<Scalars['Int']['input']>
  maximumFileSize?: InputMaybe<Scalars['Int']['input']>
  text: Scalars['String']['input']
  uploadAfterValue?: InputMaybe<Scalars['String']['input']>
  uploadBeforeValue?: InputMaybe<Scalars['String']['input']>
  uploadType?: InputMaybe<Upload_Type>
}

export type CreateTitleTemplateInput = {
  attributeList?: InputMaybe<Array<InputMaybe<CreateTitleAttributeInput>>>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateUserInTeamBatch = {
  addressDetail?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['String']['input']>
  customAttrs?: InputMaybe<Scalars['JSON']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<EGender>
  inputSafetyPassport?: InputMaybe<Array<InputMaybe<SafetyPassportInput>>>
  inputVerification?: InputMaybe<Array<InputMaybe<CreateUserVerificationInTeamInput>>>
  isSafetyPassport?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  nickName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  personalID?: InputMaybe<Scalars['ID']['input']>
  personalRelation?: InputMaybe<EPersonalRelation>
  phone?: InputMaybe<Scalars['String']['input']>
  serviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  serviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  technicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  username?: InputMaybe<Scalars['String']['input']>
}

export type CreateUserVerificationInTeamInput = {
  personalIDFileAttachment?: InputMaybe<Scalars['String']['input']>
  selfiePersonalIDFileAttachment?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateUserVerificationInput = {
  companyCertificateFileAttachment?: InputMaybe<Scalars['String']['input']>
  coordinatorPersonalIDFileAttachment?: InputMaybe<Scalars['String']['input']>
  personalIDFileAttachment?: InputMaybe<Scalars['String']['input']>
  selfieJuristicIDFileAttachment?: InputMaybe<Scalars['String']['input']>
  selfiePersonalIDFileAttachment?: InputMaybe<Scalars['String']['input']>
  vatFileAttachment?: InputMaybe<Scalars['String']['input']>
}

/**
 * Optional Custom Fields
 * AIS would use this Custom Field for their own AISRegion, for example.
 */
export type CustomField = {
  __typename?: 'CustomField'
  /**   An Enum of Allowed Values   */
  allowedValueList: Array<Scalars['String']['output']>
  /**   The name of the custom Field   */
  name: Scalars['String']['output']
}

export type DeletePatInput = {
  patId: Scalars['String']['input']
}

export type DeletePostInput = {
  postID: Scalars['ID']['input']
}

export type DeleteUserVerificationInput = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type DuplicatePatInput = {
  patId: Scalars['String']['input']
}

export enum EGender {
  Female = 'FEMALE',
  Lgbt = 'LGBT',
  Male = 'MALE',
}

export enum EIsVerification {
  False = 'FALSE',
  True = 'TRUE',
}

export enum EJobApplicationStatus {
  Accept = 'ACCEPT',
  Pending = 'PENDING',
  Reject = 'REJECT',
}

export enum EPersonalRelation {
  Divorce = 'DIVORCE',
  Married = 'MARRIED',
  Single = 'SINGLE',
}

export enum ERegion {
  Bmr = 'BMR',
  Cr = 'CR',
  Er = 'ER',
  Ner = 'NER',
  Nr = 'NR',
  Sr = 'SR',
  Wr = 'WR',
}

export enum ESafetyPassport {
  AisC = 'AIS_C',
  AisE = 'AIS_E',
  AisEe = 'AIS_EE',
  AisG = 'AIS_G',
  AisH = 'AIS_H',
}

export enum ESafetyPassporyStatus {
  Accept = 'ACCEPT',
  Pending = 'PENDING',
  Reject = 'REJECT',
}

export enum EStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum EStatusVerification {
  NonVerified = 'NON_VERIFIED',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED',
  WaitVerified = 'WAIT_VERIFIED',
}

export enum EThaiProvinces {
  AmnatCharoen = 'AMNAT_CHAROEN',
  AngThong = 'ANG_THONG',
  Bangkok = 'BANGKOK',
  Buogkan = 'BUOGKAN',
  BuriRam = 'BURI_RAM',
  Chachoengsao = 'CHACHOENGSAO',
  Chaiyaphum = 'CHAIYAPHUM',
  ChaiNat = 'CHAI_NAT',
  Chanthaburi = 'CHANTHABURI',
  ChiangMai = 'CHIANG_MAI',
  ChiangRai = 'CHIANG_RAI',
  ChonBuri = 'CHON_BURI',
  Chumphon = 'CHUMPHON',
  Kalasin = 'KALASIN',
  KamphaengPhet = 'KAMPHAENG_PHET',
  Kanchanaburi = 'KANCHANABURI',
  KhonKaen = 'KHON_KAEN',
  Krabi = 'KRABI',
  Lampang = 'LAMPANG',
  Lamphun = 'LAMPHUN',
  Loburi = 'LOBURI',
  Loei = 'LOEI',
  MaeHongSon = 'MAE_HONG_SON',
  MahaSarakham = 'MAHA_SARAKHAM',
  Mukdahan = 'MUKDAHAN',
  NakhonNayok = 'NAKHON_NAYOK',
  NakhonPathom = 'NAKHON_PATHOM',
  NakhonPhanom = 'NAKHON_PHANOM',
  NakhonRatchasima = 'NAKHON_RATCHASIMA',
  NakhonSawan = 'NAKHON_SAWAN',
  NakhonSiThammarat = 'NAKHON_SI_THAMMARAT',
  Nan = 'NAN',
  Narathiwat = 'NARATHIWAT',
  NongBuaLamPhu = 'NONG_BUA_LAM_PHU',
  NongKhai = 'NONG_KHAI',
  Nonthaburi = 'NONTHABURI',
  PathumThani = 'PATHUM_THANI',
  Pattani = 'PATTANI',
  Phangnga = 'PHANGNGA',
  Phatthalung = 'PHATTHALUNG',
  Phayao = 'PHAYAO',
  Phetchabun = 'PHETCHABUN',
  Phetchaburi = 'PHETCHABURI',
  Phichit = 'PHICHIT',
  Phitsanulok = 'PHITSANULOK',
  Phrae = 'PHRAE',
  PhraNakhonSiAyutthaya = 'PHRA_NAKHON_SI_AYUTTHAYA',
  Phuket = 'PHUKET',
  PrachinBuri = 'PRACHIN_BURI',
  PrachuapKhiriKhan = 'PRACHUAP_KHIRI_KHAN',
  Ranong = 'RANONG',
  Ratchaburi = 'RATCHABURI',
  Rayong = 'RAYONG',
  RoiEt = 'ROI_ET',
  SakonNakhon = 'SAKON_NAKHON',
  SamutPrakan = 'SAMUT_PRAKAN',
  SamutSakhon = 'SAMUT_SAKHON',
  SamutSongkhram = 'SAMUT_SONGKHRAM',
  Saraburi = 'SARABURI',
  Satun = 'SATUN',
  SaKaeo = 'SA_KAEO',
  SingBuri = 'SING_BURI',
  SiSaKet = 'SI_SA_KET',
  Songkhla = 'SONGKHLA',
  Sukhothai = 'SUKHOTHAI',
  SuphanBuri = 'SUPHAN_BURI',
  SuratThani = 'SURAT_THANI',
  Surin = 'SURIN',
  Tak = 'TAK',
  Trang = 'TRANG',
  Trat = 'TRAT',
  UbonRatchathani = 'UBON_RATCHATHANI',
  UdonThani = 'UDON_THANI',
  UthaiThani = 'UTHAI_THANI',
  Uttaradit = 'UTTARADIT',
  Yala = 'YALA',
  Yasothon = 'YASOTHON',
}

export enum ETypeOfjob {
  Carpenter = 'Carpenter',
  Electrician = 'Electrician',
  Installer = 'Installer',
  Mechanic = 'Mechanic',
  Metalworker = 'Metalworker',
  Painter = 'Painter',
  Plasterer = 'Plasterer',
  Plumber = 'Plumber',
}

export enum EUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum EUserType {
  Juristic = 'JURISTIC',
  Person = 'PERSON',
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  relation?: Maybe<Scalars['String']['output']>
}

export type EmergencyContactInput = {
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  relation?: InputMaybe<Scalars['String']['input']>
}

export type Friend = {
  __typename?: 'Friend'
  displayName?: Maybe<Scalars['String']['output']>
  nickname?: Maybe<Scalars['String']['output']>
  userId: Scalars['ID']['output']
}

export type FriendInput = {
  nickname?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['ID']['input']
}

export type GeneratePatByPatTemplateInput = {
  jobList: Array<JobInput>
  projectId: Scalars['String']['input']
  workTypeList: Array<WorkTypeInput>
}

export type GroupField = {
  __typename?: 'GroupField'
  fieldValue?: Maybe<Scalars['String']['output']>
  workTypeList?: Maybe<Array<MasterPlanWorkType>>
}

export type GroupFieldByAcceptUserId = {
  __typename?: 'GroupFieldByAcceptUserId'
  fieldValue?: Maybe<Scalars['String']['output']>
  workTypeList?: Maybe<Array<MasterPlanWorkTypeByAcceptUserId>>
}

export type GroupLineValue = {
  __typename?: 'GroupLineValue'
  lineList?: Maybe<Array<PatLine>>
  required?: Maybe<Scalars['Boolean']['output']>
}

export type HeaderKey = {
  __typename?: 'HeaderKey'
  colName: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type HeaderKeyInput = {
  colName: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type Issue = {
  __typename?: 'Issue'
  createdByUserId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  /**   Nested Field   */
  getUser?: Maybe<User>
  topic: Scalars['String']['output']
}

export enum Job_Status {
  Accepted = 'ACCEPTED',
  Finished = 'FINISHED',
  Planned = 'PLANNED',
  Wait = 'WAIT',
}

export type Job = {
  __typename?: 'Job'
  /**   Subcontractor/Organization that accepts   */
  acceptedUserId?: Maybe<Scalars['ID']['output']>
  actualDate?: Maybe<Scalars['String']['output']>
  /**   "Addition 1   */
  addition1?: Maybe<Scalars['String']['output']>
  /**   "Addition 2   */
  addition2?: Maybe<Scalars['String']['output']>
  applicationID?: Maybe<Scalars['String']['output']>
  /**   Assigned ช่าง inside that organization   */
  assignedUserId?: Maybe<Scalars['ID']['output']>
  /**   เบอร์โทรติดต่อของช่าง   */
  assignedUserPhone?: Maybe<Scalars['ID']['output']>
  changTumId: Scalars['ID']['output']
  /**   [Nested] Custom Field of Job   */
  customAttribute: Scalars['String']['output']
  /**   [Nested] Custom Field of Job from first Project Query (Ref editFieldID)   */
  customAttributeEditFieldID?: Maybe<Scalars['String']['output']>
  /**   รายละเอียด   */
  description?: Maybe<Scalars['String']['output']>
  dimension?: Maybe<Scalars['String']['output']>
  district?: Maybe<Scalars['String']['output']>
  gateWidth?: Maybe<Scalars['String']['output']>
  /**   [Nested] Get the whole pat   */
  getPAT?: Maybe<Pat>
  googleMap?: Maybe<Scalars['String']['output']>
  height?: Maybe<Scalars['String']['output']>
  installer?: Maybe<Scalars['String']['output']>
  installerDate?: Maybe<Scalars['String']['output']>
  /**   Calculated value to see if Pat I is instantiated   */
  isPatGenerated?: Maybe<Scalars['Boolean']['output']>
  /**   Nested   */
  issueCount?: Maybe<Scalars['Int']['output']>
  /**   all user can บันทึกปัญหาหน้างาน   */
  issueList?: Maybe<Array<Issue>>
  jobId?: Maybe<Scalars['ID']['output']>
  lat?: Maybe<Scalars['String']['output']>
  locationID?: Maybe<Scalars['String']['output']>
  long?: Maybe<Scalars['String']['output']>
  manufacturer?: Maybe<Scalars['String']['output']>
  manufacturerDate?: Maybe<Scalars['String']['output']>
  number?: Maybe<Scalars['String']['output']>
  /**   Calculated value to see if Pat Status   */
  patStatus?: Maybe<Pat_Status>
  /**   Calculated value to see if Pat Last updated At   */
  patUpdatedAt?: Maybe<Scalars['String']['output']>
  /**   Plan Date and Actual Date   */
  planDate?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  region?: Maybe<Scalars['String']['output']>
  /**   หมายเหตุ   */
  remark?: Maybe<Scalars['String']['output']>
  room?: Maybe<Scalars['String']['output']>
  siteArea?: Maybe<Scalars['String']['output']>
  siteCode?: Maybe<Scalars['String']['output']>
  siteName?: Maybe<Scalars['String']['output']>
  status?: Maybe<Job_Status>
  towerSubType?: Maybe<Scalars['String']['output']>
  towerType?: Maybe<Tower_Type>
  workType: Scalars['String']['output']
  zone?: Maybe<Scalars['String']['output']>
}

export type JobCustomAttributeArgs = {
  fieldID: Scalars['ID']['input']
}

/** JobApplication is for technicians to apply to a Post, which are created from Project. */
export type JobApplication = {
  __typename?: 'JobApplication'
  applicationID: Scalars['ID']['output']
  createdAt?: Maybe<Scalars['String']['output']>
  createdByUserID?: Maybe<Scalars['ID']['output']>
  post?: Maybe<Post>
  postID: Scalars['ID']['output']
  technicianList?: Maybe<Array<UserId>>
  updatedAt?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
  workTypeJobApplicationList?: Maybe<Array<WorkTypeJobApplication>>
}

export type JobApplicationQuery = {
  __typename?: 'JobApplicationQuery'
  items: Array<JobApplication>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type JobInput = {
  changTumId: Scalars['String']['input']
  workType: Scalars['String']['input']
}

export enum Line_Type {
  Approve = 'APPROVE',
  Group = 'GROUP',
  Heading2 = 'HEADING2',
  Image = 'IMAGE',
  Text = 'TEXT',
  Upload = 'UPLOAD',
}

export type MasterPlan = {
  __typename?: 'MasterPlan'
  fieldID?: Maybe<Scalars['ID']['output']>
  groupFieldList?: Maybe<Array<GroupField>>
  planningYear: Scalars['String']['output']
  /** Required Attributes. project will have masterPlan: { year1: MasterPlan1, year2: MasterPlan2} */
  projectId: Scalars['ID']['output']
  /** Update everytime the updateMasterPlan is Triggered */
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type MasterPlanByAcceptUserId = {
  __typename?: 'MasterPlanByAcceptUserId'
  fieldID?: Maybe<Scalars['ID']['output']>
  groupFieldList?: Maybe<Array<GroupFieldByAcceptUserId>>
  planningYear: Scalars['String']['output']
  /** Required Attributes. project will have masterPlan: { year1: MasterPlan1, year2: MasterPlan2} */
  projectId: Scalars['ID']['output']
  /** Update everytime the updateMasterPlan is Triggered */
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type MasterPlanWorkType = {
  __typename?: 'MasterPlanWorkType'
  workType: Scalars['String']['output']
  workTypeTable: WorkTypeTable
}

export type MasterPlanWorkTypeByAcceptUserId = {
  __typename?: 'MasterPlanWorkTypeByAcceptUserId'
  workType: Scalars['String']['output']
  workTypeTable: WorkTypeTableByAcceptUserId
}

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet',
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  eq?: InputMaybe<Scalars['Boolean']['input']>
  ne?: InputMaybe<Scalars['Boolean']['input']>
}

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  eq?: InputMaybe<Scalars['Float']['input']>
  ge?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  le?: InputMaybe<Scalars['Float']['input']>
  lt?: InputMaybe<Scalars['Float']['input']>
  ne?: InputMaybe<Scalars['Float']['input']>
}

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  beginsWith?: InputMaybe<Scalars['ID']['input']>
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  contains?: InputMaybe<Scalars['ID']['input']>
  eq?: InputMaybe<Scalars['ID']['input']>
  ge?: InputMaybe<Scalars['ID']['input']>
  gt?: InputMaybe<Scalars['ID']['input']>
  le?: InputMaybe<Scalars['ID']['input']>
  lt?: InputMaybe<Scalars['ID']['input']>
  ne?: InputMaybe<Scalars['ID']['input']>
  notContains?: InputMaybe<Scalars['ID']['input']>
  size?: InputMaybe<ModelSizeInput>
}

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  eq?: InputMaybe<Scalars['Int']['input']>
  ge?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  le?: InputMaybe<Scalars['Int']['input']>
  lt?: InputMaybe<Scalars['Int']['input']>
  ne?: InputMaybe<Scalars['Int']['input']>
}

export type ModelPostFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPostFilterInput>>>
  not?: InputMaybe<ModelPostFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelPostFilterInput>>>
  postSortBy?: InputMaybe<ModelStringInput>
  status?: InputMaybe<ModelStringInput>
}

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  eq?: InputMaybe<Scalars['Int']['input']>
  ge?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  le?: InputMaybe<Scalars['Int']['input']>
  lt?: InputMaybe<Scalars['Int']['input']>
  ne?: InputMaybe<Scalars['Int']['input']>
}

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  beginsWith?: InputMaybe<Scalars['String']['input']>
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  contains?: InputMaybe<Scalars['String']['input']>
  eq?: InputMaybe<Scalars['String']['input']>
  ge?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  le?: InputMaybe<Scalars['String']['input']>
  lt?: InputMaybe<Scalars['String']['input']>
  ne?: InputMaybe<Scalars['String']['input']>
  notContains?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<ModelSizeInput>
}

export type Mutation = {
  __typename?: 'Mutation'
  /**
   * PC5 Accept Job. Change status of WorkTypeJobApplication.
   * Case Accept: Trigger balance/approvedQty on Post
   * Case Reject: Ignore
   */
  acceptOrRejectWorkTypeJobApplication?: Maybe<Scalars['String']['output']>
  adminUserUpdate?: Maybe<User>
  /** appendIssue on that particular projectId and changTumId */
  appendIssue?: Maybe<Issue>
  /**
   * create [createAcceptedUserInProject] * without ~ accept from JobApp ~
   * this menu is Direct to [listAppend] userId ~ changID ~ Id chang Kub
   */
  createAcceptedUserInProject?: Maybe<Project>
  /** T3 Apply Job. createJobApplication สร้างคำขอสมัครงาน */
  createJobApplication?: Maybe<JobApplication>
  createNotification: Notification
  /** PAT Creation without knowing PAT Id */
  createPAT: Pat
  createPost: Post
  createProject: Project
  createTitleTemplate: TitleTemplate
  createUserInTeam?: Maybe<User>
  /** CREATE USER IN TEAM BATCH */
  createUserInTeamBatch?: Maybe<Array<Maybe<User>>>
  createUserVerification?: Maybe<UserVerification>
  createUserVerificationInTeam?: Maybe<UserVerification>
  /** delete [AcceptedUserInProject] */
  deleteAcceptedUserInProject?: Maybe<Project>
  deleteJob?: Maybe<Job>
  deletePAT: Pat
  deletePost: Post
  deleteTitleTemplate: TitleTemplate
  deleteUserInTeam?: Maybe<User>
  deleteUserVerification?: Maybe<UserVerification>
  duplicatePAT: Pat
  generatePATByPATTemplate?: Maybe<Scalars['String']['output']>
  /**
   * Beware that these shits can only upsert jobs!!
   * But You can put the only jobs that you want here, other Jobs will not be affected
   */
  putJobs: Array<Job>
  /** Update masterPlan attribute inside of Project */
  putMasterPlan: MasterPlan
  putMasterPlanOneCell: MasterPlan
  /** Should use only when you isRead to True */
  putNotification: Notification
  /**
   * This is not update PAT! This replaces the old lines with the keys COMPLETELY!
   * You can putPAT WITHOUT lineList and nothing will change, only the PAT row!!
   */
  putPAT: Pat
  /**
   * Beware that these shits can only upsert jobs!!
   * But you don't need to put in any jobs here. Jobs will not be affected.
   */
  putProject: Project
  putTitleTemplate: TitleTemplate
  /** Used for PAT Instantiations that are Rejected. (4.3 -> 4.2) Similar to putPAT but makes a copy of it and incrementing versionNo */
  redoPAT: Pat
  /** Update all Values of PAT NC PC1.3 in that particular Project by new queries and puts */
  refreshPATNC: Scalars['String']['output']
  /** Update all Values of PAT Status PC1.2 in that particular Project by new queries and puts */
  refreshPATStatus: Scalars['String']['output']
  /** Create [AIS] Safety Passport */
  requestApprovalSafetyPassport: SafetyPassport
  /** Create [AIS] Safety Passport for [user in [TEAM]] */
  requestApprovalSafetyPassportUserInTeam?: Maybe<SafetyPassport>
  /** only used for testing events for stream */
  testCreatePAT?: Maybe<Scalars['String']['output']>
  /** Update [AIS] Safety Passport */
  updateSafetyPassport: SafetyPassport
  updateUserInTeam?: Maybe<User>
  updateUserProfilePicture?: Maybe<User>
  updateUserVerification?: Maybe<UserVerification>
  userChangePassword?: Maybe<User>
  /** Returns token */
  userLogin?: Maybe<Scalars['String']['output']>
  userRegister?: Maybe<User>
  /** FOR USER UPDATING THEIR OWN ATTRIBUTES ONLY, DONT USE PASSWORD */
  userUpdate?: Maybe<User>
}

export type MutationAcceptOrRejectWorkTypeJobApplicationArgs = {
  input: AcceptOrRejectWorkTypeJobApplicationInput
}

export type MutationAdminUserUpdateArgs = {
  input: AdminUserUpdateInput
}

export type MutationAppendIssueArgs = {
  input: AppendIssueInput
}

export type MutationCreateAcceptedUserInProjectArgs = {
  acceptedUserId: Scalars['ID']['input']
  projectId: Scalars['ID']['input']
}

export type MutationCreateJobApplicationArgs = {
  input: CreateJobApplicationInput
}

export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput
}

export type MutationCreatePatArgs = {
  input: CreatePatInput
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationCreatePostArgs = {
  input: CreatePostInput
}

export type MutationCreateProjectArgs = {
  input: CreateProjectInput
}

export type MutationCreateTitleTemplateArgs = {
  input: CreateTitleTemplateInput
}

export type MutationCreateUserInTeamArgs = {
  input: UserInTeamInput
}

export type MutationCreateUserInTeamBatchArgs = {
  input?: InputMaybe<Array<CreateUserInTeamBatch>>
}

export type MutationCreateUserVerificationArgs = {
  input: CreateUserVerificationInput
}

export type MutationCreateUserVerificationInTeamArgs = {
  input: CreateUserVerificationInTeamInput
}

export type MutationDeleteAcceptedUserInProjectArgs = {
  acceptedUserId: Scalars['ID']['input']
  projectId: Scalars['ID']['input']
}

export type MutationDeleteJobArgs = {
  changTumId: Scalars['ID']['input']
  projectId: Scalars['ID']['input']
}

export type MutationDeletePatArgs = {
  input: DeletePatInput
}

export type MutationDeletePostArgs = {
  input: DeletePostInput
}

export type MutationDeleteTitleTemplateArgs = {
  titleTemplateId: Scalars['ID']['input']
}

export type MutationDeleteUserInTeamArgs = {
  input: UserIdInput
}

export type MutationDeleteUserVerificationArgs = {
  input: DeleteUserVerificationInput
}

export type MutationDuplicatePatArgs = {
  input: DuplicatePatInput
}

export type MutationGeneratePatByPatTemplateArgs = {
  input: GeneratePatByPatTemplateInput
}

export type MutationPutJobsArgs = {
  input: PutJobsInput
}

export type MutationPutMasterPlanArgs = {
  input: PutMasterPlan
}

export type MutationPutMasterPlanOneCellArgs = {
  input?: InputMaybe<PutMasterPlanOneCell>
}

export type MutationPutNotificationArgs = {
  input: UpdateNotificationInput
}

export type MutationPutPatArgs = {
  input: PutPatInput
}

export type MutationPutProjectArgs = {
  input: PutProjectInput
}

export type MutationPutTitleTemplateArgs = {
  input: PutTitleTemplateInput
}

export type MutationRedoPatArgs = {
  input: RedoPatInput
}

export type MutationRefreshPatncArgs = {
  projectId: Scalars['ID']['input']
}

export type MutationRefreshPatStatusArgs = {
  projectId: Scalars['ID']['input']
}

export type MutationRequestApprovalSafetyPassportArgs = {
  input: Array<SafetyPassportInput>
}

export type MutationRequestApprovalSafetyPassportUserInTeamArgs = {
  input: Array<SafetyPassportUserInTeamInput>
  userId: Scalars['ID']['input']
}

export type MutationTestCreatePatArgs = {
  patId: Scalars['ID']['input']
  versionNo: Scalars['Int']['input']
}

export type MutationUpdateSafetyPassportArgs = {
  input?: InputMaybe<SafetyPassportUpdate>
}

export type MutationUpdateUserInTeamArgs = {
  input: UserUpdateTeamInput
}

export type MutationUpdateUserProfilePictureArgs = {
  input: UserUpdateProfilePicture
}

export type MutationUpdateUserVerificationArgs = {
  input: UpdateUserVerificationInput
}

export type MutationUserChangePasswordArgs = {
  input: UserChangPasswordInput
}

export type MutationUserLoginArgs = {
  input: UserLoginInput
}

export type MutationUserRegisterArgs = {
  input: UserInput
}

export type MutationUserUpdateArgs = {
  input: UserUpdateInput
}

export type Notification = {
  __typename?: 'Notification'
  createdAt?: Maybe<Scalars['String']['output']>
  iconUrl?: Maybe<Scalars['String']['output']>
  isRead?: Maybe<Scalars['Boolean']['output']>
  message?: Maybe<Scalars['String']['output']>
  notificationId: Scalars['ID']['output']
  toLink?: Maybe<Scalars['String']['output']>
  userId: Scalars['ID']['output']
}

export type NotificationListResponse = {
  __typename?: 'NotificationListResponse'
  items: Array<Maybe<Notification>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type Pat = {
  __typename?: 'PAT'
  createdAt?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  /**  Header List: Custom  */
  headerList?: Maybe<Array<HeaderKey>>
  lineList?: Maybe<Array<PatLine>>
  name?: Maybe<Scalars['String']['output']>
  patExport?: Maybe<Pat_Export>
  /**
   * patId will be different in the two logical separations.
   * (1) PAT Template will be searched by UserID, so we will begin with T for template and followed by UserUUID,
   * 8 characters of radiix 36 timestamp, and 5 characters of random augmented base64 characters.
   * This is in exception of global templates, where it will be T01 and T02 and so on.
   * (2) PAT Instantiation will be searched by JobID, so we will begin with I for instantiation, followed by ProjectID, and JobID.
   */
  patId: Scalars['String']['output']
  /** [Nested] revisionNo */
  revisionNo?: Maybe<Scalars['Int']['output']>
  /** A status for PAT Instantiations, chosen between WAIT_FILL/WAIT_APPROVAL/ACCEPT_ALL/REJECT_ALL/PARTIAL_REJECT */
  status?: Maybe<Pat_Status>
  /**
   * Custom Title Page. Is the Bayface of AIS in every PAT.
   * (1) If Pat Template: will have only titleTemplateId in DB, but maybe will return all with nesting.
   * (2) If Pat Instantiate: will bring the latest version of the titlePage here!
   */
  titleTemplate?: Maybe<TitleTemplate>
  updatedAt?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['String']['output']>
  versionNo?: Maybe<Scalars['Int']['output']>
}

/** A Single line in a PAT */
export type PatLine = {
  __typename?: 'PATLine'
  /** This is a string because it could be the String PAT, we could actually parseInt before returning but I'm lazy */
  lineNo: Scalars['String']['output']
  /** Line Type will be chosen between Text/Image/Upload/Approve/Group */
  lineType: Line_Type
  /**
   * In PAT Instantiation, value will have various types depending on the lineType
   * Text: This would be a normal Text
   * Image: This would be s3 urls delimited by a comma (,)
   * Upload: An UploadLine type
   * Approve: This would be a text written "Approve"
   * Group: This would be another list PATLine in lineList
   */
  value?: Maybe<PatLineValue>
}

export type PatLineValue =
  | ApproveLineValue
  | GroupLineValue
  | StringLineValue
  | UploadAntenna
  | UploadChecklist
  | UploadGuyedLegs
  | UploadLineValue
  | UploadMeasure

export enum PatStatusType {
  Accept = 'ACCEPT',
  Installed = 'INSTALLED',
  ProjectOrder = 'PROJECT_ORDER',
  Reject = 'REJECT',
  TotalSubmit = 'TOTAL_SUBMIT',
  WaitApproval = 'WAIT_APPROVAL',
  WaitFill = 'WAIT_FILL',
}

export enum Pat_Export {
  LongForm = 'LONG_FORM',
  LongFormPoe = 'LONG_FORM_POE',
  SplitPage = 'SPLIT_PAGE',
}

export enum Pat_Status {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
  WaitApproval = 'WAIT_APPROVAL',
  WaitFill = 'WAIT_FILL',
}

export enum Project_Status {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Public = 'PUBLIC',
}

/** PlanOrActualDateReport -> WorkTypeTable: [{acceptedUserId: "ASD", quantity: 10}] */
export type PlanOrActualDateReport = {
  __typename?: 'PlanOrActualDateReport'
  fieldID?: Maybe<Scalars['ID']['output']>
  groupFieldList?: Maybe<Array<PlanOrActualGroupField>>
  /** Update everytime the updateMasterPlan is Triggered */
  updatedAt?: Maybe<Scalars['String']['output']>
}

/**
 * PlanOrActualDateReportByAcceptedUserId -> Return WorkTypeTable: Int
 * (Same as above but with arguments!!!)
 */
export type PlanOrActualDateReportByAcceptedUserId = {
  __typename?: 'PlanOrActualDateReportByAcceptedUserId'
  fieldID?: Maybe<Scalars['ID']['output']>
  groupFieldList?: Maybe<Array<PlanOrActualGroupFieldByAcceptedUserId>>
  /** Update everytime the updateMasterPlan is Triggered */
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type PlanOrActualGroupField = {
  __typename?: 'PlanOrActualGroupField'
  fieldValue?: Maybe<Scalars['String']['output']>
  workTypeList?: Maybe<Array<PlanOrActualWorkType>>
}

export type PlanOrActualGroupFieldAcceptByUserId = {
  __typename?: 'PlanOrActualGroupFieldAcceptByUserId'
  fieldValue?: Maybe<Scalars['String']['output']>
  workTypeList?: Maybe<Array<PlanOrActualWorkTypeAcceptByUserId>>
}

export type PlanOrActualGroupFieldByAcceptedUserId = {
  __typename?: 'PlanOrActualGroupFieldByAcceptedUserId'
  fieldValue?: Maybe<Scalars['String']['output']>
  workTypeList?: Maybe<Array<PlanOrActualWorkTypeByAcceptedUserId>>
}

export type PlanOrActualWorkType = {
  __typename?: 'PlanOrActualWorkType'
  workType: Scalars['String']['output']
  workTypeTable: WorkTypeTable
}

export type PlanOrActualWorkTypeAcceptByUserId = {
  __typename?: 'PlanOrActualWorkTypeAcceptByUserId'
  workType: Scalars['String']['output']
  workTypeTable: WorkTypeTable
}

export type PlanOrActualWorkTypeByAcceptedUserId = {
  __typename?: 'PlanOrActualWorkTypeByAcceptedUserId'
  workType: Scalars['String']['output']
  workTypeTable: WorkTypeTableByAcceptUserId
}

export type Post = {
  __typename?: 'Post'
  companyName?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdByUserID: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  fileAttachmentList?: Maybe<Array<Scalars['String']['output']>>
  imageURLList?: Maybe<Array<Scalars['String']['output']>>
  jobApplication?: Maybe<Array<JobApplication>>
  postID?: Maybe<Scalars['ID']['output']>
  postSortBy?: Maybe<Scalars['Date']['output']>
  project?: Maybe<Project>
  projectID?: Maybe<Scalars['ID']['output']>
  startDate?: Maybe<Scalars['String']['output']>
  status?: Maybe<EStatus>
  title?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user?: Maybe<User>
  workTypePostList?: Maybe<Array<WorkTypePost>>
}

export type PostQuery = {
  __typename?: 'PostQuery'
  items?: Maybe<Array<Post>>
  nextToken?: Maybe<Scalars['String']['output']>
}

/**
 * Project is a return value with many job list.
 * From a two-way indexing of the Key-Value Database of ProjectID and JobID
 */
export type Project = {
  __typename?: 'Project'
  /** One TOR File in S3 String */
  TORFile?: Maybe<Scalars['String']['output']>
  /**   acceptedUserIdList   */
  acceptedUserIdList?: Maybe<Array<ChangId>>
  /**   actualDateReport   */
  actualDateReport?: Maybe<PlanOrActualDateReport>
  actualDateReportByAcceptedUserId?: Maybe<PlanOrActualDateReportByAcceptedUserId>
  /**
   * Nested Average Revision per Technician
   * # Avg Revision per Technician: { [techID1]: #revision1/#sitecount1,   [techID2]: #revision2/#sitecount2, ...}
   */
  averageRevisionTechnician?: Maybe<Scalars['JSON']['output']>
  /**   Field ID to use with Trigger PC 1.1 & PC 1.2   */
  configReportFieldID?: Maybe<Scalars['ID']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  /** คนที่สร้าง project นี้ */
  createdByUserId: Scalars['ID']['output']
  /**
   * Optional Custom Fields
   * AIS would use this Custom Field for their own AISRegion, for example.
   */
  customFieldList?: Maybe<Array<CustomField>>
  /**   Edit fieldID: Need if want to edit fieldValue   */
  editFieldID?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  isPassport?: Maybe<Scalars['Boolean']['output']>
  /** All the jobs in the Project */
  jobList?: Maybe<Array<Job>>
  /**   MasterPlan for that particular Year   */
  masterPlan: MasterPlan
  /** masterPlanAll will be { year1: MasterPlan1, year2: MasterPlan2 } */
  masterPlanAll?: Maybe<Scalars['JSON']['output']>
  /**   masterPlanByAcceptUserId   */
  masterPlanByAcceptUserId: MasterPlanByAcceptUserId
  masterPlanByOneCell: MasterPlan
  maxPrice?: Maybe<Scalars['Float']['output']>
  /** Nested Fields */
  minPrice?: Maybe<Scalars['Float']['output']>
  /**   planDateReport   */
  planDateReport?: Maybe<PlanOrActualDateReport>
  planDateReportAcceptByUserId?: Maybe<PlanDateReportAcceptByUserId>
  planDateReportByAcceptedUserId?: Maybe<PlanOrActualDateReportByAcceptedUserId>
  projectDescription?: Maybe<Scalars['String']['output']>
  /** The Project ID, consisting of 'P' and a unique 13 digit identifier. */
  projectId: Scalars['ID']['output']
  projectManagerName?: Maybe<Scalars['String']['output']>
  projectManagerPosition?: Maybe<Scalars['String']['output']>
  projectName: Scalars['String']['output']
  /**  Revision per Technician: { [techID1]: #revision1,   [techID2]: #revision2, ...}   */
  revisionCountTechnician?: Maybe<Scalars['JSON']['output']>
  /**   Revision per WorkType: { [workType1]: #revision1,   [workType2]: #revision2, ...}   */
  revisionCountWorkType?: Maybe<Scalars['JSON']['output']>
  /**   graph   */
  revisionDashBoard?: Maybe<Array<Maybe<RevisionDashBoard>>>
  /**  Revision frequency: { 1: #revisionCount,   2: #revisionCount, ...}   */
  revisionHistogram?: Maybe<Scalars['JSON']['output']>
  /**  site Count per Technician: { [techID1]: #sitecount1,   [techID2]: #sitecount2, ...}   */
  siteCountTechnician?: Maybe<Scalars['JSON']['output']>
  /** Start date and End Date of the Project */
  startDate?: Maybe<Scalars['String']['output']>
  status: Project_Status
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  /** All the ประเภทงาน/Work Types in the Project */
  workTypeList?: Maybe<Array<WorkType>>
}

/**
 * Project is a return value with many job list.
 * From a two-way indexing of the Key-Value Database of ProjectID and JobID
 */
export type ProjectActualDateReportByAcceptedUserIdArgs = {
  acceptedUserId: Scalars['ID']['input']
}

/**
 * Project is a return value with many job list.
 * From a two-way indexing of the Key-Value Database of ProjectID and JobID
 */
export type ProjectMasterPlanArgs = {
  planningYear: Scalars['String']['input']
}

/**
 * Project is a return value with many job list.
 * From a two-way indexing of the Key-Value Database of ProjectID and JobID
 */
export type ProjectMasterPlanByAcceptUserIdArgs = {
  acceptedUserId: Scalars['ID']['input']
  planningYear: Scalars['String']['input']
}

/**
 * Project is a return value with many job list.
 * From a two-way indexing of the Key-Value Database of ProjectID and JobID
 */
export type ProjectMasterPlanByOneCellArgs = {
  fieldID?: InputMaybe<Scalars['String']['input']>
  fieldValue?: InputMaybe<Scalars['String']['input']>
  planningYear: Scalars['String']['input']
  week?: InputMaybe<Scalars['String']['input']>
  workType: Scalars['String']['input']
}

/**
 * Project is a return value with many job list.
 * From a two-way indexing of the Key-Value Database of ProjectID and JobID
 */
export type ProjectPlanDateReportByAcceptedUserIdArgs = {
  acceptedUserId: Scalars['ID']['input']
}

export type PutCustomFieldInput = {
  /** An Enum of Allowed Values */
  allowedValueList: Array<Scalars['String']['input']>
  /** The name of the custom Field */
  name: Scalars['String']['input']
}

export type PutGroupField = {
  fieldValue: Scalars['String']['input']
  workTypeList?: InputMaybe<Array<PutMasterPlanWorkType>>
}

export type PutIssueInput = {
  createdByUserId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  topic: Scalars['String']['input']
}

export type PutJobsInput = {
  /** Edit fieldID: Need if want to edit fieldValue */
  editFieldID?: InputMaybe<Scalars['String']['input']>
  /** All the jobs in the Project */
  jobList: Array<InputMaybe<UpdateJobInput>>
  projectId: Scalars['ID']['input']
}

export type PutMasterPlan = {
  fieldID?: InputMaybe<Scalars['ID']['input']>
  groupFieldList?: InputMaybe<Array<PutGroupField>>
  planningYear: Scalars['String']['input']
  /** Required Attributes. project will have masterPlan: { year1: MasterPlan1, year2: MasterPlan2} */
  projectId: Scalars['ID']['input']
}

export type PutMasterPlanOneCell = {
  groupFieldIndex: Scalars['Int']['input']
  planningYear: Scalars['String']['input']
  projectId: Scalars['ID']['input']
  week?: InputMaybe<Array<PutUserInWeek>>
  weekNumber: Scalars['String']['input']
  workTypeIndex: Scalars['Int']['input']
}

export type PutMasterPlanWorkType = {
  workType: Scalars['String']['input']
  workTypeTable: PutWorkTypeTable
}

export type PutPatInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  headerList?: InputMaybe<Array<HeaderKeyInput>>
  lineList?: InputMaybe<Array<CreatePatLineInput>>
  name?: InputMaybe<Scalars['String']['input']>
  patExport?: InputMaybe<Pat_Export>
  patId: Scalars['String']['input']
  status?: InputMaybe<Pat_Status>
  titleTemplate?: InputMaybe<PutTitleTemplateInput>
  updatedAt?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['String']['input']>
  versionNo?: InputMaybe<Scalars['Int']['input']>
}

export type PutPatLineValueInput = {
  AB?: InputMaybe<Scalars['Float']['input']>
  BC?: InputMaybe<Scalars['Float']['input']>
  CA?: InputMaybe<Scalars['Float']['input']>
  CD?: InputMaybe<Scalars['Float']['input']>
  CTA?: InputMaybe<Scalars['Float']['input']>
  CTB?: InputMaybe<Scalars['Float']['input']>
  CTC?: InputMaybe<Scalars['Float']['input']>
  CTD?: InputMaybe<Scalars['Float']['input']>
  DA?: InputMaybe<Scalars['Float']['input']>
  approvalRemark?: InputMaybe<Scalars['String']['input']>
  checkTolerance?: InputMaybe<Scalars['Boolean']['input']>
  columnList?: InputMaybe<Array<Scalars['String']['input']>>
  data?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Any']['input']>>>>>
  defaultProperty?: InputMaybe<Scalars['String']['input']>
  dropdown1?: InputMaybe<Array<Scalars['String']['input']>>
  dropdown2?: InputMaybe<Array<Scalars['String']['input']>>
  dropdown3?: InputMaybe<Array<Scalars['String']['input']>>
  end?: InputMaybe<Scalars['Float']['input']>
  equation?: InputMaybe<Scalars['String']['input']>
  interval?: InputMaybe<Scalars['Float']['input']>
  isApproved?: InputMaybe<Scalars['Boolean']['input']>
  isDetail?: InputMaybe<Scalars['Boolean']['input']>
  isNeedAfter?: InputMaybe<Scalars['Boolean']['input']>
  isNeedBefore?: InputMaybe<Scalars['Boolean']['input']>
  levelRow?: InputMaybe<Scalars['Int']['input']>
  lineList?: InputMaybe<Array<InputMaybe<CreatePatLineInput>>>
  maxTolerance?: InputMaybe<Scalars['String']['input']>
  maximumFileNumber?: InputMaybe<Scalars['Int']['input']>
  maximumFileSize?: InputMaybe<Scalars['Int']['input']>
  minTolerance?: InputMaybe<Scalars['String']['input']>
  numberLegs?: InputMaybe<Scalars['String']['input']>
  poleList?: InputMaybe<Array<AntennaPoleInput>>
  poleType?: InputMaybe<Tower_Type>
  remarkProperty?: InputMaybe<Scalars['String']['input']>
  required?: InputMaybe<Scalars['Boolean']['input']>
  rowList?: InputMaybe<Array<ChecklistRowInput>>
  start?: InputMaybe<Scalars['Float']['input']>
  text?: InputMaybe<Scalars['String']['input']>
  uploadAfterValue?: InputMaybe<Scalars['String']['input']>
  uploadBeforeValue?: InputMaybe<Scalars['String']['input']>
  uploadType?: InputMaybe<Scalars['String']['input']>
  userVariable?: InputMaybe<Array<UserVariableInput>>
}

export type PutProjectInput = {
  /**   One TOR File in S3 String   */
  TORFile?: InputMaybe<Scalars['String']['input']>
  configReportFieldID?: InputMaybe<Scalars['ID']['input']>
  /**   คนที่สร้าง project นี้   */
  createdByUserId?: InputMaybe<Scalars['ID']['input']>
  /**
   * Optional Custom Fields
   * AIS would use this Custom Field for their own AISRegion, for example.
   */
  customFieldList?: InputMaybe<Array<PutCustomFieldInput>>
  /**   Edit fieldID: Need if want to edit fieldValue   */
  editFieldID?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  /**   All the jobs in the Project   */
  jobList?: InputMaybe<Array<UpdateJobInput>>
  projectDescription?: InputMaybe<Scalars['String']['input']>
  projectId: Scalars['ID']['input']
  projectManagerName?: InputMaybe<Scalars['String']['input']>
  projectManagerPosition?: InputMaybe<Scalars['String']['input']>
  projectName: Scalars['String']['input']
  /**   Start date and End Date of the Project   */
  startDate?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Project_Status>
  /**   All the ประเภทงาน/Work Types in the Project   */
  workTypeList?: InputMaybe<Array<UpdateWorkTypeInput>>
}

export type PutTitleTemplateInput = {
  attributeList?: InputMaybe<Array<InputMaybe<CreateTitleAttributeInput>>>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  titleTemplateId: Scalars['ID']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type PutTotalJobInput = {
  balanceQty?: InputMaybe<Scalars['Int']['input']>
  fieldValue: Scalars['String']['input']
  totalQty?: InputMaybe<Scalars['Int']['input']>
}

export type PutUserInWeek = {
  acceptedUserId: Scalars['ID']['input']
  quantity?: InputMaybe<Scalars['Int']['input']>
}

export type PutWorkTypePostInput = {
  fieldID?: InputMaybe<Scalars['String']['input']>
  isLinked?: InputMaybe<Scalars['Boolean']['input']>
  jobList?: InputMaybe<Array<Scalars['ID']['input']>>
  totalJobList?: InputMaybe<Array<PutTotalJobInput>>
  workType: Scalars['String']['input']
}

export type PutWorkTypeTable = {
  week_1?: InputMaybe<Array<PutUserInWeek>>
  week_2?: InputMaybe<Array<PutUserInWeek>>
  week_3?: InputMaybe<Array<PutUserInWeek>>
  week_4?: InputMaybe<Array<PutUserInWeek>>
  week_5?: InputMaybe<Array<PutUserInWeek>>
  week_6?: InputMaybe<Array<PutUserInWeek>>
  week_7?: InputMaybe<Array<PutUserInWeek>>
  week_8?: InputMaybe<Array<PutUserInWeek>>
  week_9?: InputMaybe<Array<PutUserInWeek>>
  week_10?: InputMaybe<Array<PutUserInWeek>>
  week_11?: InputMaybe<Array<PutUserInWeek>>
  week_12?: InputMaybe<Array<PutUserInWeek>>
  week_13?: InputMaybe<Array<PutUserInWeek>>
  week_14?: InputMaybe<Array<PutUserInWeek>>
  week_15?: InputMaybe<Array<PutUserInWeek>>
  week_16?: InputMaybe<Array<PutUserInWeek>>
  week_17?: InputMaybe<Array<PutUserInWeek>>
  week_18?: InputMaybe<Array<PutUserInWeek>>
  week_19?: InputMaybe<Array<PutUserInWeek>>
  week_20?: InputMaybe<Array<PutUserInWeek>>
  week_21?: InputMaybe<Array<PutUserInWeek>>
  week_22?: InputMaybe<Array<PutUserInWeek>>
  week_23?: InputMaybe<Array<PutUserInWeek>>
  week_24?: InputMaybe<Array<PutUserInWeek>>
  week_25?: InputMaybe<Array<PutUserInWeek>>
  week_26?: InputMaybe<Array<PutUserInWeek>>
  week_27?: InputMaybe<Array<PutUserInWeek>>
  week_28?: InputMaybe<Array<PutUserInWeek>>
  week_29?: InputMaybe<Array<PutUserInWeek>>
  week_30?: InputMaybe<Array<PutUserInWeek>>
  week_31?: InputMaybe<Array<PutUserInWeek>>
  week_32?: InputMaybe<Array<PutUserInWeek>>
  week_33?: InputMaybe<Array<PutUserInWeek>>
  week_34?: InputMaybe<Array<PutUserInWeek>>
  week_35?: InputMaybe<Array<PutUserInWeek>>
  week_36?: InputMaybe<Array<PutUserInWeek>>
  week_37?: InputMaybe<Array<PutUserInWeek>>
  week_38?: InputMaybe<Array<PutUserInWeek>>
  week_39?: InputMaybe<Array<PutUserInWeek>>
  week_40?: InputMaybe<Array<PutUserInWeek>>
  week_41?: InputMaybe<Array<PutUserInWeek>>
  week_42?: InputMaybe<Array<PutUserInWeek>>
  week_43?: InputMaybe<Array<PutUserInWeek>>
  week_44?: InputMaybe<Array<PutUserInWeek>>
  week_45?: InputMaybe<Array<PutUserInWeek>>
  week_46?: InputMaybe<Array<PutUserInWeek>>
  week_47?: InputMaybe<Array<PutUserInWeek>>
  week_48?: InputMaybe<Array<PutUserInWeek>>
  week_49?: InputMaybe<Array<PutUserInWeek>>
  week_50?: InputMaybe<Array<PutUserInWeek>>
  week_51?: InputMaybe<Array<PutUserInWeek>>
  week_52?: InputMaybe<Array<PutUserInWeek>>
  week_53?: InputMaybe<Array<PutUserInWeek>>
}

export type Query = {
  __typename?: 'Query'
  adminGetUser?: Maybe<User>
  bark: Scalars['String']['output']
  /** Get current user from Token */
  getUser?: Maybe<User>
  getUserInTeam?: Maybe<User>
  issue?: Maybe<Job>
  job?: Maybe<Job>
  /** T3 Apply Job History for Technician */
  jobApplicationByMe?: Maybe<JobApplicationQuery>
  listAllUsers?: Maybe<Array<Maybe<User>>>
  /** NewsFeed.   [T] GSI --> PK: status,  SK: postSortBy */
  listPosts?: Maybe<Array<Maybe<Post>>>
  listPostsForFix?: Maybe<Array<Maybe<Post>>>
  /** List SafetyPassport By user [LOGIN] */
  listSafetyPassportByUser?: Maybe<Array<Maybe<SafetyPassport>>>
  /** List SafetyPassport By user [In Team] */
  listSafetyPassportByUserInTeam?: Maybe<Array<Maybe<SafetyPassport>>>
  listUserByUsername?: Maybe<Array<Maybe<User>>>
  listUserVerficationByStatus?: Maybe<UserVerficationQuery>
  listUsers?: Maybe<Array<Maybe<User>>>
  man?: Maybe<Scalars['String']['output']>
  notification: Notification
  notificationByUserId?: Maybe<NotificationListResponse>
  /**
   * There are two logical separations of the PAT: (1) PAT Template and (2) PAT Instantiation.
   * Users can create their own PAT Template. Each Job after project creation will instantiate the PAT Template.
   */
  pat: Array<Maybe<Pat>>
  patByUserId: Array<Maybe<Pat>>
  post?: Maybe<Post>
  /** NewsFeed.   [C] GSI --> PK: createdByUserID, SK: postSortBy */
  postByCreatedByUserID?: Maybe<Array<Maybe<Post>>>
  /** Job Accept. [C] GSI --> PK: projectID, SK: postID */
  postByProjectID?: Maybe<Array<Maybe<Post>>>
  /** There are two types of project List: ผู้จ้าง Project List and ช่าง Project List. */
  project?: Maybe<Project>
  /** This is required for technician to see which jobs they selected */
  projectByAcceptedUserId?: Maybe<Array<Maybe<Project>>>
  /**
   * NOte: THis WILL get Project ID,JOB  since there IS status in the Job!!! DO we change to statusProject and statusJob???
   * But when query we wont get since we filter status on it's own, but the problem is statusJob will cloud the Index too!!!
   */
  projectByStatus?: Maybe<Array<Maybe<Project>>>
  /** NOte: THis will only get Project ID since there is not createdByUserId in the Job!!! */
  projectByUserId?: Maybe<Array<Maybe<Project>>>
  projectWithoutJob?: Maybe<Project>
  /** For PC1.2 PAT Status Report */
  reportPATStatus?: Maybe<Array<Maybe<ReportPatStatus>>>
  shark?: Maybe<Scalars['String']['output']>
  /**
   * Custom Title Page. Is the Bayface of AIS in every PAT.
   * (1) If Pat Template: will have only titleTemplateId in DB, but maybe will return all with nesting.
   * (2) If Pat Instantiate: will bring the latest version of the titlePage here!
   */
  titleTemplate?: Maybe<TitleTemplate>
  titleTemplateByUserId: Array<Maybe<TitleTemplate>>
  userVerification?: Maybe<UserVerification>
}

export type QueryAdminGetUserArgs = {
  userId: Scalars['ID']['input']
}

export type QueryGetUserInTeamArgs = {
  userId: Scalars['ID']['input']
}

export type QueryIssueArgs = {
  changTumId: Scalars['ID']['input']
  topic: Scalars['ID']['input']
}

export type QueryJobArgs = {
  changTumId: Scalars['ID']['input']
  projectId: Scalars['ID']['input']
}

export type QueryJobApplicationByMeArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListPostsArgs = {
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListPostsForFixArgs = {
  filter?: InputMaybe<ModelPostFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListSafetyPassportByUserInTeamArgs = {
  userId: Scalars['ID']['input']
}

export type QueryListUserByUsernameArgs = {
  username: Scalars['ID']['input']
}

export type QueryListUserVerficationByStatusArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  sortDirection?: InputMaybe<ModelSortDirection>
  status: EStatusVerification
}

export type QueryNotificationArgs = {
  notificationId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type QueryNotificationByUserIdArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['ID']['input']
}

export type QueryPatArgs = {
  beginsWith?: InputMaybe<Scalars['String']['input']>
  patId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPatByUserIdArgs = {
  beginsWith?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['ID']['input']
}

export type QueryPostArgs = {
  postID: Scalars['ID']['input']
}

export type QueryPostByCreatedByUserIdArgs = {
  createdByUserID: Scalars['ID']['input']
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryPostByProjectIdArgs = {
  projectID: Scalars['ID']['input']
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryProjectArgs = {
  jobFilter?: InputMaybe<Scalars['JSON']['input']>
  projectId: Scalars['ID']['input']
}

export type QueryProjectByAcceptedUserIdArgs = {
  acceptedUserId: Scalars['ID']['input']
}

export type QueryProjectByStatusArgs = {
  status: Scalars['String']['input']
}

export type QueryProjectByUserIdArgs = {
  userId: Scalars['ID']['input']
}

export type QueryProjectWithoutJobArgs = {
  projectId: Scalars['ID']['input']
}

export type QueryReportPatStatusArgs = {
  projectId: Scalars['ID']['input']
}

export type QueryTitleTemplateArgs = {
  titleTemplateId: Scalars['ID']['input']
}

export type QueryTitleTemplateByUserIdArgs = {
  userId: Scalars['ID']['input']
}

export type QueryUserVerificationArgs = {
  userId: Scalars['ID']['input']
}

export type RedoPatInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  headerList?: InputMaybe<Array<HeaderKeyInput>>
  lineList?: InputMaybe<Array<InputMaybe<CreatePatLineInput>>>
  name?: InputMaybe<Scalars['String']['input']>
  patExport?: InputMaybe<Pat_Export>
  patId: Scalars['String']['input']
  status?: InputMaybe<Pat_Status>
  titleTemplate?: InputMaybe<PutTitleTemplateInput>
  updatedAt?: InputMaybe<Scalars['String']['input']>
}

export type ReportPatStatus = {
  __typename?: 'ReportPATStatus'
  PATStatusType?: Maybe<PatStatusType>
  fieldID?: Maybe<Scalars['String']['output']>
  fieldValue?: Maybe<Scalars['JSON']['output']>
  /** Check projectID */
  projectId: Scalars['ID']['output']
}

/** ##################################### */
export type RevisionDashBoard = {
  __typename?: 'RevisionDashBoard'
  revisionPerSite?: Maybe<Array<Maybe<RevisionValues>>>
  revisionTechnician?: Maybe<Array<Maybe<RevisionValues>>>
  revisionTechnicianAveragePerSite?: Maybe<Array<Maybe<RevisionValues>>>
  revisionWorkType?: Maybe<Array<Maybe<RevisionValues>>>
}

export type RevisionValues = {
  __typename?: 'RevisionValues'
  /** technician, workType, siteCode */
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  number?: Maybe<Scalars['Int']['output']>
}

export type SafetyPassport = {
  __typename?: 'SafetyPassport'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  safetyPassportFileAttachment?: Maybe<Scalars['String']['output']>
  safetyPassportStatus?: Maybe<ESafetyPassporyStatus>
  safetyPassportType?: Maybe<ESafetyPassport>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  /** for [NESTED] */
  userId?: Maybe<Scalars['ID']['output']>
}

export type SafetyPassportInput = {
  safetyPassportFileAttachment?: InputMaybe<Scalars['String']['input']>
  safetyPassportType?: InputMaybe<ESafetyPassport>
}

export type SafetyPassportUpdate = {
  safetyPassportStatus?: InputMaybe<ESafetyPassporyStatus>
  safetyPassportType: ESafetyPassport
  userId: Scalars['ID']['input']
}

export type SafetyPassportUserInTeamInput = {
  safetyPassportFileAttachment?: InputMaybe<Scalars['String']['input']>
  safetyPassportType?: InputMaybe<ESafetyPassport>
}

export type StringLineValue = {
  __typename?: 'StringLineValue'
  text: Scalars['String']['output']
}

export enum Tower_Type {
  Concretepole = 'CONCRETEPOLE',
  Guyed = 'GUYED',
  Monopole = 'MONOPOLE',
  Selfsupport = 'SELFSUPPORT',
  Steelpipe = 'STEELPIPE',
}

export type TitleAttribute = {
  __typename?: 'TitleAttribute'
  /** Custom Attribute Name */
  attributeName: Scalars['String']['output']
  /** Attribute Type will be chosen between Text/Image/Upload/Approve/Group, But title template should ONLY have Upload */
  attributeType?: Maybe<Line_Type>
  /** UPLOAD: This can be both IMAGE OR TEXT! */
  value?: Maybe<TitleAttributeValue>
}

export type TitleAttributeValue = StringLineValue | UploadLineValue

export type TitleTemplate = {
  __typename?: 'TitleTemplate'
  /** Similar to lineList, with changed attribute name. Contains all the custom attributed needed. */
  attributeList?: Maybe<Array<TitleAttribute>>
  description?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  titleTemplateId: Scalars['ID']['output']
  userId?: Maybe<Scalars['ID']['output']>
}

export type TotalJob = {
  __typename?: 'TotalJob'
  /** [Trigger] Total amount approved in that fieldValue. Should equal to total-balance */
  approveQty?: Maybe<Scalars['Int']['output']>
  /** [Trigger] Total amount of job REMAINING to apply in that fieldValue */
  balanceQty?: Maybe<Scalars['Int']['output']>
  fieldValue: Scalars['String']['output']
  /** Total amount of job in that fieldValue */
  totalQty?: Maybe<Scalars['Int']['output']>
}

export type TotalJobApplication = {
  __typename?: 'TotalJobApplication'
  approveQty?: Maybe<Scalars['Int']['output']>
  fieldValue?: Maybe<Scalars['String']['output']>
  registerQty?: Maybe<Scalars['Int']['output']>
}

export enum Upload_Type {
  Antenna = 'ANTENNA',
  /** Additional Types */
  Checklist = 'CHECKLIST',
  File = 'FILE',
  Guyedlegs = 'GUYEDLEGS',
  Image = 'IMAGE',
  Measure = 'MEASURE',
  Pdf = 'PDF',
  Sound = 'SOUND',
  Text = 'TEXT',
  Video = 'VIDEO',
}

export type UpdateJobInput = {
  /** Subcontractor/Organization that accepts */
  acceptedUserId?: InputMaybe<Scalars['ID']['input']>
  actualDate?: InputMaybe<Scalars['String']['input']>
  /**   "Addition 1   */
  addition1?: InputMaybe<Scalars['String']['input']>
  /**   "Addition 2   */
  addition2?: InputMaybe<Scalars['String']['input']>
  applicationID?: InputMaybe<Scalars['String']['input']>
  /** Assigned ช่าง inside that organization */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>
  /** เบอร์โทรติดต่อของช่าง */
  assignedUserPhone?: InputMaybe<Scalars['ID']['input']>
  changTumId: Scalars['ID']['input']
  /**   รายละเอียด   */
  description?: InputMaybe<Scalars['String']['input']>
  dimension?: InputMaybe<Scalars['String']['input']>
  district?: InputMaybe<Scalars['String']['input']>
  /**   Update [Nested] Custom Field of Job: This value is get from project.editFieldID!!!!   */
  fieldValue?: InputMaybe<Scalars['String']['input']>
  gateWidth?: InputMaybe<Scalars['String']['input']>
  googleMap?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['String']['input']>
  installer?: InputMaybe<Scalars['String']['input']>
  installerDate?: InputMaybe<Scalars['String']['input']>
  /**   all user can บันทึกปัญหาหน้างาน   */
  issueList?: InputMaybe<Array<PutIssueInput>>
  jobId?: InputMaybe<Scalars['ID']['input']>
  lat?: InputMaybe<Scalars['String']['input']>
  locationID?: InputMaybe<Scalars['String']['input']>
  long?: InputMaybe<Scalars['String']['input']>
  manufacturer?: InputMaybe<Scalars['String']['input']>
  manufacturerDate?: InputMaybe<Scalars['String']['input']>
  number?: InputMaybe<Scalars['String']['input']>
  /**   Plan Date and Actual Date   */
  planDate?: InputMaybe<Scalars['String']['input']>
  projectId?: InputMaybe<Scalars['ID']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  region?: InputMaybe<Scalars['String']['input']>
  /**   หมายเหตุ   */
  remark?: InputMaybe<Scalars['String']['input']>
  room?: InputMaybe<Scalars['String']['input']>
  siteArea?: InputMaybe<Scalars['String']['input']>
  siteCode?: InputMaybe<Scalars['String']['input']>
  siteName?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Job_Status>
  towerSubType?: InputMaybe<Scalars['String']['input']>
  towerType?: InputMaybe<Tower_Type>
  workType?: InputMaybe<Scalars['String']['input']>
  zone?: InputMaybe<Scalars['String']['input']>
}

export type UpdateNotificationInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>
  iconUrl?: InputMaybe<Scalars['String']['input']>
  isRead?: InputMaybe<Scalars['Boolean']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  notificationId: Scalars['ID']['input']
  toLink?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['ID']['input']
}

export type UpdateTotalJobApplication = {
  approveQty?: InputMaybe<Scalars['Int']['input']>
  fieldValue?: InputMaybe<Scalars['String']['input']>
  registerQty?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateUserVerificationInput = {
  personalIDFileAttachment?: InputMaybe<Scalars['String']['input']>
  selfiePersonalIDFileAttachment?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<EStatusVerification>
  userId: Scalars['ID']['input']
}

export type UpdateWorkTypeInput = {
  imageURLList?: InputMaybe<Array<Scalars['String']['input']>>
  isNoPrice?: InputMaybe<Scalars['Boolean']['input']>
  isSafetyPassport?: InputMaybe<Scalars['Boolean']['input']>
  jobUnitPrice?: InputMaybe<Scalars['Float']['input']>
  lineNumber?: InputMaybe<Scalars['Int']['input']>
  patTemplateId?: InputMaybe<Scalars['ID']['input']>
  requirements?: InputMaybe<Scalars['String']['input']>
  safetyPassport?: InputMaybe<Array<InputMaybe<ESafetyPassport>>>
  workType: Scalars['String']['input']
}

export type UpdateWorkTypeJobApplication = {
  fieldID?: InputMaybe<Scalars['String']['input']>
  jobList?: InputMaybe<Array<Scalars['ID']['input']>>
  totalJobList?: InputMaybe<Array<UpdateTotalJobApplication>>
  workType: Scalars['String']['input']
}

/** For Antenna UploadType */
export type UploadAntenna = {
  __typename?: 'UploadAntenna'
  isDetail?: Maybe<Scalars['Boolean']['output']>
  poleList?: Maybe<Array<AntennaPole>>
  poleType: Tower_Type
  text: Scalars['String']['output']
  /** Upload Type will be ANTENNA */
  uploadType: Upload_Type
}

/** For Checklist UploadType */
export type UploadChecklist = {
  __typename?: 'UploadChecklist'
  columnList?: Maybe<Array<Scalars['String']['output']>>
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['Any']['output']>>>>>
  defaultProperty?: Maybe<Scalars['String']['output']>
  dropdown1?: Maybe<Array<Scalars['String']['output']>>
  dropdown2?: Maybe<Array<Scalars['String']['output']>>
  dropdown3?: Maybe<Array<Scalars['String']['output']>>
  equation?: Maybe<Scalars['String']['output']>
  levelRow?: Maybe<Scalars['Int']['output']>
  remarkProperty?: Maybe<Scalars['String']['output']>
  rowList?: Maybe<Array<ChecklistRowValue>>
  text: Scalars['String']['output']
  /** Upload Type will be CHECKLIST */
  uploadType: Upload_Type
}

/** For GuyedLegs UploadType */
export type UploadGuyedLegs = {
  __typename?: 'UploadGuyedLegs'
  AB?: Maybe<Scalars['Float']['output']>
  BC?: Maybe<Scalars['Float']['output']>
  CA?: Maybe<Scalars['Float']['output']>
  CD?: Maybe<Scalars['Float']['output']>
  CTA?: Maybe<Scalars['Float']['output']>
  CTB?: Maybe<Scalars['Float']['output']>
  CTC?: Maybe<Scalars['Float']['output']>
  CTD?: Maybe<Scalars['Float']['output']>
  DA?: Maybe<Scalars['Float']['output']>
  numberLegs?: Maybe<Scalars['String']['output']>
  text: Scalars['String']['output']
  /** Upload Type will be GUYEDLEGS */
  uploadType: Upload_Type
}

export type UploadLineValue = {
  __typename?: 'UploadLineValue'
  isNeedAfter?: Maybe<Scalars['Boolean']['output']>
  isNeedBefore?: Maybe<Scalars['Boolean']['output']>
  maximumFileNumber?: Maybe<Scalars['Int']['output']>
  maximumFileSize?: Maybe<Scalars['Int']['output']>
  /** Text to be shown during the input */
  text: Scalars['String']['output']
  uploadAfterValue?: Maybe<Scalars['String']['output']>
  /** In PAT Instantiation, upload value need to have uploadValue. S3 URL OR Text. */
  uploadBeforeValue?: Maybe<Scalars['String']['output']>
  /** Upload Type will be chosen between IMAGE/VIDEO/TEXT/PDF/SOUND/FILE */
  uploadType: Upload_Type
}

/** For Measure UploadType */
export type UploadMeasure = {
  __typename?: 'UploadMeasure'
  checkTolerance?: Maybe<Scalars['Boolean']['output']>
  columnList?: Maybe<Array<Scalars['String']['output']>>
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['Any']['output']>>>>>
  end?: Maybe<Scalars['Float']['output']>
  interval?: Maybe<Scalars['Float']['output']>
  maxTolerance?: Maybe<Scalars['String']['output']>
  minTolerance?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['Float']['output']>
  text: Scalars['String']['output']
  /** Upload Type will be MEASURE */
  uploadType: Upload_Type
  userVariable?: Maybe<Array<UserVariable>>
}

export type User = {
  __typename?: 'User'
  addressDetail?: Maybe<Scalars['String']['output']>
  birthDate?: Maybe<Scalars['String']['output']>
  companyBranch?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  coordinatorAddress?: Maybe<Scalars['String']['output']>
  coordinatorName?: Maybe<Scalars['String']['output']>
  coordinatorPersonalID?: Maybe<Scalars['String']['output']>
  coordinatorPhone?: Maybe<Scalars['String']['output']>
  /** Date */
  createdAt?: Maybe<Scalars['String']['output']>
  createdByUserId?: Maybe<Scalars['ID']['output']>
  customAttrs?: Maybe<Scalars['JSON']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  district?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  emergencyContact?: Maybe<EmergencyContact>
  firstName?: Maybe<Scalars['String']['output']>
  friendList?: Maybe<Array<Friend>>
  gender?: Maybe<EGender>
  isSafetyPassport?: Maybe<Scalars['Boolean']['output']>
  isVerification?: Maybe<Scalars['Boolean']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  logoUrl?: Maybe<Scalars['String']['output']>
  myWorkDescription?: Maybe<Scalars['String']['output']>
  myWorkFileAttachment?: Maybe<Array<Scalars['String']['output']>>
  myWorkImageList?: Maybe<Array<Scalars['String']['output']>>
  myWorkTeamName?: Maybe<Scalars['String']['output']>
  myWorkTechnicianJobType?: Maybe<Array<ETypeOfjob>>
  myWorkserviceProvince?: Maybe<Array<EThaiProvinces>>
  myWorkserviceRegion?: Maybe<Array<ERegion>>
  nickName?: Maybe<Scalars['String']['output']>
  password_hash?: Maybe<Scalars['String']['output']>
  personalID?: Maybe<Scalars['ID']['output']>
  personalRelation?: Maybe<EPersonalRelation>
  phone?: Maybe<Scalars['String']['output']>
  /** Profile Picture ** FOR THE FUTURE (WAIT Ui) */
  profilePictureAttachment?: Maybe<Scalars['String']['output']>
  province?: Maybe<EThaiProvinces>
  /** NESTED with [userId] */
  safetyPassport?: Maybe<Array<SafetyPassport>>
  serviceProvince?: Maybe<Array<EThaiProvinces>>
  serviceRegion?: Maybe<Array<ERegion>>
  status?: Maybe<EUserStatus>
  subDistrict?: Maybe<Scalars['String']['output']>
  taxID?: Maybe<Scalars['ID']['output']>
  technicianJobType?: Maybe<Array<ETypeOfjob>>
  technicianList?: Maybe<Array<UserId>>
  updatedAt?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['ID']['output']>
  userType?: Maybe<EUserType>
  userVerification?: Maybe<UserVerification>
  username?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type UserId = {
  __typename?: 'UserID'
  getUser?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type UserIdInput = {
  userId: Scalars['ID']['input']
}

export type UserInWeek = {
  __typename?: 'UserInWeek'
  acceptedUserId: Scalars['ID']['output']
  quantity?: Maybe<Scalars['Int']['output']>
}

export type UserLoginInput = {
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type UserVariable = {
  __typename?: 'UserVariable'
  name: Scalars['String']['output']
  value?: Maybe<Scalars['Float']['output']>
  varname: Scalars['String']['output']
}

export type UserVariableInput = {
  name: Scalars['String']['input']
  value?: InputMaybe<Scalars['Float']['input']>
  varname: Scalars['String']['input']
}

export type UserVerficationQuery = {
  __typename?: 'UserVerficationQuery'
  items: Array<UserVerification>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type UserVerification = {
  __typename?: 'UserVerification'
  companyCertificateFileAttachment?: Maybe<Scalars['String']['output']>
  coordinatorPersonalIDFileAttachment?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  personalIDFileAttachment?: Maybe<Scalars['String']['output']>
  selfieJuristicIDFileAttachment?: Maybe<Scalars['String']['output']>
  selfiePersonalIDFileAttachment?: Maybe<Scalars['String']['output']>
  status?: Maybe<EStatusVerification>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  userId?: Maybe<Scalars['ID']['output']>
  vatFileAttachment?: Maybe<Scalars['String']['output']>
}

export type WorkType = {
  __typename?: 'WorkType'
  imageURLList?: Maybe<Array<Scalars['String']['output']>>
  isNoPrice?: Maybe<Scalars['Boolean']['output']>
  isSafetyPassport?: Maybe<Scalars['Boolean']['output']>
  jobUnitPrice?: Maybe<Scalars['Float']['output']>
  lineNumber?: Maybe<Scalars['Int']['output']>
  patTemplateId?: Maybe<Scalars['ID']['output']>
  requirements?: Maybe<Scalars['String']['output']>
  safetyPassport?: Maybe<Array<Maybe<ESafetyPassport>>>
  /** Calculated Cell of the total job quantity */
  totalJobQty?: Maybe<Scalars['Int']['output']>
  workType: Scalars['String']['output']
}

export type WorkTypeInput = {
  isNoPrice?: InputMaybe<Scalars['Boolean']['input']>
  jobUnitPrice?: InputMaybe<Scalars['Float']['input']>
  lineNumber?: InputMaybe<Scalars['Int']['input']>
  patTemplateId: Scalars['String']['input']
  workType: Scalars['String']['input']
}

export type WorkTypeJobApplication = {
  __typename?: 'WorkTypeJobApplication'
  fieldID?: Maybe<Scalars['String']['output']>
  jobApplication?: Maybe<JobApplication>
  jobList?: Maybe<Array<Scalars['ID']['output']>>
  status?: Maybe<EJobApplicationStatus>
  totalJobList?: Maybe<Array<TotalJobApplication>>
  workType: Scalars['String']['output']
}

export type WorkTypeJobApplicationQuery = {
  __typename?: 'WorkTypeJobApplicationQuery'
  items: Array<WorkTypeJobApplication>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type WorkTypePost = {
  __typename?: 'WorkTypePost'
  fieldID?: Maybe<Scalars['String']['output']>
  isLinked?: Maybe<Scalars['Boolean']['output']>
  jobList?: Maybe<Array<Scalars['ID']['output']>>
  jobUnitPrice?: Maybe<Scalars['Float']['output']>
  /** [Nested Query] PC5 Accept Job: listJobApplication */
  listWorkTypeJobApplication?: Maybe<WorkTypeJobApplicationQuery>
  totalJobList?: Maybe<Array<TotalJob>>
  workType: Scalars['String']['output']
}

export type WorkTypePostListWorkTypeJobApplicationArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type WorkTypeTable = {
  __typename?: 'WorkTypeTable'
  week_1?: Maybe<Array<Maybe<UserInWeek>>>
  week_2?: Maybe<Array<Maybe<UserInWeek>>>
  week_3?: Maybe<Array<Maybe<UserInWeek>>>
  week_4?: Maybe<Array<Maybe<UserInWeek>>>
  week_5?: Maybe<Array<Maybe<UserInWeek>>>
  week_6?: Maybe<Array<Maybe<UserInWeek>>>
  week_7?: Maybe<Array<Maybe<UserInWeek>>>
  week_8?: Maybe<Array<Maybe<UserInWeek>>>
  week_9?: Maybe<Array<Maybe<UserInWeek>>>
  week_10?: Maybe<Array<Maybe<UserInWeek>>>
  week_11?: Maybe<Array<Maybe<UserInWeek>>>
  week_12?: Maybe<Array<Maybe<UserInWeek>>>
  week_13?: Maybe<Array<Maybe<UserInWeek>>>
  week_14?: Maybe<Array<Maybe<UserInWeek>>>
  week_15?: Maybe<Array<Maybe<UserInWeek>>>
  week_16?: Maybe<Array<Maybe<UserInWeek>>>
  week_17?: Maybe<Array<Maybe<UserInWeek>>>
  week_18?: Maybe<Array<Maybe<UserInWeek>>>
  week_19?: Maybe<Array<Maybe<UserInWeek>>>
  week_20?: Maybe<Array<Maybe<UserInWeek>>>
  week_21?: Maybe<Array<Maybe<UserInWeek>>>
  week_22?: Maybe<Array<Maybe<UserInWeek>>>
  week_23?: Maybe<Array<Maybe<UserInWeek>>>
  week_24?: Maybe<Array<Maybe<UserInWeek>>>
  week_25?: Maybe<Array<Maybe<UserInWeek>>>
  week_26?: Maybe<Array<Maybe<UserInWeek>>>
  week_27?: Maybe<Array<Maybe<UserInWeek>>>
  week_28?: Maybe<Array<Maybe<UserInWeek>>>
  week_29?: Maybe<Array<Maybe<UserInWeek>>>
  week_30?: Maybe<Array<Maybe<UserInWeek>>>
  week_31?: Maybe<Array<Maybe<UserInWeek>>>
  week_32?: Maybe<Array<Maybe<UserInWeek>>>
  week_33?: Maybe<Array<Maybe<UserInWeek>>>
  week_34?: Maybe<Array<Maybe<UserInWeek>>>
  week_35?: Maybe<Array<Maybe<UserInWeek>>>
  week_36?: Maybe<Array<Maybe<UserInWeek>>>
  week_37?: Maybe<Array<Maybe<UserInWeek>>>
  week_38?: Maybe<Array<Maybe<UserInWeek>>>
  week_39?: Maybe<Array<Maybe<UserInWeek>>>
  week_40?: Maybe<Array<Maybe<UserInWeek>>>
  week_41?: Maybe<Array<Maybe<UserInWeek>>>
  week_42?: Maybe<Array<Maybe<UserInWeek>>>
  week_43?: Maybe<Array<Maybe<UserInWeek>>>
  week_44?: Maybe<Array<Maybe<UserInWeek>>>
  week_45?: Maybe<Array<Maybe<UserInWeek>>>
  week_46?: Maybe<Array<Maybe<UserInWeek>>>
  week_47?: Maybe<Array<Maybe<UserInWeek>>>
  week_48?: Maybe<Array<Maybe<UserInWeek>>>
  week_49?: Maybe<Array<Maybe<UserInWeek>>>
  week_50?: Maybe<Array<Maybe<UserInWeek>>>
  week_51?: Maybe<Array<Maybe<UserInWeek>>>
  week_52?: Maybe<Array<Maybe<UserInWeek>>>
  week_53?: Maybe<Array<Maybe<UserInWeek>>>
}

export type WorkTypeTableByAcceptUserId = {
  __typename?: 'WorkTypeTableByAcceptUserId'
  week_1?: Maybe<Scalars['Int']['output']>
  week_2?: Maybe<Scalars['Int']['output']>
  week_3?: Maybe<Scalars['Int']['output']>
  week_4?: Maybe<Scalars['Int']['output']>
  week_5?: Maybe<Scalars['Int']['output']>
  week_6?: Maybe<Scalars['Int']['output']>
  week_7?: Maybe<Scalars['Int']['output']>
  week_8?: Maybe<Scalars['Int']['output']>
  week_9?: Maybe<Scalars['Int']['output']>
  week_10?: Maybe<Scalars['Int']['output']>
  week_11?: Maybe<Scalars['Int']['output']>
  week_12?: Maybe<Scalars['Int']['output']>
  week_13?: Maybe<Scalars['Int']['output']>
  week_14?: Maybe<Scalars['Int']['output']>
  week_15?: Maybe<Scalars['Int']['output']>
  week_16?: Maybe<Scalars['Int']['output']>
  week_17?: Maybe<Scalars['Int']['output']>
  week_18?: Maybe<Scalars['Int']['output']>
  week_19?: Maybe<Scalars['Int']['output']>
  week_20?: Maybe<Scalars['Int']['output']>
  week_21?: Maybe<Scalars['Int']['output']>
  week_22?: Maybe<Scalars['Int']['output']>
  week_23?: Maybe<Scalars['Int']['output']>
  week_24?: Maybe<Scalars['Int']['output']>
  week_25?: Maybe<Scalars['Int']['output']>
  week_26?: Maybe<Scalars['Int']['output']>
  week_27?: Maybe<Scalars['Int']['output']>
  week_28?: Maybe<Scalars['Int']['output']>
  week_29?: Maybe<Scalars['Int']['output']>
  week_30?: Maybe<Scalars['Int']['output']>
  week_31?: Maybe<Scalars['Int']['output']>
  week_32?: Maybe<Scalars['Int']['output']>
  week_33?: Maybe<Scalars['Int']['output']>
  week_34?: Maybe<Scalars['Int']['output']>
  week_35?: Maybe<Scalars['Int']['output']>
  week_36?: Maybe<Scalars['Int']['output']>
  week_37?: Maybe<Scalars['Int']['output']>
  week_38?: Maybe<Scalars['Int']['output']>
  week_39?: Maybe<Scalars['Int']['output']>
  week_40?: Maybe<Scalars['Int']['output']>
  week_41?: Maybe<Scalars['Int']['output']>
  week_42?: Maybe<Scalars['Int']['output']>
  week_43?: Maybe<Scalars['Int']['output']>
  week_44?: Maybe<Scalars['Int']['output']>
  week_45?: Maybe<Scalars['Int']['output']>
  week_46?: Maybe<Scalars['Int']['output']>
  week_47?: Maybe<Scalars['Int']['output']>
  week_48?: Maybe<Scalars['Int']['output']>
  week_49?: Maybe<Scalars['Int']['output']>
  week_50?: Maybe<Scalars['Int']['output']>
  week_51?: Maybe<Scalars['Int']['output']>
  week_52?: Maybe<Scalars['Int']['output']>
  week_53?: Maybe<Scalars['Int']['output']>
}

export type ChangId = {
  __typename?: 'changID'
  getUser?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type PlanDateReportAcceptByUserId = {
  __typename?: 'planDateReportAcceptByUserId'
  fieldID?: Maybe<Scalars['ID']['output']>
  groupFieldList?: Maybe<Array<PlanOrActualGroupFieldAcceptByUserId>>
  /** Update everytime the updateMasterPlan is Triggered */
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type UserChangPasswordInput = {
  confirmNewPassword?: InputMaybe<Scalars['String']['input']>
  currentPassword?: InputMaybe<Scalars['String']['input']>
  newPassword?: InputMaybe<Scalars['String']['input']>
}

export type UserInTeamInput = {
  addressDetail?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['String']['input']>
  customAttrs?: InputMaybe<Scalars['JSON']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<EGender>
  isSafetyPassport?: InputMaybe<Scalars['Boolean']['input']>
  isVerification?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  nickName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  personalID?: InputMaybe<Scalars['ID']['input']>
  personalRelation?: InputMaybe<EPersonalRelation>
  phone?: InputMaybe<Scalars['String']['input']>
  /** [userSafetyPassport] Input */
  safetyPassport?: InputMaybe<Array<InputMaybe<SafetyPassportInput>>>
  serviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  serviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  technicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  username?: InputMaybe<Scalars['String']['input']>
}

export type UserInput = {
  addressDetail?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['String']['input']>
  customAttrs?: InputMaybe<Scalars['JSON']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  friendList?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  gender?: InputMaybe<EGender>
  isSafetyPassport?: InputMaybe<Scalars['Boolean']['input']>
  isVerification?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  nickName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  personalID?: InputMaybe<Scalars['ID']['input']>
  personalRelation?: InputMaybe<EPersonalRelation>
  phone?: InputMaybe<Scalars['String']['input']>
  /** [userSafetyPassport] Input */
  safetyPassport?: InputMaybe<Array<InputMaybe<SafetyPassportInput>>>
  serviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  serviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  technicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  username?: InputMaybe<Scalars['String']['input']>
}

export type UserUpdateInput = {
  addressDetail?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['String']['input']>
  companyBranch?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  coordinatorAddress?: InputMaybe<Scalars['String']['input']>
  coordinatorName?: InputMaybe<Scalars['String']['input']>
  coordinatorPersonalID?: InputMaybe<Scalars['String']['input']>
  coordinatorPhone?: InputMaybe<Scalars['String']['input']>
  customAttrs?: InputMaybe<Scalars['JSON']['input']>
  district?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  friendList?: InputMaybe<Array<InputMaybe<FriendInput>>>
  gender?: InputMaybe<EGender>
  isSafetyPassport?: InputMaybe<Scalars['Boolean']['input']>
  isVerification?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  myWorkDescription?: InputMaybe<Scalars['String']['input']>
  myWorkFileAttachment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  myWorkImageList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  myWorkTeamName?: InputMaybe<Scalars['String']['input']>
  myWorkTechnicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  myWorkserviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  myWorkserviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  nickName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  personalID?: InputMaybe<Scalars['ID']['input']>
  personalRelation?: InputMaybe<EPersonalRelation>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<EThaiProvinces>
  /** [userSafetyPassport] Input */
  safetyPassport?: InputMaybe<Array<InputMaybe<SafetyPassportInput>>>
  serviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  serviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  subDistrict?: InputMaybe<Scalars['String']['input']>
  taxID?: InputMaybe<Scalars['ID']['input']>
  technicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  technicianList?: InputMaybe<Array<InputMaybe<UserIdInput>>>
  userType?: InputMaybe<EUserType>
  username?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}

export type UserUpdateProfilePicture = {
  profilePictureAttachment: Scalars['String']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type UserUpdateTeamInput = {
  addressDetail?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['String']['input']>
  companyBranch?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  coordinatorAddress?: InputMaybe<Scalars['String']['input']>
  coordinatorName?: InputMaybe<Scalars['String']['input']>
  coordinatorPersonalID?: InputMaybe<Scalars['String']['input']>
  coordinatorPhone?: InputMaybe<Scalars['String']['input']>
  customAttrs?: InputMaybe<Scalars['JSON']['input']>
  district?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  friendList?: InputMaybe<Array<InputMaybe<FriendInput>>>
  gender?: InputMaybe<EGender>
  isSafetyPassport?: InputMaybe<Scalars['Boolean']['input']>
  isVerification?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  myWorkDescription?: InputMaybe<Scalars['String']['input']>
  myWorkFileAttachment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  myWorkImageList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  myWorkTeamName?: InputMaybe<Scalars['String']['input']>
  myWorkTechnicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  myWorkserviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  myWorkserviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  nickName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  personalID?: InputMaybe<Scalars['ID']['input']>
  personalRelation?: InputMaybe<EPersonalRelation>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<EThaiProvinces>
  /** [userSafetyPassport] Input */
  safetyPassport?: InputMaybe<Array<InputMaybe<SafetyPassportInput>>>
  serviceProvince?: InputMaybe<Array<InputMaybe<EThaiProvinces>>>
  serviceRegion?: InputMaybe<Array<InputMaybe<ERegion>>>
  status?: InputMaybe<EUserStatus>
  subDistrict?: InputMaybe<Scalars['String']['input']>
  taxID?: InputMaybe<Scalars['ID']['input']>
  technicianJobType?: InputMaybe<Array<InputMaybe<ETypeOfjob>>>
  technicianList?: InputMaybe<Array<InputMaybe<UserIdInput>>>
  userId: Scalars['ID']['input']
  userType?: InputMaybe<EUserType>
  username?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}
