import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { useFormikContext, Field } from 'formik'
import MultiDropdown from 'components/MultiDropdown'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import allAction from 'state/actions'
import EditLogo from './edit.svg'
import { CreateSafetyTeam, CreateTeamList, GetListSafetyInTeam, UpdateUserTeam } from 'adapter/xhr'
import { useParams } from 'react-router-dom'
import ModalSafety from './modalsafety'
import ModalSkip from './modalskip'

interface Props {
  setCreatTeamstep: any
}
const CreateMyTeamSecond: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState<boolean>(false)
  const [openm, setOpenM] = React.useState<boolean>(false)
  const [stepPage, setStepPage] = React.useState(null)

  const { userId } = useParams<any>()

  const setStep = () => {
    props.setCreatTeamstep('crtThird')
  }

  const QueryNewSafetyInTeam = () => {
    GetListSafetyInTeam({ userId: userId }).then((teamsafetyres: any) => {
      // if (userId) {
      const unpackingListSafetyPassport: any = {}
      teamsafetyres?.data.data.listSafetyPassportByUserInTeam?.map((safetyPassportElement: any) => {
        if (safetyPassportElement.safetyPassportFileAttachment) {
          unpackingListSafetyPassport[`${safetyPassportElement.safetyPassportType}`] = {
            safetyPassportFileAttachment: safetyPassportElement.safetyPassportFileAttachment,
            safetyPassportStatus: safetyPassportElement.safetyPassportStatus,
          }
        } else {
          unpackingListSafetyPassport[`${safetyPassportElement.safetyPassportType}`] = {
            safetyPassportFileAttachment: '',
            safetyPassportStatus: safetyPassportElement.safetyPassportStatus,
          }
        }
      })
      setFieldValue(`safetyPassport`, unpackingListSafetyPassport)
      // }
      // setFieldValue('safetypassTeam', teamsafetyres.data.data.listSafetyPassportByUserInTeam)
      console.log(teamsafetyres, 'API')
    })
  }

  useEffect(() => {
    if (!values.userteam.isSafetyPassport) {
      setFieldValue('safetyPassport', {})
    }
  }, [values.userteam.isSafetyPassport])

  return (
    <>
      <div className={styles.verifyFristContainer}>
        {' '}
        <div className={styles.flexbox}>
          {' '}
          <div className={styles.inputleft}>
            {' '}
            <div className={styles.inputArea}>
              <div>
                <p>ประเภทงานช่าง</p>
                <MultiDropdown
                  options={values.technicianWorkType}
                  name={'userteam.technicianJobType'}
                  placeHolder={values.userteam.technicianJobType?.length ? '' : 'เลือกประเภทงานช่างทั้งหมด'}
                  values={values.userteam?.technicianJobType}
                />
              </div>

              <div>
                <p>ภูมิภาคที่ให้บริการ (พิมพ์ได้มากกว่า 1)</p>
                <MultiDropdown
                  options={values.optionRegion}
                  name={'userteam.serviceRegion'}
                  placeHolder={values.userteam?.serviceRegion?.length ? '' : 'เลือกภูมิภาคที่ให้บริการ'}
                  values={values.userteam?.serviceRegion}
                />{' '}
              </div>
              <div>
                <p>จังหวัดที่ให้บริการ (พิมพ์ได้มากกว่า 1)</p>
                <MultiDropdown
                  options={values.optionProvince}
                  name={'userteam.serviceProvince'}
                  placeHolder={values.userteam?.serviceProvince ? '' : 'จังหวัดที่ให้บริการ'}
                  values={values.userteam?.serviceProvince}
                />{' '}
              </div>
            </div>
          </div>
          <div className={styles.inputrigth}>
            <span className={styles.check}>
              {' '}
              <Field type="checkbox" name="userteam.isSafetyPassport" /> มีบัตร Safety Passport
              {values.userteam.isSafetyPassport && <img src={EditLogo} alt="" onClick={() => setOpen(true)} />}
            </span>
            {console.log(values.safetyPassport, '<=========================')}

            {values.safetyPassport &&
              Object.keys(values.safetyPassport).map((item: any, safetyIndex: number) => (
                <div className={styles.safetyArea} key={safetyIndex}>
                  <p
                    className={
                      values.safetyPassport[`${item}`].safetyPassportStatus === 'ACCEPT'
                        ? styles.textAccept
                        : values.safetyPassport[`${item}`].safetyPassportStatus === 'PENDING'
                        ? styles.textPending
                        : ''
                    }
                  >
                    {values.safetyPassport[`${item}`].safetyPassportStatus}
                  </p>
                  <p>SafetyPassport [{item}]</p>
                </div>
              ))}
            {console.log('Value ============>', values)}
          </div>
        </div>
        <section>
          <article className={styles.btnFooterWrapper}>
            <button type="button" className={styles.btnCancle} onClick={() => props.setCreatTeamstep('crtOne')}>
              ย้อนกลับ
            </button>
            <button
              type="button"
              className={styles.btnConfirm}
              onClick={() => {
                const DataTeamVaribles = {
                  input: values.userteam,
                }
                {
                  if (userId) {
                    UpdateUserTeam(DataTeamVaribles).then((updateres: any) => {
                      const safetyTeamVariables: any = {
                        userId: userId,
                        input:
                          values.safetyPassport &&
                          Object.keys(values.safetyPassport)?.map((safetyPassportElement: any) => {
                            return {
                              safetyPassportType: safetyPassportElement,
                              safetyPassportFileAttachment:
                                values.safetyPassport[`${safetyPassportElement}`].safetyPassportFileAttachment,
                            }
                          }),
                      }
                      CreateSafetyTeam(safetyTeamVariables).then((res: any) => {
                        console.log(res, 'Update Safety OK')
                      })

                      if (values.Teamget && values.Teamget.userVerification === null) {
                        setOpenM(true)
                      } else {
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus(
                            `แก้ไขข้อมูล ${values.userteam.firstName} เรียบร้อยแล้ว`,
                            '',
                            'success',
                          ),
                        )
                        navigate(-1)
                      }
                    })
                  } else {
                    CreateTeamList(DataTeamVaribles).then((createres: any) => {
                      const safetyTeamVariables: any = {
                        userId: createres.data.data.createUserInTeam.userId,
                        input:
                          values.safetyPassport &&
                          Object.keys(values.safetyPassport)?.map((safetyPassportElement: any) => {
                            return {
                              safetyPassportType: safetyPassportElement,
                              safetyPassportFileAttachment:
                                values.safetyPassport[`${safetyPassportElement}`].safetyPassportFileAttachment,
                            }
                          }),
                      }
                      console.log(safetyTeamVariables, 'InputSafety')
                      CreateSafetyTeam(safetyTeamVariables).then((res: any) => {
                        console.log(res, 'Create Safety OK')
                        setOpenM(true)
                        // dispatch(
                        //   allAction.modalAction.setOpenModalAlertStatus(
                        //     `เพิ่มข้อมูล ${values.userteam.firstName} เรียบร้อยแล้ว`,
                        //     '',
                        //     'success',
                        //   ),
                        // )
                        // dispatch(
                        //   allAction.modalConfirmAction.setOpenModalConfirm(
                        //     'กรุณายืนยันตัวตน',
                        //     'คุณต้องการยืนยันตัวตนเพื่อเริ่มงานเลย ใช่หรือไม่  ?',
                        //     '',
                        //     'ใช่,Verify',
                        //     () => {
                        //       props.setCreatTeamstep('crtThird')
                        //       dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        //       dispatch(
                        //         allAction.modalAction.setOpenModalAlertStatus(
                        //           `เพิ่มทีมงาน ${values.userteam.firstName} เรียบร้อยแล้ว`,
                        //           '',
                        //           'success',
                        //         ),
                        //       )
                        //     },
                        //   ),
                        // )
                      })
                    })
                  }

                  // dispatch(
                  //   allAction.modalAction.setOpenModalAlertStatus(
                  //     `แก้ไขข้อมูล ${values.userteam.firstName} เรียบร้อยแล้ว`,
                  //     '',
                  //     'success',
                  //   ),
                  // )
                }
              }}
            >
              ยืนยัน
            </button>
          </article>
        </section>
        <ModalSkip open={openm} setOpen={setOpenM} setStep={setStep} />
        <ModalSafety open={open} setOpen={setOpen} QueryNewSafetyInTeam={QueryNewSafetyInTeam} />
      </div>
    </>
  )
}
export default CreateMyTeamSecond
