import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, Field } from 'formik'
import EditLogo from './edit.svg'
import MultiDropdown from 'components/MultiDropdown'
import ModalSafety from './modalSafty'

const VerifySecondPerson: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <div className={styles.verifyFristContainer}>
        {' '}
        <div className={styles.flexbox}>
          {' '}
          <div className={styles.inputleft}>
            {' '}
            <div className={styles.inputArea}>
              <div>
                <p>ประเภทงานช่าง</p>
                <MultiDropdown
                  options={values.technicianWorkType}
                  name={'profileUp.technicianJobType'}
                  placeHolder="เลือกประเภทงานช่างทั้งหมด"
                  values={values.profileUp.technicianJobType}
                />
              </div>

              <div>
                <p>ภูมิภาคที่ให้บริการ (พิมพ์ได้มากกว่า 1)</p>
                <MultiDropdown
                  options={values.optionRegion}
                  name={'profileUp.serviceRegion'}
                  placeHolder="เลือกภูมิภาคที่ให้บริการ"
                  values={values.profileUp.serviceRegion}
                />{' '}
              </div>
              <div>
                <p>จังหวัดที่ให้บริการ (พิมพ์ได้มากกว่า 1)</p>
                <MultiDropdown
                  options={values.optionProvince}
                  name={'profileUp.serviceProvince'}
                  placeHolder="จังหวัดที่ให้บริการ"
                  values={values.profileUp.serviceProvince}
                />{' '}
              </div>
            </div>
          </div>
          <div className={styles.inputrigth}>
            <span className={styles.check}>
              {' '}
              <Field type="checkbox" name="profileUp.isSafetyPassport" /> มีบัตร Safety Passport
              {values.profileUp.isSafetyPassport && <img src={EditLogo} alt="" onClick={() => setOpen(true)} />}
            </span>
            {values.safetyPassport &&
              Object.keys(values.safetyPassport).map((item: any, safetyIndex: number) => (
                <div className={styles.safetyArea} key={safetyIndex}>
                  <p
                    className={
                      values.safetyPassport[`${item}`].safetyPassportStatus === 'ACCEPT'
                        ? styles.textAccept
                        : values.safetyPassport[`${item}`].safetyPassportStatus === 'PENDING'
                        ? styles.textPending
                        : ''
                    }
                  >
                    {values.safetyPassport[`${item}`].safetyPassportStatus}
                  </p>
                  <p>SafetyPassport [{item}]</p>
                </div>
              ))}
          </div>
        </div>
        <ModalSafety open={open} setOpen={setOpen} />
      </div>
    </>
  )
}
export default VerifySecondPerson
