// Similar to lineTypeComponent, but separated for easiness.
// TODO: Combine these two if possible, but just do this for now.

import React from 'react'
import styles from './index.module.scss'
import { FormikInputText } from 'components/commons/input/formikInputText'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { IValues } from './index'
import { ITitleAttribute } from 'adapter/xhr'
import emptyImage from './emptyImage43.png'
import * as AWS from 'aws-sdk'
import { albumBucketName, bucketRegion, IdentityPoolId } from 'components/commons/configAws'

const HeaderRowComponent: React.FC<IProps> = ({ rowData, formikPrefix, filePrefix }: IProps) => {
  const { values, setFieldValue } = useFormikContext<IValues>()
  const { patId } = useParams()

  const handleUploadFile = (files: any, name: string) => {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })
    const file: any = files[0]
    if (file.name) {
      const albumPhotosKey = `PAT/${patId}/`
      const photoKey = albumPhotosKey + filePrefix + '-' + name + '-' + file.name
      // Use S3 ManagedUpload class as it supports multipart uploads
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ContentType: file.type,
        },
      })
      const promise = upload.promise()
      promise
        .then(function (data) {
          setFieldValue(`${formikPrefix}.value.[${name}]`, data.Location, true)
        })
        .catch(function (err) {
          console.log(err)
        })
    } else {
      console.log('err')
    }
  }
  // TWO TYPES: UPLOAD / TEXT
  if (rowData.attributeType === 'TEXT') return <p className={styles.rowText}>{rowData.value.text}</p>
  // IF UPLOADThe Value is uploadType of Image/Text
  else if (
    rowData.attributeType === 'UPLOAD' &&
    (rowData.value.uploadType === 'TEXT' ||
      rowData.value.uploadType === 'VIDEO' ||
      rowData.value.uploadType === 'SOUND')
  ) {
    return (
      <div>
        {/* Case no text */}
        {!(rowData.value.isNeedBefore && rowData.value.isNeedAfter) && (
          <div className={styles.rowUploadText}>
            {rowData.value.text}
            {values.modeAction === 'upload' ? (
              <FormikInputText name={`${formikPrefix}.value.uploadBeforeValue`} />
            ) : (
              <span className={styles.textValue}>{rowData.value.uploadBeforeValue}</span>
            )}
          </div>
        )}
        {/* Case before text */}
        {rowData.value.isNeedBefore && (
          <div className={styles.rowUploadText}>
            {rowData.value.text}ก่อนทำ
            {values.modeAction === 'upload' ? (
              <FormikInputText name={`${formikPrefix}.value.uploadBeforeValue`} />
            ) : (
              <span className={styles.textValue}>{rowData.value.uploadBeforeValue}</span>
            )}
          </div>
        )}
        {/* Case after text */}
        {rowData.value.isNeedAfter && (
          <div className={styles.rowUploadText}>
            {rowData.value.text}หลังทำ
            {values.modeAction === 'upload' ? (
              <FormikInputText name={`${formikPrefix}.value.uploadBeforeValue`} />
            ) : (
              <span className={styles.textValue}>{rowData.value.uploadAfterValue}</span>
            )}
          </div>
        )}
      </div>
    )
  } else if (
    rowData.attributeType === 'UPLOAD' &&
    (rowData.value.uploadType === 'IMAGE' || rowData.value.uploadType === 'PDF' || rowData.value.uploadType === 'FILE')
  ) {
    let acceptFile = ''
    if (rowData.value.uploadType === 'IMAGE') acceptFile = 'image/*'
    else if (rowData.value.uploadType === 'PDF') acceptFile = '.pdf'
    else if (rowData.value.uploadType === 'FILE') acceptFile = ''
    return (
      <div className={styles.rowUploadImage}>
        <p className={styles.titleImage}>{rowData.value.text}</p>

        {/* Row for Img Showing and Upload File 
          1. If isNeedBefore and isNeedAfter ->  uploadBeforeValue & uploadAfterValue
          2. If none, just show uploadBeforeValue */}
        <div className={styles.rowImage}>
          {/* If all OR any is Empty, show only one and put in uploadBeforeValue */}
          {!rowData.value.isNeedBefore && !rowData.value.isNeedAfter && (
            <div className={styles.rowElement}>
              <div className={styles.imageBorder}>
                <img
                  className={styles.imageInside}
                  src={rowData.value.uploadBeforeValue ? rowData.value.uploadBeforeValue : emptyImage}
                  alt={`Uploaded Image ${rowData.attributeName}`}
                />
              </div>

              {values.modeAction === 'upload' && (
                <input
                  type="file"
                  id={`${rowData.attributeName}-uploadBeforeValue`}
                  accept={acceptFile}
                  disabled={
                    values.unpackedPat.status === 'WAIT_FILL' || values.unpackedPat.status === 'REJECT' ? false : true
                  }
                  onChange={(e) => handleUploadFile(e.target.files, 'uploadBeforeValue')}
                />
              )}
              <p>รูปภาพ</p>
            </div>
          )}
          {/* If isNeedBefore, just show*/}
          {rowData.value.isNeedBefore && (
            <div className={styles.rowElement}>
              <div className={styles.imageBorder}>
                <img
                  className={styles.imageInside}
                  src={rowData.value.uploadBeforeValue ? rowData.value.uploadBeforeValue : emptyImage}
                  alt={`Uploaded Image ${rowData.attributeName}`}
                />
              </div>

              {values.modeAction === 'upload' && (
                <input
                  type="file"
                  id={`${rowData.attributeName}-uploadBeforeValue`}
                  accept={acceptFile}
                  disabled={
                    values.unpackedPat.status === 'WAIT_FILL' || values.unpackedPat.status === 'REJECT' ? false : true
                  }
                  onChange={(e) => handleUploadFile(e.target.files, 'uploadBeforeValue')}
                />
              )}
              <p>รูปภาพก่อนทำ</p>
            </div>
          )}
          {/* If isNeedBefore, just show*/}
          {rowData.value.isNeedAfter && (
            <div className={styles.rowElement}>
              <div className={styles.imageBorder}>
                <img
                  className={styles.imageInside}
                  src={rowData.value.uploadAfterValue ? rowData.value.uploadAfterValue : emptyImage}
                  alt={`Uploaded Image ${rowData.attributeName}`}
                />
              </div>
              {values.modeAction === 'upload' && (
                <input
                  type="file"
                  id={`${rowData.attributeName}-uploadAfterValue`}
                  accept={acceptFile}
                  disabled={
                    values.unpackedPat.status === 'WAIT_FILL' || values.unpackedPat.status === 'REJECT' ? false : true
                  }
                  onChange={(e) => handleUploadFile(e.target.files, 'uploadAfterValue')}
                />
              )}
              <p>รูปภาพหลังทำ</p>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default HeaderRowComponent

interface IProps {
  rowData: ITitleAttribute
  formikPrefix: string
  filePrefix: string
}
