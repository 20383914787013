import React from 'react'
import styles from './toolbar.module.scss'
import { Iconbutton } from '../commons/iconButton'

import searchpic from './search.svg'
import arrowboxpic from './arrowbox.svg'
// import nineboxpic from './ninebox.svg'
import { ITableColumn } from '.'

const regionFilterOptions: IOption[] = [
  { value: '', text: 'ภูมิภาคทั้งหมด' },
  { value: 'กรุงเทพและปริมณฑล', text: 'กรุงเทพและปริมณฑล (BMR)' },
  { value: 'ภาคกลาง', text: 'ภาคกลาง (CR)' },
  { value: 'ภาคเหนือ', text: 'ภาคเหนือ (NR)' },
  { value: 'ภาคตะวันออกเฉียงเหนือ', text: 'ภาคตะวันออกเฉียงเหนือ (NER)' },
  { value: 'ภาคตะวันออก', text: 'ภาคตะวันออก (ER)' },
  { value: 'ภาคตะวันตก', text: 'ภาคตะวันตก (WR)' },
  { value: 'ภาคใต้', text: 'ภาคใต้ (SR)' },
]
const patStatusFilterOptions: IOption[] = [
  { value: '', text: 'สถานะทั้งหมด' },
  { value: 'WAIT_FILL', text: 'รอการดำเนินการ' },
  { value: 'WAIT_APPROVAL', text: 'รอการตรวจสอบ' },
  { value: 'ACCEPT', text: 'ผ่าน' },
  { value: 'REJECT', text: 'รอการแก้ไข' },
]

interface IOption {
  value: string
  text: string
}

const ToolbarComponent: React.FC<any> = (props: any) => {
  return (
    <section className={styles.toolbarWrapper}>
      <article className={styles.lefttool}>
        <select
          className={styles.thirdcontentselect}
          value={props.regionFilter}
          onChange={(e) => props.setRegionFilter(e.target.value)}
        >
          {regionFilterOptions.map((optionElement, optionIndex) => (
            <option key={optionIndex} value={optionElement.value}>
              {optionElement.text}
            </option>
          ))}
        </select>
        <select
          className={styles.thirdcontentselect}
          value={props.patStatusFilter}
          onChange={(e) => props.setPatStatusFilter(e.target.value)}
        >
          {patStatusFilterOptions.map((optionElement, optionIndex) => (
            <option key={optionIndex} value={optionElement.value}>
              {optionElement.text}
            </option>
          ))}
        </select>
      </article>
      <article className={styles.righttool}>
        {/* <FormikInputText className={styles.searchtext} name={`searchtext`} placeholder="ค้นหา" /> */}
        <input
          type="text"
          className={styles.searchtext}
          value={props.textFilter}
          placeholder="ค้นหา CID/JID/SiteCode/SiteName"
          onChange={(e) => props.setTextFilter(e.target.value)}
        />
        <Iconbutton
          className={styles.searchbtn}
          buttonClassName={styles.searchimg}
          type="button"
          //   functionOnClick={() => {}}
          src={searchpic}
          alt="searchpic"
        />

        <div className={styles.arrowParentWrapper}>
          <label className={styles.btnnobackground} htmlFor={props.tableId.replace(/ /g, '')}>
            <img className={styles.btnnobackgroundimg} src={arrowboxpic} alt="arrowboxpic" />
          </label>
          <input
            className={styles.hiddenColumnToggle}
            type="checkbox"
            defaultChecked={true}
            style={{ display: 'none' }}
            id={props.tableId.replace(/ /g, '')}
          />
          <div className={styles.columnCheckboxWrapper}>
            {props.tableColumns.map((tableColumn: ITableColumn, tableColumnIndex: number) => (
              <label key={tableColumnIndex}>
                <input
                  type="checkbox"
                  checked={!props.columnFilter[tableColumn.name]}
                  onChange={(e) => {
                    const columnFilter = {
                      ...props.columnFilter,
                      [tableColumn.name]: !(e.target as any).checked,
                    }
                    props.setColumnFilter(columnFilter)
                  }}
                />
                {tableColumn.engText}
              </label>
            ))}
          </div>
        </div>
        {/* <Iconbutton
          className={styles.btnnobackground}
          buttonClassName={styles.btnnobackgroundimg}
          type="button"
          //   functionOnClick={() => {}}
          src={nineboxpic}
          alt="nineboxpic"
        /> */}
      </article>
    </section>
  )
}

export default ToolbarComponent
