import { getProjectByProjectId } from 'adapter/xhr'

export async function getProjectsByUserIdForPost(projectId: string) {
  const project = (await getProjectByProjectId(projectId)).data.data.project

  //   if (projectList.length == 0) {
  //     return []
  //   }

  return project
}
