// Get week Number
//https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
// Modified to nearest sunday?
// DONT USE THIS BECAUSE WEEKNO RETURNEDs ISN"T THE SAME AS THE BOTTOM!!!!!!!
export function getWeekNumberERROR(d: Date) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  // Set to nearest Sunday: current date + 7 - current day number
  // Make Sunday's day number 7 -> I CHANGED TO 6 because the below uses 6
  d.setUTCDate(d.getUTCDate() + 6 - (d.getUTCDay() || 7))
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  // Get last day of year
  const yearEnd = new Date(Date.UTC(d.getUTCFullYear(), 11, 31))
  // Calculate full weeks to nearest Sunday . [(nearestSunday - startYear) / 3600*24*1000 days + 1 (index start 1) ] / 7 CEIL
  // not sure why +1...  if New year start with Sunday nearestSunday - startYear will be 0 i guess. And +1 doesn't affect
  const weekNo = Math.ceil((((d as any) - (yearStart as any)) / 86400000 + 1) / 7)
  // Calculate Total weeks
  const totalWeeks = Math.ceil((((yearEnd as any) - (yearStart as any)) / 86400000 + 1) / 7)
  // Return array of year and week number
  return {
    year: d.getUTCFullYear(),
    weekNo,
    totalWeeks,
  }
}

// Binary Search : https://www.geeksforgeeks.org/binary-search-in-javascript/
export function findWeekNoInWeekList(weekList: IWeekElement[], d: Date, start: number, end: number): number | null {
  // Base Condition
  if (start > end) return null

  // Find the middle index
  const mid = Math.floor((start + end) / 2)

  // Compare mid with given key x
  if (
    d.getTime() >= new Date(weekList[mid].startDate).getTime() &&
    d.getTime() <= new Date(weekList[mid].endDate).getTime()
  )
    return weekList[mid].weekNo

  // If element at mid is greater than x,
  // search in the left half of mid
  if (new Date(weekList[mid].startDate).getTime() > d.getTime())
    return findWeekNoInWeekList(weekList, d, start, mid - 1)
  // If element at mid is smaller than x,
  // search in the right half of mid
  else return findWeekNoInWeekList(weekList, d, mid + 1, end)
}

export interface IWeekElement {
  startDate: string
  endDate: string
  weekNo: number
  month: number
}

// Return Sorted List of Monday Date - WeekNo INCLUSIVE
// [{ startDate: '2020-12-02', weekNo: 2 }, ...]
export function getWeeksInYear(year: number): IWeekElement[] {
  const weekList = []
  // Copy date so don't modify original, Start of Year
  const d1 = new Date(Date.UTC(year, 0, 1))
  const d2 = new Date(Date.UTC(year, 0, 1))
  // Set to nearest Sunday: current date + 7 - current day number
  // Make Sunday's day number 7
  // d1 is startDate and d2 is endDate, offset d1 by 7 before
  d1.setUTCDate(d1.getUTCDate() + 6 - (d1.getUTCDay() || 7))
  d1.setDate(d1.getDate() - 6)
  d2.setUTCDate(d2.getUTCDate() + 6 - (d2.getUTCDay() || 7))

  let weekNo = 0
  const totalWeeks = getWeekNumberERROR(d1).totalWeeks

  while (++weekNo <= totalWeeks) {
    weekList.push({
      startDate: getStringFromDate(d1),
      endDate: getStringFromDate(d2),
      weekNo,
      month: d1.getMonth() + 1,
    })

    d1.setDate(d1.getDate() + 7)
    d2.setDate(d2.getDate() + 7)
  }

  return weekList
}

// returns 2021-12-02
function getStringFromDate(d: Date): string {
  return `${d.getUTCFullYear()}-${`${d.getMonth() + 1}`.padStart(2, '0')}-${`${d.getDate()}`.padStart(2, '0')}`
}

export function filterWeekElement(
  weekElement: IWeekElement,
  year: number,
  month: number,
  MONTH_FORWARD: number,
): boolean {
  // Check if the start and end date is in the range of the month -> monthForward
  // Check by checking if startDate < month+monthForward (last day) and endDate > month (first day)
  const lastDay = new Date(year, month + MONTH_FORWARD - 1, 0)
  const firstDay = new Date(year, month - 1, 1)

  if (
    new Date(weekElement.startDate).getTime() <= lastDay.getTime() &&
    new Date(weekElement.endDate).getTime() >= firstDay.getTime()
  )
    return true
  return false
}
