import React from 'react'
import styles from './index.module.scss'
// import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
// import { getTheme, mergeStyleSets, Modal, IColumn, DefaultButton } from '@fluentui/react'
import closeIcon from '../../../image/closeButton.svg'
// import TableDropDown from 'components/dropdowntable'
// import TableShow from 'components/TableShow'
import TableTeam from './tableTeam'
import Modal from '@mui/material/Modal'
// import zIndex from '@mui/material/styles/zIndex'
// import { style } from '@mui/system'

// const jobSelectionColumns: IColumn[] = [
//   {
//     key: 'column1',
//     name: 'ID ช่างทำ',
//     fieldName: 'changTumId',
//     minWidth: 70,
//     maxWidth: 70,
//     isResizable: false,
//     isFiltered: true,
//     isSorted: true,
//   },
//   {
//     key: 'column2',
//     name: 'Region',
//     fieldName: 'region',
//     minWidth: 50,
//     maxWidth: 50,
//     isResizable: false,
//     isFiltered: true,
//     isSorted: true,
//   },
//   {
//     key: 'column3',
//     name: 'Province',
//     fieldName: 'province',
//     minWidth: 50,
//     maxWidth: 200,
//     isResizable: true,
//     isFiltered: true,
//     isSorted: true,
//   },
//   {
//     key: 'column4',
//     name: 'Site Code',
//     fieldName: 'siteCode',
//     minWidth: 50,
//     maxWidth: 200,
//     isResizable: true,
//     isFiltered: true,
//     isSorted: true,
//   },
//   {
//     key: 'column5',
//     name: 'Site Name',
//     fieldName: 'siteName',
//     minWidth: 80,
//     maxWidth: 200,
//     isResizable: true,
//     isFiltered: true,
//     isSorted: true,
//   },
//   {
//     key: 'column6',
//     name: 'Status',
//     fieldName: 'status',
//     minWidth: 40,
//     maxWidth: 40,
//     isResizable: false,
//     isFiltered: true,
//     isSorted: true,
//   },
//   {
//     key: 'column7',
//     name: 'มี PAT',
//     fieldName: 'isPatGenerated',
//     minWidth: 40,
//     maxWidth: 40,
//     isResizable: false,
//     isFiltered: true,
//     isSorted: true,
//   },
// ]

const MapData = [
  {
    name: 'นิคม ปันเต๋',
    type: 'ช่างยนต์',
    safty: 'AIS[G]',
  },
  {
    name: 'นิคม ปันเต๋',
    type: 'ช่างยนต์',
    safty: 'AIS[G]',
  },
  {
    name: 'นิคม ปันเต๋',
    type: 'ช่างยนต์',
    safty: 'AIS[G]',
  },
  {
    name: 'นิคม ปันเต๋',
    type: 'ช่างยนต์',
    safty: 'AIS[G]',
  },
  {
    name: 'นิคม ปันเต๋',
    type: 'ช่างยนต์',
    safty: 'AIS[G]',
  },
]
const ModalTeam: React.FC<any> = (props: any): JSX.Element => {
  const [itemsFilter, setItemsFilter] = React.useState<string>('')
  const [itemsToShow, setItemsToShow] = React.useState<any[]>([])

  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const dispatch = useDispatch()

  return (
    <Modal
      // hideBackdrop
      // containerClassName={contentStyles.container}
      open={values.isModalTeamOpen}
      onClose={props.onDismiss}
      BackdropProps={{ sx: { zIndex: 0 } }}
    >
      <div className={styles.center}>
        <div className={styles.modal}>
          <div className={styles.card}>
            {' '}
            <div className={styles.Area}>
              <div className={styles.head}>
                <p className={styles.textHead}>รายชื่อทีมงาน</p>
                <img src={closeIcon} alt="" onClick={props.onDismiss} />
              </div>
              <div className={styles.textCompany}>
                <span className={styles.text}>ผู้สมัคร : {values.selectedModalTeam.username}</span>
              </div>
              <div className={styles.TableShow}>
                <TableTeam technicianList={values.selectedModalTeam.selectedTeamList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
// const contentStyles = mergeStyleSets({
//   container: {
//     background: 'transparent',
//     boxShadow: 'transparent',
//   },
// })

export default ModalTeam
