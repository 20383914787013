import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { FormikInputSelect } from 'components/commons/input/formikInputSelect'
import { stringMap } from 'aws-sdk/clients/backup'

interface ITableDropDown {
  tableTopic: string
  // tableInputSelectName: string
  tableInputSelectOnchange?: any
  tableOption: ITableOption[]
  tableHeader: string[]
  tableRowList: ITableRow[]
  totalJobListFieldName: string

  // tablePostName?: string
  isDisabledDropdown?: boolean
  isDisabledTotalQty?: boolean
  isDisabledBalanceQty?: boolean
  isDisabledRegistryQty?: boolean
  isDisabledApproveQty?: boolean
  tableTotal?: string | number
  tableBalance?: string | number
  tableRegister?: string | number
  tableApprove?: string | number
  // tableName: string
  workType: any
  // tableIndex: number
}

interface ITableRow {
  fieldValue: string
  totalQty?: number
  balanceQty?: number
  registerQty?: number
  approveQty?: number
}

interface ITableOption {
  value: string
  text: string
}

const TableDropDown: React.FC<ITableDropDown> = (props: ITableDropDown & any) => {
  const numberRegEx = /^[0-9\b]+$/

  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const validateSetFieldTotalQty = (value: any, props: any, tableRowIndex: number) => {
    if (isNaN(parseInt(value))) {
      setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].totalQty`, '')
      setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].balanceQty`, '')
    } else {
      setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].totalQty`, parseInt(value))
      setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].balanceQty`, parseInt(value))
    }
  }

  const validateSetFieldLimit = (value: any, props: any, tableRowIndex: number, key: string, limit: number) => {
    if (isNaN(parseInt(value))) {
      setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].${key}`, '')
    } else {
      if (parseInt(value) > limit) {
        setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].${key}`, limit)
      } else {
        setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].${key}`, parseInt(value))
      }
    }
  }

  const validateSetFieldNormal = (value: any, props: any, tableRowIndex: number, key: string) => {
    if (isNaN(parseInt(value))) {
      setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].${key}`, '')
    } else {
      setFieldValue(`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].${key}`, parseInt(value))
    }
  }
  const newProps = props.totalJobListFieldName
  return (
    <div className={styles.tableContainer}>
      <section className={styles.tableTopic}>{props.tableTopic}</section>
      <section className={styles.tableHeader}>
        <div className={styles.tableRowTopic}>
          <FormikInputSelect
            className={styles.dropDownInput}
            name={`${props.totalJobListFieldName}.fieldID`}
            option={props.tableOption && [...props.tableOption]}
            disabled={props.isDisabledDropdown && props.isDisabledDropdown}
            onChange={(events) => {
              const findingObj = values.fieldList.find((field: any) => field.name === events.target.value)
              const jobList: any = []
              if (findingObj) {
                findingObj.allowedValueList.map((allowedValues: string) => {
                  jobList.push({
                    fieldValue: allowedValues,
                    totalQty: '',
                    balanceQty: '',
                    registerQty: '',
                    approveQty: '',
                  })
                })
              }
              setFieldValue(`${props.totalJobListFieldName}.fieldID`, events.target.value)
              setFieldValue(`${props.totalJobListFieldName}.totalJobList`, jobList)
            }}
            defaultValue={''}
          />
        </div>
        {props.tableHeader.map((tableItems: stringMap, tableHeaderIndex: number) => {
          return (
            <p className={styles.tableRowColumn} key={tableHeaderIndex}>
              {tableItems as any}
            </p>
          )
        })}
      </section>
      {props.workType &&
        props.workType.totalJobList.map((tableRowItems: ITableRow, tableRowIndex: number) => {
          return (
            <section className={styles.tableRow} key={tableRowIndex}>
              <p className={styles.tableRowTopic} style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}>
                {tableRowItems.fieldValue}
              </p>
              <Field
                className={styles.tableRowColumn}
                type="number"
                disabled={props.isDisabledTotalQty && props.isDisabledTotalQty}
                min={0}
                name={`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].totalQty`}
                onKeyPress={(event: any) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                  if (event.target.value === '0' && event.key === '0') {
                    event.preventDefault()
                  }
                }}
                onChange={(e: any) => {
                  validateSetFieldTotalQty(e.target.value, props, tableRowIndex)
                }}
                style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}
              />
              <Field
                className={styles.tableRowColumn}
                type="number"
                disabled={props.isDisabledBalanceQty && props.isDisabledBalanceQty}
                min={0}
                name={`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].balanceQty`}
                style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}
              />
              <Field
                className={styles.tableRowColumn}
                type="number"
                min={0}
                disabled={props.isDisabledRegistryQty && props.isDisabledRegistryQty}
                name={`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].registerQty`}
                onKeyPress={(event: any) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                  if (event.target.value === '0' && event.key === '0') {
                    event.preventDefault()
                  }
                }}
                onChange={(e: any) => {
                  validateSetFieldLimit(
                    e.target.value,
                    props,
                    tableRowIndex,
                    'registerQty',
                    tableRowItems.balanceQty ? tableRowItems.balanceQty : 0,
                  )
                }}
                style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}
              />
              <Field
                className={styles.tableRowColumn}
                type="number"
                min={0}
                disabled={props.isDisabledApproveQty && props.isDisabledApproveQty}
                name={`${props.totalJobListFieldName}.totalJobList[${tableRowIndex}].approveQty`}
                onKeyPress={(event: any) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                  if (event.target.value === '0' && event.key === '0') {
                    event.preventDefault()
                  }
                }}
                onChange={(e: any) => {
                  validateSetFieldLimit(
                    e.target.value,
                    props,
                    tableRowIndex,
                    'approveQty',
                    tableRowItems.balanceQty ? tableRowItems.balanceQty : 0,
                  )
                }}
                style={tableRowIndex % 2 !== 0 ? { background: 'white' } : {}}
              />
            </section>
          )
        })}
      <section className={styles.tableFooter}>
        <p className={styles.tableRowTopic}>Total</p>
        <p className={styles.tableRowColumnFooter}>{props.tableTotal}</p>
        <p className={styles.tableRowColumnFooter}>{props.tableBalance}</p>
        <p className={styles.tableRowColumnFooter}>{props.tableRegister}</p>
        <p className={styles.tableRowColumnFooter}>{props.tableApprove}</p>
      </section>
    </div>
  )
}

export default TableDropDown
