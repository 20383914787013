import React, { useState } from 'react'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { ICombineReducers } from 'state/reducers'
import Leftsidecontent from './leftsidecontent'
import LoginComponent from './logincomponent'
import Phonelogincomponent from './phonelogincomponent'
import Forgotpasswordcomponent from './forgotpasswordcomponent'
import { useEffect } from 'react'

const Loginpage: React.FC = () => {
  const [componentStatus, setComponentStatus] = useState('login')
  const isLoggedIn = useSelector((state: ICombineReducers) => state.tokenReducer.isLoggedIn)
  const isLoggingIn = useSelector((state: ICombineReducers) => state.tokenReducer.isLoggingIn)
  const role = useSelector((state: ICombineReducers) => state.tokenReducer.decodedAccessToken.role)
  const navigate = useNavigate()

  // Automatically redirect to home if user isLoggedIn
  useEffect(() => {
    if (isLoggedIn && !isLoggingIn && role) {
      console.log(`I am redirecting to /${role}/home`)
      navigate(`/${role}/home`)
    }
    console.log('woahh loggingin or loggedIn')
  }, [isLoggedIn, isLoggingIn, role])

  return (
    <div
      className={`wrapper2 ${styles.loginpageContainer}`}
      // style={componentStatus === 'forgotpassword' ? { alignItems: 'center' } : {}}
    >
      <Leftsidecontent />
      {componentStatus === 'login' ? (
        <LoginComponent setComponentStatus={setComponentStatus} />
      ) : componentStatus === 'smslogin' ? (
        <Phonelogincomponent setComponentStatus={setComponentStatus} />
      ) : componentStatus === 'forgotpassword' ? (
        <Forgotpasswordcomponent setComponentStatus={setComponentStatus} />
      ) : null}
    </div>
  )
}

const EnhancedLoginpage = withFormik({
  mapPropsToValues: () => ({
    username: '',
    password: '',
    phonenumber: '',
    newpassword: '',
  }),
  validate: (values) => {
    //   console.log('I AM VALIDATING')
    //   console.log(values)
  },
  handleSubmit: (values, { props, setFieldValue }: any) => {
    // console.log(values, 'values')
  },
})(Loginpage)

export default EnhancedLoginpage
