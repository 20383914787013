import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { DefaultButton, Modal } from '@fluentui/react'
import { useFormikContext, withFormik, Form, Field, FieldProps, setNestedObjectValues } from 'formik'
import Firstpage from './firstpage'
import Secondpage from './secondpage'
import Thirdpage from './thirdpage'
import ThreeStepComponent from './ThreeSteps'

import Timer from 'components/commons/timer'
import { getChangTumId } from 'components/commons/shared'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import checkIcon from './checkIcon.svg'
//* API
import {
  getUnpackedProjectByProjectId,
  packAndPutProject,
  deleteJobs,
  packAndCreateProject,
} from 'adapter/createProjectPageAdapter'
import { IProject, IWorkType, IJob } from 'adapter/xhr'

import { ICombineReducers } from 'state/reducers'
import { EMPTY_JOB } from 'components/ChungTumTable'
import { THAILAND_PROVINCE, THAILAND_REGION } from 'components/commons/thailandFact'

const Addprojectpage: React.FC = () => {
  const { values, setValues, setTouched, isValid, validateForm } = useFormikContext<IFormikValues>()
  const [pagestatus, setpagestatus] = useState<string>('firststep')

  // Since This Page is shared between both the createProject and editProject
  // projectId will be either /editproject/{projectId} or /createproject
  const { projectId } = useParams<any>()
  const [isProjectLoad, setIsProjectLoad] = useState<boolean>(false)
  // console.log('props >> :', props.location.state)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (projectId && !(location && location.state)) {
      console.time('useEffect>getUnpackedProjectByProjectId')
      getUnpackedProjectByProjectId(projectId).then((projectResponse: IProject) => {
        console.timeEnd('useEffect>getUnpackedProjectByProjectId')
        setIsProjectLoad(true)
        // Set project Response and all the trashes that arent IProject
        setValues({
          ...projectResponse,
          isShowSuccessModal: false,
          jobListToDelete: [],
          safetyPassport: [
            {
              value: 'AIS_G',
              text: 'AIS [G]',
            },
            {
              value: 'AIS_E',
              text: 'AIS [E]',
            },
            {
              value: 'AIS_EE',
              text: 'AIS [EE]',
            },
            {
              value: 'AIS_H',
              text: 'AIS [H]',
            },
            {
              value: 'AIS_C',
              text: 'AIS [C]',
            },
          ],
          options: [
            { value: '', text: '' },
            {
              value: 'province',
              text: 'จังหวัด',
            },
            {
              value: 'region',
              text: 'ภูมิภาค',
            },
          ],
          fieldList: [
            {
              name: 'region',
              allowedValueList: THAILAND_REGION,
            },
            {
              name: 'province',
              allowedValueList: THAILAND_PROVINCE,
            },
          ],
        })
      })
    }
  }, [projectId])

  useEffect(() => {
    if (location && location.state) {
      console.log('THIS IS MY LOCATION STATE', location.state)
      setpagestatus('secondstep')

      // Clone values
      const newValues = JSON.parse(JSON.stringify(location.state.values))

      // Put in patTemplateId for the selected WorkType
      newValues.workTypeList = newValues.workTypeList.map((workTypeElement: IWorkType) => {
        if (workTypeElement.workType === location.state.selectedWorkType) {
          return {
            ...workTypeElement,
            patTemplateId: location.state.patTemplateId,
          }
        }
        return workTypeElement
      })
      console.log('SETTING NEW STATE values', newValues)

      setIsProjectLoad(true)
      setValues(newValues, false)
    }
  }, [location])

  const numberFormat = (n: number) => {
    return (Math.round(n * 100) / 100).toLocaleString()
  }

  const calculateRangeWorkPrice = () => {
    const jobUnitPrices = values.workTypeList.map((workType) => workType.jobUnitPrice).filter((price) => price! > 0)

    const priceSet = new Set(jobUnitPrices)

    const priceList = [...priceSet] as number[]

    if (!priceSet.size) return '-'
    else if (priceSet.size === 1) return numberFormat(priceList[0])
    else return `${numberFormat(Math.min(...priceList))} - ${numberFormat(Math.max(...priceList))}`
  }

  return (
    <div className={styles.pageWrapper}>
      <ThreeStepComponent pagestatus={pagestatus} setpagestatus={setpagestatus} isValid={isValid} />
      {projectId && !isProjectLoad && (
        <div className="loaderWrapper" style={{ marginTop: '70px' }}>
          <div className="loader" />
        </div>
      )}
      {/* 2021-06-15T17:00:00.000Z */}
      {(!projectId || isProjectLoad) && (
        <Form>
          {pagestatus === 'firststep' ? (
            <>
              <Firstpage />
              <div className="dp-flex justify-cont-ct f-gap50px">
                <DefaultButton
                  className="dp-flex f-basis50 justify-cont-ct btnDefault3"
                  onClick={() => navigate(-1)}
                  text="ย้อนกลับ"
                />
                <DefaultButton
                  className="dp-flex f-basis50 justify-cont-ct btnDefault2 "
                  onClick={() => {
                    // setTouched({
                    //   projectName: true,
                    //   startDate: true,
                    //   endDate: true,
                    // })
                    validateForm().then((errors) => {
                      console.log('errors after vadlisad', errors)
                      if (Object.keys(errors).length === 0) {
                        setpagestatus('secondstep')
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                      }
                    })
                  }}
                  text="ถัดไป"
                />
              </div>
            </>
          ) : pagestatus === 'secondstep' ? ( //Start code secondstep
            <>
              <Secondpage />
              <div className="dp-flex justify-cont-ct f-gap50px">
                <DefaultButton
                  className="dp-flex f-basis50 justify-cont-ct btnDefault3 "
                  onClick={() => {
                    setpagestatus('firststep')
                  }}
                  text="ย้อนกลับ"
                />
                {/* Continue button sometimes alert() for no reason. */}

                <button
                  className="dp-flex f-basis50 justify-cont-ct btnDefault2"
                  style={{ height: '32px', alignItems: 'center' }}
                  onClick={() => {
                    validateForm().then((errors) => {
                      console.log('errors after vadlisad', errors)
                      if (Object.keys(errors).length === 0) {
                        setpagestatus('thirdstep')
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                      }
                    })
                  }}
                >
                  ถัดไป
                </button>
              </div>
            </>
          ) : pagestatus === 'thirdstep' ? ( //Start code thirdstep
            <>
              <Thirdpage />
              <div className="dp-flex justify-cont-ct f-gap50px">
                <DefaultButton
                  className="dp-flex f-basis50 justify-cont-ct btnDefault3 "
                  onClick={() => {
                    setpagestatus('secondstep')
                  }}
                  text="ย้อนกลับ"
                />
                <DefaultButton className="dp-flex f-basis50 justify-cont-ct btnDefault" text="ยืนยัน" type="submit" />
              </div>
            </>
          ) : null}
        </Form>
      )}
      {/* Modal to show success once submitted */}
      <Modal
        isOpen={values.isShowSuccessModal}
        // onDismiss={() => setFieldValue(`isShowSuccessModal`, false, false)}
        // containerClassName={styles.successModalWrapper}
      >
        <div className={styles.successModalWrapper}>
          <img src={checkIcon} width={90} />
          <h2>โครงการ {values.projectName}</h2>
          <p>
            ราคา {calculateRangeWorkPrice()} บาท จำนวน{' '}
            {values.workTypeList.reduce((acc, curr) => acc + curr.jobList!.length - 1, 0)} งาน
          </p>
          <p>
            {values.startDate} - {values.endDate}
          </p>
          <p className={styles.saveText}>Saved successfully</p>
          <p>Redirecting in</p>
          <Timer initialSeconds={3} />
        </div>
      </Modal>
    </div>
  )
}

const EnhancedAddProjectPage = withFormik({
  mapPropsToValues: () => ({
    // related to project
    projectName: '',
    projectDescription: '',
    startDate: '',
    endDate: '',
    TORFile: '',
    projectManagerName: '',
    projectManagerPosition: '',
    configReportFieldID: '',
    editFieldID: '',

    workTypeList: [getEmptyWorkTypeWithChangTumId()],
    customFieldList: [{ name: 'AISRegion', allowedValueList: ['BKK', 'CR', 'ER', 'NER', 'NR', 'SR'] }],

    // Not related to project
    isShowSuccessModal: false,
    jobListToDelete: [],
    safetyPassport: [
      {
        value: 'AIS_G',
        text: 'AIS [G]',
      },
      {
        value: 'AIS_E',
        text: 'AIS [E]',
      },
      {
        value: 'AIS_EE',
        text: 'AIS [EE]',
      },
      {
        value: 'AIS_H',
        text: 'AIS [H]',
      },
      {
        value: 'AIS_C',
        text: 'AIS [C]',
      },
    ],
    options: [
      { value: '', text: '' },
      {
        value: 'province',
        text: 'จังหวัด',
      },
      {
        value: 'region',
        text: 'ภูมิภาค',
      },
    ],
    fieldList: [
      {
        name: 'region',
        allowedValueList: THAILAND_REGION,
      },
      {
        name: 'province',
        allowedValueList: THAILAND_PROVINCE,
      },
    ],
  }),
  validate: (values) => {
    console.log('I AM VALIDATING', values)
    const errors: any = {}

    if (!values.projectName) {
      errors.projectName = 'Required'
    }
    if (!values.startDate) {
      errors.startDate = 'Required Start Date'
    }
    if (!values.endDate) {
      errors.endDate = 'Required End Date'
    }

    // Check workTypeList if there is any one without name!
    // Try just making it validationField
    // values.workTypeList.map((workType: IWorkType, workTypeIndex: number) => {
    //   if (!workType.workType) {
    //     errors.workTypeList[workTypeIndex] = 'Required Work Type name'
    //   }
    // })

    console.log('errors', errors)
    return errors
  },
  handleSubmit: (values, { props, setFieldValue }: any) => {
    // console.log(values, 'values')
    console.log('I AM SUBMITING')
    // alert(JSON.stringify(values, null, 2))

    // Check if this is edit project or create project (see if there is projectId)
    const isEdit = props.router.params.projectId ? true : false

    // Check if there is any thing to remove
    if (isEdit && values.jobListToDelete.length > 0) {
      console.log('TO DELETE', values.jobListToDelete)
      deleteJobs(
        props.router.params.projectId,
        values.jobListToDelete.map((jobElement: IJob) => jobElement.changTumId),
      ).catch((err) => {
        console.log(err.response)
      })
    }

    // Pack all the values and push!
    if (isEdit) {
      const unpackedProject = {
        ...values,
        projectId: props.router.params.projectId,
      }
      packAndPutProject(unpackedProject)
    } else {
      const unpackedProject = {
        ...values,
        createdByUserId: props.userId,
      }

      console.log('unpackedProject>>', unpackedProject)
      packAndCreateProject(unpackedProject)
    }

    // Show modal saved successfully and redirect to my project page
    setFieldValue('isShowSuccessModal', true, false)
    setTimeout(() => props.router.navigate(`/${props.router.params.customerOrTechnician}/myproject`), 3000)
  },
})(Addprojectpage)

function mapStateToProps(state: ICombineReducers) {
  return { userId: state.tokenReducer.decodedAccessToken.userId }
}

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

const EnhancedAddProjectPageHistory = withRouter(connect(mapStateToProps)(EnhancedAddProjectPage) as any)

export default EnhancedAddProjectPageHistory

export interface IFormikValues extends IProject {
  isShowSuccessModal?: boolean
  jobListToDelete?: IJob[]
  safetyPassport?: IDropDown[]
  options?: IDropDown[]
  fieldList?: IFieldList[]
}

interface IDropDown {
  value: string
  text: string
}

interface IFieldList {
  name: string
  allowedValueList: any
}

export const EMPTY_WORK_TYPE = {
  workType: '',
  patTemplateId: '',
  imageURLList: [],
  isSafetyPassport: false,
  safetyPassport: '',
  isNoPrice: false,
  jobUnitPrice: 0,
  requirements: '',
  jobList: [EMPTY_JOB],
}

// Need this since we want to generate ID
export function getEmptyWorkTypeWithChangTumId(safetyPassportList?: string[]) {
  return {
    workType: '',
    patTemplateId: '',
    isSafetyPassport: true,
    safetyPassport: safetyPassportList || [],
    imageURLList: [],
    isNoPrice: false,
    requirements: '',

    jobList: [{ ...EMPTY_JOB, changTumId: getChangTumId() }],
  }
}
