import React from 'react'
import { useParams } from 'react-router-dom'

import styles from './index.module.scss'
import { FormikInputFile } from 'components/commons/input/formikInputFile'
import { TECHNICIAN_PREFIX } from 'App'

import noImgPNG from 'image/pat/noImg.png'
interface Props {
  dataImg: string[]
  handleUpload?: (e: React.FormEvent<HTMLInputElement>) => void
}

const CardUploadImgPat: React.FC<Props> = ({ dataImg, handleUpload }: Props) => {
  const { customerOrTechnician } = useParams()

  return (
    <>
      {customerOrTechnician === TECHNICIAN_PREFIX ? (
        <div className={styles.container}>
          <div className={styles.wrapperImg}>
            <div className={styles.imageBorder}>
              <img className={styles.imageInside} src={noImgPNG} alt={`Uploaded Image `} />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          {dataImg.length !== 0 ? (
            <div className={styles.wrapperImg}>
              {dataImg.map((image: string, imageIndex: number) => {
                return (
                  <div className={styles.imageBorder} key={imageIndex}>
                    <img className={styles.imageInside} src={image} alt={`Uploaded Image ${imageIndex}`} />
                  </div>
                )
              })}
            </div>
          ) : (
            <div className={styles.wrapperImg}>
              <div className={styles.imageBorder}>
                <img className={styles.imageInside} src={noImgPNG} alt={`Uploaded Image `} />
              </div>
            </div>
          )}

          <FormikInputFile
            className={styles.panelsubcontenttextfield}
            accept="image/*"
            isMultiple={true}
            functionOnChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleUpload?.(e)
            }}
          />
        </div>
      )}
    </>
  )
}

export default CardUploadImgPat
