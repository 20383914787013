import React, { useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
//Components
import { useFormikContext, withFormik, Form, Field } from 'formik'
import ProfileUpload from '../../components/ProfileUpload'
//Image
import EmailIcon from '../../image/email.svg'
import PhoneIcon from '../../image/phone.svg'
import { useParams } from 'react-router-dom'
import { getUserInTeam, getUserProfile } from 'adapter/xhr'

const ProfileUserInTeamPage: React.FC = (): JSX.Element => {
  const [image, setImage] = useState('')
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const navigate = useNavigate()
  const { userId } = useParams<any>()

  React.useEffect(() => {
    getUserInTeam({ userId: userId }).then((teamuserres: any) => {
      setFieldValue('teamuser', teamuserres.data.data.getUserInTeam)
      console.log('Get Profile', teamuserres)
    })
  }, [])
  return (
    <>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          {values.teamuser && (
            <Form>
              <div className={styles.card}>
                <section className={styles.btnWrapper}>
                  <button type="button" className={styles.btnBack} onClick={() => navigate(-1)}>
                    Back
                  </button>
                  <button
                    type="button"
                    className={styles.btnEdit}
                    onClick={() => navigate(`/technician/editTeam/${values.teamuser.userId}`)}
                  >
                    Edit Profile
                  </button>
                  {/* <button
                    type="button"
                    className={styles.btnEdit}
                    onClick={() => history.push(`/technician/editTeam/${values.teamuser.userId}`)}
                  >
                    Edit Profile
                  </button> */}
                </section>

                <div className={styles.verifyFristContainer}>
                  <p className={styles.title}>ข้อมูลส่วนบุคคล</p>
                  <div className={styles.flexbox}>
                    {' '}
                    <div className={styles.inputleft}>
                      {' '}
                      <div className={styles.inputArea}>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>ชื่อ - นามสกุล : </p>
                          <p className={styles.textsub}>{values.teamuser?.firstName}</p>
                          <p className={styles.textsub}>{values.teamuser.lastName}</p>
                        </div>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>ชื่อเล่น :</p>
                          <p className={styles.textsub}>{values.teamuser.nickName}</p>
                        </div>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>เลขบัตรประชาชน :</p>
                          <p className={styles.textsub}>{values.teamuser.personalID}</p>
                        </div>

                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>ที่อยู่ตามบัตรประชาชน : </p>
                          <p className={styles.textsub}>{values.teamuser.addressDetail}</p>
                        </div>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>เบอร์ติดต่อ : </p>
                          <p className={styles.textsub}>{values.teamuser.phone}</p>
                        </div>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>อีเมล์ : </p>
                          <p className={styles.textsub}>{values.teamuser.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputleft}>
                      <div className={styles.inputArea}>
                        {' '}
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>วันเดือนปีเกิด : </p>
                          <p className={styles.textsub}>{values.teamuser.birthDate}</p>
                        </div>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>เพศ :</p>
                          <p className={styles.textsub}>{values.teamuser.gender}</p>
                        </div>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>สถานภาพ :</p>
                          <p className={styles.textsub}>{values.teamuser.personalRelation}</p>
                        </div>
                        <p className={styles.texthead}>ข้อมูลติดต่อบุคคล (กรณีฉุกเฉิน) </p>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>ชื่อ-นามสกุล :</p>
                          <p className={styles.textsub}>{values.teamuser.emergencyContact?.firstName}</p>
                          <p className={styles.textsub}>{values.teamuser.emergencyContact?.lastName}</p>
                        </div>
                        <div className={styles.flexinput}>
                          <p className={styles.texthead}>ความสัมพันธ์ :</p>
                          <p className={styles.textsub}>{values.teamuser.emergencyContact?.relation}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.TypeInpitBox}>
                  <div className={styles.bottom}>
                    <p className={styles.texthead}>ประเภทงาน :</p>
                    <div className={styles.workbox}>
                      {values.teamuser &&
                        values.teamuser.technicianJobType?.map((item: any, index: number) => {
                          return (
                            <div key={index} className={styles.boxInput}>
                              {item}
                            </div>
                          )
                        })}
                    </div>
                  </div>{' '}
                  <div className={styles.bottom}>
                    <p className={styles.texthead}>ภูมิภาคที่ให้บริการ :</p>
                    <div className={styles.workbox}>
                      {values.teamuser &&
                        values.teamuser.serviceRegion?.map((item: any, index: number) => {
                          return (
                            <div key={index} className={styles.boxInput}>
                              {item}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                  <div className={styles.bottom}>
                    <p className={styles.texthead}>จังหวัดที่ให้บริการ :</p>
                    <div className={styles.workbox}>
                      {values.teamuser &&
                        values.teamuser.serviceProvince?.map((item: any, index: number) => {
                          return (
                            <div key={index} className={styles.boxInput}>
                              {item}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                  <div className={styles.bottom}>
                    <p className={styles.texthead}>Safety Passport :</p>
                    <div className={styles.workbox}>
                      {values.teamuser &&
                        values.teamuser.safetyPassport?.map((item: any, index: number) => {
                          return (
                            <div key={index} className={styles.boxInput}>
                              {item.safetyPassportType}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  )
}

const EnhancedProfileUserInTeamPage = withFormik({
  mapPropsToValues: () => ({
    userName: '',
  }),
  validate: (values) => {
    const error = {}

    return error
  },
  handleSubmit: (values, { setFieldValue }) => {
    //
    console.log(values)
    console.log(JSON.stringify(values))
  },
})(ProfileUserInTeamPage)

export default EnhancedProfileUserInTeamPage
