import React from 'react'
import styles from './index.module.scss'
import closeIcon from './close.svg'
import { Modal, Avatar } from '@mui/material'
import AddIcon from './AddIcon.svg'
import { useDispatch } from 'react-redux'

import allAction from '../../state/actions'
import { Field, useFormikContext } from 'formik'
import { CreateTechInProject } from 'adapter/xhr'
const ModalAddTech: React.FC<any> = (props: any): JSX.Element => {
  const { values } = useFormikContext<any>()
  const dispatch = useDispatch()
  const TechnicianInProject = props.projectList
    .find((findEle: any) => findEle.projectId === props.projectIdModal)
    ?.acceptedUserIdList?.map((TechItem: any) => {
      return TechItem
    })
  const TechJobTypeArr: any = {
    Electrician: 'ช่างไฟฟ้า',
    Painter: 'ช่างสี',
    Plumber: 'ช่างปะปา',
    Plasterer: 'ช่างปูน',
    Metalworker: 'ช่างเหล็ก',
    Carpenter: 'ช่างไม้',
    Mechanic: 'ช่างยนต์',
    Installer: 'ช่างติดตั้ง',
  }
  return (
    <Modal
      open={props.open}
      disablePortal={true}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      sx={{ border: 'none' }}
      // BackdropComponent={Backdrop}
    >
      <div className={styles.modalBox}>
        <div className={styles.cardModal}>
          <div className={styles.head}>
            <p className={styles.textHead}>เพิ่มทีมงาน</p>
            <img
              src={closeIcon}
              alt=""
              onClick={() => {
                props.setOpen(false)
              }}
              className={styles.Closeicon}
            />
          </div>
          <section className={styles.InputFiled}>
            {' '}
            <Field
              className={styles.inputText}
              type="text"
              name="technicianListSearch"
              placeholder="Username ชื่อทีม/ชื่อองค์กร"
            />
          </section>
          <section className={styles.TechBox}>
            {values.techListSearch &&
              values.techListSearch
                .filter((e: any) => !TechnicianInProject?.some((item: any) => item.userId === e.userId))
                .filter((TechItem: any) => {
                  if (values.technicianListSearch == '') {
                    return TechItem
                  } else if (
                    TechItem.username?.toLocaleLowerCase().includes(values.technicianListSearch.toLocaleLowerCase())
                  ) {
                    return TechItem
                  }
                })
                .map((TechItem: any, TechIndex: number) => (
                  <div key={TechIndex} className={styles.dataBox}>
                    <div className={styles.grop}>
                      {' '}
                      <Avatar
                        alt={`${TechItem.username}`}
                        src="/static/images/avatar/5.jpg"
                        className={styles.avatar}
                      />
                      <div className={styles.TextGroup}>
                        <span>
                          {TechItem.username}
                          {TechItem.technicianJobType && (
                            <span className={styles.textType}>
                              {' '}
                              -
                              {TechItem.technicianJobType?.slice(0, 2).map((TypeJob: any, index: number) => (
                                <span className={styles.textJobType} key={index}>
                                  {TechJobTypeArr[`${TypeJob}`]}
                                </span>
                              ))}
                              {2 < TechItem.technicianJobType?.length && (
                                <span className={styles.textJobType}>+{TechItem.technicianJobType?.length - 2}</span>
                              )}
                            </span>
                          )}
                        </span>
                        <p className={styles.textType}>{TechItem.email}</p>
                      </div>
                    </div>

                    <img
                      src={AddIcon}
                      alt=""
                      className={styles.addIcon}
                      onClick={() => {
                        dispatch(
                          allAction.modalConfirmAction.setOpenModalConfirm(
                            `คุณต้องการเพิ่มทีมงาน ${TechItem.username}`,
                            '',
                            '',
                            'ใช่,เพิ่มทีมงาน',
                            () => {
                              CreateTechInProject({
                                projectId: props.projectIdModal,
                                acceptedUserId: TechItem.userId,
                              }).then((res: any) => {
                                dispatch(
                                  allAction.modalAction.setOpenModalAlertStatus(
                                    `เพิ่มทีมงาน  ${TechItem.username}  เรียบร้อยแล้ว`,
                                    '',
                                    'success',
                                  ),
                                )
                                dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                                props.setOpen(false)
                                setTimeout(() => {
                                  props.queryProjectList()
                                }, 1000)

                                console.log(res, 'Accept User In Project')
                              })
                            },
                          ),
                        )
                      }}
                    />
                  </div>
                ))}
          </section>
        </div>
      </div>
    </Modal>
  )
}
export default ModalAddTech
