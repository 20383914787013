import ExcelJS from 'exceljs'
import { IWeekElement, getWeeksInYear, filterWeekElement } from 'utils/week'
import { getInstalledExportInno, getMasterPlanExportInno, getPlanExportInno } from 'adapter/xhr'
import saveAs from 'file-saver'

function columnToLetter(column: number) {
  let temp,
    letter = ''
  while (column > 0) {
    temp = (column - 1) % 26
    letter = String.fromCharCode(temp + 65) + letter
    column = (column - temp - 1) / 26
  }
  return letter
}

const generateMasterPlanPersonEasyToUse = (groupFieldList: any) => {
  const uniqueUserID: any = {}
  groupFieldList.forEach((groupFieldElement: any) => {
    groupFieldElement.workTypeList.forEach((workTypeElement: any) => {
      Object.values(workTypeElement.workTypeTable).forEach((weekArray: any) => {
        if (weekArray) {
          weekArray.forEach((weekElement: any) => {
            uniqueUserID[`${weekElement.acceptedUserId}`] = ''
          })
        }
      })
    })
  })
  const newGroupFieldList: any[] = []
  groupFieldList.map((groupFieldElement: any) => {
    const newArrayWorkTypeElement: any[] = []
    groupFieldElement.workTypeList.map((workTypeElement: any) => {
      Object.keys(uniqueUserID).map((userID: string) => {
        const newWorkTypeElement: any = {
          workType: workTypeElement.workType,
          acceptedUserId: userID,
          workTypeTable: {},
        }
        Object.keys(workTypeElement.workTypeTable).map((weekArray: any) => {
          if (workTypeElement.workTypeTable[`${weekArray}`]) {
            const findingObjInWeekArray = workTypeElement.workTypeTable[`${weekArray}`].find(
              (weekElement: any) => weekElement.acceptedUserId === userID,
            )
            if (findingObjInWeekArray) {
              newWorkTypeElement.workTypeTable[`${weekArray}`] = findingObjInWeekArray.quantity
            } else {
              newWorkTypeElement.workTypeTable[`${weekArray}`] = null
            }
          } else {
            newWorkTypeElement.workTypeTable[`${weekArray}`] = null
          }
        })
        newArrayWorkTypeElement.push(newWorkTypeElement)
      })
      groupFieldElement.workTypeList = newArrayWorkTypeElement
    })
    newGroupFieldList.push(groupFieldElement)
  })
  return newGroupFieldList
}
// converse technicianID to username
function getUsernameFromID(userId: string, values: any) {
  const userId2Name: any = {}
  values.unpackproject.acceptedUserIdList.map((technician: any) => {
    userId2Name[technician.userId] = technician.getUser.username || technician.userId
  })
  return userId2Name[userId]
}

export const NewExportInno = async (
  projectId: string,
  year: any,
  month: any,
  isMasterPlanExport: boolean,
  isPlanDateExport: boolean,
  isInstalledExport: boolean,
  values: any,
) => {
  let test = {}
  let exportMasterPlanResponse: any = {}
  let exportPlanResponse: any = {}
  let exportInstalledResponse: any = {}
  if (isMasterPlanExport) {
    await getMasterPlanExportInno(projectId, `${year}`).then(({ data }) => {
      if (data.data.project) {
        exportMasterPlanResponse = generateMasterPlanPersonEasyToUse(data.data.project.masterPlan.groupFieldList)
        test = { ...test, masterPlan: exportMasterPlanResponse }
      }
    })
  }

  if (isPlanDateExport) {
    await getPlanExportInno(projectId, `${year}`).then(({ data }) => {
      if (data.data.project) {
        exportPlanResponse = generateMasterPlanPersonEasyToUse(data.data.project.planDateReport.groupFieldList)
        test = { ...test, plan: exportPlanResponse }
        // console.log(exportPlanResponse)
      }
    })
  }

  if (isInstalledExport) {
    await getInstalledExportInno(projectId, `${year}`).then(({ data }) => {
      if (data.data.project) {
        exportInstalledResponse = generateMasterPlanPersonEasyToUse(data.data.project.actualDateReport.groupFieldList)
        test = { ...test, installed: exportInstalledResponse }
      }
    })
  }

  // check in masterplan plan actual have data?
  const checkHaveData = Object.values(test).map((a: any) => {
    return a.every((e: any) => {
      return e.workTypeList.every((x: any) => {
        return Object.values(x.workTypeTable).every((q: any) => {
          return q != null
        })
      })
    })
  })

  // console.log(testttX, 'testttX')

  // console.log(test)

  // // export Excel
  const workbook = new ExcelJS.Workbook()
  const sheet = workbook.addWorksheet('test 1')
  const topicFont = { name: 'Calibri', size: 11, bold: false }
  // const selectWeek = getWeeksInYear(year).filter((weekElement: any) => filterWeekElement(weekElement, year, month, 4))
  const allBorder: any = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  const FillWhite: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ffffff',
    },
  }
  const FillOrange: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ed7d31',
    },
  }
  const FillYellow: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ffd966',
    },
  }
  const FillGreen: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: '92d050',
    },
  }
  const FillSemiGreen: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'c6e0b4',
    },
  }
  const FillSemiOrange: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'f8cbad',
    },
  }
  const FillSemiYellow: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ffe699',
    },
  }
  const FillTotalGreen: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: '92d050',
    },
  }
  const FillTotalOrange: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'f4b084',
    },
  }
  const FillTotalYellow: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'ffe699',
    },
  }

  const NewFillYellow: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFF00',
    },
  }
  const NewFillSemiOrange: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFC000',
    },
  }
  const FillGrey: any = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'D9D9D9',
    },
  }
  const setCell = (cellIndex: string, value: any, font: any, border: any, fill: any) => {
    sheet.getCell(cellIndex).value = value
    sheet.getCell(cellIndex).font = font
    sheet.getCell(cellIndex).border = border
    sheet.getCell(cellIndex).fill = fill
  }
  const selectFill = (type: string) => {
    if (type === 'masterPlan') {
      return NewFillYellow
    } else if (type === 'plan') {
      return FillYellow
    } else if (type === 'installed') {
      return FillGreen
    }
  }
  const selectText = (type: string) => {
    if (type === 'masterPlan') {
      return 'Master Plan'
    } else if (type === 'plan') {
      return 'Plan'
    } else if (type === 'installed') {
      return 'Actual '
    }
  }
  const selectSemiFill = (type: string) => {
    if (type === 'masterPlan') {
      return FillSemiOrange
    } else if (type === 'plan') {
      return FillSemiYellow
    } else if (type === 'installed') {
      return FillSemiGreen
    }
  }

  const selectTotalFill = (type: string) => {
    if (type === 'masterPlan') {
      return FillTotalOrange
    } else if (type === 'plan') {
      return FillTotalYellow
    } else if (type === 'installed') {
      return FillTotalGreen
    }
  }

  const currentDate = new Date()
  const weekInYear = getWeeksInYear(year)
  sheet.columns = []
  const columns = [
    { key: 'Region', width: 20 },
    { key: 'Scope', width: 20 },
    { key: 'Technician', width: 20 },
    { key: 'Total', width: 20 },
  ]
  sheet.mergeCells('A1:BE1')
  sheet.properties.defaultColWidth = 12
  setCell(
    `A1`,
    `ข้อมูล Master Plan -updated as of วัน ${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`,
    {
      name: 'Calibri',
      size: 16,
      bold: false,
      color: {
        argb: 'ff0000',
      },
    },
    allBorder,
    FillWhite,
  )

  const initializeTableRowIndex = 3
  let lastestWorkTypeLength = 0
  const initializeExcelWeekColumn = 3

  Object.values(test).map((data: any, index: number) => {
    // console.log(Object.keys(test)[index], 'Object.keys(test)[index]')
    const firstLineRow = initializeTableRowIndex * index + 3 + index * 4
    const NameOfType = Object.keys(test)[index]
    // console.log(test, 'test1')
    // console.log(Object.values(test), 'Object.values(test)')
    // console.log(checkHaveData, 'checkHaveData')

    // Head Zone
    if (checkHaveData[index] == false) {
      sheet.mergeCells(`A${firstLineRow + lastestWorkTypeLength}:BE${firstLineRow + lastestWorkTypeLength}`)

      setCell(
        `A${firstLineRow + lastestWorkTypeLength}`,
        selectText(NameOfType),
        { name: 'Calibri', size: 16, bold: false },
        allBorder,
        selectFill(NameOfType),
      )
      // topic
      columns.forEach((column, cindex) => {
        setCell(
          `${columnToLetter(cindex + 1)}${firstLineRow + lastestWorkTypeLength + 2}`,
          column.key,
          topicFont,
          allBorder,
          column.key == 'Total' ? NewFillYellow : FillWhite,
        )
      })
      weekInYear.forEach((weekElement: any, weekIndex: number) => {
        setCell(
          `${columnToLetter(initializeExcelWeekColumn + weekIndex + 2)}${firstLineRow + lastestWorkTypeLength + 1}`,
          'P' + weekElement.month,
          topicFont,
          allBorder,
          weekElement.month % 2 === 0 ? FillWhite : NewFillSemiOrange,
        )
        setCell(
          `${columnToLetter(initializeExcelWeekColumn + weekIndex + 2)}${firstLineRow + lastestWorkTypeLength + 2}`,
          'Wk' + weekElement.weekNo,
          topicFont,
          allBorder,
          FillWhite,
        )
      })
    }

    data.map((dataInType: any, dataInTypeIndex: number) => {
      dataInType.workTypeList.map((workTypeElement: any, workTypeIndex: number) => {
        // setcell A1++ for data region
        setCell(
          `A${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3}`,
          dataInType.fieldValue,
          topicFont,
          allBorder,
          dataInTypeIndex % 2 == 0 ? NewFillSemiOrange : FillWhite,
        )
        setCell(
          `B${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3}`,
          workTypeElement.workType,
          topicFont,
          allBorder,
          FillWhite,
        )
        setCell(
          `C${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3}`,
          getUsernameFromID(workTypeElement.acceptedUserId, values),
          topicFont,
          allBorder,
          FillWhite,
          //   dataInTypeIndex % 2 == 0 ? selectSemiFill(NameOfType) : FillWhite,
        )
        let isPresetBlank = true
        weekInYear.forEach((weekElement: any, weekIndex: number) => {
          const totalInRow = Object.values<number>(workTypeElement.workTypeTable).reduce(
            (prev: number, curr: number) => prev + curr,
            0,
          )
          setCell(
            `D${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3}`,
            totalInRow,
            topicFont,
            allBorder,
            NewFillYellow,
          )

          const allTotal =
            ~~(
              sheet.getCell(
                `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${
                  firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 2
                }`,
              ).value || 0
            ) +
            ~~(
              sheet.getCell(
                `${columnToLetter(initializeExcelWeekColumn + weekIndex + 2)}${
                  firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 1
                }`,
              ).value || 0
            )
          const planPerWeek = data
            .map((summaryValuesRegionElement: any) => {
              return summaryValuesRegionElement.workTypeList.map((workTypeElement: any) => {
                return workTypeElement.workTypeTable[`week_${weekElement.weekNo}`]
              })
            })
            .flat()
            .reduce((prev: number, curr: number) => prev + curr, 0)

          if (planPerWeek) {
            isPresetBlank = false
          }
          setCell(
            `${columnToLetter(initializeExcelWeekColumn + weekIndex + 2)}${
              firstLineRow + lastestWorkTypeLength + workTypeIndex + 3
            }`,
            workTypeElement.workTypeTable[`week_${weekElement.weekNo}`],
            topicFont,
            allBorder,
            isPresetBlank ? FillGrey : totalInRow === 0 || totalInRow == null ? FillGrey : FillWhite,
          )
          setCell(
            `A${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 1}`,
            'total',
            { name: 'Calibri', size: 14, bold: true },
            allBorder,
            FillWhite,
          )
          setCell(
            `A${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 2}`,
            '',
            topicFont,
            allBorder,
            FillWhite,
          )
          setCell(
            `B${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 1}`,
            `${selectText(NameOfType)}  per wk`,
            topicFont,
            allBorder,
            FillWhite,
          )
          setCell(
            `B${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 2}`,
            `${selectText(NameOfType)} acc`,
            topicFont,
            allBorder,
            FillWhite,
          )
          setCell(
            `C${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 1}`,
            '',
            topicFont,
            allBorder,
            FillWhite,
          )
          setCell(
            `C${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 2}`,
            '',
            topicFont,
            allBorder,
            FillWhite,
          )
          //   const allTotal = ~~(
          //     sheet.getCell(
          //       `${columnToLetter(initializeExcelWeekColumn + weekIndex + 2)}${
          //         firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 1
          //       }`,
          //     ).value || 0
          //   )

          setCell(
            `D${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 1}`,
            allTotal,
            topicFont,
            allBorder,
            FillWhite,
          )
          setCell(
            `D${firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 2}`,
            '',
            topicFont,
            allBorder,
            FillWhite,
          )
          //   const planPerWeek = data
          //     .map((summaryValuesRegionElement: any) => {
          //       return summaryValuesRegionElement.workTypeList.map((workTypeElement: any) => {
          //         return workTypeElement.workTypeTable[`week_${weekElement.weekNo}`]
          //       })
          //     })
          //     .flat()
          //     .reduce((prev: number, curr: number) => prev + curr, 0)

          setCell(
            `${columnToLetter(initializeExcelWeekColumn + weekIndex + 2)}${
              firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 1
            }`,
            planPerWeek,
            topicFont,
            allBorder,
            FillWhite,
          )
          setCell(
            `${columnToLetter(initializeExcelWeekColumn + weekIndex + 2)}${
              firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 2
            }`,
            ~~(
              sheet.getCell(
                `${columnToLetter(initializeExcelWeekColumn + weekIndex + 1)}${
                  firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 2
                }`,
              ).value || 0
            ) +
              ~~(
                sheet.getCell(
                  `${columnToLetter(initializeExcelWeekColumn + weekIndex + 2)}${
                    firstLineRow + lastestWorkTypeLength + workTypeIndex + 3 + 1
                  }`,
                ).value || 0
              ),
            topicFont,
            allBorder,
            FillWhite,
          )
        })
      })
      lastestWorkTypeLength = lastestWorkTypeLength + dataInType.workTypeList.length
    })
  })

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const fileName = `Report_Master_Plan_Inno_${currentDate}`
  const buffer = await workbook.xlsx.writeBuffer()
  const excelToSave = new Blob([buffer], { type: fileType })
  saveAs(excelToSave, fileName + fileExtension)
}
