import React, { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'

import ToolbarComponent from './toolbar'
import { IWorkType } from 'adapter/xhr'
import { getWeeksInYear, IWeekElement } from 'utils/week'
// import useStateInitializer from './useStateInitializer'
// import TableComponent from './tableComponent'
import PlanTable from 'components/PlanTable'

const PlanActualDashboard: React.FC<IPlanActualDashboard> = ({
  data,
  showTotal,
  showToolbar,
  width,
  tableId, // Optional for id, since checkbox needs unique id!!
  year,
  setYear,
}: IPlanActualDashboard) => {
  // const [year, setYear] = useState<number>(new Date().getFullYear())
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
  const MONTH_FORWARD = 4
  const { values, setFieldValue } = useFormikContext<any>()

  function filterWeekElement(weekElement: IWeekElement): boolean {
    // Check if the start and end date is in the range of the month -> monthForward
    // Check by checking if startDate < month+monthForward (last day) and endDate > month (first day)
    const lastDay = new Date(year, month + MONTH_FORWARD - 1, 0)
    const firstDay = new Date(year, month - 1, 1)

    if (
      new Date(weekElement.startDate).getTime() <= lastDay.getTime() &&
      new Date(weekElement.endDate).getTime() >= firstDay.getTime()
    )
      return true
    return false
  }
  const [tableColumns, setTableColumns] = useState<IWeekElement[]>(getWeeksInYear(year).filter(filterWeekElement))

  // Filters
  // provinceFilter false = open, true = close
  const [provinceFilter, setProvinceFilter] = useState<boolean>(true)

  // I need 4 things: totalJobRegion, totalJobProvince, totalJobPlan, totalWeekQty for each week and province
  // This componentDidUpdate and componentOnMount will process the new data once to get the desired result
  // Note that only totalJobPlan is required, but others is for precomputed values for fast viewing
  // totalJobRegion is mapper of  region -> number
  // totalJobProvince is mapper of province -> number
  // totalJobPlan is mapper of region -> province -> weekNo -> number [province 'T' is the total!!]
  // totalWeekQty is mapper of weekNo -> number
  // const [totalJobRegionPlan, totalJobProvincePlan, totalJobPlan, totalWeekQtyPlan, totalPlan] = useStateInitializer(
  //   data,
  //   year,
  //   month,
  //   true,
  // )
  // const [totalJobRegionActual, totalJobProvinceActual, totalJobActual, totalWeekQtyActual, totalActual] =
  //   useStateInitializer(data, year, month, false)

  // If year, tableColumns
  useEffect(() => {
    // console.log(getWeeksInYear(year))
    setTableColumns(getWeeksInYear(year).filter(filterWeekElement))
  }, [year, month])
  // console.log('values ==>', values.masterPlan)
  // console.log('values.options ==>', values.options)

  //https://softwareengineering.stackexchange.com/questions/277778/why-are-people-making-tables-with-divs
  //https://webmasters.stackexchange.com/questions/6036/why-arent-we-supposed-to-use-table-in-a-design/6037
  // Somehow Table with pure CSS is better ?? https://www.freecodecamp.org/news/https-medium-com-nakayama-shingo-creating-responsive-tables-with-pure-css-using-the-grid-layout-module-8e0ea8f03e83/
  // Another Table with pure CSS https://medium.com/evodeck/responsive-data-tables-with-css-grid-3c58ecf04723\
  // Making Table with table and CSS.. but somehow it breaks with border collapse https://adamlynch.com/flexible-data-tables-with-css-grid/
  // Actually, u can make border collapse with css grid https://stackoverflow.com/questions/43686698/collapsing-borders-using-css-grid
  // But u don't use the CSS for border collapse cause it doesn't work. You just control everything in the grid container level!!!
  // There are several ways to do this: (1) Make grid-gap and make box-shadow instead of borders
  //                                    (2) Make the addition with row and column template, and use the borders
  // console.log('etsesets', values)

  return (
    // Somehow this is used for styling grid gaps! Since there are no way to style the gaps itself otherwise
    // Source: https://stackoverflow.com/questions/13792755/show-border-grid-lines-only-between-elements/47914693#47914693
    <>
      {/* Header */}
      <h1>
        <span>ประเภทงาน</span> {data && data.workType ? data.workType : ''}
      </h1>

      {/* Toolbar */}
      {showToolbar && (
        <ToolbarComponent
          year={year}
          month={month}
          provinceFilter={provinceFilter}
          setYear={setYear}
          setMonth={setMonth}
          setProvinceFilter={setProvinceFilter}
          tableId={tableId}
          values={values}
        />
      )}

      {/* Plan Date Table */}
      {/* <TableComponent
        provinceFilter={provinceFilter}
        tableColumns={tableColumns}
        year={year}
        totalJobRegion={totalJobRegionPlan}
        totalJobProvince={totalJobProvincePlan}
        totalJobPlanOrActual={totalJobPlan}
        totalWeekQty={totalWeekQtyPlan}
        totalPlanOrActual={totalPlan}
      /> */}
      <PlanTable
        tableTopic="Master Plan"
        theme="masterplan"
        year={year}
        isDisabledDropdown={true}
        isDisabledInput={true}
        month={month}
        tableOption={values.options}
        FieldName="masterPlan"
      />

      <PlanTable
        tableTopic="Plan"
        theme="plan"
        year={year}
        isDisabledDropdown={true}
        isDisabledInput={true}
        month={month}
        tableOption={values.options}
        FieldName="planDateReport"
      />

      {/* Actual Date Table */}
      <PlanTable
        tableTopic="Installed"
        theme="installed"
        year={year}
        isDisabledDropdown={true}
        isDisabledInput={true}
        month={month}
        tableOption={values.options}
        FieldName="actualDateReport"
      />
      {/* <TableComponent
        provinceFilter={provinceFilter}
        tableColumns={tableColumns}
        year={year}
        totalJobRegion={totalJobRegionActual}
        totalJobProvince={totalJobProvinceActual}
        totalJobPlanOrActual={totalJobActual}
        totalWeekQty={totalWeekQtyActual}
        totalPlanOrActual={totalActual}
        isPlan={false}
      /> */}
    </>
  )
}

export default PlanActualDashboard

interface IPlanActualDashboard {
  data: IWorkType
  showTotal?: boolean
  showToolbar?: boolean
  width?: number
  tableId?: string
  year: number
  setYear: any
}
