import React from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import ButtonComponent from '../Button'
import { useSelector, useDispatch } from 'react-redux'
import { ICombineReducers } from '../../state/reducers'
import allAction from '../../state/actions/index'

const ModalConfirm: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const modalComfirmReducer = useSelector((state: ICombineReducers) => state.modalComfirmReducer)

  return (
    <Modal
      open={modalComfirmReducer.openModalConfirm}
      onClose={() => dispatch(allAction.modalConfirmAction.setCloseModalConfirm())}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ zIndex: 1000000 }}
    >
      <div className={styles.modalBox}>
        <div className={styles.card}>
          <div className={styles.textWrapper}>
            <p className={styles.textTopic}>{modalComfirmReducer.title}</p>
            <div className={styles.contentWrapper}>
              <p className={styles.textContent}>{modalComfirmReducer.subtitle}</p>
              {modalComfirmReducer.description.split(',').map((descriptionString: string, descriptionIndex: number) => {
                return (
                  <p className={styles.textContent} key={descriptionIndex}>
                    {descriptionString}
                  </p>
                )
              })}
              {/* <p className={styles.textContent}>{modalComfirmReducer.description}</p> */}
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.buttonWrapper}>
              <div className={styles.btnTwin}>
                <div className={styles.btnBox}>
                  <ButtonComponent
                    _variant="outlined"
                    _text="ยกเลิก"
                    _colorText="white"
                    _colorBG="red"
                    _type="button"
                    _functionOnClick={() => dispatch(allAction.modalConfirmAction.setCloseModalConfirm())}
                  />
                </div>
                {/* <div className={styles.btnBox}>
                  <ButtonComponent
                    _variant="outlined"
                    _text="ข้าม"
                    _colorText="white"
                    _colorBG="orange"
                    _type="button"
                    _functionOnClick={() => dispatch(allAction.modalConfirmAction.setCloseModalConfirm())}
                  />
                </div> */}
              </div>

              <div className={styles.btnBox}>
                <ButtonComponent
                  _variant="outlined"
                  _text={modalComfirmReducer.btnDone}
                  _colorText="white"
                  _colorBG="blue"
                  _type="button"
                  _functionOnClick={modalComfirmReducer.functionCallBack}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalConfirm
