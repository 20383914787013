import React from 'react'
import styles from './index.module.scss'
import { useFormikContext, withFormik, Form, Field, FieldProps } from 'formik'
import openEyeSVG from './openEye.svg'
import { FormikInputText } from 'components/commons/input/formikInputText'
import { calDateforInputDate } from '../../utils/common'
import ImagesGroup from 'components/imagesGroup'
import { handleUploadFile } from 'utils/common'
import filepic from '../myProjectPage/file.svg'
import { Modal, DatePicker } from '@fluentui/react'
import ImagesSlideShow from 'components/imagesSlideShow'
import xSVG from './xsvg.svg'

export const CreatePostTopic = (props: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <>
      <section className={styles.topic}>
        <h1 className={styles.topicText}>สร้างการประกาศงาน</h1>
        <img
          className={styles.topicIcon}
          onClick={() => {
            props.setIsShowExampleModal(true)
          }}
          src={openEyeSVG}
          alt=""
        />
      </section>
      <section className={styles.subject}>
        <article className={styles.leftSideSubject}>
          <div className={styles.subjectTopicLine}>
            <b className={styles.subjectTopic}>หัวข้อประกาศ</b>
            <FormikInputText className={styles.inputBox} name={`post.title`} />
          </div>
          <div className={styles.subjectDateRow}>
            <section className={styles.subjectDateColumn}>
              <p className={styles.subjectDateTopic}>เริ่มต้น</p>
              <Field name="post.startDate">
                {({ field }: FieldProps) => (
                  <DatePicker
                    {...field}
                    // allowTextInput
                    value={field.value ? new Date(field.value) : undefined}
                    onSelectDate={(date) => {
                      if (date) {
                        setFieldValue(field.name, calDateforInputDate(date))
                      }
                    }}
                    style={{ flexGrow: 1 }}
                  />
                )}
              </Field>
            </section>
            <section className={styles.subjectDateColumn}>
              <p className={styles.subjectDateTopic}>สิ้นสุด</p>
              <Field name="post.endDate">
                {({ field }: FieldProps) => (
                  <DatePicker
                    {...field}
                    // allowTextInput
                    value={field.value ? new Date(field.value) : undefined}
                    onSelectDate={(date) => {
                      if (date) {
                        setFieldValue(field.name, calDateforInputDate(date))
                      }
                    }}
                    style={{ flexGrow: 1 }}
                  />
                )}
              </Field>
            </section>
          </div>
          <div className={styles.subjectDescriptionContainer}>
            <Field
              as="textarea"
              className={styles.subjectDescription}
              //   //   placeholder="รายละเอียด"
              //   style={{ resize: 'none', height: '50px', margin: '0 0 40px 0' }}
              name="post.description"
            />
          </div>
        </article>
        <article className={styles.rightSideSubject}>
          <div className={styles.imgUploadeContainer}>
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                Promise.all(handleUploadFile(e.target.files, `Post/${props.projectID}/images/`)).then(
                  (fileLocationList: any[]) => {
                    setFieldValue(`post.imageURLList`, fileLocationList)
                  },
                )
              }}
              style={{ display: 'none' }}
              ref={props.imageFileRef}
              multiple
            />
            <span className={styles.imageDescription}>เพิ่มไฟล์รูปได้สูงสุด 4 รูป</span>
            <ImagesGroup
              onClickImage={() => {
                props.imageFileRef.current.click()
              }}
              image1={values.post.imageURLList && values.post.imageURLList[0] ? values.post.imageURLList[0] : ''}
              image2={values.post.imageURLList && values.post.imageURLList[1] ? values.post.imageURLList[1] : ''}
              image3={values.post.imageURLList && values.post.imageURLList[2] ? values.post.imageURLList[2] : ''}
              image4={values.post.imageURLList && values.post.imageURLList[3] ? values.post.imageURLList[3] : ''}
            />
          </div>
          <div>
            <p>อัพโหลดไฟล์เอกสารหรือรูปภาพของโครงการ</p>
            <section className={`dp-flex ${styles.fileContainer}`}>
              {values.post.fileAttachmentList &&
                values.post.fileAttachmentList.map((fileURL: string, fileIndex: number) => {
                  return (
                    <div className={styles.fileLine} key={fileIndex}>
                      <img src={filepic} alt="filepic" style={{ width: '25px' }} />
                      <p className={styles.fileText}>{fileURL.split('/')[fileURL.split('/').length - 1]}</p>
                    </div>
                  )
                })}
            </section>
            <input
              type="file"
              onChange={(e) => {
                Promise.all(handleUploadFile(e.target.files, `Post/${props.projectID}/files/`)).then(
                  (fileLocationList: any[]) => {
                    setFieldValue(`post.fileAttachmentList`, fileLocationList)
                  },
                )
              }}
              accept=".pdf"
              multiple
            />
          </div>
        </article>
      </section>
    </>
  )
}

export const ModalShowCreatePost = (props: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  return (
    <Modal
      titleAriaId={'hello title aria Modal'}
      isOpen={props.isShowExamPleModal}
      onDismiss={() => {
        props.setIsShowExampleModal(false)
      }}
      isBlocking={true}
      containerClassName={`${styles.modalExample} wrapper2`}
    >
      <div className={styles.innerContainer}>
        <section className={styles.title}>
          <h1>ตัวอย่างการประกาศ</h1>
          <img
            onClick={() => {
              props.setIsShowExampleModal(false)
            }}
            src={xSVG}
            alt=""
            style={{ cursor: 'pointer' }}
          />
        </section>
        <section className={styles.contentCard}>
          <article className={styles.modalHeader}>
            <div className={styles.leftSide}>
              <h1 className={styles.leftSideTopic}> {values.post.title}</h1>
              <p>{props.username}</p>
              {/* <p className={styles.fileContainer}>
            <img className={styles.fileImg} src={filepic} alt="" />
            TOR_POE_Improved_Y2021_Inno Tech_R1
          </p> */}
            </div>
            <div className={styles.rightSide}>
              <p>
                <b>ราคา</b> 2,500-5,500 บาท
              </p>
              <p>
                <b>ระยะเวลาโครงการ</b> {values.post.startDate} - {values.post.endDate}
              </p>
            </div>
          </article>
          <hr className={styles.separateLine} />
          <article className={styles.modalContent}>
            <div className={styles.leftSide}>
              <p>รายละเอียด</p>
              {values.post.description &&
                values.post.description.split('\n').map((text: string, descriptionIndex: number) => {
                  return (
                    <p className={styles.description} key={descriptionIndex}>
                      {/* ประเภทงานที่ 1 : งาน POE+Hex 2000 งาน
              <br /> ประเภทงานที่ 2 : งาน Hex only 1200 งาน
              <br /> ประเภทงานที่ 3 : งาน POE 800 งาน */}
                      {text}
                    </p>
                  )
                })}
              {values.post.fileAttachmentList &&
                values.post.fileAttachmentList.map((fileURL: string, fileIndex: number) => {
                  return (
                    <article className={styles.fileContainer} key={fileIndex}>
                      <img className={styles.fileImg} src={filepic} alt="" />
                      <p className={styles.fileText}>{fileURL.split('/')[fileURL.split('/').length - 1]}</p>
                    </article>
                  )
                })}
              {/* <article className={styles.fileContainer}>
            <img className={styles.fileImg} src={filepic} alt="" /> Site list 2022
          </article> */}
              <article className={styles.provinceContainter}>
                <b>ภูมิภาค</b> กรุงเทพ,ภาคเหนือ,ภาคอีสาน,ภาคกลาง,ภาคใต้
              </article>
            </div>
            <div className={styles.rightSide}>
              <ImagesSlideShow imageList={values.post.imageURLList} />
              <section className={styles.dateCreated}>
                {new Date().toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}
              </section>
            </div>
          </article>
        </section>
      </div>
    </Modal>
  )
}
