import React from 'react'
import styles from './index.module.scss'
import closeIcon from './close.svg'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
// import moment from 'moment'
const ModalView: React.FC<any> = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalBox}>
        <div className={styles.card}>
          <div className={styles.head}>
            <p className={styles.textHead}>กู้คืนรหัสผ่านใหม่</p>
            <img
              src={closeIcon}
              alt=""
              onClick={() => {
                props.setOpen(false)
              }}
              className={styles.Closeicon}
            />
          </div>
          <div className={styles.description}>
            <p>
              กรุณาติดต่อ Admin ของ ChangTum <br /> Tel. 092-409-6426
            </p>
          </div>

          <div className={styles.bottom}>
            <div className={styles.btnForm}>
              <button
                type="button"
                className={styles.btClose}
                onClick={() => {
                  props.setOpen(false)
                }}
              >
                ปิด
              </button>{' '}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalView
